import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { filter, map } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { versionInfo } from './version';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'body',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss', './app-responsive.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class')
    public cssClass: string = '';

    public showVersionInfo: boolean;
    public uiVersionInfo = versionInfo;

    constructor(public readonly title: Title, private readonly router: Router, public readonly config: ConfigService, private readonly dds: DeviceDetectorService) {
        if (!this.dds.isDesktop()) {
            this.setVhProperty();
        }
    }

    ngOnInit(): void {
        this.title.setTitle('Notetracks');

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(event => event as NavigationEnd)
            // tslint:disable-next-line: deprecation
        ).subscribe((event: NavigationEnd) => {
            const classes: string[] = [];
            const url: string = event.urlAfterRedirects.ctrim('/');
            const partCount: number = url.split('/').length;
            for (let i = 1; i <= partCount; i++) {
                const parts = url.split('/', i);
                classes.push(parts.join('-'));
            }

            classes.push(this.dds.deviceType.toLocaleLowerCase());
            classes.push(this.dds.os.toLocaleLowerCase());
            classes.push(this.dds.device.toLocaleLowerCase());

            this.cssClass = classes.join(' ');
        });
    }

    setVhProperty() {
        document.documentElement.style.setProperty('--vh', (window.innerHeight * .01) + 'px');
    }

    @HostListener('window:click', ['$event'])
    onClick(event: PointerEvent) {
        if (event.button === 0 && (event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {
            this.showVersionInfo = !this.showVersionInfo;
        }
    }
    // @HostListener('window:resize', ['$event'])
    // onResize(event: Event) {
    //     if (!this.dds.isDesktop()) {
    //         this.setVhProperty();
    //     }
    // }
}