<router-outlet></router-outlet>
<span version [ngClass]="{ active: showVersionInfo }">
    <strong>UI</strong>
    &lbrace;
    Version: {{ uiVersionInfo.version + '-' + uiVersionInfo.tag }}
    Build: {{ uiVersionInfo.build }}
    Hash: {{ uiVersionInfo.hash }}
    Branch: {{ uiVersionInfo.branch }}
    &rbrace;
    &nbsp;&#8212;&nbsp;
    <strong>API</strong>
    &lbrace;
    Version: {{ config.get('version').version }}
    Build: {{ config.get('version').build }}
    Hash: {{ config.get('version').hash }}
    Branch: {{ config.get('version').branch }}
    &rbrace;
</span>
