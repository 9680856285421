import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { InlineSVGModule } from 'ng-inline-svg-w';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@app/shared/shared.module';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import {
  ForgotPasswordComponent, InvitationComponent, LoginComponent, LogoutComponent,
  RegisterComponent, ResetPasswordComponent, ValidationComponent
} from './pages';
import { LoginRegisterFlowDialogComponent } from '.';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VerifyEmailDialogComponent } from './components/verify-email-dialog/verify-email-dialog.component';

export * from './components';
export * from './guards';
export * from './interceptors';
export * from './services';
export * from './types';


@NgModule({
  declarations: [AuthComponent, LoginComponent, RegisterComponent,
    ForgotPasswordComponent, ResetPasswordComponent,
    InvitationComponent, LogoutComponent, ValidationComponent, LoginRegisterFlowDialogComponent, VerifyEmailDialogComponent],
  imports: [
    CommonModule, FormsModule, FlexLayoutModule, NgbModule, InlineSVGModule,
    AuthRoutingModule,
    SharedModule, MatCheckboxModule
  ]
})
export class AuthModule { }
