import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, IApiOptions, IApiResponse } from '@app/core';
import { IMediaData, IMediaDataList, Media, MediaList, Project } from '../../types';
import { HttpContext, HttpEventType, HttpHeaders, HttpProgressEvent } from '@angular/common/http';
import { IDS } from '.';

@Injectable({
  providedIn: 'root'
})
export class MediaDataService {

  constructor(private api: ApiService) { }

  //#region CRUD Operation
  public list(params?: Record<string, any>): Observable<MediaList> {
    const { projectId, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.get<IMediaDataList>(`project/${projectId}/media?limit=0`, options).pipe(
      map((response: IApiResponse<IMediaDataList>) => {
        const ml = new MediaList(response.result, Media);
        // tslint:disable-next-line: no-string-literal
        // project['_medias'] = ml;
        return ml;
      })
    );
  }

  public get(params?: Record<string, any>): Observable<Media> {
    const { id, projectId, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.get<IMediaData>(`project/${projectId}/media/${id}`, options).pipe(
      map((response: IApiResponse<IMediaData>) => {
        return new Media(response.result);
      })
    );
  }

  public save(mediaData: Partial<IMediaData> & Record<string, any>, params?: Record<string, any>): Observable<Media> {
    const { publicKey, file } = params;
    const { id, project_id } = mediaData; delete mediaData.id, delete mediaData.project_id;
    const context = new HttpContext().set(IDS, { id, project_id });
    const pathId = id ? `/${id}` : '';

    let payload = mediaData;
    if(file && !mediaData.file) mediaData.file = file;
    if (mediaData.file && mediaData.file instanceof File) {
      const formData = new FormData();
      Object.keys(mediaData).every(k => formData.append(k, mediaData[k]));
      payload = formData;
    }

    const options: IApiOptions = { observe: 'response' as 'response', context };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.post<IMediaData, Partial<IMediaData>>(`project/${project_id}/media${pathId}`, payload, options).pipe(
      map((response: IApiResponse<IMediaData>) => new Media((response as IApiResponse<IMediaData>).result))
    );
  }

  public upload(mediaData: Partial<IMediaData> & Record<string, any>, params?: Record<string, any>): Observable<Media | HttpProgressEvent> {
    const { publicKey } = params;
    const { id, project_id } = mediaData; delete mediaData.id, delete mediaData.project_id;
    const context = new HttpContext().set(IDS, { id, project_id });
    const pathId = id ? `/${id}` : '';

    console.assert(mediaData.file && mediaData.file instanceof File, 'No File found for upload');

    const formData = new FormData();
    Object.keys(mediaData).every(k => formData.append(k, mediaData[k]));

    const options: IApiOptions = { observe: 'events' as 'response', reportProgress: true, context };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.upload<IMediaData, FormData>(`project/${project_id}/media${pathId}`, formData, options).pipe(
      map((response: IApiResponse<IMediaData> | HttpProgressEvent) => {
        if('type' in response && response.type === HttpEventType.UploadProgress) {
            return response;
        } else {
            return new Media((response as IApiResponse<IMediaData>).result);
        }
      })
    );
  }

  public delete(params?: Record<string, any>): Observable<boolean> {
    const { id, projectId, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.delete<boolean>(`project/${projectId}/media/${id}`, options).pipe(
      map((response: IApiResponse<boolean>) => {
        return response.result;
      })
    );
  }
  //#endregion
}
