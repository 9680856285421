import { ApiObject } from '@app/core/api/types';
import { IUserData } from './iuser';

export class User extends ApiObject<IUserData> {
    protected static readonly POST_FIELDS_MAP = {
        email: 'email', nameFirst: 'name_first', nameLast: 'name_last', language: 'language', optinNotification: 'optin_notification', optinPromo: 'optin_promo'
    };

    //#region Database
    //#region Read-only
    public readonly id: number;
    public readonly fbId: string;
    public readonly googleId: string;
    public readonly planExpiry: string;
    public readonly status: string;
    //#endregion

    //#region Editable
    public email: string;
    public nameFirst: string;
    public nameLast: string;
    public language: 'en' | 'fr';
    public picture: URL;

    public optinNotification: boolean;
    public optinPromo: boolean;
    //#endregion

    //#region Timestamps
    public readonly createdAt: Date;
    public readonly updatedAt: Date;
    public readonly connectedAt: Date;
    public readonly emailValidatedAt: Date;
    //#endregion
    //#endregion

    //#region Construction Setters
    protected transformPictureForInit(data: string, set: Partial<IUserData>) {
        return this.transformURLForInit(data, set);
    }
    protected transformConnectedAtForInit(data: string, set: Partial<IUserData>) {
        return this.transformDateForInit(data, set);
    }
    protected transformEmailValidatedAtForInit(data: string, set: Partial<IUserData>) {
        return this.transformDateForInit(data, set);
    }
    //#endregion

    public clone<User extends ApiObject<IUserData>>(): User {
        return this.shallowClone(this.constructor as { new (data: IUserData): User } );
    }
}