<!-- start: forgot BOX -->
<div class="box-login">
    <div class="error-wrapper">
        <ngb-alert *ngIf="error" (close)="clearError()" [dismissible]="true" [type]="'danger'">
            {{ error }}
        </ngb-alert>
    </div>
    <form fxLayout="column" (ngSubmit)="submit()" #theForm="ngForm">
        <div class="form-group">
            <span fxLayout="row" fxLayoutAlign="center center">
                <label fxFlex="16" i18n>Email</label>
                    <input fxFlex="52" type="email" class="form-control" [disabled]="isProcessing"
                           name="email" #email="ngModel" [(ngModel)]="credentials.email"
                           [ngClass]="{error: this.submitted && email.invalid}"
                           i18n-placeholder placeholder="email@address.com" email required> 
                    <span fxFlex="32" class="error-message">
                        <span *ngIf="this.submitted && theForm.controls.email.invalid">
                            {{ theForm.controls.email.errors | json }}
                        </span>
                    </span>
                </span>
        </div>
        <div class="form-group">
            <span fxLayout="row" fxLayoutAlign="center center">
                <label fxFlex="16">&nbsp;</label>
                <a fxFlex="52" [routerLink]="['/auth/login']" i18n>Back to Sign in</a>
                <span fxFlex="32">&nbsp;</span>
            </span>
        </div>
        <div class="form-group form-actions">
            <span fxLayout="row" fxLayoutAlign="center center">
                <label fxFlex="16">&nbsp;</label>
                <span fxFlex="52" class="input">
                    <button class="big" [disabled]="isProcessing"
                            (click)="validate() && theForm.ngSubmit.emit()"
                            i18n>
                        Log In
                    </button>
                </span>
                <span fxFlex="32">&nbsp;</span>
            </span>
        </div>
    </form>
</div>
<!-- end: forgot BOX -->
