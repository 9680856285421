import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { InlineSVGModule } from 'ng-inline-svg-w';

import { MatchValidatorDirective } from './validators';
import { FilterPipe, OrderByPipe } from './pipes';
import { ConfirmationDialogComponent } from './components';
import { AutofocusDirective, DraggableDirective } from './directives';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';
import { AppToolbarComponent } from './components/app-toolbar/app-toolbar.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

export * from './components';
export * from './directives';
export * from './pipes';
export * from './validators';

@NgModule({
  declarations: [MatchValidatorDirective, FilterPipe, OrderByPipe, ConfirmationDialogComponent, DraggableDirective, SafeUrlPipe, AppToolbarComponent, AutofocusDirective],
  imports: [
    CommonModule, RouterModule, MatMenuModule, InlineSVGModule, MatIconModule, 
  ],
  exports: [MatMenuModule, MatIconModule, MatchValidatorDirective, FilterPipe, OrderByPipe, AutofocusDirective, DraggableDirective, SafeUrlPipe, AppToolbarComponent]
})
export class SharedModule { }
