import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, OnDestroy, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { DrawingStorage } from '@app/features/project/types/drawing/drawing-storage';

import { Color, IDrawingStorageOptions } from '../../../types';

@Component({
    selector: 'nt-drawing-board',
    templateUrl: './drawing-board.component.html',
    styleUrls: ['./drawing-board.component.scss']
})
export class DrawingBoardComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input('mode') mode: string;
    @Input('data') data: string;
    @Input('color') color: string;

    // @ViewChild('drawingWhiteboard') whiteboard: ElementRef<HTMLDivElement>;
    @ViewChild('drawingCanvas') canvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('span') span: ElementRef<HTMLSpanElement>;

    public drawingStorage: DrawingStorage;

    private intraWidth: number = 128;
    private intraHeight: number = 72;
    private options: IDrawingStorageOptions;

    private colorRgb = {
        'passio': 'rgba(242,102,102,1)',
        'freedo': 'rgba(255,190,93,1)',
        'balanc': 'rgba(141,216,100,1)',
        'spirit': 'rgba(122,190,250,1)',
        'creati': 'rgba(187,152,230,1)',
    };

    constructor(private readonly host: ElementRef) { }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.canvas.nativeElement.width = this.intraWidth;
        this.canvas.nativeElement.height = this.intraHeight;

        this.canvas.nativeElement.getContext('2d').strokeStyle = this.colorRgb[this.color || 'white'];
        this.canvas.nativeElement.getContext('2d').lineWidth = 3;

        this.drawingStorage = new DrawingStorage(this.canvas.nativeElement, this.data);

        if (this.mode == 'write') {
            let drawing = false;
            let changed = false;

            this.host.nativeElement.addEventListener('mousedown', (event: MouseEvent) => {
                let target = (event.target || event.srcElement) as HTMLElement;
                let rect = target.getBoundingClientRect();
                let offsetX = event.clientX - rect.left;
                let offsetY = event.clientY - rect.top;
                let intraX = offsetX / rect.width * this.intraWidth;
                let intraY = offsetY / rect.height * this.intraHeight;

                if(this.span) { this.span.nativeElement.style.display = 'none'; }
                drawing = true;
                this.drawingStorage.moveTo(intraX, intraY);
            });

            this.host.nativeElement.addEventListener('mouseup', (event: MouseEvent) => {
                drawing = false;
            });

            this.host.nativeElement.addEventListener('mousemove', (event: MouseEvent) => {
                if (drawing) {
                    let target = (event.target || event.srcElement) as HTMLElement;
                    let rect = target.getBoundingClientRect();
                    let offsetX = event.clientX - rect.left;
                    let offsetY = event.clientY - rect.top;
                    let intraX = offsetX / rect.width * this.intraWidth;
                    let intraY = offsetY / rect.height * this.intraHeight;

                    this.drawingStorage.lineTo(intraX, intraY);
                    changed = true;
                }
            });

            this.host.nativeElement.addEventListener('mouseleave', (event: MouseEvent) => {
                drawing = false;
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.data instanceof SimpleChange) {
            if(this.canvas) {
                this.canvas.nativeElement.getContext('2d').strokeStyle = this.colorRgb[this.color || 'white'];
                this.canvas.nativeElement.getContext('2d').lineWidth = 3;

                this.drawingStorage = new DrawingStorage(this.canvas.nativeElement, this.data);
            }
        }
        if(changes.color instanceof SimpleChange) {
            // if (this.options) {
            //     this.options.color = <Color>(changes.color.toString());
            // }

            if (this.drawingStorage && this.drawingStorage.context) {
                this.drawingStorage.context.strokeStyle = this.colorRgb[this.color || 'white'];
                this.drawingStorage.redraw();
            }
        }
    }

    ngOnDestroy(): void {
        this.host.nativeElement.removeAllListeners();
    }

}
