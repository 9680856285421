import { Directive, HostBinding, HostListener, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { MediaFilter } from '@app/features/auth';
import { take } from 'rxjs/operators';
import { ProjectManagerService } from '../project.module';

@Directive({
  selector: '[ntFileDragAndDrop]'
})
export class FileDragAndDropDirective {
  @Input('allowMultiple') allowMultiple: boolean = true;
  @Input('mediaFilter') mediaFilter: MediaFilter = '*' as MediaFilter;
  @HostBinding('class.dragover') fileOver: boolean;
  @Output('onFilesDropped') onFilesDropped: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output('onDropError') onDropError: EventEmitter<Error> = new EventEmitter<Error>();

  constructor(private readonly pms: ProjectManagerService) { }

  // Dragenter listener
  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    // Only process drag events with Files so we don't interfere with UI draggable inside the same element
    if(!event.dataTransfer.types.includes('Files')) return;
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  // Dragover listener
  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    // Only process drag events with Files so we don't interfere with UI draggable inside the same element
    if(!event.dataTransfer.types.includes('Files')) return;
    event.preventDefault();
    event.stopPropagation();
    // We just need to stop this event here otherwise `drop` will not get triggered
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    // Only process drag events with Files so we don't interfere with UI draggable inside the same element
    if(!event.dataTransfer.types.includes('Files')) return;
    // Only process events from target previously entered with `dragover` class
    if(!(event.target as HTMLElement).classList.contains('dragover')) return;
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    // Only process drag events with Files so we don't interfere with UI draggable inside the same element
    if(!event.dataTransfer.types.includes('Files')) return;
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
    const files = event.dataTransfer.files;

    let validFiles: File[] = [];
    Array.from(files).forEach((file: File) => {
        if (this.mediaFilter.split(',').some((ext: string) => file.name.toLocaleLowerCase().endsWith(ext))) {
            validFiles.push(file);
        }
    });
    if(!files.length) {
        this.onDropError.emit(new Error('No File Selected'));
    } else if(files.length > 1 && !this.allowMultiple) {
        this.onDropError.emit(new Error('Multiple Files Drop Not Allowed'));
    } else {
        this.onFilesDropped.emit(validFiles);
    }
    
  }
}
