import { ApiObject } from "@app/core/api";
import { IAudionoteData } from ".";

export class Audionote extends ApiObject<IAudionoteData> {
    
    protected static readonly POST_FIELDS_MAP = {
        id: 'id', projectId: 'project_id', commentId: 'comment_id', anonTokenId: 'anon_token_id'
    };
    
    //#region Database
    //#region Read-only
    id: number;
    projectId: number;
    commentId: number;
    userId: number;
    anonTokenId: number;

    status: string;

    fAudio: URL;
    originalFilesize: number;
    //#endregion

    //#region Metafields
    userCanDelete: boolean;
    userCanUpdate: boolean;
    //#endregion

    //#region Timestamps
    processedAt: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    //#endregion
    //#endregion

    //#region Transformers
    protected transformDateFAudioForInit(data: string, set: Partial<IAudionoteData>) {
        return this.transformURLForInit(data, set);
    }
    protected transformProcessedAtForInit(data: string, set: Partial<IAudionoteData>) {
        return this.transformDateForInit(data, set);
    }
    //#endregion

    public clone<Audionote extends ApiObject<IAudionoteData>>(): Audionote {
        return this.shallowClone(this.constructor as { new (data: IAudionoteData): Audionote } );
    }
}
