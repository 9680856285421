<ng-container *ngIf="!(user$ | async).isPartner">
    <ng-container *ngFor="let plan of plans">
        <div class="plan" *ngIf="plan.interval == period">
            <div class="price"><span>${{ plan.price }}/{{ getPlanAbreviation(plan.id) }}</span></div>
            <div class="name">{{ allPlansWording[plan.id].title }}</div>
            <div class="description">{{ allPlansWording[plan.id].description }}</div>
            
            <div class="features" (click)="extraOptions($event)">
                <!--LIMIT PROJECT-->
                <div class="project-limit project-feature">
                    {{ plan.limitProject > 0 ? plan.limitProject : 'Unlimited' }} Projects
                    <span *ngIf="plan.limitProject > 0 && auth.currentUser && plan.id == auth.currentUser.plan.id">
                        ({{ plan.limitProject - usage.current.project }} left)
                    </span>
                </div>

                <!--LIMIT STORAGE-->
                <div class="storage-limit project-feature">
                    {{ plan.limitStorage > 0 ? (plan.limitStorage / 1024) + 'GB': 'Unlimited' }} Storage
                    <span *ngIf="plan.limitStorage > 0 && auth.currentUser && plan.id == auth.currentUser.plan.id">
                        ({{ (plan.limitStorage - usage.current.storage) / 1024 | number: '.2' }} GB left)
                    </span>
                </div>

                <!--LIMIT TRAFFIC-->
                <div *ngIf="auth.currentUser && auth.currentUser.plan.id !== 1 && showTraffic" class="traffic-limit project-feature">
                    {{ plan.limitTraffic > 0 ? (plan.limitTraffic / 1024) + 'GB': 'Unlimited' }} Bandwidth
                    <span *ngIf="plan.limitTraffic > 0 && auth.currentUser && plan.id == auth.currentUser.plan.id">
                        ({{ (plan.limitTraffic - usage.current.traffic) / 1024 | number: '.2' }} GB left)
                    </span>
                </div>

                <!--LIMIT TRAFFIC-->
                <div *ngIf="auth.currentUser && auth.currentUser.plan.id == 1 && showTraffic" class="traffic-limit project-feature">
                    {{ plan.limitTraffic > 0 ? (plan.limitTraffic / 1024) + 'GB' : 'Unlimited' }} Bandwidth
                    <span *ngIf="plan.limitTraffic > 0 && auth.currentUser && plan.id == auth.currentUser.plan.id">
                        ({{ usage.left.traffic / 1024 | number: '.2' }} GB left)
                    </span>
                </div>

                <!--COLLABORATORS-->
                <div class="collaborators-limit project-feature">
                    Unlimited collaborators
                </div>
            </div>

            <div class="action">
                <button *ngIf="!auth.currentUser" (click)="goToPro()">
                    <span>Get {{ allPlansWording[plan.id].title }}</span>
                </button>
                <button *ngIf="auth.currentUser && plan.id == auth.currentUser.plan.id" disabled>
                    <span>Subscribed</span>
                </button>
                <button *ngIf="auth.currentUser && auth.currentUser.card && plan.id != auth.currentUser.plan.id && plan.sequence > auth.currentUser.plan.sequence"
                    (click)="upgrade(plan.id, $event)" [disabled]="isProcessing || getPlanWarning(plan) != ''">
                    <span>Upgrade</span>
                </button>
                <button *ngIf="auth.currentUser && !auth.currentUser.card && plan.id != auth.currentUser.plan.id && plan.sequence > auth.currentUser.plan.sequence"
                    [routerLink]="['/account/payment/' + plan.id]" [disabled]="isProcessing || getPlanWarning(plan) != ''">
                    <span>Upgrade</span>
                </button>
                <button *ngIf="auth.currentUser && plan.id != auth.currentUser.plan.id && plan.sequence <= auth.currentUser.plan.sequence"
                    (click)="downgrade(plan.id, $event)" [disabled]="isProcessing || getPlanWarning(plan) != ''">Downgrade</button>
            </div>

            <div *ngIf="getPlanWarning(plan) != ''">
                <span class="usage_warning" [ngClass]="{ active: getPlanWarning(plan) != '' }">
                    {{ getPlanWarning(plan) }}
                </span>
            </div>

        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="(user$ | async).isPartner">
    <div class="plan unique">
        <div *ngIf="!(user$ | async).isAnnualSubscription" class="name">{{ planTitle }}</div>
        <div *ngIf="!(user$ | async).isAnnualSubscription" class="name">&nbsp;</div>

        <ng-container *ngIf="user$ | async as user">
            <div class="features" (click)="extraOptions($event)">
                <div class="project-limit project-feature">
                    {{ user.plan.limitProject > 0 ? user.plan.limitProject : 'Unlimited' }} Projects
                    <span *ngIf="user.plan.limitProject > 0 && auth.currentUser">
                        ({{ user.plan.limitProject - usage.current.project }} left)
                    </span>
                </div>

                <div class="storage-limit project-feature">
                    {{ user.plan.limitStorage > 0 ? (user.plan.limitStorage / 1024) + 'GB': 'Unlimited' }} Storage
                    <span *ngIf="user.plan.limitStorage > 0 && auth.currentUser">
                        ({{ (user.plan.limitStorage - usage.current.storage) / 1024 | number: '.2' }} GB left)
                    </span>
                </div>

                <div class="traffic-limit project-feature" *ngIf="showTraffic">
                    {{ user.plan.limitTraffic > 0 ? (user.plan.limitTraffic / 1024) + 'GB': 'Unlimited' }} Bandwidth
                    <span *ngIf="user.plan.limitTraffic > 0 && auth.currentUser">
                        ({{ (user.plan.limitTraffic - usage.current.traffic) / 1024 | number: '.2' }} GB left)
                    </span>
                </div>

                <div *ngIf="(user$ | async).isAnnualSubscription" class="project-feature">
                    Your plan expires on {{ expirationDate }}
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>