import { HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, IApiOptions, IApiResponse } from '@app/core';
import { Drawing, DrawingList, IDrawingData, IDrawingDataList } from '../../types';
import { IDS } from '.';

@Injectable({
  providedIn: 'root'
})
export class DrawingDataService {

  
  constructor(private api: ApiService) { }

  //#region CRUD Operation
  public list(params?: Record<string, any>): Observable<DrawingList> {
    const { projectId, mediaId, publicKey } = params;
    const mediaPath = mediaId ? `media/${mediaId}/` : '';
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }
    return this.api.get<IDrawingDataList>(`project/${projectId}/${mediaPath}drawing?limit=0`, options).pipe(
      map((response: IApiResponse<IDrawingDataList>) => {
        const l = new DrawingList(response.result, Drawing);
        // tslint:disable-next-line: no-string-literal
        // project['_medias'] = ml;
        return l;
      })
    );
  }

  public get(params?: Record<string, any>): Observable<Drawing> {
    const { id, projectId, mediaId, publicKey } = params;
    const mediaPath = mediaId ? `media/${mediaId}/` : '';
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.get<IDrawingData>(`project/${projectId}/${mediaPath}drawing/${id}`).pipe(
      map((response: IApiResponse<IDrawingData>) => {
        return new Drawing(response.result);
      })
    );
  }

  public save(DrawingData: Partial<IDrawingData> & Record<string, any>, params?: Record<string, any>): Observable<Drawing> {
    const { publicKey } = params;
    const { id, project_id, media_id } = DrawingData;  delete DrawingData.id, delete DrawingData.project_id, delete DrawingData.media_id;
    const context = new HttpContext().set(IDS, { id, project_id, media_id });
    const mediaPath = media_id ? `media/${media_id}/` : '';
    const pathId = id ? `/${id}` : '';
    const options: IApiOptions = { observe: 'response' as 'response', context };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.post<IDrawingData, Partial<IDrawingData>>(`project/${project_id}/${mediaPath}drawing${pathId}`, DrawingData, options).pipe(
      map((response: IApiResponse<IDrawingData>) => {
        return new Drawing(response.result);
      })
    );
  }

  public delete(params?: Record<string, any>): Observable<boolean> {
    const { id, projectId, mediaId, publicKey } = params;
    const mediaPath = mediaId ? `media/${mediaId}/` : '';
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.delete<boolean>(`project/${projectId}/${mediaPath}drawing/${id}`).pipe(
      map((response: IApiResponse<boolean>) => {
        return response.result;
      })
    );
  }
  //#endregion
  
}
