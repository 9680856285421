<div class="canvasWrapper"
    [style.width.px]="clip.duration / (pms.ui.pixelDuration$ | async)">
    <canvas #canvas
        class="canvas"
        height="100%"
        [width]="MAX_FULL_RESOLUTION / (track.duration / track.duration)"
        [style.width.px]="track.buffer.duration / (pms.ui.pixelDuration$ | async)"
        [style.left.px]="(clip.start / (pms.ui.pixelDuration$ | async) * -1)"></canvas>
</div>

<div *ngIf="isSelected" class="handles left"
    [ntDraggable]="isSelected"
    [ntDraggableDropSelector]="'#' + host.nativeElement.parentElement.id"
    ntDraggableTriggerDropOn="source"
    (move)="onHandleMove($event, 'left')"
    (drop)="onHandleMoved($event, 'left')"></div>
<div *ngIf="isSelected" class="handles right"
    [ntDraggable]="isSelected"
    [ntDraggableDropSelector]="'#' + host.nativeElement.parentElement.id"
    ntDraggableTriggerDropOn="source"
    (move)="onHandleMove($event, 'right')"
    (drop)="onHandleMoved($event, 'right')"></div>

<div style="visibility: hidden; position: absolute"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" class="clip-menu">
	<ng-template matMenuContent let-at="at">
		<button mat-menu-item (click)="onContextMenuSplitAt(at)">Split</button>
        <button mat-menu-item (click)="onContextMenuDelete()">Delete</button>
	</ng-template>
</mat-menu>