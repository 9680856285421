import { Component, OnInit } from '@angular/core';
import { AuthService, AuthUser, Plan } from '@app/features/auth';
import { PlanService } from '@app/features/auth/services/plan.service';
import { Usage } from '@app/features/auth/types/usage';
import { Observable } from 'rxjs';

@Component({
  selector: 'nt-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss','./subscription-mobile.component.scss']
})
export class SubscriptionComponent implements OnInit {

  public user$: Observable<Readonly<AuthUser>>;
  private plans$: Observable<Array<Plan>>;
  public plans: Array<Plan>;
  public planPeriod: boolean;

  constructor(
    protected planService: PlanService,
    protected auth: AuthService
  ) { }

  ngOnInit(): void {
    this.user$ = this.auth.user$;
    this.plans$ = this.planService.getPlans();

    this.plans$.subscribe((allPlans: Array<Plan>) => {
      this.plans = allPlans;
    });
  }
}
