import { Injectable} from "@angular/core";

import { BehaviorSubject, combineLatest, Observable, of, Subscription, throwError } from "rxjs";
import { catchError, defaultIfEmpty, filter, map, switchMap, take, tap } from "rxjs/operators";

import { v4 as uuidv4 } from 'uuid';

import { ApiList, ApiObject, SocketEvent, SocketEventMessage, SocketService } from "@app/core";
import { HttpEventType, HttpProgressEvent } from "@angular/common/http";


//#region Types
type DataServiceParams<T> = Partial<T> | Record<string, any>;

type DataService<T extends ApiObject<I>, I> = {
    list(params: DataServiceParams<T>): Observable<ApiList<T, I>>;
    get(params: DataServiceParams<T>): Observable<T>;
    save(item: Partial<I>, params?: Record<string, any>): Observable<T>;
    delete(params: DataServiceParams<T>): Observable<boolean>;
    upload?(item: Partial<I> & Record<string, any>, params?: Record<string, any>): Observable<T | HttpProgressEvent>
}
//#endregion

//#region Helper Functions
const compareIdList = (a: Array<string>, b: Array<string>) => a?.length === b?.length && a.every((v,i) => b.indexOf(v) === i);
//#endregion

@Injectable()
export abstract class Store<T extends  ApiObject<I>, I, S extends DataService<T, I>>{

    protected static readonly LIST_KEY_PARTS = [];

    protected readonly _idUuidMap: Record<number, string> = {};

    /**
     * Record of item ID list
     * 
     * Those lists are the base, unsorted, unfiltered list.
     * They are populated when calling fetch() and keyed using the params in conjunction with the store's LIST_KEY_PARTS
     * 
     * Any list obtained when calling list(), with or without filters/sorting, is derived from those base list.
     */
    protected readonly _uuidList: Record<string, BehaviorSubject<Array<string>>> = {};
    /**
     * Record of sorted/filtered ID list
     * 
     * Those list are created as a side-effect of calling list() and maintained as subscribers to a base list and mapped to list of Observable items.
     */
    protected readonly _sortedUuidList: Record<string, BehaviorSubject<Array<string>>> = {};
    /**
     * Record of all the items of the store keyed by their ID
     */
    protected readonly _items: Record<string, BehaviorSubject<T>> = {};

    protected readonly _subscriptions: Record<string, Subscription> = {};

    protected readonly abstract service: S;

    // protected readonly abstract socket: SocketService;

    constructor(protected readonly socket: SocketService) {
        this.observe().subscribe((message: SocketEventMessage) => {
            const id = message.object_id;
            const projectId = message.project_id;
            switch(message.event) {
                case SocketEvent.UPDATE:
                case SocketEvent.CREATE:
                    this.socket.publicKey$.pipe(take(1)).subscribe((publicKey) => {
                        this.fetch({ projectId, id, publicKey});
                    });
                    break;
                case SocketEvent.DELETE:
                    this.removeItemFromStore(id);
                    break;
            }
        });
    }

    /**
     * Method required by Store constructor to observe socket message of type T.
     * 
     * Implementation should look like the following 
     * 
     * `return this.socket.observe(TClass);`
     * 
     * Where TClass should be the actual T class i.e.: Project, Media, Comment, etc.
     */
    protected abstract observe(): Observable<SocketEventMessage>;

    abstract ngOnDestroy(): void;

    //#region Private Methods
    private key(params: Record<string, any>): string {
        const partNames = this.constructor['LIST_KEY_PARTS'] as Array<string>
        const parts = partNames.map(name => params[name] ? String(params[name]) : params[name]).filter(Boolean)

        console.assert(partNames.length === parts.length, `[${this.constructor.name}] Missing parameters to generate list key. Expecting ${partNames.length} but received ${parts.length}`);
        
        return parts.join(':');
    }

    private filterKey(params: Record<string, any>) {
        return Object.keys(params).sort().filter(k => params[k] !== undefined).map(k => `${k}|${params[k]}`).join(':');
    }

    private filter(params: Record<string, any>): (item: T, index?: number, array?: Array<T>) => boolean {
        return (item: T, index?: number, array?: Array<T>) => {
            return Object.keys(params).reduce((sum: boolean, curr: string) => sum && item[curr] === params[curr], true);
        }
    }

    private uuidList(params: Record<string, any> = {}): Observable<Array<string>> {
        const key = this.key(params); // @todo Test that params contains at least the LIST_KEY_PARTS
        if(!(key in this._uuidList)) { this._uuidList[key] = new BehaviorSubject<Array<string>>(undefined); }
        return this._uuidList[key].asObservable();
    }

    private sortedUuidList(params: Record<string, any> = {}, sortBy: string = 'id', sortDesc: boolean = false): Observable<Array<string>> {
        const filterKey = this.filterKey(params);
        const sortedKey = `${filterKey}:${sortBy}:${sortDesc}`;
        if(!(sortedKey in this._sortedUuidList)) {
            this._sortedUuidList[sortedKey] = new BehaviorSubject<Array<string>>(undefined)

            const sorted = this.uuidList(params).pipe(
                filter((identities: Array<string>) => Array.isArray(identities)), // We do not want undefined, we want to wait for a real list, even if empty
                map((identities: Array<string>) =>  identities.map(identity => this._items[identity].asObservable())), // Map to a list of Observable items
                switchMap((aot: Array<Observable<T>>) => combineLatest(aot).pipe(defaultIfEmpty([] as Array<T>))), // Combine all the latest emitted items into an array of items
                map((at: Array<T>) => at.filter(this.filter(params))), // Filter items based on the params
                map((at: Array<T>) => at.sortBy(sortBy, sortDesc)), // Sort items based on sortBy and sortDesc arguments
                map((at: Array<T>) => at.map((t: T) => t.uuid )), // Map items back to ids to push on the sorted list
                filter((aot: Array<string>) => !compareIdList(this._sortedUuidList[sortedKey].value, aot) ), // Filter out identical arrays so we don't re-emit them for nothing
            ).subscribe((aot: Array<string>) => this._sortedUuidList[sortedKey].next(aot));

            if(this._subscriptions[sortedKey] instanceof Subscription) { this._subscriptions[sortedKey].unsubscribe(); }
            this._subscriptions[sortedKey] = sorted;
        }

        return this._sortedUuidList[sortedKey].asObservable();
    }
    //#endregion

    //#region Public Methods
    get(id: number): Observable<T> {
        if(!(id in this._idUuidMap)) { this._idUuidMap[id] = uuidv4(); }
        const identity = this._idUuidMap[id];
        if(!(identity in this._items)) {
            // Add to get future result.
            this._items[identity] = new BehaviorSubject<T>(undefined);
        }
        return this._items[identity].asObservable()//.pipe(map((item: T) => Object.freeze(item)));
    }

    list(params: Record<string, any> = {}, sortBy?: string, sortDesc?: boolean): Observable<Array<Observable<T>>> {
        return this.sortedUuidList(params, sortBy, sortDesc).pipe(
            filter((a: Array<string>) => Array.isArray(a)),
            map((identities: Array<string>) => identities.map((identity: string) => this._items[identity].asObservable()/*.pipe(map((item: T) => Object.freeze(item)))*/))
        );
    }

    save(item: Partial<T>, params: Record<string, any> = {}): Observable<T> {
        const newItem = !item.id;
        this.addItemToStore(item as T);
        if(newItem) {
            this.addItemToList(item as T);
        }

        const tData: Partial<I> = item.toPostableData();
        return this.service.save(tData, params).pipe(
            catchError((error: any, caught: Observable<T>) => {
                this.removeItemFromStore(undefined, item.uuid);
                throw error;
            }),
            tap((rItem: T) => {
                rItem.uuid = item.uuid;
                if(!(rItem.id in this._idUuidMap)) { this._idUuidMap[rItem.id] = rItem.uuid; }
                else {
                    console.assert(
                        this._idUuidMap[rItem.id] === rItem.uuid,
                        'Conflicting Identity between object [%s] and map [%s] for item %o',
                        rItem.uuid,
                        this._idUuidMap[rItem.id],
                        rItem
                    );
                }
                this._idUuidMap[rItem.id] = rItem.uuid;

                if(rItem.uuid in this._items) {
                    const current = this._items[rItem.uuid].value;
                    this._items[rItem.uuid].next(this.mergeItem(current, rItem)); // Sometimes the API return object without some of the data
                } else {
                    this._items[rItem.uuid] = new BehaviorSubject<T>(rItem);
                }
                this.addItemToList(rItem);
            }),
            map((rItem: T) => this._items[rItem.uuid].value)
        );
    }

    upload(item: Partial<T>, file: File, params?: Record<string, any>): Observable<T | HttpProgressEvent> {
        console.assert(!!item.id, 'Can only upload file for existing items, ID is required');
        if(typeof this.service.upload !== 'function') { throw new Error(`Attempting to call inexistent method [${this.constructor.name}.upload()]`); }

        return this.service.upload({ ...item.toPostableData(), file }, params).pipe(
            // Contrarily to save(), we don't tap() to update since it will always 
            // be status !== 'processed' at this point and we want to ignore any
            // updates (including the response itself) until it is processed
            // Also, we will return the item itself rather than the reponse
            map((response: T | HttpProgressEvent) => {
                return response;
            })
        );
    }

    delete(params: DataServiceParams<T>) {
        const { id } = params;
        this.removeItemFromStore(id);
        return this.service.delete(params);
    }

    // Should be mostly, if not exclusively, be used internally by other data/socket services and resolvers.
    fetch(params: Record<string, any> = {}, skipStore = false): Observable<T | ApiList<T, I>> {
        let r: Observable<T | ApiList<T, I>>;
        if(params.id) {
            r = this.service.get(params).pipe(
                tap((item: T) => {
                    if(!skipStore) {
                        this.addItemToStore(item);
                        this.addItemToList(item);
                    }
                })
            );
        } else {
            // @todo Retrieved in a paged manner, first X then X by X in background
            const key = this.key(params); // @todo Test that params contains the LIST_KEY_PARTS
            r = this.service.list(params).pipe(
                tap((list: ApiList<T, I>) => {
                    if(!skipStore) {
                        list.items.forEach((item: T) => {
                            this.addItemToStore(item);
                        });

                        if(!(key in this._uuidList)) { this._uuidList[key] = new BehaviorSubject<Array<string>>([])}

                        this._uuidList[key].next(list.items.map(item => item.uuid));
                    }
                })
            );
        }

        r.subscribe();
        return r;
    }
    //#endregion
  
    //#region Helpers
    private addItemToStore(item: T): string {
        // The identity might already have been created via the get() method
        if(item.id && item.id in this._idUuidMap) { item.uuid = this._idUuidMap[item.id]; } else { item.uuid = uuidv4(); }
        if(item.id) {
            this._idUuidMap[item.id] = item.uuid;
        }
        if( item.uuid in this._items) {
            const current = this._items[item.uuid]?.value;
            const merged = this.mergeItem(current, item);
            this._items[item.uuid].next(merged);  // Sometimes the API return object without some of the data
        } else {
            this._items[item.uuid] = new BehaviorSubject<T>(item);
        }

        return item.uuid;
    }

    private addItemToList(item: T) {
        const key = this.key(item); // @todo Test that params contains at least the LIST_KEY_PARTS
        if(key in this._uuidList) {
            const list = this._uuidList[key]?.value || [];
            if(list.indexOf(item.uuid) < 0) {
                this._uuidList[key].next([...list, item.uuid]);
            }
        } else {
            this._uuidList[key] = new BehaviorSubject([item.uuid])
        }
    }

    private removeItemFromStore(id: number, uuid?: string) {
        let identity;
        if (uuid && !id) {
            identity = uuid;
        } else {
            identity = this._idUuidMap[id];
            if (uuid && identity !== uuid) {
                throw new Error("Attempting to removeItemFromStore with mismatched id and uuid");
            }
        }
        Object.keys(this._uuidList).forEach((key: string) => {
            const dList = this._uuidList[key].value.filter((itemIdentity: string) => itemIdentity !== identity);
            if(!compareIdList(this._uuidList[key].value, dList)) {
                this._uuidList[key].next(dList);
            }
        });
        delete this._items[identity];
    }

    protected mergeItem(current: T, item: T): T {
        if(!current) {
            return item;
        } else {
            return Object.assign(current, item);
        }
    }

    protected clearSubscriptions() {
        Object.keys(this._subscriptions).map(key => this._subscriptions[key])
            .filter((subscription: Subscription) => subscription instanceof Subscription)
            .forEach((subscription: Subscription) => subscription.unsubscribe());
    }
    //#endregion

}
