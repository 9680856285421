import { IDrawingStorageOptions } from "./idrawing-storage-options";

export class DrawingStorage {

    // private _canvas: HTMLCanvasElement;
    private _origin: Array<number> = [0,0];
    private _tolerance: number = 5; // 5 degrees
    private _cumulativeSimplification: number = 0;

    private _draw: boolean = true;
    
    private _storagePosition: Array<Array<Array<number>>>;
    private _storageDelta: Array<Array<Array<number>>>;
    private _storageVector: Array<Array<Array<number>>>;
    
    public context: CanvasRenderingContext2D;
    public onchange: () => void = () => {};

    constructor(canvas: HTMLCanvasElement);
    constructor(canvas: HTMLCanvasElement, data: string);
    constructor(canvas: HTMLCanvasElement, data: string, options: IDrawingStorageOptions);
    constructor(canvas: HTMLCanvasElement, options: IDrawingStorageOptions);
    constructor(private _canvas: HTMLCanvasElement, data?: string | IDrawingStorageOptions, options?: IDrawingStorageOptions) {
        // this._canvas = canvas;
        if(arguments.length === 2 && typeof data === 'object') {
            options = data;
            data = undefined;
        }

        // Set canvas width/height if not set
        if (!this._canvas.hasAttribute('width')) { this._canvas.setAttribute('width', `${this._canvas.clientWidth}`); }
        if (!this._canvas.hasAttribute('height')) { this._canvas.setAttribute('height', `${this._canvas.clientHeight}`); }

        // Default values for parameters
        if (!options?.origin && this._canvas) {
            this._origin = [Math.round(parseInt(this._canvas.getAttribute('width'))/2), Math.round(parseInt(this._canvas.getAttribute('height'))/2)];
        }
        if (options?.tolerance) { this._tolerance = options.tolerance; }

        // Save parameters into properties
        this.context = (this._canvas) ? this._canvas.getContext('2d') : null;

        // Clear drawing board
        this.clear();

        // Unserialize
        if (data) {    
            this.unserialize(data);
        }
    }

    moveTo(x,y) {
        x = Math.round(x);
        y = Math.round(y);

        x -= this._origin[0];
        y -= this._origin[1];

        this._storagePosition.push([[x,y]]);
        this._storageDelta.push([[x,y]]);
        this._storageVector.push([[x,y]]);

        if ((this._draw) && (this._canvas)) {
            this.context.moveTo(x+this._origin[0],y+this._origin[1]);
        }
    };

    lineTo(x,y) {

        x = Math.round(x);
        y = Math.round(y);

        var last_path = this._storagePosition[this._storagePosition.length-1];
        var last_vector = this._storageVector[this._storageVector.length-1];
        if (typeof last_path == 'undefined') {
            this.moveTo(x,y);
            return;
        }

        x -= this._origin[0];
        y -= this._origin[1];

        var last_point = last_path[last_path.length-1];
        var last_angle = last_vector[last_vector.length-1][0];
        var px = last_point[0];
        var py = last_point[1];

        var dx = x-px;
        var dy = y-py;
        var distance = Math.round(Math.sqrt(dx*dx+dy*dy)*100)/100;

        var angle = Math.atan2(y-last_point[1], x-last_point[0]);
        angle *= 180 / Math.PI;
        angle = Math.round(angle);

        if (angle < 0) {
            angle = angle + 360;
        }

        var position = [x,y];
        var delta = [dx,dy];
        var vector = [angle, distance];

        // Don't save null distances
        if (distance == 0) return;

        // Simplify or add
        if ((Math.abs(angle-last_angle) <= this._tolerance)  && (this._cumulativeSimplification <= this._tolerance) && (last_path.length>1)) {
            // Simplify
            this._cumulativeSimplification += Math.abs(angle-last_angle);

            // Replace position
            var last_path = this._storagePosition[this._storagePosition.length - 1];
            last_path[last_path.length-1] = position;

            // Replace delta
            var last_path = this._storageDelta[this._storageDelta.length - 1];
            var last_delta = last_path[last_path.length-1];
            delta[0] += last_delta[0];
            delta[1] += last_delta[1];
            last_path[last_path.length-1] = delta;

            // Replace vector
            var last_path = this._storageVector[this._storageVector.length - 1];
            var last_vector_ = last_path[last_path.length-1];
            vector[0] = Math.round((vector[0] + last_vector_[0])/2); // Average angles
            vector[1] = vector[1] + last_vector_[1]; // Add distances
            last_path[last_path.length-1] = vector;
        } else {
            // Add
            this._cumulativeSimplification = 0;

            // Store position
            this._storagePosition[this._storagePosition.length - 1].push(position);

            // Store delta
            this._storageDelta[this._storageDelta.length - 1].push(delta);

            // Store vector
            this._storageVector[this._storageDelta.length - 1].push(vector);
        }

        if ((this._draw) && (this._canvas)) {
            this.context.lineTo(x+this._origin[0],y+this._origin[1]);
            this.context.stroke();
        }

        if(typeof this.onchange === 'function') { this.onchange(); }
    };

    redraw () {
        if (!this._canvas) return;

        // Copy positions
        var data = [];
        for (var i=0;i<this._storagePosition.length;i++) {
            var path = [];
            for (var j=0;j<this._storagePosition[i].length;j++) {
                path.push([this._storagePosition[i][j][0], this._storagePosition[i][j][1]]);
            }
            data.push(path);
        }

        // Save draw state and enable
        var old_draw = this._draw;
        this._draw = true;

        // Clear
        this.clear();

        // Redraw
        this.decode_position(data);

        // Restore draw state
        this._draw = old_draw;
    };

    clear() {
        this._storagePosition = [];
        this._storageDelta = [];
        this._storageVector = [];

        if ((this._draw) && (this._canvas)) {
            this.clearCanvas(this._canvas);
        }
    };

    serialize(callback: (data: string, isEmpty?: boolean) => void) {
        var position = this.dataToString(['p', this._storagePosition]);
        var delta = this.dataToString(['d', this._storageDelta]);
        var vector = this.dataToString(['v', this._storageVector]);

        var data = [delta, vector];

        const smallest = data.reduce((sum, curr) => curr.length < sum.length ? curr : sum, position);

        const isEmpty = !this._storagePosition?.length && !this._storageDelta?.length && !this._storageVector?.length;
        callback(smallest, isEmpty);
    };

    //#region Helpers
    private unserialize(data, draw?) {
        // Clear canvas and storage
        this.clear();

        // Disable drawing
        if (typeof draw == 'undefined') draw = this._draw;
        var old_draw = this._draw;
        var old_tolerance = this._tolerance;
        this._tolerance = -1;
        this._draw = draw;

        // Decode json
        var data = this.stringToData(data);
        var encoding = data[0];
        data = data[1];

        switch (encoding) {
            case 'p':
                this.decode_position(data);
                break;
            case 'd':
                this.decode_delta(data);
                break;
            case 'v':
                this.decode_vector(data);
                break;
            default:
                throw "Unknown encoding: "+encoding;
        }

        // Reset drawing
        this._draw = old_draw;
        this._tolerance = old_tolerance;
    };

    private clearCanvas(canvas) {
        var context = canvas.getContext('2d');
        var strokeStyle = context.strokeStyle;
        var lineWidth = context.lineWidth;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = strokeStyle;
        context.lineWidth = lineWidth;
    };

    private decode_position (data) {
        for (var i=0; i<data.length; i++) {
            this.moveTo(data[i][0][0] + this._origin[0], data[i][0][1] + this._origin[1]);
            for (var j=1; j<data[i].length; j++) {
                this.lineTo(data[i][j][0] + this._origin[0], data[i][j][1] + this._origin[1]);
            }
        }
    };

    private decode_delta (data) {
        for (var i=0; i<data.length; i++) {
            this.moveTo(data[i][0][0] + this._origin[0], data[i][0][1] + this._origin[1]);
            var last_position = data[i][0];
            for (var j=1; j<data[i].length; j++) {
                var x = last_position[0] + data[i][j][0];
                var y = last_position[1] + data[i][j][1];
                this.lineTo(x + this._origin[0],y + this._origin[1]);
                last_position = [x,y];
            }
        }
    };

    private decode_vector (data) {
        for (var i=0; i<data.length; i++) {
            this.moveTo(data[i][0][0] + this._origin[0], data[i][0][1] + this._origin[1]);
            var last_position = data[i][0];
            for (var j=1; j<data[i].length; j++) {
                var dx, dy;
                if (data[i][j][0] == 90) {
                    dx = 0;
                    dy = data[i][j][1];
                } else {
                    dx = Math.cos(data[i][j][0]/180*Math.PI) * data[i][j][1];
                    dy = Math.sin(data[i][j][0]/180*Math.PI) * data[i][j][1];
                }

                var x = last_position[0] + dx;
                var y = last_position[1] + dy;
                this.lineTo(x + this._origin[0], y + this._origin[1]);
                last_position = [x,y];
            }
        }
    };

    private dataToString (data) {
        var str = JSON.stringify(data);
        str = str.replace(/\],\[/g, 'A');
        str = str.replace(/0A0/g, 'B');
        str = str.replace(/1A1/g, 'C');
        str = str.replace(/0A1/g, 'D');
        str = str.replace(/1A0/g, 'E');
        return str;
    };
    private stringToData (str) {
        str = str.replace(/E/g, '1A0');
        str = str.replace(/D/g, '0A1');
        str = str.replace(/C/g, '1A1');
        str = str.replace(/B/g, '0A0');
        str = str.replace(/A/g, '],[');
        var data = JSON.parse(str);
        return data;
    };
    //#endregion
};