import { Clip } from "./clip";
import { Effect } from "./effect";
import { IClip } from "./iclip";
import { IEffect } from "./ieffect";
import { IMetadata } from "./imetadata";

export class Metadata implements IMetadata {
    public effects: Array<Effect>;
    public clips: Array<Clip>;

    constructor(data: IMetadata){
        this.effects = data?.effects?.map((e: IEffect) => new Effect(e)) || null;
        this.clips = data?.clips?.filter((c: Clip) => c.uuid && c.duration > 0 && c.start >= 0 && c.position >= 0).map((c: IClip) => new Clip(c)) || null;
    }
}
