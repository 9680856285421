import { Injectable } from '@angular/core';
import { ApiService, IApiOptions, IApiResponse } from '@app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Comment, ICommentData } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class ReactionDataService {

    constructor(private api: ApiService) { }
  
    //#region CRUD Operation
    public create(code: number, commentData: Partial<ICommentData>, options?: IApiOptions): Observable<Comment> {
      const { project_id, id } = commentData;
      return this.api.post<ICommentData, Partial<ICommentData>>(`project/${project_id}/comment/${id}/reaction/${code}`, commentData, options).pipe(
        map((response: IApiResponse<ICommentData>) => {
          return new Comment(response.result);
        })
      );
    }
  
    public delete(code: number, commentData: Partial<ICommentData>, options?: IApiOptions): Observable<Comment> {
      const { project_id, id } = commentData;
      return this.api.delete<ICommentData>(`project/${project_id}/comment/${id}/reaction/${code}`, options).pipe(
        map((response: IApiResponse<ICommentData>) => {
            return new Comment(response.result);
        })
      );
    }
    //#endregion
}
