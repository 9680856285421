import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { ConfigService } from '@app/config.service';
import { commentsResult, drawingsResult, mediasResult, projectResult, projectUserState, commentTemplate, drawingTemplate } from '../demo';
import { IDS, REACTIONS } from '../services';
import { AuthService } from '@app/features/auth';

const basicResponseBody = { code: 200, fields: null, message: null, notice: null, result: null }
const listResponseResult = { count: 0, debug: null, items: [], limit: 0, skip: 0 };
const listResponseBody = { code: 200, fields: null, message: null, notice: null, result: null }

@Injectable()
export class DemoInterceptor implements HttpInterceptor {

    constructor(private readonly config: ConfigService, private readonly auth: AuthService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const params = this.getUrlParams(request);
        const isDemo = 'project' in params && params['project'] === '-1';
        if(isDemo) {
            if(request.method === 'GET') {
                return this.getDemoResponse(request, params);
            } else if(['POST','PUT'].includes(request.method)) {
                return this.saveDemoResponse(request, params);
            } else if(request.method === 'DELETE') {
                return this.deleteDemoResponse(request, params);
            }
        }

        return next.handle(request);
    }

    private getUrlParams(request: HttpRequest<unknown>) {
        const params = {};
        const api = this.config.get('api');
        (new URL(request.urlWithParams)).pathname.replace(api.basePath, '').replace(api.version, '').ctrim('/').split('/').forEach((value, index, array) => {
            if(index % 2 === 0) {
                params[value] = array[index + 1];
            }
        });
        return params;
    }

    private getDemoResponse(request: HttpRequest<unknown>, params): Observable<HttpEvent<unknown>>  {
        if('comment' in params) { // Comments
            return of(this.getListResponse(commentsResult));
        }
        else if('drawing' in params) { // Drawings
            return of(this.getListResponse(drawingsResult));
        }
        else if('audionote' in params) { // Audionotes
            return of(this.getListResponse([]));
        }
        else if('userstate' in params) { // UserState
            return of(this.getBasicResponse(projectUserState));
        }
        else if('media' in params) { // Medias
            return of(this.getListResponse(mediasResult));
        }

        // Project
        return of(new HttpResponse({ status: 200, body: { code: 200, fields: null, message: null, notice: null, result: projectResult }}));
    }

    private saveDemoResponse(request: HttpRequest<unknown>, params): Observable<HttpEvent<unknown>> {
        const ids = request.context.get(IDS);
        let template;
        if('reaction' in params) {
            const code = parseInt(params['reaction']);
            const reactions = request.context.get(REACTIONS) || { counts: {}, mine: [] };
            reactions.counts = reactions.counts || {};
            reactions.counts[code] = (reactions.counts[code] || 0) + 1;
            if(reactions.mine.indexOf(code) < 0) {
                reactions.mine.push(code);
            }
            (request.body as any).reactions = reactions;
        } else if('comment' in params) { // Comments
            template = commentTemplate;
            if(this.auth.currentUser) {
                template.user_id = this.auth.currentUser.id;
                template.user.id = this.auth.currentUser.id;
                template.user.name_first = this.auth.currentUser.nameFirst;
                template.user.name_last = this.auth.currentUser.nameLast;
                template.user.picture = this.auth.currentUser.picture;
            }
        }
        else if('drawing' in params) { // Drawings
            template = drawingTemplate;
            if(this.auth.currentUser) {
                template.user_id = this.auth.currentUser.id;
            }
        }

        const result = { ...(template || {}), ...request.body as any, ...ids };
        if(!result.id) result.id = -Date.now();
        return of(this.getBasicResponse(result));
    }

    private deleteDemoResponse(request: HttpRequest<unknown>, params): Observable<HttpEvent<unknown>> {
        if('reaction' in params) {
            const code = parseInt(params['reaction']);
            const reactions = request.context.get(REACTIONS) || { counts: {}, mine: [] };
            reactions.counts = reactions.counts || {};
            if(reactions.counts[code]) reactions.counts[code] = (reactions.counts[code] || 0) - 1;
            if(!reactions.counts[code]) delete reactions.counts[code];
            if(reactions.mine.indexOf(code) > 0) {
                reactions.mine.splice(reactions.mine.indexOf(code), 1);
            }

            (request.body as any).reactions = reactions;
        }
        return of(new HttpResponse({ status: 204 }));
    }

    private getBasicResponse(result: any): HttpResponse<unknown> {
        const body = Object.assign({}, basicResponseBody, { result });
        return new HttpResponse({ status: 200, body })
    }

    private getListResponse(items: Array<unknown>): HttpResponse<unknown> {
        const result = Object.assign({}, listResponseResult, { count: items.length, items }); 
        const body = Object.assign({}, listResponseBody, { result });
        return new HttpResponse({ status: 200, body });
    }
}
