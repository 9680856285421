import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';

import { LocalStorageService } from 'ngx-webstorage';

import { Observable } from 'rxjs';

import { AuthService } from '@app/features/auth';
import { Comment, Audionote, IAudionoteData, Project } from '../../../types';
import { StoreService, ToolService } from '../../../services';
import { AudionoteComponent } from '../../audionote/audionote.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'nt-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['../comment.component.scss', './edit-comment.component.scss']
})
export class EditCommentComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('commentText') textarea: ElementRef<HTMLTextAreaElement>;
  @ViewChild('authorName') authorName: ElementRef<HTMLInputElement>;
  @ViewChild('audionote') audionoteComponent: AudionoteComponent;
  
  @Input('comment') comment: Comment;
  
  @Input('offsetX') offsetX: number;
  @Input('offsetY') offsetY: number;

  @Output('editComplete') editComplete: EventEmitter<Comment> = new EventEmitter();
  
  public audionotes$: Observable<Array<Observable<Audionote>>>;
  public commentTextValue: string = '';
  public anonymousName: string = '';
  protected publicKey: string = '';

  public isPosting: boolean;
    
  constructor(
    public readonly element: ElementRef<HTMLElement>,
    private readonly store: StoreService,
    public readonly auth: AuthService,
    protected readonly storage: LocalStorageService,
    public readonly toolService: ToolService) {
   }

  ngOnInit(): void {
    if (this.comment.id) {
      this.audionotes$ = this.store.audionote.list({ projectId: this.comment.projectId, commentId: this.comment.id });
    } else {
      let anon_name = this.storage.retrieve('anonymous-name');
      if (anon_name) {
          this.anonymousName = anon_name;
      }
    }

    this.store.project.get(this.comment.projectId).subscribe((project: Project) => {
      this.publicKey = project.publicKey;
    });
    // .pipe(defaultIfEmpty([] as Array<Observable<Audionote>>))
  }

  ngAfterViewInit(): void {
    this.textarea.nativeElement.setAttribute('style', 'height:' + this.textarea.nativeElement.scrollHeight + 'px;overflow-y:hidden;');

    ['input', 'focus'].forEach((eventName) => {
        this.textarea.nativeElement.addEventListener(eventName, (event: Event) => {
          const target = event.target as HTMLElement;
          target.style.height = 'auto';
          target.style.height = (target.scrollHeight) + 'px';
        }, false);
    });

    this.element.nativeElement.style.transform = `translate(${this.offsetX}px, ${this.offsetY}px)`;

    if (this.comment) {
      this.commentTextValue = this.comment.comment;
    }
    
    if (this.authorName) {
      this.authorName.nativeElement.tabIndex = 1000;
    }
    
    this.textarea.nativeElement.tabIndex = 1001;

    // setTimeout(() => {
    //   if (this.authorName) {
    //     this.authorName.nativeElement.focus();
    //   } else {
    //     this.textarea.nativeElement.focus();
    //   }
    // }, 250);
  }

  ngOnDestroy(): void {
    this.textarea.nativeElement.removeAllListeners();
  }

  onKeypress(event: KeyboardEvent) {
    if(event.which === ENTER && !event.shiftKey) {
        this.isValidForm() && this.post();
        event.preventDefault();
    }
  }

  post(): void {
    this.isPosting = true;
    this.comment.comment = this.commentTextValue;
    this.comment.anonName = this.auth.currentUser ? null : this.anonymousName;
    
    this.saveComment(this.comment, this.audionoteComponent.audioFile, this.offsetX).subscribe();
    setTimeout(() => this.editComplete.emit(this.comment), 250);
  }

  

  saveComment(comment: Comment, audionoteFile: File, offsetX: number): Observable<Comment> {
    return this.store.comment.save(comment, { publicKey: this.publicKey }).pipe(
        tap((commentSaved: Comment) => {
            if (commentSaved.id && audionoteFile) {
                let newAudionote = new Audionote({
                    project_id: commentSaved.projectId,
                    comment_id: commentSaved.id,
                    f_audio: URL.createObjectURL(audionoteFile),
                    anon_token_id: commentSaved.anonTokenId
                } as IAudionoteData);

                this.store.audionote.save(newAudionote, { publicKey: this.publicKey }).subscribe((savedAudionote: Audionote) => {
                    if (savedAudionote.id) {
                        let existingAudionote = new Audionote({ id: savedAudionote.id, project_id: comment.projectId } as IAudionoteData);
                        this.store.audionote.upload(existingAudionote, audionoteFile, { publicKey: this.publicKey }).subscribe();
                    }
                });
            }
        })
    );
  }

  cancel(): void {
    this.editComplete.emit(this.comment);
    // this.parent.removeComponent();
  }

  isValidForm(): boolean {
    let valid = true;
    // valid = this.commentTextValue != '';
    // if (!valid) {
    //   return valid;
    // }

    if (!this.auth.currentUser) {
      valid = this.anonymousName != '';
    }

    return valid;
  }

}
