<button mat-dialog-close [ngStyle]="{'z-index': 1050, display: 'block'}">
    <span [inlineSVG]="'assets/images/icons/x.svg'"></span>
</button>
<h3 mat-dialog-title>
    <span>Billing</span>
</h3>
<div mat-dialog-content>
    <form action="" (ngSubmit)="submit()" [formGroup]="billingForm" #bForm="ngForm">
        <div class="row mb-2">
            <div class="col"><h6>Credit card</h6></div>
        </div>
        <div class="row mb-2 ml-1">
            <div class="col-10 stripe-form"><ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card></div>
        </div>
        <div class="row mb-2 mt-4">
            <div class="col"><h6>Billing address</h6></div>
        </div>
        <div class="row mb-2 ml-1">
            <div class="col-3 align-self-center">Address 1</div>
            <div class="col-9">
                <input class="large-width" type="text" formControlName="txtAddress1" required>
                <span class="error" *ngIf="(billingForm.get('txtAddress1').hasError('required') && bForm.submitted) || (txtAddress1.invalid && (txtAddress1.dirty || txtAddress1.touched))">*</span>
            </div>
        </div>
        <div class="row mb-2 ml-1">
            <div class="col-3 align-self-center">Address 2</div>
            <div class="col-9">
                <input class="large-width" type="text" formControlName="txtAddress2">
                <span class="error" *ngIf="(billingForm.get('txtAddress2').hasError('required') && bForm.submitted) || (txtAddress2.invalid && (txtAddress2.dirty || txtAddress2.touched))">*</span>
            </div>
        </div>
        <div class="row mb-2 ml-1">
            <div class="col-3 align-self-center">City</div>
            <div class="col-9">
                <input type="text" formControlName="txtCity" required>
                <span class="error" *ngIf="(billingForm.get('txtCity').hasError('required') && bForm.submitted) || (txtCity.invalid && (txtCity.dirty || txtCity.touched))">*</span>
            </div>
        </div>
        <div class="row mb-2 ml-1">
            <div class="col-3 align-self-center">Country</div>
            <div class="col-9">
                <select [(ngModel)]="selectedCountry" (change)="getRegions($event, selectedCountry)" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let country of allCountries" [value]="country.countryShortCode">{{ country.countryName }}</option>
                </select>
            </div>
        </div>
        <div class="row mb-2 ml-1">
            <div class="col-3 align-self-center">State/Province/Region</div>
            <div class="col-9">
                <select [(ngModel)]="selectedRegion" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let region of countryRegions" [value]="region.shortCode">{{ region.name }}</option>
                </select>
            </div>
        </div>
        <div class="row mb-2 ml-1">
            <div class="col-3 align-self-center">Postal code</div>
            <div class="col-9">
                <input type="text" formControlName="txtPostalCode">
                <span class="error" *ngIf="(billingForm.get('txtPostalCode').hasError('required') && bForm.submitted) || (txtPostalCode.invalid && (txtPostalCode.dirty || txtPostalCode.touched))">*</span>
            </div>
        </div>
        <div class="row mb-2 ml-1">
            <div class="col-3 align-self-center">Promo code</div>
            <div class="col-9">
                <input [disabled]="isCheckingPromocode" type="text" [(ngModel)]="promocode" [ngModelOptions]="{standalone: true}" (blur)="checkPromoCode()">
                <span *ngIf="isCheckingPromocode" class="loading align-middle"></span>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col"></div>
            <div class="col-1 align-right">
                <span *ngIf="isProcessing" class="loading align-middle"></span>
            </div>
            <div class="col-2 align-right">
                <button [disabled]="isProcessing" type="submit">Done</button>
            </div>
        </div>
    </form>
    
</div>
