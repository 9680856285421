import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';

import { LocalStorage, LocalStorageService } from 'ngx-webstorage';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { defaultIfEmpty, publishReplay, refCount, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from '@app/features/auth';

import { Emoji, EMOJIS } from '../../emojis';
import { ProjectManagerService, StoreService } from '../../project.module';
import { Comment, ReactionUsage, Audionote, Project } from '../../types';
import { TrackComponent } from '../track/track.component';
import { AudionoteComponent } from '../audionote/audionote.component';
import { AudioService } from '@app/core';
import { ConfirmationDialogComponent } from '@app/shared';
import { ENTER } from '@angular/cdk/keycodes';


@Component({
    selector: 'nt-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnChanges, AfterViewInit {
    // @Input('userCanComment') userCanComment: boolean;

    @Input('showLines') showLines: number & (0 | 1 | 2 | 3 | 4 | 5);
    @Input('comment') comment: Comment; // @todo Make observable?
    @Output('more') more: EventEmitter<void> = new EventEmitter();
    @Output('reply') reply: EventEmitter<void> = new EventEmitter();
    @HostBinding('class.reply') isReply: boolean;

    //   public isPublicProject: boolean = false;
    public anonymousName: string = '';
    //   @Output('editComplete') editComplete: EventEmitter<Comment> = new EventEmitter();

    //#region Comment Positioning
    @Input('offsetX') offsetX: number; // @todo Make observable?
    @Input('offsetY') offsetY: number; // @todo Make observable?
    private readonly VSPACING = 8;
    public width: number;
    public height: number;
    public level: number;
    //#endregion

    //#region Reactions
    @LocalStorage('EmojisUsage')
    public emojisUsage: Array<ReactionUsage>;
    public frequentEmojis: Array<Emoji>;
    //#endregion

    //#region Edition
    @Input('editable') editable: boolean;
    @Output('edit') edit: EventEmitter<CommentComponent> = new EventEmitter<CommentComponent>();
    @ViewChild('textarea', { static: false }) textarea: ElementRef<HTMLTextAreaElement>;
    @ViewChild('timecode', { static: false }) timecode: ElementRef<HTMLInputElement>;
    @ViewChild('timecodeEnd', { static: false }) timecodeEnd: ElementRef<HTMLInputElement>;
    @HostBinding('class.edit') @Input('editMode')
    public editMode: boolean = false;
    //#endregion

    @HostBinding('class.userCanUpdate') userCanUpdate: boolean;
    @HostBinding('class.userCanDelete') userCanDelete: boolean;
    @HostBinding('style.transform') translateX: string;
    @ViewChild(MatMenuTrigger) reactionsTrigger: MatMenuTrigger;
    @ViewChild('audionote') audionoteComponent: AudionoteComponent;
    @ViewChild('replyRef') replyRef: ElementRef<HTMLSpanElement>;

    public replies$: Observable<Array<Comment>> = of(undefined);

    public audionotes$: Observable<Array<Audionote>>;
    // protected publicKey: string = '';

    private _destroy: Subject<void> = new Subject();

    constructor(
        @Inject(EMOJIS) public readonly emojis: Array<Emoji>,
        public readonly element: ElementRef<HTMLElement>, private readonly cdr: ChangeDetectorRef,
        private storage: LocalStorageService,
        private readonly store: StoreService,
        private readonly pms: ProjectManagerService,
        public auth: AuthService,
        public audio: AudioService,
        public confirmationDialog: MatDialog, @Optional() private readonly parent?: TrackComponent
    ) { }

    //#region Lifecycle
    ngOnInit(): void {
        this.isReply = !!this.comment.parentId;
        if (!this.comment.id) {
            let anon_name = this.storage.retrieve('anonymous-name');
            if (anon_name) {
                this.anonymousName = anon_name;
            }
        }

        // Observe local storage's emojisUsage, update frequentEmojis
        this.storage.observe('emojisUsage').pipe(
            takeUntil(this._destroy),
            publishReplay(1),
            refCount()
        ).subscribe((emojisUsage: Array<ReactionUsage>) => {
            this.frequentEmojis = emojisUsage.sortBy('frequency').slice(0,8).map((usage: ReactionUsage) => this.emojis.find((emoji: Emoji) => emoji.index === usage.key));
        });

        // Simply set storage emojisUsage with same content as storage to trigger the observe
        this.storage.store('emojisUsage', this.storage.retrieve('emojisUsage') || []);
    }

    ngAfterViewInit(): void {
        const styles = getComputedStyle(this.element.nativeElement);
        this.width = this.element.nativeElement.clientWidth + parseInt(styles.marginLeft) + parseInt(styles.marginRight);
        this.height = this.element.nativeElement.clientHeight + parseInt(styles.marginTop) + parseInt(styles.marginBottom) + this.VSPACING;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.offsetX instanceof SimpleChange || changes.offsetY instanceof SimpleChange)) {
            this.translateX = `translate(${this.offsetX}px, ${this.offsetY}px)`;
        }
        if (changes.comment instanceof SimpleChange) {
            if (changes.comment.currentValue.id && (changes.comment.firstChange || changes.comment.previousValue.id !== changes.comment.currentValue.id)) {
                this.audionotes$ = this.store.audionote.list({ projectId: this.comment.projectId, commentId: this.comment.id }).pipe(
                    switchMap((notes) => combineLatest(notes)),
                );
            }
            this.userCanUpdate = this.comment.userCanUpdate;
            this.userCanDelete = this.comment.userCanDelete;
            const styles = getComputedStyle(this.element.nativeElement);
            this.width = this.element.nativeElement.clientWidth + parseInt(styles.marginLeft) + parseInt(styles.marginRight);
            this.height = this.element.nativeElement.clientHeight + parseInt(styles.marginTop) + parseInt(styles.marginBottom) + this.VSPACING;
        }
    }
    //#endregion

    trackByAudionote(index, item: Audionote) {
        return item.id;
    }

    //#region Helpers
    private updateEmojiUsage(emojiKey: number): void {
        const existingUsage = this.emojisUsage.find((usage: ReactionUsage) => usage.key === emojiKey) || { key: emojiKey, frequency: 0 };
        if(existingUsage.frequency == 0) this.emojisUsage.push(existingUsage);
        existingUsage.frequency++;
        this.emojisUsage = [...this.emojisUsage];
    }
    //#endregion

    //#region Event Hanlders
    onResolve(isResolved: boolean) {
        this.comment.isResolved = isResolved;
        this.store.comment.save(this.comment).subscribe();
    }
    onEdit() {
        if (this.editable) {
            this.editMode = true;
            setTimeout(() => {
                this.textarea.nativeElement.focus();
            });
        } else {
            this.edit.emit(this)
        }
    }

    onLike(event: Event) {
        (event.target as HTMLElement).closest('[like]').classList.toggle('active');
        console.warn('Not Yet Supported!');
    }

    onMore() {
        this.more.emit();
    }

    onReply() {
        this.reply.emit();
    }

    onCancel() {
        this.editMode = false;
    }

    onTextAreaChange(event) {
        const target = event.target as HTMLElement;
        target.style.height = 'auto';
        target.style.height = (target.scrollHeight) + 'px';
    }

    onKeypress(event: KeyboardEvent) {
      if(event.which === ENTER && !event.shiftKey) {
          this.onPost(this.textarea.nativeElement.value, this.timecode?.nativeElement?.value, this.timecodeEnd?.nativeElement?.value);
          event.preventDefault();
      }
    }

    onPost(text: string, timecode?: string, timecodeEnd?: string) {
        const comment = this.comment.clone<Comment>();
        comment.comment = text;
        comment.timecode = parseFloat(timecode) || undefined;
        comment.timecodeEnd = parseFloat(timecodeEnd) || undefined;
        comment.anonName = this.auth.currentUser ? null : this.anonymousName;

        const audionoteFile = this.audionoteComponent?.audioFile
        this.pms.project$.pipe(take(1)).subscribe((project: Project) => {
            this.store.comment.save(comment, { publicKey: project.publicKey }).subscribe((commentSaved: Comment) => {
                if (commentSaved.id > 0 && audionoteFile) {
                    const newAudionote = new Audionote({
                        project_id: commentSaved.projectId,
                        comment_id: commentSaved.id,
                        f_audio: URL.createObjectURL(audionoteFile),
                        anon_token_id: commentSaved.anonTokenId
                    });

                    this.store.audionote.save(newAudionote, { publicKey: project.publicKey }).subscribe((savedAudionote: Audionote) => {
                        if (savedAudionote.id > 0) {
                            let existingAudionote = new Audionote({ id: savedAudionote.id, project_id: comment.projectId });
                            this.store.audionote.upload(existingAudionote, audionoteFile, { publicKey: project.publicKey }).subscribe(() => {
                                this.editMode = false;
                            });
                        } else {
                            this.editMode = false;
                        }
                    });
                } else {
                    this.editMode = false;
                }
            });
        });
    }

    onDelete() {
        this.confirmationDialog.open(ConfirmationDialogComponent, {
            autoFocus: false,
            panelClass: 'confirmation-dialog',
            width: '300px',
            data: {
                // title: 'Delete comment',
                message: 'Are you sure you want to delete this comment?<br><span>This action cannot be undone',
                cancelLabel: 'Cancel',
                acceptLabel: 'Yes'
            }
        })
            .afterClosed()
            .subscribe((confirmed: boolean) => {
                if (confirmed) {
                    this.pms.project$.pipe(take(1)).subscribe((project: Project) => {
                        this.store.comment.delete({ ...this.comment, publicKey: project.publicKey }).subscribe(() => {
                            // do something else  
                        });
                    });
                } else {
                    // do something else
                }
            });
    }

    onToggleReaction(emojiKey: string) {
        const code = parseInt(emojiKey);
        if (this.comment.reactions.mine.indexOf(code) >= 0) {
            this.store.comment.removeReaction(this.comment, code);
        } else {
            this.updateEmojiUsage(code);
            this.store.comment.addReaction(this.comment, code);
        }

        // @todo Trigger comment positioning
    }

    onOpenReactions(event) {
        this.reactionsTrigger.openMenu();
    }

    onSelectReaction(emojiKey: number) {
        this.updateEmojiUsage(emojiKey);

        // saving the reaction
        this.store.comment.addReaction(this.comment, emojiKey);
        // @todo Trigger comment positioning
    }
    //#endregion

}
