import { HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, IApiOptions, IApiResponse } from '@app/core/api';
import { Folder, IFolderData, FolderList, IFolderDataList } from '../../types';
import { IDS } from '.';

@Injectable({
  providedIn: 'root'
})
export class FolderDataService {

  //#region Fields
  //#endregion

  constructor(private api: ApiService) { }

  //#region CRUD Operation
  public list(params?: Record<string, any>): Observable<FolderList> {
    const { id, limit, skip, orderBy, order } = params;
    const queryParams = { limit: limit || 0, skip, orderBy, order };
    const pathId = id ? `/${id}` : '';
    const queryString = Object.keys(queryParams).filter(k => queryParams[k] !== undefined).map(k => `${k}=${queryParams[k]}`).join('&')
    return this.api.get<IFolderDataList>(`folder${pathId}?${queryString}`).pipe(
      map((response: IApiResponse<IFolderDataList>) => {
        const pl = new FolderList(response.result, Folder);
        return pl;
      })
    );
  }

  public get(params?: Record<string, any>): Observable<Folder> {
    const { id, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.get<IFolderData>(`folder/${id}`, options).pipe(
      map((response: IApiResponse<IFolderData>) => {
        const p = new Folder(response.result);
        return p;
      })
    );
  }

  public save(folderData: Partial<IFolderData> & Record<string, any>, params: Record<string, any>): Observable<Folder> {
    const { publicKey } = params;
    const { id } = folderData; delete folderData.id;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
        options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }
    const pathId = id ? `/${id}` : '';
    return this.api.post<IFolderData, Partial<IFolderData>>(`folder${pathId}`, folderData, options).pipe(
      map((response: IApiResponse<IFolderData>) => {
        const p = new Folder(response.result);
        return p;
      })
    );
  }

  public delete(params?: Record<string, any>): Observable<boolean> {
    const { id, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.delete<boolean>(`folder/${id}`, options).pipe(
      map((response: IApiResponse<boolean>) => {
        return response.result;
      })
    );
  }
  //#endregion

}
