import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChange, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { HttpProgressEvent } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { buffer, debounceTime, defaultIfEmpty, filter, first, map, skipUntil, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AudioService } from '@app/core';
import { ConfirmationDialogComponent, NtDraggableDropEvent, NtDraggableDropOnTargetEvent, NtDraggableMoveEvent } from '@app/shared';
import { AuthUser, AuthService, MediaFilter } from '@app/features/auth';
import { ExportService, ProjectManagerService, StoreService, ToolService } from './../../services';
import { Color, Comment, ICommentData, Drawing, ProgressInfo, Project, UserState, Media, Clip, Metadata } from './../../types';
import { SequencerComponent } from './../sequencer/sequencer.component';
import { EditCommentComponent } from './../comment/edit-comment/edit-comment.component';
import { CommentComponent } from './../comment/comment.component';
import { DrawingDialogComponent } from './../drawing/drawing-dialog/drawing-dialog.component';
import { LoginRegisterFlowDialogComponent } from '@app/features/auth';
import { CommentThreadDialogComponent } from './../comment';
import { MatMenuTrigger } from '@angular/material/menu';
import { CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { TrackAddDialogComponent } from './track-add-dialog/track-add-dialog.component';
import { NtAudioTrackNode } from '@app/core/audio/types/nt-audio-track-node';
import { ToolMode } from '../../types/tool-mode';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'nt-track',
    templateUrl: './track.component.html',
    styleUrls: ['./base/base-track.component.scss', './base/base-track-responsive.component.scss', './track.component.scss', './track-responsive.component.scss']
})
export class TrackComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    private readonly HOTKEY_COMBO_PASTE = 'mod+v';

    protected readonly HOTKEY_MAP = [
        new Hotkey('r', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.mergeComments();
            return true;
        }, undefined, 'Creates a range by merging the closest comments')
    ];

    @Input('media') media: Media;

    @ViewChild('newCommentContainer', { read: ViewContainerRef }) commentsContainerRef: ViewContainerRef;
    @ViewChild('rangeSelector') rangeSelector: ElementRef<HTMLDivElement>;
    @ViewChild('volumeTrigger') volumeTrigger: MatMenuTrigger;
    @ViewChild('trackColorTrigger') trackColorTrigger: MatMenuTrigger;
    @ViewChild('trackTrigger') trackTrigger: MatMenuTrigger;

    @ViewChild('clips') clips: ElementRef<HTMLDivElement>;

    @ViewChildren('comment') commentViews: QueryList<CommentComponent>;

    @HostBinding('class.selected')
    public isSelected: boolean;

    private _isCollapsed$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isCollapsed$: Observable<boolean> = this._isCollapsed$$.asObservable();
    @HostBinding('class.collapsed')
    private get isCollapsed(): boolean { return this._isCollapsed$$.value };
    public _commentCollapsed$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public commentCollapsed$: Observable<boolean> = this._commentCollapsed$$.asObservable();
    public _drawingCollapsed$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public drawingCollapsed$: Observable<boolean> = this._drawingCollapsed$$.asObservable();

    public isAudioReady: boolean = false;
    public isReady: boolean = false;

    public uploadProgress: number;
    public uploadProgressDescription: string;

    public commentRepliesCount$: Observable<{[x:string]:number}>;

    @Output('ready') onReady: EventEmitter<boolean> = new EventEmitter();
    @Output('readyProgress') readyProgress: EventEmitter<HttpProgressEvent> = new EventEmitter();
    @Output('mediaBeingLoaded') mediaBeingLoaded: EventEmitter<ProgressInfo> = new EventEmitter();

    public get muted(): boolean { return this.isAudioReady ? this.audio.player.muteTrack(this.media.uuid) : undefined; }

    public get soloed(): boolean { return this.isAudioReady ? this.audio.player.soloTrack(this.media.uuid) : undefined; }

    public get volume(): number { return this.isAudioReady ? this.audio.player.trackVolume(this.media.uuid) : undefined; }
    public set volume(value: number) { if (value !== undefined) { this.audio.player.trackVolume(this.media.uuid, value); } }

    public comments$: Observable<Array<Comment>>;
    public drawings$: Observable<Array<Drawing>>;
    public drawings: Array<Drawing>;

    public trackNode: NtAudioTrackNode;

    public commentsOffsetY: Record<number, number>;
    public commentsHeight: number;
    public drawingsHeight: number;

    //#region Flow Comment Positioning
    public levels: number[] = [];
    //#endregion

    // #region range comments
    public markedDragTracking = {
        id: 0
    };
    public leftSideWidth: number = 0;
    public rangeStart: number = 0;
    public rangeWidth: number = 0;
    public rangeCreationInProcess: boolean = false;
    public rangeCreationInProcessEvent = null;
    public minRangeTime: number = 0.5;
    public direction: string = 'right';
    // #endregion

    // #region markers drag/drop
    private initialTimecode: number = 0;
    private initialTimecodeEnd: number = 0;
    // #endregion

    mousedown$;
    mouseup$;
    sub: any;

    //#region Double Tab
    public click$$ = new Subject<MouseEvent>()
    public doubleTab$ = this.click$$.pipe(
        buffer(this.click$$.pipe(debounceTime(250))),
        map((list: Array<PointerEvent>) => ({ length: list.length, event: list[list.length - 1]})),
        filter(item => item.length === 2),
        map(item => item.event)
    )
    //#endregion

    public project$: Observable<Project>;

    protected userState$: Observable<UserState>;

    protected _destroy: Subject<void> = new Subject();
    private _subscriptions: Record<string, Subscription> = {};

    constructor(
        public readonly cdr: ChangeDetectorRef,
        protected readonly dialog: MatDialog,
        public viewContainerRef: ViewContainerRef,
        protected readonly hotkeys: HotkeysService,

        protected readonly parent: SequencerComponent,
        protected readonly store: StoreService,
        protected auth: AuthService,
        public readonly toolService: ToolService,
        private readonly exportService: ExportService,

        public readonly audio: AudioService,
        public readonly pms: ProjectManagerService,
    ) {  }

    //#region Lifecycle
    ngOnInit(): void {
        this.project$ = this.store.project.get(this.media.projectId);
        combineLatest([this.pms.ui.selectedTrackUuid$, this.pms.ui.clipboard$]).pipe(takeUntil(this._destroy)).subscribe((result) => {
            const [uuid,clipboard] = result;
            this.isSelected = uuid === this.media.uuid;

            this.clearOwnPasteHotkey();

            if(this.isSelected && clipboard?.parent === this.media.uuid) {
                const hk = new Hotkey([this.HOTKEY_COMBO_PASTE], this.onPaste, undefined, 'Paste clip at playhead');
                this.hotkeys.add(hk);
            }
        });
        this.doubleTab$.pipe(takeUntil(this._destroy)).subscribe((event: MouseEvent) => {
            const offsetX = event.clientX - this.clips.nativeElement.offsetLeft;
            const newComment: Comment = new Comment({
                project_id: this.media.projectId,
                media_id: this.media.id,
                comment: '',
                timecode: offsetX * this.pms.ui.pixelDuration
            } as ICommentData);
            if(this.pms.ui.isSmallDesign) this.pms.ui.journalMode = true;
            setTimeout(() => this.pms.eventBus.trigger('CreateComment', this.media.id));
        });

        if (this.media.fMainDl) {
            this.audio.player.duration$.pipe(takeUntil(this._destroy)).subscribe((duration: number) => {
                if (this.isAudioReady ) {
                    const buffer = this.trackNode?.buffer;
                    // if(buffer) this.drawWaveform(buffer, this.trackNode.duration, this.media.color);
                }
            });
            this.loadTrack();
        } else {
            this.isAudioReady = this.isReady = true;
        }

        this.comments$ = this.store.comment.list({ projectId: this.media.projectId, mediaId: this.media.id, parentId: null }, 'timecode').pipe(
            switchMap((aoc: Array<Observable<Comment>>) => combineLatest(aoc).pipe(defaultIfEmpty([] as Array<Comment>)))
        );

        this.commentRepliesCount$ = this.comments$.pipe(
            switchMap((comments: Array<Comment>) => {
                return combineLatest(comments.map((comment: Comment) => {
                    return this.store.comment.list({ projectId: this.media.projectId, mediaId: this.media.id, parentId: comment.id }, 'createdAt').pipe(
                        switchMap((aor: Array<Observable<Comment>>) => combineLatest(aor).pipe(defaultIfEmpty([] as Array<Comment>))),
                        map((replies: Array<Comment>) => ({ [comment.uuid]: replies.length })),
                    )
                })).pipe(
                    map((v => v.reduce((sum, curr) => Object.assign(sum, curr), {})))
                );
            })
        );

        // this.comments$.pipe(take(1)).subscribe((comments: Array<Comment>) => comments.length ? this.onOpenComment(comments[0]) : null);

        this.drawings$ = this.store.drawing.list({ projectId: this.media.projectId, mediaId: this.media.id }, 'timecode').pipe(
            switchMap((aoc: Array<Observable<Drawing>>) => combineLatest(aoc).pipe(defaultIfEmpty([] as Array<Drawing>))),
        );

        // @todo Fix this
        this.drawings$.pipe(takeUntil(this._destroy)).subscribe((drawings: Array<Drawing>) => {
            this.drawings = drawings;
        });

        this.store.project.get(this.media.projectId).pipe(first()).subscribe((project: Project) => {
            //   this.project = project;
        });

        this.hotkeys.add(this.HOTKEY_MAP);

        this.showUploadProgress()
    }

    ngAfterViewInit(): void {
        if (this.commentViews && !this._subscriptions.commentViews) {
            this.pms.ui.pixelDuration$.pipe(takeUntil(this._destroy)).subscribe((pixelDuration: number) => {
                this.generateCommentLevels(pixelDuration);
                this._subscriptions.commentViews = this.commentViews.changes.subscribe((value: QueryList<CommentComponent>) => {
                    this.generateCommentLevels(pixelDuration);
                });
            });
        }

        // this.pms.ui.pixelDuration$.pipe(takeUntil(this._destroy)).subscribe((pixelDuration: number) => {
        //     this.generateCommentLevels(pixelDuration);
        // });

        //#region Range Comment
        this.mousedown$ = fromEvent(this.clips.nativeElement, 'mousedown').pipe(filter((event) => this.pms.ui.toolMode === ToolMode.SELECT));
        this.mouseup$ = fromEvent(this.clips.nativeElement, 'mouseup').pipe(filter((event) => this.pms.ui.toolMode === ToolMode.SELECT));
        this.mouseup$.subscribe((event) => {
            this.registerMouseEvents();
            this.onMouseUpInWaveform(event);
        })
        this.mousedown$.subscribe((event) => {
            this.onMouseDownInWaveform(event);
        });

        this.registerMouseEvents();
        //#endregion

        // this.comments$.subscribe((comments: Array<Comment>) => {
        //   this.comments = comments;
        // });

        this.pms.eventBus.on('CreateComment').pipe(
            takeUntil(this._destroy),
            filter((mediaId: number) =>  !this.pms.ui.journalMode && this.media.id === mediaId)
        ).subscribe((mediaId: number) => {
            const newComment: Comment = new Comment({
                project_id: this.media.projectId,
                media_id: this.media.id,
                comment: '',
                timecode: this.audio.player.currentTime
            } as ICommentData);
            this.loadEditCommentComponent(newComment, this.audio.player.currentTime / this.pms.ui.pixelDuration, 0);
        });

        this.pms.eventBus.on('CreateMarker').pipe(
            takeUntil(this._destroy),
            filter((mediaId: number) => !this.pms.ui.journalMode && this.media.id === mediaId)
        ).subscribe((mediaId: number) => {
            const newComment: Comment = new Comment({
                project_id: this.media.projectId,
                media_id: this.media.id,
                comment: '',
                timecode: this.audio.player.currentTime
            } as ICommentData);
            this.store.comment.save(newComment).subscribe();
            this.generateCommentLevels(this.pms.ui.pixelDuration);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.media instanceof SimpleChange) {
            if(this.isAudioReady) {
                this.volume = this.media.volume;
            }
        }
    }

    //   updateCommentsHeightVerticalView(): void {
    //     setTimeout(() => {
    //       const styles = getComputedStyle(this.commentsTabContent.nativeElement);
    //       if (this.commentsTabContent) {
    //         this.commentsHeight = parseInt(styles.height) + 32;
    //       }
    //     }, 250);
    //   }

    ngOnDestroy(): void {
        this._destroy.next()
        this._destroy.complete();
        Object.keys(this._subscriptions).map(key => this._subscriptions[key])
            .filter((subscription: Subscription) => subscription instanceof Subscription)
            .forEach((subscription: Subscription) => subscription.unsubscribe());

        this.HOTKEY_MAP.map((hk: Hotkey) => hk.combo).forEach((combo: string) => this.hotkeys.remove(this.hotkeys.get(combo)));
        this.clearOwnPasteHotkey();
        this.audio.player.removeTrack(this.media.uuid);
        delete this.trackNode;
    }
    //#endregion

    private loadTrack(fMainDl: URL = this.media.fMainDl) {
        this.audio.player
            .addTrack(this.media.uuid, fMainDl, this.media.metadata, true)
            .subscribe((trackNode: NtAudioTrackNode | HttpProgressEvent) => {
                if (trackNode instanceof NtAudioTrackNode) {
                    this.trackNode = trackNode;
                    this.isAudioReady = true;
                    // if (this.isAudioReady) {
                        this.volume = this.media.volume;
                        console.info(`Track's Audio for ${this.media.id} ready!`);
                        this.isReady = this.isAudioReady && true;
                        console.info(`Track's Waveform for ${this.media.id} ready!`);
                        this.onReady.emit(this.isReady);

                        if (this.auth.currentUser) {
                            this.userState$ = this.pms.state.state$;
                            this.setState();
                        }
                    // }
                } else {
                    this.readyProgress.emit(trackNode);
                }
            });
    }

    private clearOwnPasteHotkey() {
        const pasteSymbol = Hotkey.symbolize(this.HOTKEY_COMBO_PASTE);
        const currHk = this.hotkeys.hotkeys.filter(v => v.combo.indexOf(pasteSymbol) > -1 && v.callback === this.onPaste)
        if (currHk.length > 0) this.hotkeys.remove(currHk);
    }

    private showUploadProgress() {
        let obs$ = this.store.media.getUploadProgress(this.media.id);

        if (obs$) {
            this.uploadProgress = 0;
            this.uploadProgressDescription = this.uploadProgress.toString() + '%';
            obs$.subscribe((progress: HttpProgressEvent & { status?: string }) => {
                if (progress) {
                    if (progress.loaded <= progress.total && !progress.status) {
                        this.uploadProgress = Math.trunc(progress.loaded / progress.total * 100);
                        this.uploadProgressDescription = this.uploadProgress.toString() + '%';
                    } else {
                        this.uploadProgress = 100;
                        if (progress.status != 'processed') {
                            this.uploadProgressDescription = 'processing...';
                        } else {
                            this.uploadProgress = 100;
                            this.uploadProgressDescription = 'completed';
                            setTimeout(() => {
                                delete this.uploadProgress;
                                delete this.uploadProgressDescription;
                            }, 5000);
                        }
                    }

                    this.mediaBeingLoaded.emit({ id: this.media.id, progress: progress.loaded / progress.total * 100, status: progress.status } as ProgressInfo);
                }
            });
        }
    }

    exportTrackAudio() {
        this.audio.player.exportTrack(this.media.uuid, this.media.name);
    }

    trackByClipUuid(index: number, clip: Clip) {
        return clip.uuid;
    }

    trackByCommentId(index: number, comment: Comment) {
        return comment.id;
    }

    trackByDrawingId(index: number, drawing: Drawing) {
        return drawing.id;
    }

    //#region Actions
    toggleCommentJournal() {
        this.pms.ui.journalMode = !this.pms.ui.journalMode;
    }

    doMute() {
        this.audio.player.toggleMuteTrack(this.media.uuid);
    }

    doSolo() {
        this.audio.player.toggleSoloTrack(this.media.uuid);
    }
    //#endregion

    //#region Event Listener
    onMouseDownInWaveform(event: MouseEvent) {
        if (event.which && this.markedDragTracking.id == 0) {
            let pixelLeft = event.clientX - (this.leftSideWidth);
            this.rangeStart = pixelLeft;
            this.rangeSelector.nativeElement.style.left = pixelLeft + 'px';
            this.rangeSelector.nativeElement.style.width = 0 + 'px';
            this.rangeCreationInProcess = true;
        } else {
            this.rangeCreationInProcess = false;
            this.rangeCreationInProcessEvent = null;
        }
    }
    onVolumeClicked(event) {
        this.volumeTrigger.openMenu();
    }

    onTrackColorClicked(event) {
        this.trackColorTrigger.openMenu();
    }

    onWaveformClicked(event: MouseEvent) {
        const offsetX = event.clientX - this.clips.nativeElement.offsetLeft - this.pms.ui.scrollOffset;
        const toTime = offsetX * this.pms.ui.pixelDuration;
        const toTimeDelta = Math.abs(this.audio.player.currentTime - toTime);
        this.audio.player.currentTime = offsetX * this.pms.ui.pixelDuration;
        if(toTimeDelta > 0.5) {
            this.pms.state.save(this.media.project.id, 'timecode', this.audio.player.currentTime).subscribe();
        }
    }

    onPaste = (event: KeyboardEvent, combo: string): boolean => {
        const clip: Clip = this.pms.ui.clipboard?.clip;
        console.log("Paste clip", clip, ' at ', this.audio.player.currentTime);
        if(clip instanceof Clip) {
            clip.position = this.audio.player.currentTime;
            this.clearOwnPasteHotkey();
            if(!this.media.metadata.clips.find((c: Clip) => c.uuid === clip.uuid)) {
                this.media.metadata.clips.push(clip);
                // this.updateClips(this.media.metadata.clips);
                this.onClipChanged(clip);
                this.pms.ui.selectedClipUid = clip.uuid;
            }
            this.pms.ui.clipboard = undefined;
        } else {
            console.error('Clipboard is not a clip');
        }

        return true;
    }

    onClipSplit(event: Array<Clip>) {
        const [original, left, right] = event;
        if (!this.media.metadata) this.media.metadata = new Metadata({ effects: [], clips: [] });
        const clips = this.media.metadata.clips || [];
        const index = clips.findIndex((clip: Clip) => clip === original);

        if (index < 0 && clips.length > 0) {
            throw Error('Could not find targeted clip to split.');
        }

        clips.splice(index, 1, left, right);

        this.media.metadata.clips = clips;
        this.updateClips(clips);

        this.store.media.save(this.media, { publicKey: this.media.project.publicKey }).pipe(take(1)).subscribe((saved: Media) => {
            // this.pms.ui.selectedClip = saved.metadata.clips[index] || undefined;
        });
    }

    onClipDelete(event: Clip) {
        if (!this.media.metadata) this.media.metadata = new Metadata({ effects: [], clips: [] });
        const clips = this.media.metadata.clips || [];
        const index = clips.findIndex((clip: Clip) => clip === event);
        if (index < 0 && clips.length > 0) {
            throw Error('Could not find targeted clip to delete.');
        }

        clips.splice(index, 1);

        this.media.metadata.clips = clips;
        this.updateClips(clips);

        this.store.media.save(this.media, { publicKey: this.media.project.publicKey }).subscribe((saved: Media) => {
            this.trackNode.updateClips(clips);
        });
    }

    onClipMove(event, clip: Clip) {
        this.pms.ui.isPlayheadAttached = false;
        clip.position = Math.max(0, clip.position + (event.movementX * this.pms.ui.pixelDuration));
    }

    onClipChanged(clip: Clip) {
        this.store.media.save(this.media, { publicKey: this.media.project.publicKey }).subscribe((saved: Media) => {
            this.updateClips(this.media.metadata.clips);
        });
    }

    updateClips(clips: Clip[]) {
        const isPlaying = this.audio.player.isPlaying;
        if(isPlaying) this.audio.player.pause();
        this.trackNode.updateClips(clips);
        if(isPlaying) this.audio.player.play();
    }
    //#endregion


    //#region Track menu options
    onTrackMenuClicked(event): void {
        this.trackTrigger.openMenu();
    }

    exportToHeadliner(mediaURL: string): void {
        let HeadlinerURLEndpoint: string = 'https://api.headliner.app/api/v1/url-generator/audio-wizard/redirect?widgetKey=WnRTgWtLQTVMzM7MJWx8NRWbJ&audioUrl=' + encodeURIComponent(mediaURL);
        window.open(HeadlinerURLEndpoint, '_blank');
    }

    updateTrack(): void {
        this.dialog.open(TrackAddDialogComponent, {
            autoFocus: false,
            panelClass: 'track-add-dialog',
            width: '700px',
            data: {
              project: this.media.project,
              allowMultiple: false,
              mediaFilter: this.media.mediaType === 'audio' ? MediaFilter.Audio : MediaFilter.Video,
            }
        }).afterClosed().subscribe((files: Array<{ file: File, buffer: AudioBuffer }>) => {
            if(files && files.length === 1) {
                const file = files[0];
                const mediaUpload = new Media({ id: this.media.id, project_id: this.media.projectId });
                this.pms.media.upload(mediaUpload, file.file, { publicKey: this.media.project.publicKey }).subscribe();
                this.showUploadProgress();
                this.loadTrack(new URL(URL.createObjectURL(file.file)));
                this.cdr.detectChanges();
            }
        });
    }

    deleteTrack(): void {
        this.dialog.open(ConfirmationDialogComponent, {
            autoFocus: false,
            panelClass: 'confirmation-dialog',
            width: '300px',
            data: {
                title: 'Delete track',
                message: 'Are you sure you want to delete this track?',
                cancelLabel: 'Cancel',
                acceptLabel: 'Yes'
            }
        })
            .afterClosed()
            .subscribe((confirmed: boolean) => {
                if (confirmed) {
                    this.updateState(this.media.id);
                    this.store.media.delete(this.media).subscribe();
                } else {
                    // do something else
                }
            });
    }
    //#endregion

    //#region Track Properties
    onNameKeypress(event) {
        if(event.code == "Enter") {
            event.preventDefault();
            (event.target as HTMLSpanElement).blur();
        }
    }
    setName(event) {
        const name = (event.target as HTMLSpanElement).textContent;
        if (name !== this.media.name) {
            this.media.name = name;
            this.store.media.save(this.media, { publicKey: this.media.project.publicKey }).subscribe();
        }
    }
    setColor(color: string) {
        if (this.media.color !== color as Color) {
            this.media.color = color as Color;
            this.store.media.save(this.media, { publicKey: this.media.project.publicKey }).subscribe();
        }
    }
    setIsActive(active: boolean) {
        if(this.media.isActive !== active) {
            this.media.isActive = active;
            this.store.media.save(this.media, { publicKey: this.media.project.publicKey }).subscribe();
        }
    }
    //#endregion

    //#region Comment
    //#region Range Comment
    registerMouseEvents() {
        try {
            this.sub.unsubscribe();
        } catch (err) { } finally { }

        let mousemove$ = fromEvent(this.clips.nativeElement, 'mousemove').pipe(filter((event) => this.pms.ui.toolMode === ToolMode.SELECT));
        mousemove$ = mousemove$.pipe(skipUntil(this.mousedown$));
        mousemove$ = mousemove$.pipe(takeUntil(this.mouseup$));
        this.sub = mousemove$.subscribe((event: Event) => {
            this.onMouseMoveInWaveform(event as MouseEvent);
        })
    }

    onMouseMoveInWaveform(event: MouseEvent) {
        if (event.which && this.markedDragTracking.id == 0) {
            let currentLeft = event.clientX - (this.leftSideWidth); // PENDING!

            if (this.rangeStart < currentLeft) {
                this.direction = 'right';
                this.rangeWidth = currentLeft - this.rangeStart;
                this.rangeSelector.nativeElement.style.width = this.rangeWidth + 'px';
                this.rangeSelector.nativeElement.style.display = 'block';
            } else {
                this.direction = 'left';
                this.rangeWidth = this.rangeStart - currentLeft;
                this.rangeSelector.nativeElement.style.width = this.rangeWidth + 'px';
                this.rangeSelector.nativeElement.style.left = currentLeft + 'px';
                this.rangeSelector.nativeElement.style.display = 'block';
            }

            if (this.rangeCreationInProcess) {
                this.rangeCreationInProcessEvent = event;
            }
        } else {
            this.rangeCreationInProcess = false;
            this.rangeCreationInProcessEvent = null;
        }
    }

    onMouseUpInWaveform(event: MouseEvent) {
        if (event.which && this.markedDragTracking.id == 0) {
            const offsetX = event.clientX - this.clips.nativeElement.offsetLeft;
            this.rangeStart = offsetX - this.pms.ui.scrollOffset;
            this.rangeWidth = parseInt(this.rangeSelector.nativeElement.style.width);

            // calculate timecode and timecode_end
            // let timecode = this.audio.player.currentTime;
            // let timecode_end = this.audio.player.currentTime + (this.rangeWidth * this.pms.ui.pixelDuration);
            let timeRange = this.rangeWidth * this.pms.ui.pixelDuration;

            if (Math.abs(timeRange) < this.minRangeTime) {
                this.rangeSelector.nativeElement.style.display = 'none';
                return;
            } else {
                if (this.direction == 'right') {
                    this.rangeStart = this.rangeStart - this.rangeWidth;
                }

                let timecode = this.rangeStart  * this.pms.ui.pixelDuration;
                let timecode_end = timecode + timeRange;

                this.rangeSelector.nativeElement.style.display = 'none';

                if (this.rangeWidth > 0) {
                    // save comment
                    let newComment: Comment = new Comment({
                        project_id: this.media.projectId,
                        media_id: this.media.id,
                        comment: '',
                        timecode: timecode,
                        timecode_end: timecode_end,
                        id: 0
                    } as ICommentData);
                    this.loadEditCommentComponent(newComment, this.rangeStart, 0);
                    //

                    this.rangeCreationInProcess = false;
                    this.rangeCreationInProcessEvent = null;
                }
            }
        }
    }
    //#endregion

    onMarkerChanged(comment: Comment) {
        this.store.comment.save(comment).subscribe();
        this.generateCommentLevels(this.pms.ui.pixelDuration);
    }

    onOpenComment(comment: Comment, forReply: boolean = false) {
        this.dialog.open(CommentThreadDialogComponent, {
            autoFocus: false,
            panelClass: 'comment-thread-dialog',
            width: '320px',
            data: {
                comment: comment,
                focusReply: forReply
            }
        }).afterClosed().subscribe();
    }

    waveformDoubleClick(event: MouseEvent): void {
        // const offsetX = event.clientX - this.clips.nativeElement.offsetLeft;
        // const newComment: Comment = new Comment({
        //     project_id: this.media.projectId,
        //     media_id: this.media.id,
        //     comment: '',
        //     timecode: offsetX * this.pms.ui.pixelDuration
        // } as ICommentData);
        // if(this.pms.ui.isSmallDesign) this.pms.ui.journalMode = true;
        // this.pms.eventBus.trigger('CreateComment', this.media.id);
    }

    loadEditCommentComponent(comment: Comment, offsetX: number, offsetY: number) {

        this._isCollapsed$$.next(false);
        this._commentCollapsed$$.next(false);

        let containerRef: ViewContainerRef = this.commentsContainerRef;

        containerRef.clear();

        let componentRef: ComponentRef<EditCommentComponent> = containerRef.createComponent(EditCommentComponent);

        if (componentRef) {
            if (comment) {
                componentRef.instance.comment = comment;
                // componentRef.instance.anonymousName = comment.anonName;
                // componentRef.instance.isPublicProject = !this.auth.currentUser;
                componentRef.instance.offsetX = offsetX;
                componentRef.instance.offsetY = offsetY;
                componentRef.instance.editComplete.asObservable().pipe(take(1)).subscribe((comment: Comment) => {
                    this.removeComponent();

                    this.comments$.pipe(
                        filter((comments: Array<Comment>) => {
                            return comments.map((c: Comment) => c.uuid === comment.uuid ).reduce((s,c) => s || c, false)
                        }),
                        take(1)
                    ).subscribe((comments: Array<Comment>) => {
                        setTimeout(() => {
                            const parentElement = document.querySelector('nt-project-view nt-sequencer');
                            const commentElement = document.getElementById('track-' + comment.uuid);

                            const parentRect = parentElement.getBoundingClientRect();
                            const elementRect = commentElement.getBoundingClientRect();

                            if(elementRect.top < parentRect.top || elementRect.bottom > parentRect.bottom) {
                                setTimeout(() => commentElement.querySelector('[timecode]').scrollIntoView({ behavior: 'smooth' }), 150);
                            }
                        },25);
                    });
                });
            }
        }
    }

    removeComponent(): void {
        this.commentsContainerRef.clear();
    }
    //#endregion

    //#region Drawing
    private getMaxRowIndex(): number {
        if (this.drawings.length == 0) {
            return 1;
        }
        return Math.max(...this.drawings.map(function (o: Drawing) { return o.rowIndex; }));
    }

    getRows(): Array<number> {
        if (this.drawings.length == 0) {
            return [1];
        }
        var maxRow = this.getMaxRowIndex();
        var arr = Array.apply(null, Array(maxRow));
        return arr.map(function (_, i) { return i + 1; });
    }

    getDrawingsByRow(index: number): Array<Drawing> {
        if (this.drawings.length == 0) {
            return [];
        }
        return this.drawings.filter(function (d: Drawing) { return d.rowIndex === index; });
    }

    getNextRowIndex(eventTarget: EventTarget): number {
        let row_index: number = 0;

        if ((eventTarget as Element).classList.contains('drawingRow')) {
            row_index = parseInt((eventTarget as Element).id);
        } else {
            row_index = this.getMaxRowIndex() + 1;
        }

        return row_index;
    }

    onDoubleClickDrawingsPane(event: MouseEvent): void {
        if (this.auth.currentUser) {
            this.executeOnDoubleClickDrawingsPane(event);
        } else {
            this.dialog.open(LoginRegisterFlowDialogComponent, {
                autoFocus: false,
                panelClass: 'login-register-flow-dialog',
                width: '616px',
                data: { project: this.media.project, resource: 'drawing' }
            }).afterClosed().pipe().subscribe((user: AuthUser) => {
                if (user) {
                    this.executeOnDoubleClickDrawingsPane(event);
                }
            });
        }
    }

    executeOnDoubleClickDrawingsPane(event: MouseEvent): void {
        const offsetX = event.clientX - this.clips.nativeElement.offsetLeft - this.pms.ui.scrollOffset;
        // const offsetX = event.clientX - this.clips.nativeElement.offsetLeft;
        if (this.pms.ui.drawingType == 'simvec') {
            this.dialog.open(DrawingDialogComponent, {
                autoFocus: false,
                panelClass: 'drawing-dialog',
                width: '50%',
                height: '458px'
            }).afterClosed().subscribe((data: string) => {
                if(data) {
                    this.saveDrawing(event.target, offsetX, data);
                }
            });
        } else {
            this.saveDrawing(event.target, offsetX);
        }
    }

    onDrawingDropFromTools(event) {
        if (this.auth.currentUser) {
            this.executeOnDrawingFromTools(event);
        } else {
            this.dialog.open(LoginRegisterFlowDialogComponent, {
                autoFocus: false,
                panelClass: 'login-register-flow-dialog',
                width: '616px',
                data: { project: this.media.project, resource: 'drawing' }
            }).afterClosed().pipe().subscribe((user: AuthUser) => {
                if (user) {
                    this.executeOnDrawingFromTools(event);
                }
            });
        }
    }

    executeOnDrawingFromTools(event) {
        event = (event as NtDraggableDropOnTargetEvent<unknown>);
        // const offsetX = event.offsetX - this.clips.nativeElement.offsetLeft - this.pms.ui.scrollOffset;
        const offsetX = event.offsetX;
        (this.pms.ui.drawingType !== 'simvec' ? of(null) :
            this.dialog.open(DrawingDialogComponent, {
                autoFocus: false,
                panelClass: 'drawing-dialog',
                width: '50%',
                height: '480px',
            }).afterClosed()
        ).subscribe((data: string) => {
            this.saveDrawing(event.target, offsetX, data);
        })
    }

    saveDrawing(eventTarget: EventTarget, offsetX: number, data: string = null) {
        const row_index = this.getNextRowIndex(eventTarget);
        const media_id = this.media.id;
        const project_id = this.media.projectId;

        let newDrawing: Drawing = new Drawing({
            type: this.pms.ui.drawingType,
            project_id,
            media_id,
            timecode: offsetX * this.pms.ui.pixelDuration,
            color: this.pms.ui.drawingColor,
            row_index,
            data: data
        });

        this.store.drawing.save(newDrawing, { publicKey: this.media.project.publicKey }).subscribe((savedDrawing: Drawing) => {
            this.toolService.newDrawingId = savedDrawing.id;
        });
    }

    onMoveDrawing(moveEvent: NtDraggableMoveEvent, drawing: Drawing): void {
        drawing.timecode = this.limitTimecode(drawing.timecode + (moveEvent.movementX * this.pms.ui.pixelDuration), undefined, this.trackNode.duration);
    }

    onClickDrawingPane(event: MouseEvent): void {
        if ((event.target as Element).classList.contains('drawingsPane') ||
            (event.target as Element).classList.contains('drawingRow')) {
            this.toolService.idSelectedDrawing = 0;
        }
    }
    //#endregion

    //#region Flow Comment Positioning
    private getCommentsHeight(beforeLevel: number = Infinity): number {
        return this.levels.slice(0, beforeLevel).reduce((sum, curr) => sum + curr, 0);
    }

    protected generateCommentLevels(pixelDuration: number): void {
        this.levels = [];
        this.commentViews.forEach((currentComment: CommentComponent, index) => {
            const currentTimecodeEndPositioning = currentComment.comment.timecode + (currentComment.width * pixelDuration);

            let levelSlotFound: boolean = false;
            for (let i = 0; i <= index; i++) {
                // We assume it'll fit in the level, we'll set false if overlap is found
                levelSlotFound = true;

                //#region Check if the current comment would overlap any comment already placed in the level so far
                const commentsInLevel = this.getCommentsInLevel(i, index);
                // First comment that was in a level has prio on that level, otherwise it create issues on scale down
                if (index > 0 && commentsInLevel[0]?.comment?.id !== currentComment.comment.id) {
                    commentsInLevel.forEach((existingCommentInLevel) => {
                        const existingTimecodeEndPositioning = existingCommentInLevel.comment.timecode + (existingCommentInLevel.width * pixelDuration);

                        const currentStartsInExisting = existingCommentInLevel.comment.timecode <= currentComment.comment.timecode
                            && existingTimecodeEndPositioning >= currentComment.comment.timecode;
                        const existingStartsInCurrent = existingCommentInLevel.comment.timecode >= currentComment.comment.timecode
                            && existingCommentInLevel.comment.timecode <= currentTimecodeEndPositioning;
                        const currentOverlapsExisting = currentStartsInExisting || existingStartsInCurrent;

                        if (currentOverlapsExisting) {
                            levelSlotFound = false;
                            return; // We have an overlap, no need to look further
                        }
                    });
                }
                //#endregion

                // Slot was found in the level, exit the loop
                if (levelSlotFound) {
                    currentComment.level = i;
                    if (this.levels.length <= currentComment.level + 1) {
                        this.levels.push(currentComment.height);
                    } else if (this.levels[currentComment.level] < currentComment.height) {
                        this.levels[currentComment.level] = currentComment.height;
                    }
                    break;
                }
            }

            //   this.cdr.detectChanges();
        });

        this.commentsHeight = this.getCommentsHeight();
        this.commentsOffsetY = this.commentViews.reduce((sum, item: CommentComponent) => ({ ...sum, [item.comment.id]: this.getCommentOffsetY(item.comment.id) }), {});
        this.cdr.detectChanges();
    }
    //#endregion

    getAdjacentComments(): CommentComponent[] {
        let previousComment: CommentComponent = this.commentViews.find((c: CommentComponent) => c.comment.timecode < this.audio.player.currentTime && c.comment.userId == this.auth.currentUser.id);
        let nextComment: CommentComponent = this.commentViews.find((c: CommentComponent) => c.comment.timecode >= this.audio.player.currentTime && c.comment.userId == this.auth.currentUser.id);

        if (previousComment && nextComment) {
            return [previousComment, nextComment];
        }

        return [];
    }

    private getCommentOffsetY(id: number): number {
        if (this.commentViews && this.commentViews.length) {
            const comment = this.commentViews.filter((cc) => cc.comment.id === id).reduce((sum, curr) => curr, null);
            if (comment) {
                return this.getCommentsHeight(comment.level);
            }
        }

        return 0;
    }

    /**
     * Get the comments in a level up to a certain index
     *
     * @param level The level to look in
     * @param before The index to stop at
     * @returns An array of CommentComponent
     */
    private getCommentsInLevel(level: number, before: number): Array<CommentComponent> {

        return this.commentViews.filter((cc: CommentComponent, index: number) => cc.level === level && index < before);
    }

    private updateState(mediaId: number): void {
        let tracks = [];

        this.userState$.pipe(first()).subscribe((userState: UserState) => {
            if (userState.state.tracks && userState.state.tracks.length > 0) {
                tracks = userState.state.tracks;

                let trackIndex = tracks.findIndex(t => t.id == this.media.id);

                if (trackIndex >= 0) {
                    tracks.splice(trackIndex, 1);
                    this.pms.state.save(this.media.projectId, 'tracks', tracks).subscribe();
                }
            }
        });
    }

    mergeComments(): void {
        let adjacentComments: CommentComponent[] = this.getAdjacentComments();

        if (adjacentComments && adjacentComments.length == 2) {
            let mergedComment = adjacentComments.map(function (c) { return c.comment.comment; }).join('\n');

            let newComment = new Comment(
                {
                    id: 0,
                    comment: mergedComment,
                    media_id: this.media.id,
                    project_id: this.media.projectId,
                    timecode: adjacentComments[0].comment.timecode,
                    timecode_end: adjacentComments[1].comment.timecodeEnd ? adjacentComments[1].comment.timecodeEnd : adjacentComments[1].comment.timecode
                } as ICommentData
            );

            this.store.comment.save(newComment).subscribe();

            // TODO: merge the replies

            // deleting original comments
            this.store.comment.delete(adjacentComments[0].comment).subscribe();
            this.store.comment.delete(adjacentComments[1].comment).subscribe();
        }
    }

    limitTimecode(timecode: number, min = 0, max?) {
        min = isNaN(min) || min === null ? 0 : min;
        max = isNaN(max) || max === null ? this.audio.player.duration : max;
        return Math.min(Math.max(min, timecode), max);
    }
    toggleIsCollapsed(collapsed: boolean = !this.isCollapsed): void {
        this._isCollapsed$$.next(collapsed);
        this.changeTrackStateAttribute('collapsed');
    }
    toggleCollapseComment() {
        this._commentCollapsed$$.next(!this._commentCollapsed$$.value);
    }
    toggleCollapseDrawing() {
        this._drawingCollapsed$$.next(!this._drawingCollapsed$$.value);
    }
    toggleMuteTrack(): void {
        this.audio.player.toggleMuteTrack(this.media.uuid);
        this.changeTrackStateAttribute('muted');
    }

    toggleSoloTrack(): void {
        this.audio.player.toggleSoloTrack(this.media.uuid);
        this.changeTrackStateAttribute('solo');
    }

    onVolumeChange(event): void {
        this.media.volume = this.volume = event.value;
        this.pms.media.save(this.media, { publicKey: this.media.project.publicKey }).subscribe();
    }

    changeTrackStateAttribute(attributeName) {
        if (this.auth.currentUser) {
            this.userState$.pipe(take(1)).subscribe((userState: UserState) => {
                const tracks = (userState.state.tracks || []).filter(v => !!v);
                const trackState = {
                    id: this.media.id,
                    solo: this.audio.player.soloTrack(this.media.uuid),
                    muted: this.audio.player.muteTrack(this.media.uuid),
                    collapsed: this.isCollapsed
                }
                if(!Array.isArray(userState.state.tracks)) { userState.state.tracks = []; }

                const track = tracks.find(t => t.id == this.media.id);
                if(track) {
                    Object.assign(track, trackState);
                } else {
                    tracks.push(trackState);
                }

                this.pms.state.save(this.media.projectId, 'tracks', tracks).subscribe();
            });
        }
    }

    setState(): void {
        this.userState$.pipe(first()).subscribe((userState: UserState) => {
            if (userState.state.tracks && userState.state.tracks.length > 0) {
                let track = userState.state.tracks.find(t => t.id == this.media.id);
                if (track) {

                    this.audio.player.soloTrack(this.media.uuid, track?.solo);
                    this.audio.player.muteTrack(this.media.uuid, track?.muted);
                    this._isCollapsed$$.next(!!track?.collapsed);
                }
            }
        });
    }

    exportAudacityLabels(): void {
        this.comments$.pipe(take(1)).subscribe((comments: Comment[]) => {
            this.exportService.exportCommentsAsAudacityLabels(this.media.name, comments);
        });
    }

    exportAuditionMarkers(): void {
        this.comments$.pipe(take(1)).subscribe((comments: Comment[]) => {
            this.exportService.exportCommentsAsAuditionMarkers(this.media.name, comments);
        });
    }

    onDragStartedStartMarker(event: CdkDragStart, timecode: number): void {
        this.initialTimecode = timecode;
    }

    onDragMovedStartMarker(event: CdkDragMove, comment: Comment): void {
        comment.timecode = this.initialTimecode + (event.distance.x * this.pms.ui.pixelDuration);
    }

    onDragEndedStartMarker(event: CdkDragEnd, comment: Comment): void {
        this.store.comment.save(comment).subscribe((commentSaved: Comment) => {
            // comment updated
        });
    };

    onDragStartedEndMarker(event: CdkDragStart, timecodeEnd: number): void {
        this.initialTimecodeEnd = timecodeEnd;
    }

    onDragMovedEndMarker(event: CdkDragMove, comment: Comment): void {
        comment.timecodeEnd = parseFloat(this.initialTimecodeEnd + (event.distance.x * this.pms.ui.pixelDuration) + '');
    }

    onDragEndedEndMarker(event: CdkDragEnd, comment: Comment): void {
        this.store.comment.save(comment).subscribe((commentSaved: Comment) => {
            // comment updated
        });
    };
}
