<header>
    <mat-icon phylactry svgIcon="phylactery"></mat-icon>
    Comments • {{ (comments$ | async)?.length || 0 }}
    <mat-icon #filterTrigger="matMenuTrigger" [matMenuTriggerFor]="filterMenu" svgIcon="filter"></mat-icon>
    <mat-menu #filterMenu="matMenu" yPosition="below" class="filter-menu">
        <span class="filter-menu-option" (click)="setSort({ by: 'timecode' })">
            <mat-icon svgIcon="check" [style.opacity]="!( (sort$ | async)?.by === 'timecode' ) ? 0 : 1 "></mat-icon>
            Timecode
        </span>
        <span class="filter-menu-option" (click)="setSort({ by: 'createdAt', desc: true })">
            <mat-icon svgIcon="check" [style.opacity]="!( (sort$ | async)?.by === 'createdAt' && (sort$ | async)?.desc === true ) ? 0 : 1"></mat-icon>
            Newest
        </span>
        <span class="filter-menu-option" (click)="setSort({ by: 'createdAt' })">
            <mat-icon svgIcon="check" [style.opacity]="!( (sort$ | async)?.by === 'createdAt' && (sort$ | async)?.desc !== true ) ? 0 : 1"></mat-icon>
            Oldest
        </span>
        <span separator></span>
        <span class="filter-menu-option" (click)="toggleFilterOnlyOwn()">
            <mat-icon svgIcon="check" [style.opacity]="!(filter$ | async)?.userId ? 0 : 1"></mat-icon>
            Only your comments
        </span>
        <span class="filter-menu-option" (click)="toggleFilterShowResolved()">
            <mat-icon svgIcon="check" [style.opacity]="(filter$ | async)?.isResolved === true ? 1 : 0"></mat-icon>
            Show Resolved
        </span>
        <span class="filter-menu-option" (click)="toggleFilterShowUnresolved()">
          <mat-icon svgIcon="check" [style.opacity]="(filter$ | async)?.isResolved === false ? 1 : 0"></mat-icon>
          Show Unresolved
      </span>
    </mat-menu>
    <mat-icon svgIcon="magnify"></mat-icon>
    <input #search type="text" (input)="setSearch(search.value)" placeholder="Search comments" />
</header>
<ng-container *ngIf="(currentMedia$ | async)">
    <header sub>
        Select Track
        <span selected #filterTrigger="matMenuTrigger" [matMenuTriggerFor]="trackSelectionMenu">
            {{ (currentMedia$ | async).name }}
        </span>
        <mat-icon svgIcon="expanded" #filterTrigger="matMenuTrigger" [matMenuTriggerFor]="trackSelectionMenu"></mat-icon>
        <mat-menu #trackSelectionMenu="matMenu" yPosition="below" class="track-selection-menu">
            <ng-container *ngFor="let media of medias$ | async">
                <span *ngIf="!media.isMain && media.status === 'processed'" class="menu-option" (click)="selectMedia(media)">
                    <mat-icon svgIcon="check" [style.opacity]="media.id !== (currentMedia$ | async).id ? 0 : 1"></mat-icon>
                    {{ media.name }}
                </span>
            </ng-container>
        </mat-menu>
    </header>
    <div #scrollWrapper scrollWrapper>
        <nt-edit-comment *ngIf="newComment" [comment]="newComment" (editComplete)="onNewComment($event)"></nt-edit-comment>
        <ng-container *ngIf="comments$ | async as comments">
            <ng-container *ngFor="let comment of comments; trackBy: trackByCommentId, let i = index">
                <nt-comment-thread #thread (cancel)="thread.collapseReply = true" [collapseReply]="true" [focusReply]="true" [comment]="comment" [search]="search$" [filter]="filter$" [sortReplies]="sort$"></nt-comment-thread>
            </ng-container>
            <div #newCommentContainer2></div>
        </ng-container>
    </div>
</ng-container>
