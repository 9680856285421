import { InjectionToken } from "@angular/core";

export const EMOJIS = new InjectionToken('EMOJIS');

// export type Emoji = Record<'htmlcode', string>;
export interface Emoji {
    index: number;
    emoji?: string;
    name?: string;
    shortname?: string;
    unicode?: string;
    html?: string;
    category?: string;
    order?: number;
    htmlcode: string;
}

export const emojis: Array<Emoji> = [
    {
        "index": 1,
        "emoji": "😀",
        "name": "grinning face",
        "shortname": ":grinning:",
        "unicode": "1f600",
        "html": "&#128512;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 1,
        "htmlcode": "&#128512"
    },
    {
        "index": 2,
        "emoji": "😃",
        "name": "grinning face with big eyes",
        "shortname": ":smiley:",
        "unicode": "1f603",
        "html": "&#128515;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 5,
        "htmlcode": "&#128515"
    },
    {
        "index": 3,
        "emoji": "😄",
        "name": "grinning face with smiling eyes",
        "shortname": ":smile:",
        "unicode": "1f604",
        "html": "&#128516;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 6,
        "htmlcode": "&#128516"
    },
    {
        "index": 4,
        "emoji": "😁",
        "name": "beaming face with smiling eyes",
        "shortname": ":grin:",
        "unicode": "1f601",
        "html": "&#128513;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 2,
        "htmlcode": "&#128513"
    },
    {
        "index": 5,
        "emoji": "😆",
        "name": "grinning squinting face",
        "shortname": ":laughing:",
        "unicode": "1f606",
        "html": "&#128518;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 8,
        "htmlcode": "&#128518"
    },
    {
        "index": 6,
        "emoji": "😅",
        "name": "grinning face with sweat",
        "shortname": ":sweat_smile:",
        "unicode": "1f605",
        "html": "&#128517;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 7,
        "htmlcode": "&#128517"
    },
    {
        "index": 7,
        "emoji": "🤣",
        "name": "rolling on the floor laughing",
        "shortname": ":rolling_on_the_floor_laughing:",
        "unicode": "1F923",
        "html": "&#x1F923;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 63.5,
        "htmlcode": "&#129315"
    },
    {
        "index": 8,
        "emoji": "😂",
        "name": "face with tears of joy",
        "shortname": ":joy:",
        "unicode": "1f602",
        "html": "&#128514;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 3,
        "htmlcode": "&#128514"
    },
    {
        "index": 9,
        "emoji": "🙂",
        "name": "slightly smiling face",
        "shortname": ":slight_smile:",
        "unicode": "1f642",
        "html": "&#128578;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 19,
        "htmlcode": "&#128578"
    },
    {
        "index": 10,
        "emoji": "🙃",
        "name": "upside-down face",
        "shortname": ":upside_down:",
        "unicode": "1f643",
        "html": "&#128579;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 45,
        "htmlcode": "&#128579"
    },
    {
        "index": 11,
        "emoji": "😉",
        "name": "winking face",
        "shortname": ":wink:",
        "unicode": "1f609",
        "html": "&#128521;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 9,
        "htmlcode": "&#128521"
    },
    {
        "index": 12,
        "emoji": "😊",
        "name": "smiling face with smiling eyes",
        "shortname": ":blush:",
        "unicode": "1f60a",
        "html": "&#128522;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 10,
        "htmlcode": "&#128522"
    },
    {
        "index": 13,
        "emoji": "😇",
        "name": "smiling face with halo",
        "shortname": ":innocent:",
        "unicode": "1f607",
        "html": "&#128519;",
        "category": "Smileys & Emotion (face-smiling)",
        "order": 67,
        "htmlcode": "&#128519"
    },
    {
        "index": 14,
        "emoji": "🥰",
        "name": "smiling face with hearts",
        "shortname": ":smiling_face_with_hearts:",
        "unicode": "1F970",
        "html": "&#x1F970;",
        "category": "Smileys & Emotion (face-affection)",
        "order": 63.5,
        "htmlcode": "&#129392"
    },
    {
        "index": 15,
        "emoji": "😍",
        "name": "smiling face with heart-eyes",
        "shortname": ":heart_eyes:",
        "unicode": "1f60d",
        "html": "&#128525;",
        "category": "Smileys & Emotion (face-affection)",
        "order": 13,
        "htmlcode": "&#128525"
    },
    {
        "index": 16,
        "emoji": "🤩",
        "name": "star-struck",
        "shortname": ":starstruck:",
        "unicode": "1F929",
        "html": "&#x1F929;",
        "category": "Smileys & Emotion (face-affection)",
        "order": 63.5,
        "htmlcode": "&#129321"
    },
    {
        "index": 17,
        "emoji": "😘",
        "name": "face blowing a kiss",
        "shortname": ":kissing_heart:",
        "unicode": "1f618",
        "html": "&#128536;",
        "category": "Smileys & Emotion (face-affection)",
        "order": 14,
        "htmlcode": "&#128536"
    },
    {
        "index": 18,
        "emoji": "😗",
        "name": "kissing face",
        "shortname": ":kissing:",
        "unicode": "1f617",
        "html": "&#128535;",
        "category": "Smileys & Emotion (face-affection)",
        "order": 15,
        "htmlcode": "&#128535"
    },
    {
        "index": 19,
        "emoji": "😚",
        "name": "kissing face with closed eyes",
        "shortname": ":kissing_closed_eyes:",
        "unicode": "1f61a",
        "html": "&#128538;",
        "category": "Smileys & Emotion (face-affection)",
        "order": 17,
        "htmlcode": "&#128538"
    },
    {
        "index": 20,
        "emoji": "😙",
        "name": "kissing face with smiling eyes",
        "shortname": ":kissing_smiling_eyes:",
        "unicode": "1f619",
        "html": "&#128537;",
        "category": "Smileys & Emotion (face-affection)",
        "order": 16,
        "htmlcode": "&#128537"
    },
    // {
    //     "index": 21,
    //     "htmlcode": "&#129394"
    // },
    {
        "index": 22,
        "emoji": "😋",
        "name": "face savoring food",
        "shortname": ":yum:",
        "unicode": "1f60b",
        "html": "&#128523;",
        "category": "Smileys & Emotion (face-tongue)",
        "order": 11,
        "htmlcode": "&#128523"
    },
    {
        "index": 23,
        "emoji": "😛",
        "name": "face with tongue",
        "shortname": ":stuck_out_tongue:",
        "unicode": "1f61b",
        "html": "&#128539;",
        "category": "Smileys & Emotion (face-tongue)",
        "order": 37,
        "htmlcode": "&#128539"
    },
    {
        "index": 24,
        "emoji": "😜",
        "name": "winking face with tongue",
        "shortname": ":stuck_out_tongue_winking_eye:",
        "unicode": "1f61c",
        "html": "&#128540;",
        "category": "Smileys & Emotion (face-tongue)",
        "order": 38,
        "htmlcode": "&#128540"
    },
    {
        "index": 25,
        "emoji": "🤪",
        "name": "zany face",
        "shortname": ":zany_face:",
        "unicode": "1F92A",
        "html": "&#x1F92A;",
        "category": "Smileys & Emotion (face-tongue)",
        "order": 63.5,
        "htmlcode": "&#129322"
    },
    {
        "index": 26,
        "emoji": "😝",
        "name": "squinting face with tongue",
        "shortname": ":stuck_out_tongue_closed_eyes:",
        "unicode": "1f61d",
        "html": "&#128541;",
        "category": "Smileys & Emotion (face-tongue)",
        "order": 39,
        "htmlcode": "&#128541"
    },
    {
        "index": 27,
        "emoji": "🤑",
        "name": "money-mouth face",
        "shortname": ":moneymouth_face:",
        "unicode": "1F911",
        "html": "&#129297;",
        "category": "Smileys & Emotion (face-tongue)",
        "order": 63.5,
        "htmlcode": "&#129297"
    },
    {
        "index": 28,
        "emoji": "🤗",
        "name": "hugging face",
        "shortname": ":hugging:",
        "unicode": "1f917",
        "html": "&#129303;",
        "category": "Smileys & Emotion (face-hand)",
        "order": 20,
        "htmlcode": "&#129303"
    },
    {
        "index": 29,
        "emoji": "🤭",
        "name": "face with hand over mouth",
        "shortname": ":face_with_hand_over_mouth:",
        "unicode": "1F92D",
        "html": "&#x1F92D;",
        "category": "Smileys & Emotion (face-hand)",
        "order": 63.5,
        "htmlcode": "&#129325"
    },
    {
        "index": 30,
        "emoji": "🤫",
        "name": "shushing face",
        "shortname": ":shushing_face:",
        "unicode": "1F92B",
        "html": "&#x1F92B;",
        "category": "Smileys & Emotion (face-hand)",
        "order": 63.5,
        "htmlcode": "&#129323"
    },
    {
        "index": 31,
        "emoji": "🤔",
        "name": "thinking face",
        "shortname": ":thinking:",
        "unicode": "1f914",
        "html": "&#129300;",
        "category": "Smileys & Emotion (face-hand)",
        "order": 21,
        "htmlcode": "&#129300"
    },
    {
        "index": 32,
        "emoji": "🤐",
        "name": "zipper-mouth face",
        "shortname": ":zipper_mouth:",
        "unicode": "1f910",
        "html": "&#129296;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 30,
        "htmlcode": "&#129296"
    },
    {
        "index": 33,
        "emoji": "🤨",
        "name": "face with raised eyebrow",
        "shortname": ":face_with_raised_eyebrow:",
        "unicode": "1F928",
        "html": "&#x1F928;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 63.5,
        "htmlcode": "&#129320"
    },
    {
        "index": 34,
        "emoji": "😐",
        "name": "neutral face",
        "shortname": ":neutral_face:",
        "unicode": "1f610",
        "html": "&#128528;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 22,
        "htmlcode": "&#128528"
    },
    {
        "index": 35,
        "emoji": "😑",
        "name": "expressionless face",
        "shortname": ":expressionless:",
        "unicode": "1f611",
        "html": "&#128529;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 23,
        "htmlcode": "&#128529"
    },
    {
        "index": 36,
        "emoji": "😶",
        "name": "face without mouth",
        "shortname": ":no_mouth:",
        "unicode": "1f636",
        "html": "&#128566;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 24,
        "htmlcode": "&#128566"
    },
    {
        "index": 37,
        "emoji": "🌫",
        "name": "fog",
        "shortname": ":fog:",
        "unicode": "1f32b",
        "html": "&#127787;",
        "category": "Travel & Places (sky & weather)",
        "order": 1740,
        "htmlcode": "&#127787"
    },
    {
        "index": 38,
        "emoji": "😏",
        "name": "smirking face",
        "shortname": ":smirk:",
        "unicode": "1f60f",
        "html": "&#128527;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 26,
        "htmlcode": "&#128527"
    },
    {
        "index": 39,
        "emoji": "😒",
        "name": "unamused face",
        "shortname": ":unamused:",
        "unicode": "1f612",
        "html": "&#128530;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 41,
        "htmlcode": "&#128530"
    },
    {
        "index": 40,
        "emoji": "🙄",
        "name": "face with rolling eyes",
        "shortname": ":rolling_eyes:",
        "unicode": "1f644",
        "html": "&#128580;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 25,
        "htmlcode": "&#128580"
    },
    {
        "index": 41,
        "emoji": "😬",
        "name": "grimacing face",
        "shortname": ":grimacing:",
        "unicode": "1f62c",
        "html": "&#128556;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 60,
        "htmlcode": "&#128556"
    },
    {
        "index": 42,
        "emoji": "😮",
        "name": "face with open mouth",
        "shortname": ":open_mouth:",
        "unicode": "1f62e",
        "html": "&#128558;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 29,
        "htmlcode": "&#128558"
    },
    {
        "index": 43,
        "emoji": "💨",
        "name": "dashing away",
        "shortname": ":dash:",
        "unicode": "1f4a8",
        "html": "&#128168;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1307,
        "htmlcode": "&#128168"
    },
    {
        "index": 44,
        "emoji": "🤥",
        "name": "lying face",
        "shortname": ":lying_face:",
        "unicode": "1F925",
        "html": "&#x1F925;",
        "category": "Smileys & Emotion (face-neutral-skeptical)",
        "order": 63.5,
        "htmlcode": "&#129317"
    },
    {
        "index": 45,
        "emoji": "😌",
        "name": "relieved face",
        "shortname": ":relieved:",
        "unicode": "1f60c",
        "html": "&#128524;",
        "category": "Smileys & Emotion (face-sleepy)",
        "order": 35,
        "htmlcode": "&#128524"
    },
    {
        "index": 46,
        "emoji": "😔",
        "name": "pensive face",
        "shortname": ":pensive:",
        "unicode": "1f614",
        "html": "&#128532;",
        "category": "Smileys & Emotion (face-sleepy)",
        "order": 43,
        "htmlcode": "&#128532"
    },
    {
        "index": 47,
        "emoji": "😪",
        "name": "sleepy face",
        "shortname": ":sleepy:",
        "unicode": "1f62a",
        "html": "&#128554;",
        "category": "Smileys & Emotion (face-sleepy)",
        "order": 32,
        "htmlcode": "&#128554"
    },
    {
        "index": 48,
        "emoji": "🤤",
        "name": "drooling face",
        "shortname": ":drooling_face:",
        "unicode": "1F924",
        "html": "&#x1F924;",
        "category": "Smileys & Emotion (face-sleepy)",
        "order": 63.5,
        "htmlcode": "&#129316"
    },
    {
        "index": 49,
        "emoji": "😴",
        "name": "sleeping face",
        "shortname": ":sleeping:",
        "unicode": "1f634",
        "html": "&#128564;",
        "category": "Smileys & Emotion (face-sleepy)",
        "order": 34,
        "htmlcode": "&#128564"
    },
    {
        "index": 50,
        "emoji": "😷",
        "name": "face with medical mask",
        "shortname": ":mask:",
        "unicode": "1f637",
        "html": "&#128567;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 71,
        "htmlcode": "&#128567"
    },
    {
        "index": 51,
        "emoji": "🤒",
        "name": "face with thermometer",
        "shortname": ":face_with_thermometer:",
        "unicode": "1F912",
        "html": "&#129298;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129298"
    },
    {
        "index": 52,
        "emoji": "🤕",
        "name": "face with head-bandage",
        "shortname": ":face_with_headbandage:",
        "unicode": "1F915",
        "html": "&#129301;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129301"
    },
    {
        "index": 53,
        "emoji": "🤢",
        "name": "nauseated face",
        "shortname": ":nauseated_face:",
        "unicode": "1F922",
        "html": "&#x1F922;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129314"
    },
    {
        "index": 54,
        "emoji": "🤮",
        "name": "face vomiting",
        "shortname": ":face_vomiting:",
        "unicode": "1F92E",
        "html": "&#x1F92E;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129326"
    },
    {
        "index": 55,
        "emoji": "🤧",
        "name": "sneezing face",
        "shortname": ":sneezing_face:",
        "unicode": "1F927",
        "html": "&#x1F927;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129319"
    },
    {
        "index": 56,
        "emoji": "🥵",
        "name": "hot face",
        "shortname": ":hot_face:",
        "unicode": "1F975",
        "html": "&#x1F975;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129397"
    },
    {
        "index": 57,
        "emoji": "🥶",
        "name": "cold face",
        "shortname": ":cold_face:",
        "unicode": "1F976",
        "html": "&#x1F976;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129398"
    },
    {
        "index": 58,
        "emoji": "🥴",
        "name": "woozy face",
        "shortname": ":woozy_face:",
        "unicode": "1F974",
        "html": "&#x1F974;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129396"
    },
    {
        "index": 59,
        "emoji": "😵",
        "name": "dizzy face",
        "shortname": ":dizzy_face:",
        "unicode": "1f635",
        "html": "&#128565;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 64,
        "htmlcode": "&#128565"
    },
    {
        "index": 60,
        "emoji": "💫",
        "name": "dizzy",
        "shortname": ":dizzy:",
        "unicode": "1f4ab",
        "html": "&#128171;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1308,
        "htmlcode": "&#128171"
    },
    {
        "index": 61,
        "emoji": "🤯",
        "name": "exploding head",
        "shortname": ":exploding_head:",
        "unicode": "1F92F",
        "html": "&#x1F92F;",
        "category": "Smileys & Emotion (face-unwell)",
        "order": 63.5,
        "htmlcode": "&#129327"
    },
    {
        "index": 62,
        "emoji": "🤠",
        "name": "cowboy hat face",
        "shortname": ":cowboy_hat_face:",
        "unicode": "1F920",
        "html": "&#x1F920;",
        "category": "Smileys & Emotion (face-hat)",
        "order": 63.5,
        "htmlcode": "&#129312"
    },
    {
        "index": 63,
        "emoji": "🥳",
        "name": "partying face",
        "shortname": ":partying_face:",
        "unicode": "1F973",
        "html": "&#x1F973;",
        "category": "Smileys & Emotion (face-hat)",
        "order": 63.5,
        "htmlcode": "&#129395"
    },
    // {
    //     "index": 64,
    //     "htmlcode": "&#129400"
    // },
    {
        "index": 65,
        "emoji": "😎",
        "name": "smiling face with sunglasses",
        "shortname": ":sunglasses:",
        "unicode": "1f60e",
        "html": "&#128526;",
        "category": "Smileys & Emotion (face-glasses)",
        "order": 12,
        "htmlcode": "&#128526"
    },
    {
        "index": 66,
        "emoji": "🤓",
        "name": "nerd face",
        "shortname": ":nerd:",
        "unicode": "1f913",
        "html": "&#129299;",
        "category": "Smileys & Emotion (face-glasses)",
        "order": 36,
        "htmlcode": "&#129299"
    },
    {
        "index": 67,
        "emoji": "🧐",
        "name": "face with monocle",
        "shortname": ":face_with_monocle:",
        "unicode": "1F9D0",
        "html": "&#x1F9D0;",
        "category": "Smileys & Emotion (face-glasses)",
        "order": 63.5,
        "htmlcode": "&#129488"
    },
    {
        "index": 68,
        "emoji": "😕",
        "name": "confused face",
        "shortname": ":confused:",
        "unicode": "1f615",
        "html": "&#128533;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 44,
        "htmlcode": "&#128533"
    },
    {
        "index": 69,
        "emoji": "😟",
        "name": "worried face",
        "shortname": ":worried:",
        "unicode": "1f61f",
        "html": "&#128543;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 52,
        "htmlcode": "&#128543"
    },
    {
        "index": 70,
        "emoji": "🙁",
        "name": "slightly frowning face",
        "shortname": ":slightly_frowning_face:",
        "unicode": "1F641",
        "html": "&#128577;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 63.5,
        "htmlcode": "&#128577"
    },
    {
        "index": 71,
        "emoji": "😯",
        "name": "hushed face",
        "shortname": ":hushed:",
        "unicode": "1f62f",
        "html": "&#128559;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 31,
        "htmlcode": "&#128559"
    },
    {
        "index": 72,
        "emoji": "😲",
        "name": "astonished face",
        "shortname": ":astonished:",
        "unicode": "1f632",
        "html": "&#128562;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 47,
        "htmlcode": "&#128562"
    },
    {
        "index": 73,
        "emoji": "😳",
        "name": "flushed face",
        "shortname": ":flushed:",
        "unicode": "1f633",
        "html": "&#128563;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 63,
        "htmlcode": "&#128563"
    },
    {
        "index": 74,
        "emoji": "🥺",
        "name": "pleading face",
        "shortname": ":pleading_face:",
        "unicode": "1F97A",
        "html": "&#x1F97A;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 63.5,
        "htmlcode": "&#129402"
    },
    {
        "index": 75,
        "emoji": "😦",
        "name": "frowning face with open mouth",
        "shortname": ":frowning:",
        "unicode": "1f626",
        "html": "&#128550;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 56,
        "htmlcode": "&#128550"
    },
    {
        "index": 76,
        "emoji": "😧",
        "name": "anguished face",
        "shortname": ":anguished:",
        "unicode": "1f627",
        "html": "&#128551;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 57,
        "htmlcode": "&#128551"
    },
    {
        "index": 77,
        "emoji": "😨",
        "name": "fearful face",
        "shortname": ":fearful:",
        "unicode": "1f628",
        "html": "&#128552;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 58,
        "htmlcode": "&#128552"
    },
    {
        "index": 78,
        "emoji": "😰",
        "name": "anxious face with sweat",
        "shortname": ":cold_sweat:",
        "unicode": "1f630",
        "html": "&#128560;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 61,
        "htmlcode": "&#128560"
    },
    {
        "index": 79,
        "emoji": "😥",
        "name": "sad but relieved face",
        "shortname": ":disappointed_relieved:",
        "unicode": "1f625",
        "html": "&#128549;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 28,
        "htmlcode": "&#128549"
    },
    {
        "index": 80,
        "emoji": "😢",
        "name": "crying face",
        "shortname": ":cry:",
        "unicode": "1f622",
        "html": "&#128546;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 54,
        "htmlcode": "&#128546"
    },
    {
        "index": 81,
        "emoji": "😭",
        "name": "loudly crying face",
        "shortname": ":sob:",
        "unicode": "1f62d",
        "html": "&#128557;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 55,
        "htmlcode": "&#128557"
    },
    {
        "index": 82,
        "emoji": "😱",
        "name": "face screaming in fear",
        "shortname": ":scream:",
        "unicode": "1f631",
        "html": "&#128561;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 62,
        "htmlcode": "&#128561"
    },
    {
        "index": 83,
        "emoji": "😖",
        "name": "confounded face",
        "shortname": ":confounded:",
        "unicode": "1f616",
        "html": "&#128534;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 50,
        "htmlcode": "&#128534"
    },
    {
        "index": 84,
        "emoji": "😣",
        "name": "persevering face",
        "shortname": ":persevere:",
        "unicode": "1f623",
        "html": "&#128547;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 27,
        "htmlcode": "&#128547"
    },
    {
        "index": 85,
        "emoji": "😞",
        "name": "disappointed face",
        "shortname": ":disappointed:",
        "unicode": "1f61e",
        "html": "&#128542;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 51,
        "htmlcode": "&#128542"
    },
    {
        "index": 86,
        "emoji": "😓",
        "name": "downcast face with sweat",
        "shortname": ":sweat:",
        "unicode": "1f613",
        "html": "&#128531;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 42,
        "htmlcode": "&#128531"
    },
    {
        "index": 87,
        "emoji": "😩",
        "name": "weary face",
        "shortname": ":weary:",
        "unicode": "1f629",
        "html": "&#128553;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 59,
        "htmlcode": "&#128553"
    },
    {
        "index": 88,
        "emoji": "😫",
        "name": "tired face",
        "shortname": ":tired_face:",
        "unicode": "1f62b",
        "html": "&#128555;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 33,
        "htmlcode": "&#128555"
    },
    {
        "index": 89,
        "emoji": "🥱",
        "name": "yawning face",
        "shortname": ":yawning_face:",
        "unicode": "1F971",
        "html": "&#x1F971;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 63.5,
        "htmlcode": "&#129393"
    },
    {
        "index": 90,
        "emoji": "😤",
        "name": "face with steam from nose",
        "shortname": ":triumph:",
        "unicode": "1f624",
        "html": "&#128548;",
        "category": "Smileys & Emotion (face-negative)",
        "order": 53,
        "htmlcode": "&#128548"
    },
    {
        "index": 91,
        "emoji": "😡",
        "name": "pouting face",
        "shortname": ":rage:",
        "unicode": "1f621",
        "html": "&#128545;",
        "category": "Smileys & Emotion (face-negative)",
        "order": 65,
        "htmlcode": "&#128545"
    },
    {
        "index": 92,
        "emoji": "😠",
        "name": "angry face",
        "shortname": ":angry:",
        "unicode": "1f620",
        "html": "&#128544;",
        "category": "Smileys & Emotion (face-negative)",
        "order": 66,
        "htmlcode": "&#128544"
    },
    {
        "index": 93,
        "emoji": "🤬",
        "name": "face with symbols on mouth",
        "shortname": ":face_with_symbols_on_mouth:",
        "unicode": "1F92C",
        "html": "&#x1F92C;",
        "category": "Smileys & Emotion (face-negative)",
        "order": 63.5,
        "htmlcode": "&#129324"
    },
    {
        "index": 94,
        "emoji": "😈",
        "name": "smiling face with horns",
        "shortname": ":smiling_imp:",
        "unicode": "1f608",
        "html": "&#128520;",
        "category": "Smileys & Emotion (face-negative)",
        "order": 76,
        "htmlcode": "&#128520"
    },
    {
        "index": 95,
        "emoji": "👿",
        "name": "angry face with horns",
        "shortname": ":imp:",
        "unicode": "1f47f",
        "html": "&#128127;",
        "category": "Smileys & Emotion (face-negative)",
        "order": 77,
        "htmlcode": "&#128127"
    },
    {
        "index": 96,
        "emoji": "💀",
        "name": "skull",
        "shortname": ":skull:",
        "unicode": "1f480",
        "html": "&#128128;",
        "category": "Smileys & Emotion (face-negative)",
        "order": 80,
        "htmlcode": "&#128128"
    },
    {
        "index": 97,
        "emoji": "💩",
        "name": "pile of poo",
        "shortname": ":poop:",
        "unicode": "1f4a9",
        "html": "&#128169;",
        "category": "Smileys & Emotion (face-costume)",
        "order": 85,
        "htmlcode": "&#128169"
    },
    {
        "index": 98,
        "emoji": "🤡",
        "name": "clown face",
        "shortname": ":clown_face:",
        "unicode": "1F921",
        "html": "&#x1F921;",
        "category": "Smileys & Emotion (face-costume)",
        "order": 63.5,
        "htmlcode": "&#129313"
    },
    {
        "index": 99,
        "emoji": "👹",
        "name": "ogre",
        "shortname": ":japanese_ogre:",
        "unicode": "1f479",
        "html": "&#128121;",
        "category": "Smileys & Emotion (face-costume)",
        "order": 78,
        "htmlcode": "&#128121"
    },
    {
        "index": 100,
        "emoji": "👺",
        "name": "goblin",
        "shortname": ":japanese_goblin:",
        "unicode": "1f47a",
        "html": "&#128122;",
        "category": "Smileys & Emotion (face-costume)",
        "order": 79,
        "htmlcode": "&#128122"
    },
    {
        "index": 101,
        "emoji": "👻",
        "name": "ghost",
        "shortname": ":ghost:",
        "unicode": "1f47b",
        "html": "&#128123;",
        "category": "Smileys & Emotion (face-costume)",
        "order": 82,
        "htmlcode": "&#128123"
    },
    {
        "index": 102,
        "emoji": "👽",
        "name": "alien",
        "shortname": ":alien:",
        "unicode": "1f47d",
        "html": "&#128125;",
        "category": "Smileys & Emotion (face-costume)",
        "order": 83,
        "htmlcode": "&#128125"
    },
    {
        "index": 103,
        "emoji": "👾",
        "name": "alien monster",
        "shortname": ":space_invader:",
        "unicode": "1f47e",
        "html": "&#128126;",
        "category": "Smileys & Emotion (face-costume)",
        "order": 84,
        "htmlcode": "&#128126"
    },
    {
        "index": 104,
        "emoji": "🤖",
        "name": "robot",
        "shortname": ":robot:",
        "unicode": "1F916",
        "html": "&#129302;",
        "category": "Smileys & Emotion (face-costume)",
        "order": 63.5,
        "htmlcode": "&#129302"
    },
    {
        "index": 105,
        "emoji": "😺",
        "name": "grinning cat",
        "shortname": ":smiley_cat:",
        "unicode": "1f63a",
        "html": "&#128570;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 87,
        "htmlcode": "&#128570"
    },
    {
        "index": 106,
        "emoji": "😸",
        "name": "grinning cat with smiling eyes",
        "shortname": ":smile_cat:",
        "unicode": "1f638",
        "html": "&#128568;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 88,
        "htmlcode": "&#128568"
    },
    {
        "index": 107,
        "emoji": "😹",
        "name": "cat with tears of joy",
        "shortname": ":joy_cat:",
        "unicode": "1f639",
        "html": "&#128569;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 89,
        "htmlcode": "&#128569"
    },
    {
        "index": 108,
        "emoji": "😻",
        "name": "smiling cat with heart-eyes",
        "shortname": ":heart_eyes_cat:",
        "unicode": "1f63b",
        "html": "&#128571;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 90,
        "htmlcode": "&#128571"
    },
    {
        "index": 109,
        "emoji": "😼",
        "name": "cat with wry smile",
        "shortname": ":smirk_cat:",
        "unicode": "1f63c",
        "html": "&#128572;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 91,
        "htmlcode": "&#128572"
    },
    {
        "index": 110,
        "emoji": "😽",
        "name": "kissing cat",
        "shortname": ":kissing_cat:",
        "unicode": "1f63d",
        "html": "&#128573;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 92,
        "htmlcode": "&#128573"
    },
    {
        "index": 111,
        "emoji": "🙀",
        "name": "weary cat",
        "shortname": ":scream_cat:",
        "unicode": "1f640",
        "html": "&#128576;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 93,
        "htmlcode": "&#128576"
    },
    {
        "index": 112,
        "emoji": "😿",
        "name": "crying cat",
        "shortname": ":crying_cat_face:",
        "unicode": "1f63f",
        "html": "&#128575;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 94,
        "htmlcode": "&#128575"
    },
    {
        "index": 113,
        "emoji": "😾",
        "name": "pouting cat",
        "shortname": ":pouting_cat:",
        "unicode": "1f63e",
        "html": "&#128574;",
        "category": "Smileys & Emotion (cat-face)",
        "order": 95,
        "htmlcode": "&#128574"
    },
    {
        "index": 114,
        "emoji": "🙈",
        "name": "see-no-evil monkey",
        "shortname": ":see_no_evil:",
        "unicode": "1f648",
        "html": "&#128584;",
        "category": "Smileys & Emotion (monkey-face)",
        "order": 96,
        "htmlcode": "&#128584"
    },
    {
        "index": 115,
        "emoji": "🙉",
        "name": "hear-no-evil monkey",
        "shortname": ":hear_no_evil:",
        "unicode": "1f649",
        "html": "&#128585;",
        "category": "Smileys & Emotion (monkey-face)",
        "order": 97,
        "htmlcode": "&#128585"
    },
    {
        "index": 116,
        "emoji": "🙊",
        "name": "speak-no-evil monkey",
        "shortname": ":speak_no_evil:",
        "unicode": "1f64a",
        "html": "&#128586;",
        "category": "Smileys & Emotion (monkey-face)",
        "order": 98,
        "htmlcode": "&#128586"
    },
    {
        "index": 117,
        "emoji": "💋",
        "name": "kiss mark",
        "shortname": ":kiss:",
        "unicode": "1f48b",
        "html": "&#128139;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1284,
        "htmlcode": "&#128139"
    },
    {
        "index": 118,
        "emoji": "💌",
        "name": "love letter",
        "shortname": ":love_letter:",
        "unicode": "1f48c",
        "html": "&#128140;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1301,
        "htmlcode": "&#128140"
    },
    {
        "index": 119,
        "emoji": "💘",
        "name": "heart with arrow",
        "shortname": ":cupid:",
        "unicode": "1f498",
        "html": "&#128152;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1285,
        "htmlcode": "&#128152"
    },
    {
        "index": 120,
        "emoji": "💝",
        "name": "heart with ribbon",
        "shortname": ":gift_heart:",
        "unicode": "1f49d",
        "html": "&#128157;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1297,
        "htmlcode": "&#128157"
    },
    {
        "index": 121,
        "emoji": "💖",
        "name": "sparkling heart",
        "shortname": ":sparkling_heart:",
        "unicode": "1f496",
        "html": "&#128150;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1290,
        "htmlcode": "&#128150"
    },
    {
        "index": 122,
        "emoji": "💗",
        "name": "growing heart",
        "shortname": ":heartpulse:",
        "unicode": "1f497",
        "html": "&#128151;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1291,
        "htmlcode": "&#128151"
    },
    {
        "index": 123,
        "emoji": "💓",
        "name": "beating heart",
        "shortname": ":heartbeat:",
        "unicode": "1f493",
        "html": "&#128147;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1287,
        "htmlcode": "&#128147"
    },
    {
        "index": 124,
        "emoji": "💞",
        "name": "revolving hearts",
        "shortname": ":revolving_hearts:",
        "unicode": "1f49e",
        "html": "&#128158;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1298,
        "htmlcode": "&#128158"
    },
    {
        "index": 125,
        "emoji": "💕",
        "name": "two hearts",
        "shortname": ":two_hearts:",
        "unicode": "1f495",
        "html": "&#128149;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1289,
        "htmlcode": "&#128149"
    },
    {
        "index": 126,
        "emoji": "💟",
        "name": "heart decoration",
        "shortname": ":heart_decoration:",
        "unicode": "1f49f",
        "html": "&#128159;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1299,
        "htmlcode": "&#128159"
    },
    {
        "index": 127,
        "emoji": "💔",
        "name": "broken heart",
        "shortname": ":broken_heart:",
        "unicode": "1f494",
        "html": "&#128148;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1288,
        "htmlcode": "&#128148"
    },
    {
        "index": 128,
        "emoji": "🔥",
        "name": "fire",
        "shortname": ":fire:",
        "unicode": "1f525",
        "html": "&#128293;",
        "category": "Travel & Places (sky & weather)",
        "order": 1753,
        "htmlcode": "&#128293"
    },
    {
        "index": 129,
        "emoji": "🩹",
        "name": "adhesive bandage",
        "shortname": ":adhesive_bandage:",
        "unicode": "1FA79",
        "html": "&#x1FA79;",
        "category": "Objects (medical)",
        "order": 63.5,
        "htmlcode": "&#129657"
    },
    {
        "index": 130,
        "emoji": "🧡",
        "name": "orange heart",
        "shortname": ":orange_heart:",
        "unicode": "1F9E1",
        "html": "&#x1F9E1;",
        "category": "Smileys & Emotion (emotion)",
        "order": 63.5,
        "htmlcode": "&#129505"
    },
    {
        "index": 131,
        "emoji": "💛",
        "name": "yellow heart",
        "shortname": ":yellow_heart:",
        "unicode": "1f49b",
        "html": "&#128155;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1294,
        "htmlcode": "&#128155"
    },
    {
        "index": 132,
        "emoji": "💚",
        "name": "green heart",
        "shortname": ":green_heart:",
        "unicode": "1f49a",
        "html": "&#128154;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1293,
        "htmlcode": "&#128154"
    },
    {
        "index": 133,
        "emoji": "💙",
        "name": "blue heart",
        "shortname": ":blue_heart:",
        "unicode": "1f499",
        "html": "&#128153;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1292,
        "htmlcode": "&#128153"
    },
    {
        "index": 134,
        "emoji": "💜",
        "name": "purple heart",
        "shortname": ":purple_heart:",
        "unicode": "1f49c",
        "html": "&#128156;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1295,
        "htmlcode": "&#128156"
    },
    {
        "index": 135,
        "emoji": "🤎",
        "name": "brown heart",
        "shortname": ":brown_heart:",
        "unicode": "1F90E",
        "html": "&#x1F90E;",
        "category": "Smileys & Emotion (emotion)",
        "order": 63.5,
        "htmlcode": "&#129294"
    },
    {
        "index": 136,
        "emoji": "🖤",
        "name": "black heart",
        "shortname": ":black_heart:",
        "unicode": "1f5a4",
        "html": "&#128420;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1296,
        "htmlcode": "&#128420"
    },
    {
        "index": 137,
        "emoji": "🤍",
        "name": "white heart",
        "shortname": ":white_heart:",
        "unicode": "1F90D",
        "html": "&#x1F90D;",
        "category": "Smileys & Emotion (emotion)",
        "order": 63.5,
        "htmlcode": "&#129293"
    },
    {
        "index": 138,
        "emoji": "💯",
        "name": "hundred points",
        "shortname": ":hundred_points:",
        "unicode": "1F4AF",
        "html": "&#128175;",
        "category": "Smileys & Emotion (emotion)",
        "order": 63.5,
        "htmlcode": "&#128175"
    },
    {
        "index": 139,
        "emoji": "💢",
        "name": "anger symbol",
        "shortname": ":anger:",
        "unicode": "1f4a2",
        "html": "&#128162;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1303,
        "htmlcode": "&#128162"
    },
    {
        "index": 140,
        "emoji": "💥",
        "name": "collision",
        "shortname": ":boom:",
        "unicode": "1f4a5",
        "html": "&#128165;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1305,
        "htmlcode": "&#128165"
    },
    {
        "index": 141,
        "emoji": "💦",
        "name": "sweat droplets",
        "shortname": ":sweat_drops:",
        "unicode": "1f4a6",
        "html": "&#128166;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1306,
        "htmlcode": "&#128166"
    },
    {
        "index": 142,
        "emoji": "🕳",
        "name": "hole",
        "shortname": ":hole:",
        "unicode": "1f573",
        "html": "&#128371;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1313,
        "htmlcode": "&#128371"
    },
    {
        "index": 143,
        "emoji": "💣",
        "name": "bomb",
        "shortname": ":bomb:",
        "unicode": "1f4a3",
        "html": "&#128163;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1304,
        "htmlcode": "&#128163"
    },
    {
        "index": 144,
        "emoji": "💬",
        "name": "speech balloon",
        "shortname": ":speech_balloon:",
        "unicode": "1f4ac",
        "html": "&#128172;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1309,
        "htmlcode": "&#128172"
    },
    {
        "index": 145,
        "emoji": "👁",
        "name": "eye",
        "shortname": ":eye:",
        "unicode": "1f441",
        "html": "&#128065;",
        "category": "People & Body (body-parts)",
        "order": 1280,
        "htmlcode": "&#128065"
    },
    {
        "index": 146,
        "emoji": "🗨",
        "name": "left speech bubble",
        "shortname": ":speech_left:",
        "unicode": "1f5e8",
        "html": "&#128488;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1310,
        "htmlcode": "&#128488"
    },
    {
        "index": 147,
        "emoji": "🗯",
        "name": "right anger bubble",
        "shortname": ":anger_right:",
        "unicode": "1f5ef",
        "html": "&#128495;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1311,
        "htmlcode": "&#128495"
    },
    {
        "index": 148,
        "emoji": "💭",
        "name": "thought balloon",
        "shortname": ":thought_balloon:",
        "unicode": "1f4ad",
        "html": "&#128173;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1312,
        "htmlcode": "&#128173"
    },
    {
        "index": 149,
        "emoji": "💤",
        "name": "zzz",
        "shortname": ":zzz:",
        "unicode": "1f4a4",
        "html": "&#128164;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1302,
        "htmlcode": "&#128164"
    },
    {
        "index": 150,
        "emoji": "👋",
        "name": "waving hand",
        "shortname": ":wave:",
        "unicode": "1f44b",
        "html": "&#128075;",
        "category": "People & Body (hand-fingers-open)",
        "order": 1218,
        "htmlcode": "&#128075"
    },
    {
        "index": 151,
        "emoji": "🤚",
        "name": "raised back of hand",
        "shortname": ":raised_back_of_hand:",
        "unicode": "1F91A",
        "html": "&#x1F91A;",
        "category": "People & Body (hand-fingers-open)",
        "order": 63.5,
        "htmlcode": "&#129306"
    },
    {
        "index": 152,
        "emoji": "🖐",
        "name": "hand with fingers splayed",
        "shortname": ":hand_with_fingers_splayed:",
        "unicode": "1F590",
        "html": "&#128400;",
        "category": "People & Body (hand-fingers-open)",
        "order": 63.5,
        "htmlcode": "&#128400"
    },
    {
        "index": 153,
        "emoji": "🖖",
        "name": "vulcan salute",
        "shortname": ":vulcan_salute:",
        "unicode": "1F596",
        "html": "&#128406;",
        "category": "People & Body (hand-fingers-open)",
        "order": 63.5,
        "htmlcode": "&#128406"
    },
    {
        "index": 154,
        "emoji": "👌",
        "name": "OK hand",
        "shortname": ":ok_hand:",
        "unicode": "1f44c",
        "html": "&#128076;",
        "category": "People & Body (hand-fingers-partial)",
        "order": 1170,
        "htmlcode": "&#128076"
    },
    // {
    //     "index": 155,
    //     "htmlcode": "&#129292"
    // },
    {
        "index": 156,
        "emoji": "🤏",
        "name": "pinching hand",
        "shortname": ":pinching_hand:",
        "unicode": "1F90F",
        "html": "&#x1F90F;",
        "category": "People & Body (hand-fingers-partial)",
        "order": 63.5,
        "htmlcode": "&#129295"
    },
    {
        "index": 157,
        "emoji": "🤞",
        "name": "crossed fingers",
        "shortname": ":crossed_fingers:",
        "unicode": "1F91E",
        "html": "&#x1F91E;",
        "category": "People & Body (hand-fingers-partial)",
        "order": 63.5,
        "htmlcode": "&#129310"
    },
    {
        "index": 158,
        "emoji": "🤟",
        "name": "love-you gesture",
        "shortname": ":loveyou_gesture:",
        "unicode": "1F91F",
        "html": "&#x1F91F;",
        "category": "People & Body (hand-fingers-partial)",
        "order": 63.5,
        "htmlcode": "&#129311"
    },
    {
        "index": 159,
        "emoji": "🤘",
        "name": "sign of the horns",
        "shortname": ":sign_of_the_horns:",
        "unicode": "1F918",
        "html": "&#129304;",
        "category": "People & Body (hand-fingers-partial)",
        "order": 63.5,
        "htmlcode": "&#129304"
    },
    {
        "index": 160,
        "emoji": "🤙",
        "name": "call me hand",
        "shortname": ":call_me_hand:",
        "unicode": "1F919",
        "html": "&#x1F919;",
        "category": "People & Body (hand-fingers-partial)",
        "order": 63.5,
        "htmlcode": "&#129305"
    },
    {
        "index": 161,
        "emoji": "👈",
        "name": "backhand index pointing left",
        "shortname": ":point_left:",
        "unicode": "1f448",
        "html": "&#128072;",
        "category": "People & Body (hand-single-finger)",
        "order": 1092,
        "htmlcode": "&#128072"
    },
    {
        "index": 162,
        "emoji": "👉",
        "name": "backhand index pointing right",
        "shortname": ":point_right:",
        "unicode": "1f449",
        "html": "&#128073;",
        "category": "People & Body (hand-single-finger)",
        "order": 1098,
        "htmlcode": "&#128073"
    },
    {
        "index": 163,
        "emoji": "👆",
        "name": "backhand index pointing up",
        "shortname": ":point_up_2:",
        "unicode": "1f446",
        "html": "&#128070;",
        "category": "People & Body (hand-single-finger)",
        "order": 1110,
        "htmlcode": "&#128070"
    },
    {
        "index": 164,
        "emoji": "🖕",
        "name": "middle finger",
        "shortname": ":middle_finger:",
        "unicode": "1f595",
        "html": "&#128405;",
        "category": "People & Body (hand-single-finger)",
        "order": 1116,
        "htmlcode": "&#128405"
    },
    {
        "index": 165,
        "emoji": "👇",
        "name": "backhand index pointing down",
        "shortname": ":point_down:",
        "unicode": "1f447",
        "html": "&#128071;",
        "category": "People & Body (hand-single-finger)",
        "order": 1122,
        "htmlcode": "&#128071"
    },
    {
        "index": 166,
        "htmlcode": "&#128077"
    },
    {
        "index": 167,
        "htmlcode": "&#128078"
    },
    {
        "index": 168,
        "emoji": "👊",
        "name": "oncoming fist",
        "shortname": ":oncoming_fist:",
        "unicode": "1F44A",
        "html": "&#128074;",
        "category": "People & Body (hand-fingers-closed)",
        "order": 63.5,
        "htmlcode": "&#128074"
    },
    {
        "index": 169,
        "emoji": "🤛",
        "name": "left-facing fist",
        "shortname": ":leftfacing_fist:",
        "unicode": "1F91B",
        "html": "&#x1F91B;",
        "category": "People & Body (hand-fingers-closed)",
        "order": 63.5,
        "htmlcode": "&#129307"
    },
    {
        "index": 170,
        "emoji": "🤜",
        "name": "right-facing fist",
        "shortname": ":rightfacing_fist:",
        "unicode": "1F91C",
        "html": "&#x1F91C;",
        "category": "People & Body (hand-fingers-closed)",
        "order": 63.5,
        "htmlcode": "&#129308"
    },
    {
        "index": 171,
        "emoji": "👏",
        "name": "clapping hands",
        "shortname": ":clap:",
        "unicode": "1f44f",
        "html": "&#128079;",
        "category": "People & Body (hands)",
        "order": 1224,
        "htmlcode": "&#128079"
    },
    {
        "index": 172,
        "emoji": "🙌",
        "name": "raising hands",
        "shortname": ":raised_hands:",
        "unicode": "1f64c",
        "html": "&#128588;",
        "category": "People & Body (hands)",
        "order": 1242,
        "htmlcode": "&#128588"
    },
    {
        "index": 173,
        "emoji": "👐",
        "name": "open hands",
        "shortname": ":open_hands:",
        "unicode": "1f450",
        "html": "&#128080;",
        "category": "People & Body (hands)",
        "order": 1236,
        "htmlcode": "&#128080"
    },
    {
        "index": 174,
        "emoji": "🤲",
        "name": "palms up together",
        "shortname": ":palms_up_together:",
        "unicode": "1F932",
        "html": "&#x1F932;",
        "category": "People & Body (hands)",
        "order": 63.5,
        "htmlcode": "&#129330"
    },
    {
        "index": 175,
        "emoji": "🤝",
        "name": "handshake",
        "shortname": ":handshake:",
        "unicode": "1F91D",
        "html": "&#x1F91D;",
        "category": "People & Body (hands)",
        "order": 63.5,
        "htmlcode": "&#129309"
    },
    {
        "index": 176,
        "emoji": "🙏",
        "name": "folded hands",
        "shortname": ":pray:",
        "unicode": "1f64f",
        "html": "&#128591;",
        "category": "People & Body (hands)",
        "order": 1248,
        "htmlcode": "&#128591"
    },
    {
        "index": 177,
        "emoji": "💅",
        "name": "nail polish",
        "shortname": ":nail_care:",
        "unicode": "1f485",
        "html": "&#128133;",
        "category": "People & Body (hand-prop)",
        "order": 1260,
        "htmlcode": "&#128133"
    },
    {
        "index": 178,
        "emoji": "🤳",
        "name": "selfie",
        "shortname": ":selfie:",
        "unicode": "1F933",
        "html": "&#x1F933;",
        "category": "People & Body (hand-prop)",
        "order": 63.5,
        "htmlcode": "&#129331"
    },
    {
        "index": 179,
        "emoji": "💪",
        "name": "flexed biceps",
        "shortname": ":muscle:",
        "unicode": "1f4aa",
        "html": "&#128170;",
        "category": "People & Body (body-parts)",
        "order": 1080,
        "htmlcode": "&#128170"
    },
    {
        "index": 180,
        "emoji": "🦾",
        "name": "mechanical arm",
        "shortname": ":mechanical_arm:",
        "unicode": "1F9BE",
        "html": "&#x1F9BE;",
        "category": "People & Body (body-parts)",
        "order": 63.5,
        "htmlcode": "&#129470"
    },
    {
        "index": 181,
        "emoji": "🦿",
        "name": "mechanical leg",
        "shortname": ":mechanical_leg:",
        "unicode": "1F9BF",
        "html": "&#x1F9BF;",
        "category": "People & Body (body-parts)",
        "order": 63.5,
        "htmlcode": "&#129471"
    },
    {
        "index": 182,
        "emoji": "🦵",
        "name": "leg",
        "shortname": ":leg:",
        "unicode": "1F9B5",
        "html": "&#x1F9B5;",
        "category": "People & Body (body-parts)",
        "order": 63.5,
        "htmlcode": "&#129461"
    },
    {
        "index": 183,
        "emoji": "🦶",
        "name": "foot",
        "shortname": ":foot:",
        "unicode": "1F9B6",
        "html": "&#x1F9B6;",
        "category": "People & Body (body-parts)",
        "order": 63.5,
        "htmlcode": "&#129462"
    },
    {
        "index": 184,
        "emoji": "👂",
        "name": "ear",
        "shortname": ":ear:",
        "unicode": "1f442",
        "html": "&#128066;",
        "category": "People & Body (body-parts)",
        "order": 1266,
        "htmlcode": "&#128066"
    },
    {
        "index": 185,
        "emoji": "🦻",
        "name": "ear with hearing aid",
        "shortname": ":ear_with_hearing_aid:",
        "unicode": "1F9BB",
        "html": "&#x1F9BB;",
        "category": "People & Body (body-parts)",
        "order": 63.5,
        "htmlcode": "&#129467"
    },
    {
        "index": 186,
        "emoji": "👃",
        "name": "nose",
        "shortname": ":nose:",
        "unicode": "1f443",
        "html": "&#128067;",
        "category": "People & Body (body-parts)",
        "order": 1272,
        "htmlcode": "&#128067"
    },
    {
        "index": 187,
        "emoji": "🧠",
        "name": "brain",
        "shortname": ":brain:",
        "unicode": "1F9E0",
        "html": "&#x1F9E0;",
        "category": "People & Body (body-parts)",
        "order": 63.5,
        "htmlcode": "&#129504"
    },
    // {
    //     "index": 188,
    //     "htmlcode": "&#129728"
    // },
    // {
    //     "index": 189,
    //     "htmlcode": "&#129729"
    // },
    {
        "index": 190,
        "emoji": "🦷",
        "name": "tooth",
        "shortname": ":tooth:",
        "unicode": "1F9B7",
        "html": "&#x1F9B7;",
        "category": "People & Body (body-parts)",
        "order": 63.5,
        "htmlcode": "&#129463"
    },
    {
        "index": 191,
        "emoji": "🦴",
        "name": "bone",
        "shortname": ":bone:",
        "unicode": "1F9B4",
        "html": "&#x1F9B4;",
        "category": "People & Body (body-parts)",
        "order": 63.5,
        "htmlcode": "&#129460"
    },
    {
        "index": 192,
        "emoji": "👀",
        "name": "eyes",
        "shortname": ":eyes:",
        "unicode": "1f440",
        "html": "&#128064;",
        "category": "People & Body (body-parts)",
        "order": 1279,
        "htmlcode": "&#128064"
    },
    {
        "index": 193,
        "emoji": "👅",
        "name": "tongue",
        "shortname": ":tongue:",
        "unicode": "1f445",
        "html": "&#128069;",
        "category": "People & Body (body-parts)",
        "order": 1282,
        "htmlcode": "&#128069"
    },
    {
        "index": 194,
        "emoji": "👄",
        "name": "mouth",
        "shortname": ":lips:",
        "unicode": "1f444",
        "html": "&#128068;",
        "category": "People & Body (body-parts)",
        "order": 1283,
        "htmlcode": "&#128068"
    },
    {
        "index": 195,
        "emoji": "👶",
        "name": "baby",
        "shortname": ":baby:",
        "unicode": "1f476",
        "html": "&#128118;",
        "category": "People & Body (person)",
        "order": 135,
        "htmlcode": "&#128118"
    },
    {
        "index": 196,
        "emoji": "🧒",
        "name": "child",
        "shortname": ":child:",
        "unicode": "1F9D2",
        "html": "&#x1F9D2;",
        "category": "People & Body (person)",
        "order": 63.5,
        "htmlcode": "&#129490"
    },
    {
        "index": 197,
        "emoji": "👦",
        "name": "boy",
        "shortname": ":boy:",
        "unicode": "1f466",
        "html": "&#128102;",
        "category": "People & Body (person)",
        "order": 99,
        "htmlcode": "&#128102"
    },
    {
        "index": 198,
        "emoji": "👧",
        "name": "girl",
        "shortname": ":girl:",
        "unicode": "1f467",
        "html": "&#128103;",
        "category": "People & Body (person)",
        "order": 105,
        "htmlcode": "&#128103"
    },
    {
        "index": 199,
        "emoji": "🧑",
        "name": "person",
        "shortname": ":person:",
        "unicode": "1F9D1",
        "html": "&#x1F9D1;",
        "category": "People & Body (person)",
        "order": 63.5,
        "htmlcode": "&#129489"
    },
    {
        "index": 200,
        "emoji": "👱",
        "name": "person: blond hair",
        "shortname": ":person_with_blond_hair:",
        "unicode": "1f471",
        "html": "&#128113;",
        "category": "People & Body (person)",
        "order": 429,
        "htmlcode": "&#128113"
    },
    {
        "index": 201,
        "emoji": "👨",
        "name": "man",
        "shortname": ":man:",
        "unicode": "1f468",
        "html": "&#128104;",
        "category": "People & Body (person)",
        "order": 111,
        "htmlcode": "&#128104"
    },
    {
        "index": 202,
        "emoji": "🧔",
        "name": "man: beard",
        "shortname": ":beard:",
        "unicode": "1F9D4",
        "html": "&#x1F9D4;",
        "category": "People & Body (person)",
        "order": 63.5,
        "htmlcode": "&#129492"
    },
    {
        "index": 203,
        "emoji": "🦰",
        "name": "red hair",
        "shortname": ":red_hair:",
        "unicode": "1F9B0",
        "html": "&#x1F9B0;",
        "category": "Component (hair-style)",
        "order": 63.5,
        "htmlcode": "&#129456"
    },
    {
        "index": 204,
        "emoji": "🦱",
        "name": "curly hair",
        "shortname": ":curly_hair:",
        "unicode": "1F9B1",
        "html": "&#x1F9B1;",
        "category": "Component (hair-style)",
        "order": 63.5,
        "htmlcode": "&#129457"
    },
    {
        "index": 205,
        "emoji": "🦳",
        "name": "white hair",
        "shortname": ":white_hair:",
        "unicode": "1F9B3",
        "html": "&#x1F9B3;",
        "category": "Component (hair-style)",
        "order": 63.5,
        "htmlcode": "&#129459"
    },
    {
        "index": 206,
        "emoji": "🦲",
        "name": "bald",
        "shortname": ":bald:",
        "unicode": "1F9B2",
        "html": "&#x1F9B2;",
        "category": "Component (hair-style)",
        "order": 63.5,
        "htmlcode": "&#129458"
    },
    {
        "index": 207,
        "emoji": "👩",
        "name": "woman",
        "shortname": ":woman:",
        "unicode": "1f469",
        "html": "&#128105;",
        "category": "People & Body (person)",
        "order": 117,
        "htmlcode": "&#128105"
    },
    {
        "index": 208,
        "emoji": "🧓",
        "name": "older person",
        "shortname": ":older_person:",
        "unicode": "1F9D3",
        "html": "&#x1F9D3;",
        "category": "People & Body (person)",
        "order": 63.5,
        "htmlcode": "&#129491"
    },
    {
        "index": 209,
        "emoji": "👴",
        "name": "old man",
        "shortname": ":older_man:",
        "unicode": "1f474",
        "html": "&#128116;",
        "category": "People & Body (person)",
        "order": 123,
        "htmlcode": "&#128116"
    },
    {
        "index": 210,
        "emoji": "👵",
        "name": "old woman",
        "shortname": ":older_woman:",
        "unicode": "1f475",
        "html": "&#128117;",
        "category": "People & Body (person)",
        "order": 129,
        "htmlcode": "&#128117"
    },
    {
        "index": 211,
        "emoji": "🙍",
        "name": "person frowning",
        "shortname": ":person_frowning:",
        "unicode": "1f64d",
        "html": "&#128589;",
        "category": "People & Body (person-gesture)",
        "order": 495,
        "htmlcode": "&#128589"
    },
    {
        "index": 212,
        "emoji": "🙎",
        "name": "person pouting",
        "shortname": ":person_with_pouting_face:",
        "unicode": "1f64e",
        "html": "&#128590;",
        "category": "People & Body (person-gesture)",
        "order": 513,
        "htmlcode": "&#128590"
    },
    {
        "index": 213,
        "emoji": "🙅",
        "name": "person gesturing NO",
        "shortname": ":no_good:",
        "unicode": "1f645",
        "html": "&#128581;",
        "category": "People & Body (person-gesture)",
        "order": 531,
        "htmlcode": "&#128581"
    },
    {
        "index": 214,
        "emoji": "🙆",
        "name": "person gesturing OK",
        "shortname": ":ok_woman:",
        "unicode": "1f646",
        "html": "&#128582;",
        "category": "People & Body (person-gesture)",
        "order": 549,
        "htmlcode": "&#128582"
    },
    {
        "index": 215,
        "emoji": "💁",
        "name": "person tipping hand",
        "shortname": ":information_desk_person:",
        "unicode": "1f481",
        "html": "&#128129;",
        "category": "People & Body (person-gesture)",
        "order": 567,
        "htmlcode": "&#128129"
    },
    {
        "index": 216,
        "htmlcode": "&#128587"
    },
    {
        "index": 217,
        "emoji": "🧏",
        "name": "deaf person",
        "shortname": ":deaf_person:",
        "unicode": "1F9CF",
        "html": "&#x1F9CF;",
        "category": "People & Body (person-gesture)",
        "order": 63.5,
        "htmlcode": "&#129487"
    },
    {
        "index": 218,
        "emoji": "🙇",
        "name": "person bowing",
        "shortname": ":bow:",
        "unicode": "1f647",
        "html": "&#128583;",
        "category": "People & Body (person-gesture)",
        "order": 603,
        "htmlcode": "&#128583"
    },
    {
        "index": 219,
        "emoji": "🤦",
        "name": "person facepalming",
        "shortname": ":person_facepalming:",
        "unicode": "1F926",
        "html": "&#x1F926;",
        "category": "People & Body (person-gesture)",
        "order": 63.5,
        "htmlcode": "&#129318"
    },
    {
        "index": 220,
        "emoji": "🤷",
        "name": "person shrugging",
        "shortname": ":person_shrugging:",
        "unicode": "1F937",
        "html": "&#x1F937;",
        "category": "People & Body (person-gesture)",
        "order": 63.5,
        "htmlcode": "&#129335"
    },
    {
        "index": 221,
        "emoji": "🎓",
        "name": "graduation cap",
        "shortname": ":mortar_board:",
        "unicode": "1f393",
        "html": "&#127891;",
        "category": "Objects (clothing)",
        "order": 1336,
        "htmlcode": "&#127891"
    },
    {
        "index": 222,
        "emoji": "🏫",
        "name": "school",
        "shortname": ":school:",
        "unicode": "1f3eb",
        "html": "&#127979;",
        "category": "Travel & Places (place-building)",
        "order": 1569,
        "htmlcode": "&#127979"
    },
    {
        "index": 223,
        "emoji": "🌾",
        "name": "sheaf of rice",
        "shortname": ":ear_of_rice:",
        "unicode": "1f33e",
        "html": "&#127806;",
        "category": "Animals & Nature (plant-other)",
        "order": 1442,
        "htmlcode": "&#127806"
    },
    {
        "index": 224,
        "emoji": "🍳",
        "name": "cooking",
        "shortname": ":cooking:",
        "unicode": "1F373",
        "html": "&#127859;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#127859"
    },
    {
        "index": 225,
        "emoji": "🔧",
        "name": "wrench",
        "shortname": ":wrench:",
        "unicode": "1f527",
        "html": "&#128295;",
        "category": "Objects (tool)",
        "order": 1959,
        "htmlcode": "&#128295"
    },
    {
        "index": 226,
        "emoji": "🏭",
        "name": "factory",
        "shortname": ":factory:",
        "unicode": "1f3ed",
        "html": "&#127981;",
        "category": "Travel & Places (place-building)",
        "order": 1571,
        "htmlcode": "&#127981"
    },
    {
        "index": 227,
        "emoji": "💼",
        "name": "briefcase",
        "shortname": ":briefcase:",
        "unicode": "1f4bc",
        "html": "&#128188;",
        "category": "Objects (office)",
        "order": 1921,
        "htmlcode": "&#128188"
    },
    {
        "index": 228,
        "emoji": "🔬",
        "name": "microscope",
        "shortname": ":microscope:",
        "unicode": "1f52c",
        "html": "&#128300;",
        "category": "Objects (science)",
        "order": 1867,
        "htmlcode": "&#128300"
    },
    {
        "index": 229,
        "emoji": "💻",
        "name": "laptop",
        "shortname": ":computer:",
        "unicode": "1f4bb",
        "html": "&#128187;",
        "category": "Objects (computer)",
        "order": 1846,
        "htmlcode": "&#128187"
    },
    {
        "index": 230,
        "emoji": "🎤",
        "name": "microphone",
        "shortname": ":microphone:",
        "unicode": "1f3a4",
        "html": "&#127908;",
        "category": "Objects (music)",
        "order": 1829,
        "htmlcode": "&#127908"
    },
    {
        "index": 231,
        "emoji": "🎨",
        "name": "artist palette",
        "shortname": ":artist_palette:",
        "unicode": "1F3A8",
        "html": "&#127912;",
        "category": "Activities (arts & crafts)",
        "order": 63.5,
        "htmlcode": "&#127912"
    },
    {
        "index": 232,
        "emoji": "🚀",
        "name": "rocket",
        "shortname": ":rocket:",
        "unicode": "1f680",
        "html": "&#128640;",
        "category": "Travel & Places (transport-air)",
        "order": 1659,
        "htmlcode": "&#128640"
    },
    {
        "index": 233,
        "emoji": "🚒",
        "name": "fire engine",
        "shortname": ":fire_engine:",
        "unicode": "1f692",
        "html": "&#128658;",
        "category": "Travel & Places (transport-ground)",
        "order": 1619,
        "htmlcode": "&#128658"
    },
    {
        "index": 234,
        "emoji": "👮",
        "name": "police officer",
        "shortname": ":cop:",
        "unicode": "1f46e",
        "html": "&#128110;",
        "category": "People & Body (person-role)",
        "order": 339,
        "htmlcode": "&#128110"
    },
    {
        "index": 235,
        "emoji": "🕵",
        "name": "detective",
        "shortname": ":detective:",
        "unicode": "1F575",
        "html": "&#128373;",
        "category": "People & Body (person-role)",
        "order": 63.5,
        "htmlcode": "&#128373"
    },
    {
        "index": 236,
        "emoji": "💂",
        "name": "guard",
        "shortname": ":guardsman:",
        "unicode": "1f482",
        "html": "&#128130;",
        "category": "People & Body (person-role)",
        "order": 375,
        "htmlcode": "&#128130"
    },
    // {
    //     "index": 237,
    //     "htmlcode": "&#129399"
    // },
    {
        "index": 238,
        "emoji": "👷",
        "name": "construction worker",
        "shortname": ":construction_worker:",
        "unicode": "1f477",
        "html": "&#128119;",
        "category": "People & Body (person-role)",
        "order": 393,
        "htmlcode": "&#128119"
    },
    {
        "index": 239,
        "emoji": "🤴",
        "name": "prince",
        "shortname": ":prince:",
        "unicode": "1F934",
        "html": "&#x1F934;",
        "category": "People & Body (person-role)",
        "order": 63.5,
        "htmlcode": "&#129332"
    },
    {
        "index": 240,
        "htmlcode": "&#128120"
    },
    {
        "index": 241,
        "emoji": "👳",
        "name": "person wearing turban",
        "shortname": ":man_with_turban:",
        "unicode": "1f473",
        "html": "&#128115;",
        "category": "People & Body (person-role)",
        "order": 411,
        "htmlcode": "&#128115"
    },
    {
        "index": 242,
        "emoji": "👲",
        "name": "man with skullcap",
        "shortname": ":man_with_gua_pi_mao:",
        "unicode": "1f472",
        "html": "&#128114;",
        "category": "People & Body (person-role)",
        "order": 489,
        "htmlcode": "&#128114"
    },
    {
        "index": 243,
        "emoji": "🧕",
        "name": "woman with headscarf",
        "shortname": ":woman_with_headscarf:",
        "unicode": "1F9D5",
        "html": "&#x1F9D5;",
        "category": "People & Body (person-role)",
        "order": 63.5,
        "htmlcode": "&#129493"
    },
    {
        "index": 244,
        "emoji": "🤵",
        "name": "man in tuxedo",
        "shortname": ":man_in_tuxedo:",
        "unicode": "1F935",
        "html": "&#x1F935;",
        "category": "People & Body (person-role)",
        "order": 63.5,
        "htmlcode": "&#129333"
    },
    {
        "index": 245,
        "emoji": "👰",
        "name": "bride with veil",
        "shortname": ":bride_with_veil:",
        "unicode": "1f470",
        "html": "&#128112;",
        "category": "People & Body (person-role)",
        "order": 471,
        "htmlcode": "&#128112"
    },
    {
        "index": 246,
        "emoji": "🤰",
        "name": "pregnant woman",
        "shortname": ":pregnant_woman:",
        "unicode": "1F930",
        "html": "&#x1F930;",
        "category": "People & Body (person-role)",
        "order": 63.5,
        "htmlcode": "&#129328"
    },
    {
        "index": 247,
        "emoji": "🤱",
        "name": "breast-feeding",
        "shortname": ":breastfeeding:",
        "unicode": "1F931",
        "html": "&#x1F931;",
        "category": "People & Body (person-role)",
        "order": 63.5,
        "htmlcode": "&#129329"
    },
    {
        "index": 248,
        "emoji": "🍼",
        "name": "baby bottle",
        "shortname": ":baby_bottle:",
        "unicode": "1f37c",
        "html": "&#127868;",
        "category": "Food & Drink (drink)",
        "order": 1520,
        "htmlcode": "&#127868"
    },
    {
        "index": 249,
        "emoji": "👼",
        "name": "baby angel",
        "shortname": ":angel:",
        "unicode": "1f47c",
        "html": "&#128124;",
        "category": "People & Body (person-fantasy)",
        "order": 141,
        "htmlcode": "&#128124"
    },
    {
        "index": 250,
        "emoji": "🎅",
        "name": "Santa Claus",
        "shortname": ":santa:",
        "unicode": "1f385",
        "html": "&#127877;",
        "category": "People & Body (person-fantasy)",
        "order": 447,
        "htmlcode": "&#127877"
    },
    {
        "index": 251,
        "emoji": "🤶",
        "name": "Mrs. Claus",
        "shortname": ":Mrs_Claus:",
        "unicode": "1F936",
        "html": "&#x1F936;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129334"
    },
    {
        "index": 252,
        "emoji": "🎄",
        "name": "Christmas tree",
        "shortname": ":christmas_tree:",
        "unicode": "1f384",
        "html": "&#127876;",
        "category": "Activities (event)",
        "order": 1757,
        "htmlcode": "&#127876"
    },
    {
        "index": 253,
        "emoji": "🦸",
        "name": "superhero",
        "shortname": ":superhero:",
        "unicode": "1F9B8",
        "html": "&#x1F9B8;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129464"
    },
    {
        "index": 254,
        "emoji": "🦹",
        "name": "supervillain",
        "shortname": ":supervillain:",
        "unicode": "1F9B9",
        "html": "&#x1F9B9;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129465"
    },
    {
        "index": 255,
        "emoji": "🧙",
        "name": "mage",
        "shortname": ":mage:",
        "unicode": "1F9D9",
        "html": "&#x1F9D9;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129497"
    },
    {
        "index": 256,
        "emoji": "🧚",
        "name": "fairy",
        "shortname": ":fairy:",
        "unicode": "1F9DA",
        "html": "&#x1F9DA;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129498"
    },
    {
        "index": 257,
        "emoji": "🧛",
        "name": "vampire",
        "shortname": ":vampire:",
        "unicode": "1F9DB",
        "html": "&#x1F9DB;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129499"
    },
    {
        "index": 258,
        "emoji": "🧜",
        "name": "merperson",
        "shortname": ":merperson:",
        "unicode": "1F9DC",
        "html": "&#x1F9DC;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129500"
    },
    {
        "index": 259,
        "emoji": "🧝",
        "name": "elf",
        "shortname": ":elf:",
        "unicode": "1F9DD",
        "html": "&#x1F9DD;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129501"
    },
    {
        "index": 260,
        "emoji": "🧞",
        "name": "genie",
        "shortname": ":genie:",
        "unicode": "1F9DE",
        "html": "&#x1F9DE;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129502"
    },
    {
        "index": 261,
        "emoji": "🧟",
        "name": "zombie",
        "shortname": ":zombie:",
        "unicode": "1F9DF",
        "html": "&#x1F9DF;",
        "category": "People & Body (person-fantasy)",
        "order": 63.5,
        "htmlcode": "&#129503"
    },
    {
        "index": 262,
        "emoji": "💆",
        "name": "person getting massage",
        "shortname": ":massage:",
        "unicode": "1f486",
        "html": "&#128134;",
        "category": "People & Body (person-activity)",
        "order": 657,
        "htmlcode": "&#128134"
    },
    {
        "index": 263,
        "emoji": "💇",
        "name": "person getting haircut",
        "shortname": ":haircut:",
        "unicode": "1f487",
        "html": "&#128135;",
        "category": "People & Body (person-activity)",
        "order": 675,
        "htmlcode": "&#128135"
    },
    {
        "index": 264,
        "emoji": "🚶",
        "name": "person walking",
        "shortname": ":walking:",
        "unicode": "1f6b6",
        "html": "&#128694;",
        "category": "People & Body (person-activity)",
        "order": 693,
        "htmlcode": "&#128694"
    },
    {
        "index": 265,
        "emoji": "🧍",
        "name": "person standing",
        "shortname": ":person_standing:",
        "unicode": "1F9CD",
        "html": "&#x1F9CD;",
        "category": "People & Body (person-activity)",
        "order": 63.5,
        "htmlcode": "&#129485"
    },
    {
        "index": 266,
        "emoji": "🧎",
        "name": "person kneeling",
        "shortname": ":person_kneeling:",
        "unicode": "1F9CE",
        "html": "&#x1F9CE;",
        "category": "People & Body (person-activity)",
        "order": 63.5,
        "htmlcode": "&#129486"
    },
    {
        "index": 267,
        "emoji": "🦯",
        "name": "probing cane",
        "shortname": ":probing_cane:",
        "unicode": "1F9AF",
        "html": "&#x1F9AF;",
        "category": "Objects (tool)",
        "order": 63.5,
        "htmlcode": "&#129455"
    },
    {
        "index": 268,
        "emoji": "🦼",
        "name": "motorized wheelchair",
        "shortname": ":motorized_wheelchair:",
        "unicode": "1F9BC",
        "html": "&#x1F9BC;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#129468"
    },
    {
        "index": 269,
        "emoji": "🦽",
        "name": "manual wheelchair",
        "shortname": ":manual_wheelchair:",
        "unicode": "1F9BD",
        "html": "&#x1F9BD;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#129469"
    },
    {
        "index": 270,
        "emoji": "🏃",
        "name": "person running",
        "shortname": ":runner:",
        "unicode": "1f3c3",
        "html": "&#127939;",
        "category": "People & Body (person-activity)",
        "order": 711,
        "htmlcode": "&#127939"
    },
    {
        "index": 271,
        "emoji": "💃",
        "name": "woman dancing",
        "shortname": ":dancer:",
        "unicode": "1f483",
        "html": "&#128131;",
        "category": "People & Body (person-activity)",
        "order": 729,
        "htmlcode": "&#128131"
    },
    {
        "index": 272,
        "emoji": "🕺",
        "name": "man dancing",
        "shortname": ":man_dancing:",
        "unicode": "1F57A",
        "html": "&#x1F57A;",
        "category": "People & Body (person-activity)",
        "order": 63.5,
        "htmlcode": "&#128378"
    },
    {
        "index": 273,
        "emoji": "🕴",
        "name": "man in suit levitating",
        "shortname": ":man_in_suit:",
        "unicode": "1f574",
        "html": "&#128372;",
        "category": "People & Body (person-activity)",
        "order": 784,
        "htmlcode": "&#128372"
    },
    {
        "index": 274,
        "emoji": "👯",
        "name": "people with bunny ears",
        "shortname": ":dancers:",
        "unicode": "1f46f",
        "html": "&#128111;",
        "category": "People & Body (person-activity)",
        "order": 741,
        "htmlcode": "&#128111"
    },
    {
        "index": 275,
        "emoji": "🧖",
        "name": "person in steamy room",
        "shortname": ":person_in_steamy_room:",
        "unicode": "1F9D6",
        "html": "&#x1F9D6;",
        "category": "People & Body (person-activity)",
        "order": 63.5,
        "htmlcode": "&#129494"
    },
    {
        "index": 276,
        "emoji": "🧗",
        "name": "person climbing",
        "shortname": ":person_climbing:",
        "unicode": "1F9D7",
        "html": "&#x1F9D7;",
        "category": "People & Body (person-activity)",
        "order": 63.5,
        "htmlcode": "&#129495"
    },
    {
        "index": 277,
        "emoji": "🤺",
        "name": "person fencing",
        "shortname": ":person_fencing:",
        "unicode": "1F93A",
        "html": "&#x1F93A;",
        "category": "People & Body (person-sport)",
        "order": 63.5,
        "htmlcode": "&#129338"
    },
    {
        "index": 278,
        "emoji": "🏇",
        "name": "horse racing",
        "shortname": ":horse_racing:",
        "unicode": "1f3c7",
        "html": "&#127943;",
        "category": "People & Body (person-sport)",
        "order": 769,
        "htmlcode": "&#127943"
    },
    {
        "index": 279,
        "emoji": "🏂",
        "name": "snowboarder",
        "shortname": ":snowboarder:",
        "unicode": "1f3c2",
        "html": "&#127938;",
        "category": "People & Body (person-sport)",
        "order": 776,
        "htmlcode": "&#127938"
    },
    {
        "index": 280,
        "emoji": "🏌",
        "name": "person golfing",
        "shortname": ":golfer:",
        "unicode": "1f3cc",
        "html": "&#127948;",
        "category": "People & Body (person-sport)",
        "order": 782,
        "htmlcode": "&#127948"
    },
    {
        "index": 281,
        "emoji": "🏄",
        "name": "person surfing",
        "shortname": ":surfer:",
        "unicode": "1f3c4",
        "html": "&#127940;",
        "category": "People & Body (person-sport)",
        "order": 800,
        "htmlcode": "&#127940"
    },
    {
        "index": 282,
        "emoji": "🚣",
        "name": "person rowing boat",
        "shortname": ":rowboat:",
        "unicode": "1f6a3",
        "html": "&#128675;",
        "category": "People & Body (person-sport)",
        "order": 818,
        "htmlcode": "&#128675"
    },
    {
        "index": 283,
        "emoji": "🏊",
        "name": "person swimming",
        "shortname": ":swimmer:",
        "unicode": "1f3ca",
        "html": "&#127946;",
        "category": "People & Body (person-sport)",
        "order": 836,
        "htmlcode": "&#127946"
    },
    {
        "index": 284,
        "emoji": "🏋",
        "name": "person lifting weights",
        "shortname": ":weightlifter:",
        "unicode": "1F3CB",
        "html": "&#127947;",
        "category": "People & Body (person-sport)",
        "order": 63.5,
        "htmlcode": "&#127947"
    },
    {
        "index": 285,
        "emoji": "🚴",
        "name": "person biking",
        "shortname": ":bicyclist:",
        "unicode": "1f6b4",
        "html": "&#128692;",
        "category": "People & Body (person-sport)",
        "order": 890,
        "htmlcode": "&#128692"
    },
    {
        "index": 286,
        "emoji": "🚵",
        "name": "person mountain biking",
        "shortname": ":mountain_bicyclist:",
        "unicode": "1f6b5",
        "html": "&#128693;",
        "category": "People & Body (person-sport)",
        "order": 908,
        "htmlcode": "&#128693"
    },
    {
        "index": 287,
        "emoji": "🤸",
        "name": "person cartwheeling",
        "shortname": ":person_cartwheeling:",
        "unicode": "1F938",
        "html": "&#x1F938;",
        "category": "People & Body (person-sport)",
        "order": 63.5,
        "htmlcode": "&#129336"
    },
    {
        "index": 288,
        "emoji": "🤼",
        "name": "people wrestling",
        "shortname": ":people_wrestling:",
        "unicode": "1F93C",
        "html": "&#x1F93C;",
        "category": "People & Body (person-sport)",
        "order": 63.5,
        "htmlcode": "&#129340"
    },
    {
        "index": 289,
        "emoji": "🤽",
        "name": "person playing water polo",
        "shortname": ":person_playing_water_polo:",
        "unicode": "1F93D",
        "html": "&#x1F93D;",
        "category": "People & Body (person-sport)",
        "order": 63.5,
        "htmlcode": "&#129341"
    },
    {
        "index": 290,
        "emoji": "🤾",
        "name": "person playing handball",
        "shortname": ":person_playing_handball:",
        "unicode": "1F93E",
        "html": "&#x1F93E;",
        "category": "People & Body (person-sport)",
        "order": 63.5,
        "htmlcode": "&#129342"
    },
    {
        "index": 291,
        "emoji": "🤹",
        "name": "person juggling",
        "shortname": ":person_juggling:",
        "unicode": "1F939",
        "html": "&#x1F939;",
        "category": "People & Body (person-sport)",
        "order": 63.5,
        "htmlcode": "&#129337"
    },
    {
        "index": 292,
        "emoji": "🧘",
        "name": "person in lotus position",
        "shortname": ":person_in_lotus_position:",
        "unicode": "1F9D8",
        "html": "&#x1F9D8;",
        "category": "People & Body (person-resting)",
        "order": 63.5,
        "htmlcode": "&#129496"
    },
    {
        "index": 293,
        "emoji": "🛀",
        "name": "person taking bath",
        "shortname": ":bath:",
        "unicode": "1f6c0",
        "html": "&#128704;",
        "category": "People & Body (person-resting)",
        "order": 1673,
        "htmlcode": "&#128704"
    },
    {
        "index": 294,
        "emoji": "🛌",
        "name": "person in bed",
        "shortname": ":sleeping_accommodation:",
        "unicode": "1f6cc",
        "html": "&#128716;",
        "category": "People & Body (person-resting)",
        "order": 1663,
        "htmlcode": "&#128716"
    },
    {
        "index": 295,
        "emoji": "👭",
        "name": "women holding hands",
        "shortname": ":two_women_holding_hands:",
        "unicode": "1f46d",
        "html": "&#128109;",
        "category": "People & Body (family)",
        "order": 1030,
        "htmlcode": "&#128109"
    },
    {
        "index": 296,
        "emoji": "👫",
        "name": "woman and man holding hands",
        "shortname": ":couple:",
        "unicode": "1f46b",
        "html": "&#128107;",
        "category": "People & Body (family)",
        "order": 1018,
        "htmlcode": "&#128107"
    },
    {
        "index": 297,
        "emoji": "👬",
        "name": "men holding hands",
        "shortname": ":two_men_holding_hands:",
        "unicode": "1f46c",
        "html": "&#128108;",
        "category": "People & Body (family)",
        "order": 1024,
        "htmlcode": "&#128108"
    },
    {
        "index": 298,
        "emoji": "💏",
        "name": "kiss",
        "shortname": ":couplekiss:",
        "unicode": "1f48f",
        "html": "&#128143;",
        "category": "People & Body (family)",
        "order": 1036,
        "htmlcode": "&#128143"
    },
    {
        "index": 299,
        "emoji": "💑",
        "name": "couple with heart",
        "shortname": ":couple_with_heart:",
        "unicode": "1f491",
        "html": "&#128145;",
        "category": "People & Body (family)",
        "order": 1040,
        "htmlcode": "&#128145"
    },
    {
        "index": 300,
        "emoji": "👪",
        "name": "family",
        "shortname": ":family:",
        "unicode": "1f46a",
        "html": "&#128106;",
        "category": "People & Body (family)",
        "order": 1044,
        "htmlcode": "&#128106"
    },
    {
        "index": 301,
        "emoji": "🗣",
        "name": "speaking head",
        "shortname": ":speaking_head:",
        "unicode": "1F5E3",
        "html": "&#128483;",
        "category": "People & Body (person-symbol)",
        "order": 63.5,
        "htmlcode": "&#128483"
    },
    {
        "index": 302,
        "emoji": "👤",
        "name": "bust in silhouette",
        "shortname": ":bust_in_silhouette:",
        "unicode": "1F464",
        "html": "&#128100;",
        "category": "People & Body (person-symbol)",
        "order": 63.5,
        "htmlcode": "&#128100"
    },
    {
        "index": 303,
        "emoji": "👥",
        "name": "busts in silhouette",
        "shortname": ":busts_in_silhouette:",
        "unicode": "1f465",
        "html": "&#128101;",
        "category": "People & Body (person-symbol)",
        "order": 767,
        "htmlcode": "&#128101"
    },
    // {
    //     "index": 304,
    //     "htmlcode": "&#129730"
    // },
    {
        "index": 305,
        "emoji": "👣",
        "name": "footprints",
        "shortname": ":footprints:",
        "unicode": "1f463",
        "html": "&#128099;",
        "category": "People & Body (person-symbol)",
        "order": 1278,
        "htmlcode": "&#128099"
    },
    {
        "index": 306,
        "emoji": "🐵",
        "name": "monkey face",
        "shortname": ":monkey_face:",
        "unicode": "1f435",
        "html": "&#128053;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1342,
        "htmlcode": "&#128053"
    },
    {
        "index": 307,
        "emoji": "🐒",
        "name": "monkey",
        "shortname": ":monkey:",
        "unicode": "1f412",
        "html": "&#128018;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1343,
        "htmlcode": "&#128018"
    },
    {
        "index": 308,
        "emoji": "🦍",
        "name": "gorilla",
        "shortname": ":gorilla:",
        "unicode": "1F98D",
        "html": "&#x1F98D;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129421"
    },
    {
        "index": 309,
        "emoji": "🦧",
        "name": "orangutan",
        "shortname": ":orangutan:",
        "unicode": "1F9A7",
        "html": "&#x1F9A7;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129447"
    },
    {
        "index": 310,
        "emoji": "🐶",
        "name": "dog face",
        "shortname": ":dog:",
        "unicode": "1f436",
        "html": "&#128054;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1345,
        "htmlcode": "&#128054"
    },
    {
        "index": 311,
        "emoji": "🐕",
        "name": "dog",
        "shortname": ":dog2:",
        "unicode": "1f415",
        "html": "&#128021;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1346,
        "htmlcode": "&#128021"
    },
    {
        "index": 312,
        "emoji": "🦮",
        "name": "guide dog",
        "shortname": ":guide_dog:",
        "unicode": "1F9AE",
        "html": "&#x1F9AE;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129454"
    },
    {
        "index": 313,
        "emoji": "🦺",
        "name": "safety vest",
        "shortname": ":safety_vest:",
        "unicode": "1F9BA",
        "html": "&#x1F9BA;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129466"
    },
    {
        "index": 314,
        "emoji": "🐩",
        "name": "poodle",
        "shortname": ":poodle:",
        "unicode": "1f429",
        "html": "&#128041;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1347,
        "htmlcode": "&#128041"
    },
    {
        "index": 315,
        "emoji": "🐺",
        "name": "wolf",
        "shortname": ":wolf:",
        "unicode": "1f43a",
        "html": "&#128058;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1348,
        "htmlcode": "&#128058"
    },
    {
        "index": 316,
        "emoji": "🦊",
        "name": "fox",
        "shortname": ":fox:",
        "unicode": "1F98A",
        "html": "&#x1F98A;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129418"
    },
    {
        "index": 317,
        "emoji": "🦝",
        "name": "raccoon",
        "shortname": ":raccoon:",
        "unicode": "1F99D",
        "html": "&#x1F99D;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129437"
    },
    {
        "index": 318,
        "emoji": "🐱",
        "name": "cat face",
        "shortname": ":cat:",
        "unicode": "1f431",
        "html": "&#128049;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1350,
        "htmlcode": "&#128049"
    },
    {
        "index": 319,
        "emoji": "🐈",
        "name": "cat",
        "shortname": ":cat2:",
        "unicode": "1f408",
        "html": "&#128008;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1351,
        "htmlcode": "&#128008"
    },
    {
        "index": 320,
        "emoji": "🦁",
        "name": "lion",
        "shortname": ":lion_face:",
        "unicode": "1f981",
        "html": "&#129409;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1352,
        "htmlcode": "&#129409"
    },
    {
        "index": 321,
        "emoji": "🐯",
        "name": "tiger face",
        "shortname": ":tiger:",
        "unicode": "1f42f",
        "html": "&#128047;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1353,
        "htmlcode": "&#128047"
    },
    {
        "index": 322,
        "emoji": "🐅",
        "name": "tiger",
        "shortname": ":tiger2:",
        "unicode": "1f405",
        "html": "&#128005;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1354,
        "htmlcode": "&#128005"
    },
    {
        "index": 323,
        "emoji": "🐆",
        "name": "leopard",
        "shortname": ":leopard:",
        "unicode": "1f406",
        "html": "&#128006;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1355,
        "htmlcode": "&#128006"
    },
    {
        "index": 324,
        "emoji": "🐴",
        "name": "horse face",
        "shortname": ":horse:",
        "unicode": "1f434",
        "html": "&#128052;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1356,
        "htmlcode": "&#128052"
    },
    {
        "index": 325,
        "emoji": "🐎",
        "name": "horse",
        "shortname": ":racehorse:",
        "unicode": "1f40e",
        "html": "&#128014;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1357,
        "htmlcode": "&#128014"
    },
    {
        "index": 326,
        "emoji": "🦄",
        "name": "unicorn",
        "shortname": ":unicorn:",
        "unicode": "1F984",
        "html": "&#129412;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129412"
    },
    {
        "index": 327,
        "emoji": "🦓",
        "name": "zebra",
        "shortname": ":zebra:",
        "unicode": "1F993",
        "html": "&#x1F993;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129427"
    },
    {
        "index": 328,
        "emoji": "🦌",
        "name": "deer",
        "shortname": ":deer:",
        "unicode": "1F98C",
        "html": "&#x1F98C;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129420"
    },
    // {
    //     "index": 329,
    //     "htmlcode": "&#129452"
    // },
    {
        "index": 330,
        "emoji": "🐮",
        "name": "cow face",
        "shortname": ":cow:",
        "unicode": "1f42e",
        "html": "&#128046;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1360,
        "htmlcode": "&#128046"
    },
    {
        "index": 331,
        "emoji": "🐂",
        "name": "ox",
        "shortname": ":ox:",
        "unicode": "1f402",
        "html": "&#128002;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1361,
        "htmlcode": "&#128002"
    },
    {
        "index": 332,
        "emoji": "🐃",
        "name": "water buffalo",
        "shortname": ":water_buffalo:",
        "unicode": "1f403",
        "html": "&#128003;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1362,
        "htmlcode": "&#128003"
    },
    {
        "index": 333,
        "emoji": "🐄",
        "name": "cow",
        "shortname": ":cow2:",
        "unicode": "1f404",
        "html": "&#128004;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1363,
        "htmlcode": "&#128004"
    },
    {
        "index": 334,
        "emoji": "🐷",
        "name": "pig face",
        "shortname": ":pig:",
        "unicode": "1f437",
        "html": "&#128055;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1364,
        "htmlcode": "&#128055"
    },
    {
        "index": 335,
        "emoji": "🐖",
        "name": "pig",
        "shortname": ":pig2:",
        "unicode": "1f416",
        "html": "&#128022;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1365,
        "htmlcode": "&#128022"
    },
    {
        "index": 336,
        "emoji": "🐗",
        "name": "boar",
        "shortname": ":boar:",
        "unicode": "1f417",
        "html": "&#128023;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1366,
        "htmlcode": "&#128023"
    },
    {
        "index": 337,
        "emoji": "🐽",
        "name": "pig nose",
        "shortname": ":pig_nose:",
        "unicode": "1f43d",
        "html": "&#128061;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1367,
        "htmlcode": "&#128061"
    },
    {
        "index": 338,
        "emoji": "🐏",
        "name": "ram",
        "shortname": ":ram:",
        "unicode": "1f40f",
        "html": "&#128015;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1368,
        "htmlcode": "&#128015"
    },
    {
        "index": 339,
        "emoji": "🐑",
        "name": "ewe",
        "shortname": ":sheep:",
        "unicode": "1f411",
        "html": "&#128017;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1369,
        "htmlcode": "&#128017"
    },
    {
        "index": 340,
        "emoji": "🐐",
        "name": "goat",
        "shortname": ":goat:",
        "unicode": "1f410",
        "html": "&#128016;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1370,
        "htmlcode": "&#128016"
    },
    {
        "index": 341,
        "emoji": "🐪",
        "name": "camel",
        "shortname": ":dromedary_camel:",
        "unicode": "1f42a",
        "html": "&#128042;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1371,
        "htmlcode": "&#128042"
    },
    {
        "index": 342,
        "emoji": "🐫",
        "name": "two-hump camel",
        "shortname": ":camel:",
        "unicode": "1f42b",
        "html": "&#128043;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1372,
        "htmlcode": "&#128043"
    },
    {
        "index": 343,
        "emoji": "🦙",
        "name": "llama",
        "shortname": ":llama:",
        "unicode": "1F999",
        "html": "&#x1F999;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129433"
    },
    {
        "index": 344,
        "emoji": "🦒",
        "name": "giraffe",
        "shortname": ":giraffe:",
        "unicode": "1F992",
        "html": "&#x1F992;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129426"
    },
    {
        "index": 345,
        "emoji": "🐘",
        "name": "elephant",
        "shortname": ":elephant:",
        "unicode": "1f418",
        "html": "&#128024;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1373,
        "htmlcode": "&#128024"
    },
    // {
    //     "index": 346,
    //     "htmlcode": "&#129443"
    // },
    {
        "index": 347,
        "emoji": "🦏",
        "name": "rhinoceros",
        "shortname": ":rhinoceros:",
        "unicode": "1F98F",
        "html": "&#x1F98F;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129423"
    },
    {
        "index": 348,
        "emoji": "🦛",
        "name": "hippopotamus",
        "shortname": ":hippopotamus:",
        "unicode": "1F99B",
        "html": "&#x1F99B;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129435"
    },
    {
        "index": 349,
        "emoji": "🐭",
        "name": "mouse face",
        "shortname": ":mouse:",
        "unicode": "1f42d",
        "html": "&#128045;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1375,
        "htmlcode": "&#128045"
    },
    {
        "index": 350,
        "emoji": "🐁",
        "name": "mouse",
        "shortname": ":mouse2:",
        "unicode": "1f401",
        "html": "&#128001;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1376,
        "htmlcode": "&#128001"
    },
    {
        "index": 351,
        "emoji": "🐀",
        "name": "rat",
        "shortname": ":rat:",
        "unicode": "1f400",
        "html": "&#128000;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1377,
        "htmlcode": "&#128000"
    },
    {
        "index": 352,
        "emoji": "🐹",
        "name": "hamster",
        "shortname": ":hamster:",
        "unicode": "1f439",
        "html": "&#128057;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1378,
        "htmlcode": "&#128057"
    },
    {
        "index": 353,
        "emoji": "🐰",
        "name": "rabbit face",
        "shortname": ":rabbit:",
        "unicode": "1f430",
        "html": "&#128048;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1379,
        "htmlcode": "&#128048"
    },
    {
        "index": 354,
        "emoji": "🐇",
        "name": "rabbit",
        "shortname": ":rabbit2:",
        "unicode": "1f407",
        "html": "&#128007;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1380,
        "htmlcode": "&#128007"
    },
    {
        "index": 355,
        "emoji": "🐿",
        "name": "chipmunk",
        "shortname": ":chipmunk:",
        "unicode": "1f43f",
        "html": "&#128063;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1381,
        "htmlcode": "&#128063"
    },
    // {
    //     "index": 356,
    //     "htmlcode": "&#129451"
    // },
    {
        "index": 357,
        "emoji": "🦔",
        "name": "hedgehog",
        "shortname": ":hedgehog:",
        "unicode": "1F994",
        "html": "&#x1F994;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129428"
    },
    {
        "index": 358,
        "emoji": "🦇",
        "name": "bat",
        "shortname": ":bat:",
        "unicode": "1F987",
        "html": "&#x1F987;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129415"
    },
    {
        "index": 359,
        "emoji": "🐻",
        "name": "bear",
        "shortname": ":bear:",
        "unicode": "1f43b",
        "html": "&#128059;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1383,
        "htmlcode": "&#128059"
    },
    {
        "index": 360,
        "emoji": "🐨",
        "name": "koala",
        "shortname": ":koala:",
        "unicode": "1f428",
        "html": "&#128040;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1384,
        "htmlcode": "&#128040"
    },
    {
        "index": 361,
        "emoji": "🐼",
        "name": "panda",
        "shortname": ":panda_face:",
        "unicode": "1f43c",
        "html": "&#128060;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1385,
        "htmlcode": "&#128060"
    },
    {
        "index": 362,
        "emoji": "🦥",
        "name": "sloth",
        "shortname": ":sloth:",
        "unicode": "1F9A5",
        "html": "&#x1F9A5;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129445"
    },
    {
        "index": 363,
        "emoji": "🦦",
        "name": "otter",
        "shortname": ":otter:",
        "unicode": "1F9A6",
        "html": "&#x1F9A6;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129446"
    },
    {
        "index": 364,
        "emoji": "🦨",
        "name": "skunk",
        "shortname": ":skunk:",
        "unicode": "1F9A8",
        "html": "&#x1F9A8;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129448"
    },
    {
        "index": 365,
        "emoji": "🦘",
        "name": "kangaroo",
        "shortname": ":kangaroo:",
        "unicode": "1F998",
        "html": "&#x1F998;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129432"
    },
    {
        "index": 366,
        "emoji": "🦡",
        "name": "badger",
        "shortname": ":badger:",
        "unicode": "1F9A1",
        "html": "&#x1F9A1;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 63.5,
        "htmlcode": "&#129441"
    },
    {
        "index": 367,
        "emoji": "🐾",
        "name": "paw prints",
        "shortname": ":feet:",
        "unicode": "1f43e",
        "html": "&#128062;",
        "category": "Animals & Nature (animal-mammal)",
        "order": 1386,
        "htmlcode": "&#128062"
    },
    {
        "index": 368,
        "emoji": "🦃",
        "name": "turkey",
        "shortname": ":turkey:",
        "unicode": "1f983",
        "html": "&#129411;",
        "category": "Animals & Nature (animal-bird)",
        "order": 1387,
        "htmlcode": "&#129411"
    },
    {
        "index": 369,
        "emoji": "🐔",
        "name": "chicken",
        "shortname": ":chicken:",
        "unicode": "1f414",
        "html": "&#128020;",
        "category": "Animals & Nature (animal-bird)",
        "order": 1388,
        "htmlcode": "&#128020"
    },
    {
        "index": 370,
        "emoji": "🐓",
        "name": "rooster",
        "shortname": ":rooster:",
        "unicode": "1f413",
        "html": "&#128019;",
        "category": "Animals & Nature (animal-bird)",
        "order": 1389,
        "htmlcode": "&#128019"
    },
    {
        "index": 371,
        "emoji": "🐣",
        "name": "hatching chick",
        "shortname": ":hatching_chick:",
        "unicode": "1f423",
        "html": "&#128035;",
        "category": "Animals & Nature (animal-bird)",
        "order": 1390,
        "htmlcode": "&#128035"
    },
    {
        "index": 372,
        "emoji": "🐤",
        "name": "baby chick",
        "shortname": ":baby_chick:",
        "unicode": "1f424",
        "html": "&#128036;",
        "category": "Animals & Nature (animal-bird)",
        "order": 1391,
        "htmlcode": "&#128036"
    },
    {
        "index": 373,
        "emoji": "🐥",
        "name": "front-facing baby chick",
        "shortname": ":hatched_chick:",
        "unicode": "1f425",
        "html": "&#128037;",
        "category": "Animals & Nature (animal-bird)",
        "order": 1392,
        "htmlcode": "&#128037"
    },
    {
        "index": 374,
        "emoji": "🐦",
        "name": "bird",
        "shortname": ":bird:",
        "unicode": "1f426",
        "html": "&#128038;",
        "category": "Animals & Nature (animal-bird)",
        "order": 1393,
        "htmlcode": "&#128038"
    },
    {
        "index": 375,
        "emoji": "🐧",
        "name": "penguin",
        "shortname": ":penguin:",
        "unicode": "1f427",
        "html": "&#128039;",
        "category": "Animals & Nature (animal-bird)",
        "order": 1394,
        "htmlcode": "&#128039"
    },
    {
        "index": 376,
        "emoji": "🕊",
        "name": "dove",
        "shortname": ":dove:",
        "unicode": "1F54A",
        "html": "&#128330;",
        "category": "Animals & Nature (animal-bird)",
        "order": 63.5,
        "htmlcode": "&#128330"
    },
    {
        "index": 377,
        "emoji": "🦅",
        "name": "eagle",
        "shortname": ":eagle:",
        "unicode": "1F985",
        "html": "&#x1F985;",
        "category": "Animals & Nature (animal-bird)",
        "order": 63.5,
        "htmlcode": "&#129413"
    },
    {
        "index": 378,
        "emoji": "🦆",
        "name": "duck",
        "shortname": ":duck:",
        "unicode": "1F986",
        "html": "&#x1F986;",
        "category": "Animals & Nature (animal-bird)",
        "order": 63.5,
        "htmlcode": "&#129414"
    },
    {
        "index": 379,
        "emoji": "🦢",
        "name": "swan",
        "shortname": ":swan:",
        "unicode": "1F9A2",
        "html": "&#x1F9A2;",
        "category": "Animals & Nature (animal-bird)",
        "order": 63.5,
        "htmlcode": "&#129442"
    },
    {
        "index": 380,
        "emoji": "🦉",
        "name": "owl",
        "shortname": ":owl:",
        "unicode": "1F989",
        "html": "&#x1F989;",
        "category": "Animals & Nature (animal-bird)",
        "order": 63.5,
        "htmlcode": "&#129417"
    },
    // {
    //     "index": 381,
    //     "htmlcode": "&#129444"
    // },
    // {
    //     "index": 382,
    //     "htmlcode": "&#129718"
    // },
    {
        "index": 383,
        "emoji": "🦩",
        "name": "flamingo",
        "shortname": ":flamingo:",
        "unicode": "1F9A9",
        "html": "&#x1F9A9;",
        "category": "Animals & Nature (animal-bird)",
        "order": 63.5,
        "htmlcode": "&#129449"
    },
    {
        "index": 384,
        "emoji": "🦚",
        "name": "peacock",
        "shortname": ":peacock:",
        "unicode": "1F99A",
        "html": "&#x1F99A;",
        "category": "Animals & Nature (animal-bird)",
        "order": 63.5,
        "htmlcode": "&#129434"
    },
    {
        "index": 385,
        "emoji": "🦜",
        "name": "parrot",
        "shortname": ":parrot:",
        "unicode": "1F99C",
        "html": "&#x1F99C;",
        "category": "Animals & Nature (animal-bird)",
        "order": 63.5,
        "htmlcode": "&#129436"
    },
    {
        "index": 386,
        "emoji": "🐸",
        "name": "frog",
        "shortname": ":frog:",
        "unicode": "1f438",
        "html": "&#128056;",
        "category": "Animals & Nature (animal-amphibian)",
        "order": 1399,
        "htmlcode": "&#128056"
    },
    {
        "index": 387,
        "emoji": "🐊",
        "name": "crocodile",
        "shortname": ":crocodile:",
        "unicode": "1f40a",
        "html": "&#128010;",
        "category": "Animals & Nature (animal-reptile)",
        "order": 1400,
        "htmlcode": "&#128010"
    },
    {
        "index": 388,
        "emoji": "🐢",
        "name": "turtle",
        "shortname": ":turtle:",
        "unicode": "1f422",
        "html": "&#128034;",
        "category": "Animals & Nature (animal-reptile)",
        "order": 1401,
        "htmlcode": "&#128034"
    },
    {
        "index": 389,
        "emoji": "🦎",
        "name": "lizard",
        "shortname": ":lizard:",
        "unicode": "1F98E",
        "html": "&#x1F98E;",
        "category": "Animals & Nature (animal-reptile)",
        "order": 63.5,
        "htmlcode": "&#129422"
    },
    {
        "index": 390,
        "emoji": "🐍",
        "name": "snake",
        "shortname": ":snake:",
        "unicode": "1f40d",
        "html": "&#128013;",
        "category": "Animals & Nature (animal-reptile)",
        "order": 1403,
        "htmlcode": "&#128013"
    },
    {
        "index": 391,
        "emoji": "🐲",
        "name": "dragon face",
        "shortname": ":dragon_face:",
        "unicode": "1f432",
        "html": "&#128050;",
        "category": "Animals & Nature (animal-reptile)",
        "order": 1404,
        "htmlcode": "&#128050"
    },
    {
        "index": 392,
        "emoji": "🐉",
        "name": "dragon",
        "shortname": ":dragon:",
        "unicode": "1f409",
        "html": "&#128009;",
        "category": "Animals & Nature (animal-reptile)",
        "order": 1405,
        "htmlcode": "&#128009"
    },
    {
        "index": 393,
        "emoji": "🦕",
        "name": "sauropod",
        "shortname": ":sauropod:",
        "unicode": "1F995",
        "html": "&#x1F995;",
        "category": "Animals & Nature (animal-reptile)",
        "order": 63.5,
        "htmlcode": "&#129429"
    },
    {
        "index": 394,
        "emoji": "🦖",
        "name": "T-Rex",
        "shortname": ":TRex:",
        "unicode": "1F996",
        "html": "&#x1F996;",
        "category": "Animals & Nature (animal-reptile)",
        "order": 63.5,
        "htmlcode": "&#129430"
    },
    {
        "index": 395,
        "emoji": "🐳",
        "name": "spouting whale",
        "shortname": ":whale:",
        "unicode": "1f433",
        "html": "&#128051;",
        "category": "Animals & Nature (animal-marine)",
        "order": 1406,
        "htmlcode": "&#128051"
    },
    {
        "index": 396,
        "emoji": "🐋",
        "name": "whale",
        "shortname": ":whale2:",
        "unicode": "1f40b",
        "html": "&#128011;",
        "category": "Animals & Nature (animal-marine)",
        "order": 1407,
        "htmlcode": "&#128011"
    },
    {
        "index": 397,
        "emoji": "🐬",
        "name": "dolphin",
        "shortname": ":dolphin:",
        "unicode": "1f42c",
        "html": "&#128044;",
        "category": "Animals & Nature (animal-marine)",
        "order": 1408,
        "htmlcode": "&#128044"
    },
    // {
    //     "index": 398,
    //     "htmlcode": "&#129453"
    // },
    {
        "index": 399,
        "emoji": "🐟",
        "name": "fish",
        "shortname": ":fish:",
        "unicode": "1f41f",
        "html": "&#128031;",
        "category": "Animals & Nature (animal-marine)",
        "order": 1409,
        "htmlcode": "&#128031"
    },
    {
        "index": 400,
        "emoji": "🐠",
        "name": "tropical fish",
        "shortname": ":tropical_fish:",
        "unicode": "1f420",
        "html": "&#128032;",
        "category": "Animals & Nature (animal-marine)",
        "order": 1410,
        "htmlcode": "&#128032"
    },
    {
        "index": 401,
        "emoji": "🐡",
        "name": "blowfish",
        "shortname": ":blowfish:",
        "unicode": "1f421",
        "html": "&#128033;",
        "category": "Animals & Nature (animal-marine)",
        "order": 1411,
        "htmlcode": "&#128033"
    },
    {
        "index": 402,
        "emoji": "🦈",
        "name": "shark",
        "shortname": ":shark:",
        "unicode": "1F988",
        "html": "&#x1F988;",
        "category": "Animals & Nature (animal-marine)",
        "order": 63.5,
        "htmlcode": "&#129416"
    },
    {
        "index": 403,
        "emoji": "🐙",
        "name": "octopus",
        "shortname": ":octopus:",
        "unicode": "1f419",
        "html": "&#128025;",
        "category": "Animals & Nature (animal-marine)",
        "order": 1413,
        "htmlcode": "&#128025"
    },
    {
        "index": 404,
        "emoji": "🐚",
        "name": "spiral shell",
        "shortname": ":shell:",
        "unicode": "1f41a",
        "html": "&#128026;",
        "category": "Animals & Nature (animal-marine)",
        "order": 1414,
        "htmlcode": "&#128026"
    },
    {
        "index": 405,
        "emoji": "🐌",
        "name": "snail",
        "shortname": ":snail:",
        "unicode": "1f40c",
        "html": "&#128012;",
        "category": "Animals & Nature (animal-bug)",
        "order": 1419,
        "htmlcode": "&#128012"
    },
    {
        "index": 406,
        "emoji": "🦋",
        "name": "butterfly",
        "shortname": ":butterfly:",
        "unicode": "1F98B",
        "html": "&#x1F98B;",
        "category": "Animals & Nature (animal-bug)",
        "order": 63.5,
        "htmlcode": "&#129419"
    },
    {
        "index": 407,
        "emoji": "🐛",
        "name": "bug",
        "shortname": ":bug:",
        "unicode": "1f41b",
        "html": "&#128027;",
        "category": "Animals & Nature (animal-bug)",
        "order": 1420,
        "htmlcode": "&#128027"
    },
    {
        "index": 408,
        "emoji": "🐜",
        "name": "ant",
        "shortname": ":ant:",
        "unicode": "1f41c",
        "html": "&#128028;",
        "category": "Animals & Nature (animal-bug)",
        "order": 1421,
        "htmlcode": "&#128028"
    },
    {
        "index": 409,
        "emoji": "🐝",
        "name": "honeybee",
        "shortname": ":bee:",
        "unicode": "1f41d",
        "html": "&#128029;",
        "category": "Animals & Nature (animal-bug)",
        "order": 1422,
        "htmlcode": "&#128029"
    },
    // {
    //     "index": 410,
    //     "htmlcode": "&#129714"
    // },
    {
        "index": 411,
        "emoji": "🐞",
        "name": "lady beetle",
        "shortname": ":beetle:",
        "unicode": "1f41e",
        "html": "&#128030;",
        "category": "Animals & Nature (animal-bug)",
        "order": 1423,
        "htmlcode": "&#128030"
    },
    {
        "index": 412,
        "emoji": "🦗",
        "name": "cricket",
        "shortname": ":cricket:",
        "unicode": "1F997",
        "html": "&#x1F997;",
        "category": "Animals & Nature (animal-bug)",
        "order": 63.5,
        "htmlcode": "&#129431"
    },
    // {
    //     "index": 413,
    //     "htmlcode": "&#129715"
    // },
    {
        "index": 414,
        "emoji": "🕷",
        "name": "spider",
        "shortname": ":spider:",
        "unicode": "1f577",
        "html": "&#128375;",
        "category": "Animals & Nature (animal-bug)",
        "order": 1424,
        "htmlcode": "&#128375"
    },
    {
        "index": 415,
        "emoji": "🕸",
        "name": "spider web",
        "shortname": ":spider_web:",
        "unicode": "1f578",
        "html": "&#128376;",
        "category": "Animals & Nature (animal-bug)",
        "order": 1425,
        "htmlcode": "&#128376"
    },
    {
        "index": 416,
        "emoji": "🦂",
        "name": "scorpion",
        "shortname": ":scorpion:",
        "unicode": "1f982",
        "html": "&#129410;",
        "category": "Animals & Nature (animal-bug)",
        "order": 1426,
        "htmlcode": "&#129410"
    },
    {
        "index": 417,
        "emoji": "🦟",
        "name": "mosquito",
        "shortname": ":mosquito:",
        "unicode": "1F99F",
        "html": "&#x1F99F;",
        "category": "Animals & Nature (animal-bug)",
        "order": 63.5,
        "htmlcode": "&#129439"
    },
    // {
    //     "index": 418,
    //     "htmlcode": "&#129712"
    // },
    // {
    //     "index": 419,
    //     "htmlcode": "&#129713"
    // },
    {
        "index": 420,
        "emoji": "🦠",
        "name": "microbe",
        "shortname": ":microbe:",
        "unicode": "1F9A0",
        "html": "&#x1F9A0;",
        "category": "Animals & Nature (animal-bug)",
        "order": 63.5,
        "htmlcode": "&#129440"
    },
    {
        "index": 421,
        "emoji": "💐",
        "name": "bouquet",
        "shortname": ":bouquet:",
        "unicode": "1f490",
        "html": "&#128144;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1427,
        "htmlcode": "&#128144"
    },
    {
        "index": 422,
        "emoji": "🌸",
        "name": "cherry blossom",
        "shortname": ":cherry_blossom:",
        "unicode": "1f338",
        "html": "&#127800;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1428,
        "htmlcode": "&#127800"
    },
    {
        "index": 423,
        "emoji": "💮",
        "name": "white flower",
        "shortname": ":white_flower:",
        "unicode": "1f4ae",
        "html": "&#128174;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1429,
        "htmlcode": "&#128174"
    },
    {
        "index": 424,
        "emoji": "🏵",
        "name": "rosette",
        "shortname": ":rosette:",
        "unicode": "1f3f5",
        "html": "&#127989;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1430,
        "htmlcode": "&#127989"
    },
    {
        "index": 425,
        "emoji": "🌹",
        "name": "rose",
        "shortname": ":rose:",
        "unicode": "1f339",
        "html": "&#127801;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1431,
        "htmlcode": "&#127801"
    },
    {
        "index": 426,
        "emoji": "🥀",
        "name": "wilted flower",
        "shortname": ":wilted_flower:",
        "unicode": "1F940",
        "html": "&#x1F940;",
        "category": "Animals & Nature (plant-flower)",
        "order": 63.5,
        "htmlcode": "&#129344"
    },
    {
        "index": 427,
        "emoji": "🌺",
        "name": "hibiscus",
        "shortname": ":hibiscus:",
        "unicode": "1f33a",
        "html": "&#127802;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1433,
        "htmlcode": "&#127802"
    },
    {
        "index": 428,
        "emoji": "🌻",
        "name": "sunflower",
        "shortname": ":sunflower:",
        "unicode": "1f33b",
        "html": "&#127803;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1434,
        "htmlcode": "&#127803"
    },
    {
        "index": 429,
        "emoji": "🌼",
        "name": "blossom",
        "shortname": ":blossom:",
        "unicode": "1f33c",
        "html": "&#127804;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1435,
        "htmlcode": "&#127804"
    },
    {
        "index": 430,
        "emoji": "🌷",
        "name": "tulip",
        "shortname": ":tulip:",
        "unicode": "1f337",
        "html": "&#127799;",
        "category": "Animals & Nature (plant-flower)",
        "order": 1436,
        "htmlcode": "&#127799"
    },
    {
        "index": 431,
        "emoji": "🌱",
        "name": "seedling",
        "shortname": ":seedling:",
        "unicode": "1f331",
        "html": "&#127793;",
        "category": "Animals & Nature (plant-other)",
        "order": 1437,
        "htmlcode": "&#127793"
    },
    // {
    //     "index": 432,
    //     "htmlcode": "&#129716"
    // },
    {
        "index": 433,
        "emoji": "🌲",
        "name": "evergreen tree",
        "shortname": ":evergreen_tree:",
        "unicode": "1f332",
        "html": "&#127794;",
        "category": "Animals & Nature (plant-other)",
        "order": 1438,
        "htmlcode": "&#127794"
    },
    {
        "index": 434,
        "emoji": "🌳",
        "name": "deciduous tree",
        "shortname": ":deciduous_tree:",
        "unicode": "1f333",
        "html": "&#127795;",
        "category": "Animals & Nature (plant-other)",
        "order": 1439,
        "htmlcode": "&#127795"
    },
    {
        "index": 435,
        "emoji": "🌴",
        "name": "palm tree",
        "shortname": ":palm_tree:",
        "unicode": "1f334",
        "html": "&#127796;",
        "category": "Animals & Nature (plant-other)",
        "order": 1440,
        "htmlcode": "&#127796"
    },
    {
        "index": 436,
        "emoji": "🌵",
        "name": "cactus",
        "shortname": ":cactus:",
        "unicode": "1f335",
        "html": "&#127797;",
        "category": "Animals & Nature (plant-other)",
        "order": 1441,
        "htmlcode": "&#127797"
    },
    {
        "index": 437,
        "emoji": "🌿",
        "name": "herb",
        "shortname": ":herb:",
        "unicode": "1f33f",
        "html": "&#127807;",
        "category": "Animals & Nature (plant-other)",
        "order": 1443,
        "htmlcode": "&#127807"
    },
    {
        "index": 438,
        "emoji": "🍀",
        "name": "four leaf clover",
        "shortname": ":four_leaf_clover:",
        "unicode": "1f340",
        "html": "&#127808;",
        "category": "Animals & Nature (plant-other)",
        "order": 1445,
        "htmlcode": "&#127808"
    },
    {
        "index": 439,
        "emoji": "🍁",
        "name": "maple leaf",
        "shortname": ":maple_leaf:",
        "unicode": "1f341",
        "html": "&#127809;",
        "category": "Animals & Nature (plant-other)",
        "order": 1446,
        "htmlcode": "&#127809"
    },
    {
        "index": 440,
        "emoji": "🍂",
        "name": "fallen leaf",
        "shortname": ":fallen_leaf:",
        "unicode": "1f342",
        "html": "&#127810;",
        "category": "Animals & Nature (plant-other)",
        "order": 1447,
        "htmlcode": "&#127810"
    },
    {
        "index": 441,
        "emoji": "🍃",
        "name": "leaf fluttering in wind",
        "shortname": ":leaves:",
        "unicode": "1f343",
        "html": "&#127811;",
        "category": "Animals & Nature (plant-other)",
        "order": 1448,
        "htmlcode": "&#127811"
    },
    {
        "index": 442,
        "emoji": "🍇",
        "name": "grapes",
        "shortname": ":grapes:",
        "unicode": "1f347",
        "html": "&#127815;",
        "category": "Food & Drink (food-fruit)",
        "order": 1449,
        "htmlcode": "&#127815"
    },
    {
        "index": 443,
        "emoji": "🍈",
        "name": "melon",
        "shortname": ":melon:",
        "unicode": "1f348",
        "html": "&#127816;",
        "category": "Food & Drink (food-fruit)",
        "order": 1450,
        "htmlcode": "&#127816"
    },
    {
        "index": 444,
        "emoji": "🍉",
        "name": "watermelon",
        "shortname": ":watermelon:",
        "unicode": "1f349",
        "html": "&#127817;",
        "category": "Food & Drink (food-fruit)",
        "order": 1451,
        "htmlcode": "&#127817"
    },
    {
        "index": 445,
        "emoji": "🍊",
        "name": "tangerine",
        "shortname": ":tangerine:",
        "unicode": "1f34a",
        "html": "&#127818;",
        "category": "Food & Drink (food-fruit)",
        "order": 1452,
        "htmlcode": "&#127818"
    },
    {
        "index": 446,
        "emoji": "🍋",
        "name": "lemon",
        "shortname": ":lemon:",
        "unicode": "1f34b",
        "html": "&#127819;",
        "category": "Food & Drink (food-fruit)",
        "order": 1453,
        "htmlcode": "&#127819"
    },
    {
        "index": 447,
        "emoji": "🍌",
        "name": "banana",
        "shortname": ":banana:",
        "unicode": "1f34c",
        "html": "&#127820;",
        "category": "Food & Drink (food-fruit)",
        "order": 1454,
        "htmlcode": "&#127820"
    },
    {
        "index": 448,
        "emoji": "🍍",
        "name": "pineapple",
        "shortname": ":pineapple:",
        "unicode": "1f34d",
        "html": "&#127821;",
        "category": "Food & Drink (food-fruit)",
        "order": 1455,
        "htmlcode": "&#127821"
    },
    {
        "index": 449,
        "emoji": "🥭",
        "name": "mango",
        "shortname": ":mango:",
        "unicode": "1F96D",
        "html": "&#x1F96D;",
        "category": "Food & Drink (food-fruit)",
        "order": 63.5,
        "htmlcode": "&#129389"
    },
    {
        "index": 450,
        "emoji": "🍎",
        "name": "red apple",
        "shortname": ":apple:",
        "unicode": "1f34e",
        "html": "&#127822;",
        "category": "Food & Drink (food-fruit)",
        "order": 1456,
        "htmlcode": "&#127822"
    },
    {
        "index": 451,
        "emoji": "🍏",
        "name": "green apple",
        "shortname": ":green_apple:",
        "unicode": "1f34f",
        "html": "&#127823;",
        "category": "Food & Drink (food-fruit)",
        "order": 1457,
        "htmlcode": "&#127823"
    },
    {
        "index": 452,
        "emoji": "🍐",
        "name": "pear",
        "shortname": ":pear:",
        "unicode": "1f350",
        "html": "&#127824;",
        "category": "Food & Drink (food-fruit)",
        "order": 1458,
        "htmlcode": "&#127824"
    },
    {
        "index": 453,
        "emoji": "🍑",
        "name": "peach",
        "shortname": ":peach:",
        "unicode": "1f351",
        "html": "&#127825;",
        "category": "Food & Drink (food-fruit)",
        "order": 1459,
        "htmlcode": "&#127825"
    },
    {
        "index": 454,
        "emoji": "🍒",
        "name": "cherries",
        "shortname": ":cherries:",
        "unicode": "1f352",
        "html": "&#127826;",
        "category": "Food & Drink (food-fruit)",
        "order": 1460,
        "htmlcode": "&#127826"
    },
    {
        "index": 455,
        "emoji": "🍓",
        "name": "strawberry",
        "shortname": ":strawberry:",
        "unicode": "1f353",
        "html": "&#127827;",
        "category": "Food & Drink (food-fruit)",
        "order": 1461,
        "htmlcode": "&#127827"
    },
    // {
    //     "index": 456,
    //     "htmlcode": "&#129744"
    // },
    {
        "index": 457,
        "emoji": "🥝",
        "name": "kiwi fruit",
        "shortname": ":kiwi_fruit:",
        "unicode": "1F95D",
        "html": "&#x1F95D;",
        "category": "Food & Drink (food-fruit)",
        "order": 63.5,
        "htmlcode": "&#129373"
    },
    {
        "index": 458,
        "emoji": "🍅",
        "name": "tomato",
        "shortname": ":tomato:",
        "unicode": "1f345",
        "html": "&#127813;",
        "category": "Food & Drink (food-fruit)",
        "order": 1463,
        "htmlcode": "&#127813"
    },
    // {
    //     "index": 459,
    //     "htmlcode": "&#129746"
    // },
    {
        "index": 460,
        "emoji": "🥥",
        "name": "coconut",
        "shortname": ":coconut:",
        "unicode": "1F965",
        "html": "&#x1F965;",
        "category": "Food & Drink (food-fruit)",
        "order": 63.5,
        "htmlcode": "&#129381"
    },
    {
        "index": 461,
        "emoji": "🥑",
        "name": "avocado",
        "shortname": ":avocado:",
        "unicode": "1F951",
        "html": "&#x1F951;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129361"
    },
    {
        "index": 462,
        "emoji": "🍆",
        "name": "eggplant",
        "shortname": ":eggplant:",
        "unicode": "1f346",
        "html": "&#127814;",
        "category": "Food & Drink (food-vegetable)",
        "order": 1465,
        "htmlcode": "&#127814"
    },
    {
        "index": 463,
        "emoji": "🥔",
        "name": "potato",
        "shortname": ":potato:",
        "unicode": "1F954",
        "html": "&#x1F954;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129364"
    },
    {
        "index": 464,
        "emoji": "🥕",
        "name": "carrot",
        "shortname": ":carrot:",
        "unicode": "1F955",
        "html": "&#x1F955;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129365"
    },
    {
        "index": 465,
        "emoji": "🌽",
        "name": "ear of corn",
        "shortname": ":corn:",
        "unicode": "1f33d",
        "html": "&#127805;",
        "category": "Food & Drink (food-vegetable)",
        "order": 1468,
        "htmlcode": "&#127805"
    },
    {
        "index": 466,
        "emoji": "🌶",
        "name": "hot pepper",
        "shortname": ":hot_pepper:",
        "unicode": "1f336",
        "html": "&#127798;",
        "category": "Food & Drink (food-vegetable)",
        "order": 1469,
        "htmlcode": "&#127798"
    },
    // {
    //     "index": 467,
    //     "htmlcode": "&#129745"
    // },
    {
        "index": 468,
        "emoji": "🥒",
        "name": "cucumber",
        "shortname": ":cucumber:",
        "unicode": "1F952",
        "html": "&#x1F952;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129362"
    },
    {
        "index": 469,
        "emoji": "🥬",
        "name": "leafy green",
        "shortname": ":leafy_green:",
        "unicode": "1F96C",
        "html": "&#x1F96C;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129388"
    },
    {
        "index": 470,
        "emoji": "🥦",
        "name": "broccoli",
        "shortname": ":broccoli:",
        "unicode": "1F966",
        "html": "&#x1F966;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129382"
    },
    {
        "index": 471,
        "emoji": "🧄",
        "name": "garlic",
        "shortname": ":garlic:",
        "unicode": "1F9C4",
        "html": "&#x1F9C4;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129476"
    },
    {
        "index": 472,
        "emoji": "🧅",
        "name": "onion",
        "shortname": ":onion:",
        "unicode": "1F9C5",
        "html": "&#x1F9C5;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129477"
    },
    {
        "index": 473,
        "emoji": "🍄",
        "name": "mushroom",
        "shortname": ":mushroom:",
        "unicode": "1f344",
        "html": "&#127812;",
        "category": "Food & Drink (food-vegetable)",
        "order": 1471,
        "htmlcode": "&#127812"
    },
    {
        "index": 474,
        "emoji": "🥜",
        "name": "peanuts",
        "shortname": ":peanuts:",
        "unicode": "1F95C",
        "html": "&#x1F95C;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#129372"
    },
    {
        "index": 475,
        "emoji": "🌰",
        "name": "chestnut",
        "shortname": ":chestnut:",
        "unicode": "1F330",
        "html": "&#127792;",
        "category": "Food & Drink (food-vegetable)",
        "order": 63.5,
        "htmlcode": "&#127792"
    },
    {
        "index": 476,
        "emoji": "🍞",
        "name": "bread",
        "shortname": ":bread:",
        "unicode": "1f35e",
        "html": "&#127838;",
        "category": "Food & Drink (food-prepared)",
        "order": 1474,
        "htmlcode": "&#127838"
    },
    {
        "index": 477,
        "emoji": "🥐",
        "name": "croissant",
        "shortname": ":croissant:",
        "unicode": "1F950",
        "html": "&#x1F950;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129360"
    },
    {
        "index": 478,
        "emoji": "🥖",
        "name": "baguette bread",
        "shortname": ":baguette_bread:",
        "unicode": "1F956",
        "html": "&#x1F956;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129366"
    },
    // {
    //     "index": 479,
    //     "htmlcode": "&#129747"
    // },
    {
        "index": 480,
        "emoji": "🥨",
        "name": "pretzel",
        "shortname": ":pretzel:",
        "unicode": "1F968",
        "html": "&#x1F968;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129384"
    },
    {
        "index": 481,
        "emoji": "🥯",
        "name": "bagel",
        "shortname": ":bagel:",
        "unicode": "1F96F",
        "html": "&#x1F96F;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129391"
    },
    {
        "index": 482,
        "emoji": "🥞",
        "name": "pancakes",
        "shortname": ":pancakes:",
        "unicode": "1F95E",
        "html": "&#x1F95E;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129374"
    },
    {
        "index": 483,
        "emoji": "🧇",
        "name": "waffle",
        "shortname": ":waffle:",
        "unicode": "1F9C7",
        "html": "&#x1F9C7;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129479"
    },
    {
        "index": 484,
        "emoji": "🧀",
        "name": "cheese wedge",
        "shortname": ":cheese_wedge:",
        "unicode": "1F9C0",
        "html": "&#129472;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129472"
    },
    {
        "index": 485,
        "emoji": "🍖",
        "name": "meat on bone",
        "shortname": ":meat_on_bone:",
        "unicode": "1f356",
        "html": "&#127830;",
        "category": "Food & Drink (food-prepared)",
        "order": 1479,
        "htmlcode": "&#127830"
    },
    {
        "index": 486,
        "emoji": "🍗",
        "name": "poultry leg",
        "shortname": ":poultry_leg:",
        "unicode": "1f357",
        "html": "&#127831;",
        "category": "Food & Drink (food-prepared)",
        "order": 1480,
        "htmlcode": "&#127831"
    },
    {
        "index": 487,
        "emoji": "🥩",
        "name": "cut of meat",
        "shortname": ":cut_of_meat:",
        "unicode": "1F969",
        "html": "&#x1F969;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129385"
    },
    {
        "index": 488,
        "emoji": "🥓",
        "name": "bacon",
        "shortname": ":bacon:",
        "unicode": "1F953",
        "html": "&#x1F953;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129363"
    },
    {
        "index": 489,
        "emoji": "🍔",
        "name": "hamburger",
        "shortname": ":hamburger:",
        "unicode": "1f354",
        "html": "&#127828;",
        "category": "Food & Drink (food-prepared)",
        "order": 1482,
        "htmlcode": "&#127828"
    },
    {
        "index": 490,
        "emoji": "🍟",
        "name": "french fries",
        "shortname": ":fries:",
        "unicode": "1f35f",
        "html": "&#127839;",
        "category": "Food & Drink (food-prepared)",
        "order": 1483,
        "htmlcode": "&#127839"
    },
    {
        "index": 491,
        "emoji": "🍕",
        "name": "pizza",
        "shortname": ":pizza:",
        "unicode": "1f355",
        "html": "&#127829;",
        "category": "Food & Drink (food-prepared)",
        "order": 1484,
        "htmlcode": "&#127829"
    },
    {
        "index": 492,
        "emoji": "🌭",
        "name": "hot dog",
        "shortname": ":hotdog:",
        "unicode": "1f32d",
        "html": "&#127789;",
        "category": "Food & Drink (food-prepared)",
        "order": 1485,
        "htmlcode": "&#127789"
    },
    {
        "index": 493,
        "emoji": "🥪",
        "name": "sandwich",
        "shortname": ":sandwich:",
        "unicode": "1F96A",
        "html": "&#x1F96A;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129386"
    },
    {
        "index": 494,
        "emoji": "🌮",
        "name": "taco",
        "shortname": ":taco:",
        "unicode": "1f32e",
        "html": "&#127790;",
        "category": "Food & Drink (food-prepared)",
        "order": 1486,
        "htmlcode": "&#127790"
    },
    {
        "index": 495,
        "emoji": "🌯",
        "name": "burrito",
        "shortname": ":burrito:",
        "unicode": "1f32f",
        "html": "&#127791;",
        "category": "Food & Drink (food-prepared)",
        "order": 1487,
        "htmlcode": "&#127791"
    },
    // {
    //     "index": 496,
    //     "htmlcode": "&#129748"
    // },
    {
        "index": 497,
        "emoji": "🥙",
        "name": "stuffed flatbread",
        "shortname": ":stuffed_flatbread:",
        "unicode": "1F959",
        "html": "&#x1F959;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129369"
    },
    {
        "index": 498,
        "emoji": "🧆",
        "name": "falafel",
        "shortname": ":falafel:",
        "unicode": "1F9C6",
        "html": "&#x1F9C6;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129478"
    },
    {
        "index": 499,
        "emoji": "🥚",
        "name": "egg",
        "shortname": ":egg:",
        "unicode": "1f95a",
        "html": "&#129370;",
        "category": "Food & Drink (food-prepared)",
        "order": 1489,
        "htmlcode": "&#129370"
    },
    {
        "index": 500,
        "emoji": "🥘",
        "name": "shallow pan of food",
        "shortname": ":shallow_pan_of_food:",
        "unicode": "1F958",
        "html": "&#x1F958;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129368"
    },
    {
        "index": 501,
        "emoji": "🍲",
        "name": "pot of food",
        "shortname": ":stew:",
        "unicode": "1f372",
        "html": "&#127858;",
        "category": "Food & Drink (food-prepared)",
        "order": 1492,
        "htmlcode": "&#127858"
    },
    // {
    //     "index": 502,
    //     "htmlcode": "&#129749"
    // },
    {
        "index": 503,
        "emoji": "🥣",
        "name": "bowl with spoon",
        "shortname": ":bowl_with_spoon:",
        "unicode": "1F963",
        "html": "&#x1F963;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129379"
    },
    {
        "index": 504,
        "emoji": "🥗",
        "name": "green salad",
        "shortname": ":green_salad:",
        "unicode": "1F957",
        "html": "&#x1F957;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129367"
    },
    {
        "index": 505,
        "emoji": "🍿",
        "name": "popcorn",
        "shortname": ":popcorn:",
        "unicode": "1f37f",
        "html": "&#127871;",
        "category": "Food & Drink (food-prepared)",
        "order": 1494,
        "htmlcode": "&#127871"
    },
    {
        "index": 506,
        "emoji": "🧈",
        "name": "butter",
        "shortname": ":butter:",
        "unicode": "1F9C8",
        "html": "&#x1F9C8;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129480"
    },
    {
        "index": 507,
        "emoji": "🧂",
        "name": "salt",
        "shortname": ":salt:",
        "unicode": "1F9C2",
        "html": "&#x1F9C2;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129474"
    },
    {
        "index": 508,
        "emoji": "🥫",
        "name": "canned food",
        "shortname": ":canned_food:",
        "unicode": "1F96B",
        "html": "&#x1F96B;",
        "category": "Food & Drink (food-prepared)",
        "order": 63.5,
        "htmlcode": "&#129387"
    },
    {
        "index": 509,
        "emoji": "🍱",
        "name": "bento box",
        "shortname": ":bento:",
        "unicode": "1f371",
        "html": "&#127857;",
        "category": "Food & Drink (food-asian)",
        "order": 1495,
        "htmlcode": "&#127857"
    },
    {
        "index": 510,
        "emoji": "🍘",
        "name": "rice cracker",
        "shortname": ":rice_cracker:",
        "unicode": "1f358",
        "html": "&#127832;",
        "category": "Food & Drink (food-asian)",
        "order": 1496,
        "htmlcode": "&#127832"
    },
    {
        "index": 511,
        "emoji": "🍙",
        "name": "rice ball",
        "shortname": ":rice_ball:",
        "unicode": "1f359",
        "html": "&#127833;",
        "category": "Food & Drink (food-asian)",
        "order": 1497,
        "htmlcode": "&#127833"
    },
    {
        "index": 512,
        "emoji": "🍚",
        "name": "cooked rice",
        "shortname": ":rice:",
        "unicode": "1f35a",
        "html": "&#127834;",
        "category": "Food & Drink (food-asian)",
        "order": 1498,
        "htmlcode": "&#127834"
    },
    {
        "index": 513,
        "emoji": "🍛",
        "name": "curry rice",
        "shortname": ":curry:",
        "unicode": "1f35b",
        "html": "&#127835;",
        "category": "Food & Drink (food-asian)",
        "order": 1499,
        "htmlcode": "&#127835"
    },
    {
        "index": 514,
        "emoji": "🍜",
        "name": "steaming bowl",
        "shortname": ":ramen:",
        "unicode": "1f35c",
        "html": "&#127836;",
        "category": "Food & Drink (food-asian)",
        "order": 1500,
        "htmlcode": "&#127836"
    },
    {
        "index": 515,
        "emoji": "🍝",
        "name": "spaghetti",
        "shortname": ":spaghetti:",
        "unicode": "1f35d",
        "html": "&#127837;",
        "category": "Food & Drink (food-asian)",
        "order": 1501,
        "htmlcode": "&#127837"
    },
    {
        "index": 516,
        "emoji": "🍠",
        "name": "roasted sweet potato",
        "shortname": ":sweet_potato:",
        "unicode": "1f360",
        "html": "&#127840;",
        "category": "Food & Drink (food-asian)",
        "order": 1502,
        "htmlcode": "&#127840"
    },
    {
        "index": 517,
        "emoji": "🍢",
        "name": "oden",
        "shortname": ":oden:",
        "unicode": "1f362",
        "html": "&#127842;",
        "category": "Food & Drink (food-asian)",
        "order": 1503,
        "htmlcode": "&#127842"
    },
    {
        "index": 518,
        "emoji": "🍣",
        "name": "sushi",
        "shortname": ":sushi:",
        "unicode": "1f363",
        "html": "&#127843;",
        "category": "Food & Drink (food-asian)",
        "order": 1504,
        "htmlcode": "&#127843"
    },
    {
        "index": 519,
        "emoji": "🍤",
        "name": "fried shrimp",
        "shortname": ":fried_shrimp:",
        "unicode": "1f364",
        "html": "&#127844;",
        "category": "Food & Drink (food-asian)",
        "order": 1505,
        "htmlcode": "&#127844"
    },
    {
        "index": 520,
        "emoji": "🍥",
        "name": "fish cake with swirl",
        "shortname": ":fish_cake:",
        "unicode": "1f365",
        "html": "&#127845;",
        "category": "Food & Drink (food-asian)",
        "order": 1506,
        "htmlcode": "&#127845"
    },
    {
        "index": 521,
        "emoji": "🥮",
        "name": "moon cake",
        "shortname": ":moon_cake:",
        "unicode": "1F96E",
        "html": "&#x1F96E;",
        "category": "Food & Drink (food-asian)",
        "order": 63.5,
        "htmlcode": "&#129390"
    },
    {
        "index": 522,
        "emoji": "🍡",
        "name": "dango",
        "shortname": ":dango:",
        "unicode": "1f361",
        "html": "&#127841;",
        "category": "Food & Drink (food-asian)",
        "order": 1507,
        "htmlcode": "&#127841"
    },
    {
        "index": 523,
        "emoji": "🥟",
        "name": "dumpling",
        "shortname": ":dumpling:",
        "unicode": "1F95F",
        "html": "&#x1F95F;",
        "category": "Food & Drink (food-asian)",
        "order": 63.5,
        "htmlcode": "&#129375"
    },
    {
        "index": 524,
        "emoji": "🥠",
        "name": "fortune cookie",
        "shortname": ":fortune_cookie:",
        "unicode": "1F960",
        "html": "&#x1F960;",
        "category": "Food & Drink (food-asian)",
        "order": 63.5,
        "htmlcode": "&#129376"
    },
    {
        "index": 525,
        "emoji": "🥡",
        "name": "takeout box",
        "shortname": ":takeout_box:",
        "unicode": "1F961",
        "html": "&#x1F961;",
        "category": "Food & Drink (food-asian)",
        "order": 63.5,
        "htmlcode": "&#129377"
    },
    {
        "index": 526,
        "emoji": "🦀",
        "name": "crab",
        "shortname": ":crab:",
        "unicode": "1f980",
        "html": "&#129408;",
        "category": "Food & Drink (food-marine)",
        "order": 1415,
        "htmlcode": "&#129408"
    },
    {
        "index": 527,
        "emoji": "🦞",
        "name": "lobster",
        "shortname": ":lobster:",
        "unicode": "1F99E",
        "html": "&#x1F99E;",
        "category": "Food & Drink (food-marine)",
        "order": 63.5,
        "htmlcode": "&#129438"
    },
    {
        "index": 528,
        "emoji": "🦐",
        "name": "shrimp",
        "shortname": ":shrimp:",
        "unicode": "1F990",
        "html": "&#x1F990;",
        "category": "Food & Drink (food-marine)",
        "order": 63.5,
        "htmlcode": "&#129424"
    },
    {
        "index": 529,
        "emoji": "🦑",
        "name": "squid",
        "shortname": ":squid:",
        "unicode": "1F991",
        "html": "&#x1F991;",
        "category": "Food & Drink (food-marine)",
        "order": 63.5,
        "htmlcode": "&#129425"
    },
    {
        "index": 530,
        "emoji": "🦪",
        "name": "oyster",
        "shortname": ":oyster:",
        "unicode": "1F9AA",
        "html": "&#x1F9AA;",
        "category": "Food & Drink (food-marine)",
        "order": 63.5,
        "htmlcode": "&#129450"
    },
    {
        "index": 531,
        "emoji": "🍦",
        "name": "soft ice cream",
        "shortname": ":icecream:",
        "unicode": "1f366",
        "html": "&#127846;",
        "category": "Food & Drink (food-sweet)",
        "order": 1508,
        "htmlcode": "&#127846"
    },
    {
        "index": 532,
        "emoji": "🍧",
        "name": "shaved ice",
        "shortname": ":shaved_ice:",
        "unicode": "1f367",
        "html": "&#127847;",
        "category": "Food & Drink (food-sweet)",
        "order": 1509,
        "htmlcode": "&#127847"
    },
    {
        "index": 533,
        "emoji": "🍨",
        "name": "ice cream",
        "shortname": ":ice_cream:",
        "unicode": "1f368",
        "html": "&#127848;",
        "category": "Food & Drink (food-sweet)",
        "order": 1510,
        "htmlcode": "&#127848"
    },
    {
        "index": 534,
        "emoji": "🍩",
        "name": "doughnut",
        "shortname": ":doughnut:",
        "unicode": "1f369",
        "html": "&#127849;",
        "category": "Food & Drink (food-sweet)",
        "order": 1511,
        "htmlcode": "&#127849"
    },
    {
        "index": 535,
        "emoji": "🍪",
        "name": "cookie",
        "shortname": ":cookie:",
        "unicode": "1f36a",
        "html": "&#127850;",
        "category": "Food & Drink (food-sweet)",
        "order": 1512,
        "htmlcode": "&#127850"
    },
    {
        "index": 536,
        "emoji": "🎂",
        "name": "birthday cake",
        "shortname": ":birthday:",
        "unicode": "1f382",
        "html": "&#127874;",
        "category": "Food & Drink (food-sweet)",
        "order": 1513,
        "htmlcode": "&#127874"
    },
    {
        "index": 537,
        "emoji": "🍰",
        "name": "shortcake",
        "shortname": ":cake:",
        "unicode": "1f370",
        "html": "&#127856;",
        "category": "Food & Drink (food-sweet)",
        "order": 1514,
        "htmlcode": "&#127856"
    },
    {
        "index": 538,
        "emoji": "🧁",
        "name": "cupcake",
        "shortname": ":cupcake:",
        "unicode": "1F9C1",
        "html": "&#x1F9C1;",
        "category": "Food & Drink (food-sweet)",
        "order": 63.5,
        "htmlcode": "&#129473"
    },
    {
        "index": 539,
        "emoji": "🥧",
        "name": "pie",
        "shortname": ":pie:",
        "unicode": "1F967",
        "html": "&#x1F967;",
        "category": "Food & Drink (food-sweet)",
        "order": 63.5,
        "htmlcode": "&#129383"
    },
    {
        "index": 540,
        "emoji": "🍫",
        "name": "chocolate bar",
        "shortname": ":chocolate_bar:",
        "unicode": "1f36b",
        "html": "&#127851;",
        "category": "Food & Drink (food-sweet)",
        "order": 1515,
        "htmlcode": "&#127851"
    },
    {
        "index": 541,
        "emoji": "🍬",
        "name": "candy",
        "shortname": ":candy:",
        "unicode": "1f36c",
        "html": "&#127852;",
        "category": "Food & Drink (food-sweet)",
        "order": 1516,
        "htmlcode": "&#127852"
    },
    {
        "index": 542,
        "emoji": "🍭",
        "name": "lollipop",
        "shortname": ":lollipop:",
        "unicode": "1f36d",
        "html": "&#127853;",
        "category": "Food & Drink (food-sweet)",
        "order": 1517,
        "htmlcode": "&#127853"
    },
    {
        "index": 543,
        "emoji": "🍮",
        "name": "custard",
        "shortname": ":custard:",
        "unicode": "1f36e",
        "html": "&#127854;",
        "category": "Food & Drink (food-sweet)",
        "order": 1518,
        "htmlcode": "&#127854"
    },
    {
        "index": 544,
        "emoji": "🍯",
        "name": "honey pot",
        "shortname": ":honey_pot:",
        "unicode": "1f36f",
        "html": "&#127855;",
        "category": "Food & Drink (food-sweet)",
        "order": 1519,
        "htmlcode": "&#127855"
    },
    {
        "index": 545,
        "emoji": "🥛",
        "name": "glass of milk",
        "shortname": ":glass_of_milk:",
        "unicode": "1F95B",
        "html": "&#x1F95B;",
        "category": "Food & Drink (drink)",
        "order": 63.5,
        "htmlcode": "&#129371"
    },
    // {
    //     "index": 546,
    //     "htmlcode": "&#129750"
    // },
    {
        "index": 547,
        "emoji": "🍵",
        "name": "teacup without handle",
        "shortname": ":tea:",
        "unicode": "1f375",
        "html": "&#127861;",
        "category": "Food & Drink (drink)",
        "order": 1523,
        "htmlcode": "&#127861"
    },
    {
        "index": 548,
        "emoji": "🍶",
        "name": "sake",
        "shortname": ":sake:",
        "unicode": "1f376",
        "html": "&#127862;",
        "category": "Food & Drink (drink)",
        "order": 1524,
        "htmlcode": "&#127862"
    },
    {
        "index": 549,
        "emoji": "🍾",
        "name": "bottle with popping cork",
        "shortname": ":champagne:",
        "unicode": "1f37e",
        "html": "&#127870;",
        "category": "Food & Drink (drink)",
        "order": 1525,
        "htmlcode": "&#127870"
    },
    {
        "index": 550,
        "emoji": "🍷",
        "name": "wine glass",
        "shortname": ":wine_glass:",
        "unicode": "1f377",
        "html": "&#127863;",
        "category": "Food & Drink (drink)",
        "order": 1526,
        "htmlcode": "&#127863"
    },
    {
        "index": 551,
        "emoji": "🍸",
        "name": "cocktail glass",
        "shortname": ":cocktail:",
        "unicode": "1f378",
        "html": "&#127864;",
        "category": "Food & Drink (drink)",
        "order": 1527,
        "htmlcode": "&#127864"
    },
    {
        "index": 552,
        "emoji": "🍹",
        "name": "tropical drink",
        "shortname": ":tropical_drink:",
        "unicode": "1f379",
        "html": "&#127865;",
        "category": "Food & Drink (drink)",
        "order": 1528,
        "htmlcode": "&#127865"
    },
    {
        "index": 553,
        "emoji": "🍺",
        "name": "beer mug",
        "shortname": ":beer:",
        "unicode": "1f37a",
        "html": "&#127866;",
        "category": "Food & Drink (drink)",
        "order": 1529,
        "htmlcode": "&#127866"
    },
    {
        "index": 554,
        "emoji": "🍻",
        "name": "clinking beer mugs",
        "shortname": ":beers:",
        "unicode": "1f37b",
        "html": "&#127867;",
        "category": "Food & Drink (drink)",
        "order": 1530,
        "htmlcode": "&#127867"
    },
    {
        "index": 555,
        "emoji": "🥂",
        "name": "clinking glasses",
        "shortname": ":clinking_glasses:",
        "unicode": "1F942",
        "html": "&#x1F942;",
        "category": "Food & Drink (drink)",
        "order": 63.5,
        "htmlcode": "&#129346"
    },
    {
        "index": 556,
        "emoji": "🥃",
        "name": "tumbler glass",
        "shortname": ":tumbler_glass:",
        "unicode": "1F943",
        "html": "&#x1F943;",
        "category": "Food & Drink (drink)",
        "order": 63.5,
        "htmlcode": "&#129347"
    },
    {
        "index": 557,
        "emoji": "🥤",
        "name": "cup with straw",
        "shortname": ":cup_with_straw:",
        "unicode": "1F964",
        "html": "&#x1F964;",
        "category": "Food & Drink (drink)",
        "order": 63.5,
        "htmlcode": "&#129380"
    },
    // {
    //     "index": 558,
    //     "htmlcode": "&#129483"
    // },
    {
        "index": 559,
        "emoji": "🧃",
        "name": "beverage box",
        "shortname": ":beverage_box:",
        "unicode": "1F9C3",
        "html": "&#x1F9C3;",
        "category": "Food & Drink (drink)",
        "order": 63.5,
        "htmlcode": "&#129475"
    },
    {
        "index": 560,
        "emoji": "🧉",
        "name": "mate",
        "shortname": ":mate:",
        "unicode": "1F9C9",
        "html": "&#x1F9C9;",
        "category": "Food & Drink (drink)",
        "order": 63.5,
        "htmlcode": "&#129481"
    },
    {
        "index": 561,
        "emoji": "🧊",
        "name": "ice",
        "shortname": ":ice:",
        "unicode": "1F9CA",
        "html": "&#x1F9CA;",
        "category": "Food & Drink (drink)",
        "order": 63.5,
        "htmlcode": "&#129482"
    },
    {
        "index": 562,
        "emoji": "🥢",
        "name": "chopsticks",
        "shortname": ":chopsticks:",
        "unicode": "1F962",
        "html": "&#x1F962;",
        "category": "Food & Drink (dishware)",
        "order": 63.5,
        "htmlcode": "&#129378"
    },
    {
        "index": 563,
        "emoji": "🍽",
        "name": "fork and knife with plate",
        "shortname": ":fork_and_knife_with_plate:",
        "unicode": "1F37D",
        "html": "&#127869;",
        "category": "Food & Drink (dishware)",
        "order": 63.5,
        "htmlcode": "&#127869"
    },
    {
        "index": 564,
        "emoji": "🍴",
        "name": "fork and knife",
        "shortname": ":fork_and_knife:",
        "unicode": "1f374",
        "html": "&#127860;",
        "category": "Food & Drink (dishware)",
        "order": 1534,
        "htmlcode": "&#127860"
    },
    {
        "index": 565,
        "emoji": "🥄",
        "name": "spoon",
        "shortname": ":spoon:",
        "unicode": "1F944",
        "html": "&#x1F944;",
        "category": "Food & Drink (dishware)",
        "order": 63.5,
        "htmlcode": "&#129348"
    },
    {
        "index": 566,
        "emoji": "🔪",
        "name": "kitchen knife",
        "shortname": ":kitchen_knife:",
        "unicode": "1F52A",
        "html": "&#128298;",
        "category": "Food & Drink (dishware)",
        "order": 63.5,
        "htmlcode": "&#128298"
    },
    {
        "index": 567,
        "emoji": "🏺",
        "name": "amphora",
        "shortname": ":amphora:",
        "unicode": "1f3fa",
        "html": "&#127994;",
        "category": "Food & Drink (dishware)",
        "order": 1537,
        "htmlcode": "&#127994"
    },
    {
        "index": 568,
        "emoji": "🌍",
        "name": "globe showing Europe-Africa",
        "shortname": ":earth_africa:",
        "unicode": "1f30d",
        "html": "&#127757;",
        "category": "Travel & Places (place-map)",
        "order": 1538,
        "htmlcode": "&#127757"
    },
    {
        "index": 569,
        "emoji": "🌎",
        "name": "globe showing Americas",
        "shortname": ":earth_americas:",
        "unicode": "1f30e",
        "html": "&#127758;",
        "category": "Travel & Places (place-map)",
        "order": 1539,
        "htmlcode": "&#127758"
    },
    {
        "index": 570,
        "emoji": "🌏",
        "name": "globe showing Asia-Australia",
        "shortname": ":earth_asia:",
        "unicode": "1f30f",
        "html": "&#127759;",
        "category": "Travel & Places (place-map)",
        "order": 1540,
        "htmlcode": "&#127759"
    },
    {
        "index": 571,
        "emoji": "🌐",
        "name": "globe with meridians",
        "shortname": ":globe_with_meridians:",
        "unicode": "1f310",
        "html": "&#127760;",
        "category": "Travel & Places (place-map)",
        "order": 1541,
        "htmlcode": "&#127760"
    },
    {
        "index": 572,
        "emoji": "🗺",
        "name": "world map",
        "shortname": ":world_map:",
        "unicode": "1F5FA",
        "html": "&#128506;",
        "category": "Travel & Places (place-map)",
        "order": 63.5,
        "htmlcode": "&#128506"
    },
    {
        "index": 573,
        "emoji": "🗾",
        "name": "map of Japan",
        "shortname": ":japan:",
        "unicode": "1f5fe",
        "html": "&#128510;",
        "category": "Travel & Places (place-map)",
        "order": 1543,
        "htmlcode": "&#128510"
    },
    {
        "index": 574,
        "emoji": "🧭",
        "name": "compass",
        "shortname": ":compass:",
        "unicode": "1F9ED",
        "html": "&#x1F9ED;",
        "category": "Travel & Places (place-map)",
        "order": 63.5,
        "htmlcode": "&#129517"
    },
    {
        "index": 575,
        "emoji": "🏔",
        "name": "snow-capped mountain",
        "shortname": ":snowcapped_mountain:",
        "unicode": "1F3D4",
        "html": "&#127956;",
        "category": "Travel & Places (place-geographic)",
        "order": 63.5,
        "htmlcode": "&#127956"
    },
    {
        "index": 576,
        "emoji": "🌋",
        "name": "volcano",
        "shortname": ":volcano:",
        "unicode": "1f30b",
        "html": "&#127755;",
        "category": "Travel & Places (place-geographic)",
        "order": 1546,
        "htmlcode": "&#127755"
    },
    {
        "index": 577,
        "emoji": "🗻",
        "name": "mount fuji",
        "shortname": ":mount_fuji:",
        "unicode": "1f5fb",
        "html": "&#128507;",
        "category": "Travel & Places (place-geographic)",
        "order": 1547,
        "htmlcode": "&#128507"
    },
    {
        "index": 578,
        "emoji": "🏕",
        "name": "camping",
        "shortname": ":camping:",
        "unicode": "1f3d5",
        "html": "&#127957;",
        "category": "Travel & Places (place-geographic)",
        "order": 1548,
        "htmlcode": "&#127957"
    },
    {
        "index": 579,
        "emoji": "🏖",
        "name": "beach with umbrella",
        "shortname": ":beach_with_umbrella:",
        "unicode": "1F3D6",
        "html": "&#127958;",
        "category": "Travel & Places (place-geographic)",
        "order": 63.5,
        "htmlcode": "&#127958"
    },
    {
        "index": 580,
        "emoji": "🏜",
        "name": "desert",
        "shortname": ":desert:",
        "unicode": "1f3dc",
        "html": "&#127964;",
        "category": "Travel & Places (place-geographic)",
        "order": 1550,
        "htmlcode": "&#127964"
    },
    {
        "index": 581,
        "emoji": "🏝",
        "name": "desert island",
        "shortname": ":desert_island:",
        "unicode": "1F3DD",
        "html": "&#127965;",
        "category": "Travel & Places (place-geographic)",
        "order": 63.5,
        "htmlcode": "&#127965"
    },
    {
        "index": 582,
        "emoji": "🏞",
        "name": "national park",
        "shortname": ":national_park:",
        "unicode": "1F3DE",
        "html": "&#127966;",
        "category": "Travel & Places (place-geographic)",
        "order": 63.5,
        "htmlcode": "&#127966"
    },
    {
        "index": 583,
        "emoji": "🏟",
        "name": "stadium",
        "shortname": ":stadium:",
        "unicode": "1f3df",
        "html": "&#127967;",
        "category": "Travel & Places (place-building)",
        "order": 1553,
        "htmlcode": "&#127967"
    },
    {
        "index": 584,
        "emoji": "🏛",
        "name": "classical building",
        "shortname": ":classical_building:",
        "unicode": "1f3db",
        "html": "&#127963;",
        "category": "Travel & Places (place-building)",
        "order": 1554,
        "htmlcode": "&#127963"
    },
    {
        "index": 585,
        "emoji": "🏗",
        "name": "building construction",
        "shortname": ":building_construction:",
        "unicode": "1F3D7",
        "html": "&#127959;",
        "category": "Travel & Places (place-building)",
        "order": 63.5,
        "htmlcode": "&#127959"
    },
    {
        "index": 586,
        "emoji": "🧱",
        "name": "brick",
        "shortname": ":brick:",
        "unicode": "1F9F1",
        "html": "&#x1F9F1;",
        "category": "Travel & Places (place-building)",
        "order": 63.5,
        "htmlcode": "&#129521"
    },
    // {
    //     "index": 587,
    //     "htmlcode": "&#129704"
    // },
    // {
    //     "index": 588,
    //     "htmlcode": "&#129717"
    // },
    // {
    //     "index": 589,
    //     "htmlcode": "&#128726"
    // },
    {
        "index": 590,
        "emoji": "🏘",
        "name": "houses",
        "shortname": ":houses:",
        "unicode": "1F3D8",
        "html": "&#127960;",
        "category": "Travel & Places (place-building)",
        "order": 63.5,
        "htmlcode": "&#127960"
    },
    {
        "index": 591,
        "emoji": "🏚",
        "name": "derelict house",
        "shortname": ":derelict_house:",
        "unicode": "1F3DA",
        "html": "&#127962;",
        "category": "Travel & Places (place-building)",
        "order": 63.5,
        "htmlcode": "&#127962"
    },
    {
        "index": 592,
        "emoji": "🏠",
        "name": "house",
        "shortname": ":house:",
        "unicode": "1f3e0",
        "html": "&#127968;",
        "category": "Travel & Places (place-building)",
        "order": 1559,
        "htmlcode": "&#127968"
    },
    {
        "index": 593,
        "emoji": "🏡",
        "name": "house with garden",
        "shortname": ":house_with_garden:",
        "unicode": "1f3e1",
        "html": "&#127969;",
        "category": "Travel & Places (place-building)",
        "order": 1560,
        "htmlcode": "&#127969"
    },
    {
        "index": 594,
        "emoji": "🏢",
        "name": "office building",
        "shortname": ":office:",
        "unicode": "1f3e2",
        "html": "&#127970;",
        "category": "Travel & Places (place-building)",
        "order": 1561,
        "htmlcode": "&#127970"
    },
    {
        "index": 595,
        "emoji": "🏣",
        "name": "Japanese post office",
        "shortname": ":post_office:",
        "unicode": "1f3e3",
        "html": "&#127971;",
        "category": "Travel & Places (place-building)",
        "order": 1562,
        "htmlcode": "&#127971"
    },
    {
        "index": 596,
        "emoji": "🏤",
        "name": "post office",
        "shortname": ":european_post_office:",
        "unicode": "1f3e4",
        "html": "&#127972;",
        "category": "Travel & Places (place-building)",
        "order": 1563,
        "htmlcode": "&#127972"
    },
    {
        "index": 597,
        "emoji": "🏥",
        "name": "hospital",
        "shortname": ":hospital:",
        "unicode": "1f3e5",
        "html": "&#127973;",
        "category": "Travel & Places (place-building)",
        "order": 1564,
        "htmlcode": "&#127973"
    },
    {
        "index": 598,
        "emoji": "🏦",
        "name": "bank",
        "shortname": ":bank:",
        "unicode": "1f3e6",
        "html": "&#127974;",
        "category": "Travel & Places (place-building)",
        "order": 1565,
        "htmlcode": "&#127974"
    },
    {
        "index": 599,
        "emoji": "🏨",
        "name": "hotel",
        "shortname": ":hotel:",
        "unicode": "1f3e8",
        "html": "&#127976;",
        "category": "Travel & Places (place-building)",
        "order": 1566,
        "htmlcode": "&#127976"
    },
    {
        "index": 600,
        "emoji": "🏩",
        "name": "love hotel",
        "shortname": ":love_hotel:",
        "unicode": "1f3e9",
        "html": "&#127977;",
        "category": "Travel & Places (place-building)",
        "order": 1567,
        "htmlcode": "&#127977"
    },
    {
        "index": 601,
        "emoji": "🏪",
        "name": "convenience store",
        "shortname": ":convenience_store:",
        "unicode": "1f3ea",
        "html": "&#127978;",
        "category": "Travel & Places (place-building)",
        "order": 1568,
        "htmlcode": "&#127978"
    },
    {
        "index": 602,
        "emoji": "🏬",
        "name": "department store",
        "shortname": ":department_store:",
        "unicode": "1f3ec",
        "html": "&#127980;",
        "category": "Travel & Places (place-building)",
        "order": 1570,
        "htmlcode": "&#127980"
    },
    {
        "index": 603,
        "emoji": "🏯",
        "name": "Japanese castle",
        "shortname": ":japanese_castle:",
        "unicode": "1f3ef",
        "html": "&#127983;",
        "category": "Travel & Places (place-building)",
        "order": 1572,
        "htmlcode": "&#127983"
    },
    {
        "index": 604,
        "emoji": "🏰",
        "name": "castle",
        "shortname": ":european_castle:",
        "unicode": "1f3f0",
        "html": "&#127984;",
        "category": "Travel & Places (place-building)",
        "order": 1573,
        "htmlcode": "&#127984"
    },
    {
        "index": 605,
        "emoji": "💒",
        "name": "wedding",
        "shortname": ":wedding:",
        "unicode": "1f492",
        "html": "&#128146;",
        "category": "Travel & Places (place-building)",
        "order": 1574,
        "htmlcode": "&#128146"
    },
    {
        "index": 606,
        "emoji": "🗼",
        "name": "Tokyo tower",
        "shortname": ":tokyo_tower:",
        "unicode": "1f5fc",
        "html": "&#128508;",
        "category": "Travel & Places (place-building)",
        "order": 1575,
        "htmlcode": "&#128508"
    },
    {
        "index": 607,
        "emoji": "🗽",
        "name": "Statue of Liberty",
        "shortname": ":statue_of_liberty:",
        "unicode": "1f5fd",
        "html": "&#128509;",
        "category": "Travel & Places (place-building)",
        "order": 1576,
        "htmlcode": "&#128509"
    },
    {
        "index": 608,
        "emoji": "🕌",
        "name": "mosque",
        "shortname": ":mosque:",
        "unicode": "1f54c",
        "html": "&#128332;",
        "category": "Travel & Places (place-religious)",
        "order": 1578,
        "htmlcode": "&#128332"
    },
    {
        "index": 609,
        "emoji": "🛕",
        "name": "hindu temple",
        "shortname": ":hindu_temple:",
        "unicode": "1F6D5",
        "html": "&#x1F6D5;",
        "category": "Travel & Places (place-religious)",
        "order": 63.5,
        "htmlcode": "&#128725"
    },
    {
        "index": 610,
        "emoji": "🕍",
        "name": "synagogue",
        "shortname": ":synagogue:",
        "unicode": "1f54d",
        "html": "&#128333;",
        "category": "Travel & Places (place-religious)",
        "order": 1579,
        "htmlcode": "&#128333"
    },
    {
        "index": 611,
        "emoji": "🕋",
        "name": "kaaba",
        "shortname": ":kaaba:",
        "unicode": "1f54b",
        "html": "&#128331;",
        "category": "Travel & Places (place-religious)",
        "order": 1581,
        "htmlcode": "&#128331"
    },
    {
        "index": 612,
        "emoji": "🌁",
        "name": "foggy",
        "shortname": ":foggy:",
        "unicode": "1f301",
        "html": "&#127745;",
        "category": "Travel & Places (place-other)",
        "order": 1584,
        "htmlcode": "&#127745"
    },
    {
        "index": 613,
        "emoji": "🌃",
        "name": "night with stars",
        "shortname": ":night_with_stars:",
        "unicode": "1f303",
        "html": "&#127747;",
        "category": "Travel & Places (place-other)",
        "order": 1585,
        "htmlcode": "&#127747"
    },
    {
        "index": 614,
        "emoji": "🏙",
        "name": "cityscape",
        "shortname": ":cityscape:",
        "unicode": "1f3d9",
        "html": "&#127961;",
        "category": "Travel & Places (place-other)",
        "order": 1557,
        "htmlcode": "&#127961"
    },
    {
        "index": 615,
        "emoji": "🌄",
        "name": "sunrise over mountains",
        "shortname": ":sunrise_over_mountains:",
        "unicode": "1f304",
        "html": "&#127748;",
        "category": "Travel & Places (place-other)",
        "order": 1586,
        "htmlcode": "&#127748"
    },
    {
        "index": 616,
        "emoji": "🌅",
        "name": "sunrise",
        "shortname": ":sunrise:",
        "unicode": "1f305",
        "html": "&#127749;",
        "category": "Travel & Places (place-other)",
        "order": 1587,
        "htmlcode": "&#127749"
    },
    {
        "index": 617,
        "emoji": "🌆",
        "name": "cityscape at dusk",
        "shortname": ":cityscape_at_dusk:",
        "unicode": "1F306",
        "html": "&#127750;",
        "category": "Travel & Places (place-other)",
        "order": 63.5,
        "htmlcode": "&#127750"
    },
    {
        "index": 618,
        "emoji": "🌇",
        "name": "sunset",
        "shortname": ":city_sunset:",
        "unicode": "1f307",
        "html": "&#127751;",
        "category": "Travel & Places (place-other)",
        "order": 1589,
        "htmlcode": "&#127751"
    },
    {
        "index": 619,
        "emoji": "🌉",
        "name": "bridge at night",
        "shortname": ":bridge_at_night:",
        "unicode": "1f309",
        "html": "&#127753;",
        "category": "Travel & Places (place-other)",
        "order": 1590,
        "htmlcode": "&#127753"
    },
    {
        "index": 620,
        "emoji": "🎠",
        "name": "carousel horse",
        "shortname": ":carousel_horse:",
        "unicode": "1f3a0",
        "html": "&#127904;",
        "category": "Travel & Places (place-other)",
        "order": 1593,
        "htmlcode": "&#127904"
    },
    {
        "index": 621,
        "emoji": "🎡",
        "name": "ferris wheel",
        "shortname": ":ferris_wheel:",
        "unicode": "1f3a1",
        "html": "&#127905;",
        "category": "Travel & Places (place-other)",
        "order": 1594,
        "htmlcode": "&#127905"
    },
    {
        "index": 622,
        "emoji": "🎢",
        "name": "roller coaster",
        "shortname": ":roller_coaster:",
        "unicode": "1f3a2",
        "html": "&#127906;",
        "category": "Travel & Places (place-other)",
        "order": 1595,
        "htmlcode": "&#127906"
    },
    {
        "index": 623,
        "emoji": "💈",
        "name": "barber pole",
        "shortname": ":barber:",
        "unicode": "1f488",
        "html": "&#128136;",
        "category": "Travel & Places (place-other)",
        "order": 1596,
        "htmlcode": "&#128136"
    },
    {
        "index": 624,
        "emoji": "🎪",
        "name": "circus tent",
        "shortname": ":circus_tent:",
        "unicode": "1f3aa",
        "html": "&#127914;",
        "category": "Travel & Places (place-other)",
        "order": 1597,
        "htmlcode": "&#127914"
    },
    {
        "index": 625,
        "emoji": "🚂",
        "name": "locomotive",
        "shortname": ":steam_locomotive:",
        "unicode": "1f682",
        "html": "&#128642;",
        "category": "Travel & Places (transport-ground)",
        "order": 1602,
        "htmlcode": "&#128642"
    },
    {
        "index": 626,
        "emoji": "🚃",
        "name": "railway car",
        "shortname": ":railway_car:",
        "unicode": "1f683",
        "html": "&#128643;",
        "category": "Travel & Places (transport-ground)",
        "order": 1603,
        "htmlcode": "&#128643"
    },
    {
        "index": 627,
        "emoji": "🚄",
        "name": "high-speed train",
        "shortname": ":bullettrain_side:",
        "unicode": "1f684",
        "html": "&#128644;",
        "category": "Travel & Places (transport-ground)",
        "order": 1604,
        "htmlcode": "&#128644"
    },
    {
        "index": 628,
        "emoji": "🚅",
        "name": "bullet train",
        "shortname": ":bullettrain_front:",
        "unicode": "1f685",
        "html": "&#128645;",
        "category": "Travel & Places (transport-ground)",
        "order": 1605,
        "htmlcode": "&#128645"
    },
    {
        "index": 629,
        "emoji": "🚆",
        "name": "train",
        "shortname": ":train2:",
        "unicode": "1f686",
        "html": "&#128646;",
        "category": "Travel & Places (transport-ground)",
        "order": 1606,
        "htmlcode": "&#128646"
    },
    {
        "index": 630,
        "emoji": "🚇",
        "name": "metro",
        "shortname": ":metro:",
        "unicode": "1f687",
        "html": "&#128647;",
        "category": "Travel & Places (transport-ground)",
        "order": 1607,
        "htmlcode": "&#128647"
    },
    {
        "index": 631,
        "emoji": "🚈",
        "name": "light rail",
        "shortname": ":light_rail:",
        "unicode": "1f688",
        "html": "&#128648;",
        "category": "Travel & Places (transport-ground)",
        "order": 1608,
        "htmlcode": "&#128648"
    },
    {
        "index": 632,
        "emoji": "🚉",
        "name": "station",
        "shortname": ":station:",
        "unicode": "1f689",
        "html": "&#128649;",
        "category": "Travel & Places (transport-ground)",
        "order": 1609,
        "htmlcode": "&#128649"
    },
    {
        "index": 633,
        "emoji": "🚊",
        "name": "tram",
        "shortname": ":tram:",
        "unicode": "1f68a",
        "html": "&#128650;",
        "category": "Travel & Places (transport-ground)",
        "order": 1610,
        "htmlcode": "&#128650"
    },
    {
        "index": 634,
        "emoji": "🚝",
        "name": "monorail",
        "shortname": ":monorail:",
        "unicode": "1f69d",
        "html": "&#128669;",
        "category": "Travel & Places (transport-ground)",
        "order": 1611,
        "htmlcode": "&#128669"
    },
    {
        "index": 635,
        "emoji": "🚞",
        "name": "mountain railway",
        "shortname": ":mountain_railway:",
        "unicode": "1f69e",
        "html": "&#128670;",
        "category": "Travel & Places (transport-ground)",
        "order": 1612,
        "htmlcode": "&#128670"
    },
    {
        "index": 636,
        "emoji": "🚋",
        "name": "tram car",
        "shortname": ":train:",
        "unicode": "1f68b",
        "html": "&#128651;",
        "category": "Travel & Places (transport-ground)",
        "order": 1613,
        "htmlcode": "&#128651"
    },
    {
        "index": 637,
        "emoji": "🚌",
        "name": "bus",
        "shortname": ":bus:",
        "unicode": "1f68c",
        "html": "&#128652;",
        "category": "Travel & Places (transport-ground)",
        "order": 1614,
        "htmlcode": "&#128652"
    },
    {
        "index": 638,
        "emoji": "🚍",
        "name": "oncoming bus",
        "shortname": ":oncoming_bus:",
        "unicode": "1f68d",
        "html": "&#128653;",
        "category": "Travel & Places (transport-ground)",
        "order": 1615,
        "htmlcode": "&#128653"
    },
    {
        "index": 639,
        "emoji": "🚎",
        "name": "trolleybus",
        "shortname": ":trolleybus:",
        "unicode": "1f68e",
        "html": "&#128654;",
        "category": "Travel & Places (transport-ground)",
        "order": 1616,
        "htmlcode": "&#128654"
    },
    {
        "index": 640,
        "emoji": "🚐",
        "name": "minibus",
        "shortname": ":minibus:",
        "unicode": "1f690",
        "html": "&#128656;",
        "category": "Travel & Places (transport-ground)",
        "order": 1617,
        "htmlcode": "&#128656"
    },
    {
        "index": 641,
        "emoji": "🚑",
        "name": "ambulance",
        "shortname": ":ambulance:",
        "unicode": "1f691",
        "html": "&#128657;",
        "category": "Travel & Places (transport-ground)",
        "order": 1618,
        "htmlcode": "&#128657"
    },
    {
        "index": 642,
        "emoji": "🚓",
        "name": "police car",
        "shortname": ":police_car:",
        "unicode": "1f693",
        "html": "&#128659;",
        "category": "Travel & Places (transport-ground)",
        "order": 1620,
        "htmlcode": "&#128659"
    },
    {
        "index": 643,
        "emoji": "🚔",
        "name": "oncoming police car",
        "shortname": ":oncoming_police_car:",
        "unicode": "1f694",
        "html": "&#128660;",
        "category": "Travel & Places (transport-ground)",
        "order": 1621,
        "htmlcode": "&#128660"
    },
    {
        "index": 644,
        "emoji": "🚕",
        "name": "taxi",
        "shortname": ":taxi:",
        "unicode": "1f695",
        "html": "&#128661;",
        "category": "Travel & Places (transport-ground)",
        "order": 1622,
        "htmlcode": "&#128661"
    },
    {
        "index": 645,
        "emoji": "🚖",
        "name": "oncoming taxi",
        "shortname": ":oncoming_taxi:",
        "unicode": "1f696",
        "html": "&#128662;",
        "category": "Travel & Places (transport-ground)",
        "order": 1623,
        "htmlcode": "&#128662"
    },
    {
        "index": 646,
        "emoji": "🚗",
        "name": "automobile",
        "shortname": ":automobile:",
        "unicode": "1F697",
        "html": "&#128663;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#128663"
    },
    {
        "index": 647,
        "emoji": "🚘",
        "name": "oncoming automobile",
        "shortname": ":oncoming_automobile:",
        "unicode": "1f698",
        "html": "&#128664;",
        "category": "Travel & Places (transport-ground)",
        "order": 1625,
        "htmlcode": "&#128664"
    },
    {
        "index": 648,
        "emoji": "🚙",
        "name": "sport utility vehicle",
        "shortname": ":blue_car:",
        "unicode": "1f699",
        "html": "&#128665;",
        "category": "Travel & Places (transport-ground)",
        "order": 1626,
        "htmlcode": "&#128665"
    },
    // {
    //     "index": 649,
    //     "htmlcode": "&#128763"
    // },
    {
        "index": 650,
        "emoji": "🚚",
        "name": "delivery truck",
        "shortname": ":truck:",
        "unicode": "1f69a",
        "html": "&#128666;",
        "category": "Travel & Places (transport-ground)",
        "order": 1627,
        "htmlcode": "&#128666"
    },
    {
        "index": 651,
        "emoji": "🚛",
        "name": "articulated lorry",
        "shortname": ":articulated_lorry:",
        "unicode": "1f69b",
        "html": "&#128667;",
        "category": "Travel & Places (transport-ground)",
        "order": 1628,
        "htmlcode": "&#128667"
    },
    {
        "index": 652,
        "emoji": "🚜",
        "name": "tractor",
        "shortname": ":tractor:",
        "unicode": "1f69c",
        "html": "&#128668;",
        "category": "Travel & Places (transport-ground)",
        "order": 1629,
        "htmlcode": "&#128668"
    },
    {
        "index": 653,
        "emoji": "🏎",
        "name": "racing car",
        "shortname": ":racing_car:",
        "unicode": "1F3CE",
        "html": "&#127950;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#127950"
    },
    {
        "index": 654,
        "emoji": "🏍",
        "name": "motorcycle",
        "shortname": ":motorcycle:",
        "unicode": "1F3CD",
        "html": "&#127949;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#127949"
    },
    {
        "index": 655,
        "emoji": "🛵",
        "name": "motor scooter",
        "shortname": ":motor_scooter:",
        "unicode": "1F6F5",
        "html": "&#x1F6F5;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#128757"
    },
    {
        "index": 656,
        "emoji": "🛺",
        "name": "auto rickshaw",
        "shortname": ":auto_rickshaw:",
        "unicode": "1F6FA",
        "html": "&#x1F6FA;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#128762"
    },
    {
        "index": 657,
        "emoji": "🚲",
        "name": "bicycle",
        "shortname": ":bike:",
        "unicode": "1f6b2",
        "html": "&#128690;",
        "category": "Travel & Places (transport-ground)",
        "order": 1630,
        "htmlcode": "&#128690"
    },
    {
        "index": 658,
        "emoji": "🛴",
        "name": "kick scooter",
        "shortname": ":kick_scooter:",
        "unicode": "1F6F4",
        "html": "&#x1F6F4;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#128756"
    },
    {
        "index": 659,
        "emoji": "🛹",
        "name": "skateboard",
        "shortname": ":skateboard:",
        "unicode": "1F6F9",
        "html": "&#x1F6F9;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#128761"
    },
    // {
    //     "index": 660,
    //     "htmlcode": "&#128764"
    // },
    {
        "index": 661,
        "emoji": "🚏",
        "name": "bus stop",
        "shortname": ":busstop:",
        "unicode": "1f68f",
        "html": "&#128655;",
        "category": "Travel & Places (transport-ground)",
        "order": 1633,
        "htmlcode": "&#128655"
    },
    {
        "index": 662,
        "emoji": "🛣",
        "name": "motorway",
        "shortname": ":motorway:",
        "unicode": "1f6e3",
        "html": "&#128739;",
        "category": "Travel & Places (transport-ground)",
        "order": 1634,
        "htmlcode": "&#128739"
    },
    {
        "index": 663,
        "emoji": "🛤",
        "name": "railway track",
        "shortname": ":railway_track:",
        "unicode": "1f6e4",
        "html": "&#128740;",
        "category": "Travel & Places (transport-ground)",
        "order": 1635,
        "htmlcode": "&#128740"
    },
    {
        "index": 664,
        "emoji": "🛢",
        "name": "oil drum",
        "shortname": ":oil_drum:",
        "unicode": "1F6E2",
        "html": "&#128738;",
        "category": "Travel & Places (transport-ground)",
        "order": 63.5,
        "htmlcode": "&#128738"
    },
    {
        "index": 665,
        "emoji": "🚨",
        "name": "police car light",
        "shortname": ":rotating_light:",
        "unicode": "1f6a8",
        "html": "&#128680;",
        "category": "Travel & Places (transport-ground)",
        "order": 1637,
        "htmlcode": "&#128680"
    },
    {
        "index": 666,
        "emoji": "🚥",
        "name": "horizontal traffic light",
        "shortname": ":traffic_light:",
        "unicode": "1f6a5",
        "html": "&#128677;",
        "category": "Travel & Places (transport-ground)",
        "order": 1638,
        "htmlcode": "&#128677"
    },
    {
        "index": 667,
        "emoji": "🚦",
        "name": "vertical traffic light",
        "shortname": ":vertical_traffic_light:",
        "unicode": "1f6a6",
        "html": "&#128678;",
        "category": "Travel & Places (transport-ground)",
        "order": 1639,
        "htmlcode": "&#128678"
    },
    {
        "index": 668,
        "emoji": "🛑",
        "name": "stop sign",
        "shortname": ":octagonal_sign:",
        "unicode": "1f6d1",
        "html": "&#128721;",
        "category": "Travel & Places (transport-ground)",
        "order": 1641,
        "htmlcode": "&#128721"
    },
    {
        "index": 669,
        "emoji": "🚧",
        "name": "construction",
        "shortname": ":construction:",
        "unicode": "1f6a7",
        "html": "&#128679;",
        "category": "Travel & Places (transport-ground)",
        "order": 1640,
        "htmlcode": "&#128679"
    },
    {
        "index": 670,
        "emoji": "🛶",
        "name": "canoe",
        "shortname": ":canoe:",
        "unicode": "1F6F6",
        "html": "&#x1F6F6;",
        "category": "Travel & Places (transport-water)",
        "order": 63.5,
        "htmlcode": "&#128758"
    },
    {
        "index": 671,
        "emoji": "🚤",
        "name": "speedboat",
        "shortname": ":speedboat:",
        "unicode": "1f6a4",
        "html": "&#128676;",
        "category": "Travel & Places (transport-water)",
        "order": 1645,
        "htmlcode": "&#128676"
    },
    {
        "index": 672,
        "emoji": "🛳",
        "name": "passenger ship",
        "shortname": ":passenger_ship:",
        "unicode": "1F6F3",
        "html": "&#128755;",
        "category": "Travel & Places (transport-water)",
        "order": 63.5,
        "htmlcode": "&#128755"
    },
    {
        "index": 673,
        "emoji": "🛥",
        "name": "motor boat",
        "shortname": ":motor_boat:",
        "unicode": "1F6E5",
        "html": "&#128741;",
        "category": "Travel & Places (transport-water)",
        "order": 63.5,
        "htmlcode": "&#128741"
    },
    {
        "index": 674,
        "emoji": "🚢",
        "name": "ship",
        "shortname": ":ship:",
        "unicode": "1f6a2",
        "html": "&#128674;",
        "category": "Travel & Places (transport-water)",
        "order": 1649,
        "htmlcode": "&#128674"
    },
    {
        "index": 675,
        "emoji": "🛩",
        "name": "small airplane",
        "shortname": ":small_airplane:",
        "unicode": "1F6E9",
        "html": "&#128745;",
        "category": "Travel & Places (transport-air)",
        "order": 63.5,
        "htmlcode": "&#128745"
    },
    {
        "index": 676,
        "emoji": "🛫",
        "name": "airplane departure",
        "shortname": ":airplane_departure:",
        "unicode": "1f6eb",
        "html": "&#128747;",
        "category": "Travel & Places (transport-air)",
        "order": 1652,
        "htmlcode": "&#128747"
    },
    {
        "index": 677,
        "emoji": "🛬",
        "name": "airplane arrival",
        "shortname": ":airplane_arriving:",
        "unicode": "1f6ec",
        "html": "&#128748;",
        "category": "Travel & Places (transport-air)",
        "order": 1653,
        "htmlcode": "&#128748"
    },
    {
        "index": 678,
        "emoji": "🪂",
        "name": "parachute",
        "shortname": ":parachute:",
        "unicode": "1FA82",
        "html": "&#x1FA82;",
        "category": "Travel & Places (transport-air)",
        "order": 63.5,
        "htmlcode": "&#129666"
    },
    {
        "index": 679,
        "emoji": "💺",
        "name": "seat",
        "shortname": ":seat:",
        "unicode": "1f4ba",
        "html": "&#128186;",
        "category": "Travel & Places (transport-air)",
        "order": 1654,
        "htmlcode": "&#128186"
    },
    {
        "index": 680,
        "emoji": "🚁",
        "name": "helicopter",
        "shortname": ":helicopter:",
        "unicode": "1f681",
        "html": "&#128641;",
        "category": "Travel & Places (transport-air)",
        "order": 1655,
        "htmlcode": "&#128641"
    },
    {
        "index": 681,
        "emoji": "🚟",
        "name": "suspension railway",
        "shortname": ":suspension_railway:",
        "unicode": "1f69f",
        "html": "&#128671;",
        "category": "Travel & Places (transport-air)",
        "order": 1656,
        "htmlcode": "&#128671"
    },
    {
        "index": 682,
        "emoji": "🚠",
        "name": "mountain cableway",
        "shortname": ":mountain_cableway:",
        "unicode": "1f6a0",
        "html": "&#128672;",
        "category": "Travel & Places (transport-air)",
        "order": 1657,
        "htmlcode": "&#128672"
    },
    {
        "index": 683,
        "emoji": "🚡",
        "name": "aerial tramway",
        "shortname": ":aerial_tramway:",
        "unicode": "1f6a1",
        "html": "&#128673;",
        "category": "Travel & Places (transport-air)",
        "order": 1658,
        "htmlcode": "&#128673"
    },
    {
        "index": 684,
        "emoji": "🛰",
        "name": "satellite",
        "shortname": ":satellite:",
        "unicode": "1F6F0",
        "html": "&#128752;",
        "category": "Travel & Places (transport-air)",
        "order": 63.5,
        "htmlcode": "&#128752"
    },
    {
        "index": 685,
        "emoji": "🛸",
        "name": "flying saucer",
        "shortname": ":flying_saucer:",
        "unicode": "1F6F8",
        "html": "&#x1F6F8;",
        "category": "Travel & Places (transport-air)",
        "order": 63.5,
        "htmlcode": "&#128760"
    },
    {
        "index": 686,
        "emoji": "🛎",
        "name": "bellhop bell",
        "shortname": ":bellhop_bell:",
        "unicode": "1F6CE",
        "html": "&#128718;",
        "category": "Travel & Places (hotel)",
        "order": 63.5,
        "htmlcode": "&#128718"
    },
    {
        "index": 687,
        "emoji": "🧳",
        "name": "luggage",
        "shortname": ":luggage:",
        "unicode": "1F9F3",
        "html": "&#x1F9F3;",
        "category": "Travel & Places (hotel)",
        "order": 63.5,
        "htmlcode": "&#129523"
    },
    {
        "index": 688,
        "emoji": "🕰",
        "name": "mantelpiece clock",
        "shortname": ":mantelpiece_clock:",
        "unicode": "1F570",
        "html": "&#128368;",
        "category": "Travel & Places (time)",
        "order": 63.5,
        "htmlcode": "&#128368"
    },
    {
        "index": 689,
        "emoji": "🕛",
        "name": "twelve o’clock",
        "shortname": ":clock12:",
        "unicode": "1f55b",
        "html": "&#128347;",
        "category": "Travel & Places (time)",
        "order": 1687,
        "htmlcode": "&#128347"
    },
    {
        "index": 690,
        "emoji": "🕧",
        "name": "twelve-thirty",
        "shortname": ":clock1230:",
        "unicode": "1f567",
        "html": "&#128359;",
        "category": "Travel & Places (time)",
        "order": 1688,
        "htmlcode": "&#128359"
    },
    {
        "index": 691,
        "emoji": "🕐",
        "name": "one o’clock",
        "shortname": ":clock1:",
        "unicode": "1f550",
        "html": "&#128336;",
        "category": "Travel & Places (time)",
        "order": 1689,
        "htmlcode": "&#128336"
    },
    {
        "index": 692,
        "emoji": "🕜",
        "name": "one-thirty",
        "shortname": ":clock130:",
        "unicode": "1f55c",
        "html": "&#128348;",
        "category": "Travel & Places (time)",
        "order": 1690,
        "htmlcode": "&#128348"
    },
    {
        "index": 693,
        "emoji": "🕑",
        "name": "two o’clock",
        "shortname": ":clock2:",
        "unicode": "1f551",
        "html": "&#128337;",
        "category": "Travel & Places (time)",
        "order": 1691,
        "htmlcode": "&#128337"
    },
    {
        "index": 694,
        "emoji": "🕝",
        "name": "two-thirty",
        "shortname": ":clock230:",
        "unicode": "1f55d",
        "html": "&#128349;",
        "category": "Travel & Places (time)",
        "order": 1692,
        "htmlcode": "&#128349"
    },
    {
        "index": 695,
        "emoji": "🕒",
        "name": "three o’clock",
        "shortname": ":clock3:",
        "unicode": "1f552",
        "html": "&#128338;",
        "category": "Travel & Places (time)",
        "order": 1693,
        "htmlcode": "&#128338"
    },
    {
        "index": 696,
        "emoji": "🕞",
        "name": "three-thirty",
        "shortname": ":clock330:",
        "unicode": "1f55e",
        "html": "&#128350;",
        "category": "Travel & Places (time)",
        "order": 1694,
        "htmlcode": "&#128350"
    },
    {
        "index": 697,
        "emoji": "🕓",
        "name": "four o’clock",
        "shortname": ":clock4:",
        "unicode": "1f553",
        "html": "&#128339;",
        "category": "Travel & Places (time)",
        "order": 1695,
        "htmlcode": "&#128339"
    },
    {
        "index": 698,
        "emoji": "🕟",
        "name": "four-thirty",
        "shortname": ":clock430:",
        "unicode": "1f55f",
        "html": "&#128351;",
        "category": "Travel & Places (time)",
        "order": 1696,
        "htmlcode": "&#128351"
    },
    {
        "index": 699,
        "emoji": "🕔",
        "name": "five o’clock",
        "shortname": ":clock5:",
        "unicode": "1f554",
        "html": "&#128340;",
        "category": "Travel & Places (time)",
        "order": 1697,
        "htmlcode": "&#128340"
    },
    {
        "index": 700,
        "emoji": "🕠",
        "name": "five-thirty",
        "shortname": ":clock530:",
        "unicode": "1f560",
        "html": "&#128352;",
        "category": "Travel & Places (time)",
        "order": 1698,
        "htmlcode": "&#128352"
    },
    {
        "index": 701,
        "emoji": "🕕",
        "name": "six o’clock",
        "shortname": ":clock6:",
        "unicode": "1f555",
        "html": "&#128341;",
        "category": "Travel & Places (time)",
        "order": 1699,
        "htmlcode": "&#128341"
    },
    {
        "index": 702,
        "emoji": "🕡",
        "name": "six-thirty",
        "shortname": ":clock630:",
        "unicode": "1f561",
        "html": "&#128353;",
        "category": "Travel & Places (time)",
        "order": 1700,
        "htmlcode": "&#128353"
    },
    {
        "index": 703,
        "emoji": "🕖",
        "name": "seven o’clock",
        "shortname": ":clock7:",
        "unicode": "1f556",
        "html": "&#128342;",
        "category": "Travel & Places (time)",
        "order": 1701,
        "htmlcode": "&#128342"
    },
    {
        "index": 704,
        "emoji": "🕢",
        "name": "seven-thirty",
        "shortname": ":clock730:",
        "unicode": "1f562",
        "html": "&#128354;",
        "category": "Travel & Places (time)",
        "order": 1702,
        "htmlcode": "&#128354"
    },
    {
        "index": 705,
        "emoji": "🕗",
        "name": "eight o’clock",
        "shortname": ":clock8:",
        "unicode": "1f557",
        "html": "&#128343;",
        "category": "Travel & Places (time)",
        "order": 1703,
        "htmlcode": "&#128343"
    },
    {
        "index": 706,
        "emoji": "🕣",
        "name": "eight-thirty",
        "shortname": ":clock830:",
        "unicode": "1f563",
        "html": "&#128355;",
        "category": "Travel & Places (time)",
        "order": 1704,
        "htmlcode": "&#128355"
    },
    {
        "index": 707,
        "emoji": "🕘",
        "name": "nine o’clock",
        "shortname": ":clock9:",
        "unicode": "1f558",
        "html": "&#128344;",
        "category": "Travel & Places (time)",
        "order": 1705,
        "htmlcode": "&#128344"
    },
    {
        "index": 708,
        "emoji": "🕤",
        "name": "nine-thirty",
        "shortname": ":clock930:",
        "unicode": "1f564",
        "html": "&#128356;",
        "category": "Travel & Places (time)",
        "order": 1706,
        "htmlcode": "&#128356"
    },
    {
        "index": 709,
        "emoji": "🕙",
        "name": "ten o’clock",
        "shortname": ":clock10:",
        "unicode": "1f559",
        "html": "&#128345;",
        "category": "Travel & Places (time)",
        "order": 1707,
        "htmlcode": "&#128345"
    },
    {
        "index": 710,
        "emoji": "🕥",
        "name": "ten-thirty",
        "shortname": ":clock1030:",
        "unicode": "1f565",
        "html": "&#128357;",
        "category": "Travel & Places (time)",
        "order": 1708,
        "htmlcode": "&#128357"
    },
    {
        "index": 711,
        "emoji": "🕚",
        "name": "eleven o’clock",
        "shortname": ":clock11:",
        "unicode": "1f55a",
        "html": "&#128346;",
        "category": "Travel & Places (time)",
        "order": 1709,
        "htmlcode": "&#128346"
    },
    {
        "index": 712,
        "emoji": "🕦",
        "name": "eleven-thirty",
        "shortname": ":clock1130:",
        "unicode": "1f566",
        "html": "&#128358;",
        "category": "Travel & Places (time)",
        "order": 1710,
        "htmlcode": "&#128358"
    },
    {
        "index": 713,
        "emoji": "🌑",
        "name": "new moon",
        "shortname": ":new_moon:",
        "unicode": "1f311",
        "html": "&#127761;",
        "category": "Travel & Places (sky & weather)",
        "order": 1711,
        "htmlcode": "&#127761"
    },
    {
        "index": 714,
        "emoji": "🌒",
        "name": "waxing crescent moon",
        "shortname": ":waxing_crescent_moon:",
        "unicode": "1f312",
        "html": "&#127762;",
        "category": "Travel & Places (sky & weather)",
        "order": 1712,
        "htmlcode": "&#127762"
    },
    {
        "index": 715,
        "emoji": "🌓",
        "name": "first quarter moon",
        "shortname": ":first_quarter_moon:",
        "unicode": "1f313",
        "html": "&#127763;",
        "category": "Travel & Places (sky & weather)",
        "order": 1713,
        "htmlcode": "&#127763"
    },
    {
        "index": 716,
        "emoji": "🌔",
        "name": "waxing gibbous moon",
        "shortname": ":waxing_gibbous_moon:",
        "unicode": "1F314",
        "html": "&#127764;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#127764"
    },
    {
        "index": 717,
        "emoji": "🌕",
        "name": "full moon",
        "shortname": ":full_moon:",
        "unicode": "1f315",
        "html": "&#127765;",
        "category": "Travel & Places (sky & weather)",
        "order": 1715,
        "htmlcode": "&#127765"
    },
    {
        "index": 718,
        "emoji": "🌖",
        "name": "waning gibbous moon",
        "shortname": ":waning_gibbous_moon:",
        "unicode": "1f316",
        "html": "&#127766;",
        "category": "Travel & Places (sky & weather)",
        "order": 1716,
        "htmlcode": "&#127766"
    },
    {
        "index": 719,
        "emoji": "🌗",
        "name": "last quarter moon",
        "shortname": ":last_quarter_moon:",
        "unicode": "1f317",
        "html": "&#127767;",
        "category": "Travel & Places (sky & weather)",
        "order": 1717,
        "htmlcode": "&#127767"
    },
    {
        "index": 720,
        "emoji": "🌘",
        "name": "waning crescent moon",
        "shortname": ":waning_crescent_moon:",
        "unicode": "1f318",
        "html": "&#127768;",
        "category": "Travel & Places (sky & weather)",
        "order": 1718,
        "htmlcode": "&#127768"
    },
    {
        "index": 721,
        "emoji": "🌙",
        "name": "crescent moon",
        "shortname": ":crescent_moon:",
        "unicode": "1f319",
        "html": "&#127769;",
        "category": "Travel & Places (sky & weather)",
        "order": 1719,
        "htmlcode": "&#127769"
    },
    {
        "index": 722,
        "emoji": "🌚",
        "name": "new moon face",
        "shortname": ":new_moon_with_face:",
        "unicode": "1f31a",
        "html": "&#127770;",
        "category": "Travel & Places (sky & weather)",
        "order": 1720,
        "htmlcode": "&#127770"
    },
    {
        "index": 723,
        "emoji": "🌛",
        "name": "first quarter moon face",
        "shortname": ":first_quarter_moon_with_face:",
        "unicode": "1f31b",
        "html": "&#127771;",
        "category": "Travel & Places (sky & weather)",
        "order": 1721,
        "htmlcode": "&#127771"
    },
    {
        "index": 724,
        "emoji": "🌜",
        "name": "last quarter moon face",
        "shortname": ":last_quarter_moon_with_face:",
        "unicode": "1f31c",
        "html": "&#127772;",
        "category": "Travel & Places (sky & weather)",
        "order": 1722,
        "htmlcode": "&#127772"
    },
    {
        "index": 725,
        "emoji": "🌡",
        "name": "thermometer",
        "shortname": ":thermometer:",
        "unicode": "1f321",
        "html": "&#127777;",
        "category": "Travel & Places (sky & weather)",
        "order": 1723,
        "htmlcode": "&#127777"
    },
    {
        "index": 726,
        "emoji": "🌝",
        "name": "full moon face",
        "shortname": ":full_moon_with_face:",
        "unicode": "1f31d",
        "html": "&#127773;",
        "category": "Travel & Places (sky & weather)",
        "order": 1725,
        "htmlcode": "&#127773"
    },
    {
        "index": 727,
        "emoji": "🌞",
        "name": "sun with face",
        "shortname": ":sun_with_face:",
        "unicode": "1f31e",
        "html": "&#127774;",
        "category": "Travel & Places (sky & weather)",
        "order": 1726,
        "htmlcode": "&#127774"
    },
    {
        "index": 728,
        "emoji": "🪐",
        "name": "ringed planet",
        "shortname": ":ringed_planet:",
        "unicode": "1FA90",
        "html": "&#x1FA90;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#129680"
    },
    {
        "index": 729,
        "emoji": "🌟",
        "name": "glowing star",
        "shortname": ":star2:",
        "unicode": "1f31f",
        "html": "&#127775;",
        "category": "Travel & Places (sky & weather)",
        "order": 1728,
        "htmlcode": "&#127775"
    },
    {
        "index": 730,
        "emoji": "🌠",
        "name": "shooting star",
        "shortname": ":stars:",
        "unicode": "1f320",
        "html": "&#127776;",
        "category": "Travel & Places (sky & weather)",
        "order": 1729,
        "htmlcode": "&#127776"
    },
    {
        "index": 731,
        "emoji": "🌌",
        "name": "milky way",
        "shortname": ":milky_way:",
        "unicode": "1f30c",
        "html": "&#127756;",
        "category": "Travel & Places (sky & weather)",
        "order": 1592,
        "htmlcode": "&#127756"
    },
    {
        "index": 732,
        "emoji": "🌤",
        "name": "sun behind small cloud",
        "shortname": ":sun_behind_small_cloud:",
        "unicode": "1F324",
        "html": "&#127780;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#127780"
    },
    {
        "index": 733,
        "emoji": "🌥",
        "name": "sun behind large cloud",
        "shortname": ":sun_behind_large_cloud:",
        "unicode": "1F325",
        "html": "&#127781;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#127781"
    },
    {
        "index": 734,
        "emoji": "🌦",
        "name": "sun behind rain cloud",
        "shortname": ":sun_behind_rain_cloud:",
        "unicode": "1F326",
        "html": "&#127782;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#127782"
    },
    {
        "index": 735,
        "emoji": "🌧",
        "name": "cloud with rain",
        "shortname": ":cloud_with_rain:",
        "unicode": "1F327",
        "html": "&#127783;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#127783"
    },
    {
        "index": 736,
        "emoji": "🌨",
        "name": "cloud with snow",
        "shortname": ":cloud_with_snow:",
        "unicode": "1F328",
        "html": "&#127784;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#127784"
    },
    {
        "index": 737,
        "emoji": "🌩",
        "name": "cloud with lightning",
        "shortname": ":cloud_with_lightning:",
        "unicode": "1F329",
        "html": "&#127785;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#127785"
    },
    {
        "index": 738,
        "emoji": "🌪",
        "name": "tornado",
        "shortname": ":tornado:",
        "unicode": "1F32A",
        "html": "&#127786;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#127786"
    },
    {
        "index": 739,
        "emoji": "🌬",
        "name": "wind face",
        "shortname": ":wind_blowing_face:",
        "unicode": "1f32c",
        "html": "&#127788;",
        "category": "Travel & Places (sky & weather)",
        "order": 1741,
        "htmlcode": "&#127788"
    },
    {
        "index": 740,
        "emoji": "🌀",
        "name": "cyclone",
        "shortname": ":cyclone:",
        "unicode": "1f300",
        "html": "&#127744;",
        "category": "Travel & Places (sky & weather)",
        "order": 1742,
        "htmlcode": "&#127744"
    },
    {
        "index": 741,
        "emoji": "🌈",
        "name": "rainbow",
        "shortname": ":rainbow:",
        "unicode": "1f308",
        "html": "&#127752;",
        "category": "Travel & Places (sky & weather)",
        "order": 1743,
        "htmlcode": "&#127752"
    },
    {
        "index": 742,
        "emoji": "🌂",
        "name": "closed umbrella",
        "shortname": ":closed_umbrella:",
        "unicode": "1f302",
        "html": "&#127746;",
        "category": "Travel & Places (sky & weather)",
        "order": 1744,
        "htmlcode": "&#127746"
    },
    {
        "index": 743,
        "emoji": "💧",
        "name": "droplet",
        "shortname": ":droplet:",
        "unicode": "1f4a7",
        "html": "&#128167;",
        "category": "Travel & Places (sky & weather)",
        "order": 1754,
        "htmlcode": "&#128167"
    },
    {
        "index": 744,
        "emoji": "🌊",
        "name": "water wave",
        "shortname": ":ocean:",
        "unicode": "1f30a",
        "html": "&#127754;",
        "category": "Travel & Places (sky & weather)",
        "order": 1755,
        "htmlcode": "&#127754"
    },
    {
        "index": 745,
        "emoji": "🎃",
        "name": "jack-o-lantern",
        "shortname": ":jack_o_lantern:",
        "unicode": "1f383",
        "html": "&#127875;",
        "category": "Activities (event)",
        "order": 1756,
        "htmlcode": "&#127875"
    },
    {
        "index": 746,
        "emoji": "🎆",
        "name": "fireworks",
        "shortname": ":fireworks:",
        "unicode": "1f386",
        "html": "&#127878;",
        "category": "Activities (event)",
        "order": 1758,
        "htmlcode": "&#127878"
    },
    {
        "index": 747,
        "emoji": "🎇",
        "name": "sparkler",
        "shortname": ":sparkler:",
        "unicode": "1f387",
        "html": "&#127879;",
        "category": "Activities (event)",
        "order": 1759,
        "htmlcode": "&#127879"
    },
    {
        "index": 748,
        "emoji": "🧨",
        "name": "firecracker",
        "shortname": ":firecracker:",
        "unicode": "1F9E8",
        "html": "&#x1F9E8;",
        "category": "Activities (event)",
        "order": 63.5,
        "htmlcode": "&#129512"
    },
    {
        "index": 749,
        "emoji": "🎈",
        "name": "balloon",
        "shortname": ":balloon:",
        "unicode": "1f388",
        "html": "&#127880;",
        "category": "Activities (event)",
        "order": 1761,
        "htmlcode": "&#127880"
    },
    {
        "index": 750,
        "emoji": "🎉",
        "name": "party popper",
        "shortname": ":tada:",
        "unicode": "1f389",
        "html": "&#127881;",
        "category": "Activities (event)",
        "order": 1762,
        "htmlcode": "&#127881"
    },
    {
        "index": 751,
        "emoji": "🎊",
        "name": "confetti ball",
        "shortname": ":confetti_ball:",
        "unicode": "1f38a",
        "html": "&#127882;",
        "category": "Activities (event)",
        "order": 1763,
        "htmlcode": "&#127882"
    },
    {
        "index": 752,
        "emoji": "🎋",
        "name": "tanabata tree",
        "shortname": ":tanabata_tree:",
        "unicode": "1f38b",
        "html": "&#127883;",
        "category": "Activities (event)",
        "order": 1764,
        "htmlcode": "&#127883"
    },
    {
        "index": 753,
        "emoji": "🎍",
        "name": "pine decoration",
        "shortname": ":bamboo:",
        "unicode": "1f38d",
        "html": "&#127885;",
        "category": "Activities (event)",
        "order": 1765,
        "htmlcode": "&#127885"
    },
    {
        "index": 754,
        "emoji": "🎎",
        "name": "Japanese dolls",
        "shortname": ":dolls:",
        "unicode": "1f38e",
        "html": "&#127886;",
        "category": "Activities (event)",
        "order": 1766,
        "htmlcode": "&#127886"
    },
    {
        "index": 755,
        "emoji": "🎏",
        "name": "carp streamer",
        "shortname": ":flags:",
        "unicode": "1f38f",
        "html": "&#127887;",
        "category": "Activities (event)",
        "order": 1767,
        "htmlcode": "&#127887"
    },
    {
        "index": 756,
        "emoji": "🎐",
        "name": "wind chime",
        "shortname": ":wind_chime:",
        "unicode": "1f390",
        "html": "&#127888;",
        "category": "Activities (event)",
        "order": 1768,
        "htmlcode": "&#127888"
    },
    {
        "index": 757,
        "emoji": "🎑",
        "name": "moon viewing ceremony",
        "shortname": ":rice_scene:",
        "unicode": "1f391",
        "html": "&#127889;",
        "category": "Activities (event)",
        "order": 1769,
        "htmlcode": "&#127889"
    },
    {
        "index": 758,
        "emoji": "🧧",
        "name": "red envelope",
        "shortname": ":red_envelope:",
        "unicode": "1F9E7",
        "html": "&#x1F9E7;",
        "category": "Activities (event)",
        "order": 63.5,
        "htmlcode": "&#129511"
    },
    {
        "index": 759,
        "emoji": "🎀",
        "name": "ribbon",
        "shortname": ":ribbon:",
        "unicode": "1f380",
        "html": "&#127872;",
        "category": "Activities (event)",
        "order": 1770,
        "htmlcode": "&#127872"
    },
    {
        "index": 760,
        "emoji": "🎁",
        "name": "wrapped gift",
        "shortname": ":gift:",
        "unicode": "1f381",
        "html": "&#127873;",
        "category": "Activities (event)",
        "order": 1771,
        "htmlcode": "&#127873"
    },
    {
        "index": 761,
        "emoji": "🎗",
        "name": "reminder ribbon",
        "shortname": ":reminder_ribbon:",
        "unicode": "1f397",
        "html": "&#127895;",
        "category": "Activities (event)",
        "order": 1772,
        "htmlcode": "&#127895"
    },
    {
        "index": 762,
        "emoji": "🎟",
        "name": "admission tickets",
        "shortname": ":admission_tickets:",
        "unicode": "1F39F",
        "html": "&#127903;",
        "category": "Activities (event)",
        "order": 63.5,
        "htmlcode": "&#127903"
    },
    {
        "index": 763,
        "emoji": "🎫",
        "name": "ticket",
        "shortname": ":ticket:",
        "unicode": "1f3ab",
        "html": "&#127915;",
        "category": "Activities (event)",
        "order": 1774,
        "htmlcode": "&#127915"
    },
    {
        "index": 764,
        "emoji": "🎖",
        "name": "military medal",
        "shortname": ":military_medal:",
        "unicode": "1F396",
        "html": "&#127894;",
        "category": "Activities (award-medal)",
        "order": 63.5,
        "htmlcode": "&#127894"
    },
    {
        "index": 765,
        "emoji": "🏆",
        "name": "trophy",
        "shortname": ":trophy:",
        "unicode": "1f3c6",
        "html": "&#127942;",
        "category": "Activities (award-medal)",
        "order": 1776,
        "htmlcode": "&#127942"
    },
    {
        "index": 766,
        "emoji": "🏅",
        "name": "sports medal",
        "shortname": ":medal:",
        "unicode": "1f3c5",
        "html": "&#127941;",
        "category": "Activities (award-medal)",
        "order": 1777,
        "htmlcode": "&#127941"
    },
    {
        "index": 767,
        "emoji": "🥇",
        "name": "1st place medal",
        "shortname": ":1st_place_medal:",
        "unicode": "1F947",
        "html": "&#x1F947;",
        "category": "Activities (award-medal)",
        "order": 63.5,
        "htmlcode": "&#129351"
    },
    {
        "index": 768,
        "emoji": "🥈",
        "name": "2nd place medal",
        "shortname": ":2nd_place_medal:",
        "unicode": "1F948",
        "html": "&#x1F948;",
        "category": "Activities (award-medal)",
        "order": 63.5,
        "htmlcode": "&#129352"
    },
    {
        "index": 769,
        "emoji": "🥉",
        "name": "3rd place medal",
        "shortname": ":3rd_place_medal:",
        "unicode": "1F949",
        "html": "&#x1F949;",
        "category": "Activities (award-medal)",
        "order": 63.5,
        "htmlcode": "&#129353"
    },
    {
        "index": 770,
        "emoji": "🥎",
        "name": "softball",
        "shortname": ":softball:",
        "unicode": "1F94E",
        "html": "&#x1F94E;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#129358"
    },
    {
        "index": 771,
        "emoji": "🏀",
        "name": "basketball",
        "shortname": ":basketball:",
        "unicode": "1f3c0",
        "html": "&#127936;",
        "category": "Activities (sport)",
        "order": 1783,
        "htmlcode": "&#127936"
    },
    {
        "index": 772,
        "emoji": "🏐",
        "name": "volleyball",
        "shortname": ":volleyball:",
        "unicode": "1f3d0",
        "html": "&#127952;",
        "category": "Activities (sport)",
        "order": 1784,
        "htmlcode": "&#127952"
    },
    {
        "index": 773,
        "emoji": "🏈",
        "name": "american football",
        "shortname": ":football:",
        "unicode": "1f3c8",
        "html": "&#127944;",
        "category": "Activities (sport)",
        "order": 1785,
        "htmlcode": "&#127944"
    },
    {
        "index": 774,
        "emoji": "🏉",
        "name": "rugby football",
        "shortname": ":rugby_football:",
        "unicode": "1f3c9",
        "html": "&#127945;",
        "category": "Activities (sport)",
        "order": 1786,
        "htmlcode": "&#127945"
    },
    {
        "index": 775,
        "emoji": "🎾",
        "name": "tennis",
        "shortname": ":tennis:",
        "unicode": "1f3be",
        "html": "&#127934;",
        "category": "Activities (sport)",
        "order": 1787,
        "htmlcode": "&#127934"
    },
    {
        "index": 776,
        "emoji": "🥏",
        "name": "flying disc",
        "shortname": ":flying_disc:",
        "unicode": "1F94F",
        "html": "&#x1F94F;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#129359"
    },
    {
        "index": 777,
        "emoji": "🎳",
        "name": "bowling",
        "shortname": ":bowling:",
        "unicode": "1f3b3",
        "html": "&#127923;",
        "category": "Activities (sport)",
        "order": 1789,
        "htmlcode": "&#127923"
    },
    {
        "index": 778,
        "emoji": "🏏",
        "name": "cricket game",
        "shortname": ":cricket_game:",
        "unicode": "1F3CF",
        "html": "&#127951;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#127951"
    },
    {
        "index": 779,
        "emoji": "🏑",
        "name": "field hockey",
        "shortname": ":field_hockey:",
        "unicode": "1F3D1",
        "html": "&#127953;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#127953"
    },
    {
        "index": 780,
        "emoji": "🏒",
        "name": "ice hockey",
        "shortname": ":ice_hockey:",
        "unicode": "1F3D2",
        "html": "&#127954;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#127954"
    },
    {
        "index": 781,
        "emoji": "🥍",
        "name": "lacrosse",
        "shortname": ":lacrosse:",
        "unicode": "1F94D",
        "html": "&#x1F94D;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#129357"
    },
    {
        "index": 782,
        "emoji": "🏓",
        "name": "ping pong",
        "shortname": ":ping_pong:",
        "unicode": "1F3D3",
        "html": "&#127955;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#127955"
    },
    {
        "index": 783,
        "emoji": "🏸",
        "name": "badminton",
        "shortname": ":badminton:",
        "unicode": "1F3F8",
        "html": "&#127992;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#127992"
    },
    {
        "index": 784,
        "emoji": "🥊",
        "name": "boxing glove",
        "shortname": ":boxing_glove:",
        "unicode": "1F94A",
        "html": "&#x1F94A;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#129354"
    },
    {
        "index": 785,
        "emoji": "🥋",
        "name": "martial arts uniform",
        "shortname": ":martial_arts_uniform:",
        "unicode": "1F94B",
        "html": "&#x1F94B;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#129355"
    },
    {
        "index": 786,
        "emoji": "🥅",
        "name": "goal net",
        "shortname": ":goal_net:",
        "unicode": "1F945",
        "html": "&#x1F945;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#129349"
    },
    {
        "index": 787,
        "emoji": "🎣",
        "name": "fishing pole",
        "shortname": ":fishing_pole_and_fish:",
        "unicode": "1f3a3",
        "html": "&#127907;",
        "category": "Activities (sport)",
        "order": 1801,
        "htmlcode": "&#127907"
    },
    {
        "index": 788,
        "emoji": "🤿",
        "name": "diving mask",
        "shortname": ":diving_mask:",
        "unicode": "1F93F",
        "html": "&#x1F93F;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#129343"
    },
    {
        "index": 789,
        "emoji": "🎽",
        "name": "running shirt",
        "shortname": ":running_shirt_with_sash:",
        "unicode": "1f3bd",
        "html": "&#127933;",
        "category": "Activities (sport)",
        "order": 1802,
        "htmlcode": "&#127933"
    },
    {
        "index": 790,
        "emoji": "🎿",
        "name": "skis",
        "shortname": ":ski:",
        "unicode": "1f3bf",
        "html": "&#127935;",
        "category": "Activities (sport)",
        "order": 1803,
        "htmlcode": "&#127935"
    },
    {
        "index": 791,
        "emoji": "🛷",
        "name": "sled",
        "shortname": ":sled:",
        "unicode": "1F6F7",
        "html": "&#x1F6F7;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#128759"
    },
    {
        "index": 792,
        "emoji": "🥌",
        "name": "curling stone",
        "shortname": ":curling_stone:",
        "unicode": "1F94C",
        "html": "&#x1F94C;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#129356"
    },
    {
        "index": 793,
        "emoji": "🎯",
        "name": "direct hit",
        "shortname": ":dart:",
        "unicode": "1f3af",
        "html": "&#127919;",
        "category": "Activities (game)",
        "order": 1798,
        "htmlcode": "&#127919"
    },
    {
        "index": 794,
        "emoji": "🪀",
        "name": "yo-yo",
        "shortname": ":yoyo:",
        "unicode": "1FA80",
        "html": "&#x1FA80;",
        "category": "Activities (game)",
        "order": 63.5,
        "htmlcode": "&#129664"
    },
    {
        "index": 795,
        "emoji": "🪁",
        "name": "kite",
        "shortname": ":kite:",
        "unicode": "1FA81",
        "html": "&#x1FA81;",
        "category": "Activities (game)",
        "order": 63.5,
        "htmlcode": "&#129665"
    },
    {
        "index": 796,
        "emoji": "🎱",
        "name": "pool 8 ball",
        "shortname": ":8ball:",
        "unicode": "1f3b1",
        "html": "&#127921;",
        "category": "Activities (game)",
        "order": 1788,
        "htmlcode": "&#127921"
    },
    {
        "index": 797,
        "emoji": "🔮",
        "name": "crystal ball",
        "shortname": ":crystal_ball:",
        "unicode": "1f52e",
        "html": "&#128302;",
        "category": "Activities (game)",
        "order": 1974,
        "htmlcode": "&#128302"
    },
    // {
    //     "index": 798,
    //     "htmlcode": "&#129668"
    // },
    {
        "index": 799,
        "emoji": "🧿",
        "name": "nazar amulet",
        "shortname": ":nazar_amulet:",
        "unicode": "1F9FF",
        "html": "&#x1F9FF;",
        "category": "Activities (game)",
        "order": 63.5,
        "htmlcode": "&#129535"
    },
    {
        "index": 800,
        "emoji": "🎮",
        "name": "video game",
        "shortname": ":video_game:",
        "unicode": "1f3ae",
        "html": "&#127918;",
        "category": "Activities (game)",
        "order": 1804,
        "htmlcode": "&#127918"
    },
    {
        "index": 801,
        "emoji": "🕹",
        "name": "joystick",
        "shortname": ":joystick:",
        "unicode": "1f579",
        "html": "&#128377;",
        "category": "Activities (game)",
        "order": 1805,
        "htmlcode": "&#128377"
    },
    {
        "index": 802,
        "emoji": "🎰",
        "name": "slot machine",
        "shortname": ":slot_machine:",
        "unicode": "1f3b0",
        "html": "&#127920;",
        "category": "Activities (game)",
        "order": 1601,
        "htmlcode": "&#127920"
    },
    {
        "index": 803,
        "emoji": "🎲",
        "name": "game die",
        "shortname": ":game_die:",
        "unicode": "1f3b2",
        "html": "&#127922;",
        "category": "Activities (game)",
        "order": 1806,
        "htmlcode": "&#127922"
    },
    {
        "index": 804,
        "emoji": "🧩",
        "name": "puzzle piece",
        "shortname": ":puzzle_piece:",
        "unicode": "1F9E9",
        "html": "&#x1F9E9;",
        "category": "Activities (game)",
        "order": 63.5,
        "htmlcode": "&#129513"
    },
    {
        "index": 805,
        "emoji": "🧸",
        "name": "teddy bear",
        "shortname": ":teddy_bear:",
        "unicode": "1F9F8",
        "html": "&#x1F9F8;",
        "category": "Activities (game)",
        "order": 63.5,
        "htmlcode": "&#129528"
    },
    // {
    //     "index": 806,
    //     "htmlcode": "&#129669"
    // },
    // {
    //     "index": 807,
    //     "htmlcode": "&#129670"
    // },
    {
        "index": 808,
        "emoji": "🃏",
        "name": "joker",
        "shortname": ":black_joker:",
        "unicode": "1f0cf",
        "html": "&#127183;",
        "category": "Activities (game)",
        "order": 1811,
        "htmlcode": "&#127183"
    },
    {
        "index": 809,
        "emoji": "🀄",
        "name": "mahjong red dragon",
        "shortname": ":mahjong:",
        "unicode": "1f004",
        "html": "&#126980;",
        "category": "Activities (game)",
        "order": 1812,
        "htmlcode": "&#126980"
    },
    {
        "index": 810,
        "emoji": "🎴",
        "name": "flower playing cards",
        "shortname": ":flower_playing_cards:",
        "unicode": "1f3b4",
        "html": "&#127924;",
        "category": "Activities (game)",
        "order": 1813,
        "htmlcode": "&#127924"
    },
    {
        "index": 811,
        "emoji": "🎭",
        "name": "performing arts",
        "shortname": ":performing_arts:",
        "unicode": "1f3ad",
        "html": "&#127917;",
        "category": "Activities (arts & crafts)",
        "order": 1598,
        "htmlcode": "&#127917"
    },
    {
        "index": 812,
        "emoji": "🖼",
        "name": "framed picture",
        "shortname": ":framed_picture:",
        "unicode": "1F5BC",
        "html": "&#128444;",
        "category": "Activities (arts & crafts)",
        "order": 63.5,
        "htmlcode": "&#128444"
    },
    {
        "index": 813,
        "emoji": "🧵",
        "name": "thread",
        "shortname": ":thread:",
        "unicode": "1F9F5",
        "html": "&#x1F9F5;",
        "category": "Activities (arts & crafts)",
        "order": 63.5,
        "htmlcode": "&#129525"
    },
    // {
    //     "index": 814,
    //     "htmlcode": "&#129697"
    // },
    {
        "index": 815,
        "emoji": "🧶",
        "name": "yarn",
        "shortname": ":yarn:",
        "unicode": "1F9F6",
        "html": "&#x1F9F6;",
        "category": "Activities (arts & crafts)",
        "order": 63.5,
        "htmlcode": "&#129526"
    },
    // {
    //     "index": 816,
    //     "htmlcode": "&#129698"
    // },
    {
        "index": 817,
        "emoji": "👓",
        "name": "glasses",
        "shortname": ":eyeglasses:",
        "unicode": "1f453",
        "html": "&#128083;",
        "category": "Objects (clothing)",
        "order": 1314,
        "htmlcode": "&#128083"
    },
    {
        "index": 818,
        "emoji": "🕶",
        "name": "sunglasses",
        "shortname": ":dark_sunglasses:",
        "unicode": "1f576",
        "html": "&#128374;",
        "category": "Objects (clothing)",
        "order": 1315,
        "htmlcode": "&#128374"
    },
    {
        "index": 819,
        "emoji": "🥽",
        "name": "goggles",
        "shortname": ":goggles:",
        "unicode": "1F97D",
        "html": "&#x1F97D;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129405"
    },
    {
        "index": 820,
        "emoji": "🥼",
        "name": "lab coat",
        "shortname": ":lab_coat:",
        "unicode": "1F97C",
        "html": "&#x1F97C;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129404"
    },
    {
        "index": 821,
        "emoji": "👔",
        "name": "necktie",
        "shortname": ":necktie:",
        "unicode": "1f454",
        "html": "&#128084;",
        "category": "Objects (clothing)",
        "order": 1316,
        "htmlcode": "&#128084"
    },
    {
        "index": 822,
        "emoji": "👕",
        "name": "t-shirt",
        "shortname": ":shirt:",
        "unicode": "1f455",
        "html": "&#128085;",
        "category": "Objects (clothing)",
        "order": 1317,
        "htmlcode": "&#128085"
    },
    {
        "index": 823,
        "emoji": "👖",
        "name": "jeans",
        "shortname": ":jeans:",
        "unicode": "1f456",
        "html": "&#128086;",
        "category": "Objects (clothing)",
        "order": 1318,
        "htmlcode": "&#128086"
    },
    {
        "index": 824,
        "emoji": "🧣",
        "name": "scarf",
        "shortname": ":scarf:",
        "unicode": "1F9E3",
        "html": "&#x1F9E3;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129507"
    },
    {
        "index": 825,
        "emoji": "🧤",
        "name": "gloves",
        "shortname": ":gloves:",
        "unicode": "1F9E4",
        "html": "&#x1F9E4;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129508"
    },
    {
        "index": 826,
        "emoji": "🧥",
        "name": "coat",
        "shortname": ":coat:",
        "unicode": "1F9E5",
        "html": "&#x1F9E5;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129509"
    },
    {
        "index": 827,
        "emoji": "🧦",
        "name": "socks",
        "shortname": ":socks:",
        "unicode": "1F9E6",
        "html": "&#x1F9E6;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129510"
    },
    {
        "index": 828,
        "emoji": "👗",
        "name": "dress",
        "shortname": ":dress:",
        "unicode": "1f457",
        "html": "&#128087;",
        "category": "Objects (clothing)",
        "order": 1319,
        "htmlcode": "&#128087"
    },
    {
        "index": 829,
        "emoji": "👘",
        "name": "kimono",
        "shortname": ":kimono:",
        "unicode": "1f458",
        "html": "&#128088;",
        "category": "Objects (clothing)",
        "order": 1320,
        "htmlcode": "&#128088"
    },
    {
        "index": 830,
        "emoji": "🥻",
        "name": "sari",
        "shortname": ":sari:",
        "unicode": "1F97B",
        "html": "&#x1F97B;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129403"
    },
    {
        "index": 831,
        "emoji": "🩱",
        "name": "one-piece swimsuit",
        "shortname": ":onepiece_swimsuit:",
        "unicode": "1FA71",
        "html": "&#x1FA71;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129649"
    },
    {
        "index": 832,
        "emoji": "🩲",
        "name": "briefs",
        "shortname": ":briefs:",
        "unicode": "1FA72",
        "html": "&#x1FA72;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129650"
    },
    {
        "index": 833,
        "emoji": "🩳",
        "name": "shorts",
        "shortname": ":shorts:",
        "unicode": "1FA73",
        "html": "&#x1FA73;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129651"
    },
    {
        "index": 834,
        "emoji": "👙",
        "name": "bikini",
        "shortname": ":bikini:",
        "unicode": "1f459",
        "html": "&#128089;",
        "category": "Objects (clothing)",
        "order": 1321,
        "htmlcode": "&#128089"
    },
    {
        "index": 835,
        "emoji": "👚",
        "name": "woman’s clothes",
        "shortname": ":womans_clothes:",
        "unicode": "1f45a",
        "html": "&#128090;",
        "category": "Objects (clothing)",
        "order": 1322,
        "htmlcode": "&#128090"
    },
    {
        "index": 836,
        "emoji": "👛",
        "name": "purse",
        "shortname": ":purse:",
        "unicode": "1f45b",
        "html": "&#128091;",
        "category": "Objects (clothing)",
        "order": 1323,
        "htmlcode": "&#128091"
    },
    {
        "index": 837,
        "emoji": "👜",
        "name": "handbag",
        "shortname": ":handbag:",
        "unicode": "1f45c",
        "html": "&#128092;",
        "category": "Objects (clothing)",
        "order": 1324,
        "htmlcode": "&#128092"
    },
    {
        "index": 838,
        "emoji": "👝",
        "name": "clutch bag",
        "shortname": ":pouch:",
        "unicode": "1f45d",
        "html": "&#128093;",
        "category": "Objects (clothing)",
        "order": 1325,
        "htmlcode": "&#128093"
    },
    {
        "index": 839,
        "emoji": "🛍",
        "name": "shopping bags",
        "shortname": ":shopping_bags:",
        "unicode": "1f6cd",
        "html": "&#128717;",
        "category": "Objects (clothing)",
        "order": 1326,
        "htmlcode": "&#128717"
    },
    {
        "index": 840,
        "emoji": "🎒",
        "name": "backpack",
        "shortname": ":school_satchel:",
        "unicode": "1f392",
        "html": "&#127890;",
        "category": "Objects (clothing)",
        "order": 1327,
        "htmlcode": "&#127890"
    },
    {
        "index": 841,
        "htmlcode": "&#129652"
    },
    {
        "index": 842,
        "emoji": "👞",
        "name": "man’s shoe",
        "shortname": ":mans_shoe:",
        "unicode": "1f45e",
        "html": "&#128094;",
        "category": "Objects (clothing)",
        "order": 1328,
        "htmlcode": "&#128094"
    },
    {
        "index": 843,
        "emoji": "👟",
        "name": "running shoe",
        "shortname": ":athletic_shoe:",
        "unicode": "1f45f",
        "html": "&#128095;",
        "category": "Objects (clothing)",
        "order": 1329,
        "htmlcode": "&#128095"
    },
    {
        "index": 844,
        "emoji": "🥾",
        "name": "hiking boot",
        "shortname": ":hiking_boot:",
        "unicode": "1F97E",
        "html": "&#x1F97E;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129406"
    },
    {
        "index": 845,
        "emoji": "🥿",
        "name": "flat shoe",
        "shortname": ":flat_shoe:",
        "unicode": "1F97F",
        "html": "&#x1F97F;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129407"
    },
    {
        "index": 846,
        "emoji": "👠",
        "name": "high-heeled shoe",
        "shortname": ":high_heel:",
        "unicode": "1f460",
        "html": "&#128096;",
        "category": "Objects (clothing)",
        "order": 1330,
        "htmlcode": "&#128096"
    },
    {
        "index": 847,
        "emoji": "👡",
        "name": "woman’s sandal",
        "shortname": ":sandal:",
        "unicode": "1f461",
        "html": "&#128097;",
        "category": "Objects (clothing)",
        "order": 1331,
        "htmlcode": "&#128097"
    },
    {
        "index": 848,
        "emoji": "🩰",
        "name": "ballet shoes",
        "shortname": ":ballet_shoes:",
        "unicode": "1FA70",
        "html": "&#x1FA70;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129648"
    },
    {
        "index": 849,
        "emoji": "👢",
        "name": "woman’s boot",
        "shortname": ":boot:",
        "unicode": "1f462",
        "html": "&#128098;",
        "category": "Objects (clothing)",
        "order": 1332,
        "htmlcode": "&#128098"
    },
    {
        "index": 850,
        "emoji": "👑",
        "name": "crown",
        "shortname": ":crown:",
        "unicode": "1f451",
        "html": "&#128081;",
        "category": "Objects (clothing)",
        "order": 1333,
        "htmlcode": "&#128081"
    },
    {
        "index": 851,
        "emoji": "👒",
        "name": "woman’s hat",
        "shortname": ":womans_hat:",
        "unicode": "1f452",
        "html": "&#128082;",
        "category": "Objects (clothing)",
        "order": 1334,
        "htmlcode": "&#128082"
    },
    {
        "index": 852,
        "emoji": "🎩",
        "name": "top hat",
        "shortname": ":tophat:",
        "unicode": "1f3a9",
        "html": "&#127913;",
        "category": "Objects (clothing)",
        "order": 1335,
        "htmlcode": "&#127913"
    },
    {
        "index": 853,
        "emoji": "🧢",
        "name": "billed cap",
        "shortname": ":billed_cap:",
        "unicode": "1F9E2",
        "html": "&#x1F9E2;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#129506"
    },
    // {
    //     "index": 854,
    //     "htmlcode": "&#129686"
    // },
    {
        "index": 855,
        "emoji": "📿",
        "name": "prayer beads",
        "shortname": ":prayer_beads:",
        "unicode": "1f4ff",
        "html": "&#128255;",
        "category": "Objects (clothing)",
        "order": 1338,
        "htmlcode": "&#128255"
    },
    {
        "index": 856,
        "emoji": "💄",
        "name": "lipstick",
        "shortname": ":lipstick:",
        "unicode": "1f484",
        "html": "&#128132;",
        "category": "Objects (clothing)",
        "order": 1339,
        "htmlcode": "&#128132"
    },
    {
        "index": 857,
        "emoji": "💍",
        "name": "ring",
        "shortname": ":ring:",
        "unicode": "1f48d",
        "html": "&#128141;",
        "category": "Objects (clothing)",
        "order": 1340,
        "htmlcode": "&#128141"
    },
    {
        "index": 858,
        "emoji": "💎",
        "name": "gem stone",
        "shortname": ":gem:",
        "unicode": "1f48e",
        "html": "&#128142;",
        "category": "Objects (clothing)",
        "order": 1341,
        "htmlcode": "&#128142"
    },
    {
        "index": 859,
        "emoji": "🔇",
        "name": "muted speaker",
        "shortname": ":mute:",
        "unicode": "1f507",
        "html": "&#128263;",
        "category": "Objects (sound)",
        "order": 1814,
        "htmlcode": "&#128263"
    },
    {
        "index": 860,
        "emoji": "🔈",
        "name": "speaker low volume",
        "shortname": ":speaker:",
        "unicode": "1f508",
        "html": "&#128264;",
        "category": "Objects (sound)",
        "order": 1815,
        "htmlcode": "&#128264"
    },
    {
        "index": 861,
        "emoji": "🔉",
        "name": "speaker medium volume",
        "shortname": ":sound:",
        "unicode": "1f509",
        "html": "&#128265;",
        "category": "Objects (sound)",
        "order": 1816,
        "htmlcode": "&#128265"
    },
    {
        "index": 862,
        "emoji": "🔊",
        "name": "speaker high volume",
        "shortname": ":loud_sound:",
        "unicode": "1f50a",
        "html": "&#128266;",
        "category": "Objects (sound)",
        "order": 1817,
        "htmlcode": "&#128266"
    },
    {
        "index": 863,
        "emoji": "📢",
        "name": "loudspeaker",
        "shortname": ":loudspeaker:",
        "unicode": "1f4e2",
        "html": "&#128226;",
        "category": "Objects (sound)",
        "order": 1818,
        "htmlcode": "&#128226"
    },
    {
        "index": 864,
        "emoji": "📣",
        "name": "megaphone",
        "shortname": ":mega:",
        "unicode": "1f4e3",
        "html": "&#128227;",
        "category": "Objects (sound)",
        "order": 1819,
        "htmlcode": "&#128227"
    },
    {
        "index": 865,
        "emoji": "📯",
        "name": "postal horn",
        "shortname": ":postal_horn:",
        "unicode": "1f4ef",
        "html": "&#128239;",
        "category": "Objects (sound)",
        "order": 1820,
        "htmlcode": "&#128239"
    },
    {
        "index": 866,
        "emoji": "🔔",
        "name": "bell",
        "shortname": ":bell:",
        "unicode": "1f514",
        "html": "&#128276;",
        "category": "Objects (sound)",
        "order": 1821,
        "htmlcode": "&#128276"
    },
    {
        "index": 867,
        "emoji": "🔕",
        "name": "bell with slash",
        "shortname": ":no_bell:",
        "unicode": "1f515",
        "html": "&#128277;",
        "category": "Objects (sound)",
        "order": 1822,
        "htmlcode": "&#128277"
    },
    {
        "index": 868,
        "emoji": "🎼",
        "name": "musical score",
        "shortname": ":musical_score:",
        "unicode": "1f3bc",
        "html": "&#127932;",
        "category": "Objects (music)",
        "order": 1823,
        "htmlcode": "&#127932"
    },
    {
        "index": 869,
        "emoji": "🎵",
        "name": "musical note",
        "shortname": ":musical_note:",
        "unicode": "1f3b5",
        "html": "&#127925;",
        "category": "Objects (music)",
        "order": 1824,
        "htmlcode": "&#127925"
    },
    {
        "index": 870,
        "emoji": "🎶",
        "name": "musical notes",
        "shortname": ":notes:",
        "unicode": "1f3b6",
        "html": "&#127926;",
        "category": "Objects (music)",
        "order": 1825,
        "htmlcode": "&#127926"
    },
    {
        "index": 871,
        "emoji": "🎙",
        "name": "studio microphone",
        "shortname": ":studio_microphone:",
        "unicode": "1F399",
        "html": "&#127897;",
        "category": "Objects (music)",
        "order": 63.5,
        "htmlcode": "&#127897"
    },
    {
        "index": 872,
        "emoji": "🎚",
        "name": "level slider",
        "shortname": ":level_slider:",
        "unicode": "1f39a",
        "html": "&#127898;",
        "category": "Objects (music)",
        "order": 1827,
        "htmlcode": "&#127898"
    },
    {
        "index": 873,
        "emoji": "🎛",
        "name": "control knobs",
        "shortname": ":control_knobs:",
        "unicode": "1f39b",
        "html": "&#127899;",
        "category": "Objects (music)",
        "order": 1828,
        "htmlcode": "&#127899"
    },
    {
        "index": 874,
        "emoji": "🎧",
        "name": "headphone",
        "shortname": ":headphones:",
        "unicode": "1f3a7",
        "html": "&#127911;",
        "category": "Objects (music)",
        "order": 1830,
        "htmlcode": "&#127911"
    },
    {
        "index": 875,
        "emoji": "📻",
        "name": "radio",
        "shortname": ":radio:",
        "unicode": "1f4fb",
        "html": "&#128251;",
        "category": "Objects (music)",
        "order": 1831,
        "htmlcode": "&#128251"
    },
    {
        "index": 876,
        "emoji": "🎷",
        "name": "saxophone",
        "shortname": ":saxophone:",
        "unicode": "1f3b7",
        "html": "&#127927;",
        "category": "Objects (musical-instrument)",
        "order": 1832,
        "htmlcode": "&#127927"
    },
    // {
    //     "index": 877,
    //     "htmlcode": "&#129687"
    // },
    {
        "index": 878,
        "emoji": "🎸",
        "name": "guitar",
        "shortname": ":guitar:",
        "unicode": "1f3b8",
        "html": "&#127928;",
        "category": "Objects (musical-instrument)",
        "order": 1833,
        "htmlcode": "&#127928"
    },
    {
        "index": 879,
        "emoji": "🎹",
        "name": "musical keyboard",
        "shortname": ":musical_keyboard:",
        "unicode": "1f3b9",
        "html": "&#127929;",
        "category": "Objects (musical-instrument)",
        "order": 1834,
        "htmlcode": "&#127929"
    },
    {
        "index": 880,
        "emoji": "🎺",
        "name": "trumpet",
        "shortname": ":trumpet:",
        "unicode": "1f3ba",
        "html": "&#127930;",
        "category": "Objects (musical-instrument)",
        "order": 1835,
        "htmlcode": "&#127930"
    },
    {
        "index": 881,
        "emoji": "🎻",
        "name": "violin",
        "shortname": ":violin:",
        "unicode": "1f3bb",
        "html": "&#127931;",
        "category": "Objects (musical-instrument)",
        "order": 1836,
        "htmlcode": "&#127931"
    },
    {
        "index": 882,
        "emoji": "🪕",
        "name": "banjo",
        "shortname": ":banjo:",
        "unicode": "1FA95",
        "html": "&#x1FA95;",
        "category": "Objects (musical-instrument)",
        "order": 63.5,
        "htmlcode": "&#129685"
    },
    {
        "index": 883,
        "emoji": "🥁",
        "name": "drum",
        "shortname": ":drum:",
        "unicode": "1f941",
        "html": "&#129345;",
        "category": "Objects (musical-instrument)",
        "order": 1837,
        "htmlcode": "&#129345"
    },
    // {
    //     "index": 884,
    //     "htmlcode": "&#129688"
    // },
    {
        "index": 885,
        "emoji": "📱",
        "name": "mobile phone",
        "shortname": ":iphone:",
        "unicode": "1f4f1",
        "html": "&#128241;",
        "category": "Objects (phone)",
        "order": 1838,
        "htmlcode": "&#128241"
    },
    {
        "index": 886,
        "emoji": "📲",
        "name": "mobile phone with arrow",
        "shortname": ":calling:",
        "unicode": "1f4f2",
        "html": "&#128242;",
        "category": "Objects (phone)",
        "order": 1839,
        "htmlcode": "&#128242"
    },
    {
        "index": 887,
        "emoji": "📞",
        "name": "telephone receiver",
        "shortname": ":telephone_receiver:",
        "unicode": "1f4de",
        "html": "&#128222;",
        "category": "Objects (phone)",
        "order": 1841,
        "htmlcode": "&#128222"
    },
    {
        "index": 888,
        "emoji": "📟",
        "name": "pager",
        "shortname": ":pager:",
        "unicode": "1f4df",
        "html": "&#128223;",
        "category": "Objects (phone)",
        "order": 1842,
        "htmlcode": "&#128223"
    },
    {
        "index": 889,
        "emoji": "📠",
        "name": "fax machine",
        "shortname": ":fax:",
        "unicode": "1f4e0",
        "html": "&#128224;",
        "category": "Objects (phone)",
        "order": 1843,
        "htmlcode": "&#128224"
    },
    {
        "index": 890,
        "emoji": "🔋",
        "name": "battery",
        "shortname": ":battery:",
        "unicode": "1f50b",
        "html": "&#128267;",
        "category": "Objects (computer)",
        "order": 1844,
        "htmlcode": "&#128267"
    },
    {
        "index": 891,
        "emoji": "🔌",
        "name": "electric plug",
        "shortname": ":electric_plug:",
        "unicode": "1f50c",
        "html": "&#128268;",
        "category": "Objects (computer)",
        "order": 1845,
        "htmlcode": "&#128268"
    },
    {
        "index": 892,
        "emoji": "🖥",
        "name": "desktop computer",
        "shortname": ":desktop_computer:",
        "unicode": "1F5A5",
        "html": "&#128421;",
        "category": "Objects (computer)",
        "order": 63.5,
        "htmlcode": "&#128421"
    },
    {
        "index": 893,
        "emoji": "🖨",
        "name": "printer",
        "shortname": ":printer:",
        "unicode": "1f5a8",
        "html": "&#128424;",
        "category": "Objects (computer)",
        "order": 1848,
        "htmlcode": "&#128424"
    },
    {
        "index": 894,
        "emoji": "🖱",
        "name": "computer mouse",
        "shortname": ":computer_mouse:",
        "unicode": "1F5B1",
        "html": "&#128433;",
        "category": "Objects (computer)",
        "order": 63.5,
        "htmlcode": "&#128433"
    },
    {
        "index": 895,
        "emoji": "🖲",
        "name": "trackball",
        "shortname": ":trackball:",
        "unicode": "1f5b2",
        "html": "&#128434;",
        "category": "Objects (computer)",
        "order": 1851,
        "htmlcode": "&#128434"
    },
    {
        "index": 896,
        "emoji": "💽",
        "name": "computer disk",
        "shortname": ":minidisc:",
        "unicode": "1f4bd",
        "html": "&#128189;",
        "category": "Objects (computer)",
        "order": 1852,
        "htmlcode": "&#128189"
    },
    {
        "index": 897,
        "emoji": "💾",
        "name": "floppy disk",
        "shortname": ":floppy_disk:",
        "unicode": "1f4be",
        "html": "&#128190;",
        "category": "Objects (computer)",
        "order": 1853,
        "htmlcode": "&#128190"
    },
    {
        "index": 898,
        "emoji": "💿",
        "name": "optical disk",
        "shortname": ":cd:",
        "unicode": "1f4bf",
        "html": "&#128191;",
        "category": "Objects (computer)",
        "order": 1854,
        "htmlcode": "&#128191"
    },
    {
        "index": 899,
        "emoji": "📀",
        "name": "dvd",
        "shortname": ":dvd:",
        "unicode": "1f4c0",
        "html": "&#128192;",
        "category": "Objects (computer)",
        "order": 1855,
        "htmlcode": "&#128192"
    },
    {
        "index": 900,
        "emoji": "🧮",
        "name": "abacus",
        "shortname": ":abacus:",
        "unicode": "1F9EE",
        "html": "&#x1F9EE;",
        "category": "Objects (computer)",
        "order": 63.5,
        "htmlcode": "&#129518"
    },
    {
        "index": 901,
        "emoji": "🎥",
        "name": "movie camera",
        "shortname": ":movie_camera:",
        "unicode": "1f3a5",
        "html": "&#127909;",
        "category": "Objects (light & video)",
        "order": 1856,
        "htmlcode": "&#127909"
    },
    {
        "index": 902,
        "emoji": "🎞",
        "name": "film frames",
        "shortname": ":film_frames:",
        "unicode": "1f39e",
        "html": "&#127902;",
        "category": "Objects (light & video)",
        "order": 1857,
        "htmlcode": "&#127902"
    },
    {
        "index": 903,
        "emoji": "📽",
        "name": "film projector",
        "shortname": ":film_projector:",
        "unicode": "1F4FD",
        "html": "&#128253;",
        "category": "Objects (light & video)",
        "order": 63.5,
        "htmlcode": "&#128253"
    },
    {
        "index": 904,
        "emoji": "🎬",
        "name": "clapper board",
        "shortname": ":clapper:",
        "unicode": "1f3ac",
        "html": "&#127916;",
        "category": "Objects (light & video)",
        "order": 1859,
        "htmlcode": "&#127916"
    },
    {
        "index": 905,
        "emoji": "📺",
        "name": "television",
        "shortname": ":tv:",
        "unicode": "1f4fa",
        "html": "&#128250;",
        "category": "Objects (light & video)",
        "order": 1860,
        "htmlcode": "&#128250"
    },
    {
        "index": 906,
        "emoji": "📷",
        "name": "camera",
        "shortname": ":camera:",
        "unicode": "1f4f7",
        "html": "&#128247;",
        "category": "Objects (light & video)",
        "order": 1861,
        "htmlcode": "&#128247"
    },
    {
        "index": 907,
        "emoji": "📸",
        "name": "camera with flash",
        "shortname": ":camera_with_flash:",
        "unicode": "1f4f8",
        "html": "&#128248;",
        "category": "Objects (light & video)",
        "order": 1862,
        "htmlcode": "&#128248"
    },
    {
        "index": 908,
        "emoji": "📹",
        "name": "video camera",
        "shortname": ":video_camera:",
        "unicode": "1f4f9",
        "html": "&#128249;",
        "category": "Objects (light & video)",
        "order": 1863,
        "htmlcode": "&#128249"
    },
    {
        "index": 909,
        "emoji": "📼",
        "name": "videocassette",
        "shortname": ":vhs:",
        "unicode": "1f4fc",
        "html": "&#128252;",
        "category": "Objects (light & video)",
        "order": 1864,
        "htmlcode": "&#128252"
    },
    {
        "index": 910,
        "emoji": "🔍",
        "name": "magnifying glass tilted left",
        "shortname": ":mag:",
        "unicode": "1f50d",
        "html": "&#128269;",
        "category": "Objects (light & video)",
        "order": 1865,
        "htmlcode": "&#128269"
    },
    {
        "index": 911,
        "emoji": "🔎",
        "name": "magnifying glass tilted right",
        "shortname": ":mag_right:",
        "unicode": "1f50e",
        "html": "&#128270;",
        "category": "Objects (light & video)",
        "order": 1866,
        "htmlcode": "&#128270"
    },
    {
        "index": 912,
        "emoji": "🕯",
        "name": "candle",
        "shortname": ":candle:",
        "unicode": "1f56f",
        "html": "&#128367;",
        "category": "Objects (light & video)",
        "order": 1870,
        "htmlcode": "&#128367"
    },
    {
        "index": 913,
        "emoji": "💡",
        "name": "light bulb",
        "shortname": ":bulb:",
        "unicode": "1f4a1",
        "html": "&#128161;",
        "category": "Objects (light & video)",
        "order": 1871,
        "htmlcode": "&#128161"
    },
    {
        "index": 914,
        "emoji": "🔦",
        "name": "flashlight",
        "shortname": ":flashlight:",
        "unicode": "1f526",
        "html": "&#128294;",
        "category": "Objects (light & video)",
        "order": 1872,
        "htmlcode": "&#128294"
    },
    {
        "index": 915,
        "emoji": "🏮",
        "name": "red paper lantern",
        "shortname": ":izakaya_lantern:",
        "unicode": "1f3ee",
        "html": "&#127982;",
        "category": "Objects (light & video)",
        "order": 1873,
        "htmlcode": "&#127982"
    },
    {
        "index": 916,
        "emoji": "🪔",
        "name": "diya lamp",
        "shortname": ":diya_lamp:",
        "unicode": "1FA94",
        "html": "&#x1FA94;",
        "category": "Objects (light & video)",
        "order": 63.5,
        "htmlcode": "&#129684"
    },
    {
        "index": 917,
        "emoji": "📔",
        "name": "notebook with decorative cover",
        "shortname": ":notebook_with_decorative_cover:",
        "unicode": "1f4d4",
        "html": "&#128212;",
        "category": "Objects (book-paper)",
        "order": 1874,
        "htmlcode": "&#128212"
    },
    {
        "index": 918,
        "emoji": "📕",
        "name": "closed book",
        "shortname": ":closed_book:",
        "unicode": "1f4d5",
        "html": "&#128213;",
        "category": "Objects (book-paper)",
        "order": 1875,
        "htmlcode": "&#128213"
    },
    {
        "index": 919,
        "emoji": "📖",
        "name": "open book",
        "shortname": ":book:",
        "unicode": "1f4d6",
        "html": "&#128214;",
        "category": "Objects (book-paper)",
        "order": 1876,
        "htmlcode": "&#128214"
    },
    {
        "index": 920,
        "emoji": "📗",
        "name": "green book",
        "shortname": ":green_book:",
        "unicode": "1f4d7",
        "html": "&#128215;",
        "category": "Objects (book-paper)",
        "order": 1877,
        "htmlcode": "&#128215"
    },
    {
        "index": 921,
        "emoji": "📘",
        "name": "blue book",
        "shortname": ":blue_book:",
        "unicode": "1f4d8",
        "html": "&#128216;",
        "category": "Objects (book-paper)",
        "order": 1878,
        "htmlcode": "&#128216"
    },
    {
        "index": 922,
        "emoji": "📙",
        "name": "orange book",
        "shortname": ":orange_book:",
        "unicode": "1f4d9",
        "html": "&#128217;",
        "category": "Objects (book-paper)",
        "order": 1879,
        "htmlcode": "&#128217"
    },
    {
        "index": 923,
        "emoji": "📚",
        "name": "books",
        "shortname": ":books:",
        "unicode": "1f4da",
        "html": "&#128218;",
        "category": "Objects (book-paper)",
        "order": 1880,
        "htmlcode": "&#128218"
    },
    {
        "index": 924,
        "emoji": "📓",
        "name": "notebook",
        "shortname": ":notebook:",
        "unicode": "1f4d3",
        "html": "&#128211;",
        "category": "Objects (book-paper)",
        "order": 1881,
        "htmlcode": "&#128211"
    },
    {
        "index": 925,
        "emoji": "📒",
        "name": "ledger",
        "shortname": ":ledger:",
        "unicode": "1f4d2",
        "html": "&#128210;",
        "category": "Objects (book-paper)",
        "order": 1882,
        "htmlcode": "&#128210"
    },
    {
        "index": 926,
        "emoji": "📃",
        "name": "page with curl",
        "shortname": ":page_with_curl:",
        "unicode": "1f4c3",
        "html": "&#128195;",
        "category": "Objects (book-paper)",
        "order": 1883,
        "htmlcode": "&#128195"
    },
    {
        "index": 927,
        "emoji": "📜",
        "name": "scroll",
        "shortname": ":scroll:",
        "unicode": "1f4dc",
        "html": "&#128220;",
        "category": "Objects (book-paper)",
        "order": 1884,
        "htmlcode": "&#128220"
    },
    {
        "index": 928,
        "emoji": "📄",
        "name": "page facing up",
        "shortname": ":page_facing_up:",
        "unicode": "1f4c4",
        "html": "&#128196;",
        "category": "Objects (book-paper)",
        "order": 1885,
        "htmlcode": "&#128196"
    },
    {
        "index": 929,
        "emoji": "📰",
        "name": "newspaper",
        "shortname": ":newspaper:",
        "unicode": "1f4f0",
        "html": "&#128240;",
        "category": "Objects (book-paper)",
        "order": 1886,
        "htmlcode": "&#128240"
    },
    {
        "index": 930,
        "emoji": "🗞",
        "name": "rolled-up newspaper",
        "shortname": ":rolledup_newspaper:",
        "unicode": "1F5DE",
        "html": "&#128478;",
        "category": "Objects (book-paper)",
        "order": 63.5,
        "htmlcode": "&#128478"
    },
    {
        "index": 931,
        "emoji": "📑",
        "name": "bookmark tabs",
        "shortname": ":bookmark_tabs:",
        "unicode": "1f4d1",
        "html": "&#128209;",
        "category": "Objects (book-paper)",
        "order": 1888,
        "htmlcode": "&#128209"
    },
    {
        "index": 932,
        "emoji": "🔖",
        "name": "bookmark",
        "shortname": ":bookmark:",
        "unicode": "1f516",
        "html": "&#128278;",
        "category": "Objects (book-paper)",
        "order": 1889,
        "htmlcode": "&#128278"
    },
    {
        "index": 933,
        "emoji": "🏷",
        "name": "label",
        "shortname": ":label:",
        "unicode": "1f3f7",
        "html": "&#127991;",
        "category": "Objects (book-paper)",
        "order": 1890,
        "htmlcode": "&#127991"
    },
    {
        "index": 934,
        "emoji": "💰",
        "name": "money bag",
        "shortname": ":moneybag:",
        "unicode": "1f4b0",
        "html": "&#128176;",
        "category": "Objects (money)",
        "order": 1891,
        "htmlcode": "&#128176"
    },
    // {
    //     "index": 935,
    //     "htmlcode": "&#129689"
    // },
    {
        "index": 936,
        "emoji": "💴",
        "name": "yen banknote",
        "shortname": ":yen:",
        "unicode": "1f4b4",
        "html": "&#128180;",
        "category": "Objects (money)",
        "order": 1892,
        "htmlcode": "&#128180"
    },
    {
        "index": 937,
        "emoji": "💵",
        "name": "dollar banknote",
        "shortname": ":dollar:",
        "unicode": "1f4b5",
        "html": "&#128181;",
        "category": "Objects (money)",
        "order": 1893,
        "htmlcode": "&#128181"
    },
    {
        "index": 938,
        "emoji": "💶",
        "name": "euro banknote",
        "shortname": ":euro:",
        "unicode": "1f4b6",
        "html": "&#128182;",
        "category": "Objects (money)",
        "order": 1894,
        "htmlcode": "&#128182"
    },
    {
        "index": 939,
        "emoji": "💷",
        "name": "pound banknote",
        "shortname": ":pound:",
        "unicode": "1f4b7",
        "html": "&#128183;",
        "category": "Objects (money)",
        "order": 1895,
        "htmlcode": "&#128183"
    },
    {
        "index": 940,
        "emoji": "💸",
        "name": "money with wings",
        "shortname": ":money_with_wings:",
        "unicode": "1f4b8",
        "html": "&#128184;",
        "category": "Objects (money)",
        "order": 1896,
        "htmlcode": "&#128184"
    },
    {
        "index": 941,
        "emoji": "💳",
        "name": "credit card",
        "shortname": ":credit_card:",
        "unicode": "1f4b3",
        "html": "&#128179;",
        "category": "Objects (money)",
        "order": 1897,
        "htmlcode": "&#128179"
    },
    {
        "index": 942,
        "emoji": "🧾",
        "name": "receipt",
        "shortname": ":receipt:",
        "unicode": "1F9FE",
        "html": "&#x1F9FE;",
        "category": "Objects (money)",
        "order": 63.5,
        "htmlcode": "&#129534"
    },
    {
        "index": 943,
        "emoji": "💹",
        "name": "chart increasing with yen",
        "shortname": ":chart:",
        "unicode": "1f4b9",
        "html": "&#128185;",
        "category": "Objects (money)",
        "order": 1898,
        "htmlcode": "&#128185"
    },
    {
        "index": 944,
        "emoji": "📧",
        "name": "e-mail",
        "shortname": ":e-mail:",
        "unicode": "1f4e7",
        "html": "&#128231;",
        "category": "Objects (mail)",
        "order": 1902,
        "htmlcode": "&#128231"
    },
    {
        "index": 945,
        "emoji": "📨",
        "name": "incoming envelope",
        "shortname": ":incoming_envelope:",
        "unicode": "1f4e8",
        "html": "&#128232;",
        "category": "Objects (mail)",
        "order": 1903,
        "htmlcode": "&#128232"
    },
    {
        "index": 946,
        "emoji": "📩",
        "name": "envelope with arrow",
        "shortname": ":envelope_with_arrow:",
        "unicode": "1f4e9",
        "html": "&#128233;",
        "category": "Objects (mail)",
        "order": 1904,
        "htmlcode": "&#128233"
    },
    {
        "index": 947,
        "emoji": "📤",
        "name": "outbox tray",
        "shortname": ":outbox_tray:",
        "unicode": "1f4e4",
        "html": "&#128228;",
        "category": "Objects (mail)",
        "order": 1905,
        "htmlcode": "&#128228"
    },
    {
        "index": 948,
        "emoji": "📥",
        "name": "inbox tray",
        "shortname": ":inbox_tray:",
        "unicode": "1f4e5",
        "html": "&#128229;",
        "category": "Objects (mail)",
        "order": 1906,
        "htmlcode": "&#128229"
    },
    {
        "index": 949,
        "emoji": "📦",
        "name": "package",
        "shortname": ":package:",
        "unicode": "1f4e6",
        "html": "&#128230;",
        "category": "Objects (mail)",
        "order": 1907,
        "htmlcode": "&#128230"
    },
    {
        "index": 950,
        "emoji": "📫",
        "name": "closed mailbox with raised flag",
        "shortname": ":mailbox:",
        "unicode": "1f4eb",
        "html": "&#128235;",
        "category": "Objects (mail)",
        "order": 1908,
        "htmlcode": "&#128235"
    },
    {
        "index": 951,
        "emoji": "📪",
        "name": "closed mailbox with lowered flag",
        "shortname": ":mailbox_closed:",
        "unicode": "1f4ea",
        "html": "&#128234;",
        "category": "Objects (mail)",
        "order": 1909,
        "htmlcode": "&#128234"
    },
    {
        "index": 952,
        "emoji": "📬",
        "name": "open mailbox with raised flag",
        "shortname": ":mailbox_with_mail:",
        "unicode": "1f4ec",
        "html": "&#128236;",
        "category": "Objects (mail)",
        "order": 1910,
        "htmlcode": "&#128236"
    },
    {
        "index": 953,
        "emoji": "📭",
        "name": "open mailbox with lowered flag",
        "shortname": ":mailbox_with_no_mail:",
        "unicode": "1f4ed",
        "html": "&#128237;",
        "category": "Objects (mail)",
        "order": 1911,
        "htmlcode": "&#128237"
    },
    {
        "index": 954,
        "emoji": "📮",
        "name": "postbox",
        "shortname": ":postbox:",
        "unicode": "1f4ee",
        "html": "&#128238;",
        "category": "Objects (mail)",
        "order": 1912,
        "htmlcode": "&#128238"
    },
    {
        "index": 955,
        "emoji": "🗳",
        "name": "ballot box with ballot",
        "shortname": ":ballot_box:",
        "unicode": "1f5f3",
        "html": "&#128499;",
        "category": "Objects (mail)",
        "order": 1913,
        "htmlcode": "&#128499"
    },
    {
        "index": 956,
        "emoji": "🖋",
        "name": "fountain pen",
        "shortname": ":fountain_pen:",
        "unicode": "1F58B",
        "html": "&#128395;",
        "category": "Objects (writing)",
        "order": 63.5,
        "htmlcode": "&#128395"
    },
    {
        "index": 957,
        "emoji": "🖊",
        "name": "pen",
        "shortname": ":pen:",
        "unicode": "1F58A",
        "html": "&#128394;",
        "category": "Objects (writing)",
        "order": 63.5,
        "htmlcode": "&#128394"
    },
    {
        "index": 958,
        "emoji": "🖌",
        "name": "paintbrush",
        "shortname": ":paintbrush:",
        "unicode": "1F58C",
        "html": "&#128396;",
        "category": "Objects (writing)",
        "order": 63.5,
        "htmlcode": "&#128396"
    },
    {
        "index": 959,
        "emoji": "🖍",
        "name": "crayon",
        "shortname": ":crayon:",
        "unicode": "1F58D",
        "html": "&#128397;",
        "category": "Objects (writing)",
        "order": 63.5,
        "htmlcode": "&#128397"
    },
    {
        "index": 960,
        "emoji": "📝",
        "name": "memo",
        "shortname": ":memo:",
        "unicode": "1F4DD",
        "html": "&#128221;",
        "category": "Objects (writing)",
        "order": 63.5,
        "htmlcode": "&#128221"
    },
    {
        "index": 961,
        "emoji": "📁",
        "name": "file folder",
        "shortname": ":file_folder:",
        "unicode": "1f4c1",
        "html": "&#128193;",
        "category": "Objects (office)",
        "order": 1922,
        "htmlcode": "&#128193"
    },
    {
        "index": 962,
        "emoji": "📂",
        "name": "open file folder",
        "shortname": ":open_file_folder:",
        "unicode": "1f4c2",
        "html": "&#128194;",
        "category": "Objects (office)",
        "order": 1923,
        "htmlcode": "&#128194"
    },
    {
        "index": 963,
        "emoji": "🗂",
        "name": "card index dividers",
        "shortname": ":card_index_dividers:",
        "unicode": "1F5C2",
        "html": "&#128450;",
        "category": "Objects (office)",
        "order": 63.5,
        "htmlcode": "&#128450"
    },
    {
        "index": 964,
        "emoji": "📅",
        "name": "calendar",
        "shortname": ":date:",
        "unicode": "1f4c5",
        "html": "&#128197;",
        "category": "Objects (office)",
        "order": 1925,
        "htmlcode": "&#128197"
    },
    {
        "index": 965,
        "emoji": "📆",
        "name": "tear-off calendar",
        "shortname": ":calendar:",
        "unicode": "1f4c6",
        "html": "&#128198;",
        "category": "Objects (office)",
        "order": 1926,
        "htmlcode": "&#128198"
    },
    {
        "index": 966,
        "emoji": "🗒",
        "name": "spiral notepad",
        "shortname": ":spiral_notepad:",
        "unicode": "1F5D2",
        "html": "&#128466;",
        "category": "Objects (office)",
        "order": 63.5,
        "htmlcode": "&#128466"
    },
    {
        "index": 967,
        "emoji": "🗓",
        "name": "spiral calendar",
        "shortname": ":spiral_calendar:",
        "unicode": "1F5D3",
        "html": "&#128467;",
        "category": "Objects (office)",
        "order": 63.5,
        "htmlcode": "&#128467"
    },
    {
        "index": 968,
        "emoji": "📇",
        "name": "card index",
        "shortname": ":card_index:",
        "unicode": "1f4c7",
        "html": "&#128199;",
        "category": "Objects (office)",
        "order": 1929,
        "htmlcode": "&#128199"
    },
    {
        "index": 969,
        "emoji": "📈",
        "name": "chart increasing",
        "shortname": ":chart_with_upwards_trend:",
        "unicode": "1f4c8",
        "html": "&#128200;",
        "category": "Objects (office)",
        "order": 1930,
        "htmlcode": "&#128200"
    },
    {
        "index": 970,
        "emoji": "📉",
        "name": "chart decreasing",
        "shortname": ":chart_with_downwards_trend:",
        "unicode": "1f4c9",
        "html": "&#128201;",
        "category": "Objects (office)",
        "order": 1931,
        "htmlcode": "&#128201"
    },
    {
        "index": 971,
        "emoji": "📊",
        "name": "bar chart",
        "shortname": ":bar_chart:",
        "unicode": "1f4ca",
        "html": "&#128202;",
        "category": "Objects (office)",
        "order": 1932,
        "htmlcode": "&#128202"
    },
    {
        "index": 972,
        "emoji": "📋",
        "name": "clipboard",
        "shortname": ":clipboard:",
        "unicode": "1f4cb",
        "html": "&#128203;",
        "category": "Objects (office)",
        "order": 1933,
        "htmlcode": "&#128203"
    },
    {
        "index": 973,
        "emoji": "📌",
        "name": "pushpin",
        "shortname": ":pushpin:",
        "unicode": "1f4cc",
        "html": "&#128204;",
        "category": "Objects (office)",
        "order": 1934,
        "htmlcode": "&#128204"
    },
    {
        "index": 974,
        "emoji": "📍",
        "name": "round pushpin",
        "shortname": ":round_pushpin:",
        "unicode": "1f4cd",
        "html": "&#128205;",
        "category": "Objects (office)",
        "order": 1935,
        "htmlcode": "&#128205"
    },
    {
        "index": 975,
        "emoji": "📎",
        "name": "paperclip",
        "shortname": ":paperclip:",
        "unicode": "1f4ce",
        "html": "&#128206;",
        "category": "Objects (office)",
        "order": 1936,
        "htmlcode": "&#128206"
    },
    {
        "index": 976,
        "emoji": "🖇",
        "name": "linked paperclips",
        "shortname": ":linked_paperclips:",
        "unicode": "1F587",
        "html": "&#128391;",
        "category": "Objects (office)",
        "order": 63.5,
        "htmlcode": "&#128391"
    },
    {
        "index": 977,
        "emoji": "📏",
        "name": "straight ruler",
        "shortname": ":straight_ruler:",
        "unicode": "1f4cf",
        "html": "&#128207;",
        "category": "Objects (office)",
        "order": 1938,
        "htmlcode": "&#128207"
    },
    {
        "index": 978,
        "emoji": "📐",
        "name": "triangular ruler",
        "shortname": ":triangular_ruler:",
        "unicode": "1f4d0",
        "html": "&#128208;",
        "category": "Objects (office)",
        "order": 1939,
        "htmlcode": "&#128208"
    },
    {
        "index": 979,
        "emoji": "🗃",
        "name": "card file box",
        "shortname": ":card_file_box:",
        "unicode": "1F5C3",
        "html": "&#128451;",
        "category": "Objects (office)",
        "order": 63.5,
        "htmlcode": "&#128451"
    },
    {
        "index": 980,
        "emoji": "🗄",
        "name": "file cabinet",
        "shortname": ":file_cabinet:",
        "unicode": "1f5c4",
        "html": "&#128452;",
        "category": "Objects (office)",
        "order": 1942,
        "htmlcode": "&#128452"
    },
    {
        "index": 981,
        "emoji": "🗑",
        "name": "wastebasket",
        "shortname": ":wastebasket:",
        "unicode": "1f5d1",
        "html": "&#128465;",
        "category": "Objects (office)",
        "order": 1943,
        "htmlcode": "&#128465"
    },
    {
        "index": 982,
        "emoji": "🔒",
        "name": "locked",
        "shortname": ":lock:",
        "unicode": "1f512",
        "html": "&#128274;",
        "category": "Objects (lock)",
        "order": 1944,
        "htmlcode": "&#128274"
    },
    {
        "index": 983,
        "emoji": "🔓",
        "name": "unlocked",
        "shortname": ":unlock:",
        "unicode": "1f513",
        "html": "&#128275;",
        "category": "Objects (lock)",
        "order": 1945,
        "htmlcode": "&#128275"
    },
    {
        "index": 984,
        "emoji": "🔏",
        "name": "locked with pen",
        "shortname": ":lock_with_ink_pen:",
        "unicode": "1f50f",
        "html": "&#128271;",
        "category": "Objects (lock)",
        "order": 1946,
        "htmlcode": "&#128271"
    },
    {
        "index": 985,
        "emoji": "🔐",
        "name": "locked with key",
        "shortname": ":closed_lock_with_key:",
        "unicode": "1f510",
        "html": "&#128272;",
        "category": "Objects (lock)",
        "order": 1947,
        "htmlcode": "&#128272"
    },
    {
        "index": 986,
        "emoji": "🔑",
        "name": "key",
        "shortname": ":key:",
        "unicode": "1f511",
        "html": "&#128273;",
        "category": "Objects (lock)",
        "order": 1948,
        "htmlcode": "&#128273"
    },
    {
        "index": 987,
        "emoji": "🗝",
        "name": "old key",
        "shortname": ":old_key:",
        "unicode": "1F5DD",
        "html": "&#128477;",
        "category": "Objects (lock)",
        "order": 63.5,
        "htmlcode": "&#128477"
    },
    {
        "index": 988,
        "emoji": "🔨",
        "name": "hammer",
        "shortname": ":hammer:",
        "unicode": "1f528",
        "html": "&#128296;",
        "category": "Objects (tool)",
        "order": 1950,
        "htmlcode": "&#128296"
    },
    {
        "index": 989,
        "emoji": "🪓",
        "name": "axe",
        "shortname": ":axe:",
        "unicode": "1FA93",
        "html": "&#x1FA93;",
        "category": "Objects (tool)",
        "order": 63.5,
        "htmlcode": "&#129683"
    },
    {
        "index": 990,
        "emoji": "🛠",
        "name": "hammer and wrench",
        "shortname": ":hammer_and_wrench:",
        "unicode": "1F6E0",
        "html": "&#128736;",
        "category": "Objects (tool)",
        "order": 63.5,
        "htmlcode": "&#128736"
    },
    {
        "index": 991,
        "emoji": "🗡",
        "name": "dagger",
        "shortname": ":dagger:",
        "unicode": "1F5E1",
        "html": "&#128481;",
        "category": "Objects (tool)",
        "order": 63.5,
        "htmlcode": "&#128481"
    },
    {
        "index": 992,
        "emoji": "🔫",
        "name": "pistol",
        "shortname": ":gun:",
        "unicode": "1f52b",
        "html": "&#128299;",
        "category": "Objects (tool)",
        "order": 1956,
        "htmlcode": "&#128299"
    },
    // {
    //     "index": 993,
    //     "htmlcode": "&#129667"
    // },
    {
        "index": 994,
        "emoji": "🏹",
        "name": "bow and arrow",
        "shortname": ":bow_and_arrow:",
        "unicode": "1f3f9",
        "html": "&#127993;",
        "category": "Objects (tool)",
        "order": 1957,
        "htmlcode": "&#127993"
    },
    {
        "index": 995,
        "emoji": "🛡",
        "name": "shield",
        "shortname": ":shield:",
        "unicode": "1f6e1",
        "html": "&#128737;",
        "category": "Objects (tool)",
        "order": 1958,
        "htmlcode": "&#128737"
    },
    // {
    //     "index": 996,
    //     "htmlcode": "&#129690"
    // },
    // {
    //     "index": 997,
    //     "htmlcode": "&#129691"
    // },
    {
        "index": 998,
        "emoji": "🔩",
        "name": "nut and bolt",
        "shortname": ":nut_and_bolt:",
        "unicode": "1f529",
        "html": "&#128297;",
        "category": "Objects (tool)",
        "order": 1960,
        "htmlcode": "&#128297"
    },
    {
        "index": 999,
        "emoji": "🗜",
        "name": "clamp",
        "shortname": ":compression:",
        "unicode": "1f5dc",
        "html": "&#128476;",
        "category": "Objects (tool)",
        "order": 1962,
        "htmlcode": "&#128476"
    },
    {
        "index": 1000,
        "emoji": "🔗",
        "name": "link",
        "shortname": ":link:",
        "unicode": "1f517",
        "html": "&#128279;",
        "category": "Objects (tool)",
        "order": 1965,
        "htmlcode": "&#128279"
    },
    // {
    //     "index": 1001,
    //     "htmlcode": "&#129693"
    // },
    {
        "index": 1002,
        "emoji": "🧰",
        "name": "toolbox",
        "shortname": ":toolbox:",
        "unicode": "1F9F0",
        "html": "&#x1F9F0;",
        "category": "Objects (tool)",
        "order": 63.5,
        "htmlcode": "&#129520"
    },
    {
        "index": 1003,
        "emoji": "🧲",
        "name": "magnet",
        "shortname": ":magnet:",
        "unicode": "1F9F2",
        "html": "&#x1F9F2;",
        "category": "Objects (tool)",
        "order": 63.5,
        "htmlcode": "&#129522"
    },
    // {
    //     "index": 1004,
    //     "htmlcode": "&#129692"
    // },
    {
        "index": 1005,
        "emoji": "🧪",
        "name": "test tube",
        "shortname": ":test_tube:",
        "unicode": "1F9EA",
        "html": "&#x1F9EA;",
        "category": "Objects (science)",
        "order": 63.5,
        "htmlcode": "&#129514"
    },
    {
        "index": 1006,
        "emoji": "🧫",
        "name": "petri dish",
        "shortname": ":petri_dish:",
        "unicode": "1F9EB",
        "html": "&#x1F9EB;",
        "category": "Objects (science)",
        "order": 63.5,
        "htmlcode": "&#129515"
    },
    {
        "index": 1007,
        "emoji": "🧬",
        "name": "dna",
        "shortname": ":dna:",
        "unicode": "1F9EC",
        "html": "&#x1F9EC;",
        "category": "Objects (science)",
        "order": 63.5,
        "htmlcode": "&#129516"
    },
    {
        "index": 1008,
        "emoji": "🔭",
        "name": "telescope",
        "shortname": ":telescope:",
        "unicode": "1f52d",
        "html": "&#128301;",
        "category": "Objects (science)",
        "order": 1868,
        "htmlcode": "&#128301"
    },
    {
        "index": 1009,
        "emoji": "📡",
        "name": "satellite antenna",
        "shortname": ":satellite:",
        "unicode": "1f4e1",
        "html": "&#128225;",
        "category": "Objects (science)",
        "order": 1869,
        "htmlcode": "&#128225"
    },
    {
        "index": 1010,
        "emoji": "💉",
        "name": "syringe",
        "shortname": ":syringe:",
        "unicode": "1f489",
        "html": "&#128137;",
        "category": "Objects (medical)",
        "order": 1967,
        "htmlcode": "&#128137"
    },
    {
        "index": 1011,
        "emoji": "🩸",
        "name": "drop of blood",
        "shortname": ":drop_of_blood:",
        "unicode": "1FA78",
        "html": "&#x1FA78;",
        "category": "Objects (medical)",
        "order": 63.5,
        "htmlcode": "&#129656"
    },
    {
        "index": 1012,
        "emoji": "💊",
        "name": "pill",
        "shortname": ":pill:",
        "unicode": "1f48a",
        "html": "&#128138;",
        "category": "Objects (medical)",
        "order": 1968,
        "htmlcode": "&#128138"
    },
    {
        "index": 1013,
        "emoji": "🩺",
        "name": "stethoscope",
        "shortname": ":stethoscope:",
        "unicode": "1FA7A",
        "html": "&#x1FA7A;",
        "category": "Objects (medical)",
        "order": 63.5,
        "htmlcode": "&#129658"
    },
    {
        "index": 1014,
        "emoji": "🚪",
        "name": "door",
        "shortname": ":door:",
        "unicode": "1f6aa",
        "html": "&#128682;",
        "category": "Objects (household)",
        "order": 1662,
        "htmlcode": "&#128682"
    },
    // {
    //     "index": 1015,
    //     "htmlcode": "&#128727"
    // },
    // {
    //     "index": 1016,
    //     "htmlcode": "&#129694"
    // },
    // {
    //     "index": 1017,
    //     "htmlcode": "&#129695"
    // },
    {
        "index": 1018,
        "emoji": "🛏",
        "name": "bed",
        "shortname": ":bed:",
        "unicode": "1f6cf",
        "html": "&#128719;",
        "category": "Objects (household)",
        "order": 1669,
        "htmlcode": "&#128719"
    },
    {
        "index": 1019,
        "emoji": "🛋",
        "name": "couch and lamp",
        "shortname": ":couch_and_lamp:",
        "unicode": "1F6CB",
        "html": "&#128715;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#128715"
    },
    {
        "index": 1020,
        "emoji": "🪑",
        "name": "chair",
        "shortname": ":chair:",
        "unicode": "1FA91",
        "html": "&#x1FA91;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129681"
    },
    {
        "index": 1021,
        "emoji": "🚽",
        "name": "toilet",
        "shortname": ":toilet:",
        "unicode": "1f6bd",
        "html": "&#128701;",
        "category": "Objects (household)",
        "order": 1671,
        "htmlcode": "&#128701"
    },
    // {
    //     "index": 1022,
    //     "htmlcode": "&#129696"
    // },
    {
        "index": 1023,
        "emoji": "🚿",
        "name": "shower",
        "shortname": ":shower:",
        "unicode": "1f6bf",
        "html": "&#128703;",
        "category": "Objects (household)",
        "order": 1672,
        "htmlcode": "&#128703"
    },
    {
        "index": 1024,
        "emoji": "🛁",
        "name": "bathtub",
        "shortname": ":bathtub:",
        "unicode": "1f6c1",
        "html": "&#128705;",
        "category": "Objects (household)",
        "order": 1679,
        "htmlcode": "&#128705"
    },
    // {
    //     "index": 1025,
    //     "htmlcode": "&#129700"
    // },
    {
        "index": 1026,
        "emoji": "🪒",
        "name": "razor",
        "shortname": ":razor:",
        "unicode": "1FA92",
        "html": "&#x1FA92;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129682"
    },
    {
        "index": 1027,
        "emoji": "🧴",
        "name": "lotion bottle",
        "shortname": ":lotion_bottle:",
        "unicode": "1F9F4",
        "html": "&#x1F9F4;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129524"
    },
    {
        "index": 1028,
        "emoji": "🧷",
        "name": "safety pin",
        "shortname": ":safety_pin:",
        "unicode": "1F9F7",
        "html": "&#x1F9F7;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129527"
    },
    {
        "index": 1029,
        "emoji": "🧹",
        "name": "broom",
        "shortname": ":broom:",
        "unicode": "1F9F9",
        "html": "&#x1F9F9;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129529"
    },
    {
        "index": 1030,
        "emoji": "🧺",
        "name": "basket",
        "shortname": ":basket:",
        "unicode": "1F9FA",
        "html": "&#x1F9FA;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129530"
    },
    {
        "index": 1031,
        "emoji": "🧻",
        "name": "roll of paper",
        "shortname": ":roll_of_paper:",
        "unicode": "1F9FB",
        "html": "&#x1F9FB;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129531"
    },
    // {
    //     "index": 1032,
    //     "htmlcode": "&#129699"
    // },
    {
        "index": 1033,
        "emoji": "🧼",
        "name": "soap",
        "shortname": ":soap:",
        "unicode": "1F9FC",
        "html": "&#x1F9FC;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129532"
    },
    // {
    //     "index": 1034,
    //     "htmlcode": "&#129701"
    // },
    {
        "index": 1035,
        "emoji": "🧽",
        "name": "sponge",
        "shortname": ":sponge:",
        "unicode": "1F9FD",
        "html": "&#x1F9FD;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129533"
    },
    {
        "index": 1036,
        "emoji": "🧯",
        "name": "fire extinguisher",
        "shortname": ":fire_extinguisher:",
        "unicode": "1F9EF",
        "html": "&#x1F9EF;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#129519"
    },
    {
        "index": 1037,
        "emoji": "🛒",
        "name": "shopping cart",
        "shortname": ":shopping_cart:",
        "unicode": "1F6D2",
        "html": "&#x1F6D2;",
        "category": "Objects (household)",
        "order": 63.5,
        "htmlcode": "&#128722"
    },
    {
        "index": 1038,
        "emoji": "🚬",
        "name": "cigarette",
        "shortname": ":smoking:",
        "unicode": "1f6ac",
        "html": "&#128684;",
        "category": "Objects (other-object)",
        "order": 1969,
        "htmlcode": "&#128684"
    },
    // {
    //     "index": 1039,
    //     "htmlcode": "&#129702"
    // },
    {
        "index": 1040,
        "emoji": "🗿",
        "name": "moai",
        "shortname": ":moyai:",
        "unicode": "1f5ff",
        "html": "&#128511;",
        "category": "Objects (other-object)",
        "order": 1972,
        "htmlcode": "&#128511"
    },
    // {
    //     "index": 1041,
    //     "htmlcode": "&#129703"
    // },
    {
        "index": 1042,
        "emoji": "🏧",
        "name": "ATM sign",
        "shortname": ":atm:",
        "unicode": "1f3e7",
        "html": "&#127975;",
        "category": "Symbols (transport-sign)",
        "order": 1976,
        "htmlcode": "&#127975"
    },
    {
        "index": 1043,
        "emoji": "🚮",
        "name": "litter in bin sign",
        "shortname": ":put_litter_in_its_place:",
        "unicode": "1f6ae",
        "html": "&#128686;",
        "category": "Symbols (transport-sign)",
        "order": 1977,
        "htmlcode": "&#128686"
    },
    {
        "index": 1044,
        "emoji": "🚰",
        "name": "potable water",
        "shortname": ":potable_water:",
        "unicode": "1f6b0",
        "html": "&#128688;",
        "category": "Symbols (transport-sign)",
        "order": 1978,
        "htmlcode": "&#128688"
    },
    {
        "index": 1045,
        "emoji": "🚹",
        "name": "men’s room",
        "shortname": ":mens:",
        "unicode": "1f6b9",
        "html": "&#128697;",
        "category": "Symbols (transport-sign)",
        "order": 1980,
        "htmlcode": "&#128697"
    },
    {
        "index": 1046,
        "emoji": "🚺",
        "name": "women’s room",
        "shortname": ":womens:",
        "unicode": "1f6ba",
        "html": "&#128698;",
        "category": "Symbols (transport-sign)",
        "order": 1981,
        "htmlcode": "&#128698"
    },
    {
        "index": 1047,
        "emoji": "🚻",
        "name": "restroom",
        "shortname": ":restroom:",
        "unicode": "1f6bb",
        "html": "&#128699;",
        "category": "Symbols (transport-sign)",
        "order": 1982,
        "htmlcode": "&#128699"
    },
    {
        "index": 1048,
        "emoji": "🚼",
        "name": "baby symbol",
        "shortname": ":baby_symbol:",
        "unicode": "1f6bc",
        "html": "&#128700;",
        "category": "Symbols (transport-sign)",
        "order": 1983,
        "htmlcode": "&#128700"
    },
    {
        "index": 1049,
        "emoji": "🚾",
        "name": "water closet",
        "shortname": ":wc:",
        "unicode": "1f6be",
        "html": "&#128702;",
        "category": "Symbols (transport-sign)",
        "order": 1984,
        "htmlcode": "&#128702"
    },
    {
        "index": 1050,
        "emoji": "🛂",
        "name": "passport control",
        "shortname": ":passport_control:",
        "unicode": "1f6c2",
        "html": "&#128706;",
        "category": "Symbols (transport-sign)",
        "order": 1985,
        "htmlcode": "&#128706"
    },
    {
        "index": 1051,
        "emoji": "🛃",
        "name": "customs",
        "shortname": ":customs:",
        "unicode": "1f6c3",
        "html": "&#128707;",
        "category": "Symbols (transport-sign)",
        "order": 1986,
        "htmlcode": "&#128707"
    },
    {
        "index": 1052,
        "emoji": "🛄",
        "name": "baggage claim",
        "shortname": ":baggage_claim:",
        "unicode": "1f6c4",
        "html": "&#128708;",
        "category": "Symbols (transport-sign)",
        "order": 1987,
        "htmlcode": "&#128708"
    },
    {
        "index": 1053,
        "emoji": "🛅",
        "name": "left luggage",
        "shortname": ":left_luggage:",
        "unicode": "1f6c5",
        "html": "&#128709;",
        "category": "Symbols (transport-sign)",
        "order": 1988,
        "htmlcode": "&#128709"
    },
    {
        "index": 1054,
        "emoji": "🚸",
        "name": "children crossing",
        "shortname": ":children_crossing:",
        "unicode": "1f6b8",
        "html": "&#128696;",
        "category": "Symbols (warning)",
        "order": 1990,
        "htmlcode": "&#128696"
    },
    {
        "index": 1055,
        "emoji": "🚫",
        "name": "prohibited",
        "shortname": ":no_entry_sign:",
        "unicode": "1f6ab",
        "html": "&#128683;",
        "category": "Symbols (warning)",
        "order": 1992,
        "htmlcode": "&#128683"
    },
    {
        "index": 1056,
        "emoji": "🚳",
        "name": "no bicycles",
        "shortname": ":no_bicycles:",
        "unicode": "1f6b3",
        "html": "&#128691;",
        "category": "Symbols (warning)",
        "order": 1993,
        "htmlcode": "&#128691"
    },
    {
        "index": 1057,
        "emoji": "🚭",
        "name": "no smoking",
        "shortname": ":no_smoking:",
        "unicode": "1f6ad",
        "html": "&#128685;",
        "category": "Symbols (warning)",
        "order": 1994,
        "htmlcode": "&#128685"
    },
    {
        "index": 1058,
        "emoji": "🚯",
        "name": "no littering",
        "shortname": ":do_not_litter:",
        "unicode": "1f6af",
        "html": "&#128687;",
        "category": "Symbols (warning)",
        "order": 1995,
        "htmlcode": "&#128687"
    },
    {
        "index": 1059,
        "emoji": "🚱",
        "name": "non-potable water",
        "shortname": ":non-potable_water:",
        "unicode": "1f6b1",
        "html": "&#128689;",
        "category": "Symbols (warning)",
        "order": 1996,
        "htmlcode": "&#128689"
    },
    {
        "index": 1060,
        "emoji": "🚷",
        "name": "no pedestrians",
        "shortname": ":no_pedestrians:",
        "unicode": "1f6b7",
        "html": "&#128695;",
        "category": "Symbols (warning)",
        "order": 1997,
        "htmlcode": "&#128695"
    },
    {
        "index": 1061,
        "emoji": "📵",
        "name": "no mobile phones",
        "shortname": ":no_mobile_phones:",
        "unicode": "1f4f5",
        "html": "&#128245;",
        "category": "Symbols (warning)",
        "order": 1998,
        "htmlcode": "&#128245"
    },
    {
        "index": 1062,
        "emoji": "🔞",
        "name": "no one under eighteen",
        "shortname": ":underage:",
        "unicode": "1f51e",
        "html": "&#128286;",
        "category": "Symbols (warning)",
        "order": 1999,
        "htmlcode": "&#128286"
    },
    {
        "index": 1063,
        "emoji": "🔃",
        "name": "clockwise vertical arrows",
        "shortname": ":arrows_clockwise:",
        "unicode": "1f503",
        "html": "&#128259;",
        "category": "Symbols (arrow)",
        "order": 2016,
        "htmlcode": "&#128259"
    },
    {
        "index": 1064,
        "emoji": "🔄",
        "name": "counterclockwise arrows button",
        "shortname": ":arrows_counterclockwise:",
        "unicode": "1f504",
        "html": "&#128260;",
        "category": "Symbols (arrow)",
        "order": 2017,
        "htmlcode": "&#128260"
    },
    {
        "index": 1065,
        "emoji": "🔙",
        "name": "BACK arrow",
        "shortname": ":back:",
        "unicode": "1f519",
        "html": "&#128281;",
        "category": "Symbols (arrow)",
        "order": 2018,
        "htmlcode": "&#128281"
    },
    {
        "index": 1066,
        "emoji": "🔚",
        "name": "END arrow",
        "shortname": ":end:",
        "unicode": "1f51a",
        "html": "&#128282;",
        "category": "Symbols (arrow)",
        "order": 2019,
        "htmlcode": "&#128282"
    },
    {
        "index": 1067,
        "emoji": "🔛",
        "name": "ON! arrow",
        "shortname": ":on:",
        "unicode": "1f51b",
        "html": "&#128283;",
        "category": "Symbols (arrow)",
        "order": 2020,
        "htmlcode": "&#128283"
    },
    {
        "index": 1068,
        "emoji": "🔜",
        "name": "SOON arrow",
        "shortname": ":soon:",
        "unicode": "1f51c",
        "html": "&#128284;",
        "category": "Symbols (arrow)",
        "order": 2021,
        "htmlcode": "&#128284"
    },
    {
        "index": 1069,
        "emoji": "🔝",
        "name": "TOP arrow",
        "shortname": ":top:",
        "unicode": "1f51d",
        "html": "&#128285;",
        "category": "Symbols (arrow)",
        "order": 2022,
        "htmlcode": "&#128285"
    },
    {
        "index": 1070,
        "emoji": "🛐",
        "name": "place of worship",
        "shortname": ":place_of_worship:",
        "unicode": "1f6d0",
        "html": "&#128720;",
        "category": "Symbols (religion)",
        "order": 2023,
        "htmlcode": "&#128720"
    },
    {
        "index": 1071,
        "emoji": "🕉",
        "name": "om",
        "shortname": ":om_symbol:",
        "unicode": "1f549",
        "html": "&#128329;",
        "category": "Symbols (religion)",
        "order": 2025,
        "htmlcode": "&#128329"
    },
    {
        "index": 1072,
        "emoji": "🕎",
        "name": "menorah",
        "shortname": ":menorah:",
        "unicode": "1f54e",
        "html": "&#128334;",
        "category": "Symbols (religion)",
        "order": 2033,
        "htmlcode": "&#128334"
    },
    {
        "index": 1073,
        "emoji": "🔯",
        "name": "dotted six-pointed star",
        "shortname": ":six_pointed_star:",
        "unicode": "1f52f",
        "html": "&#128303;",
        "category": "Symbols (religion)",
        "order": 2034,
        "htmlcode": "&#128303"
    },
    {
        "index": 1074,
        "emoji": "🔀",
        "name": "shuffle tracks button",
        "shortname": ":twisted_rightwards_arrows:",
        "unicode": "1f500",
        "html": "&#128256;",
        "category": "Symbols (av-symbol)",
        "order": 2048,
        "htmlcode": "&#128256"
    },
    {
        "index": 1075,
        "emoji": "🔁",
        "name": "repeat button",
        "shortname": ":repeat:",
        "unicode": "1f501",
        "html": "&#128257;",
        "category": "Symbols (av-symbol)",
        "order": 2049,
        "htmlcode": "&#128257"
    },
    {
        "index": 1076,
        "emoji": "🔂",
        "name": "repeat single button",
        "shortname": ":repeat_one:",
        "unicode": "1f502",
        "html": "&#128258;",
        "category": "Symbols (av-symbol)",
        "order": 2050,
        "htmlcode": "&#128258"
    },
    {
        "index": 1077,
        "emoji": "🔼",
        "name": "upwards button",
        "shortname": ":arrow_up_small:",
        "unicode": "1f53c",
        "html": "&#128316;",
        "category": "Symbols (av-symbol)",
        "order": 2058,
        "htmlcode": "&#128316"
    },
    {
        "index": 1078,
        "emoji": "🔽",
        "name": "downwards button",
        "shortname": ":arrow_down_small:",
        "unicode": "1f53d",
        "html": "&#128317;",
        "category": "Symbols (av-symbol)",
        "order": 2060,
        "htmlcode": "&#128317"
    },
    {
        "index": 1079,
        "emoji": "🎦",
        "name": "cinema",
        "shortname": ":cinema:",
        "unicode": "1f3a6",
        "html": "&#127910;",
        "category": "Symbols (av-symbol)",
        "order": 2066,
        "htmlcode": "&#127910"
    },
    {
        "index": 1080,
        "emoji": "🔅",
        "name": "dim button",
        "shortname": ":low_brightness:",
        "unicode": "1f505",
        "html": "&#128261;",
        "category": "Symbols (av-symbol)",
        "order": 2067,
        "htmlcode": "&#128261"
    },
    {
        "index": 1081,
        "emoji": "🔆",
        "name": "bright button",
        "shortname": ":high_brightness:",
        "unicode": "1f506",
        "html": "&#128262;",
        "category": "Symbols (av-symbol)",
        "order": 2068,
        "htmlcode": "&#128262"
    },
    {
        "index": 1082,
        "emoji": "📶",
        "name": "antenna bars",
        "shortname": ":signal_strength:",
        "unicode": "1f4f6",
        "html": "&#128246;",
        "category": "Symbols (av-symbol)",
        "order": 2069,
        "htmlcode": "&#128246"
    },
    {
        "index": 1083,
        "emoji": "📳",
        "name": "vibration mode",
        "shortname": ":vibration_mode:",
        "unicode": "1f4f3",
        "html": "&#128243;",
        "category": "Symbols (av-symbol)",
        "order": 2070,
        "htmlcode": "&#128243"
    },
    {
        "index": 1084,
        "emoji": "📴",
        "name": "mobile phone off",
        "shortname": ":mobile_phone_off:",
        "unicode": "1f4f4",
        "html": "&#128244;",
        "category": "Symbols (av-symbol)",
        "order": 2071,
        "htmlcode": "&#128244"
    },
    {
        "index": 1085,
        "emoji": "💱",
        "name": "currency exchange",
        "shortname": ":currency_exchange:",
        "unicode": "1f4b1",
        "html": "&#128177;",
        "category": "Objects (money)",
        "order": 1899,
        "htmlcode": "&#128177"
    },
    {
        "index": 1086,
        "emoji": "💲",
        "name": "heavy dollar sign",
        "shortname": ":heavy_dollar_sign:",
        "unicode": "1f4b2",
        "html": "&#128178;",
        "category": "Objects (money)",
        "order": 1900,
        "htmlcode": "&#128178"
    },
    {
        "index": 1087,
        "emoji": "🔱",
        "name": "trident emblem",
        "shortname": ":trident:",
        "unicode": "1f531",
        "html": "&#128305;",
        "category": "Symbols (other-symbol)",
        "order": 2076,
        "htmlcode": "&#128305"
    },
    {
        "index": 1088,
        "emoji": "📛",
        "name": "name badge",
        "shortname": ":name_badge:",
        "unicode": "1f4db",
        "html": "&#128219;",
        "category": "Symbols (other-symbol)",
        "order": 2073,
        "htmlcode": "&#128219"
    },
    {
        "index": 1089,
        "emoji": "🔰",
        "name": "Japanese symbol for beginner",
        "shortname": ":beginner:",
        "unicode": "1f530",
        "html": "&#128304;",
        "category": "Symbols (other-symbol)",
        "order": 2075,
        "htmlcode": "&#128304"
    },
    {
        "index": 1090,
        "emoji": "🔟",
        "name": "keycap: 10",
        "shortname": ":keycap_ten:",
        "unicode": "1f51f",
        "html": "&#128287;",
        "category": "Symbols (keycap)",
        "order": 2118,
        "htmlcode": "&#128287"
    },
    {
        "index": 1091,
        "emoji": "🔠",
        "name": "input latin uppercase",
        "shortname": ":capital_abcd:",
        "unicode": "1f520",
        "html": "&#128288;",
        "category": "Symbols (alphanum)",
        "order": 2120,
        "htmlcode": "&#128288"
    },
    {
        "index": 1092,
        "emoji": "🔡",
        "name": "input latin lowercase",
        "shortname": ":abcd:",
        "unicode": "1f521",
        "html": "&#128289;",
        "category": "Symbols (alphanum)",
        "order": 2121,
        "htmlcode": "&#128289"
    },
    {
        "index": 1093,
        "emoji": "🔢",
        "name": "input numbers",
        "shortname": ":input_numbers:",
        "unicode": "1F522",
        "html": "&#128290;",
        "category": "Symbols (alphanum)",
        "order": 63.5,
        "htmlcode": "&#128290"
    },
    {
        "index": 1094,
        "emoji": "🔣",
        "name": "input symbols",
        "shortname": ":symbols:",
        "unicode": "1f523",
        "html": "&#128291;",
        "category": "Symbols (alphanum)",
        "order": 2123,
        "htmlcode": "&#128291"
    },
    {
        "index": 1095,
        "emoji": "🔤",
        "name": "input latin letters",
        "shortname": ":abc:",
        "unicode": "1f524",
        "html": "&#128292;",
        "category": "Symbols (alphanum)",
        "order": 2124,
        "htmlcode": "&#128292"
    },
    {
        "index": 1096,
        "emoji": "🅰️",
        "name": "A button (blood type)",
        "shortname": ":a:",
        "unicode": "1f170",
        "html": "&#127344;",
        "category": "Symbols (alphanum)",
        "order": 2125,
        "htmlcode": "&#127344"
    },
    {
        "index": 1097,
        "emoji": "🆎",
        "name": "AB button (blood type)",
        "shortname": ":ab:",
        "unicode": "1f18e",
        "html": "&#127374;",
        "category": "Symbols (alphanum)",
        "order": 2126,
        "htmlcode": "&#127374"
    },
    {
        "index": 1098,
        "emoji": "🅱️",
        "name": "B button (blood type)",
        "shortname": ":b:",
        "unicode": "1f171",
        "html": "&#127345;",
        "category": "Symbols (alphanum)",
        "order": 2127,
        "htmlcode": "&#127345"
    },
    {
        "index": 1099,
        "emoji": "🆑",
        "name": "CL button",
        "shortname": ":cl:",
        "unicode": "1f191",
        "html": "&#127377;",
        "category": "Symbols (alphanum)",
        "order": 2128,
        "htmlcode": "&#127377"
    },
    {
        "index": 1100,
        "emoji": "🆒",
        "name": "COOL button",
        "shortname": ":cool:",
        "unicode": "1f192",
        "html": "&#127378;",
        "category": "Symbols (alphanum)",
        "order": 2129,
        "htmlcode": "&#127378"
    },
    {
        "index": 1101,
        "emoji": "🆓",
        "name": "FREE button",
        "shortname": ":free:",
        "unicode": "1f193",
        "html": "&#127379;",
        "category": "Symbols (alphanum)",
        "order": 2130,
        "htmlcode": "&#127379"
    },
    {
        "index": 1102,
        "emoji": "🆔",
        "name": "ID button",
        "shortname": ":id:",
        "unicode": "1f194",
        "html": "&#127380;",
        "category": "Symbols (alphanum)",
        "order": 2132,
        "htmlcode": "&#127380"
    },
    {
        "index": 1103,
        "emoji": "🆕",
        "name": "NEW button",
        "shortname": ":new:",
        "unicode": "1f195",
        "html": "&#127381;",
        "category": "Symbols (alphanum)",
        "order": 2134,
        "htmlcode": "&#127381"
    },
    {
        "index": 1104,
        "emoji": "🆖",
        "name": "NG button",
        "shortname": ":ng:",
        "unicode": "1f196",
        "html": "&#127382;",
        "category": "Symbols (alphanum)",
        "order": 2135,
        "htmlcode": "&#127382"
    },
    {
        "index": 1105,
        "emoji": "🅾️",
        "name": "O button (blood type)",
        "shortname": ":o2:",
        "unicode": "1f17e",
        "html": "&#127358;",
        "category": "Symbols (alphanum)",
        "order": 2136,
        "htmlcode": "&#127358"
    },
    {
        "index": 1106,
        "emoji": "🆗",
        "name": "OK button",
        "shortname": ":ok:",
        "unicode": "1f197",
        "html": "&#127383;",
        "category": "Symbols (alphanum)",
        "order": 2137,
        "htmlcode": "&#127383"
    },
    {
        "index": 1107,
        "emoji": "🅿️",
        "name": "P button",
        "shortname": ":parking:",
        "unicode": "1f17f",
        "html": "&#127359;",
        "category": "Symbols (alphanum)",
        "order": 2138,
        "htmlcode": "&#127359"
    },
    {
        "index": 1108,
        "emoji": "🆘",
        "name": "SOS button",
        "shortname": ":sos:",
        "unicode": "1f198",
        "html": "&#127384;",
        "category": "Symbols (alphanum)",
        "order": 2139,
        "htmlcode": "&#127384"
    },
    {
        "index": 1109,
        "emoji": "🆙",
        "name": "UP! button",
        "shortname": ":up:",
        "unicode": "1f199",
        "html": "&#127385;",
        "category": "Symbols (alphanum)",
        "order": 2140,
        "htmlcode": "&#127385"
    },
    {
        "index": 1110,
        "emoji": "🆚",
        "name": "VS button",
        "shortname": ":vs:",
        "unicode": "1f19a",
        "html": "&#127386;",
        "category": "Symbols (alphanum)",
        "order": 2141,
        "htmlcode": "&#127386"
    },
    {
        "index": 1111,
        "emoji": "🈁",
        "name": "Japanese here button",
        "shortname": ":koko:",
        "unicode": "1f201",
        "html": "&#127489;",
        "category": "Symbols (alphanum)",
        "order": 2142,
        "htmlcode": "&#127489"
    },
    {
        "index": 1112,
        "emoji": "🈂️",
        "name": "Japanese service charge button",
        "shortname": ":sa:",
        "unicode": "1f202",
        "html": "&#127490;",
        "category": "Symbols (alphanum)",
        "order": 2143,
        "htmlcode": "&#127490"
    },
    {
        "index": 1113,
        "emoji": "🈷",
        "name": "Japanese monthly amount button",
        "shortname": ":u6708:",
        "unicode": "1f237",
        "html": "&#127543;",
        "category": "Symbols (alphanum)",
        "order": 2144,
        "htmlcode": "&#127543"
    },
    {
        "index": 1114,
        "emoji": "🈶",
        "name": "Japanese not free of charge button",
        "shortname": ":u6709:",
        "unicode": "1f236",
        "html": "&#127542;",
        "category": "Symbols (alphanum)",
        "order": 2145,
        "htmlcode": "&#127542"
    },
    {
        "index": 1115,
        "emoji": "🈯️",
        "name": "",
        "shortname": ":u6307:",
        "unicode": "1f22f",
        "html": "&#127535;",
        "category": "",
        "order": 2146,
        "htmlcode": "&#127535"
    },
    {
        "index": 1116,
        "emoji": "🉐",
        "name": "Japanese bargain button",
        "shortname": ":ideograph_advantage:",
        "unicode": "1f250",
        "html": "&#127568;",
        "category": "Symbols (alphanum)",
        "order": 2147,
        "htmlcode": "&#127568"
    },
    {
        "index": 1117,
        "emoji": "🈹",
        "name": "Japanese discount button",
        "shortname": ":u5272:",
        "unicode": "1f239",
        "html": "&#127545;",
        "category": "Symbols (alphanum)",
        "order": 2148,
        "htmlcode": "&#127545"
    },
    {
        "index": 1118,
        "emoji": "🈚️",
        "name": "",
        "shortname": ":u7121:",
        "unicode": "1f21a",
        "html": "&#127514;",
        "category": "",
        "order": 2149,
        "htmlcode": "&#127514"
    },
    {
        "index": 1119,
        "emoji": "🈲",
        "name": "Japanese prohibited button",
        "shortname": ":u7981:",
        "unicode": "1f232",
        "html": "&#127538;",
        "category": "Symbols (alphanum)",
        "order": 2150,
        "htmlcode": "&#127538"
    },
    {
        "index": 1120,
        "emoji": "🉑",
        "name": "Japanese acceptable button",
        "shortname": ":accept:",
        "unicode": "1f251",
        "html": "&#127569;",
        "category": "Symbols (alphanum)",
        "order": 2151,
        "htmlcode": "&#127569"
    },
    {
        "index": 1121,
        "emoji": "🈸",
        "name": "Japanese application button",
        "shortname": ":u7533:",
        "unicode": "1f238",
        "html": "&#127544;",
        "category": "Symbols (alphanum)",
        "order": 2152,
        "htmlcode": "&#127544"
    },
    {
        "index": 1122,
        "emoji": "🈴",
        "name": "Japanese passing grade button",
        "shortname": ":u5408:",
        "unicode": "1f234",
        "html": "&#127540;",
        "category": "Symbols (alphanum)",
        "order": 2153,
        "htmlcode": "&#127540"
    },
    {
        "index": 1123,
        "emoji": "🈳",
        "name": "Japanese vacancy button",
        "shortname": ":u7a7a:",
        "unicode": "1f233",
        "html": "&#127539;",
        "category": "Symbols (alphanum)",
        "order": 2154,
        "htmlcode": "&#127539"
    },
    {
        "index": 1124,
        "emoji": "🈺",
        "name": "Japanese open for business button",
        "shortname": ":u55b6:",
        "unicode": "1f23a",
        "html": "&#127546;",
        "category": "Symbols (alphanum)",
        "order": 2157,
        "htmlcode": "&#127546"
    },
    {
        "index": 1125,
        "emoji": "🈵",
        "name": "Japanese no vacancy button",
        "shortname": ":u6e80:",
        "unicode": "1f235",
        "html": "&#127541;",
        "category": "Symbols (alphanum)",
        "order": 2158,
        "htmlcode": "&#127541"
    },
    {
        "index": 1126,
        "emoji": "🔴",
        "name": "red circle",
        "shortname": ":red_circle:",
        "unicode": "1f534",
        "html": "&#128308;",
        "category": "Symbols (geometric)",
        "order": 2179,
        "htmlcode": "&#128308"
    },
    {
        "index": 1127,
        "emoji": "🟠",
        "name": "orange circle",
        "shortname": ":orange_circle:",
        "unicode": "1F7E0",
        "html": "&#x1F7E0;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#128992"
    },
    {
        "index": 1128,
        "emoji": "🟡",
        "name": "yellow circle",
        "shortname": ":yellow_circle:",
        "unicode": "1F7E1",
        "html": "&#x1F7E1;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#128993"
    },
    {
        "index": 1129,
        "emoji": "🟢",
        "name": "green circle",
        "shortname": ":green_circle:",
        "unicode": "1F7E2",
        "html": "&#x1F7E2;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#128994"
    },
    {
        "index": 1130,
        "emoji": "🔵",
        "name": "blue circle",
        "shortname": ":blue_circle:",
        "unicode": "1f535",
        "html": "&#128309;",
        "category": "Symbols (geometric)",
        "order": 2180,
        "htmlcode": "&#128309"
    },
    {
        "index": 1131,
        "emoji": "🟣",
        "name": "purple circle",
        "shortname": ":purple_circle:",
        "unicode": "1F7E3",
        "html": "&#x1F7E3;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#128995"
    },
    {
        "index": 1132,
        "emoji": "🟤",
        "name": "brown circle",
        "shortname": ":brown_circle:",
        "unicode": "1F7E4",
        "html": "&#x1F7E4;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#128996"
    },
    {
        "index": 1133,
        "emoji": "🟥",
        "name": "red square",
        "shortname": ":red_square:",
        "unicode": "1F7E5",
        "html": "&#x1F7E5;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#128997"
    },
    {
        "index": 1134,
        "emoji": "🟧",
        "name": "orange square",
        "shortname": ":orange_square:",
        "unicode": "1F7E7",
        "html": "&#x1F7E7;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#128999"
    },
    {
        "index": 1135,
        "emoji": "🟨",
        "name": "yellow square",
        "shortname": ":yellow_square:",
        "unicode": "1F7E8",
        "html": "&#x1F7E8;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#129000"
    },
    {
        "index": 1136,
        "emoji": "🟩",
        "name": "green square",
        "shortname": ":green_square:",
        "unicode": "1F7E9",
        "html": "&#x1F7E9;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#129001"
    },
    {
        "index": 1137,
        "emoji": "🟦",
        "name": "blue square",
        "shortname": ":blue_square:",
        "unicode": "1F7E6",
        "html": "&#x1F7E6;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#128998"
    },
    {
        "index": 1138,
        "htmlcode": "&#129002"
    },
    {
        "index": 1139,
        "htmlcode": "&#129003"
    },
    {
        "index": 1140,
        "emoji": "🔶",
        "name": "large orange diamond",
        "shortname": ":large_orange_diamond:",
        "unicode": "1f536",
        "html": "&#128310;",
        "category": "Symbols (geometric)",
        "order": 2167,
        "htmlcode": "&#128310"
    },
    {
        "index": 1141,
        "emoji": "🔷",
        "name": "large blue diamond",
        "shortname": ":large_blue_diamond:",
        "unicode": "1f537",
        "html": "&#128311;",
        "category": "Symbols (geometric)",
        "order": 2168,
        "htmlcode": "&#128311"
    },
    {
        "index": 1142,
        "emoji": "🔸",
        "name": "small orange diamond",
        "shortname": ":small_orange_diamond:",
        "unicode": "1f538",
        "html": "&#128312;",
        "category": "Symbols (geometric)",
        "order": 2169,
        "htmlcode": "&#128312"
    },
    {
        "index": 1143,
        "emoji": "🔹",
        "name": "small blue diamond",
        "shortname": ":small_blue_diamond:",
        "unicode": "1f539",
        "html": "&#128313;",
        "category": "Symbols (geometric)",
        "order": 2170,
        "htmlcode": "&#128313"
    },
    {
        "index": 1144,
        "emoji": "🔺",
        "name": "red triangle pointed up",
        "shortname": ":small_red_triangle:",
        "unicode": "1f53a",
        "html": "&#128314;",
        "category": "Symbols (geometric)",
        "order": 2171,
        "htmlcode": "&#128314"
    },
    {
        "index": 1145,
        "emoji": "🔻",
        "name": "red triangle pointed down",
        "shortname": ":small_red_triangle_down:",
        "unicode": "1f53b",
        "html": "&#128315;",
        "category": "Symbols (geometric)",
        "order": 2172,
        "htmlcode": "&#128315"
    },
    {
        "index": 1146,
        "emoji": "💠",
        "name": "diamond with a dot",
        "shortname": ":diamond_shape_with_a_dot_inside:",
        "unicode": "1f4a0",
        "html": "&#128160;",
        "category": "Symbols (geometric)",
        "order": 2173,
        "htmlcode": "&#128160"
    },
    {
        "index": 1147,
        "emoji": "🔘",
        "name": "radio button",
        "shortname": ":radio_button:",
        "unicode": "1f518",
        "html": "&#128280;",
        "category": "Symbols (geometric)",
        "order": 2174,
        "htmlcode": "&#128280"
    },
    {
        "index": 1148,
        "emoji": "🔳",
        "name": "white square button",
        "shortname": ":white_square_button:",
        "unicode": "1f533",
        "html": "&#128307;",
        "category": "Symbols (geometric)",
        "order": 2176,
        "htmlcode": "&#128307"
    },
    {
        "index": 1149,
        "emoji": "🔲",
        "name": "black square button",
        "shortname": ":black_square_button:",
        "unicode": "1f532",
        "html": "&#128306;",
        "category": "Symbols (geometric)",
        "order": 2175,
        "htmlcode": "&#128306"
    },
    {
        "index": 1150,
        "emoji": "🏁",
        "name": "chequered flag",
        "shortname": ":checkered_flag:",
        "unicode": "1f3c1",
        "html": "&#127937;",
        "category": "Flags (flag)",
        "order": 2181,
        "htmlcode": "&#127937"
    },
    {
        "index": 1151,
        "emoji": "🚩",
        "name": "triangular flag",
        "shortname": ":triangular_flag_on_post:",
        "unicode": "1f6a9",
        "html": "&#128681;",
        "category": "Flags (flag)",
        "order": 2182,
        "htmlcode": "&#128681"
    },
    {
        "index": 1152,
        "emoji": "🎌",
        "name": "crossed flags",
        "shortname": ":crossed_flags:",
        "unicode": "1f38c",
        "html": "&#127884;",
        "category": "Flags (flag)",
        "order": 2183,
        "htmlcode": "&#127884"
    },
    {
        "index": 1153,
        "emoji": "🏴",
        "name": "black flag",
        "shortname": ":flag_black:",
        "unicode": "1f3f4",
        "html": "&#127988;",
        "category": "Flags (flag)",
        "order": 2184,
        "htmlcode": "&#127988"
    },
    {
        "index": 1154,
        "emoji": "🏳",
        "name": "white flag",
        "shortname": ":flag_white:",
        "unicode": "1f3f3",
        "html": "&#127987;",
        "category": "Flags (flag)",
        "order": 2185,
        "htmlcode": "&#127987"
    },
    {
        "index": 1155,
        "emoji": "❤️",
        "name": "red heart",
        "shortname": ":heart:",
        "unicode": "2764",
        "html": "&#10084;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1286,
        "htmlcode": "&#10084"
    },
    {
        "index": 1156,
        "emoji": "♻",
        "name": "recycling symbol",
        "shortname": ":recycle:",
        "unicode": "267b",
        "html": "&#9851;",
        "category": "Symbols (other-symbol)",
        "order": 2072,
        "htmlcode": "&#9851"
    },
    {
        "index": 1157,
        "emoji": "✌️",
        "name": "victory hand",
        "shortname": ":v:",
        "unicode": "270c",
        "html": "&#9996;",
        "category": "People & Body (hand-fingers-partial)",
        "order": 1128,
        "htmlcode": "&#9996"
    },
    {
        "index": 1158,
        "emoji": "✨",
        "name": "sparkles",
        "shortname": ":sparkles:",
        "unicode": "2728",
        "html": "&#10024;",
        "category": "Activities (event)",
        "order": 1760,
        "htmlcode": "&#10024"
    },
    {
        "index": 1159,
        "emoji": "⬅️",
        "name": "left arrow",
        "shortname": ":arrow_left:",
        "unicode": "2b05",
        "html": "&#11013;",
        "category": "Symbols (arrow)",
        "order": 2008,
        "htmlcode": "&#11013"
    },
    {
        "index": 1160,
        "emoji": "✋",
        "name": "raised hand",
        "shortname": ":raised_hand:",
        "unicode": "270B",
        "html": "&#9995;",
        "category": "People & Body (hand-fingers-open)",
        "order": 63.5,
        "htmlcode": "&#9995"
    },
    {
        "index": 1161,
        "emoji": "☀️",
        "name": "sun",
        "shortname": ":sunny:",
        "unicode": "2600",
        "html": "&#9728;",
        "category": "Travel & Places (sky & weather)",
        "order": 1724,
        "htmlcode": "&#9728"
    },
    {
        "index": 1162,
        "emoji": "✔️",
        "name": "check mark",
        "shortname": ":heavy_check_mark:",
        "unicode": "2714",
        "html": "&#10004;",
        "category": "Symbols (other-symbol)",
        "order": 2080,
        "htmlcode": "&#10004"
    },
    {
        "index": 1163,
        "emoji": "▶️",
        "name": "play button",
        "shortname": ":arrow_forward:",
        "unicode": "25b6",
        "html": "&#9654;",
        "category": "Symbols (av-symbol)",
        "order": 2051,
        "htmlcode": "&#9654"
    },
    {
        "index": 1164,
        "emoji": "◀️",
        "name": "reverse button",
        "shortname": ":arrow_backward:",
        "unicode": "25c0",
        "html": "&#9664;",
        "category": "Symbols (av-symbol)",
        "order": 2055,
        "htmlcode": "&#9664"
    },
    {
        "index": 1165,
        "emoji": "↪️",
        "name": "left arrow curving right",
        "shortname": ":arrow_right_hook:",
        "unicode": "21aa",
        "html": "&#8618;",
        "category": "Symbols (arrow)",
        "order": 2013,
        "htmlcode": "&#8618"
    },
    {
        "index": 1166,
        "emoji": "↩️",
        "name": "right arrow curving left",
        "shortname": ":leftwards_arrow_with_hook:",
        "unicode": "21a9",
        "html": "&#8617;",
        "category": "Symbols (arrow)",
        "order": 2012,
        "htmlcode": "&#8617"
    },
    {
        "index": 1167,
        "emoji": "✅",
        "name": "check mark button",
        "shortname": ":white_check_mark:",
        "unicode": "2705",
        "html": "&#9989;",
        "category": "Symbols (other-symbol)",
        "order": 2078,
        "htmlcode": "&#9989"
    },
    {
        "index": 1168,
        "emoji": "➡",
        "name": "right arrow",
        "shortname": ":arrow_right:",
        "unicode": "27a1",
        "html": "&#10145;",
        "category": "Symbols (arrow)",
        "order": 2004,
        "htmlcode": "&#10145"
    },
    {
        "index": 1169,
        "emoji": "✊",
        "name": "raised fist",
        "shortname": ":fist:",
        "unicode": "270a",
        "html": "&#9994;",
        "category": "People & Body (hand-fingers-closed)",
        "order": 1188,
        "htmlcode": "&#9994"
    },
    {
        "index": 1170,
        "emoji": "✈️",
        "name": "airplane",
        "shortname": ":airplane:",
        "unicode": "2708",
        "html": "&#9992;",
        "category": "Travel & Places (transport-air)",
        "order": 1650,
        "htmlcode": "&#9992"
    },
    {
        "index": 1171,
        "emoji": "⭐",
        "name": "star",
        "shortname": ":star:",
        "unicode": "2b50",
        "html": "&#11088;",
        "category": "Travel & Places (sky & weather)",
        "order": 1727,
        "htmlcode": "&#11088"
    },
    {
        "index": 1172,
        "emoji": "☑️",
        "name": "check box with check",
        "shortname": ":ballot_box_with_check:",
        "unicode": "2611",
        "html": "&#9745;",
        "category": "Symbols (other-symbol)",
        "order": 2079,
        "htmlcode": "&#9745"
    },
    {
        "index": 1173,
        "emoji": "‼️",
        "name": "double exclamation mark",
        "shortname": ":bangbang:",
        "unicode": "203c",
        "html": "&#8252;",
        "category": "Symbols (other-symbol)",
        "order": 2096,
        "htmlcode": "&#8252"
    },
    {
        "index": 1174,
        "emoji": "❌",
        "name": "cross mark",
        "shortname": ":x:",
        "unicode": "274c",
        "html": "&#10060;",
        "category": "Symbols (other-symbol)",
        "order": 2082,
        "htmlcode": "&#10060"
    },
    {
        "index": 1175,
        "emoji": "☁",
        "name": "cloud",
        "shortname": ":cloud:",
        "unicode": "2601",
        "html": "&#9729;",
        "category": "Travel & Places (sky & weather)",
        "order": 1730,
        "htmlcode": "&#9729"
    },
    {
        "index": 1176,
        "emoji": "⚽",
        "name": "soccer ball",
        "shortname": ":soccer:",
        "unicode": "26bd",
        "html": "&#9917;",
        "category": "Activities (sport)",
        "order": 1781,
        "htmlcode": "&#9917"
    },
    {
        "index": 1177,
        "emoji": "❗",
        "name": "exclamation mark",
        "shortname": ":exclamation:",
        "unicode": "2757",
        "html": "&#10071;",
        "category": "Symbols (other-symbol)",
        "order": 2101,
        "htmlcode": "&#10071"
    },
    {
        "index": 1178,
        "emoji": "❄️",
        "name": "snowflake",
        "shortname": ":snowflake:",
        "unicode": "2744",
        "html": "&#10052;",
        "category": "Travel & Places (sky & weather)",
        "order": 1749,
        "htmlcode": "&#10052"
    },
    {
        "index": 1179,
        "emoji": "☝️",
        "name": "index pointing up",
        "shortname": ":point_up:",
        "unicode": "261d",
        "html": "&#9757;",
        "category": "People & Body (hand-single-finger)",
        "order": 1104,
        "htmlcode": "&#9757"
    },
    {
        "index": 1180,
        "emoji": "⚓",
        "name": "anchor",
        "shortname": ":anchor:",
        "unicode": "2693",
        "html": "&#9875;",
        "category": "Travel & Places (transport-water)",
        "order": 1642,
        "htmlcode": "&#9875"
    },
    {
        "index": 1181,
        "emoji": "⚡",
        "name": "high voltage",
        "shortname": ":zap:",
        "unicode": "26a1",
        "html": "&#9889;",
        "category": "Travel & Places (sky & weather)",
        "order": 1748,
        "htmlcode": "&#9889"
    },
    {
        "index": 1182,
        "emoji": "✖️",
        "name": "multiplication sign",
        "shortname": ":heavy_multiplication_x:",
        "unicode": "2716",
        "html": "&#10006;",
        "category": "Symbols (other-symbol)",
        "order": 2081,
        "htmlcode": "&#10006"
    },
    {
        "index": 1183,
        "emoji": "☕",
        "name": "hot beverage",
        "shortname": ":coffee:",
        "unicode": "2615",
        "html": "&#9749;",
        "category": "Food & Drink (drink)",
        "order": 1522,
        "htmlcode": "&#9749"
    },
    {
        "index": 1184,
        "emoji": "♨️",
        "name": "hot springs",
        "shortname": ":hotsprings:",
        "unicode": "2668",
        "html": "&#9832;",
        "category": "Travel & Places (place-other)",
        "order": 1591,
        "htmlcode": "&#9832"
    },
    {
        "index": 1185,
        "emoji": "☎️",
        "name": "telephone",
        "shortname": ":telephone:",
        "unicode": "260e",
        "html": "&#9742;",
        "category": "Objects (phone)",
        "order": 1840,
        "htmlcode": "&#9742"
    },
    {
        "index": 1186,
        "emoji": "🇺🇸",
        "name": "flag: United States",
        "shortname": ":us:",
        "unicode": "1f1fa",
        "html": "&#127482;",
        "category": "Flags (country-flag)",
        "order": 63.5,
        "htmlcode": "&#127482"
    },
    {
        "index": 1187,
        "emoji": "☔",
        "name": "umbrella with rain drops",
        "shortname": ":umbrella:",
        "unicode": "2614",
        "html": "&#9748;",
        "category": "Travel & Places (sky & weather)",
        "order": 1746,
        "htmlcode": "&#9748"
    },
    {
        "index": 1188,
        "emoji": "⛄",
        "name": "snowman without snow",
        "shortname": ":snowman:",
        "unicode": "26c4",
        "html": "&#9924;",
        "category": "Travel & Places (sky & weather)",
        "order": 1751,
        "htmlcode": "&#9924"
    },
    {
        "index": 1189,
        "emoji": "⛅",
        "name": "sun behind cloud",
        "shortname": ":partly_sunny:",
        "unicode": "26c5",
        "html": "&#9925;",
        "category": "Travel & Places (sky & weather)",
        "order": 1731,
        "htmlcode": "&#9925"
    },
    {
        "index": 1190,
        "emoji": "⚠️",
        "name": "warning",
        "shortname": ":warning:",
        "unicode": "26a0",
        "html": "&#9888;",
        "category": "Symbols (warning)",
        "order": 1989,
        "htmlcode": "&#9888"
    },
    {
        "index": 1191,
        "emoji": "⭕",
        "name": "hollow red circle",
        "shortname": ":o:",
        "unicode": "2b55",
        "html": "&#11093;",
        "category": "Symbols (other-symbol)",
        "order": 2077,
        "htmlcode": "&#11093"
    },
    {
        "index": 1192,
        "emoji": "⏩",
        "name": "fast-forward button",
        "shortname": ":fast_forward:",
        "unicode": "2.3E+10",
        "html": "&#9193;",
        "category": "Symbols (av-symbol)",
        "order": 2052,
        "htmlcode": "&#2"
    },
    {
        "index": 1193,
        "emoji": "▪️",
        "name": "black small square",
        "shortname": ":black_small_square:",
        "unicode": "25aa",
        "html": "&#9642;",
        "category": "Symbols (geometric)",
        "order": 2159,
        "htmlcode": "&#9642"
    },
    {
        "index": 1194,
        "emoji": "⬇️",
        "name": "down arrow",
        "shortname": ":arrow_down:",
        "unicode": "2b07",
        "html": "&#11015;",
        "category": "Symbols (arrow)",
        "order": 2006,
        "htmlcode": "&#11015"
    },
    {
        "index": 1195,
        "emoji": "✂️",
        "name": "scissors",
        "shortname": ":scissors:",
        "unicode": "2702",
        "html": "&#9986;",
        "category": "Objects (office)",
        "order": 1940,
        "htmlcode": "&#9986"
    },
    {
        "index": 1196,
        "emoji": "🇫🇷",
        "name": "flag: France",
        "shortname": ":fr:",
        "unicode": "1f1eb",
        "html": "&#127467;",
        "category": "Flags (country-flag)",
        "order": 63.5,
        "htmlcode": "&#127467"
    },
    {
        "index": 1197,
        "emoji": "⚾️",
        "name": "",
        "shortname": ":baseball:",
        "unicode": "26be",
        "html": "&#9918;",
        "category": "",
        "order": 1782,
        "htmlcode": "&#9918"
    },
    {
        "index": 1198,
        "emoji": "✏",
        "name": "pencil",
        "shortname": ":pencil2:",
        "unicode": "270f",
        "html": "&#9999;",
        "category": "Objects (writing)",
        "order": 1914,
        "htmlcode": "&#9999"
    },
    {
        "index": 1199,
        "emoji": "❓",
        "name": "question mark",
        "shortname": ":question:",
        "unicode": "2753",
        "html": "&#10067;",
        "category": "Symbols (other-symbol)",
        "order": 2098,
        "htmlcode": "&#10067"
    },
    {
        "index": 1200,
        "emoji": "⚫",
        "name": "black circle",
        "shortname": ":black_circle:",
        "unicode": "26ab",
        "html": "&#9899;",
        "category": "Symbols (geometric)",
        "order": 2178,
        "htmlcode": "&#9899"
    },
    {
        "index": 1201,
        "emoji": "⚪",
        "name": "white circle",
        "shortname": ":white_circle:",
        "unicode": "26aa",
        "html": "&#9898;",
        "category": "Symbols (geometric)",
        "order": 2177,
        "htmlcode": "&#9898"
    },
    {
        "index": 1202,
        "emoji": "⬆️",
        "name": "up arrow",
        "shortname": ":arrow_up:",
        "unicode": "2b06",
        "html": "&#11014;",
        "category": "Symbols (arrow)",
        "order": 2002,
        "htmlcode": "&#11014"
    },
    {
        "index": 1203,
        "emoji": "↗️",
        "name": "up-right arrow",
        "shortname": ":arrow_upper_right:",
        "unicode": "2197",
        "html": "&#8599;",
        "category": "Symbols (arrow)",
        "order": 2003,
        "htmlcode": "&#8599"
    },
    {
        "index": 1204,
        "emoji": "↘️",
        "name": "down-right arrow",
        "shortname": ":arrow_lower_right:",
        "unicode": "2198",
        "html": "&#8600;",
        "category": "Symbols (arrow)",
        "order": 2005,
        "htmlcode": "&#8600"
    },
    {
        "index": 1205,
        "emoji": "↙️",
        "name": "down-left arrow",
        "shortname": ":arrow_lower_left:",
        "unicode": "2199",
        "html": "&#8601;",
        "category": "Symbols (arrow)",
        "order": 2007,
        "htmlcode": "&#8601"
    },
    {
        "index": 1206,
        "emoji": "✳️",
        "name": "eight-spoked asterisk",
        "shortname": ":eight_spoked_asterisk:",
        "unicode": "2733",
        "html": "&#10035;",
        "category": "Symbols (other-symbol)",
        "order": 2093,
        "htmlcode": "&#10035"
    },
    {
        "index": 1207,
        "emoji": "⛽",
        "name": "fuel pump",
        "shortname": ":fuelpump:",
        "unicode": "26fd",
        "html": "&#9981;",
        "category": "Travel & Places (transport-ground)",
        "order": 1636,
        "htmlcode": "&#9981"
    },
    {
        "index": 1208,
        "emoji": "⌚",
        "name": "watch",
        "shortname": ":watch:",
        "unicode": "231a",
        "html": "&#8986;",
        "category": "Travel & Places (time)",
        "order": 1682,
        "htmlcode": "&#8986"
    },
    {
        "index": 1209,
        "emoji": "⏰",
        "name": "alarm clock",
        "shortname": ":alarm_clock:",
        "unicode": "23f0",
        "html": "&#9200;",
        "category": "Travel & Places (time)",
        "order": 1683,
        "htmlcode": "&#9200"
    },
    {
        "index": 1210,
        "emoji": "❕",
        "name": "white exclamation mark",
        "shortname": ":grey_exclamation:",
        "unicode": "2755",
        "html": "&#10069;",
        "category": "Symbols (other-symbol)",
        "order": 2100,
        "htmlcode": "&#10069"
    },
    {
        "index": 1211,
        "emoji": "↖",
        "name": "up-left arrow",
        "shortname": ":arrow_upper_left:",
        "unicode": "2196",
        "html": "&#8598;",
        "category": "Symbols (arrow)",
        "order": 2009,
        "htmlcode": "&#8598"
    },
    {
        "index": 1212,
        "emoji": "⛳",
        "name": "flag in hole",
        "shortname": ":golf:",
        "unicode": "26f3",
        "html": "&#9971;",
        "category": "Activities (sport)",
        "order": 1799,
        "htmlcode": "&#9971"
    },
    {
        "index": 1213,
        "emoji": "⁉️",
        "name": "exclamation question mark",
        "shortname": ":interrobang:",
        "unicode": "2049",
        "html": "&#8265;",
        "category": "Symbols (other-symbol)",
        "order": 2097,
        "htmlcode": "&#8265"
    },
    {
        "index": 1214,
        "emoji": "♋",
        "name": "Cancer",
        "shortname": ":cancer:",
        "unicode": "264b",
        "html": "&#9803;",
        "category": "Symbols (zodiac)",
        "order": 2038,
        "htmlcode": "&#9803"
    },
    {
        "index": 1215,
        "emoji": "⤵️",
        "name": "right arrow curving down",
        "shortname": ":arrow_heading_down:",
        "unicode": "2935",
        "html": "&#10549;",
        "category": "Symbols (arrow)",
        "order": 2015,
        "htmlcode": "&#10549"
    },
    {
        "index": 1216,
        "emoji": "↕",
        "name": "up-down arrow",
        "shortname": ":arrow_up_down:",
        "unicode": "2195",
        "html": "&#8597;",
        "category": "Symbols (arrow)",
        "order": 2010,
        "htmlcode": "&#8597"
    },
    {
        "index": 1217,
        "emoji": "➰",
        "name": "curly loop",
        "shortname": ":curly_loop:",
        "unicode": "27b0",
        "html": "&#10160;",
        "category": "Symbols (other-symbol)",
        "order": 2090,
        "htmlcode": "&#10160"
    },
    {
        "index": 1218,
        "emoji": "〰",
        "name": "wavy dash",
        "shortname": ":wavy_dash:",
        "unicode": "3030",
        "html": "&#12336;",
        "category": "Symbols (other-symbol)",
        "order": 2102,
        "htmlcode": "&#12336"
    },
    {
        "index": 1219,
        "emoji": "⏪",
        "name": "fast reverse button",
        "shortname": ":rewind:",
        "unicode": "23ea",
        "html": "&#9194;",
        "category": "Symbols (av-symbol)",
        "order": 2056,
        "htmlcode": "&#9194"
    },
    {
        "index": 1220,
        "emoji": "✴️",
        "name": "eight-pointed star",
        "shortname": ":eight_pointed_black_star:",
        "unicode": "2734",
        "html": "&#10036;",
        "category": "Symbols (other-symbol)",
        "order": 2094,
        "htmlcode": "&#10036"
    },
    {
        "index": 1221,
        "emoji": "➕",
        "name": "plus sign",
        "shortname": ":heavy_plus_sign:",
        "unicode": "2795",
        "html": "&#10133;",
        "category": "Symbols (other-symbol)",
        "order": 2084,
        "htmlcode": "&#10133"
    },
    {
        "index": 1222,
        "emoji": "⤴️",
        "name": "right arrow curving up",
        "shortname": ":arrow_heading_up:",
        "unicode": "2934",
        "html": "&#10548;",
        "category": "Symbols (arrow)",
        "order": 2014,
        "htmlcode": "&#10548"
    },
    {
        "index": 1223,
        "emoji": "⛔",
        "name": "no entry",
        "shortname": ":no_entry:",
        "unicode": "26d4",
        "html": "&#9940;",
        "category": "Symbols (warning)",
        "order": 1991,
        "htmlcode": "&#9940"
    },
    {
        "index": 1224,
        "emoji": "❇️",
        "name": "sparkle",
        "shortname": ":sparkle:",
        "unicode": "2747",
        "html": "&#10055;",
        "category": "Symbols (other-symbol)",
        "order": 2095,
        "htmlcode": "&#10055"
    },
    {
        "index": 1225,
        "emoji": "⛵",
        "name": "sailboat",
        "shortname": ":sailboat:",
        "unicode": "26F5",
        "html": "&#9973;",
        "category": "Travel & Places (transport-water)",
        "order": 63.5,
        "htmlcode": "&#9973"
    },
    {
        "index": 1226,
        "emoji": "Ⓜ️",
        "name": "circled M",
        "shortname": ":m:",
        "unicode": "24c2",
        "html": "&#9410;",
        "category": "Symbols (alphanum)",
        "order": 2133,
        "htmlcode": "&#9410"
    },
    {
        "index": 1227,
        "emoji": "♒",
        "name": "Aquarius",
        "shortname": ":aquarius:",
        "unicode": "2652",
        "html": "&#9810;",
        "category": "Symbols (zodiac)",
        "order": 2045,
        "htmlcode": "&#9810"
    },
    {
        "index": 1228,
        "emoji": "✒",
        "name": "black nib",
        "shortname": ":black_nib:",
        "unicode": "2712",
        "html": "&#10002;",
        "category": "Objects (writing)",
        "order": 1915,
        "htmlcode": "&#10002"
    },
    {
        "index": 1229,
        "emoji": "♈",
        "name": "Aries",
        "shortname": ":aries:",
        "unicode": "2648",
        "html": "&#9800;",
        "category": "Symbols (zodiac)",
        "order": 2035,
        "htmlcode": "&#9800"
    },
    {
        "index": 1230,
        "emoji": "♏",
        "name": "Scorpio",
        "shortname": ":scorpius:",
        "unicode": "264f",
        "html": "&#9807;",
        "category": "Symbols (zodiac)",
        "order": 2042,
        "htmlcode": "&#9807"
    },
    {
        "index": 1231,
        "emoji": "⏳",
        "name": "hourglass not done",
        "shortname": ":hourglass_flowing_sand:",
        "unicode": "23f3",
        "html": "&#9203;",
        "category": "Travel & Places (time)",
        "order": 1681,
        "htmlcode": "&#9203"
    },
    {
        "index": 1232,
        "emoji": "➖",
        "name": "minus sign",
        "shortname": ":heavy_minus_sign:",
        "unicode": "2796",
        "html": "&#10134;",
        "category": "Symbols (other-symbol)",
        "order": 2088,
        "htmlcode": "&#10134"
    },
    {
        "index": 1233,
        "emoji": "♐",
        "name": "Sagittarius",
        "shortname": ":sagittarius:",
        "unicode": "2650",
        "html": "&#9808;",
        "category": "Symbols (zodiac)",
        "order": 2043,
        "htmlcode": "&#9808"
    },
    {
        "index": 1234,
        "emoji": "〽️",
        "name": "part alternation mark",
        "shortname": ":part_alternation_mark:",
        "unicode": "303d",
        "html": "&#12349;",
        "category": "Symbols (other-symbol)",
        "order": 2092,
        "htmlcode": "&#12349"
    },
    {
        "index": 1235,
        "emoji": "♌",
        "name": "Leo",
        "shortname": ":leo:",
        "unicode": "264c",
        "html": "&#9804;",
        "category": "Symbols (zodiac)",
        "order": 2039,
        "htmlcode": "&#9804"
    },
    {
        "index": 1236,
        "emoji": "♊",
        "name": "Gemini",
        "shortname": ":gemini:",
        "unicode": "264a",
        "html": "&#9802;",
        "category": "Symbols (zodiac)",
        "order": 2037,
        "htmlcode": "&#9802"
    },
    {
        "index": 1237,
        "emoji": "♉",
        "name": "Taurus",
        "shortname": ":taurus:",
        "unicode": "2649",
        "html": "&#9801;",
        "category": "Symbols (zodiac)",
        "order": 2036,
        "htmlcode": "&#9801"
    },
    {
        "index": 1238,
        "emoji": "⛪",
        "name": "church",
        "shortname": ":church:",
        "unicode": "26ea",
        "html": "&#9962;",
        "category": "Travel & Places (place-religious)",
        "order": 1577,
        "htmlcode": "&#9962"
    },
    {
        "index": 1239,
        "emoji": "♓",
        "name": "Pisces",
        "shortname": ":pisces:",
        "unicode": "2653",
        "html": "&#9811;",
        "category": "Symbols (zodiac)",
        "order": 2046,
        "htmlcode": "&#9811"
    },
    {
        "index": 1240,
        "emoji": "♑",
        "name": "Capricorn",
        "shortname": ":capricorn:",
        "unicode": "2651",
        "html": "&#9809;",
        "category": "Symbols (zodiac)",
        "order": 2044,
        "htmlcode": "&#9809"
    },
    {
        "index": 1241,
        "emoji": "⌛",
        "name": "hourglass done",
        "shortname": ":hourglass:",
        "unicode": "231b",
        "html": "&#8987;",
        "category": "Travel & Places (time)",
        "order": 1680,
        "htmlcode": "&#8987"
    },
    {
        "index": 1242,
        "emoji": "❎",
        "name": "cross mark button",
        "shortname": ":negative_squared_cross_mark:",
        "unicode": "274e",
        "html": "&#10062;",
        "category": "Symbols (other-symbol)",
        "order": 2083,
        "htmlcode": "&#10062"
    },
    {
        "index": 1243,
        "emoji": "❔",
        "name": "white question mark",
        "shortname": ":grey_question:",
        "unicode": "2754",
        "html": "&#10068;",
        "category": "Symbols (other-symbol)",
        "order": 2099,
        "htmlcode": "&#10068"
    },
    {
        "index": 1244,
        "emoji": "㊙",
        "name": "Japanese secret button",
        "shortname": ":secret:",
        "unicode": "3299",
        "html": "&#12953;",
        "category": "Symbols (alphanum)",
        "order": 2156,
        "htmlcode": "&#12953"
    },
    {
        "index": 1245,
        "emoji": "♎",
        "name": "Libra",
        "shortname": ":libra:",
        "unicode": "264e",
        "html": "&#9806;",
        "category": "Symbols (zodiac)",
        "order": 2041,
        "htmlcode": "&#9806"
    },
    {
        "index": 1246,
        "emoji": "♍",
        "name": "Virgo",
        "shortname": ":virgo:",
        "unicode": "264d",
        "html": "&#9805;",
        "category": "Symbols (zodiac)",
        "order": 2040,
        "htmlcode": "&#9805"
    },
    {
        "index": 1247,
        "emoji": "㊗",
        "name": "Japanese congratulations button",
        "shortname": ":congratulations:",
        "unicode": "3297",
        "html": "&#12951;",
        "category": "Symbols (alphanum)",
        "order": 2155,
        "htmlcode": "&#12951"
    },
    {
        "index": 1248,
        "emoji": "➿",
        "name": "double curly loop",
        "shortname": ":loop:",
        "unicode": "27bf",
        "html": "&#10175;",
        "category": "Symbols (other-symbol)",
        "order": 2091,
        "htmlcode": "&#10175"
    },
    {
        "index": 1249,
        "emoji": "ℹ",
        "name": "information",
        "shortname": ":information_source:",
        "unicode": "2139",
        "html": "&#8505;",
        "category": "Symbols (alphanum)",
        "order": 2131,
        "htmlcode": "&#8505"
    },
    {
        "index": 1250,
        "emoji": "⛺",
        "name": "tent",
        "shortname": ":tent:",
        "unicode": "26fa",
        "html": "&#9978;",
        "category": "Travel & Places (place-other)",
        "order": 1583,
        "htmlcode": "&#9978"
    },
    {
        "index": 1251,
        "emoji": "◾",
        "name": "black medium-small square",
        "shortname": ":black_medium_small_square:",
        "unicode": "25fe",
        "html": "&#9726;",
        "category": "Symbols (geometric)",
        "order": 2164,
        "htmlcode": "&#9726"
    },
    {
        "index": 1252,
        "emoji": "⏫",
        "name": "fast up button",
        "shortname": ":arrow_double_up:",
        "unicode": "23eb",
        "html": "&#9195;",
        "category": "Symbols (av-symbol)",
        "order": 2059,
        "htmlcode": "&#9195"
    },
    {
        "index": 1253,
        "emoji": "▫",
        "name": "white small square",
        "shortname": ":white_small_square:",
        "unicode": "25ab",
        "html": "&#9643;",
        "category": "Symbols (geometric)",
        "order": 2160,
        "htmlcode": "&#9643"
    },
    {
        "index": 1254,
        "emoji": "◼",
        "name": "black medium square",
        "shortname": ":black_medium_square:",
        "unicode": "25fc",
        "html": "&#9724;",
        "category": "Symbols (geometric)",
        "order": 2162,
        "htmlcode": "&#9724"
    },
    {
        "index": 1255,
        "emoji": "⏬",
        "name": "fast down button",
        "shortname": ":arrow_double_down:",
        "unicode": "23ec",
        "html": "&#9196;",
        "category": "Symbols (av-symbol)",
        "order": 2061,
        "htmlcode": "&#9196"
    },
    {
        "index": 1256,
        "emoji": "⬜",
        "name": "white large square",
        "shortname": ":white_large_square:",
        "unicode": "2b1c",
        "html": "&#11036;",
        "category": "Symbols (geometric)",
        "order": 2166,
        "htmlcode": "&#11036"
    },
    {
        "index": 1257,
        "emoji": "♿",
        "name": "wheelchair symbol",
        "shortname": ":wheelchair:",
        "unicode": "267f",
        "html": "&#9855;",
        "category": "Symbols (transport-sign)",
        "order": 1979,
        "htmlcode": "&#9855"
    },
    {
        "index": 1258,
        "emoji": "◻️",
        "name": "white medium square",
        "shortname": ":white_medium_square:",
        "unicode": "25fb",
        "html": "&#9723;",
        "category": "Symbols (geometric)",
        "order": 2161,
        "htmlcode": "&#9723"
    },
    {
        "index": 1259,
        "emoji": "⛲",
        "name": "fountain",
        "shortname": ":fountain:",
        "unicode": "26f2",
        "html": "&#9970;",
        "category": "Travel & Places (place-other)",
        "order": 1582,
        "htmlcode": "&#9970"
    },
    {
        "index": 1260,
        "emoji": "⬛",
        "name": "black large square",
        "shortname": ":black_large_square:",
        "unicode": "2b1b",
        "html": "&#11035;",
        "category": "Symbols (geometric)",
        "order": 2165,
        "htmlcode": "&#11035"
    },
    {
        "index": 1261,
        "emoji": "⛎",
        "name": "Ophiuchus",
        "shortname": ":ophiuchus:",
        "unicode": "26ce",
        "html": "&#9934;",
        "category": "Symbols (zodiac)",
        "order": 2047,
        "htmlcode": "&#9934"
    },
    {
        "index": 1262,
        "emoji": "◽",
        "name": "white medium-small square",
        "shortname": ":white_medium_small_square:",
        "unicode": "25fd",
        "html": "&#9725;",
        "category": "Symbols (geometric)",
        "order": 2163,
        "htmlcode": "&#9725"
    },
    {
        "index": 1263,
        "emoji": "➗",
        "name": "division sign",
        "shortname": ":heavy_division_sign:",
        "unicode": "2797",
        "html": "&#10135;",
        "category": "Symbols (other-symbol)",
        "order": 2089,
        "htmlcode": "&#10135"
    },
    {
        "index": 1264,
        "emoji": "☹",
        "name": "frowning face",
        "shortname": ":frowning_face:",
        "unicode": "2639",
        "html": "&#9785;",
        "category": "Smileys & Emotion (face-concerned)",
        "order": 63.5,
        "htmlcode": "&#9785"
    },
    {
        "index": 1265,
        "emoji": "☠",
        "name": "skull and crossbones",
        "shortname": ":skull_crossbones:",
        "unicode": "2620",
        "html": "&#9760;",
        "category": "Smileys & Emotion (face-negative)",
        "order": 81,
        "htmlcode": "&#9760"
    },
    {
        "index": 1266,
        "emoji": "✍",
        "name": "writing hand",
        "shortname": ":writing_hand:",
        "unicode": "270d",
        "html": "&#9997;",
        "category": "People & Body (hand-prop)",
        "order": 1230,
        "htmlcode": "&#9997"
    },
    {
        "index": 1267,
        "emoji": "⛹",
        "name": "person bouncing ball",
        "shortname": ":basketballer:",
        "unicode": "26F9",
        "html": "&#9977;",
        "category": "People & Body (person-sport)",
        "order": 63.5,
        "htmlcode": "&#9977"
    },
    {
        "index": 1268,
        "emoji": "❣️",
        "name": "heart exclamation",
        "shortname": ":heart_exclamation:",
        "unicode": "2763",
        "html": "&#10083;",
        "category": "Smileys & Emotion (emotion)",
        "order": 1300,
        "htmlcode": "&#10083"
    },
    {
        "index": 1269,
        "emoji": "✡️",
        "name": "star of David",
        "shortname": ":star_of_david:",
        "unicode": "2721",
        "html": "&#10017;",
        "category": "Symbols (religion)",
        "order": 2026,
        "htmlcode": "&#10017"
    },
    {
        "index": 1270,
        "emoji": "✝️",
        "name": "latin cross",
        "shortname": ":cross:",
        "unicode": "271d",
        "html": "&#10013;",
        "category": "Symbols (religion)",
        "order": 2029,
        "htmlcode": "&#10013"
    },
    {
        "index": 1271,
        "emoji": "⚜",
        "name": "fleur-de-lis",
        "shortname": ":fleur-de-lis:",
        "unicode": "269c",
        "html": "&#9884;",
        "category": "Symbols (other-symbol)",
        "order": 2074,
        "htmlcode": "&#9884"
    },
    {
        "index": 1272,
        "emoji": "⚛",
        "name": "atom symbol",
        "shortname": ":atom:",
        "unicode": "269b",
        "html": "&#9883;",
        "category": "Symbols (religion)",
        "order": 2024,
        "htmlcode": "&#9883"
    },
    {
        "index": 1273,
        "emoji": "☸",
        "name": "wheel of dharma",
        "shortname": ":wheel_of_dharma:",
        "unicode": "2638",
        "html": "&#9784;",
        "category": "Symbols (religion)",
        "order": 2027,
        "htmlcode": "&#9784"
    },
    {
        "index": 1274,
        "emoji": "☯",
        "name": "yin yang",
        "shortname": ":yin_yang:",
        "unicode": "262f",
        "html": "&#9775;",
        "category": "Symbols (religion)",
        "order": 2028,
        "htmlcode": "&#9775"
    },
    {
        "index": 1275,
        "emoji": "☮",
        "name": "peace symbol",
        "shortname": ":peace:",
        "unicode": "262e",
        "html": "&#9774;",
        "category": "Symbols (religion)",
        "order": 2032,
        "htmlcode": "&#9774"
    },
    {
        "index": 1276,
        "emoji": "☪",
        "name": "star and crescent",
        "shortname": ":star_and_crescent:",
        "unicode": "262a",
        "html": "&#9770;",
        "category": "Symbols (religion)",
        "order": 2031,
        "htmlcode": "&#9770"
    },
    {
        "index": 1277,
        "emoji": "☦",
        "name": "orthodox cross",
        "shortname": ":orthodox_cross:",
        "unicode": "2626",
        "html": "&#9766;",
        "category": "Symbols (religion)",
        "order": 2030,
        "htmlcode": "&#9766"
    },
    {
        "index": 1278,
        "emoji": "☣",
        "name": "biohazard",
        "shortname": ":biohazard:",
        "unicode": "2623",
        "html": "&#9763;",
        "category": "Symbols (warning)",
        "order": 2001,
        "htmlcode": "&#9763"
    },
    {
        "index": 1279,
        "emoji": "☢",
        "name": "radioactive",
        "shortname": ":radioactive:",
        "unicode": "2622",
        "html": "&#9762;",
        "category": "Symbols (warning)",
        "order": 2000,
        "htmlcode": "&#9762"
    },
    {
        "index": 1280,
        "emoji": "⚱",
        "name": "funeral urn",
        "shortname": ":funeral_urn:",
        "unicode": "26B1",
        "html": "&#9905;",
        "category": "Objects (other-object)",
        "order": 63.5,
        "htmlcode": "&#9905"
    },
    {
        "index": 1281,
        "emoji": "⚰",
        "name": "coffin",
        "shortname": ":coffin:",
        "unicode": "26b0",
        "html": "&#9904;",
        "category": "Objects (other-object)",
        "order": 1970,
        "htmlcode": "&#9904"
    },
    {
        "index": 1282,
        "emoji": "⚙",
        "name": "gear",
        "shortname": ":gear:",
        "unicode": "2699",
        "html": "&#9881;",
        "category": "Objects (tool)",
        "order": 1961,
        "htmlcode": "&#9881"
    },
    {
        "index": 1283,
        "emoji": "⚗",
        "name": "alembic",
        "shortname": ":alembic:",
        "unicode": "2697",
        "html": "&#9879;",
        "category": "Objects (science)",
        "order": 1963,
        "htmlcode": "&#9879"
    },
    {
        "index": 1284,
        "emoji": "⚖",
        "name": "balance scale",
        "shortname": ":scales:",
        "unicode": "2696",
        "html": "&#9878;",
        "category": "Objects (tool)",
        "order": 1964,
        "htmlcode": "&#9878"
    },
    {
        "index": 1285,
        "emoji": "⚔",
        "name": "crossed swords",
        "shortname": ":crossed_swords:",
        "unicode": "2694",
        "html": "&#9876;",
        "category": "Objects (tool)",
        "order": 1955,
        "htmlcode": "&#9876"
    },
    {
        "index": 1286,
        "emoji": "⌨",
        "name": "keyboard",
        "shortname": ":keyboard:",
        "unicode": "2328",
        "html": "&#9000;",
        "category": "Objects (computer)",
        "order": 1849,
        "htmlcode": "&#9000"
    },
    {
        "index": 1287,
        "emoji": "☘",
        "name": "shamrock",
        "shortname": ":shamrock:",
        "unicode": "2618",
        "html": "&#9752;",
        "category": "Animals & Nature (plant-other)",
        "order": 1444,
        "htmlcode": "&#9752"
    },
    {
        "index": 1288,
        "emoji": "☄",
        "name": "comet",
        "shortname": ":comet:",
        "unicode": "2604",
        "html": "&#9732;",
        "category": "Travel & Places (sky & weather)",
        "order": 1752,
        "htmlcode": "&#9732"
    },
    {
        "index": 1289,
        "emoji": "⏺",
        "name": "record button",
        "shortname": ":record_button:",
        "unicode": "23FA",
        "html": "&#9210;",
        "category": "Symbols (av-symbol)",
        "order": 63.5,
        "htmlcode": "&#9210"
    },
    {
        "index": 1290,
        "emoji": "⏹",
        "name": "stop button",
        "shortname": ":stop_button:",
        "unicode": "23F9",
        "html": "&#9209;",
        "category": "Symbols (av-symbol)",
        "order": 63.5,
        "htmlcode": "&#9209"
    },
    {
        "index": 1291,
        "emoji": "⏸",
        "name": "pause button",
        "shortname": ":pause_button:",
        "unicode": "23F8",
        "html": "&#9208;",
        "category": "Symbols (av-symbol)",
        "order": 63.5,
        "htmlcode": "&#9208"
    },
    {
        "index": 1292,
        "emoji": "⏯",
        "name": "play or pause button",
        "shortname": ":play_pause:",
        "unicode": "23ef",
        "html": "&#9199;",
        "category": "Symbols (av-symbol)",
        "order": 2054,
        "htmlcode": "&#9199"
    },
    {
        "index": 1293,
        "emoji": "⏮",
        "name": "last track button",
        "shortname": ":track_previous:",
        "unicode": "23ee",
        "html": "&#9198;",
        "category": "Symbols (av-symbol)",
        "order": 2057,
        "htmlcode": "&#9198"
    },
    {
        "index": 1294,
        "emoji": "⏭",
        "name": "next track button",
        "shortname": ":track_next:",
        "unicode": "23ed",
        "html": "&#9197;",
        "category": "Symbols (av-symbol)",
        "order": 2053,
        "htmlcode": "&#9197"
    },
    {
        "index": 1295,
        "emoji": "⛱",
        "name": "umbrella on ground",
        "shortname": ":beach_umbrella:",
        "unicode": "26f1",
        "html": "&#9969;",
        "category": "Travel & Places (sky & weather)",
        "order": 1747,
        "htmlcode": "&#9969"
    },
    {
        "index": 1296,
        "emoji": "⛓",
        "name": "chains",
        "shortname": ":chains:",
        "unicode": "26d3",
        "html": "&#9939;",
        "category": "Objects (tool)",
        "order": 1966,
        "htmlcode": "&#9939"
    },
    {
        "index": 1297,
        "emoji": "⛏",
        "name": "pick",
        "shortname": ":pick:",
        "unicode": "26cf",
        "html": "&#9935;",
        "category": "Objects (tool)",
        "order": 1951,
        "htmlcode": "&#9935"
    },
    {
        "index": 1298,
        "emoji": "⚒",
        "name": "hammer and pick",
        "shortname": ":hammer_and_pick:",
        "unicode": "2692",
        "html": "&#9874;",
        "category": "Objects (tool)",
        "order": 63.5,
        "htmlcode": "&#9874"
    },
    {
        "index": 1299,
        "emoji": "⏲",
        "name": "timer clock",
        "shortname": ":timer_clock:",
        "unicode": "23F2",
        "html": "&#9202;",
        "category": "Travel & Places (time)",
        "order": 63.5,
        "htmlcode": "&#9202"
    },
    {
        "index": 1300,
        "emoji": "⏱",
        "name": "stopwatch",
        "shortname": ":stopwatch:",
        "unicode": "23f1",
        "html": "&#9201;",
        "category": "Travel & Places (time)",
        "order": 1684,
        "htmlcode": "&#9201"
    },
    {
        "index": 1301,
        "emoji": "⛴",
        "name": "ferry",
        "shortname": ":ferry:",
        "unicode": "26f4",
        "html": "&#9972;",
        "category": "Travel & Places (transport-water)",
        "order": 1647,
        "htmlcode": "&#9972"
    },
    {
        "index": 1302,
        "emoji": "⛰",
        "name": "mountain",
        "shortname": ":mountain:",
        "unicode": "26f0",
        "html": "&#9968;",
        "category": "Travel & Places (place-geographic)",
        "order": 1545,
        "htmlcode": "&#9968"
    },
    {
        "index": 1303,
        "emoji": "⛩",
        "name": "shinto shrine",
        "shortname": ":shinto_shrine:",
        "unicode": "2.6E+10",
        "html": "&#9961;",
        "category": "Travel & Places (place-religious)",
        "order": 1580,
        "htmlcode": "&#2"
    },
    {
        "index": 1304,
        "emoji": "⛸",
        "name": "ice skate",
        "shortname": ":ice_skate:",
        "unicode": "26f8",
        "html": "&#9976;",
        "category": "Activities (sport)",
        "order": 1800,
        "htmlcode": "&#9976"
    },
    {
        "index": 1305,
        "emoji": "⛷",
        "name": "skier",
        "shortname": ":skier:",
        "unicode": "26f7",
        "html": "&#9975;",
        "category": "People & Body (person-sport)",
        "order": 775,
        "htmlcode": "&#9975"
    },
    {
        "index": 1306,
        "emoji": "⛈",
        "name": "cloud with lightning and rain",
        "shortname": ":cloud_with_lightning_and_rain:",
        "unicode": "26C8",
        "html": "&#9928;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#9928"
    },
    {
        "index": 1307,
        "emoji": "⛑",
        "name": "rescue worker’s helmet",
        "shortname": ":rescue_worker’s_helmet:",
        "unicode": "26D1",
        "html": "&#9937;",
        "category": "Objects (clothing)",
        "order": 63.5,
        "htmlcode": "&#9937"
    },
    {
        "index": 1308,
        "emoji": "♠",
        "name": "spade suit",
        "shortname": ":spades:",
        "unicode": "2660",
        "html": "&spades;",
        "category": "Activities (game)",
        "order": 1807,
        "htmlcode": "&#9824"
    },
    {
        "index": 1309,
        "emoji": "♥",
        "name": "heart suit",
        "shortname": ":hearts:",
        "unicode": "2665",
        "html": "&hearts;",
        "category": "Activities (game)",
        "order": 1808,
        "htmlcode": "&#9829"
    },
    {
        "index": 1310,
        "emoji": "♦",
        "name": "diamond suit",
        "shortname": ":diamonds:",
        "unicode": "2666",
        "html": "&diams;",
        "category": "Activities (game)",
        "order": 1809,
        "htmlcode": "&#9830"
    },
    {
        "index": 1311,
        "emoji": "♣",
        "name": "club suit",
        "shortname": ":clubs:",
        "unicode": "2663",
        "html": "&clubs;",
        "category": "Activities (game)",
        "order": 1810,
        "htmlcode": "&#9827"
    },
    {
        "index": 1312,
        "emoji": "↔",
        "name": "left-right arrow",
        "shortname": ":left_right_arrow:",
        "unicode": "2194",
        "html": "&harr;",
        "category": "Symbols (arrow)",
        "order": 2011,
        "htmlcode": "&#8596"
    },
    {
        "index": 1313,
        "emoji": "™",
        "name": "trade mark",
        "shortname": ":tm:",
        "unicode": "2122",
        "html": "&trade;",
        "category": "Symbols (other-symbol)",
        "order": 2105,
        "htmlcode": "&#8482"
    },
    {
        "index": 1314,
        "emoji": "☺",
        "name": "smiling face",
        "shortname": ":smiling_face:",
        "unicode": "263A",
        "html": "&#x263A;",
        "category": "Smileys & Emotion (face-affection)",
        "order": 63.5,
        "htmlcode": "&#9786"
    },
    {
        "index": 1315,
        "emoji": "❣",
        "name": "heart exclamation",
        "shortname": ":heart_exclamation:",
        "unicode": "2763",
        "html": "&#x2763;",
        "category": "Smileys & Emotion (emotion)",
        "order": 63.5,
        "htmlcode": "&#10083"
    },
    {
        "index": 1316,
        "emoji": "❤",
        "name": "red heart",
        "shortname": ":red_heart:",
        "unicode": "2764",
        "html": "&#x2764;",
        "category": "Smileys & Emotion (emotion)",
        "order": 63.5,
        "htmlcode": "&#10084"
    },
    {
        "index": 1317,
        "emoji": "✌",
        "name": "victory hand",
        "shortname": ":victory_hand:",
        "unicode": "270C",
        "html": "&#x270C;",
        "category": "People & Body (hand-fingers-partial)",
        "order": 63.5,
        "htmlcode": "&#9996"
    },
    {
        "index": 1318,
        "emoji": "☝",
        "name": "index pointing up",
        "shortname": ":index_pointing_up:",
        "unicode": "261D",
        "html": "&#x261D;",
        "category": "People & Body (hand-single-finger)",
        "order": 63.5,
        "htmlcode": "&#9757"
    },
    {
        "index": 1319,
        "emoji": "🏻",
        "name": "light skin tone",
        "shortname": ":light_skin_tone:",
        "unicode": "1F3FB",
        "html": "&#x1F3FB;",
        "category": "Component (skin-tone)",
        "order": 63.5,
        "htmlcode": "&#127995"
    },
    {
        "index": 1320,
        "emoji": "🏼",
        "name": "medium-light skin tone",
        "shortname": ":mediumlight_skin_tone:",
        "unicode": "1F3FC",
        "html": "&#x1F3FC;",
        "category": "Component (skin-tone)",
        "order": 63.5,
        "htmlcode": "&#127996"
    },
    {
        "index": 1321,
        "emoji": "🏽",
        "name": "medium skin tone",
        "shortname": ":medium_skin_tone:",
        "unicode": "1F3FD",
        "html": "&#x1F3FD;",
        "category": "Component (skin-tone)",
        "order": 63.5,
        "htmlcode": "&#127997"
    },
    {
        "index": 1322,
        "emoji": "🏾",
        "name": "medium-dark skin tone",
        "shortname": ":mediumdark_skin_tone:",
        "unicode": "1F3FE",
        "html": "&#x1F3FE;",
        "category": "Component (skin-tone)",
        "order": 63.5,
        "htmlcode": "&#127998"
    },
    {
        "index": 1323,
        "emoji": "🏿",
        "name": "dark skin tone",
        "shortname": ":dark_skin_tone:",
        "unicode": "1F3FF",
        "html": "&#x1F3FF;",
        "category": "Component (skin-tone)",
        "order": 63.5,
        "htmlcode": "&#127999"
    },
    {
        "index": 1324,
        "emoji": "♨",
        "name": "hot springs",
        "shortname": ":hot_springs:",
        "unicode": "2668",
        "html": "&#x2668;",
        "category": "Travel & Places (place-other)",
        "order": 63.5,
        "htmlcode": "&#9832"
    },
    {
        "index": 1325,
        "emoji": "✈",
        "name": "airplane",
        "shortname": ":airplane:",
        "unicode": "2708",
        "html": "&#x2708;",
        "category": "Travel & Places (transport-air)",
        "order": 63.5,
        "htmlcode": "&#9992"
    },
    {
        "index": 1326,
        "emoji": "☀",
        "name": "sun",
        "shortname": ":sun:",
        "unicode": "2600",
        "html": "&#x2600;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#9728"
    },
    {
        "index": 1327,
        "emoji": "☂",
        "name": "umbrella",
        "shortname": ":umbrella:",
        "unicode": "2602",
        "html": "&#x2602;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#9730"
    },
    {
        "index": 1328,
        "emoji": "❄",
        "name": "snowflake",
        "shortname": ":snowflake:",
        "unicode": "2744",
        "html": "&#x2744;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#10052"
    },
    {
        "index": 1329,
        "emoji": "☃",
        "name": "snowman",
        "shortname": ":snowman:",
        "unicode": "2603",
        "html": "&#x2603;",
        "category": "Travel & Places (sky & weather)",
        "order": 63.5,
        "htmlcode": "&#9731"
    },
    {
        "index": 1330,
        "emoji": "⚾",
        "name": "baseball",
        "shortname": ":baseball:",
        "unicode": "26BE",
        "html": "&#x26BE;",
        "category": "Activities (sport)",
        "order": 63.5,
        "htmlcode": "&#9918"
    },
    {
        "index": 1331,
        "emoji": "♟",
        "name": "chess pawn",
        "shortname": ":chess_pawn:",
        "unicode": "265F",
        "html": "&#x265F;",
        "category": "Activities (game)",
        "order": 63.5,
        "htmlcode": "&#9823"
    },
    {
        "index": 1332,
        "emoji": "☎",
        "name": "telephone",
        "shortname": ":telephone:",
        "unicode": "260E",
        "html": "&#x260E;",
        "category": "Objects (phone)",
        "order": 63.5,
        "htmlcode": "&#9742"
    },
    {
        "index": 1333,
        "emoji": "✉",
        "name": "envelope",
        "shortname": ":envelope:",
        "unicode": "2709",
        "html": "&#x2709;",
        "category": "Objects (mail)",
        "order": 63.5,
        "htmlcode": "&#9993"
    },
    {
        "index": 1334,
        "emoji": "✂",
        "name": "scissors",
        "shortname": ":scissors:",
        "unicode": "2702",
        "html": "&#x2702;",
        "category": "Objects (office)",
        "order": 63.5,
        "htmlcode": "&#9986"
    },
    {
        "index": 1335,
        "emoji": "⚠",
        "name": "warning",
        "shortname": ":warning:",
        "unicode": "26A0",
        "html": "&#x26A0;",
        "category": "Symbols (warning)",
        "order": 63.5,
        "htmlcode": "&#9888"
    },
    {
        "index": 1336,
        "emoji": "⬆",
        "name": "up arrow",
        "shortname": ":up_arrow:",
        "unicode": "2B06",
        "html": "&#x2B06;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#11014"
    },
    {
        "index": 1337,
        "emoji": "↗",
        "name": "up-right arrow",
        "shortname": ":upright_arrow:",
        "unicode": "2197",
        "html": "&#x2197;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#8599"
    },
    {
        "index": 1338,
        "emoji": "↘",
        "name": "down-right arrow",
        "shortname": ":downright_arrow:",
        "unicode": "2198",
        "html": "&#x2198;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#8600"
    },
    {
        "index": 1339,
        "emoji": "⬇",
        "name": "down arrow",
        "shortname": ":down_arrow:",
        "unicode": "2B07",
        "html": "&#x2B07;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#11015"
    },
    {
        "index": 1340,
        "emoji": "↙",
        "name": "down-left arrow",
        "shortname": ":downleft_arrow:",
        "unicode": "2199",
        "html": "&#x2199;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#8601"
    },
    {
        "index": 1341,
        "emoji": "⬅",
        "name": "left arrow",
        "shortname": ":left_arrow:",
        "unicode": "2B05",
        "html": "&#x2B05;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#11013"
    },
    {
        "index": 1342,
        "emoji": "↩",
        "name": "right arrow curving left",
        "shortname": ":right_arrow_curving_left:",
        "unicode": "21A9",
        "html": "&#x21A9;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#8617"
    },
    {
        "index": 1343,
        "emoji": "↪",
        "name": "left arrow curving right",
        "shortname": ":left_arrow_curving_right:",
        "unicode": "21AA",
        "html": "&#x21AA;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#8618"
    },
    {
        "index": 1344,
        "emoji": "⤴",
        "name": "right arrow curving up",
        "shortname": ":right_arrow_curving_up:",
        "unicode": "2934",
        "html": "&#x2934;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#10548"
    },
    {
        "index": 1345,
        "emoji": "⤵",
        "name": "right arrow curving down",
        "shortname": ":right_arrow_curving_down:",
        "unicode": "2935",
        "html": "&#x2935;",
        "category": "Symbols (arrow)",
        "order": 63.5,
        "htmlcode": "&#10549"
    },
    {
        "index": 1346,
        "emoji": "✡",
        "name": "star of David",
        "shortname": ":star_of_David:",
        "unicode": "2721",
        "html": "&#x2721;",
        "category": "Symbols (religion)",
        "order": 63.5,
        "htmlcode": "&#10017"
    },
    {
        "index": 1347,
        "emoji": "✝",
        "name": "latin cross",
        "shortname": ":latin_cross:",
        "unicode": "271D",
        "html": "&#x271D;",
        "category": "Symbols (religion)",
        "order": 63.5,
        "htmlcode": "&#10013"
    },
    {
        "index": 1348,
        "emoji": "▶",
        "name": "play button",
        "shortname": ":play_button:",
        "unicode": "25B6",
        "html": "&#x25B6;",
        "category": "Symbols (av-symbol)",
        "order": 63.5,
        "htmlcode": "&#9654"
    },
    {
        "index": 1349,
        "emoji": "◀",
        "name": "reverse button",
        "shortname": ":reverse_button:",
        "unicode": "25C0",
        "html": "&#x25C0;",
        "category": "Symbols (av-symbol)",
        "order": 63.5,
        "htmlcode": "&#9664"
    },
    {
        "index": 1350,
        "emoji": "⏏",
        "name": "eject button",
        "shortname": ":eject_button:",
        "unicode": "23CF",
        "html": "&#x23CF;",
        "category": "Symbols (av-symbol)",
        "order": 63.5,
        "htmlcode": "&#9167"
    },
    {
        "index": 1351,
        "emoji": "♀",
        "name": "female sign",
        "shortname": ":female_sign:",
        "unicode": "2640",
        "html": "&#x2640;",
        "category": "Symbols (gender)",
        "order": 63.5,
        "htmlcode": "&#9792"
    },
    {
        "index": 1352,
        "emoji": "♂",
        "name": "male sign",
        "shortname": ":male_sign:",
        "unicode": "2642",
        "html": "&#x2642;",
        "category": "Symbols (gender)",
        "order": 63.5,
        "htmlcode": "&#9794"
    },
    {
        "index": 1353,
        "emoji": "⚕",
        "name": "medical symbol",
        "shortname": ":medical_symbol:",
        "unicode": "2695",
        "html": "&#x2695;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#9877"
    },
    {
        "index": 1354,
        "emoji": "♾",
        "name": "infinity",
        "shortname": ":infinity:",
        "unicode": "267E",
        "html": "&#x267E;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#9854"
    },
    {
        "index": 1355,
        "emoji": "☑",
        "name": "check box with check",
        "shortname": ":check_box_with_check:",
        "unicode": "2611",
        "html": "&#x2611;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#9745"
    },
    {
        "index": 1356,
        "emoji": "✔",
        "name": "check mark",
        "shortname": ":check_mark:",
        "unicode": "2714",
        "html": "&#x2714;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#10004"
    },
    {
        "index": 1357,
        "emoji": "✖",
        "name": "multiplication sign",
        "shortname": ":multiplication_sign:",
        "unicode": "2716",
        "html": "&#x2716;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#10006"
    },
    {
        "index": 1358,
        "emoji": "〽",
        "name": "part alternation mark",
        "shortname": ":part_alternation_mark:",
        "unicode": "303D",
        "html": "&#x303D;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#12349"
    },
    {
        "index": 1359,
        "emoji": "✳",
        "name": "eight-spoked asterisk",
        "shortname": ":eightspoked_asterisk:",
        "unicode": "2733",
        "html": "&#x2733;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#10035"
    },
    {
        "index": 1360,
        "emoji": "✴",
        "name": "eight-pointed star",
        "shortname": ":eightpointed_star:",
        "unicode": "2734",
        "html": "&#x2734;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#10036"
    },
    {
        "index": 1361,
        "emoji": "❇",
        "name": "sparkle",
        "shortname": ":sparkle:",
        "unicode": "2747",
        "html": "&#x2747;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#10055"
    },
    {
        "index": 1362,
        "emoji": "‼",
        "name": "double exclamation mark",
        "shortname": ":double_exclamation_mark:",
        "unicode": "203C",
        "html": "&#x203C;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#8252"
    },
    {
        "index": 1363,
        "emoji": "⁉",
        "name": "exclamation question mark",
        "shortname": ":exclamation_question_mark:",
        "unicode": "2049",
        "html": "&#x2049;",
        "category": "Symbols (other-symbol)",
        "order": 63.5,
        "htmlcode": "&#8265"
    },
    {
        "index": 1364,
        "emoji": "Ⓜ",
        "name": "circled M",
        "shortname": ":circled_M:",
        "unicode": "24C2",
        "html": "&#x24C2;",
        "category": "Symbols (alphanum)",
        "order": 63.5,
        "htmlcode": "&#9410"
    },
    {
        "index": 1365,
        "emoji": "▪",
        "name": "black small square",
        "shortname": ":black_small_square:",
        "unicode": "25AA",
        "html": "&#x25AA;",
        "category": "Symbols (geometric)",
        "order": 63.5,
        "htmlcode": "&#9642"
    }
].sortBy('order');