// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  staging: false,
  production: false,
  api: {
    host: 'develop.notetracks.com',
    basePath: '/api/',
    version: '1.0.0'
  },
  partners: {
    appsumo: {
        displayName: "AppSumo",
        logo: "partner/appsumologo.jpg"
    }
  },
  fullstoryTag: ``,
  helpUrl: 'https://notetrackspro.zendesk.com/hc/en-us/categories/360003158871-General',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
