import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[match][ngFormControl],[match][ngControl],[match][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MatchValidatorDirective), multi: true }
  ]
})
export class MatchValidatorDirective {

  @Input() match: string = '';

  // private validator;

  validate(control: AbstractControl): ValidationErrors | null {
    const matchControl: AbstractControl = (control.parent?.controls as { [key: string]: AbstractControl })[this.match];
    if (matchControl){
      // tslint:disable-next-line: deprecation
      matchControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity({});
      });
    }
    return (control?.value || '') === (matchControl?.value || '') ? null : { match: true };
  }

}
