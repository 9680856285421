import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';

import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { ConfigService } from '@app/config.service';
import { AudioService } from '@app/core/audio';
import { AuthService } from '@app/features/auth';
import { ProjectManagerService } from '../../services';
import { UserState } from '../../types';

@Component({
    selector: 'nt-ruler',
    templateUrl: './ruler.component.html',
    styleUrls: ['./ruler.component.scss', './ruler-responsive.component.scss']
})
export class RulerComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {

    private readonly HOTKEY_MAP = [
        new Hotkey('+', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.pms.ui.incrementZoom(0.1);
            return true;
        }, undefined, 'Zoom In'),
        new Hotkey('-', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.pms.ui.incrementZoom(-0.1);
            return true;
        }, undefined, 'Zoom Out'),
        new Hotkey(['ctrl+r','command+r'], (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.pms.ui.setZoom(this.DEFAULT_ZOOM);
            return true;
        }, undefined, 'Reset Zoom'),
        new Hotkey('shift+right', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.onScroll(-10)
            return true;
        }, undefined, 'Scroll Right'),
        new Hotkey('shift+left', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.onScroll(10)
            return true;
        }, undefined, 'Scroll Left'),
        new Hotkey('f', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.pms.ui.isPlayheadAttached = !this.pms.ui.isPlayheadAttached;
            return true;
        }, undefined, 'Auto-Scroll On/Off'),
    ];

    private readonly DEFAULT_ZOOM = 5;

    @Input('projectId') projectId: number;
    // @Input('isPlayheadAttached') isPlayheadAttached: boolean = true;
    @Input('hasAllTracksCollapsed') hasAllTracksCollapsed: boolean;
    @Output('ready') ready: EventEmitter<boolean> = new EventEmitter();
    @Output('allTracksCollapsed') allTracksCollapsed: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('ruler') ruler: ElementRef;

    private _playheadOffset: number;
    public get playheadOffset(): number {
        return this._playheadOffset;
    }

    private _rulerUrl$$: BehaviorSubject<string> = new BehaviorSubject(undefined);
    public rulerUrl$: Observable<string> = this._rulerUrl$$.asObservable().pipe(filter((v => v !== undefined)), distinctUntilChanged());

    public get loopWidth(): number {
        return (this.audio.player.loop.end - this.audio.player.loop.start) / this.pms.ui.pixelDuration;
    }

    public get loopStartOffset(): number {
        return (this.audio.player.loop.start / this.pms.ui.pixelDuration) + this.pms.ui.scrollOffset
    }

    public get loopEndOffset(): number {
        return (this.audio.player.loop.end / this.pms.ui.pixelDuration) + this.pms.ui.scrollOffset
    }

    private _svg: SVGElement;
    private _userState$: Observable<UserState>;
    private _destroy: Subject<void> = new Subject();

    constructor(
        private readonly hotkeys: HotkeysService, protected auth: AuthService,
        public readonly audio: AudioService, public readonly config: ConfigService,
        public readonly pms: ProjectManagerService,
        public readonly dds: DeviceDetectorService,
        private readonly element: ElementRef,
    ) { }

    //#region Lifecycle
    ngOnInit(): void {
        if(!this.dds.isDesktop()) {
            this.pms.ui.setZoom(1);
        } else {
            this.pms.ui.setZoom(this.DEFAULT_ZOOM);
        }
        this.pms.ui.setWidth((this.element.nativeElement.clientWidth - 272) * this.DEFAULT_ZOOM);
        this.pms.ui.setScrollOffsetFactor(0);

        if (this.dds.isDesktop() && this.auth.currentUser) {
            this.pms.state.state$.subscribe((state: UserState) => {
                if(state.state.zoom) {
                    this.pms.ui.setZoom(state.state.zoom);
                }
            });
        }
        

        //#region WheelEvent Setup
        const wheelEvents$ = fromEvent<WheelEvent>(window, 'wheel', { passive: false });
        //#region Horizontal Scroll
        wheelEvents$.pipe(
            takeUntil(this._destroy),
            filter((event: WheelEvent) => {
                const noModifierKey = !event.altKey && !event.shiftKey && !event.ctrlKey && !event.metaKey;
                const isDeltaXMove = event.deltaX !== 0 && Math.abs(event.deltaX) > Math.abs(event.deltaY)
                return event.shiftKey || (noModifierKey && isDeltaXMove);
            }),
            tap((event: WheelEvent) => event.preventDefault()),
            map((event: WheelEvent): number => event.shiftKey ? event.deltaY : -event.deltaX),
        ).subscribe((value: number) => {
            this.onScroll(value / 2);
        });
        //#endregion
        //#region Zoom
        wheelEvents$.pipe(
            takeUntil(this._destroy),
            filter((event: WheelEvent) => event.ctrlKey),
            tap((event: WheelEvent) => event.preventDefault()),
            map((event: WheelEvent) => -Math.sign(event.deltaY))
        ).subscribe((increment: number) => {
            this.pms.ui.setZoom(this.pms.ui.zoom + (increment / 10))
        });
        //#endregion
        //#endregion

        // HOTKEYS
        this.hotkeys.add(this.HOTKEY_MAP);
        // currentTime$
        this.audio.player.currentTime$.pipe(takeUntil(this._destroy)).subscribe((currentTime: number) => {
            if (this.pms.ui.isPlayheadAttached) { this.setSvgOffsetByTime(currentTime); }
            this.setPlayheadOffsetByTime(currentTime);
        });
        // width$ & scrollOffset$
        combineLatest([this.pms.ui.width$, this.pms.ui.scrollOffsetFactor$]).pipe(takeUntil(this._destroy)).subscribe((results => {
            const [width, scrollOffset] = results;
            if(this._svg) this._svg.setAttribute('width', width + 'px');
            if(this._svg) this._svg.style.transform = `translateX(${scrollOffset * width}px)`;
            this.updatePlayheadOffset();
        }));
        // zoom$
        this.pms.ui.zoom$.pipe(takeUntil(this._destroy)).subscribe((zoom: number) => {
            this.setWidth(zoom);
        });
        // pixelDuration$
        this.pms.ui.pixelDuration$.pipe(takeUntil(this._destroy)).subscribe((pixelDuration) => {
            const hi = this.getRulerHiWidth(pixelDuration);
            this._rulerUrl$$.next(`https://${this.config.get('api').host}/api/1.0.0/graphics/ruler?duration=${this.pms.duration}&bgcolor=1A1A1A&locolor=1A1A1A&hicolor=808080&lo=1&hi=${hi}&fontsize=11`)
        });
    }

    ngAfterContentInit(): void {
        if (this.dds.isDesktop() && this.auth.currentUser) {
            this._userState$ = this.pms.state.state$;
            this._userState$.pipe(take(1)).subscribe((userState: UserState) => {
                if(userState?.state?.zoom) {
                    this.pms.ui.setZoom(userState?.state?.zoom);
                }
                // TODO: set global "volume" and "volume muted" here
            });
        }
    }

    ngAfterViewInit() {
        this.setWidth(this.pms.ui.zoom);
        const pixelDuration = this.pms.duration ? this.pms.ui.width / this.pms.duration : 0;
        setTimeout(() => {
            this._rulerUrl$$.next(`https://${this.config.get('api').host}/api/1.0.0/graphics/ruler?duration=${this.pms.duration}&bgcolor=1A1A1A&locolor=1A1A1A&hicolor=808080&lo=1&hi=${this.getRulerHiWidth(pixelDuration)}&fontsize=11`)
        });
    }

    ngOnDestroy() {
        this.HOTKEY_MAP.map((hk: Hotkey) => hk.combo).forEach((combo: string) => this.hotkeys.remove(this.hotkeys.get(combo)));
        this._destroy.next();
        this._destroy.complete();
    }
    //#endregion

    //#region Actions
    toggleAllTracksCollapsed() {
        this.allTracksCollapsed.emit(!this.hasAllTracksCollapsed);
    }

    setZoom(zoom: number) {
        this.pms.ui.setZoom(zoom);
        if (this.auth.currentUser) { this.pms.state.save(this.projectId, 'zoom', zoom).subscribe(); }
    }
    //#endregion

    //#region Helpers
    private setWidth(zoom: number) {
        if(this.ruler) {
            const newWidth = this.ruler.nativeElement.clientWidth * zoom;
            this.pms.ui.setWidth(newWidth);
        }
    }

    private getRulerHiWidth(pixelDuration) {
        const next = { 1: 5, 5: 10, 10: 15 }
        const secondWidth = 1 / pixelDuration;
        let hi, hiWidth;
        do {
            hi = !hi ? 1 : next[hi] || hi + 15;
            hiWidth = secondWidth * hi;
        } while (hiWidth < 80)

        return hi;
    }

    private updatePlayheadOffset() {
        this.audio.player.currentTime$.pipe(take(1), tap(t => this.setPlayheadOffsetByTime(t))).subscribe();
    }

    private setSvgOffsetByTime(currentTime: number) {
        const currentPixel: number = currentTime / this.pms.ui.pixelDuration;

        if (this.ruler instanceof ElementRef) {
            // If is beyond right edge
            if (currentPixel + this.pms.ui.scrollOffset > this.ruler.nativeElement.clientWidth * 0.9) {
                const newOffset = -currentPixel + (this.ruler.nativeElement.clientWidth * 0.5);
                this.pms.ui.setScrollOffsetFactor(Math.min(0, newOffset / this.pms.ui.width));
            }
            // If has past the left edge
            else if (-currentPixel > this.pms.ui.scrollOffset) {
                const newOffset = -currentPixel + (this.ruler.nativeElement.clientWidth * 0.10);
                this.pms.ui.setScrollOffsetFactor(Math.min(0, newOffset / this.pms.ui.width));
            }
        }

        if (this._svg) {
            this._svg.style.transform = `translateX(${this.pms.ui.scrollOffset}px)`;
        }
    }

    private setPlayheadOffsetByTime(currentTime: number): number {
        return this._playheadOffset = currentTime / this.pms.ui.pixelDuration + (this.pms.ui.scrollOffsetFactor * this.pms.ui.width);
    }

    private setLoopRange(range: { start?: number, end?: number }) {
        const loop = this.normalizeLoopRange(Object.assign({ start: 0, end: 0 }, this.audio.player.loop, range));
        this.audio.player.loop = loop;
        this.audio.player.looping(true);
    }

    private normalizeLoopRange(range: { start: number, end: number }): { start: number, end: number } {
        // Limit start
        range.start = Math.max(0, range.start);
        range.start = Math.min(this.pms.duration, range.start);
        // Limit end
        range.end = Math.max(0, range.end);
        range.end = Math.min(this.pms.duration, range.end);

        // Ensure start is before end
        if (range.start > range.end) {
            const { start, end } = range;
            range.start = end; range.end = start;
        }

        return range;
    }
    //#endregion

    //#region Event Listeners
    @HostListener('window:resize', ['$event'])
    public onResize(event: Event) {
        this.setWidth(this.pms.ui.zoom);
    }

    onScroll = (scroll: number) => {
        if (this.audio.player.isPlaying) { this.pms.ui.isPlayheadAttached = false; }
        const maxOffset = (this.ruler.nativeElement.clientWidth / 4) - this.pms.ui.width;
        const rulerOffset = Math.max(Math.min(0, this.pms.ui.scrollOffset + scroll), maxOffset);
        this.pms.ui.setScrollOffsetFactor(rulerOffset / this.pms.ui.width);
    }

    private setScroll(offset: number): void {
        if (this.audio.player.isPlaying) { this.pms.ui.isPlayheadAttached = false; }
        const maxOffset = (this.ruler.nativeElement.clientWidth / 4) - this.pms.ui.width;
        const rulerOffset = Math.max(Math.min(0, offset), maxOffset);
        // this._svg.style.transform = `translateX(${this.rulerOffset}px)`;
        this.pms.ui.setScrollOffsetFactor(rulerOffset / this.pms.ui.width);

        // this.audio.player.currentTime$.pipe(take(1), tap(t => this.setPlayheadOffsetByTime(t))).subscribe();
    }

    onRulerClicked(event: MouseEvent) {
        if (event.ctrlKey && !event.altKey && !event.shiftKey) {
            const start = event.offsetX * this.pms.ui.pixelDuration;
            this.setLoopRange({ start });
        } else if (event.altKey && !event.ctrlKey && !event.shiftKey) {
            const end = event.offsetX * this.pms.ui.pixelDuration;
            this.setLoopRange({ end });
        } else {
            this.audio.player.currentTime = event.offsetX * this.pms.ui.pixelDuration;
            this.pms.state.save(this.projectId, 'timecode', this.audio.player.currentTime).subscribe();
        }
    }

    onLoopControlDragStarted(event: CdkDragStart) {
        this.ruler.nativeElement.style.pointerEvents = 'none'
    }

    onLoopControlDragEnded(event: CdkDragEnd) {
        if (event.source.element.nativeElement.classList.contains('loop-range-handle-left')) {
            let start = this.audio.player.loop.start + (event.distance.x * this.pms.ui.pixelDuration);
            this.setLoopRange({ start });
        }
        if(event.source.element.nativeElement.classList.contains('loop-range-handle-right')) {
            let end = this.audio.player.loop.end + ((event.distance.x) * this.pms.ui.pixelDuration);
            this.setLoopRange({ end });
        }

        event.source.reset();
        this.ruler.nativeElement.style.pointerEvents = null
    }

    onSvgLoaded = (svg: SVGElement, parent: Element): SVGElement => {
        svg.setAttribute('width', (this.pms.ui.width || 0) + 'px');
        if(this.ruler) {
            // Since we prepend, we have to remove any exising SVG so they don't pile up when the URL changes
            const existingSvg = this.ruler.nativeElement.querySelector('svg');
            if(existingSvg) this.ruler.nativeElement.removeChild(existingSvg);
        }
        return svg;
    }

    onSvgInserted(svg: SVGElement) {
        this._svg = svg;
        combineLatest([this.pms.ui.width$, this.pms.ui.scrollOffsetFactor$]).pipe(take(1)).subscribe((results => {
            const [width, scrollOffset] = results;
            if(this._svg) this._svg.setAttribute('width', width + 'px');
            if(this._svg) this._svg.style.transform = `translateX(${scrollOffset * width}px)`;
        }));
        console.debug('Ruler ready!');
        this.ready.emit(true);
    }
    //#endregion
}
