import { IClip } from "./iclip";

export class Clip {
    /**
     * A unique ID v4
     */
    uuid: string;
    /**
     * Position of the clip on the project timeline
     */
    position: number;
    /**
     * Start of the clip in the media
     */
    start: number;
    /**
     * Duration of the clip
     */
    duration: number;

    constructor(data: IClip) {
        this.uuid = data.uuid;
        this.position = data.position;
        this.start = data.start;
        this.duration = data.duration;
    }
}
