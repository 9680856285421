import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';

import { LocalStorage } from 'ngx-webstorage';

import { BehaviorSubject, combineLatest, Observable, take } from 'rxjs';
import { ToastrService, IndividualConfig } from 'ngx-toastr';


import { AuthService } from '@app/features/auth/services';
import { AppComponent } from '@app/app.component';
import { ConfigService } from '@app/config.service';
import { Folder, FolderList, IProjectData, Project } from '../../types';
import { StoreService } from '../../services';
import { ProjectFormDialogComponent, ProjectShareDialogComponent } from '../../components';
import { projectResult } from '../../demo';
import { ConfirmationDialogComponent } from '@app/shared';
import { AuthUser } from '@app/features/auth';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PlanService } from '@app/features/auth/services/plan.service';
import { Usage } from '@app/features/auth/types/usage';
import { ApiService } from '@app/core';
import { FolderDataService } from '../../services/data/folder-data.service';
import { HttpClient } from '@angular/common/http';

type FilterString = string & ('' | 'recents' | 'shared');
type SortByString = string & ('name' | 'mediaType' | 'userId' | 'createdAt' | 'updatedAt');
interface Sort {
    by: SortByString;
    desc?: boolean;
}

@Component({
    selector: 'nt-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss', './project-list-progressive.component.scss']
})
export class ProjectListComponent implements OnInit {

    @LocalStorage('hideDemoProject') hideDemo: boolean;
    @LocalStorage('recentProjects') recentProjects: Array<number>;

    private _filter$$: BehaviorSubject<FilterString> = new BehaviorSubject('');
    public filter$: Observable<FilterString> = this._filter$$.asObservable();
    public setFilter(filter: FilterString) { this._filter$$.next(filter); }

    private _sort$$: BehaviorSubject<Sort> = new BehaviorSubject({ by: 'createdAt', desc: true });
    public sort$: Observable<Sort> = this._sort$$.asObservable();
    public setSort(sort: SortByString) {
        const s: Sort = { by: sort, desc: ['createdAt','updatedAt'].includes(sort) ? true : false };
        if(sort === this._sort$$.value.by) {
            s.desc = !this._sort$$.value.desc;
        }
        this._sort$$.next(s);
    }

    private _search$$: BehaviorSubject<string> = new BehaviorSubject('');
    public search$: Observable<string> = this._search$$.asObservable();
    public setSearch(search) { this._search$$.next(search); }

    private openedMenu: Project;
    public user$: Observable<AuthUser>;

    @ViewChild(MatMenuTrigger) userMenuTrigger: MatMenuTrigger;

    projects$: Observable<Array<Project>>;
    demo: Project;

    // tslint:disable-next-line: max-line-length
    constructor(
        private readonly router: Router, private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog, private readonly toast: ToastrService,
        private readonly parent: AppComponent, public readonly config: ConfigService,
        public readonly auth: AuthService, public readonly store: StoreService,
        private readonly plan: PlanService, public confirmationDialog: MatDialog,
        private readonly folder: FolderDataService, private readonly http: HttpClient
    ) { }

    //#region Lifecycle
    ngOnInit() {
        this.parent.title.setTitle('Notetracks - Projects');
        // combineLatest(this.folder.delete({ id: 6 })).subscribe();
        // const folder = new Folder({ name: 'The Sub Sub', parent_folder_id: 7 });
        // this.folder.save(folder.toPostableData(), {}).subscribe((f: Folder) => {
        //     console.log("Saved", f);
        // })

        // console.time('Transcription');
        // this.http.get(`${window.location.protocol.ctrim(':')}://${window.location.hostname}/assets/demo/transcription.json`).subscribe((result: any) => {
        //     console.log(result);
        //     const items = result.results.items;
        //     const pronunciationItems = items.filter(v => v.type === "pronunciation");
        //     const punctuationItems = items.filter(v => v.type === "punctuation");

        //     const segments = result.results.speaker_labels.segments;
        //     const segmentItems = segments.map(s => s.items.map(i => ({...i, segment: {...s, items: undefined}}))).reduce((sum, curr) => [...sum,...curr], []);
        //     const pronunciationSegmentItems = segmentItems.filter(v => v.type === "pronunciation");
        //     const punctuationSegmentItems = segmentItems.filter(v => v.type === "punctuation");
        //     console.log(
        //         'Pronunciation Items: ', pronunciationItems.length,
        //         'Punctuation Items: ', punctuationItems.length,
        //         'Segments: ', segments.length,
        //         'Segment Items: ', segmentItems.length,
        //         'First: ', pronunciationItems[0], segmentItems[0],
        //         'Ponctuations: ', punctuationItems
        //     );

        //     console.timeEnd('Transcription');
        // })


        // this.folder.list({}).pipe(take(1)).subscribe((folders: FolderList) => {
        //     folders.items.forEach((v: Folder) => {
        //         this.folder.list({ id: v.id }).subscribe((folders) => {
        //             folders.items.forEach((v: Folder) => {
        //                 this.folder.list({ id: v.id }).subscribe((folders) => {

        //                 })
        //             });
        //         })
        //     });
        // });

        this.projects$ = combineLatest([this.search$, this.filter$, this.sort$]).pipe(
            switchMap((values) => {
                const [search, filter, sort] = values;

                return this.store.project.list({}, sort.by, sort.desc).pipe(
                    switchMap((ops: Array<Observable<Project>>) => combineLatest(ops)),
                    map((projects: Array<Project>) => {
                        return projects.filter((project: Project) => {
                            return !!project.name.toLowerCase().match(search.toLowerCase())
                                && (filter !== 'shared' || project.userId !== this.auth.currentUser.id)
                                && (filter !== 'recents' || (this.recentProjects || []).includes(project.id))
                        });
                    })
                )
            })
        )

        if(!this.hideDemo) {
            this.demo = new Project(projectResult as IProjectData)
        }

        this.user$ = this.auth.user$.pipe(
            filter((user: AuthUser) => !!user?.plan?.name)
        );
    }
    //#endregion

    //#region Helpers
    //#endregion

    //#region Actions
    doNotificationClick() {
        console.warn('Notification Not Yet Implemented');
    }

    openProject(project: Project): void {
        if (this.openedMenu) { return; }
        console.debug(`Opening project #${project.id}`);
        if (project.status === 'duplicating') {
            this.toast.warning('This project is not ready yet, please try again after it\'s finished duplicating.'); //@todo i18n
            return;
        }

        const recents = this.recentProjects || [];
        recents.unshift(project.id);
        this.recentProjects = recents.filter((item, pos, array) => array.indexOf(item) === pos);

        this.router.navigate(['/project', project.publicKey]);
    }

    createProject(duplicateFrom?: number): void {
        if(this.auth.currentUser.isPlanExpired) {
            const msg = this.auth.currentUser.isPlanTrial
                ? 'The free trial for your account has expired. Please <a href="#/account/subscription">subscribe</a>.'
                : 'The plan for your account has expired. Please <a href="#/account/subscription">subscribe</a>.'

            this.toast.error(msg, undefined, { enableHtml: true, disableTimeOut: true });
            // this.router.navigate(['/account/subscription']);
            return;
        }

        this.plan.getUsage().subscribe((usage: Usage) => {
            if(!usage.hasProjectLeft) {
                const msg = `Project limit reached, please <a href="#/account/subscription">upgrade here</a> or delete existing projects.`;
                this.toast.error(msg, undefined, { enableHtml: true, disableTimeOut: true });
                // this.router.navigate(['/account/subscription']);
                return;
            }
            this.dialog.open(ProjectFormDialogComponent, {
                autoFocus: false,
                panelClass: 'project-form-dialog',
                width: '50%',
                data: {
                    project: { duplicate_from: duplicateFrom }
                }
                // tslint:disable-next-line: deprecation
            }).afterClosed().subscribe((project: Project) => {
                if (project) {
                    if(project.duplicatedFrom) {
                        this.toast.success('Project created successfully, copying data...'); //@todo i18n
                    } else {
                        this.toast.success('Project created successfully!'); //@todo i18n
                        this.router.navigate(['/project', project.publicKey]);
                    }
                }
            });
        });
    }

    editProject(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.dialog.open(ProjectFormDialogComponent, {
            autoFocus: false,
            panelClass: 'project-form-dialog',
            width: '50%',
            data: { project }
            // tslint:disable-next-line: deprecation no-shadowed-variable
        }).afterClosed().subscribe((project: Project) => {
            if (project) {
                this.toast.success('Project saved successfully!'); //@todo i18n
            }
        });
    }

    duplicateProject(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.createProject(project.id);
    }

    deleteProject(event: MouseEvent, project: Project) {
        event.stopPropagation();

        this.confirmationDialog.open(ConfirmationDialogComponent, {
            autoFocus: false,
            panelClass: 'confirmation-dialog',
            width: '300px',
            data: {
              title: 'Delete project',
              message: 'Are you sure you want to delete this project?',
              cancelLabel: 'Cancel',
              acceptLabel: 'Yes'
            }
          })
          .afterClosed()
          .subscribe((confirmed: boolean) => {
            if (confirmed) {
                if(project.id === -1 && project.publicKey === 'demo') {
                    this.hideDemo = true;
                    delete this.demo;
                }
                this.store.project.delete(project).subscribe(() => {
                    this.toast.success('Project delete successfully!'); //@todo i18n
                });
            } else {
              // do something else
            }
          });
    }

    shareProject(event: MouseEvent, project: Project) {
        event.stopPropagation();
        this.dialog.open(ProjectShareDialogComponent, {
            autoFocus: false,
            panelClass: 'project-share-dialog',
            width: '800px',
            data: { project }
        }).afterClosed().subscribe((project: Project) => {

        });
    }

    starProject(event: MouseEvent, project: Project) {
        event.stopPropagation();
        console.warn(`Starring project #${project.id} NOT IMPLEMENTED YET`); // @todo Implement, probably using Project's UserState for now
    }

    expandProject(event: MouseEvent, project: Project, ele: HTMLDivElement) {
        event.stopPropagation();
        console.warn(`Expanding project #${project.id} NOT SUPPORTED YET`);
        ele.classList.toggle('collapsed');
    }

    openProjectMenu(project: Project): void {
        this.openedMenu = project;
    }

    closeProjectMenu(project: Project): void {
        this.openedMenu = null;
    }
    //#endregion

}
