import { HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, EMPTY, Observable, Observer, of, Subject } from 'rxjs';
import { defaultIfEmpty, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';

import { AudioService, FileService } from '@app/core';
import { AuthService, MediaFilter } from '@app/features/auth';
import { Audionote, Clip, Color, Comment, Drawing, DrawingType, IProjectData, Media, Metadata, Project, UserState } from '../types';
import { ToolMode } from '../types/tool-mode';
import { StoreService } from './store.service';

type EventBusName = 'CreateComment' | 'CreateMarker';

@Injectable({
    providedIn: 'root'
})
export class ProjectManagerService {

    private _projectId: number;
    private _projectKey: string;
    private _duration: number; // We can use while we don't have a real value from audio player

    public duration$: Observable<number> = this.audio.player.duration$;
    public get duration(): number { return this.audio.player.duration || this._duration; }

    private _project$: Observable<Project>;
    public get project$(): Observable<Project> {
        return this._project$;
    }

    constructor(
        private readonly store: StoreService,
        private readonly file: FileService,
        private readonly auth: AuthService,
        private readonly audio: AudioService
    ) { }

    loadProject(projectKey) {
        const { publicKey, projectId } = Project.parseProjectKey(projectKey);
        this._projectId = projectId;
        this._projectKey = publicKey;

        this._project$ = this.store.project.get(this._projectId).pipe(
            filter<Project>(Boolean),
            tap((project: Project) => this._duration = this.audio.player.duration)
        );

        if (this.auth.currentUser) {
            this.state.state$ = this.store.userState.get(projectId);
        }

        this.media.list$ = this.store.media.list({ projectId, isMain: false }, 'sequence', false).pipe(
            switchMap((aom: Array<Observable<Media>>) => combineLatest(aom).pipe(defaultIfEmpty([] as Array<Media>)))
        );
    }

    //#region EventBus
    private static readonly EventBusService = class {
        private _eventBus$$: Subject<{ name: string, params: any }> = new Subject();
        constructor(private readonly manager: ProjectManagerService) { }
        trigger<T>(name: EventBusName, params?: T ) {
            this._eventBus$$.next({ name, params });
        }
        on<T>(name: EventBusName): Observable<T> {
            return this._eventBus$$.pipe(filter((v) => v.name === name), map((v) => v.params));
        }
    }
    public eventBus = new ProjectManagerService.EventBusService(this);
    //#endregion

    //#region UI
    private static readonly UiService = class {

        //#region Drawing Color
        private _drawingColor$$: BehaviorSubject<Color> = new BehaviorSubject('freedo');
        public drawingColor$: Observable<Color> = this._drawingColor$$.asObservable();
        public get drawingColor(): Color { return this._drawingColor$$.value; }
        public set drawingColor(color: Color) { this._drawingColor$$.next(color); }
        //#endregion

        //#region Drawing Tool
        private _drawingType$$: BehaviorSubject<DrawingType> = new BehaviorSubject('bongo');
        public drawingType$: Observable<DrawingType> = this._drawingType$$.asObservable();
        public get drawingType(): DrawingType { return this._drawingType$$.value; }
        public set drawingType(drawingType: DrawingType) { this._drawingType$$.next(drawingType); }
        //#endregion

        //#region Zoom
        public readonly MAX_ZOOM = 50;
        private _zoom$$: BehaviorSubject<number> = new BehaviorSubject(undefined);
        public zoom$: Observable<number> = this._zoom$$.asObservable().pipe(filter(v => v !== undefined), distinctUntilChanged());
        public get zoom(): number { return this._zoom$$.value; }
        public incrementZoom(increment: number) {
            const z = Math.min(this.MAX_ZOOM, Math.max(1, this._zoom$$.value + increment));
            this._zoom$$.next(z)
        }
        public setZoom(zoom: number) {
            const z = Math.min(this.MAX_ZOOM, Math.max(1, zoom));
            this._zoom$$.next(z);
        }
        //#endregion

        //#region Width
        private _width$$: BehaviorSubject<number> = new BehaviorSubject(undefined);
        public width$: Observable<number> = this._width$$.asObservable().pipe(filter(v => v !== undefined), distinctUntilChanged());
        public get width(): number { return this._width$$.value; }
        public setWidth(width: number) {
            this._width$$.next(Math.max(1,width));
        }
        //#endregion

        //#region ScrollOffset
        private _scrollOffsetFactor$$: BehaviorSubject<number> = new BehaviorSubject(undefined);
        public scrollOffsetFactor$: Observable<number> = this._scrollOffsetFactor$$.asObservable().pipe(filter(v => v !== undefined), distinctUntilChanged());
        public get scrollOffsetFactor(): number { return this._scrollOffsetFactor$$.value; }
        public scrollOffset$: Observable<number> = combineLatest([this.width$, this.scrollOffsetFactor$]).pipe(map((values: Array<number>) => {
            const [width, scrollOffsetFactor] = values;
            return width * scrollOffsetFactor
        }));
        public get scrollOffset(): number { return this._width$$.value * this._scrollOffsetFactor$$.value; }
        public setScrollOffsetFactor(scrollOffset: number) {
            this._scrollOffsetFactor$$.next(scrollOffset);
        }
        //#endregion

        //#region PixelDuration
        public pixelDuration$: Observable<number> = combineLatest([this.manager.duration$, this.width$]).pipe(
            map((values: Array<number>) => {
                const [duration, width] = values;
                return duration / (width /* zoom */);
            }), distinctUntilChanged()
        );
        public get pixelDuration(): number {
            return this.manager.audio.player.duration / (this._width$$.value /* zoom */);
        };
        //#endregion

        //#region VideoPlayer
        public readonly DEFAULT_VIDEO_HEIGHT = 320;
        private _videoDisplayFactor$$: BehaviorSubject<number> = new BehaviorSubject(1);
        public videoDisplayFactor$: Observable<number> = this._videoDisplayFactor$$.asObservable().pipe(distinctUntilChanged());
        public get video$(): Observable<Media> {
            return this.manager.media.list$.pipe(
                map((medias: Array<Media>) => {
                    return medias.find((m: Media) => { return m.mediaType == 'video'});
                }),
                distinctUntilChanged()
            )
        }
        // public get videoDisplayFactor(): number { return this._videoDisplayFactor$$.value; }
        public setVideoDisplayFactor(factor: number) {
            this._videoDisplayFactor$$.next(factor);
        }
        //#endregion

        //#region JournalMode
        private _journalMode$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
        public journalMode$: Observable<boolean> = this._journalMode$$.asObservable();
        public get journalMode(): boolean { return this._journalMode$$.value; }
        public set journalMode(journalMode: boolean) { this._journalMode$$.next(journalMode); }
        //#endregion

        //#region JournalMode
        private _toolMode$$: BehaviorSubject<ToolMode> = new BehaviorSubject(ToolMode.SELECT);
        public toolMode$: Observable<ToolMode> = this._toolMode$$.asObservable();
        public get toolMode(): ToolMode { return this._toolMode$$.value; }
        public set toolMode(toolMode: ToolMode) { this._toolMode$$.next(toolMode); }
        //#endregion

        //#region Selected Clip
        private _selectedClipUuid$$: BehaviorSubject<string> = new BehaviorSubject(undefined);
        public selectedClipUuid$: Observable<string> = this._selectedClipUuid$$.asObservable().pipe(distinctUntilChanged());
        public get selectedClipUid(): string { return this._selectedClipUuid$$.value; }
        public set selectedClipUid(uuid: string) { this._selectedClipUuid$$.next(uuid); }
        //#endregion

        //#region Selected Track
        private _selectedTrackUuid$$: BehaviorSubject<string> = new BehaviorSubject(undefined);
        public selectedTrackUuid$: Observable<string> = this._selectedTrackUuid$$.asObservable().pipe(distinctUntilChanged());
        public get selectedTrackUuid(): string { return this._selectedTrackUuid$$.value; }
        public set selectedTrackUuid(uuid: string) { this._selectedTrackUuid$$.next(uuid); }
        //#endregion

        //#region Playhead Attached
        private _isPlayheadAttached$$: BehaviorSubject<boolean> = new BehaviorSubject(true);
        public isPlayheadAttached$: Observable<boolean> = this._isPlayheadAttached$$.asObservable().pipe(distinctUntilChanged());
        public get isPlayheadAttached(): boolean { return this._isPlayheadAttached$$.value; }
        public set isPlayheadAttached(uuid: boolean) { this._isPlayheadAttached$$.next(uuid); }
        //#endregion

        //#region Clipboard
        private _clipboard$$: BehaviorSubject<any> = new BehaviorSubject(undefined);
        public clipboard$: Observable<any> = this._clipboard$$.asObservable().pipe(distinctUntilChanged());
        public get clipboard(): any { return this._clipboard$$.value; }
        public set clipboard(clipboard: any) { this._clipboard$$.next(clipboard); }
        //#endregion

        public get isSmallDesign(): boolean {
            return window.innerWidth <= 768;
        }

        public get mediaFilter$(): Observable<MediaFilter> {
            return this.manager.media.list$.pipe(
                switchMap((medias: Array<Media>) => {
                    return this.manager._project$.pipe(map((project: Project) => {
                        if(project.mediaType === 'video') {
                            return medias.filter((media: Media) => media.mediaType === 'video').length > 0 ? MediaFilter.Audio : MediaFilter.Video;
                        } else {
                            return MediaFilter.Audio;
                        }
                    }))
                })
            );
        }

        constructor(private readonly manager: ProjectManagerService) { }
    };
    public ui = new ProjectManagerService.UiService(this);
    //#endregion

    //#region UserState
    private static readonly UserStateService = class {
        public state$: Observable<UserState>;

        constructor(private readonly manager: ProjectManagerService) {}

        public save(id: number, name: string, value: any): Observable<UserState> {
            if (!this.manager.auth.currentUser) { return EMPTY; }
            return this.manager.store.userState.saveState(id, name, value) ;
        }
    };
    public state = new ProjectManagerService.UserStateService(this);
    //#endregion

    //#region Media
    private static readonly MediaService = class {
        public list$: Observable<Media[]>;

        private get lastSequence$(): Observable<number> {
            return this.list$.pipe(map((medias: Array<Media>) => {
                return (medias || [{sequence:0}]).map((m: Media) => m.sequence).reduce((sum: number, curr: number) => Math.max(sum, curr), 0);
            }));
        }

        constructor(private readonly manager: ProjectManagerService) {}

        public save(media: Partial<Media>, params: Record<string, any>): Observable<Media> {
            return this.manager.store.media.save(media, params);
        }

        public upload(item: Partial<Media>, file: File, params?: Record<string, any>): Observable<Media | HttpProgressEvent> {
            return this.manager.store.media.upload(item, file, params);
        }

        public createAndUpload(file: File, buffer: AudioBuffer): Observable<Media> {
            return this.manager.project$.pipe(take(1), switchMap((project: Project) => {
                return this.prepareMedia(file, buffer).pipe(tap((media: Media) => {
                    const params = { publicKey: project.publicKey };
                    this.save(media, params).pipe(take(1)).subscribe((savedMedia: Media) => {
                      const mediaUpload = new Media({ id: savedMedia.id, project_id: savedMedia.projectId });
                      this.upload(mediaUpload, file, params).subscribe();
                    });
                }));
            }));
        }

        prepareMedia(file: File, buffer: AudioBuffer): Observable<Media> {
            return combineLatest([this.manager.project$, this.lastSequence$]).pipe(
                take(1),
                map((values) => {
                    const [project, lastSequence] = values;
                    const blobUrl = URL.createObjectURL(file)

                    // return this.manager.audio.blobToBuffer(file).pipe(
                    //     map((buffer: AudioBuffer) =>
                            const clip = new Clip({ uuid: uuidv4(), position: 0, start: 0, duration: buffer.duration });
                            return new Media({
                                project_id: project.id,
                                project: project.toPostableData() as IProjectData,
                                name: file.name,
                                color: 'freedo',
                                duration: buffer.duration, // @todo
                                original_filesize: file.size,
                                sequence: lastSequence+1,
                                f_main: blobUrl,
                                f_main_dl: blobUrl,
                                is_main: false,
                                media_type: file.type.startsWith('video') ? 'video' : 'audio',
                                status: 'processed',
                                metadata:  new Metadata({ effects: [], clips: [clip]}),
                            })
                        // )
                    // );
                })
            );
        }
    };
    public media = new ProjectManagerService.MediaService(this);
    //#endregion

    //#region Demo
    public saveDemoProject(): Observable<number> {
        const projectId = -1;
        const project$: Observable<Project> = this.store.project.get(projectId).pipe(take(1));
        const medias$: Observable<Media[]> = this.store.media.list({ projectId }).pipe(
            switchMap((ms: Array<Observable<Media>>) => combineLatest(ms).pipe(defaultIfEmpty([])))
        );
        const comments$: Observable<Comment[]> = this.store.comment.list({ projectId }).pipe(
            switchMap((cs: Array<Observable<Comment>>) => combineLatest(cs).pipe(defaultIfEmpty([])))
        );
        const audionotes$ = this.store.audionote.list({ projectId }).pipe(
            switchMap((cs: Array<Observable<Audionote>>) => combineLatest(cs).pipe(defaultIfEmpty([])))
        );
        const drawings$ = this.store.drawing.list({ projectId }).pipe(
            switchMap((cs: Array<Observable<Drawing>>) => combineLatest(cs).pipe(defaultIfEmpty([])))
        );

        return combineLatest([project$,medias$,comments$,audionotes$,drawings$]).pipe(
          take(1),
          switchMap((v: [Project, Media[], Comment[], Audionote[], Drawing[]]) => {
              let [dProject, dMedias, dComments, dAudionotes, dDrawings] = v;

              // Remove Comments, Drawings, Audionotes from deleted items
              dComments = dComments.filter((c: Comment) => dMedias.map((m: Media) => m.id).includes(c.mediaId));
              dAudionotes = dAudionotes.filter((an: Audionote) => dComments.map((c: Comment) => c.id).includes(an.commentId));
              dDrawings = dDrawings.filter((d: Drawing) => dMedias.map((m: Media) => m.id).includes(d.mediaId));

              const dItems = 1 + dMedias.length + dComments.length + dAudionotes.length + dDrawings.length;
              let sItems = 0;

              return new Observable((observer: Observer<number>) => {
                  //#region Emits current progress
                  observer.next(sItems / dItems);
                  //#endregion
                  // Saving Project
                  const dProjectData = dProject.toPostableData();
                  delete dProjectData.id;
                  delete dProjectData.user_id;
                  const newProject = new Project(dProjectData);
                  this.store.project.save(newProject).subscribe((savedProject: Project) => {
                      //#region Emits current progress
                      sItems++;
                      observer.next(sItems / dItems);
                      if(sItems === dItems) observer.complete();
                      //#endregion
                      const publicKey = savedProject.publicKey;

                      // Saving Medias
                      dMedias.forEach((m: Media) => {
                          const dMediaData = m.toPostableData();
                          delete dMediaData.id;
                          dMediaData.project_id = savedProject.id;
                          const newMedia = new Media(dMediaData);
                          const uploadFile = m.fMainDl.protocol !== 'blob:' ? m.fMainDl : undefined;
                          this.store.media.save(newMedia, { publicKey, file: uploadFile }).subscribe((savedMedia: Media) => {
                              (m.fMainDl.protocol !== 'blob:'
                                  ? of(savedMedia)
                                  : this.file.downloadFile(m.fMainDl).pipe(
                                      map((blob: Blob) => new File([blob], m.name, { type: blob.type })),
                                      switchMap((file: File) => this.store.media.upload(new Media({ id: savedMedia.id, project_id: savedMedia.projectId }), file, { publicKey })),
                                      filter((v: Media | HttpProgressEvent) => v instanceof Media),
                                      // switchMap((v: Media) => this.store.media.getUploadProgress(v.id).pipe(
                                      //     last(),
                                      //     filter((v2: HttpProgressEvent & { status: string }) => v2.status === 'processed'),
                                      //     map((v2: HttpProgressEvent & { status: string }) => v)
                                      // )),
                                  )
                              ).subscribe((savedMedia: Media) => {
                                  //#region Emits current progress
                                  sItems++;
                                  observer.next(sItems / dItems);
                                  if(sItems === dItems) observer.complete();
                                  //#endregion

                                  // Saving Comments
                                  dComments.filter((c: Comment) => c.mediaId === m.id && !c.parentId).forEach((c: Comment) => {
                                      const dCommentData = c.toPostableData();
                                      delete dCommentData.id;
                                      dCommentData.project_id = savedMedia.projectId;
                                      dCommentData.media_id = savedMedia.id;
                                      const newComment = new Comment(dCommentData);
                                      this.store.comment.save(newComment, { publicKey }).subscribe((savedComment: Comment) => {
                                          //#region Emits current progress
                                          sItems++;
                                          observer.next(sItems / dItems);
                                          if(sItems === dItems) observer.complete();
                                          //#endregion

                                          // Saving Audionotes
                                          dAudionotes.filter((an: Audionote) => an.commentId === c.id).forEach((an: Audionote) => {
                                              const dAudionoteData = an.toPostableData();
                                              delete dAudionoteData.id;
                                              dAudionoteData.project_id = savedMedia.projectId;
                                              dAudionoteData.comment_id = savedComment.id;
                                              const newAudionote = new Audionote(dAudionoteData);
                                              this.store.audionote.save(newAudionote, { publicKey }).subscribe((savedAudionote: Audionote) => {
                                                  this.file.downloadFile(an.fAudio).pipe(
                                                      map((blob: Blob) => new File([blob], m.name, { type: blob.type })),
                                                      switchMap((file: File) => this.store.audionote.upload(new Audionote({ id: savedAudionote.id, project_id: savedAudionote.projectId }), file, { publicKey })),
                                                  ).subscribe((savedAudionote: Audionote) => {
                                                      //#region Emits current progress
                                                      sItems++;
                                                      observer.next(sItems / dItems);
                                                      if(sItems === dItems) observer.complete();
                                                      //#endregion
                                                  });
                                              });
                                          });

                                          // @todo Saving Reactions


                                          // Saving Replies
                                          dComments.filter((r: Comment) => r.mediaId === m.id && r.parentId === c.id).forEach((r: Comment) => {
                                              const dReplyData = r.toPostableData();
                                              delete dReplyData.id;
                                              dReplyData.project_id = savedMedia.projectId;
                                              dReplyData.media_id = savedMedia.id;
                                              dReplyData.parent_id = savedComment.id;
                                              const newReply = new Comment(dReplyData);
                                              this.store.comment.save(newReply, { publicKey }).subscribe((savedReply: Comment) => {
                                                  //#region Emits current progress
                                                  sItems++;
                                                  observer.next(sItems / dItems);
                                                  if(sItems === dItems) observer.complete();
                                                  //#endregion

                                                  // Saving Audionotes
                                                  dAudionotes.filter((an: Audionote) => an.commentId === r.id).forEach((an: Audionote) => {
                                                      const dAudionoteData = an.toPostableData();
                                                      delete dAudionoteData.id;
                                                      dAudionoteData.project_id = savedMedia.projectId;
                                                      dAudionoteData.comment_id = savedReply.id;
                                                      const newAudionote = new Audionote(dAudionoteData);
                                                      this.store.audionote.save(newAudionote, { publicKey }).subscribe((savedAudionote: Audionote) => {
                                                          this.file.downloadFile(an.fAudio).pipe(
                                                              map((blob: Blob) => new File([blob], m.name, { type: blob.type })),
                                                              switchMap((file: File) => this.store.audionote.upload(new Audionote({ id: savedAudionote.id, project_id: savedAudionote.projectId }), file, { publicKey })),
                                                          ).subscribe((savedAudionote: Audionote) => {
                                                              //#region Emits current progress
                                                              sItems++;
                                                              observer.next(sItems / dItems);
                                                              if(sItems === dItems) observer.complete();
                                                              //#endregion
                                                          });
                                                      });
                                                  });

                                                  // @todo Saving Reactions
                                              });
                                          });

                                          // Saving Drawings
                                          dDrawings.filter((d: Drawing) => d.mediaId === m.id).forEach((d: Drawing) => {
                                              const dDrawingData = d.toPostableData();
                                              delete dDrawingData.id;
                                              dDrawingData.project_id = savedMedia.projectId;
                                              dDrawingData.media_id = savedMedia.id;
                                              const newDrawing = new Drawing(dDrawingData);
                                              this.store.drawing.save(newDrawing, { publicKey }).subscribe(() => {
                                                  //#region Emits current progress
                                                  sItems++;
                                                  observer.next(sItems / dItems);
                                                  if(sItems === dItems) observer.complete();
                                                  //#endregion
                                              });
                                          });
                                      });
                                  });
                              })
                          });
                      });
                  });
              });
          })
        );
    }
    //#endregion
}
