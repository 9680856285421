<nt-app-toolbar [user]="user$ | async"></nt-app-toolbar>

<div class="main-title">Profile</div>

<div class="profile">
    <label for="fullName">Full name</label>
    <div class="fullName">
        <input #txtFirstName type="text" name="fullName" [(ngModel)]="firstName" placeholder="First name" (click)="edit($event)">
        <span>&nbsp;&nbsp;&nbsp;</span>
        <input type="text" [(ngModel)]="lastName" placeholder="Last name" (click)="edit($event)">
    </div>
    <mat-checkbox class="check" (click)="edit($event)" [(ngModel)]="receiveNotifications">Email notifications</mat-checkbox>

    <div class="buttons">
        <button #editButton class="medium" *ngIf="!editing" (click)="edit($event)">Edit</button>
        <button class="medium green" *ngIf="editing" [disabled]="firstName == '' || lastName == ''" (click)="save()">Save</button>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <button *ngIf="editing" class="linkButton" (click)="cancel()">Cancel</button>
    </div>
</div>
