import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, AuthUser, Plan, plansWording } from '@app/features/auth';
import { PlanService } from '@app/features/auth/services/plan.service';
import { SubscriptionService } from '@app/features/auth/services/subscription.service';
import { Usage } from '@app/features/auth/types/usage';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'nt-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss','./plan-mobile.component.scss']
})
export class PlanComponent implements OnInit {

  @Input('plans') plans: Array<Plan>;
  @Input('period') period: string; // month / year
  
  public isProcessing: boolean = false;
  public planTitle: string = '';
  public expirationDate: string;
  public user$: Observable<Readonly<AuthUser>>;
  private usage$: Observable<Usage> = null;
  public usage: Usage = null;
  public showTraffic: boolean = false;
  public allPlansWording: any;

  constructor(
    public auth: AuthService,
    protected planService: PlanService,
    private readonly subscription: SubscriptionService,
    private router: Router,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.user$ = this.auth.user$ // necessary to get the user.plan.name attribute
    this.allPlansWording = plansWording;

    this.user$.subscribe((user: AuthUser) => {
        if (user) {      
          this.planTitle = user.partnerPlan.sequence + ' ' + (user.partnerPlan.sequence == 1 ? 'Code' : 'Codes');
      
          if (user.isPartner) {
            if(user.isAnnualSubscription) {
              if (user.planExpiry) {
                let expiration = new Date(user.planExpiry);
                this.expirationDate = expiration.getDate() + "-" + (expiration.getMonth() + 1) + "-" + expiration.getFullYear();
              } else {
                let expiration = user.isAnnualSubscription ? this.addDays(new Date(user.plan.createdAt), user.plan.ttl) : null;
                this.expirationDate = expiration.getDate() + "-" + (expiration.getMonth() + 1) + "-" + expiration.getFullYear();
              }
            }
          }
        }
    });

    if (this.auth.currentUser) {
      this.usage$ = this.planService.getUsage();

      this.usage$.subscribe((theUsage: Usage) => {
        this.usage = theUsage;
      });
    }
  }

  getPlanAbreviation(planId: number): string {
    switch (planId) {
      case 2: case 3: case 4: return 'mo';
      case 25: case 26: case 27: return 'yr'
    }

    return 'mo';
  }

  getPlanWarning(plan:Plan): string {
    let warning: string = '';

    if (this.usage) {
      let projectLimitSurpassed = plan.limitProject && (this.usage.current.project > plan.limitProject);
      let projectStorageSurpassed = plan.limitStorage && (this.usage.current.storage > plan.limitStorage);

      if (projectLimitSurpassed && projectStorageSurpassed) {
        warning = 'You cannot select this plan because you have currently ' + this.usage.current.project + ' projects created and surpassed the limit of ' + (plan.limitStorage / 1024) + 'GB of storage.';
      } else if (projectLimitSurpassed) {
        warning = 'You cannot select this plan because you have currently ' + this.usage.current.project + ' projects created.';
      } else if (projectStorageSurpassed) {
        warning = 'You cannot select this plan because you have surpassed the limit of ' + (plan.limitStorage / 1024) + 'GB of storage.';
      } else {
        warning = '';
      }
    }

    return warning;
  }

  addDays(theDate: Date, days: number): Date {
    theDate.setDate(theDate.getDate() + days);
    return theDate;
  }

  upgrade(planId: number, event: MouseEvent): void {
    this.isProcessing = true;
    this.subscription.upgradePlan(planId).subscribe((plan: Plan) => {
      this.isProcessing = false;
      this.toast.success('Subscription plan upgraded successfully.');
      this.router.navigate(['project','list']);
    });
  }

  downgrade(planId: number, event: MouseEvent): void {
    this.isProcessing = true;
    this.subscription.downgradePlan(planId).subscribe((plan: Plan) => {
      this.isProcessing = false;
      this.toast.success('Subscription plan downgraded successfully.');
      this.router.navigate(['project','list']);
    });
  }

  goToPro(): void {
    this.router.navigate(['/auth/register'], {});
  }

  extraOptions(event: MouseEvent): void {
    if (event.altKey) {
      this.showTraffic = !this.showTraffic;
    }
  }

}
