import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, AuthUser } from '@app/features/auth';
import { SubscriptionService } from '@app/features/auth/services/subscription.service';
import { FormComponent } from '@app/shared';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { countries } from '../../../project/types';

@Component({
  selector: 'nt-edit-billing-dialog',
  templateUrl: './edit-billing-dialog.component.html',
  styleUrls: ['./edit-billing-dialog.component.scss']
})
export class EditBillingDialogComponent extends FormComponent implements OnInit, AfterViewInit {

  public isProcessing: boolean = false;
  public isCheckingPromocode: boolean = false;
  public selectedCountry: string;
  public selectedRegion: string;
  public promocode: string;
  public allCountries: Array<any>;
  public countryRegions: Array<any>;
  public billingForm: FormGroup;

  get txtAddress1() { return this.billingForm.get('txtAddress1'); }
  get txtAddress2() { return this.billingForm.get('txtAddress2'); }
  get txtCity() { return this.billingForm.get('txtCity'); }
  get txtPostalCode() { return this.billingForm.get('txtPostalCode'); }

  //#region Stripe
  public cardOptions: StripeCardElementOptions;
  public elementsOptions: StripeElementsOptions;
  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  //#endregion end

  constructor(
    private readonly subscription: SubscriptionService,
    private readonly stripe: StripeService,
    private readonly toast: ToastrService,
    private readonly auth: AuthService,
    private readonly dialogRef: MatDialogRef<EditBillingDialogComponent>, 
  ) { 
    super();
    this.cardOptions = {
      hidePostalCode: true,
      style: {
        base: {
          backgroundColor: '#212121',
          iconColor: '#666EE8',
          color: '#fff',
          fontWeight: '500',
          fontFamily: 'Inter, "Theinhardt", Roboto, "Open Sans", sans-serif',
          fontSize: '14px',
          '::placeholder': {
            color: '#808080'
          }
        }
      }
    } as StripeCardElementOptions;

    this.elementsOptions = {
      locale: 'auto'
    } as StripeElementsOptions;
  }

  ngOnInit(): void {
    this.allCountries = countries;

    this.billingForm = new FormGroup({
        txtAddress1: new FormControl(this.auth.currentUser.billingAddress1, Validators.required),
        txtAddress2: new FormControl(this.auth.currentUser.billingAddress2),
        txtCity: new FormControl(this.auth.currentUser.billingCity, Validators.required),
        txtPostalCode: new FormControl(this.auth.currentUser.billingPostalcode),
    });

    if (this.auth.currentUser.billingCountry) {
      this.selectedCountry = this.auth.currentUser.billingCountry;
    } else {
      this.selectedCountry = 'CA';
    }
    
    this.getRegions(null, this.selectedCountry);

    this.auth.user$.subscribe((usr: AuthUser) => {});
  }

  ngAfterViewInit(): void {
    this.selectedRegion = this.auth.currentUser.billingRegion;
  }

  public getRegions(event: Event, countryCode: string): void {
    let country = this.allCountries.find((c: any) => { return c.countryShortCode == countryCode });

    if (country) {
      this.countryRegions = country.regions;
    } else {
      this.countryRegions = [];
    }
  }

  public cardFormIsComplete(): boolean {
    return document.querySelector('ngx-stripe-card div').classList.contains('StripeElement--complete');
  }

  public formIsValid(): boolean {
    return this.billingForm.valid && this.cardFormIsComplete();
  }

  submit(): void {
    if (!this.formIsValid()) {
      this.stripe.createToken(this.card.element).subscribe((result) => {});
      return;
    }
     
    this.isProcessing = true;
    
    if (this.promocode) {
      this.subscription.validatePromocode(this.promocode).subscribe((data: any) => {
        let coupon = data.result;
        this.updateBillingInfo(coupon.valid);
      });
    } else {
      this.updateBillingInfo(false);
    }
  }

  private updateBillingInfo(validCoupon: boolean): void {
    this.stripe.createToken(this.card.element).subscribe((result) => {
      if (result.token) {
        let apiForm = this.getFormData(result.token.id);
        if (!validCoupon) {
          apiForm.stripe_promocode = '';
        }
        this.subscription.updateBilling(apiForm).subscribe(() => {
          this.toast.success('Billing info updated successfully.');
          this.isProcessing = false;
          this.dialogRef.close();
        }, (error) => {
          this.isProcessing = false;
          this.toast.error(error.error.notice.message);
        });
      }
    });
  }

  private getFormData(stripeToken: string): any {
    return { 
      billing_address1: this.txtAddress1.value,
      billing_address2: this.txtAddress2.value,
      billing_city: this.txtCity.value,
      billing_country: this.selectedCountry,
      billing_postalcode: this.txtPostalCode.value,
      billing_region: this.selectedRegion,
      plan_id: this.auth.currentUser.plan.id,
      stripe_token: stripeToken,
      stripe_promocode: this.promocode
    };
  }

  checkPromoCode(): void {
    if (this.promocode) {
      this.isCheckingPromocode = true;
      this.subscription.validatePromocode(this.promocode).subscribe((data: any) => {
        let coupon = data.result;
        if (coupon.valid) {
          this.isCheckingPromocode = false;
          this.toast.success('Coupon OK.');
        }
      }, (error) => {
        this.isCheckingPromocode = false;
        this.toast.error(error.error.notice.message);
      });
    }
  }

}
