import { ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';

import { LocalStorage } from 'ngx-webstorage';
import { combineLatest, Observable, of } from 'rxjs';
import { defaultIfEmpty, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from '@app/features/auth';
import { ProjectManagerService, StoreService, AudionoteComponent, CommentComponent } from '@app/features/project/project.module';
import { Audionote, Comment, IUserData, Project } from '@app/features/project/types';

@Component({
    selector: 'nt-comment-thread',
    templateUrl: './comment-thread.component.html',
    styleUrls: ['./comment-thread.component.scss']
})
export class CommentThreadComponent implements OnInit, AfterViewInit {

    @LocalStorage('anonymous-name')
    public anonymousName: string;

    @Input('comment') comment: Comment;
    @Input('search') search$: Observable<string> = of('');
    @Input('filter') filter$: Observable<{ userId?: number, isResolved?: boolean }> = of({})
    @Input('sortReplies') sort$: Observable<{ by?: string, desc?: boolean }> = of({ by: 'createdAt', desc: false });
    @HostBinding('class.collapseReply') @Input('collapseReply') collapseReply: boolean;
    @Input('focusReply') focusReply: boolean;

    @Output('cancel') cancel: EventEmitter<void> = new EventEmitter();

    @HostBinding('hidden') isHidden: boolean = false;

    @ViewChild('commentRef') commentRef: CommentComponent;
    @ViewChild('authorName') authorName: ElementRef<HTMLInputElement>;
    @ViewChild('authorEmail') authorEmail: ElementRef<HTMLInputElement>;
    @ViewChild('textarea') textarea: ElementRef<HTMLTextAreaElement>;
    @ViewChild('textareaControl') textareaControl: ElementRef<HTMLTextAreaElement>;
    @ViewChild('audionote') audionote: AudionoteComponent;
    @ViewChild('postRef') postRef: ElementRef<HTMLButtonElement>;

    public replies$: Observable<Array<Comment>>;
    public isSavingReply: boolean;
    public hideAudionote: boolean;

    constructor(private readonly pms: ProjectManagerService, private readonly store: StoreService, public readonly auth: AuthService) { }

    ngOnInit(): void {
        this.replies$ = combineLatest([this.search$, this.filter$, this.sort$.pipe(map((sort) => sort.by === 'timecode' ? { by: 'createdAt', desc: false } : sort))]).pipe(
            switchMap((values) => {
                const [search, filter, sort] = values;
                return this.store.comment.list({ projectId: this.comment.projectId, mediaId: this.comment.mediaId, parentId: this.comment.id, ...filter }, sort.by, sort.desc).pipe(
                    switchMap((aor: Array<Observable<Comment>>) => combineLatest(aor).pipe(
                        defaultIfEmpty([] as Array<Comment>),
                        map((replies: Array<Comment>) => {
                            if(filter.userId) {
                                replies = replies.filter((reply: Comment) => reply.userId === filter.userId)
                            }
                            if (search) {
                                replies = replies.filter((reply: Comment) => reply.comment.toLowerCase().includes(search.toLowerCase()));
                            }

                            return replies;
                        }),
                        tap((replies: Array<Comment>) => {
                            if(replies.length && filter.isResolved === undefined) { // If we have a isResolved filter set, we don't care even if they're replies
                                this.isHidden = false;
                            } else if((!filter.userId || this.comment.userId === filter.userId)
                                && (filter.isResolved === undefined || this.comment.isResolved === filter.isResolved)
                                && (!search || this.comment.comment.toLowerCase().includes(search.toLowerCase()))) {
                                this.isHidden = false;
                            } else {
                                this.isHidden = true;
                            }
                        })
                    )),
                );
            }),
            shareReplay()
        );
    }

    ngAfterViewInit(): void {

    }

    trackById(index, item: Comment) {
        return item.id;
    }

    //#region Event Handlers
    onTextAreaChange(event) {
        const target = event.target as HTMLElement;
        target.style.height = 'auto';
        target.style.height = (target.scrollHeight) + 'px';
    }

    onKeypress(event: KeyboardEvent) {
      if(event.which === ENTER && !event.shiftKey) {
          this.onPost(this.textarea.nativeElement.value);
          event.preventDefault();
      }
    }

    onCancel() {
        this.textarea.nativeElement.value = '';
        this.textarea.nativeElement.blur();
        this.cancel.emit();
    }

    onPost(text: string, anonName?: string, anonEmail?: string) {
        this.isSavingReply = true;
        let reply = new Comment({
            comment: text,
            media_id: this.comment.mediaId,
            project_id: this.comment.projectId,
            parent_id: this.comment.id,
            user_id: this.auth?.currentUser?.id,
            user: this.auth?.currentUser?.toPostableData() as IUserData,
            anon_name: this.auth.currentUser ? null : anonName,
            reactions: { counts: {}, mine: []},
            updated_at: new Date().toISOString(),
            created_at: new Date().toISOString(),
        });

        const audionoteFile = this.audionote?.audioFile
        this.pms.project$.pipe(take(1)).subscribe((project: Project) => {
            this.store.comment.save(reply, { publicKey: project.publicKey }).subscribe((replySaved: Comment) => {
                this.textarea.nativeElement.value = '';
                this.textarea.nativeElement.blur();
                this.hideAudionote = true;

                //#region HACKISH Way to bring reply into view
                const context = this.commentRef.element.nativeElement.closest('nt-comment-thread').parentElement.parentElement.tagName.toLowerCase();
                const [containerIdType, _] = this.commentRef.element.nativeElement.id.split('-');
                const replyRefId = `${containerIdType}-${replySaved.uuid}`;

                if(context === 'nt-comment-thread-dialog') {
                    setTimeout(() => document.getElementById(replyRefId).scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' }), 150);
                } else {
                    // setTimeout(() => this.postRef.nativeElement.focus());
                    setTimeout(() => this.postRef.nativeElement.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' }), 150);
                }
                //#endregion

                if (replySaved.id > 0 && audionoteFile) {
                    const newAudionote = new Audionote({
                        project_id: replySaved.projectId,
                        comment_id: replySaved.id,
                        f_audio: URL.createObjectURL(audionoteFile),
                        anon_token_id: replySaved.anonTokenId
                    });

                    this.store.audionote.save(newAudionote, { publicKey: project.publicKey }).subscribe((savedAudionote: Audionote) => {
                        if (savedAudionote.id > 0) {
                            let existingAudionote = new Audionote({ id: savedAudionote.id, project_id: replySaved.projectId });
                            this.store.audionote.upload(existingAudionote, audionoteFile, { publicKey: project.publicKey }).subscribe(() => {
                                this.hideAudionote = false;
                                this.store.audionote.list({ projectId: replySaved.projectId, commentId: replySaved.id }).pipe(
                                    take(1), switchMap((notes: Observable<Audionote>[]) => combineLatest(notes))
                                ).subscribe();
                            });
                        }
                    });
                }
            }).add(() => this.isSavingReply = false );
        });
    }
    //#endregion

}
