import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, EventEmitter, AfterContentInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpProgressEvent } from '@angular/common/http';

import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, defaultIfEmpty, first, map, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';

import { AudioService } from '@app/core';
import { AuthService, MediaFilter } from '@app/features/auth';

import { Media, Project, UserState, ProgressInfo, Clip, Metadata } from '../../types';
import { RulerComponent } from '../ruler/ruler.component';
import { TrackComponent } from '../track/track.component';
import { TrackAddDialogComponent } from '../track/track-add-dialog/track-add-dialog.component';
import { ProjectManagerService, StoreService } from '../../project.module';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'nt-sequencer',
  templateUrl: './sequencer.component.html',
  styleUrls: ['./sequencer.component.scss', './sequencer-responsive.component.scss']
})
export class SequencerComponent implements OnInit, OnDestroy, AfterViewInit {

  @HostBinding('class.video') get classVideo(): boolean { return this.medias.find((m: Media) => { return m.mediaType == 'video'}) instanceof Media; }

  @Input('project') project: Project;
  @Input('medias') medias: Array<Media> = [];
  @Output('downloadProgressChange') downloadProgressChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('ruler') readonly ruler: RulerComponent;
  @ViewChildren('track') readonly tracks: QueryList<TrackComponent>;
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

  private arrDownloadProgress: Array<ProgressInfo> = [];
  @Output('mediasBeingLoadedChange') mediasBeingLoadedChange: EventEmitter<ProgressInfo> = new EventEmitter();

  protected userState$: Observable<UserState>;

  /**
   * Whether the audio player is initialized
   */
  private _isAudioPlayerInitialized: boolean;
  public get isAudioPlayerInitialized(): boolean {
    return this._isAudioPlayerInitialized;
  }

  private _isRulerReady: boolean;
  public get isRulerReady(): boolean {
    return this._isRulerReady;
  }
  public set isRulerReady(value: boolean) {
    this._isRulerReady = value;
    this.cdr.detectChanges();
  }

  /**
   * Whether all the tracks are ready to play
   */
  private _isTracksReady: boolean;

  /**
   * Whether the sequencer is ready
   */
  public get isReady(): boolean {
    return this._isAudioPlayerInitialized && this._isRulerReady && this._isTracksReady;
  }

  private _hasAllTracksCollapsed$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasAllTracksCollapsed: Observable<boolean> = this._hasAllTracksCollapsed$$.asObservable();

  /**
   * Emits on detroy to auto unsub using takeUntil
   */
   private _destroy: Subject<void> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: StoreService,
    public readonly audio: AudioService,
    private readonly dialog: MatDialog,
    protected auth: AuthService,
    public readonly pms: ProjectManagerService
  ) { }

  //#region Lifecycle
  ngOnInit() {
    this._isAudioPlayerInitialized = this.audio.player.initialize(this.project.uuid);
    this.audio.player.state$.pipe(takeUntil(this._destroy)).subscribe(state => { this.pms.ui.isPlayheadAttached = true; });

    this.audio.player.duration$.subscribe();

    //https://local.notetracks.com/#/project/81f_jzZSoh5Kv9iUTfOdtLqb-fjzjNYEjPrMNzXwxD1Gj?t=4.976&loop=4.976,8.174
    this.route.queryParams.pipe(takeUntil(this._destroy)).subscribe((params: Params) => {
        const time = parseFloat(params?.t);
        if(time) {
            this.audio.player.currentTime = time;
        }
        const loop = (params?.loop || '').split(',').filter(v => !!v);
        if(loop.length == 2) {
            this.audio.player.loop = loop;
            this.audio.player.looping(true);
        }
    });

    if (this.auth.currentUser) {
      this.userState$ = this.pms.state.state$;
    }
  }

  ngAfterViewInit(): void {
    if (this.videoPlayer) {
      this.audio.player.speed$.pipe(takeUntil(this._destroy)).subscribe((speed: number) => {
        this.videoPlayer.nativeElement.playbackRate = speed;
      });
      this.audio.player.playing$.pipe(takeUntil(this._destroy)).subscribe((playing: boolean) => {
        if (playing) {
          this.videoPlayer.nativeElement.play();
        } else {
          this.videoPlayer.nativeElement.pause();
        }
      });

      this.audio.player.currentTime$.pipe(takeUntil(this._destroy),throttleTime(5)).subscribe((currentTime: number) => {
            const delta = Math.abs(this.videoPlayer.nativeElement.currentTime - this.audio.player.currentTime);
            if(delta > 0.250) {
                this.videoPlayer.nativeElement.currentTime = currentTime;
            }
      });
    }

    setTimeout(() => {
        this.tracks.changes.pipe(
            takeUntil(this._destroy),
            switchMap((tracks: QueryList<TrackComponent>) => {
                return combineLatest(tracks.map((track: TrackComponent) => track.isCollapsed$)).pipe(
                    map((tracks: Array<boolean>) => tracks.reduce((sum, curr) => sum && curr, true))
                )
            })
        ).subscribe((hasAllTracksCollapsed: boolean) => this._hasAllTracksCollapsed$$.next(hasAllTracksCollapsed));
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
  //#endregion

  trackByMediaId(index: number, media: Media) {
    return media.id;
  }

  //#region Actions
  toggleAllTracksCollapsed(collapsed: boolean) {
    this.tracks.forEach((track: TrackComponent) => track.toggleIsCollapsed(collapsed));
  }
  //#endregion

  //#region Event Listener
//   @HostBinding('onFilesDropped')
  public onFilesDropped(files: File[]) {
    this.pms.ui.mediaFilter$.pipe(take(1)).subscribe((mediaFilter: MediaFilter) => {
        this.dialog.open(TrackAddDialogComponent, {
            autoFocus: false,
            panelClass: 'track-add-dialog',
            width: '700px',
            data: {
                project: this.project,
                mediaFilter: mediaFilter,
                allowMultiple: true,
                files
            }
        }).afterClosed().subscribe((files: Array<{ file: File, buffer: AudioBuffer }>) => {
            if(files) {
                files.forEach((file: { file: File, buffer: AudioBuffer }) => this.pms.media.createAndUpload(file.file, file.buffer).subscribe());
            }
        });
    });
  }
  public onDrop(event) {
    const { previousIndex, currentIndex } = event;

    if(currentIndex === previousIndex) return;

    const previousIdSequence = this.medias.reduce((sum, curr) => { sum[curr.id] = curr.sequence; return sum; }, {});

    moveItemInArray(this.medias, event.previousIndex, event.currentIndex);

    const currentIdOrder = this.medias.map(m=>  ({ id: m.id, sequence: m.sequence }));

    // Update Media sequence to match new order when necessary
    currentIdOrder.map((m, index, array) => {
      // First match next sequence if this sequence is greater or equal to the next one
      if(index < array.length - 1 && m.sequence >= array[index+1].sequence) {
        m.sequence = array[index+1].sequence;
      }
      // Otherwise, set sequence to the previous sequence + 1 if this sequence is smaller or equal to the previous
      else if(index > 0 && m.sequence <= array[index-1].sequence) {
        m.sequence = array[index-1].sequence + 1;
      }
      return m;
    });

    // Find medias that had need to have their sequence updated
    const mediasToUpdate = currentIdOrder.filter(m => previousIdSequence[m.id] !== m.sequence).map((m, i) => {
      const media = this.medias.filter((value) => value.id === m.id).reduce((sum, curr) => curr);
      media.sequence = m.sequence;
      return media;
    });

    const params = { publicKey: this.project.publicKey };
    mediasToUpdate.forEach((m: Media) => { this.store.media.save(m, params).subscribe() });
  }

  public onTrackReady(ready: boolean) {
    // When all tracks are ready, the project is ready
    const isReady = this.tracks.map((track: TrackComponent) => track.isReady).reduce((sum: boolean, curr: boolean) => sum && curr, true); // this.tracks.length > 0
    if(this._isTracksReady !== isReady) {
      this._isTracksReady = isReady;
      if(this._isTracksReady) {
        console.debug('All tracks ready!');

        this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
            const time = parseFloat(params?.t);
            if(time) {
                this.audio.player.currentTime = time;
            } else if (this.auth.currentUser) {
                this.userState$.pipe(first()).subscribe((userState: UserState) => {
                    this.audio.player.currentTime = parseFloat(userState.state.timecode) || 0;
                });
            }
            const loop = (params?.loop || '').split(',').filter(v => !!v);
            if(loop.length == 2) {
                this.audio.player.loop = loop;
                this.audio.player.looping(true);
            }
            const autoplay = params?.autoplay && params?.autoplay !== 'false' && params?.autoplay !== '0';
            if(autoplay) setTimeout(() => this.audio.player.play(), 500);
        });
      }
    }
  }

  onShowAddTrackDialog(): void {
    this.pms.ui.mediaFilter$.pipe(take(1)).subscribe((mediaFilter: MediaFilter) => {
        this.dialog.open(TrackAddDialogComponent, {
            autoFocus: false,
            panelClass: 'track-add-dialog',
            width: '700px',
            data: {
                project: this.project,
                mediaFilter: mediaFilter,
                allowMultiple: true,
            }
        }).afterClosed().subscribe((files: Array<{ file: File, buffer: AudioBuffer }>) => {
            if(files) {
                files.forEach((file: { file: File, buffer: AudioBuffer }) => this.pms.media.createAndUpload(file.file, file.buffer).subscribe());
            }
        });
    });
  }

  onRecordTrack(buffer: AudioBuffer) {
    const file = this.audio.bufferToWavFile(buffer, 'Recording');
    const clip = new Clip({ uuid: uuidv4(), position: 0, start: 0, duration: buffer.duration });
    const media = new Media({
        project_id: this.project.id,
        name: file.name,
        color: 'freedo',
        duration: buffer.duration, // @todo
        original_filesize: file.size,
        sequence: Math.max(0, this.firstSequence() - 1),
        f_main: URL.createObjectURL(file),
        f_main_dl: URL.createObjectURL(file),
        is_main: false,
        status: 'processed',
        metadata: new Metadata({ effects: [], clips: [clip]}),
    });
    this.createAndUploadMedia(media, file);
  }

  firstSequence(): number {
    return (this.medias || [{sequence:0}]).map((m: Media) => m.sequence).reduce((sum: number, curr: number) => Math.min(sum, curr), 0);
  }
  lastSequence(): number {
    return (this.medias || [{sequence:0}]).map((m: Media) => m.sequence).reduce((sum: number, curr: number) => Math.max(sum, curr), 0);
  }
  //#endregion

  //#region Helpers
  triggerTrackReadyWhenEmpty(isEmpty: boolean) {
    if(isEmpty) {
        this.onTrackReady(isEmpty);
    }

    return isEmpty;
  }
  //#endregion
  private createAndUploadMedia(media: Media, file: File): void {
    const params = { publicKey: this.project.publicKey };
    this.pushSequenceFor(media).pipe(
        switchMap((medias: Array<Media>) => {
            this.audio.recorder.reset();
            return this.store.media.save(media, params);
        })
    ).subscribe((savedMedia: Media) => {
        const mediaUpload = new Media({ id: savedMedia.id, project_id: savedMedia.projectId });
        this.store.media.upload(mediaUpload, file, params).pipe(
          catchError((err) => {
              console.warn(err);
              return err;
          })
        ).subscribe()
        //.add(() => this.audio.recorder.reset());

      });
  }

  private pushSequenceFor(media: Media): Observable<Array<Media>> {
    const mediasToUpdate = [];
    let lastSequence = media.sequence;
    this.medias.forEach((m: Media, index, array) => {
        if(m.sequence === lastSequence) {
            m.sequence++;
            lastSequence = m.sequence;
            mediasToUpdate.push(m);
        }
    });

    const params = { publicKey: this.project.publicKey };
    return combineLatest(mediasToUpdate.map((m: Media) => this.store.media.save(m, params) )).pipe(defaultIfEmpty([]));
  }

  updateProgress(event: HttpProgressEvent, media: Media): void {
    let mediaProgresInfo = this.arrDownloadProgress.find((item: ProgressInfo) => { return item.id == media.id; });
    if (mediaProgresInfo) {
      mediaProgresInfo.progress = event.loaded / event.total * 100;
    } else {
      let newProgress = new ProgressInfo(media.id, event.loaded / event.total * 100);
      this.arrDownloadProgress ? this.arrDownloadProgress.push(newProgress) : this.arrDownloadProgress = [newProgress];
    }

    let downloadProgres = Math.trunc(this.arrDownloadProgress.reduce((total: number, next: ProgressInfo) => total + next.progress, 0) / this.arrDownloadProgress.length);
    this.downloadProgressChange.emit(downloadProgres);
  }

  mediaBeingLoaded(event: ProgressInfo): void {
    this.mediasBeingLoadedChange.emit(event);
  }

}
