import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { SocketEventMessage, SocketService, Store } from '@app/core';
import { IProjectData, IShareData, Project, Share, ShareList } from '../types';
import { ProjectDataService, ShareDataService } from '../services';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ProjectStore extends Store<Project, IProjectData, ProjectDataService> implements OnDestroy {


    constructor(protected readonly service: ProjectDataService, protected readonly socket: SocketService, protected readonly share: ShareDataService) {
        super(socket);
    }
  

    protected observe(): Observable<SocketEventMessage> {
        return this.socket.observe(Project);
    }

    ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    listShare(params?: Record<string, any>): Observable<Array<Share>> {
        return this.share.list(params).pipe(map((list: ShareList) => list.items));
    }

    saveShare(shareData: Partial<IShareData> & Record<string, any>): Observable<Share> {
        return this.share.save(shareData);
    }

    removeShare(shareData: Partial<IShareData> & Record<string, any>): Observable<boolean>{
        return this.share.delete(shareData);
    }
}
