<!-- #region Track -->
<div class="trackDetails">
    <ng-container *ngIf="isAudioReady">
        <div top>
            <span handle [inlineSVG]="'/assets/images/icons/hamburger.svg'"></span>
            <span name>Recording</span>
            <span menu [inlineSVG]="'/assets/images/icons/ellipses.svg'"></span>
        </div>
    </ng-container>
</div>

<div #waveform class="waveform spirit" [style.background-position]="(pms.ui.scrollOffset$ | async) + 'px 0px'" [style.background-size]="(10 / (pms.ui.pixelDuration$ | async) / 1.2).toString() + 'px 1px'">
    <canvas #canvas class="canvas" height="100%" [width]="16384 * 3" [style.width]="(pms.ui.width$ | async) + 'px'" [style.transform]="'translateX(' + (pms.ui.scrollOffset$ | async) + 'px)'"></canvas>
</div>
