
<nt-app-toolbar [user]="user$ | async"></nt-app-toolbar>

<div class="main-title">Billing</div>

<ng-container *ngIf="user$ | async as user">
    <div class="container">
        <div>
            <span class="creditcard" [inlineSVG]="'/assets/images/icons/credit_card.svg'"></span>
            {{ user?.card?.brand }} ending {{ user?.card?.last4 }}<button class="btn btn-link" (click)="editBilling()">Change</button>
        </div>
        <div>
            CA {{ (user.plan.priceCharged || user.plan.price) | currency }} / month including applicable taxes, billed monthly.
        </div>
        <div>
            <span *ngIf="!!user.planPastdue">Your subscription is past due since {{ user.planPastdue | date:'long' }}.</span>
            <span *ngIf="!user.planPastdue && !!planCanceledPending()">Your subscription will end on {{ planCanceledPending() | date:'long' }}.</span>
            <span *ngIf="!user.planPastdue && !!planCanceledEffecive()">Your subscription ended on {{ planCanceledEffecive() | date:'long' }}.</span>
            <span *ngIf="!user.planPastdue && !planCanceledPending() && !planCanceledEffecive() && user.periodEnd">Your subscription will renew on {{ user.periodEnd | date:'long' }}.</span>
            <span *ngIf="!user.planPastdue && !planCanceledPending() && !planCanceledEffecive() && !user.periodEnd">Could not retrieve information about your subscription status.</span>
            <a href="#" [routerLink]="['/account/subscription']">Edit Subscription</a>
            <button class="btn btn-link" *ngIf="!planCanceledPending() && !planCanceledEffecive()" (click)="cancel()">Cancel Subscription</button>
        </div>
    </div>
</ng-container>
