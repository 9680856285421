<!-- #region Vertical Line -->
<hr class="vline"
    [style.top]="-(offsetY + element.nativeElement.offsetTop + element.nativeElement.parentElement.offsetTop) + 'px'"
    [style.height]="(offsetY + element.nativeElement.offsetTop + element.nativeElement.parentElement.offsetTop) + 'px'" />
<!-- #endregion -->

<!-- #region Timecode -->
<div  *ngIf="!comment.parentId" timecode (click)="audio.player.currentTime = comment.timecode" [title]=" editMode ? '' : (comment.timecode | timecode: 'h?:mm:ss.msmsms' + (comment.timecodeEnd ? ' - ' + (comment.timecodeEnd | timecode : 'h?:mm:ss.msmsms') : ''))">
    <ng-container *ngIf="!editMode; else editTimecode">
        {{ comment.timecode | timecode: 'h?:mm:ss' }}{{ comment.timecodeEnd ? ' - ' + (comment.timecodeEnd | timecode : 'h?mm:ss') : '' }}
    </ng-container>
    <ng-template #editTimecode>
        <input #timecode type="text" (keypress)="onKeypress($event)" [ngModel]="comment.timecode" /><span>sec</span>
        <ng-container *ngIf="comment.timecodeEnd">
            &nbsp;-&nbsp;<input #timecodeEnd type="text" (keypress)="onKeypress($event)" [ngModel]="comment.timecodeEnd" /><span>sec</span>
        </ng-container>
    </ng-template>
</div>
<!-- #endregion -->

<!-- #region Actions-->
<div *ngIf="!editMode" actions>
    <span *ngIf="comment.userCanUpdate && !comment.parentId" class="action" resolved [ngClass]="{ disabled: !comment.userCanUpdate, active: comment.isResolved}" (click)="onResolve(!comment.isResolved)" [inlineSVG]="'/assets/images/icons/resolved.svg'"></span>
    <span *ngIf="comment.userCanUpdate" class="action" edit (click)="onEdit()" [inlineSVG]="'/assets/images/icons/pencil.svg'"></span>
    <span *ngIf="comment.userCanDelete" class="action" delete (click)="onDelete()" [inlineSVG]="'/assets/images/icons/trash.svg'"></span>
    <span class="action" menu (click)="$event" [inlineSVG]="'/assets/images/icons/dots.svg'"></span>
</div>
<!-- #endregion -->

<!-- #region Info -->
<div info>
    <img [src]="comment?.user?.picture"/>
    <span>{{ comment.userId ? comment.user.nameFirst + ' ' + comment.user.nameLast : comment.anonName }} · {{ comment.createdAt ? (comment.createdAt | timeago) : '' }}</span>
</div>
<!-- #endregion -->

<!-- #region Comment-->
<div *ngIf="comment.comment || editMode" comment>
    <div *ngIf="!editMode; else editComment" #commentTextView commentView [attr.truncate]="showLines">
        <span [outerHTML]="comment.comment | linky:{ stripPrefix: false, truncate: { length: 32, location: 'smart' }, sanitizeHtml: true }"></span>
        <span more (click)="onMore()" *ngIf="commentTextView.scrollHeight > commentTextView.clientHeight">
            <span class="dots">&nbsp;&hellip;</span>
            more
        </span>
    </div>
    <ng-template #editComment>
        <textarea #textarea *ngIf="editMode" #commentText placeholder="Add Comment..." (focus)="onTextAreaChange($event)" (input)="onTextAreaChange($event)" (keypress)="onKeypress($event)" [ngModel]="comment.comment" (ngModel)="onTextAreaChange($event)"></textarea>
    </ng-template>
</div>
<!-- #endregion -->

<!-- #region Audionote-->
<ng-container *ngIf="(audionotes$ | async)?.length > 0 || !editMode; else noAudionote">
    <ng-container *ngFor="let audionote of audionotes$ | async; trackBy: trackByAudionote">
        <!-- <ng-container *ngIf="audionote$ | async as audionote"> -->
            <nt-audionote *ngIf="audionote.status === 'processed' || editMode" [editMode]="editMode" [audionote]="audionote"></nt-audionote>
        <!-- </ng-container> -->
    </ng-container>
</ng-container>
<ng-template #noAudionote>
    <nt-audionote #audionote [editMode]="true"></nt-audionote>
</ng-template>
<!-- #endregion -->

<!-- #region Reactions-->
<div *ngIf="!editMode" reactions>
    <ng-container *ngFor="let emoji of comment.reactions.counts | keyvalue">
        <span emoji *ngIf="!editMode" tabindex="{{emoji.key}}" [innerHTML]="emoji.key | emoji: emoji.value" (click)="onToggleReaction(emoji.key)"></span>
    </ng-container>
    
    <span emoji [inlineSVG]="'/assets/images/icons/emoji.svg'" [prepend]="true" [matMenuTriggerFor]="reactionsMenu" (click)="onOpenReactions($event)">+</span>
    <mat-menu #reactionsMenu="matMenu" yPosition="below" [overlapTrigger]="false" class="commentReactionMenu">
        <ng-template matMenuContent>
            <div>
                <div class="emojisWrapper">
                    <div class="emojisSectionTitle" *ngIf="frequentEmojis">Frequently Used</div>
                    <ng-container *ngFor="let emoji of frequentEmojis">
                        <span emoji [innerHTML]="emoji | emoji" (click)="onSelectReaction(emoji.index)"></span>
                    </ng-container>

                    <div class="emojisSectionTitle">All emojis</div>
                    <ng-container *ngFor="let emoji of emojis">
                        <span title="{{emoji.shortname}}" tabindex="{{emoji.index}}" emoji [innerHTML]="emoji | emoji" (click)="onSelectReaction(emoji.index)"></span>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </mat-menu>
</div>
<!-- #endregion -->

<!-- #region Reply-->
<div reply>
    <ng-container *ngIf="!editMode; else editButton">
        <!-- <span class="action" like (click)="onLike($event)" [inlineSVG]="'/assets/images/icons/thumbsup.svg'"></span> -->
        <span #replyRef (click)="onReply()" count>
            <ng-content [hidden]="!replyRef.children.length && !replyRef.innerHTML" select="[reply]"></ng-content>
        </span>
        <span *ngIf="!replyRef.children.length && !replyRef.innerHTML" (click)="onReply()" count>
                Reply
        </span>
    </ng-container>
    <ng-template #editButton>
        <div class="actions-buttons" *ngIf="editMode">
            <button class="cancel" (click)="onCancel()">Cancel</button> <!--(click)="cancel()"-->
            <button class="basic1" (click)="onPost(textarea.nativeElement.value, timecode?.nativeElement?.value, timecodeEnd?.nativeElement?.value)">Post</button> <!--(click)="post()" [disabled]="!isValidForm()"-->
        </div>
    </ng-template>
</div>
<!-- #endregion -->
