import { ApiObject } from '@app/core/api';
import { environment } from 'src/environments/environment';
import { Card, IAuthUser, Plan, IPlanData, ICardData } from '../auth.module';

export class AuthUser extends ApiObject<IAuthUser> {
    protected static POST_FIELDS_MAP = {
        id: 'id', email: 'email', password: 'password', nameFirst: 'name_first', nameLast: 'name_last', externalImage: 'external_image',
        language: 'language', picture: 'picture', optinNotification: 'optin_notification', optinPromo: 'optin_promo',
        billingAddress1: 'billing_address1', billingAddress2: 'billing_address2', billingCity: 'billing_city', billingRegion: 'billing_region',
        billingCountry: 'billing_country', billingPostalcode: 'billing_postalcode'
    };

    //#region IAuthUser Data Accessors
    //#region Read-only
    public readonly id: number;
    public readonly fbId: string;
    public readonly googleId: string;
    public readonly status: string;

    public readonly planId: number;
    public readonly periodStart: string;
    public readonly periodEnd: string;
    public readonly planCancelAtPeriodEnd: number;
    public readonly planCanceledAt: string;
    public readonly planExpiry: string;
    public readonly planPastdue: Date;
    public readonly planPrice: number;
    //#endregion

    //#region Editable
    public email: string;
    public nameFirst: string;
    public nameLast: string;
    public language: 'en' | 'fr';
    public picture: URL;
    public optinNotification: boolean;
    public optinPromo: boolean;

    public billingAddress1: string;
    public billingAddress2: string;
    public billingCity: string;
    public billingRegion: string;
    public billingCountry: string;
    public billingPostalcode: string;
    //#endregion


    //#region Meta fields
    public readonly flags: string[];
    public readonly card: Card;
    public readonly plan: Plan;
    //#endregion

    //#region Timestamps
    public readonly createdAt: Date;
    public readonly updatedAt: Date;
    public readonly connectedAt: Date;
    public readonly emailValidatedAt: Date;
    //#endregion
    //#endregion

    //#region Partner
    public get isPartner(): boolean {
        let result = false;

        if (this && this.plan) {
            let nameParts = this.plan.name.replace('plans.', '').replace('name.', '').split('_');
            if (nameParts.length == 2) {
                let name = nameParts[0].toLowerCase();
                if (name.endsWith('x')) {
                    name = name.slice(0, -1);
                }

                result = Object.keys(environment.partners).indexOf(name) >= 0;
            }
        }

        return result;
    }

    public get isAnnualSubscription(): boolean {
        let nameParts = this.plan.name.replace('plans.', '').replace('name.', '').split('_');
        let name = nameParts[0].toLowerCase();
        return name.endsWith('x');
    }

    public get partnerPlan(): Plan {
        let plan = new Plan({
            name: '', sequence: 0
        });

        if (this && this.plan) {
            let nameParts = this.plan.name.replace('plans.', '').replace('name.', '').split('_');
            if (nameParts.length == 2) {
                let name = nameParts[0].toLowerCase();
                if (name.endsWith('x')) {
                    name = name.slice(0, -1);
                }

                plan = new Plan({
                    name: name, 
                    sequence: parseInt(nameParts[1])
                });
            }
        }
        
        return plan;
    }

    public get partnerInfo(): { displayName?: string, logo?: string } {
        const partnerName = this.partnerPlan.name;
        if (Object.keys(environment.partners).indexOf(partnerName) >= 0) {
            return environment.partners[partnerName];
        }

        return {};
    }
    //#endregion

    //#region Plan
    public get isPlanTrial(): boolean {
        return this.planExpiry && this.plan.code === 'free';
    }
    public get isPlanExpired(): boolean {
        if(!this.planExpiry) { return null } // No Expiry
        else if (Date.parse(this.planExpiry) > Date.now()) {
            return false;
        }
        else if (Date.parse(this.planExpiry) <= Date.now()) {
            return true;
        }
        else { // Should be unreachable
            throw {message: 'This should not happen'};
        }
    }
    //#endregion

    constructor(data: IAuthUser) {
        super(data);
    }

    //#region Helpers
    //#endregion

    //#region Construction Setters
    protected transformPictureForInit(data: string, set: Partial<IAuthUser>) {
        return this.transformURLForInit(data, set);
    }
    protected transformCardForInit(data: ICardData, set: Partial<IAuthUser>) {
        return data ? new Card(data) : null;
    }
    protected transformPlanForInit(data: IPlanData, set: Partial<IAuthUser>) {
        return new Plan(data);
    }
    protected transformConnectedAtForInit(data: string, set: Partial<IAuthUser>) {
        return this.transformDateForInit(data, set);
    }
    protected transformEmailValidatedAtForInit(data: string, set: Partial<IAuthUser>) {
        return this.transformDateForInit(data, set);
    }
    //#endregion

    public clone<AuthUser extends ApiObject<IAuthUser>>(): AuthUser {
        return this.shallowClone(this.constructor as { new (data: IAuthUser): AuthUser } );
    }
}
