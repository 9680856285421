import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from '@app/features/auth/services';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { StoreService } from '../services';
import { UserState } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ProjectUserStateResolver implements Resolve<UserState> {

  

  constructor(private readonly store: StoreService, private readonly auth: AuthService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserState> {
    let publicKey: string;
    let projectId: number;

    return this.auth.authenticated$.pipe(take(1)).pipe(switchMap((authenticated: boolean) => {
        // Backward compatibility for links with projectId
        if (!isNaN(route.params.projectId)) {
            projectId = parseInt(route.params.projectId, 10);
        } else {
            publicKey = route.params.projectId;
            projectId = parseInt(publicKey.substring(0, publicKey.indexOf('_')), 16);
        }

        if (isNaN(projectId) && publicKey === 'demo') { projectId = -1; }

        if(authenticated || (isNaN(projectId) && publicKey === 'demo')) {
            this.store.userState.fetch({ id: projectId, publicKey });
            return this.store.userState.get(projectId).pipe(first());
        } else {
            return of(null);
        }
    }));
  }
}
