import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { ConfirmationDialogComponent, NtDraggableDropEvent, NtDraggableMoveEvent } from '@app/shared';

import { ProjectManagerService, StoreService, ToolService } from '../../services';
import { Project, Color, Drawing } from '../../types';
import { TrackComponent } from '../track/track.component';
import { DrawingDialogComponent } from './drawing-dialog/drawing-dialog.component';

@Component({
  selector: 'nt-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss']
})
export class DrawingComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input('drawing') drawing: Drawing;
  @Input('offsetX') offsetX: number;
  @Input('offsetY') offsetY: number = 0;
  @HostBinding('style.transform') translateX: string;
  @HostBinding('style.marginTop') marginTop: string;
  @ViewChild('paragraph') paragraph: ElementRef<HTMLParagraphElement>;

  private _destroy: Subject<void> = new Subject();
  private publicKey: string;

  constructor(
    protected readonly dialog: MatDialog,
    public readonly element: ElementRef<HTMLElement>,
    private readonly store: StoreService,
    private readonly pms: ProjectManagerService,
    public readonly toolService: ToolService,
    public readonly confirmationDialog: MatDialog,
    private readonly parent: TrackComponent
  ) { }

  ngOnInit(): void {
    this.store.project.get(this.drawing.projectId).pipe(first()).subscribe((project: Project) => {
      this.publicKey = project.publicKey;
    });
  }

  ngAfterViewInit(): void {
    if (this.toolService.newDrawingId == this.drawing.id) {
      if (this.paragraph) {
        this.enableTextStampEditing();
        this.toolService.newDrawingId = 0;
      }
    }

    this.pms.ui.drawingColor$.pipe(takeUntil(this._destroy)).subscribe((color: Color) => {
        if(this.drawing.id === this.toolService.idSelectedDrawing) {
            if(this.drawing.color !== color) {
                this.drawing.color = color;
                this.store.drawing.save(this.drawing, { publicKey: this.publicKey }).subscribe();
            }
        }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.offsetX instanceof SimpleChange || changes.offsetY instanceof SimpleChange) {
      this.translateX = `translate(${this.offsetX}px, ${this.offsetY}px)`;
    }
  }

  ngOnDestroy(): void {
      this._destroy.next();
      this._destroy.complete();
  }

  onDelete(): void {
    this.confirmationDialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      panelClass: 'confirmation-dialog',
      width: '300px',
      data: {
        title: 'Delete drawing',
        message: 'Are you sure you want to delete this drawing?',
        cancelLabel: 'Cancel',
        acceptLabel: 'Yes'
      }
    })
    .afterClosed()
    .subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.store.drawing.delete(this.drawing).subscribe(() => {
          // do something else
        });
      } else {
        // do something else
      }
    });
  }

  @HostListener('move', ['$event'])
  onMove(moveEvent: NtDraggableMoveEvent) {
    const rowHeight = 84 * 2; // @todo get some otherway
    const targetIndex = this.parent.getNextRowIndex(moveEvent.target);
    const diff = targetIndex - this.drawing.rowIndex

    this.marginTop = `${rowHeight * diff}px`;
  }

  @HostListener('drop', ['$event'])
  onDrop(dropEvent: NtDraggableDropEvent) {
    this.drawing.rowIndex = this.parent.getNextRowIndex(dropEvent.target);

    this.store.drawing.save(this.drawing, { publicKey: this.publicKey }).subscribe();
  }

  //#region: text drawing
  onEditText(event: MouseEvent): void {
    event.stopPropagation();
    this.enableTextStampEditing();
  }

  onEditing(event: KeyboardEvent): void {
    if (event.code.toLowerCase().endsWith('enter')) {
      event.preventDefault();
      this.saveTextStamp();
    }
  }

  onEditSimvec(event: MouseEvent) {
    event.stopPropagation();
    this.dialog.open(DrawingDialogComponent, {
        autoFocus: false,
        panelClass: 'drawing-dialog',
        width: '50%',
        height: '480px',
        data: this.drawing.data,
    }).afterClosed().subscribe((data) => {
        if(data && data !== this.drawing.data) {
            const drawing: Drawing = this.drawing.clone();
            drawing.data = data;
            this.store.drawing.save(drawing, { publicKey: this.publicKey }).subscribe();
        }
    })
  }

  private enableTextStampEditing() {
    this.paragraph.nativeElement.setAttribute('contenteditable', 'true');
    this.paragraph.nativeElement.addEventListener('blur', this.saveTextStamp)
    this.paragraph.nativeElement.focus();
  }

  private saveTextStamp = () => {
    this.drawing.data = this.paragraph.nativeElement.innerText;
    this.store.drawing.save(this.drawing, { publicKey: this.publicKey }).subscribe((savedDrawing: Drawing) => {
        this.paragraph.nativeElement.removeEventListener('blur', this.saveTextStamp);
        this.paragraph.nativeElement.removeAttribute('contenteditable');
    });
  }
  //#endregion
}
