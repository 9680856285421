import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocalStorageService } from 'ngx-webstorage';

import { FormComponent } from '@app/shared';
import { AuthService } from '@app/features/auth';
import { StoreService } from '../../../services';
import { Comment } from '../../../types';

@Component({
    selector: 'nt-comment-thread-dialog',
    templateUrl: './comment-thread-dialog.component.html',
    styleUrls: ['./comment-thread-dialog.component.scss']
})
export class CommentThreadDialogComponent extends FormComponent implements OnInit {

    @ViewChild('commentTextarea') textarea: ElementRef<HTMLTextAreaElement>;
    @ViewChild('authorName') authorName: ElementRef<HTMLInputElement>;
    public comment: Comment;
    // public replies$: Observable<Array<Comment>>;

    public focusReply: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data,
        public readonly dialogRef: MatDialogRef<CommentThreadDialogComponent>,
        private store: StoreService,
        public confirmationDialog: MatDialog,
        protected auth: AuthService,
        protected storage: LocalStorageService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.data && this.data.comment instanceof Comment) {
            this.comment = <Comment>this.data.comment;
            // this.replies$ = this.store.comment.list({ projectId: this.comment.projectId, mediaId: this.comment.mediaId, parentId: this.comment.id }, 'createdAt').pipe(
                
            // )
        }

        this.focusReply = !!this?.data?.focusReply;
    }

    submit(): void {

    }

    ngAfterViewInit(): void {
        // if(this.textarea) {
        //     this.textarea.nativeElement.setAttribute('style', 'height:' + this.textarea.nativeElement.scrollHeight + 'px;overflow-y:hidden;');

        //     ['input', 'focus'].forEach((eventName) => {
        //         this.textarea.nativeElement.addEventListener(eventName, (event: Event) => {
        //             const target = event.target as HTMLElement;
        //             target.style.height = 'auto';
        //             target.style.height = (target.scrollHeight) + 'px';
        //         }, false);
        //     });
        // }

        // if (this.authorName) {
        //     this.authorName.nativeElement.tabIndex = 1000;
        // }

        // if(this.textarea) {
        //     this.textarea.nativeElement.tabIndex = 1001;
        // }

        // setTimeout(() => {
        //     if(this.authorName) {
        //         this.authorName.nativeElement.focus();
        //     }
        //     if(this.textarea) {
        //         this.textarea.nativeElement.focus();
        //     }
        // }, 250);
    }

    // isValidForm(): boolean {
    //     let valid = true;
    //     valid = this.reply != '';
    //     if (!valid) {
    //         return valid;
    //     }

    //     if (this.isPublicProject) {
    //         valid = this.anonymousName != '';
    //     }

    //     return valid;
    // }
}
