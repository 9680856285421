import { HttpContext, HttpContextToken, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, IApiOptions, IApiResponse } from '@app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDS } from '.';
import { Audionote, AudionoteList, IAudionoteData, IAudionoteDataList } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class AudionoteDataService {

    constructor(private api: ApiService) { }

    //#region CRUD Operation
    public list(params?: Record<string, any>): Observable<AudionoteList> {
      const { projectId, mediaId, commentId, publicKey } = params;
      const mediaPath = mediaId ? `media/${mediaId}/` : '';
      const commentPath = commentId ? `comment/${commentId}/` : '';
      const options: IApiOptions = { observe: 'response' as 'response' };
      if (publicKey) {
        options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
      }
      return this.api.get<IAudionoteDataList>(`project/${projectId}/${mediaPath}${commentPath}audionote?limit=0`, options).pipe(
        map((response: IApiResponse<IAudionoteDataList>) => {
          const l = new AudionoteList(response.result, Audionote);
          return l;
        })
      );
    }
  
    public get(params?: Record<string, any>): Observable<Audionote> {
      const { id, projectId, mediaId, commentId, publicKey } = params;
      const mediaPath = mediaId ? `media/${mediaId}/` : '';
      const commentPath = commentId ? `comment/${commentId}/` : '';
      const options: IApiOptions = { observe: 'response' as 'response' };
      if (publicKey) {
        options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
      }
  
      return this.api.get<IAudionoteData>(`project/${projectId}/${mediaPath}${commentPath}audionote/${id}`, options).pipe(
        map((response: IApiResponse<IAudionoteData>) => {
          return new Audionote(response.result);
        })
      );
    }
  
    public save(commentData: Partial<IAudionoteData> & Record<string, any>, params: Record<string, any>): Observable<Audionote> {
      const { publicKey } = params;
      const { id, project_id, media_id, comment_id } = commentData; delete commentData.id, delete commentData.project_id, delete commentData.media_id;
      const context = new HttpContext().set(IDS, { id, project_id, media_id, comment_id });
      const mediaPath = media_id ? `media/${media_id}/` : '';
      const commentPath = comment_id ? `comment/${comment_id}/` : '';
      const pathId = id ? `/${id}` : '';
      let queryStringPath = '';

      let payload = commentData;
      if (commentData.file && commentData.file instanceof File) {
        if (id) {
          queryStringPath = '?_method=PUT';
        }
        const formData = new FormData();
        Object.keys(commentData).every(k => formData.append(k, commentData[k]));
        payload = formData;
      }

      const options: IApiOptions = { observe: 'response' as 'response', context };
      if (publicKey) {
        options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
      }
  
      return this.api.post<IAudionoteData, Partial<IAudionoteData>>(`project/${project_id}/${mediaPath}${commentPath}audionote${pathId}${queryStringPath}`, payload, options).pipe(
        map((response: IApiResponse<IAudionoteData>) => {
          return new Audionote(response.result);
        })
      );
    }
  
    public delete(params?: Record<string, any>): Observable<boolean> {
      const { id, projectId, mediaId, commentId, publicKey } = params;
      const mediaPath = mediaId ? `media/${mediaId}/` : '';
      const commentPath = commentId ? `comment/${commentId}/` : '';
      const options: IApiOptions = { observe: 'response' as 'response' };
      if (publicKey) {
        options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
      }
  
      return this.api.delete<boolean>(`project/${projectId}/${mediaPath}${commentPath}audionote/${id}`, options).pipe(
        map((response: IApiResponse<boolean>) => {
          return response.result;
        })
      );
    }
    //#endregion
}
