import { Injectable, OnDestroy } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { LocalStorage, LocalStorageService } from 'ngx-webstorage';

import { Observable, throwError, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { map, catchError, filter, tap, shareReplay, take, switchMap, publishReplay, refCount, takeUntil, takeWhile } from 'rxjs/operators';

import { ApiService, IApiOptions, IApiResponse } from '@app/core/core.module';

import { AuthResponse, IAuthResponse, IAuthCredentials, IAuthUser, AuthUser } from '../types';
import { User } from '@app/features/project/types';

const filterUndefined = (v) => v !== undefined;

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  //#region Session
  private _session$: BehaviorSubject<AuthResponse> = new BehaviorSubject(undefined);
  public session$: Observable<Readonly<AuthResponse>> = this._session$.asObservable().pipe(
    filter(filterUndefined),
    publishReplay(1),
    refCount()
  );
  public get currentSession(): Readonly<AuthResponse> { return this._session$.value; }
  //#endregion

  //#region User
  public user$: Observable<Readonly<AuthUser>> = this.session$.pipe(
    map((session: AuthResponse) => session?.user),
    publishReplay(1),
    refCount()
  );
  public get currentUser(): Readonly<AuthUser> { return this._session$.value?.user; }
  //#endregion

  //#region Authenticated Status
  public authenticated$: Observable<boolean> = this.session$.pipe(
    map((session: AuthResponse) => !!session && !!session.authToken && !this.hasExpired(session)),
    publishReplay(1),
    refCount()
  );
  public get isAuthenticated(): boolean { return !!this._session$.value && !!this._session$.value.authToken && !this.hasExpired(this._session$.value); }
  //#endregion
  //#endregion

  private _postAuthenticationUrl: UrlTree | false;
  public set postAuthenticationUrl(redirect: UrlTree | false) { // @todo Make those configurable
    if (['/', '/auth/login', '/auth/register', '/auth/invitation', '/auth/partner'].includes(redirect.toString())) { return; }
    this._postAuthenticationUrl = redirect;
  }

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private router: Router, private api: ApiService, private storage: LocalStorageService) {
    // We subscribe to the main observables to get their stream going so that "snapshot" value are up to date
    this.session$.pipe(takeUntil(this._unsubscribe$)).subscribe();
    this.user$.pipe(takeUntil(this._unsubscribe$)).subscribe();
    this.authenticated$.pipe(takeUntil(this._unsubscribe$)).subscribe();

    // Observe local storage's auth, map to AuthResponse, attach to _session$ subject
    this.storage.observe('auth').pipe(
        takeUntil(this._unsubscribe$),
        map((session: AuthResponse | IAuthResponse) => {
            // If session is not a AuthResponse
            if(!(session instanceof AuthResponse) && session) {
                session = new AuthResponse(session);
            }

            return session as AuthResponse;
        }),
        publishReplay(1),
        refCount()
    ).subscribe(this._session$);

    // Simply set storage auth with same content as storage to trigger the observe
    this.storage.store('auth', this.storage.retrieve('auth') || null);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  //#region Calls
  public authenticate(email: string, password: string, throwError: boolean = false): Observable<boolean> {
    const options: IApiOptions = { observe: 'response' as 'response' };
    options.headers = new HttpHeaders({
      Authorization: `Basic ${(`${email}:${password}`).base64encode()}`,
    });

    return this.api.get<IAuthResponse>('user/authToken?long_lived=1', options, throwError).pipe(
      switchMap(this.authenticateMapHandler),
      catchError(this.authenticateExceptionHandler),
    );
  }

  public register(user: IAuthCredentials, emailToken?: string): Observable<AuthUser> {
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (emailToken) {
      options.headers = new HttpHeaders({
        Authorization: `Basic ${(`emailToken:${emailToken}`).base64encode()}`,
      });

      return this.api.put<IAuthUser, IAuthCredentials>('user', user, options).pipe(
        map((response: IApiResponse<IAuthUser>) => new AuthUser(response.result))
      );
    }

    return this.api.post<IAuthUser, IAuthCredentials>('user', user, options).pipe(
      map((response: IApiResponse<IAuthUser>) => new AuthUser(response.result))
    );
  }

  public save(name_first: string, name_last: string, optin_notification: boolean): Observable<AuthUser> {
    const options: IApiOptions = { observe: 'response' as 'response' };

    return this.api.put<IAuthUser, { name_first: string, name_last: string, optin_notification: boolean }>('user', { name_first, name_last, optin_notification }, options).pipe(
      map((response: IApiResponse<IAuthUser>) => new AuthUser(response.result)),
      tap((user: AuthUser) => {
        const session: AuthResponse = this.storage.retrieve('auth');
        const mergedUser = Object.assign({}, session.user, user);
        const mergedSession = Object.assign({}, session, { user: mergedUser });
        this.storage.store('auth', mergedSession);
      })
    );
  }

  public requestPasswordReset(email: string): Observable<string> {
    return this.api.post<string, { email: string }>('user/passwordReset', { email }).pipe(
      map((response: IApiResponse<string>) => response.result),
      catchError((error: HttpErrorResponse, caught: Observable<string>) => {
        return throwError(error) as Observable<string>;
      })
    );
  }

  public confirmPasswordReset(token: string, password: string): Observable<AuthUser> { // @todo Trigger user$.next()? And log in?
    return this.api.post<IAuthUser, { token: string, password: string }>('user/passwordChange', { token, password }).pipe(
      map((response: IApiResponse<IAuthUser>) => {
        return new AuthUser(response.result);
      }),
      catchError((error: HttpErrorResponse, caught: Observable<AuthUser>) => {
        return throwError(error) as Observable<AuthUser>;
      })
    );
  }

  public confirmEmail(token: string): Observable<AuthUser> { // @todo Trigger user$.next()? And login?
    return this.api.post<IAuthUser, { token: string }>('user/confirmEmail', { token }).pipe(
      map((response: IApiResponse<IAuthUser>) => {
        return new AuthUser(response.result);
      }),
      catchError((error: HttpErrorResponse, caught: Observable<AuthUser>) => {
        return throwError(error) as Observable<AuthUser>;
      })
    );
  }
  //#endregion

  //#region Helpers
  private hasExpired(session: AuthResponse): boolean {
    let expired: boolean = !session || !session.expiry;
    if (!expired) {
      const now = new Date();
      const expiry = new Date(session.expiry || 0);
      expired = now >= expiry;
    }

    if (expired) {
      this.clear();
    }

    return expired;
  }
  public fetchUser(): Observable<AuthUser> {
    const options: IApiOptions = { observe: 'response' as 'response' };
    return this.api.get<IAuthUser>('user', options).pipe(
      map((response: IApiResponse<IAuthUser>) => new AuthUser(response.result))
    );
  }
  public postAuthenticationRedirect() {
    if(this._postAuthenticationUrl !== false) {
        this.router.navigateByUrl((this._postAuthenticationUrl || this.router.createUrlTree(['/'], {})).toString());
    }
  }
  public revoke(withRedirect: boolean = true): void {
    this.clear();
    if(withRedirect) {
        this.router.navigate(['/auth'], {});
    }
  }
  private clear(): void {
    this.storage.store('auth', null);
  }
  //#endregion

  //#region Handlers
  private authenticateMapHandler = (response: IApiResponse<IAuthResponse>): Observable<boolean> => {
    const session = new AuthResponse(Object.assign({}, response.result || {}, { rawResponse: response } ));
    this.storage.store('auth', session);
    return this.fetchUser().pipe(switchMap((user: AuthUser) => {
        const mergedSession = Object.assign({}, session, { user });
        this.storage.store('auth', mergedSession);
        return this.authenticated$.pipe(
            take(1),
          //   tap((authenticated: boolean) => console.debug(this.constructor.name, 'authenticated$ tap in authenticateMapHandler', authenticated)),
            tap((authenticated: boolean) => {
              if (authenticated) {
                this.postAuthenticationRedirect();
              }
            })
          );
    }));
  }

  private authenticateExceptionHandler = (error: HttpErrorResponse): Observable<boolean> => {
    this.clear()
    return throwError(error);
  }
  //#endregion
}
