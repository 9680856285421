import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeLast, takeUntil, tap } from 'rxjs/operators';

import { AudioService, SocketService, Subscriber } from '@app/core';
import { ConfirmationDialogComponent } from '@app/shared';
import { AuthService, MediaFilter } from '@app/features/auth';
import { AppComponent } from '@app/app.component';
import { ProgressInfo, Project, Media, UserState } from '../../types';
import { ProjectManagerService, ToolService } from '../../services';
import { ControlsComponent, ProjectShareDialogComponent, SequencerComponent, TrackAddDialogComponent } from '../../components';
import { LocalStorageService } from 'ngx-webstorage';
import { ToolMode } from '../../types/tool-mode';

@Component({
    selector: 'nt-project-view',
    templateUrl: './project-view.component.html',
    styleUrls: ['./project-view.component.scss','./project-view-progressive.component.scss']
})
export class ProjectViewComponent implements OnInit, OnDestroy, AfterViewInit {

    public readonly Tool = ToolMode;

    @HostBinding("style.--videoHeight") videoHeight: string = `0px`;
    @HostBinding('class.userCanComment') userCanComment: boolean;
    @HostBinding('class.userCanWrite') userCanWrite: boolean;
    @HostBinding('class.notepad') notepad: boolean;
    @HostBinding('class.journal')
    get journal(): boolean {
        return this.pms.ui.journalMode;
    }
    set journal(value: boolean) {
        this.pms.ui.journalMode = value;
    }
    @HostBinding('class.isReady')
    get isReady(): boolean {
        return this?.sequencer?.isReady;
    };
    @HostBinding('class') cssClass;

    @ViewChild(ControlsComponent) controls: ControlsComponent;
    @ViewChild(SequencerComponent) sequencer: SequencerComponent;

    /**
     * Project
     */
    public project$: Observable<Project>;
    /**
     * Project's UserState
     */
    public userState$: Observable<UserState>;
    /**
     * Observable media list
     */
    public medias$: Observable<Array<Media>>;

    public collaborators$: Observable<Array<Subscriber>>;

    //#region Download Progress
    public  uploading: boolean = false;
    // private filesToFetchCount: number = 0;
    // private filesToFetchProgresses: Array<number> = [];
    public  lastSequence: number;
    // private _progress: number;
    //#endregion

    public downloadProgress: number = 0;
    private arrMediasBeingLoaded: Array<number> = [];

    public demoSaving: boolean;

    private _destroy: Subject<void> = new Subject();

    constructor(
        private readonly route: ActivatedRoute, private readonly router: Router,
        private readonly dialog: MatDialog, private readonly socket: SocketService,
        private readonly parent: AppComponent, public readonly auth: AuthService,
        public readonly audio: AudioService, private readonly storage: LocalStorageService,
        public readonly pms: ProjectManagerService,
        public readonly confirmationDialog: MatDialog,
    ) { }

    //#region Lifecycle
    ngOnInit(): void {
        // Load project/medias on params change
        this.route.params.pipe(takeUntil(this._destroy)).subscribe((params) => {
            const { publicKey, projectId } = Project.parseProjectKey(params.projectId);

            this.socket.sub(projectId, publicKey).subscribe();
            this.collaborators$ = this.socket.subscribers(projectId).pipe(
                map((collaborators: Array<Subscriber>) => collaborators.filter((s: Subscriber) => s.id !== this.auth.currentUser?.id))
            );

            this.pms.loadProject(params.projectId);
            this.project$ = this.pms.project$.pipe(
                tap((project: Project) => {
                    setTimeout(() => {
                        this.userCanComment = project.userCanComment;
                        this.userCanWrite = project.userCanWrite;
                    });
                }),
                tap((project: Project) => this.parent.title.setTitle(`${project.name} - Notetracks`)),
            )
            this.userState$ = this.pms.state.state$;

            this.medias$ = this.pms.media.list$.pipe(
                tap((medias: Array<Media>) => {
                    this.lastSequence =  (medias || [{sequence:0}]).map((m: Media) => m.sequence).reduce((sum: number, curr: number) => Math.max(sum, curr), 0);
                })
            );
        });

        this.route.queryParams.pipe(takeUntil(this._destroy)).subscribe((params: Params) => {
            this.pms.ui.toolMode = params.tool ? params.tool as ToolMode : ToolMode.SELECT;
            this.cssClass = this.pms.ui.toolMode;
        });

        combineLatest([this.pms.ui.video$, this.pms.ui.videoDisplayFactor$]).pipe(takeUntil(this._destroy)).subscribe((result => {
            const [video, factor] = result;
            if(video instanceof Media) this.videoHeight = `${this.pms.ui.DEFAULT_VIDEO_HEIGHT * factor}px`;
        }));
    }

    ngAfterViewInit(): void {
        // setTimeout(() => {
        //     this.journal = true;
        // }, 500);
    }

    ngOnDestroy(): void {
        this.audio.destroySession();
        this.project$.pipe(
            take(1),
            switchMap((project: Project) => this.socket.unsub(project.id))
        ).subscribe().add(() => {
            this._destroy.next();
            this._destroy.complete();
        });
    }
    //#endregion

    //#region Actions
    public fullscreen() {
        document.querySelector('video').requestFullscreen({ navigationUI: 'hide' });
    }
    public shareProject() {
        this.project$.pipe(take(1)).subscribe((project: Project) => {
            this.dialog.open(ProjectShareDialogComponent, {
                autoFocus: false,
                panelClass: 'project-share-dialog',
                width: '800px',
                data: { project }
            }).afterClosed().pipe(takeLast(1)).subscribe((project: Project) => {});
        });
    }

    public saveDemoProject() {
        this.demoSaving = true;
        this.downloadProgress = 0;
        this.pms.saveDemoProject().subscribe((completed: number) => {
            this.downloadProgress = Math.floor(completed * 100);
        }).add(() => {
            this.router.navigate(['project','list']);
        });
    }

    public toggleJournal() {
        let delay = 0;
        if(this.notepad) {
            this.notepad = false;
            delay = 500;
        }

        setTimeout(() => this.journal = !this.journal, delay);
    }
    public toggleNotepad() {
        let delay = 0;
        if(this.journal) {
            this.journal = false;
            delay = 500;
        }
        setTimeout(() => this.notepad = !this.notepad, delay);
        ;
    }
    //#endregion

    //#region Event Listener
    //#endregion

    //#region Helpers
    //#endregion

    goToProjectsList(): void {
        this.updateProjectState();
        if (this.arrMediasBeingLoaded && this.arrMediasBeingLoaded.length > 0) {

            this.confirmationDialog.open(ConfirmationDialogComponent, {
                autoFocus: false,
                panelClass: 'confirmation-dialog',
                width: '300px',
                data: {
                  title: 'Confirmation',
                  message: 'Are you sure you want to move away? Your audios are still processing in the background and you may lose the tracks',
                  cancelLabel: 'Cancel',
                  acceptLabel: 'Yes'
                }
              })
              .afterClosed()
              .subscribe((confirmed: boolean) => {
                if (confirmed) {
                    this.arrMediasBeingLoaded.splice(0, this.arrMediasBeingLoaded.length);
                    this.router.navigate(['..']);
                }
              });
        } else {
            this.router.navigate(['..']);
        }
    }

    updateProjectState(): void {
        if (this.auth.currentUser) {
            this.project$.pipe(take(1)).subscribe((project: Project) => {
                this.pms.state.save(project.id, 'timecode', this.audio.player.currentTime).subscribe();
            });
        }
    }

    downloadProgressChange(progress: number): void {
        this.downloadProgress = Math.min(99, progress);
    }

    mediasBeingLoadedChange(progressInfo: ProgressInfo): void {
        let itemExistIndex = this.arrMediasBeingLoaded.findIndex((m:number) => { return m == progressInfo.id; });
        if (itemExistIndex >= 0) {
            if (progressInfo.progress == 100 && progressInfo.status == 'processed') {
                this.arrMediasBeingLoaded.splice(itemExistIndex, 1);
            }
        } else {
            this.arrMediasBeingLoaded ? this.arrMediasBeingLoaded.push(progressInfo.id) : this.arrMediasBeingLoaded = [progressInfo.id];
        }
        // console.info('media being loaded', this.arrMediasBeingLoaded);
    }

    addTrack(): void {
        this.project$.pipe(take(1)).subscribe((project: Project) => {
            this.pms.ui.mediaFilter$.pipe(take(1)).subscribe((mediaFilter: MediaFilter) => {
                this.dialog.open(TrackAddDialogComponent, {
                    autoFocus: false,
                    panelClass: 'track-add-dialog',
                    width: '700px',
                    data: {
                        project: project,
                        mediaFilter: mediaFilter,
                        allowMultiple: true,
                    }
                }).afterClosed().subscribe((files: Array<{ file: File, buffer: AudioBuffer }>) => {
                    if(files) {
                        files.forEach((file: { file: File, buffer: AudioBuffer }) => this.pms.media.createAndUpload(file.file, file.buffer).subscribe());
                    }
                });
            });
        });
    }

    newComment(): void {
        this.medias$.pipe(take(1)).subscribe((medias: Array<Media>) => {
            if (medias && medias.length > 0) {
                if(!this.pms.ui.selectedTrackUuid || medias.filter((m: Media) => m.uuid === this.pms.ui.selectedTrackUuid).length === 0) {
                    this.pms.ui.selectedTrackUuid = medias[0].uuid;
                }
                if(this.pms.ui.isSmallDesign) this.pms.ui.journalMode = true;
                // setTimeout(() =>
                this.pms.eventBus.trigger('CreateComment',  medias.filter((m: Media) => m.uuid === this.pms.ui.selectedTrackUuid)?.[0]?.id)
                // );
            }
        });

    }

    addMarker(): void {
        this.medias$.pipe(take(1)).subscribe((medias: Array<Media>) => {
            if (medias && medias.length > 0) {
                if(!this.pms.ui.selectedTrackUuid || medias.filter((m: Media) => m.uuid === this.pms.ui.selectedTrackUuid).length === 0) {
                    this.pms.ui.selectedTrackUuid = medias[0].uuid;
                }
                this.pms.eventBus.trigger('CreateMarker',  medias.filter((m: Media) => m.uuid === this.pms.ui.selectedTrackUuid)?.[0]?.id);
            }
        });
    }
}
