import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  // tslint:disable-next-line: ban-types
  private filters: {[key: string]: Function} = {
    string: (filter: string) => {
      if (filter) {
        filter = filter.toLowerCase();
      }

      return (value: any) => {
        const negative: boolean = filter.indexOf('!') === 0;
        if (negative) {
          filter = filter.substr(1);
          return !filter || !(value ? `${value}`.toLowerCase().indexOf(`${filter}`) !== -1 : false);
        }
        return !filter || (value ? `${value}`.toLowerCase().indexOf(`${filter}`) !== -1 : false);
      };
    },
    boolean: (filter: boolean) => {
      return (value: any) => {
        return Boolean(value) === filter;
      };
    },
    object: (filter: { [x: string]: any; hasOwnProperty: (arg0: string) => any; $or: any; }) => {
      return (value: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }) => {
        for (const key in filter) {
          if (!filter.hasOwnProperty(key)) { continue; }

          if (key === '$or') {
            if (!this.filters.$or(filter.$or)(this.getValue(value))) {
              return false;
            }
            continue;
          }

          if (!value.hasOwnProperty(key) && !Object.getOwnPropertyDescriptor(Object.getPrototypeOf(value), key)) {
            return false;
          }

          const val = this.getValue(value[key]);
          const filterType = typeof filter[key];
          let isMatching;

          if (this.filters[filterType]){
            isMatching = this.filters[filterType](filter[key])(val);
          } else {
            isMatching = this.filters.$default(filter[key])(val);
          }

          if (!isMatching) {
            return false;
          }
        }

        return true;
      };
    },
    function: (filter: any) =>  filter,
    $or: (filter: any[]) => {
      return (value: any) => {
        let hasMatch = false;
        const length = filter.length;
        const isArray = value instanceof Array;

        const arrayComparison = (i: any) => {
          return value.indexOf(filter[i]) !== -1;
        };
        const otherComparison = (i: any) => {
          return value === filter[i];
        };
        const comparison = isArray ? arrayComparison : otherComparison;

        for (let i = 0; i < length; i++) {
          if (comparison(i)) {
            hasMatch = true;
            break;
          }
        }

        return hasMatch;
      };
    },
    $default: (filter: any) => {
      return (value: any) => {
        return filter === undefined || filter === value;
      };
    }
  };

  private getValue(value: any): any {
    return typeof value === 'function' ? value() : value;
  }

  private isNumber(value: any): boolean {
    return !isNaN(parseInt(value, 10)) && isFinite(value);
  }

  transform(array: any[], filter: any): any[] {
    const type = typeof filter;

    if (!array) {
      return array;
    }

    if (this.filters[type]){
      return array.filter(this.filters[type](filter));
    }

    return array.filter(this.filters.$default(filter));
  }

}
