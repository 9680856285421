<header>
    <mat-icon phylactry svgIcon="notepad"></mat-icon>
    Notes
    <ng-container *ngIf="editable">
        <mat-icon *ngIf="!editMode; else editing" [ngClass]="{editMode: true }" svgIcon="pencil" (click)="setEditMode(!editMode)"></mat-icon>
        <ng-template #editing>
            <!-- <mat-icon [ngClass]="{editMode: true }" svgIcon="x" (click)="cancel()"></mat-icon> -->
            <mat-icon [ngClass]="{editMode: true }" svgIcon="check" (click)="onSave()"></mat-icon>
        </ng-template>
    </ng-container>
</header>

<ckeditor #ckeditor [editor]="Editor" [disabled]="!editable || !editMode" [config]="config" [ngModel]="data$ | async" (change)="onNotepadChange($event)" (click)="onNotepadFocus($event)" (blur)="onSave()" (ready)="onCkReady($event)"></ckeditor>