import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'nt-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss','./auth-responsive.component.scss']
})
export class AuthComponent implements OnInit {

  public showPricingLink: boolean;
  public leftText: string[];

  constructor(public cd: ChangeDetectorRef, public router: Router) { }

  ngOnInit(): void {
  }

}
