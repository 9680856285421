import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { MatMenuTrigger } from '@angular/material/menu';

import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { Observable } from 'rxjs';

import { AudioService } from '@app/core';
import { ExportService, ProjectManagerService, StoreService } from '../../project.module';
import { Project, UserState } from '../../types';
import { take } from 'rxjs/operators';
import { AuthService } from '@app/features/auth';


@Component({
    selector: 'nt-controls',
    templateUrl: './controls.component.html',
    styleUrls: ['./controls.component.scss', './controls-responsive.component.scss']
})
export class ControlsComponent implements OnInit, OnDestroy {

    private readonly HOTKEY_MAP = [
        new Hotkey('space', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.audio.player.isPlaying ? this.pause() : this.play();
            return true;
        }, undefined, 'Play/pause'),
        new Hotkey('right', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.skipBy(0.5)
            return true;
        }, undefined, 'Fast-Forward'),
        new Hotkey('left', (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.skipBy(-0.5)
            return true;
        }, undefined, 'Rewind'),
        new Hotkey(['l'], (event: KeyboardEvent, combo: string) => {
            event.preventDefault();
            this.toggleLoop()
            return true;
        }, undefined, 'Toggle Loop'),
    ];

    isReady: boolean = true;
    @Input('project') project: Project;
    @ViewChild('projectMenuTrigger') projectMenuTrigger: MatMenuTrigger;

    /**
     * Observable current playback time
     */
    public currentTime$: Observable<number> = this.audio.player.currentTime$;

    constructor(
        private readonly hotkeys: HotkeysService, 
        private readonly auth: AuthService,
        public readonly audio: AudioService,
        private readonly pms: ProjectManagerService,
        private readonly store: StoreService,
        protected readonly exportService: ExportService) { }

    ngOnInit(): void {
        this.hotkeys.add(this.HOTKEY_MAP);
        if (this.auth.currentUser) {
            this.pms.state.state$.pipe(take(1)).subscribe((state: UserState) => {
                if(state.state.volume) {
                    this.audio.player.volume(state.state.volume);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.HOTKEY_MAP.map((hk: Hotkey) => hk.combo).forEach((combo: string) => this.hotkeys.remove(this.hotkeys.get(combo)));
    }

    //#region Actions
   public play() {
        this.audio.player.play();
    }

    public pause() {
        this.audio.player.pause();
        this.pms.state.save(this.project.id, 'timecode', this.audio.player.currentTime).subscribe();
    }

    public stop() {
        this.audio.recorder.stop();
        this.audio.player.stop();
        this.pms.state.save(this.project.id, 'timecode', this.audio.player.currentTime).subscribe();
    }

    public startRecording() {
        this.audio.player.currentTime = 0;
        this.audio.recorder.start();
    }

    public stopRecording() {
        this.audio.recorder.stop();
        this.pms.state.save(this.project.id, 'timecode', this.audio.player.currentTime).subscribe();
    }

    public skipBy(seconds: number) {
        this.audio.player.currentTime = Math.max(0, Math.min(this.audio.player.duration, this.audio.player.currentTime + seconds));
        if(!this.audio.player.isPlaying) {
            this.pms.state.save(this.project.id, 'timecode', this.audio.player.currentTime).subscribe();
        }
    }

    public skipTo(seconds: number) {
        this.audio.player.currentTime = Math.max(0, Math.min(this.audio.player.duration, seconds));
        if(!this.audio.player.isPlaying) {
            this.pms.state.save(this.project.id, 'timecode', this.audio.player.currentTime).subscribe();
        }
    }

    public toggleLoop() {
        if (!this.audio.player.loop) {
            this.audio.player.loop = { start: 0, end: 0 };
        }
        this.audio.player.toggleLooping();
    }

    public setVolume(volume: number) {
        const vol = this.audio.player.volume(volume);
        this.pms.state.save(this.project.id, 'volume', vol).subscribe();
    }
    //#endregion

    onNameKeypress(event) {
        if(event.code == "Enter") {
            event.preventDefault();
            (event.target as HTMLSpanElement).blur();
        }
    }
    setName(event) {
        const name = (event.target as HTMLSpanElement).textContent;
        if (name !== this.project.name) {
            this.project.name = name;
            this.store.project.save(this.project, { publicKey: this.project.publicKey }).subscribe();
        }
    }

    onProjectMenu(event: MouseEvent): void {
        this.projectMenuTrigger.openMenu();
    }

    downloadMix(): void {
        this.audio.player.exportMix(this.project.name);
    }

    downloadComments(): void {
        this.exportService.exportComments(this.project);
    }
}
