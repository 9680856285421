import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ProjectManagerService, ToolService } from '../../../services';
import { Color, DrawingType, Project } from '../../../types';

@Component({
  selector: 'nt-drawing-toolbar',
  templateUrl: './drawing-toolbar.component.html',
  styleUrls: ['./drawing-toolbar.component.scss']
})
export class DrawingToolbarComponent implements OnInit {

  @Input() public project: Project;

  public ColorsList: Color[] = ['freedo','passio','balanc','spirit','creati'];
  public DrawingsList: DrawingType[] = ['bongo', 'mic', 'trumpe', 'piano', 'saxo', 'guitar', 'tambou', 'disc'];

  constructor(
    public readonly toolService: ToolService,
    public readonly pms: ProjectManagerService,
    protected readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    
  }

  setColor(color: Color): void {
    this.pms.ui.drawingColor = color;
  }

  setDrawingType(drawingType: DrawingType): void {
    this.pms.ui.drawingType = drawingType;
  }
}
