import { HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileService } from '@app/core';
import { combineLatest, Observable, Observer, of } from 'rxjs';
import { defaultIfEmpty, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { CommentStore, MediaStore, ProjectStore, AudionoteStore, DrawingStore, UserStateStore } from '../stores';
import { Audionote, Comment, Drawing, Media, Project } from '../types';


@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    public readonly project: ProjectStore,
    public readonly userState: UserStateStore,
    public readonly media: MediaStore,
    public readonly comment: CommentStore,
    public readonly audionote: AudionoteStore,
    public readonly drawing: DrawingStore,
  ) { }

}
