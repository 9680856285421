export class NtAudioBufferSourceNode extends AudioBufferSourceNode {

    public hasEnded: boolean;

    public get loop(): boolean {
        return super.loop;
    }
    public set loop(value: boolean) {
        if(this.loopEnd > this.buffer.duration) {
            // this.buffer = this.loopEnd;
            // const silence = this.context.createBuffer(this.buffer.numberOfChannels, Math.ceil(this.loopEnd), this.buffer.sampleRate);
            // const sound = this.clone(this.context, this.buffer);
            // this.buffer = null;
            // this.buffer = this.merge(this.context, silence, sound);
        }
        super.loop = value;
    }

    constructor(node: AudioBufferSourceNode) {
        super(node.context);
        Object.assign(this, node);
        this.addEventListener('ended', (ev) => this.hasEnded = true);
    }

    private merge(context: BaseAudioContext, ...buffers: Array<AudioBuffer>) {

        const nbBuffer = buffers.length;
        const maxChannels = buffers.reduce((sum: number, curr: AudioBuffer) => Math.max(sum, curr.numberOfChannels), 0);
        const maxDuration = buffers.reduce((sum: number, curr: AudioBuffer) => Math.max(sum, curr.duration), 0);
    
        // Get the output buffer (which is an array of datas) with the right number of channels and size/duration
        const mixed = context.createBuffer(maxChannels, context.sampleRate * maxDuration, context.sampleRate);        
    
        for (var i = 0; i < nbBuffer; i++){
            // For each channel contained in a buffer...
            for (let srcChannel = 0; srcChannel < buffers[i].numberOfChannels; srcChannel++) {
                const _out = mixed.getChannelData(srcChannel);// Get the channel we will mix into
                const _in = buffers[i].getChannelData(srcChannel);// Get the channel we want to mix in
    
                for (var j = 0; j < _in.length; j++) {
                    _out[j] += _in[j];// Calculate the new value for each index of the buffer array
                }
            }
        }
    
        return mixed;
    }

    private clone(context: BaseAudioContext, buffer: AudioBuffer) {
        const clone = context.createBuffer(buffer.numberOfChannels, buffer.length, buffer.sampleRate);
        return this.copy(buffer, clone);
    }

    private copy (from: AudioBuffer, to: AudioBuffer) {
        for (let channel = 0, l = Math.min(from.numberOfChannels, to.numberOfChannels); channel < l; channel++) {
            to.getChannelData(channel).set(from.getChannelData(channel));
        }
        return to;
    }
}
