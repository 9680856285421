import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, IApiOptions, IApiResponse } from '@app/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailValidationService {

  constructor(private api: ApiService) { }

  emailExist(email: string, publicKey: string): Observable<any> {
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.get<any>('emails/exists/?email=' + email, options, true).pipe(
      map((response: IApiResponse<any>) => response.result),
      catchError((error: any) => {
        return throwError(error) as Observable<any>
      })
    );
  }

  requestVerificationCode(email: string, publicKey: string): Observable<any> {
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.get<any>('emails/code/?email=' + email, options, true).pipe(
      map((response: IApiResponse<any>) => response.result),
      catchError((error: any) => {
        return throwError(error) as Observable<any>
      })
    );
  }

  validateVerificationCode(email: string, code: string, publicKey: string): Observable<any> {
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.post<any, { email: string, code: string }>('emails/code/?email=' + email, {email, code}, options, true).pipe(
      map((response: IApiResponse<any>) => response.result),
      catchError((error: any) => {
        return throwError(error) as Observable<any>
      })
    );
  }
}
