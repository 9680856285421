import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ntAutofocus]'
})
export class AutofocusDirective implements OnInit {

    @Input('ntAutofocus') focus?: boolean | string = true;

    constructor(private readonly host: ElementRef) {}

    ngOnInit(): void {
        this.setFocus();
    }

    // ngOnChanges(changes: SimpleChanges): void {
    //     this.setFocus()
    // }

    private setFocus() {
        if((this.focus || this.focus === '') && this.focus !== 'false') {
            this.host.nativeElement.focus();
            setTimeout(() => this.host.nativeElement.focus(), 500); // Seems like this is needed for mobile on some occasions
        } else if(this.host.nativeElement === document.activeElement) {
            this.host.nativeElement.blur()
        }
    }

}
