import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, OnDestroy, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';

import { Observable, timer } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import { AudioService, FileService } from '@app/core';

import { StoreService } from '../../services';
import { Audionote } from '../../types';
import { switchMap } from 'rxjs/operators';


enum AudionoteStatus {
    Initial = 0,
    Armed,
    Recording,
    Recorded,
    Playing,
    Paused
}

@Component({
    selector: 'nt-audionote',
    templateUrl: './audionote.component.html',
    styleUrls: ['./audionote.component.scss']
})
export class AudionoteComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    public readonly Statuses: typeof AudionoteStatus = AudionoteStatus;

    @HostBinding('class') get class() { return [this.Statuses[this.status].toLowerCase()]; }
    @HostBinding('class.edit-mode') @Input('editMode') editMode: boolean = false;
    @Input('audionote') audionote: Audionote;

    @ViewChild('waveform') private waveform: ElementRef;
    @ViewChild('canvas') private canvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('player') private player: ElementRef<HTMLAudioElement>;

    public status: AudionoteStatus = AudionoteStatus.Initial;
    public audioFile: File;
    public showPlayhead: boolean = false;
    public playheadOffset: number;

    private recorder: MediaRecorder;
    public recorderTime: Observable<number>;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly audio: AudioService,
        private readonly store: StoreService,
        private readonly fileService: FileService
    ) { }

    ngOnInit(): void {
        // const audioTypes = ["mp4","mp4a","3gp","QuickTime","adts","mpeg","webm", "ogg", "mp3", "x-matroska","flac","wav"];
        // const codecs = ["vp9", "vp9.0", "vp8", "vp8.0", "avc1", "av1", "h265", "h.265", "h264", "h.264", "opus", "pcm", "aac", "mpeg", "mp4a","vorbis","flac"];

        // const supportedAudios = this.getSupportedMimeTypes("audio", audioTypes, codecs);

        // console.debug('-- Top supported Audio : ', supportedAudios[0])
        // console.debug('-- All supported Audios : ', supportedAudios)
    }

    ngAfterViewInit(): void {
        if (this.canvas) {
            this.canvas.nativeElement.width = this.canvas.nativeElement.clientWidth;
        }
    }

    //   private getSupportedMimeTypes(media, types, codecs) {
    //     const isSupported = MediaRecorder.isTypeSupported;
    //     const supported = [];
    //     types.forEach((type) => {
    //       const mimeType = `${media}/${type}`;
    //       codecs.forEach((codec) => [
    //           `${mimeType};codecs=${codec}`,
    //           `${mimeType};codecs:${codec}`,
    //           `${mimeType};codecs=${codec.toUpperCase()}`,
    //           `${mimeType};codecs:${codec.toUpperCase()}`
    //         ].forEach(variation => {
    //           if(isSupported(variation)) 
    //               supported.push(variation);
    //       }));
    //       if (isSupported(mimeType))
    //         supported.push(mimeType);
    //     });
    //     return supported;
    //   }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.audionote && changes.audionote instanceof SimpleChange) {
            this.status = AudionoteStatus.Recorded;
            //   this.time = 0;
            this.showPlayhead = false;

            if (this.audionote.fAudio) {

                this.fileService.downloadFile(this.audionote.fAudio).pipe(switchMap((blob: Blob) => blob.ntArrayBuffer()), switchMap(this.audio.decodeAudioData)).subscribe((buffer: AudioBuffer) => {
                    this.player.nativeElement.src = this.audionote.fAudio.toString();
                    this.setPlayerListeners();
                    this.generateWaveform(buffer, false);
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.player?.nativeElement?.removeAllListeners();
    }

    public armRecording(): void {
        this.status = this.Statuses.Armed;
    }

    public startRecording(): void {
        this.editMode = true;

        navigator.mediaDevices.getUserMedia({ audio: true }).then(
            (stream: MediaStream) => {
                // const mimeType = MediaRecorder.isTypeSupported('audio/webm;codecs:aac') ? { mimeType: 'audio/webm;codecs=pcm'} : undefined;
                const mimeType = { /*mimeType: 'audio/webm;codecs:pcm'*/ }
                // console.assert(MediaRecorder.isTypeSupported(mimeType.mimeType));
                this.recorder = new MediaRecorder(stream, mimeType);

                const chunks: Blob[] = [];
                this.recorder.start();
                this.recorderTime = timer(0, 1000);

                this.recorder.ondataavailable = (evt: BlobEvent) => {
                    chunks.push(evt.data);
                }

                this.recorder.onstop = (evt: BlobEvent) => {
                    this.recorder.removeAllListeners();
                    delete this.recorder;
                    this.recorderTime = undefined;

                    const blob = new Blob(chunks);      

                    this.audio.blobToBuffer(blob).subscribe((buffer: AudioBuffer) => {
                        this.audioFile = this.audio.bufferToWavFile(buffer, uuidv4());
                        this.player.nativeElement.src = URL.createObjectURL(this.audioFile);
                        this.setPlayerListeners();
                        this.generateWaveform(buffer, false);
                    });
                }
            },
            (e) => {
                console.error('error', e);
            }
        );

        // **********************************************************************************

        this.status = AudionoteStatus.Recording;
    }

    public stopRecording() {
        this.status = AudionoteStatus.Recorded;
        this.recorder.stop();
        this.showPlayhead = false;
    }

    public play() {
        this.status = AudionoteStatus.Playing;
        this.player.nativeElement.play();
        this.showPlayhead = true;
    }

    public pause() {
        this.status = AudionoteStatus.Paused;
        this.player.nativeElement.pause();
        this.showPlayhead = true;
    }

    public remove() {
        if (this.audionote) {
            this.store.audionote.delete({ projectId: this.audionote.projectId, id: this.audionote.id }).subscribe((result) => {
                this.resetVariables();
            });
        } else {
            this.resetVariables();
        }
    }

    private setPlayerListeners(): void {
        this.player.nativeElement.addEventListener('ended', () => {
            this.status = AudionoteStatus.Recorded;
            this.showPlayhead = false;
            this.cdr.detectChanges();
        });

        this.player.nativeElement.addEventListener('timeupdate', () => {
            this.playheadOffset = (this.canvas.nativeElement.clientWidth / this.player.nativeElement.duration * (this.player.nativeElement.currentTime));
            this.cdr.detectChanges()
        });
    }

    private resetVariables() {
        this.audionote = null;
        this.status = AudionoteStatus.Initial;
        // this.time = 0;
        // this.timeString = '00:00';
        this.showPlayhead = false;
    }

    //#region Helpers
    private generateWaveform(buffer: AudioBuffer, stereo: boolean = false): void {
        this.canvas.nativeElement.width = this.canvas.nativeElement.clientWidth;
        
        const context = this.canvas.nativeElement.getContext('2d');
        const canvasWidth = this.canvas.nativeElement.clientWidth;
        const canvasHeight = this.waveform.nativeElement.clientHeight;
        const dataLeft = buffer.getChannelData(0);
        const dataRight = buffer.numberOfChannels >= 2 ? buffer.getChannelData(1) : null;
        const data = dataRight && stereo ? [dataLeft, dataRight] : [dataLeft];

        // we 'resample' with cumul, count, variance
        // Offset 0 : PositiveCumul  1: PositiveCount  2: PositiveVariance
        //        3 : NegativeCumul  4: NegativeCount  5: NegativeVariance
        // that makes 6 data per bucket
        const bucketSize = 6;

        const sampleCount = dataLeft.length;

        const waveSkipLength = 1;

        context.save();
        context.scale(1, 1);
        context.moveTo(0, 0);
        context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        // if(skipDraw) return true;
        data.forEach((d, dIdx, alld) => {
            const resampled = new Float64Array(canvasWidth * bucketSize);
            let min = Infinity, max = -Infinity;
            // first pass for mean
            for (let i = 0; i < sampleCount; i += waveSkipLength) {
                // in which bucket do we fall ?
                let buckIndex = 0 | (canvasWidth * i / sampleCount);
                buckIndex *= 6;
                // positive or negative ?
                const thisValue = d[i] || 0;
                if (thisValue > 0) {
                    resampled[buckIndex] += thisValue;
                    resampled[buckIndex + 1] += 1;
                } else if (thisValue < 0) {
                    resampled[buckIndex + 3] += thisValue;
                    resampled[buckIndex + 4] += 1;
                }
                if (thisValue < min) min = thisValue;
                if (thisValue > max) max = thisValue;
            }

            // compute mean now
            for (let i = 0, j = 0; i < canvasWidth; i++, j += 6) {
                if (resampled[j + 1] != 0) {
                    resampled[j] /= resampled[j + 1];
                }
                if (resampled[j + 4] != 0) {
                    resampled[j + 3] /= resampled[j + 4];
                }
            }

            // second pass for mean variation  ( variance is too low)
            for (let i = 0; i < sampleCount; i += waveSkipLength) {
                // in which bucket do we fall ?
                let buckIndex = 0 | (canvasWidth * i / sampleCount);
                buckIndex *= 6;
                // positive or negative ?
                let thisValue = dataLeft[i] || 0;
                if (thisValue > 0) {
                    resampled[buckIndex + 2] += Math.abs(resampled[buckIndex] - thisValue);
                } else if (thisValue < 0) {
                    resampled[buckIndex + 5] += Math.abs(resampled[buckIndex + 3] - thisValue);
                }
            }

            // compute mean variation/variance now
            for (let i = 0, j = 0; i < canvasWidth; i++, j += 6) {
                if (resampled[j + 1]) resampled[j + 2] /= resampled[j + 1];
                if (resampled[j + 4]) resampled[j + 5] /= resampled[j + 4];
            }

            context.scale(1, 1);
            context.translate(0, 0);
            // context.beginPath();
            // context.globalCompositeOperation = 'copy';
            context.globalAlpha = 1;


            // Draw channel separator if on secong channel
            if (dIdx > 0) {
                context.translate(0, 0);
                context.strokeStyle = 'rgba(0,0,0, 0.5)';
                context.lineWidth = 0.25;
                context.beginPath();
                context.moveTo(0, 50);
                context.lineTo(canvasWidth, 50);
                context.stroke();
                context.lineWidth = 1;
            }

            // context.fillStyle = '#888' ;
            // context.fillRect(0,0,canvasWidth,canvasHeight );
            context.translate(0.5, (alld.length === 1 ? 50 : (dIdx === 0 ? 25 : 75)));
            context.scale(1, 100);

            const strokeColor = 'rgba(153,153,153,1)';

            const reductionFactor = (alld.length === 1 ? 0.99 : 0.45);

            for (var i = 0; i < canvasWidth; i++) {
                const j = i * 6;

                // Offset 0 : PositiveCumul  1: PositiveCount  2: PositiveVariance
                //        3 : NegativeCumul  4: NegativeCount  5: NegativeVariance

                // draw from positiveAvg + variance to negativeAvg + variance 
                context.strokeStyle = strokeColor; // '#F00';
                context.beginPath();
                context.moveTo(i, (resampled[j] + resampled[j + 2]) * reductionFactor);
                context.lineTo(i, (resampled[j + 3] - resampled[j + 5]) * reductionFactor);
                context.stroke();
                // draw from positiveAvg - variance to negativeAvg - variance 
                context.strokeStyle = "rgba(0, 0, 0, 0.05)";
                context.beginPath();
                context.moveTo(i, (resampled[j] - resampled[j + 2]) * reductionFactor);
                context.lineTo(i, (resampled[j + 3] + resampled[j + 5]) * reductionFactor);
                context.stroke();
            }
            context.restore();
            context.save();
        });
    }
    //#endregion
}
