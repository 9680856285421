import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _config: { [key: string]: any } = {};

  constructor(@Inject('Window') private window: Window, private http: HttpClient) {
    this._config.api = environment.api;
    this._config.partners = environment.partners;
  }

  public load(): Promise<void> {
    const options = { observe: 'response' as 'response', responseType: 'json' as 'json', headers: new HttpHeaders({Accept: 'application/json'}) };
    const url = `${this.window.location.protocol.ctrim(':')}://${this.get('api').host.ctrim('/')}/${this.get('api').basePath.ctrim('/')}/${this.get('api').version}/frontend-config`;
    return new Promise((resolve) => {
      // tslint:disable-next-line: deprecation
      this.http.get(url, options).subscribe((response: HttpResponse<any>) => {
        Object.assign(this._config, response.body);
        resolve();
      });
    });
  }

  public get(key?: string): any {
    if (key) { return this._config[key]; }

    return this._config;
  }
}
