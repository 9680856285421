import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account.component';
import { BillingComponent, PartnerComponent, PaymentComponent, ProfileComponent, SubscriptionComponent } from './pages';

const routes: Routes = [
    {
        //#region Routes
        path: 'account',
        component: AccountComponent,
        children:[
            {
                path: 'subscription',
                component: SubscriptionComponent
            },
            {
                path: 'partner',
                component: PartnerComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'payment/:planId',
                component: PaymentComponent
            },
            {
                path: 'billing',
                component: BillingComponent
            }
        ]
    }
    //#endregion
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule { }
