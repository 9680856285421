import { ApiObject } from "@app/core";
import { IUserStateData } from "..";

export class UserState extends ApiObject<IUserStateData> {
    protected static readonly POST_FIELDS_MAP = {
        id: 'id', // Not editable but needed to build the Api URL
        state: 'state'
    };

    //#region Database
    //#region Read-only
    public readonly id: number;
    //#endregion

    //#region Editable
    public state: Record<string, any>;
    //#endregion

    //#region Timestamps
    updatedAt: Date;
    //#endregion
    //#endregion

    //#region Construction Transformers
    // protected transformStateForInit(data: string): Record<string, any> {
    //     return this.transformJSONForInit(data);
    // }
    //#endregion

    public clone<UserState extends ApiObject<IUserStateData>>(): UserState {
        return this.shallowClone(this.constructor as { new (data: IUserStateData): UserState } );
    }
}
