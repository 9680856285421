import { Injectable } from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { StoreService } from '../services';
import { Drawing } from '../types';


@Injectable({
    providedIn: 'root'
})
export class DrawingResolver implements Resolve<Observable<Array<Observable<Drawing>>>> {
    constructor(private store: StoreService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Observable<Drawing>>> {
        let publicKey: string;
        let projectId: number;
        
        // Backward compatibility for links with projectId
        if (!isNaN(route.params.projectId)) {
            projectId = parseInt(route.params.projectId, 10);
        } else {
            publicKey = route.params.projectId;
            projectId = parseInt(publicKey.substring(0, publicKey.indexOf('_')), 16);
        }

        if (isNaN(projectId) && publicKey === 'demo') { projectId = -1; }

        this.store.drawing.fetch({ projectId, publicKey });
        return this.store.drawing.list({ projectId }).pipe(take(1));
    }
}
