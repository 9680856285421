import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectComponent } from './project.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { ProjectViewComponent } from './pages/project-view/project-view.component';
import { StopAudioBeforeLeavingGuard } from './guards';
import { AudionotesResolver, CommentResolver, DrawingResolver, MediasResolver, ProjectUserStateResolver, ProjectResolver, ProjectsResolver } from './resolvers';

const routes: Routes = [
  //#region Redirect legacy urls
  { path: 'app/projects', redirectTo: 'project/list' },
  { path: 'app/project/:projectId', redirectTo: 'project/:projectId' },
  { path: 'app/audioproject/:projectId', redirectTo: 'project/:projectId' },
  //#endregion
  //#region Routes
  {
    path: 'project',
    component: ProjectComponent,
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: ProjectListComponent, resolve: { projects: ProjectsResolver} },
      { path: ':projectId', component: ProjectViewComponent, resolve: { project: ProjectResolver, userState: ProjectUserStateResolver, medias: MediasResolver, comments: CommentResolver, audionotes: AudionotesResolver, drawings: DrawingResolver }, canDeactivate: [StopAudioBeforeLeavingGuard] }
    ],
    // canActivate: [IsAuthenticatedGuard]
  }
  //#endregion
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectRoutingModule { }
