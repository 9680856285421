import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Observable, EMPTY } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { SocialAuthService } from '@abacritt/angularx-social-login';

import { AuthFormBaseComponent } from '../../components';
import { AuthComponent } from '../../auth.component';
import { AuthService } from '../../services';
import { AuthUser } from '../../types';

@Component({
  selector: 'nt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends AuthFormBaseComponent implements OnInit {

  public resetToken: string;

  // tslint:disable-next-line: variable-name
  public password_confirm: string;

  // tslint:disable-next-line: max-line-length
  constructor(
    protected authComponent: AuthComponent, protected auth: AuthService, protected socialAuth: SocialAuthService,
    protected title: Title, private route: ActivatedRoute, private router: Router, private toast: ToastrService,
  ) {
    super(title, authComponent, auth, socialAuth);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.title.setTitle('Notetracks - Reset Password');
    this.authComponent.showPricingLink = false;
    this.authComponent.leftText = ['Account', 'Recovery', 'Reset your password'];
    // tslint:disable-next-line: deprecation
    this.route.params.subscribe((params: Params) => {
      // tslint:disable-next-line: no-string-literal
      this.resetToken = params['token'];
    });
    this.authComponent.cd.detectChanges();
  }

  submit(): void {
    if (this.form.valid){
      this.isProcessing = true;
      this.submitted = true;
      this.auth.confirmPasswordReset(this.resetToken, this.credentials.password).pipe(
        finalize(() => {
          this.isProcessing = false;
        }),
        catchError((response: HttpErrorResponse, caught: Observable<AuthUser>) => {
          console.warn(response);
          this.error = response.error.notice.message || response.message;
          return EMPTY;
        })
      // tslint:disable-next-line: deprecation
      ).subscribe((user: AuthUser) => {
        this.toast.success('Password changed.');
        this.router.navigate(['/auth/login']);
      });
    }
  }

}
