import { Component, ElementRef, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';

import { AudioService } from '@app/core';
import { ProjectManagerService, StoreService } from '../../../services';

const colorRgb = {
    passio: 'rgba(242,102,102,1)',
    freedo: 'rgba(255,190,93,1)',
    balanc: 'rgba(141,216,100,1)',
    spirit: 'rgba(122,190,250,1)',
    creati: 'rgba(187,152,230,1)',
}

@Component({
    template: ''
})
export abstract class BaseTrackComponent{

    abstract readonly audio: AudioService;
    abstract readonly pms: ProjectManagerService

    // private _waveformOffset: number = 0;
    // public get waveformOffset(): number {
    //     return this._waveformOffset;
    // }

    public isAudioReady: boolean = false;

    // @ViewChild('waveform') waveform: ElementRef;
    @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

    protected _destroy: Subject<void> = new Subject();

    //#region Helpers
    drawWaveform(buffer: AudioBuffer, duration: number, color: string, strereo: boolean = true) {
        const context = this.canvas.nativeElement.getContext('2d');
        const canvasWidth = 16384 * 3;

        const dataLeft = buffer.getChannelData(0);
        const dataRight = buffer.numberOfChannels >= 2 ? buffer.getChannelData(1) : null;
        const data = dataRight && strereo ? [dataLeft, dataRight] : [dataLeft];
        // we 'resample' with cumul, count, variance
        // Offset 0 : PositiveCumul  1: PositiveCount  2: PositiveVariance
        //        3 : NegativeCumul  4: NegativeCount  5: NegativeVariance
        // that makes 6 data per bucket
        const bucketSize = 6;
        
        const padLength = Math.max(0, (this.audio.player.duration - duration) * buffer.sampleRate);
        const sampleCount = dataLeft.length + padLength;

        const waveSkipLength = 1;

        try {
            context.save();
            context.scale(1, 1);
            context.moveTo(0, 0);
            context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
            // if(skipDraw) return true;
            data.forEach((d, dIdx, alld) => {
                const resampled = new Float64Array(canvasWidth * bucketSize);
                let min = Infinity, max = -Infinity;
                // first pass for mean
                for (let i = 0; i < sampleCount; i += waveSkipLength) {
                    // in which bucket do we fall ?
                    let buckIndex = 0 | (canvasWidth * i / sampleCount);
                    buckIndex *= 6;
                    // positive or negative ?
                    const thisValue = d[i] || 0;
                    if (thisValue > 0) {
                        resampled[buckIndex] += thisValue;
                        resampled[buckIndex + 1] += 1;
                    } else if (thisValue < 0) {
                        resampled[buckIndex + 3] += thisValue;
                        resampled[buckIndex + 4] += 1;
                    }
                    if (thisValue < min) min = thisValue;
                    if (thisValue > max) max = thisValue;
                }

                // compute mean now
                for (let i = 0, j = 0; i < canvasWidth; i++, j += 6) {
                    if (resampled[j + 1] != 0) {
                        resampled[j] /= resampled[j + 1];;
                    }
                    if (resampled[j + 4] != 0) {
                        resampled[j + 3] /= resampled[j + 4];
                    }
                }

                // second pass for mean variation  ( variance is too low)
                for (let i = 0; i < sampleCount; i += waveSkipLength) {
                    // in which bucket do we fall ?
                    let buckIndex = 0 | (canvasWidth * i / sampleCount);
                    buckIndex *= 6;
                    // positive or negative ?
                    let thisValue = dataLeft[i] || 0;
                    if (thisValue > 0) {
                        resampled[buckIndex + 2] += Math.abs(resampled[buckIndex] - thisValue);
                    } else if (thisValue < 0) {
                        resampled[buckIndex + 5] += Math.abs(resampled[buckIndex + 3] - thisValue);
                    }
                }

                // compute mean variation/variance now
                for (let i = 0, j = 0; i < canvasWidth; i++, j += 6) {
                    if (resampled[j + 1]) resampled[j + 2] /= resampled[j + 1];
                    if (resampled[j + 4]) resampled[j + 5] /= resampled[j + 4];
                }

                context.scale(1, 1);
                context.translate(0, 0);
                // context.beginPath();
                // context.globalCompositeOperation = 'copy';
                context.globalAlpha = 1;


                // Draw channel separator if on secong channel
                if (dIdx > 0) {
                    context.translate(0, 0);
                    context.strokeStyle = colorRgb[color || 'freedo'].replace(',1)', ',0.25)'); //'rgba(0,0,0, 0.5)';
                    context.lineWidth = 0.25;
                    context.beginPath();
                    context.moveTo(0, 50);
                    context.lineTo(canvasWidth, 50);
                    context.stroke();
                    context.lineWidth = 1;
                }

                // context.fillStyle = '#888' ;
                // context.fillRect(0,0,canvasWidth,canvasHeight );
                context.translate(0.5, (alld.length === 1 ? 50 : (dIdx === 0 ? 25 : 75)));
                context.scale(1, 100);

                const strokeColor = colorRgb[color || 'freedo'];

                const reductionFactor = (alld.length === 1 ? 0.8 : 0.4);
                for (var i = 0; i < canvasWidth; i++) {
                    const j = i * 6;

                    // Offset 0 : PositiveCumul  1: PositiveCount  2: PositiveVariance
                    //        3 : NegativeCumul  4: NegativeCount  5: NegativeVariance

                    // draw from positiveAvg + variance to negativeAvg + variance 
                    context.strokeStyle = strokeColor; // '#F00';
                    context.beginPath();
                    context.moveTo(i, Math.min(0.5, (resampled[j] /*+ resampled[j + 2]*/)) * reductionFactor);
                    context.lineTo(i, Math.max(-0.5, (resampled[j + 3] /*- resampled[j + 5]*/)) * reductionFactor);
                    context.stroke();
                    // draw from positiveAvg - variance to negativeAvg - variance 
                    // context.strokeStyle = "rgba(0, 0, 0, 0.05)";
                    // context.beginPath();
                    // context.moveTo(i, Math.max(-0.5, (resampled[j] - resampled[j + 2])) * reductionFactor);
                    // context.lineTo(i, Math.min(0.5, (resampled[j + 3] + resampled[j + 5])) * reductionFactor);
                    // context.stroke();
                    // // draw from positiveAvg - variance to positiveAvg + variance 
                    // context.strokeStyle = strokeColor; // '#FFF';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j] - resampled[j+2] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j] + resampled[j+2] ) * reductionFactor );
                    // context.stroke();
                    // draw from negativeAvg + variance to negativeAvg - variance 
                    // // context.strokeStyle = '#FFF';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j+3] + resampled[j+5] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j+3] - resampled[j+5] ) * reductionFactor );
                    // context.stroke();


                    //#region Old Logic
                    // // draw from positiveAvg - variance to negativeAvg - variance 
                    // context.strokeStyle = strokeColor; // '#F00';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j] - resampled[j+2] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j +3] + resampled[j+5] ) * reductionFactor );
                    // context.stroke();
                    // // draw from positiveAvg - variance to positiveAvg + variance 
                    // context.strokeStyle = strokeColor; // '#FFF';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j] - resampled[j+2] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j] + resampled[j+2] ) * reductionFactor );
                    // context.stroke();
                    // // draw from negativeAvg + variance to negativeAvg - variance 
                    // // context.strokeStyle = '#FFF';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j+3] + resampled[j+5] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j+3] - resampled[j+5] ) * reductionFactor );
                    // context.stroke();
                    //#endregion
                }
                context.restore();
                context.save();
            });
        } catch (e) {
            console.warn('Error while drawing waveform', e);
        }

        return true;
    }

    //#endregion
}
