<div class="colors-container">
    <label>Color:</label>
    <ng-container *ngFor="let color of ColorsList">
        <span color [ngClass]="{selected: color == pms.ui.drawingColor}" class="{{ color }}" (click)="setColor(color)">
            <span inner></span>
        </span>
    </ng-container>
</div>
<div class="drawings-container">
    <label>Tags:</label>
    <ng-container *ngFor="let drawing of DrawingsList">
        <span drawing ntDraggable ntDraggableDropSelector=".drawingRow,.drawingsPane" ntDraggableTriggerDropOn="target" [ntDraggableGhost]="true" (start)="setDrawingType(drawing)"
            class="{{ drawing }} {{ pms.ui.drawingColor }} {{ drawing == pms.ui.drawingType ? 'selected' : '' }}" >
        </span>
    </ng-container>
    <span class="separator"></span>
    <span drawing ntDraggable ntDraggableDropSelector=".drawingRow,.drawingsPane" ntDraggableTriggerDropOn="target" [ntDraggableGhost]="true" (start)="setDrawingType('text')"
        class="text {{ pms.ui.drawingColor }} {{ pms.ui.drawingType == 'text' ? 'selected' : '' }}"></span>
    <span class="separator"></span>
    <span drawing ntDraggable ntDraggableDropSelector=".drawingRow,.drawingsPane" ntDraggableTriggerDropOn="target" [ntDraggableGhost]="true" (start)="setDrawingType('simvec')"
        class="simvec {{ pms.ui.drawingColor }} {{ pms.ui.drawingType == 'simvec' ? 'selected' : '' }}"></span>
</div>
