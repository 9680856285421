<!-- #region TOOLBAR-->
<nt-app-toolbar logo="icon" profile="icon" [logoLink]="undefined" (logoClick)="goToProjectsList()" [user]="auth.user$ | async">
    <button postLogo class="exit dark" [inlineSVG]="'/assets/images/icons/exit.svg'" [prepend]="true" (mouseup)="goToProjectsList()">
        <span>Exit Project</span>
    </button>

    <div class="tools">
        <span select [routerLink]="['.']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [inlineSVG]="'/assets/images/icons/phylactery.svg'" [prepend]="true">Comment</span> <!-- Select pointer -->
        <!-- <span comment [routerLink]="['.']" [queryParams]="{ tool: Tool.COMMENT }" routerLinkActive="active" [inlineSVG]="'/assets/images/icons/phylactery.svg'" [prepend]="true">Comment</span> -->
        <!-- <span draw [routerLink]="['.']" [queryParams]="{ tool: Tool.DRAW }" routerLinkActive="active" [inlineSVG]="'/assets/images/icons/pencil.svg'" [prepend]="true">Draw</span> -->
        <span draw [routerLink]="['.']" [queryParams]="{ tool: Tool.EDITING }" routerLinkActive="active" [inlineSVG]="'/assets/images/icons/editing.svg'" [prepend]="true">Editing</span>
        <span tag [routerLink]="['.']" [queryParams]="{ tool: Tool.TAG }" routerLinkActive="active" [inlineSVG]="'/assets/images/icons/pencil.svg'" [prepend]="true">Draw</span> <!-- Tag hashtag -->
    </div>

    <button preProfile *ngIf="(project$ | async)?.id > 0; else saveDemo" share [inlineSVG]="'/assets/images/icons/reply.svg'" [prepend]="true" (click)="shareProject()">
        Share Project
    </button>
    <ng-template #saveDemo>
        <button preProfile saveDemo [inlineSVG]="'/assets/images/icons/upload.svg'" [prepend]="true" (click)="saveDemoProject()">
            Save Project
        </button>
    </ng-template>

    <ng-container profileExtra>
        <nt-collaborator-bar [collaborators]="collaborators$"></nt-collaborator-bar>
    </ng-container>

</nt-app-toolbar>
<!-- #endregion -->

<!-- #region Tool Palette -->
<div id="palette">
    <div class="section left">
        <!-- <span class="undo disabled" [inlineSVG]="'/assets/images/icons/back.svg'"></span> -->
        <!-- <span class="redo disabled" [inlineSVG]="'/assets/images/icons/back.svg'"></span> -->
        <ng-container>
            <button addTrack (click)="addTrack()" [inlineSVG]="'/assets/images/icons/plus.svg'" [prepend]="true"><span>Add track</span></button>
            <button addMarker (click)="addMarker()" [inlineSVG]="'/assets/images/icons/commentmarker.svg'" [prepend]="true"><span>Add Marker</span></button>
            <button newComment (click)="newComment()" [inlineSVG]="'/assets/images/icons/phylactery.svg'" [prepend]="true"><span>New Comment</span></button>
        </ng-container>
        
    </div>
    <div class="section center">
        <nt-drawing-toolbar *ngIf="sequencer.isRulerReady" [project]="project$ | async" [hidden]="pms.ui.toolMode != Tool.TAG"></nt-drawing-toolbar>
        <ng-container *ngIf="pms.ui.toolMode == Tool.SELECT && sequencer.isReady">
            <!-- <nt-navigation-bar [project]="project$ | async" [isReady]="sequencer.isReady"></nt-navigation-bar> -->
        </ng-container>
    </div>
    <div class="section right">
        <ng-container *ngIf="(pms.ui.video$ | async)">
            <ng-container *ngIf="(pms.ui.video$ | async) && (pms.ui.videoDisplayFactor$ | async) || true as videoDisplayFactor">
                <mat-icon #videoTrigger="matMenuTrigger" [matMenuTriggerFor]="videoMenu" class="material-icons-outlined">movie</mat-icon>
                <mat-menu #videoMenu="matMenu" yPosition="below" class="video-menu">
                    <span class="video-menu-option" [ngClass]="{ active: videoDisplayFactor === true }" (click)="pms.ui.setVideoDisplayFactor(0)">
                        <span [inlineSVG]="'/assets/images/icons/hidden.svg'"></span>
                    </span>
                    <span class="video-menu-option" [ngClass]="{ active: videoDisplayFactor === 0.25 }" (click)="pms.ui.setVideoDisplayFactor(0.25)">
                        1/4x
                    </span>
                    <span class="video-menu-option" [ngClass]="{ active: videoDisplayFactor === 0.5 }" (click)="pms.ui.setVideoDisplayFactor(0.5)">
                        1/2x
                    </span>
                    <span class="video-menu-option" [ngClass]="{ active: videoDisplayFactor === 0.75 }" (click)="pms.ui.setVideoDisplayFactor(0.75)">
                        3/2x
                    </span>
                    <span class="video-menu-option" [ngClass]="{ active: videoDisplayFactor === 1 }" (click)="pms.ui.setVideoDisplayFactor(1)">
                        1x
                    </span>
                    <span class="video-menu-option" [ngClass]="{ active: videoDisplayFactor === 1.5 }" (click)="pms.ui.setVideoDisplayFactor(1.5)">
                        1.5x
                    </span>
                    <span class="video-menu-option" [ngClass]="{ active: videoDisplayFactor === 2 }" (click)="pms.ui.setVideoDisplayFactor(2)">
                        2x
                    </span>
                    <!-- <span class="video-menu-option" (click)="fullscreen()">
                        <mat-icon class="material-icons-outlined">fullscreen</mat-icon>
                    </span> -->
                </mat-menu>
            </ng-container>
        </ng-container>
        <span class="notepad" [ngClass]="{active: notepad}" [inlineSVG]="'/assets/images/icons/notepad.svg'" (click)="toggleNotepad()"></span>
        <!-- <span class="comments_view vertical" [ngClass]="{active: journal}" [inlineSVG]="'/assets/images/icons/vertical_lines.svg'" (click)="toggleJournal()"></span> -->
        <span class="comments_view horizontal" [ngClass]="{active: !journal}" [inlineSVG]="'/assets/images/icons/horizontal_lines.svg'" (click)="toggleJournal()"></span>
    </div>
    <!-- <span class="journal" [inlineSVG]="'/assets/images/icons/phylactery.svg'" (click)="toggleJournal()"></span> -->
</div>
<!-- #endregion -->

<!-- #region Sequencer-->
<nt-sequencer #sequencer [project]="project$ | async" [medias]="medias$ | async" ntFileDragAndDrop [mediaFilter]="pms.ui.mediaFilter$ | async" (onFilesDropped)="sequencer.onFilesDropped($event)" 
    (downloadProgressChange)="downloadProgressChange($event)"
    (mediasBeingLoadedChange)="mediasBeingLoadedChange($event)"></nt-sequencer>
<!-- #endregion -->

<!-- #region Controls-->
<nt-controls [project]="project$ | async"></nt-controls>
<!-- #endregion -->

<!-- #region Notepad -->
<nt-notepad [project$]="project$" [editable]="(project$ | async)?.userCanWrite"></nt-notepad>
<!-- #endregion -->

<!-- #region Journal-->
<nt-comment-journal id="journal" [project]="project$ | async" [medias$]="medias$" (close)="toggleJournal()"></nt-comment-journal>
<!-- #endregion -->

<!-- #region Loading-->
<div loader *ngIf="!sequencer.isReady || demoSaving">
    <div class="shaft-load5">
        <div class="shaft1"></div>
        <div class="shaft2"></div>
        <div class="shaft3"></div>
        <div class="shaft4"></div>
        <div class="shaft5"></div>
        <div class="shaft6"></div>
        <div class="shaft7"></div>
        <div class="shaft8"></div>
        <div class="shaft9"></div>
        <div class="shaft10"></div>
        <span class="loading">
            <ng-container *ngIf="demoSaving; else loading">Saving</ng-container>
            <ng-template #loading>Loading</ng-template>...&nbsp;{{ downloadProgress || 0 }}%</span>
    </div>
</div>
<!-- #endregion -->