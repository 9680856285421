import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';

import { SocketEventMessage, SocketService, Store } from '@app/core';
import { IMediaData, Media } from '../types';
import { MediaDataService } from '../services';
import { HttpProgressEvent } from '@angular/common/http';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class MediaStore extends Store<Media, IMediaData, MediaDataService> implements OnDestroy {

    protected static override LIST_KEY_PARTS = ['projectId'];

    private _uploadProgress$$: Record<string, BehaviorSubject<HttpProgressEvent & { status?: string }>> = {};


    constructor(protected service: MediaDataService, protected readonly socket: SocketService) {
        super(socket);
    }

    ngOnDestroy(): void {
        this.clearSubscriptions();
    }
  
    protected observe(): Observable<SocketEventMessage> {
        return this.socket.observe(Media).pipe(shareReplay(1));
    }

    getUploadProgress(id: number): Observable<HttpProgressEvent & { status?: string }> {
        const identity = this._idUuidMap[id];
        return this._uploadProgress$$[identity] && !this._uploadProgress$$[identity].isStopped ? this._uploadProgress$$[identity]?.asObservable() : null;
    }

    // upload(media: Media, file: File, publicKey: string) {
    //     return this.service.save({ ...media.toPostableData(), file: file }, { publicKey: publicKey });
    // }

    protected override mergeItem(current: Media, item: Media): Media {
        if(current && item.status !== 'processed') {
            return Object.assign(item, current, { status: current.status }); // If not processed, we want to keep the local values
        }

        return super.mergeItem(current, item);
    }

    override upload(item: Partial<Media>, file: File, params?: Record<string, any>): Observable<Media | HttpProgressEvent> {
        const identity = this._idUuidMap[item.id];
        this._uploadProgress$$[identity] = new BehaviorSubject(undefined);

        return super.upload(item, file, params).pipe(tap((response: Media | HttpProgressEvent) => {
            if(response instanceof Media) {
                const status = item.id < 0 ? 'processed' : response.status; // DEMO/Offline
                const next = { ...this._uploadProgress$$[identity].value, status };
                this._uploadProgress$$[identity].next(next);
                if(response.status === 'processed') {
                    this._uploadProgress$$[identity].complete();
                }
            } else {
                this._uploadProgress$$[identity].next(response);
            }

            if(response instanceof Media && response.status !== 'processed') {
                this.observe().pipe(
                    filter((message: SocketEventMessage) => message.object_id === item.id),
                    switchMap((message: SocketEventMessage) => {
                        return this.socket.publicKey$.pipe(
                            take(1),
                            switchMap((publicKey) => {
                                return this.fetch({ projectId: message.project_id, id: message.object_id, publicKey }, true) as Observable<Media>;
                            })
                        );
                    }),
                    tap((m: Media) => this._uploadProgress$$[identity].next({ ...this._uploadProgress$$[identity].value, status: m.status }))
                ).subscribe((m: Media) => {
                    if(m.status === 'processed') {
                        this._uploadProgress$$[identity].complete();
                    }
                });
            }
        }));
    }
}
