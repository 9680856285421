import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormComponent } from '@app/shared/components';
import { ProjectManagerService, StoreService } from '../../services';
import { IProjectData, Project, Share } from '../../types';
import { Clipboard } from '@angular/cdk/clipboard';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSelectChange } from '@angular/material/select';
import { AudioService } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { MatCheckbox } from '@angular/material/checkbox';
import { ConfirmationDialogComponent } from '@app/shared';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from '@app/config.service';

export interface IRecipientShare {
    name: string,
    email: string
}

export interface IPermission {
    code: string,
    description: string
}

export interface IShareMode {
    code: string,
    description: string
}

@Component({
    selector: 'nt-project-share-dialog',
    templateUrl: './project-share-dialog.component.html',
    styleUrls: ['./project-share-dialog.component.scss']
})
export class ProjectShareDialogComponent extends FormComponent implements OnInit, AfterViewInit {

    public readonly PERMISSIONS: IPermission[] = [
        { code: 'rw', description: 'Can edit' },
        { code: 'rc', description: 'Can comment' },
        { code: 'r', description: 'Can view' }
    ];

    public readonly PERMISSIONS_WITH_REMOVE: IPermission[] = [
        { code: 'rw', description: 'Can edit' },
        { code: 'rc', description: 'Can comment' },
        { code: 'r', description: 'Can view' },
        { code: 'rm', description: 'Remove' }
    ];

    private readonly SHARE_MODES: IShareMode[] = [
        { code: 'an', description: 'Anyone' },
        { code: 'im', description: 'Invited members' }
    ];

    private _project$$: BehaviorSubject<Project> = new BehaviorSubject(undefined);
    public project$: Observable<Project> = this._project$$.pipe(filter((p: Project) => p !== undefined));

    //#region Share Mode / Public Share
    public shareURL: string;
    public shareMode$: Observable<IShareMode> = this.project$.pipe(map((project: Project) => project.publicPermission !== "" ? this.SHARE_MODES[0] : this.SHARE_MODES[1]))
    public publicPermission$: Observable<IPermission> = this.project$.pipe(map((project: Project) => this.PERMISSIONS.find((p: IPermission) => p.code === project.publicPermission)));
    //#endregion

    //#region Invitation
    public readonly separatorKeysCodes = [ENTER, COMMA] as const;
    public recipients: IRecipientShare[] = [];
    public invitePermission: IPermission;
    public invitationMessage: string;
    public errorMessage: string = '';
    //#endregion

    shares$: Observable<Array<Share>>;
    public timeCode: number = 0;
    public timeCodeAux: number = 0;
    @ViewChild("chkParameter") chkParameter: MatCheckbox;
    @ViewChild("txtTimecode") txtTimecode: ElementRef<HTMLInputElement>;
    public editingTimecode: boolean;

    // Work around for share not having a real store
    private _updateShares$$: BehaviorSubject<void> = new BehaviorSubject(undefined);

    private _destroy: Subject<void> = new Subject();

    public projectShareText: string;
    public encodedProjectName: string;
    public encodedProjectURL: string;
    @ViewChild('shareTeams') divShareTeams: ElementRef<HTMLDivElement>;

    constructor(
        @Inject(MAT_DIALOG_DATA) data, private readonly router: Router,
        private readonly location: Location, private readonly clipboard: Clipboard,
        private readonly pms: ProjectManagerService, private readonly store: StoreService,
        public readonly audio: AudioService, private readonly toast: ToastrService,
        public confirmationDialog: MatDialog, private config: ConfigService,
        private readonly elementRef: ElementRef
    ) {
        super();
        if(data.project && data.project instanceof Project) {
            this.store.project.get(data.project.id).subscribe(this._project$$)
        } else {
            this.pms.project$.subscribe(this._project$$);
        }
    }

    //#region Lifecycle
    ngOnInit(): void {
        this.shares$ = this.project$.pipe(
            takeUntil(this._destroy),
            tap((project: Project) => {
                const shareUrlTree = this.router.createUrlTree(['/project', project.publicKey]);
                const sharePath = this.location.prepareExternalUrl(shareUrlTree.toString());
                this.shareURL = `${location.origin}/${sharePath}`;

                this.projectShareText = project.user.nameFirst + ' ' + project.user.nameLast + ' has shared a Notetracks project: ' + project.name;
                this.encodedProjectName = encodeURIComponent(this.projectShareText);
                this.encodedProjectURL = encodeURIComponent(this.shareURL);
            }),
            switchMap((project: Project) => {
                return this._updateShares$$.pipe(
                    switchMap(() => this.store.project.listShare({ id: project.id }))
                )
            })
        );

        this.resetInvitation();
        this.timeCode = Math.round(this.audio.player.currentTime * 100) / 100;
        this.timeCodeAux = this.timeCode;
    }
    //#endregion

    ngAfterViewInit(): void {
        // Adding the reference to the Facebook Messenger API
        const s = document.createElement('script');
        s.type = "text/javascript";
        s.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0";
        s.id = "facebookMessenger";
        s.nonce = "ywRETNx8";
        s.crossOrigin = "anonymous";
        s.setAttribute("appId", this.config.get('facebook').app_key);
        s.setAttribute("autoLogAppEvents", "1");
  
        s.onload = () => {
            console.info('Facebook messenger sdk loaded!');

            FB.init({
                appId: this.config.get('facebook').app_id,
                xfbml: true,
                version: 'v14.0'
            });
            // FB.AppEvents.logPageView();
        };

        this.elementRef.nativeElement.appendChild(s);
        // End

        // Adding the reference to the Share to Teams feature
        const st = document.createElement('script');
        st.type = 'text/javascript';
        st.src = 'https://teams.microsoft.com/share/launcher.js';
        st.async = true;
        st.defer = true;
        st.onload = () => {
            console.info('microsoft Teams launcher is loaded!');
        };
        this.elementRef.nativeElement.appendChild(st);
        this.divShareTeams.nativeElement.setAttribute('data-href', this.shareURL);
        this.divShareTeams.nativeElement.setAttribute('data-msg-text', this.projectShareText);
        // End
      }

    //#region Actions
    addRecipient(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if(value) {
            if (this.validateEmail(value)) {
                this.recipients.push({ name: '', email: value });
            } else {
                this.toast.error('Email entered is not valid.');
            }
        }

        event.chipInput!.clear();
    }

    removeRecipient(recipient: IRecipientShare): void {
        const index = this.recipients.indexOf(recipient);

        if (index >= 0) {
            this.recipients.splice(index, 1);
            this.checkErrors();
        }
    }

    submit(): void {
        this.project$.pipe(take(1)).subscribe((project: Project) => {
            const basePayload = {
                project_id: project.id,
                permission: this.invitePermission.code,
                message: this.invitationMessage || ''
            };
            
            const results: Array<Observable<Share>> = this.recipients.map((recipient: IRecipientShare) => {
                const payload = Object.assign({ email: recipient.email }, basePayload);
                return this.store.project.saveShare(payload);
            });
            combineLatest(results).subscribe((r: Share[]) => {
                // this.shares$ = this.store.project.listShare({ id: this.project.id });
                this._updateShares$$.next();
                this.resetInvitation();
            });
        });
    }

    copyLink(): void {
        this.shareMode$.pipe(take(1)).subscribe((shareMode: IShareMode) => {
            this.clipboard.copy(this.shareURL);
            if(shareMode.code === 'im') {
                this.savePublicPermission(this.PERMISSIONS[1].code);
                this.toast.success('Link copied to clipboard');
            } else {
                this.toast.success('Link copied');
            }
        });
    }
    //#endregion

    //#region EventHandlers
    onSharePermissionChanged(event: MatSelectChange, share: Share) {
        if(event.value !== share.permission) {
            share.permission = event.value;

            if (share.permission === 'rm') {
                this.removeCollaborator(share);
            } else {
                this.store.project.saveShare(share.toPostableData()).subscribe((s: Share) => {
                    Object.assign(share, s);
                });
            }
        }
    }

    // onShareModeChanged(event: MatSelectChange){
    //     let permission = '';
    //     if(event.value.code === 'an') {
    //         permission = this.publicPermission.code;
    //     }

    //     this.savePublicPermission(permission);
    // }

    // onPublicPermissionChanged(event: MatSelectChange) {
    //     this.savePublicPermission(event.value.code);
    // }
    //#endregion

    //#region Helpers
    //#region Invited Collaborators
    private removeCollaborator(share: Share): void {
        this.confirmationDialog.open(ConfirmationDialogComponent, {
            autoFocus: false,
            panelClass: 'confirmation-dialog',
            width: '300px',
            data: {
              title: 'Remove ' + share.user.email,
              message: 'If you remove ' + share.user.email + ', they won\'t be able to see future changes to this file',
              cancelLabel: 'Cancel',
              acceptLabel: 'Remove'
            }
          })
          .afterClosed()
          .subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.store.project.removeShare(share.toPostableData()).subscribe(() => {
                    this._updateShares$$.next();
                });
            }
          });
    }
    //#endregion

    //#region Public Share / Permissions
    savePublicPermission(permission: string) {
        this.project$.pipe(take(1)).subscribe((project: Project) => {
            const payload = {
                id: project.id,
                public_permission: permission
            }
    
            const p = new Project(payload as unknown as IProjectData) as Partial<Project>
    
            this.store.project.save(p).subscribe((project: Project) => {
                Object.assign(project, project);
            });
        });
    }
    //#endregion

    //#region Shares/Recipients
    hasErrors(): boolean {
        return this.recipients.some(recipient => !this.validateEmail(recipient.email));
    }

    private checkErrors(): void {
        if (this.hasErrors()) {
            this.errorMessage = 'Sorry, could not understand the invalid email addresses or names.';
        } else {
            this.errorMessage = '';
        }
    }

    private validateEmail(email: string): boolean {
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    }

    private resetInvitation() {
        this.recipients.splice(0);
        this.invitePermission = this.PERMISSIONS[0];
        this.invitationMessage = undefined;
    }
    //#endregion
    //#endregion

    public setParameter() {
        // let param = '?t=' + this.audio.player.currentTime.toFixed(2);
        let param = '?t=' + this.timeCode;
        if (this.shareURL.includes(param)) {
            this.shareURL = this.shareURL.replace(param, '');
        } else {
            this.shareURL = this.shareURL + param;
        }
        this.encodedProjectURL = encodeURIComponent(this.shareURL);
    }

    public setShareMode(mode: IShareMode) {
        let permission = '';
        if(mode.code === 'an') {
            permission = this.PERMISSIONS[0].code;
        }

        this.savePublicPermission(permission);
    }

    public setPermission(permission: IPermission) {
        this.savePublicPermission(permission.code);
    }

    public setTimecode(): void {

        if (this.timeCodeAux < 0 || this.timeCodeAux > this.audio.player.duration) {
            this.timeCodeAux = this.timeCode
        } else {
            if (this.chkParameter.checked) {
                let param = '?t=' + this.timeCode;
                this.shareURL = this.shareURL.replace(param, '');
                this.timeCode = this.timeCodeAux;
                param = '?t=' + this.timeCode;
                this.shareURL = this.shareURL + param;
            } else {
                this.timeCode = this.timeCodeAux;
            }
        }

        this.editingTimecode = false;
    }

    public onEdit(): void {
        this.editingTimecode = true;
        setTimeout(() => {
            this.txtTimecode.nativeElement.focus();
        }, 50);
    }

    // Facebook Messenger
    public isMobile() {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
    
    public messengerShare() {
        let url = this.shareURL;
        if (this.isMobile()) {
            window.location.href = "fb-messenger://share/?link=" + url;
        } else {
            FB.ui({
                method: 'send',
                link: url,
                redirect_uri: url
            });
        }
    }
    // End
}
