<!-- start: LOGIN BOX -->
<div class="box-login">
    <div *ngIf="error" class="error-wrapper">
        <ngb-alert (close)="clearError()" [dismissible]="true" [type]="'danger'">
            {{ error }}
        </ngb-alert>
    </div>
	<div class="titles">
		<div class="maintitle">Try Notetracks for Free</div>
		<div class="subtitle">7-day free trial | No credit card needed</div>
	</div>
	<form class="form-login form-register" [ngClass]="{ invitation: partner != '' }" fxLayout="column" (ngSubmit)="submit()" #theForm="ngForm">
		<div class="form-group logo">
			<a class="logo"></a>
		</div>
		<ng-container *ngIf="config.get('google').auth_enabled && !(partner != '')">
            <div class="form-group">
                
                <span fxFlex="75" fxLayout="column" fxLayoutAlign="center center" class="input">
                    <button class="google" type="button" [disabled]="isProcessing" (click)="loginWithGoogle()">
                        <div class="glogo"></div>
                        <span>Sign up with Google</span>
                    </button>
                </span>
                
            </div>

            <div class="form-group dvLabel">
                <span fxFlex="75" class="title3">OR</span>
            </div>
        </ng-container>
        <div class="form-group dvLabel no-content">&nbsp;</div>

        <div class="form-group">
			<span fxFlex="75">
					<span fxFlex="48">
						<label class="title4">First name</label>
						<input type="text" class="form-control first half" [disabled]="isProcessing"
						name="name_first" #nameFirst="ngModel" [(ngModel)]="credentials.name_first"
						[ngClass]="{error: this.submitted && nameFirst.invalid}"
						i18n-placeholder placeholder="First name" required>
					</span>
					<span fxFlex="4" class="no-content"></span>
					<span fxFlex="48">
						<label class="title4">Last name</label>
						<input type="text" class="form-control last half" [disabled]="isProcessing"
						name="name_last" #nameLast="ngModel" [(ngModel)]="credentials.name_last"
						[ngClass]="{error: this.submitted && nameLast.invalid}"
						i18n-placeholder placeholder="Last name" required>
					</span>
				
			</span>
        </div>
        <div class="form-group">
			<span fxFlex="75">
				<label class="title4">Email address</label>
				<input type="email" class="form-control" [disabled]="isProcessing"
					   name="email" #email="ngModel" [(ngModel)]="credentials.email" i18n-placeholder
					   [ngClass]="{error: this.submitted && theForm.controls.email.invalid}"
					   placeholder="Email" email required>
			</span>
        </div>
        <div class="form-group">
			<span fxFlex="75">
				
				<span fxFlex="48">
					<label class="title4">Password</label>
					<input type="password" class="form-control password first half" [disabled]="isProcessing"
					name="password" #password="ngModel" [(ngModel)]="credentials.password"
					[ngClass]="{error: this.submitted && password.invalid}"
					i18n-placeholder placeholder="Type password" minlength="6" required>
				</span>
				<span fxFlex="4" class="no-content"></span>
				<span fxFlex="48">
					<label class="title4">Confirm Password</label>
					<input type="password" class="form-control last half" [disabled]="isProcessing"
					name="password_confirm" #passwordConfirm="ngModel" [(ngModel)]="password_confirm"
					[ngClass]="{error: this.submitted && passwordConfirm.invalid}"
					i18n-placeholder placeholder="Re-enter password"
					match="password">
				</span>
			</span>
        </div>

        <!--<div *ngIf="config.get('invitation').enabled && !inviteToken" class="form-group">-->
		<div *ngIf="partner" class="form-group">
			<span fxFlex="75">
				<label class="title4">invitation</label>
				<input type="text" class="form-control" [disabled]="isProcessing"
					   name="invitation_code" #invitationCode="ngModel" [(ngModel)]="code"
					   [ngClass]="{error: this.submitted && invitationCode.invalid}"
					   i18n-placeholder placeholder="{{ partnerName }} code" required>
			</span>
        </div>

		<div class="form-group">
			<span fxFlex="75">
				<mat-checkbox (change)="termsAccepted = !termsAccepted" [color]="'#FFBE5D'">
					<span class="title5">&nbsp;By signing up you agree to our <a class="nomargin basiclink" href="http://notetracks.com/pro/terms">T&amp;C</a></span>
				</mat-checkbox>
				<div class="red" *ngIf="tcUnchecked">Please check the box if you want to proceed.</div>
			</span>
		</div>

        <div class="form-group form-actions">
			<span fxLayout="row" fxLayoutAlign="center center" fxFlex="75" class="input">
				<button class="big" type="submit" [disabled]="isProcessing"
						(click)="checkForm() && validate() && theForm.ngSubmit.emit()" i18n>
					Create account
				</button>
			</span>
        </div>
		<div class="form-group center goto-login">
			<span class="title5">Already have an account? <a ui-sref="public.login" class="nomargin basiclink" href="#/p/login">Log in</a></span>
		</div>
        <!--<div *ngIf="!!app.env.google.auth_enabled || !!app.env.facebook.auth_enabled" class="form-actions">-->
        <!--<a *ngIf="!!app.env.facebook.auth_enabled && " class="btn btn-primary" [disabled]="isProcessing" (click)="facebookLogin()">-->
        <!--Connect with <span class="zmdi zmdi-facebook"></span>-->
        <!--</a>-->

        <!--<a *ngIf="!!app.env.google.auth_enabled" class="btn btn-primary" [disabled]="isProcessing" (click)="googleLogin()">-->
        <!--Connect with <span class="zmdi zmdi-google-plus"></span>-->
        <!--</a>-->
		<!--</div>-->
    </form>
    
	<div *ngIf="showErrorTip" class="tip" [style.top]="tiptop + 'px'" [style.left]="tipleft + 'px'">
		<span [inlineSVG]="'assets/images/icons/info.svg'"></span>
		<span class="msg">{{ message }}</span>
	</div>

</div>
<!-- end: LOGIN BOX -->
