<!-- start: LOGIN BOX -->
<div class="box-login">
    <div class="error-wrapper">
        <ngb-alert *ngIf="error" (close)="clearError()" [dismissible]="true" [type]="'danger'">
            {{ error }}
        </ngb-alert>
    </div>
    <form fxLayout="column" class="form-login" (ngSubmit)="submit()" #theForm="ngForm">
        <fieldset>
            <legend i18n>Reset password</legend >
            <p i18n>Please enter a new password</p>
            
            <div class="form-group">
                <span fxLayout="row" fxLayoutAlign="center center">
                    <input fxFlex="72" type="password" class="form-control password" [disabled]="isProcessing"
                            name="password" #password="ngModel" [(ngModel)]="credentials.password"
                            [ngClass]="{error: this.submitted && password.invalid}"
                            i18n-placeholder placeholder="Your password" minlength="6" required>
                    <span fxFlex="28" class="error-message">
                        <span *ngIf="this.submitted && password.errors">
                            <span [hidden]="!password.errors.required" i18n>
                                Password is required.
                            </span>
                            <span [hidden]="!password.errors.minlength" i18n>
                                Password must be at least 6 characters.
                            </span>
                        </span>
                    </span>
                </span>
            </div>

            <div class="form-group">
                <span fxLayout="row" fxLayoutAlign="center center">
                    <input fxFlex="72" type="password" class="form-control password" [disabled]="isProcessing"
                        name="passwordConfirm" #passwordConfirm="ngModel" [(ngModel)]="password_confirm"
                        placeholder="Re-enter password" required match="credentials.password"
                        [ngClass]="{error: this.submitted && passwordConfirm.invalid}"
                        i18n-placeholder placeholder="Confirm password" match="password">
                    <i class="fa fa-lock"></i>
                    <span fxFlex="28" class="error-message">
                        <span *ngIf="this.submitted && passwordConfirm.errors && !password.errors">
                            <span [hidden]="!passwordConfirm.errors.match" i18n>
                                Passwords must match.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div class="form-group form-actions">
                <button  type="submit"  class="btn btn-primary pull-right" [disabled]="isProcessing"
                        (click)="validate() && theForm.ngSubmit.emit()" i18n>
                    Submit <i class="fa fa-arrow-circle-right"></i>
                </button>
            </div>
        </fieldset>
        <p>{{ theForm.errors }}</p>
    </form>
    <div>
        <a [routerLink]="'/auth/login'">Back to Login</a>
    </div>
</div>
<!-- end: LOGIN BOX -->
