import { ApiObject } from "@app/core/api";
import { IUserData, User } from "..";
import { ICommentData, IReaction } from "./icomment-data";

export class Comment extends ApiObject<ICommentData> {

    protected static readonly POST_FIELDS_MAP = {
        id: 'id', projectId: 'project_id', mediaId: 'media_id', // Not editable but needed to build the Api URL
        timecode: 'timecode', timecodeEnd: 'timecode_end', parentId: 'parent_id', comment: 'comment', anonName: 'anon_name', isResolved: 'is_resolved'
    };

    //#region Database
    //#region Read-only
    public readonly id: number;
    public readonly projectId: number;
    public readonly mediaId: number;
    public readonly userId: number;
    public readonly anonTokenId: number;
    //#endregion

    //#region Editable
    public parentId: number;
    public timecode: number;
    public timecodeEnd: number;
    public comment: string;
    public anonName: string;
    public isResolved: boolean;
    //#endregion

    //#region Metafields
    userCanDelete: boolean;
    userCanUpdate: boolean;
    //#endregion

    //#region Foreign Object
    user: User;
    reactions: IReaction;
    //#endregion

    //#region Timestamps
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    //#endregion
    //#endregion

    //#region Construction Transformers
    protected transformUserForInit(data: IUserData): User {
        return new User(data || { picture: `${location.protocol}//${location.host}/assets/images/default_audio_project_icon.png` }); // {} in case of anonymous user
    }
    //#endregion

    public clone<Comment extends ApiObject<ICommentData>>(): Comment {
        const clone: Comment = this.shallowClone(this.constructor);
        return clone as Comment;
    }
}
