import { Injectable } from "@angular/core";
import { ApiService, IApiOptions, IApiResponse } from "@app/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService, IPlanData } from "..";
import { Plan } from "../auth.module";
import { IPlanDataList } from "../types/iplan-data-list";
import { IUsageData } from "../types/iusage-data";
import { PlanList } from "../types/plan-list";
import { Usage } from "../types/usage";

@Injectable({
    providedIn: 'root'
  })
export class PlanService {
    constructor(private api: ApiService, protected auth: AuthService) { }

    getPlans(): Observable<Array<Plan>> {
        const options: IApiOptions = { observe: 'response' as 'response' };

        let endpoint: string = 'pricing';
        if (this.auth.currentUser) {
            endpoint = 'plans'
        }

        return this.api.get<IPlanDataList>(endpoint, options, true).pipe(
            map((response: IApiResponse<IPlanDataList>) => {
                return new PlanList(response.result, Plan).items;
            }),
            catchError((error: any) => {
                return throwError(() => error) as Observable<any>
            })
        );
    }

    getPlan(planId: number): Observable<Plan> {
        const options: IApiOptions = { observe: 'response' as 'response' };

        let endpoint: string = 'pricing';
        if (this.auth.currentUser) {
            endpoint = 'plans'
        }

        return this.api.get<IPlanDataList>(endpoint, options, true).pipe(
            map((response: IApiResponse<IPlanDataList>) => { 
                return new PlanList(response.result, Plan).items.filter((plan: Plan) => { return plan.id == planId; })[0];
            }),
            catchError((error: any) => {
                return throwError(() => error) as Observable<any>
            })
        );
    }

    getUsage(): Observable<Usage> {
        const options: IApiOptions = { observe: 'response' as 'response' };

        return this.api.get<IUsageData>('user/usage', options, true).pipe(
            map((response: IApiResponse<IUsageData>) => { 
                return new Usage(response.result);
            }),
            catchError((error: any) => {
                return throwError(() => error) as Observable<any>
            })
        );
    }
}