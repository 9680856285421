import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

export class NtDraggableDropOnTargetEvent<T> extends CustomEvent<T> {
    offsetX: number;
    offsetY: number;
}

export interface NtDraggableStartEvent {
    target: Element;
    offsetX: number;
    offsetY: number;
}

export interface NtDraggableMoveEvent {
    target: Element;
    offsetX: number;
    offsetY: number;
    movementX: number;
    movementY: number;
}

export interface NtDraggableDropEvent {
    target: Element;
    offsetX: number;
    offsetY: number;
}

@Directive({
    selector: '[ntDraggable]'
})
export class DraggableDirective {
    
    @Input('ntDraggable') ntDraggable?: '' | boolean = true;
    @Input('ntDraggableGhost') makeGhost: boolean;
    @Input('ntDraggableDropSelector') dropSelector: string;
    @Input('ntDraggableTriggerDropOn') triggerDropOn: 'auto' | 'source' | 'target' | 'both' = 'auto';
    
    @Output('start') start: EventEmitter<NtDraggableStartEvent> = new EventEmitter();
    @Output('move') move: EventEmitter<NtDraggableMoveEvent> = new EventEmitter();
    @Output('drop') drop: EventEmitter<NtDraggableDropEvent> = new EventEmitter();

    private static _isDragging: boolean;
    private _isDragging: boolean;
    @HostBinding('class.isDragging')
    get isDragging(): boolean { return this._isDragging; };
    set isDragging(isDragging: boolean) { this._isDragging = isDragging; DraggableDirective._isDragging = isDragging; }

    private captured: boolean;
    private ghost: HTMLElement;

    private lastPosX: number;
    private lastPosY: number;

    private get isDraggable(): boolean {
        return (typeof this.ntDraggable === 'boolean') ? this.ntDraggable : true;
    }

    private unlistenMove: () => void;
    private unlistenUp: () => void;

    constructor(private readonly element: ElementRef<HTMLElement>, private readonly renderer: Renderer2) {
        element.nativeElement.style.pointerEvents = ''
    }

    //#region Event Handler
    @HostListener('pointerdown', ['$event'])
    onPointerDown(event: PointerEvent) {
        if(!this.isDraggable) return;
        if(event.button !== 0) return;
        if(event.currentTarget !== this.element.nativeElement) return;
        event.preventDefault();
        event.stopPropagation();
        if (this.unlistenMove) this.unlistenMove();

        if(this.makeGhost) {
            this.ghost = this.element.nativeElement.cloneNode(true) as HTMLElement;
            this.ghost.style.position = 'absolute';
            this.ghost.style.top = this.element.nativeElement.offsetTop + event.offsetY + event.movementY - (this.element.nativeElement.clientHeight / 2) + 'px';
            this.ghost.style.left = this.element.nativeElement.offsetLeft + event.offsetX + event.movementX - (this.element.nativeElement.clientWidth / 2) + 'px';
            this.ghost.setAttribute('ntDraggableGhost', '');
            this.element.nativeElement.parentElement.style.position = 'relative'; // Hackish
            // this.element.nativeElement.parentElement.append(this.ghost);
        }

        // this.element.nativeElement.setPointerCapture(event.pointerId);
        this.isDragging = true;
        this.lastPosX = event.clientX;
        this.lastPosY = event.clientY;
        this.unlistenMove = this.renderer.listen(this.element.nativeElement, 'pointermove', this.onPointerMove);
        this.unlistenUp = this.renderer.listen(document, 'pointerup', this.onPointerUp);
        const emit = {
            target: this.element.nativeElement,
            offsetX: event.clientX - this.element.nativeElement.getBoundingClientRect().x,
            offsetY: event.clientY - this.element.nativeElement.getBoundingClientRect().y,
        };

        this.start.emit(emit);
    }
    @HostListener('pointerup', ['$event'])
    onPointerUp = (event: PointerEvent) => {
        if(!this.isDraggable) return;
        if(event.button !== 0) return;
        if (!this.isDragging || !DraggableDirective._isDragging) return;
        event.preventDefault();
        event.stopPropagation();
        
        if (this.unlistenMove) this.unlistenMove();
        if (this.unlistenUp) this.unlistenUp();
        if(this.ghost) this.ghost.remove();

        this.isDragging = false;
        this.lastPosX = undefined;
        this.lastPosY = undefined;
        if(this.captured) {
            this.captured = false;
            this.element.nativeElement.releasePointerCapture(event.pointerId);
        // }
        // There should've been no movement if no capture happened, so we should not need to do any of this?
            const dropTarget = document.elementsFromPoint(event.clientX, event.clientY).filter(e => e.matches(this.dropSelector || '*')).shift()
                || document.elementsFromPoint(event.clientX, event.clientY).filter(e => e !== this.element.nativeElement && e.closest(this.dropSelector || '*')).shift()?.closest(this.dropSelector);
            const rect = dropTarget ? dropTarget.getBoundingClientRect() : {} as any;
            const emit = {
                target: dropTarget,
                offsetX: event.clientX - rect.x,
                offsetY: event.clientY - rect.y,
            };

            const triggerOnSource = (this.triggerDropOn == 'auto' && !dropTarget) || ['source','both'].includes(this.triggerDropOn);
            const triggerOnTarget = dropTarget && ['auto', 'target','both'].includes(this.triggerDropOn);

            if(triggerOnTarget) {
                const e = new NtDraggableDropOnTargetEvent('ntDrop', { ...emit, bubbles: true, cancelable: true });
                e.offsetX = emit.offsetX;
                e.offsetY = emit.offsetY;
                dropTarget.dispatchEvent(e);
            }
            if(triggerOnSource) {
                this.drop.emit(emit);
            }
        }
    }
    // @HostListener('pointermove', ['$event']) We don't want to listen all the time so we de/register them on pointerup/down
    onPointerMove = (event: PointerEvent) => {
        if(!this.isDraggable) return;
        if (!this.isDragging) return;
        event.preventDefault();
        event.stopPropagation();
        const moveX = event.clientX - this.lastPosX;
        const moveY = event.clientY - this.lastPosY;

        if(!event.movementX && !event.movementY || (!moveX && !moveY)) return;

        if(!this.captured) {
            this.element.nativeElement.setPointerCapture(event.pointerId);
            this.captured = this.element.nativeElement.hasPointerCapture(event.pointerId);
        }
        if(this.ghost && !this.element.nativeElement.parentElement.querySelector('[ntDraggableGhost]')) {
            this.element.nativeElement.parentElement.append(this.ghost);
        }
        this.lastPosX = event.clientX;
        this.lastPosY = event.clientY;
        
        const dropTarget = document.elementsFromPoint(event.clientX, event.clientY).filter(e => e.matches(this.dropSelector || '*')).shift()
            || document.elementsFromPoint(event.clientX, event.clientY).filter(e => e !== this.element.nativeElement && e.closest(this.dropSelector || '*')).shift()?.closest(this.dropSelector);
        const rect = dropTarget ? dropTarget.getBoundingClientRect() : {} as any;
        const emit = {
            target: dropTarget,
            offsetX: event.clientX - rect.x,
            offsetY: event.clientY - rect.y,
            movementX: event.movementX || moveX,
            movementY: event.movementY || moveY,
        };
        // if(!this.dropSelector || dropTarget) {
            if(this.ghost) {
                this.ghost.style.top = (parseFloat(this.ghost.style.top) || 0) + emit.movementY + 'px';
                this.ghost.style.left = (parseFloat(this.ghost.style.left) || 0) + emit.movementX + 'px';
            }
            this.move.emit(emit);
        // }
    }
    //#endregion

}
