/**
 * Abstract class giving some utility to database backed object classes
 */
export abstract class ApiObject<I> {
    protected static readonly POST_FIELDS_MAP;

    public uuid: string;

    public id: number;

    constructor(data: Partial<I>) {
        const keyMap = this.constructor['POST_FIELDS_MAP'] || [];
        const props = Object.keys(keyMap);
        const keys = Object.values(keyMap);
        Object.keys(data).forEach((key) => {
            const index = keys.indexOf(key);
            const propName = index >= 0 ? props[index] : key.toCamel();
            const setterName = `transform${propName.ucfirst()}ForInit`;
            if(setterName in this && typeof this[setterName] === 'function') {
                this[propName] = this[setterName](data[key], data);
            } else {
                this[propName] = data[key];
            }
        });
    }

    //#region Construction Setters
    //#region Helpers
    protected transformDateForInit(data: string, set: Partial<I>): Date {
        return data ? new Date(data) : null;
    }
    protected transformURLForInit(data: string, set: Partial<I>): URL {
        return data ? new URL(data) : null;
    }
    protected transformJSONForInit(data: string, set: Partial<I>): URL {
        return data ? JSON.parse(data) : null;
    }
    //#endregion

    //#region Base Transformers
    protected transformCreatedAtForInit(data: string, set: Partial<I>) {
        return this.transformDateForInit(data, set);
    }
    protected transformUpdatedAtForInit(data: string, set: Partial<I>) {
        return this.transformDateForInit(data, set);
    }
    protected transformDeletedAtForInit(data: string, set: Partial<I>) {
        return this.transformDateForInit(data, set);
    }
    //#endregion
    //#endregion

    public toPostableData(): Partial<I> {
        // tslint:disable-next-line: no-string-literal
        const keyMap = this.constructor['POST_FIELDS_MAP'] || [];
        const filterKeys = Object.keys(this).filter((key) => { return keyMap.hasOwnProperty(key); });
        return filterKeys.map((value) => {
            if (typeof this[`transform${value.ucfirst()}ForPost`] === 'function') {
                return { [keyMap[value]]: this[`transform${value.ucfirst()}ForPost`](value) };
            }
            return { [keyMap[value]]: this[value] };
        }).reduce((sum, curr) => {
            return Object.assign(sum, curr);
        }, {}) as unknown as Partial<I>;
    }

    //#region Cloning
    public abstract clone<T extends ApiObject<I>>(): T

    protected shallowClone<T extends ApiObject<I>>(constructor): T {
        const clone = new constructor({}) as T;

        Object.keys(this).forEach((key) => clone[key] = this[key]);

        return clone;
    }
    //#endregion
}
