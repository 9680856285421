const urlPrefix = `${window.location.protocol.ctrim(':')}://${window.location.host.ctrim(':')}`;

const userMike = {
    id: -1,
    name_first: "Mike",
    name_last: "Hamilton",
    picture: `${urlPrefix}/assets/demo/Mike_Hamilton.jpg`
};

const userKristen = {
    id: -2,
    name_first: "Kristen",
    name_last: "Hall",
    picture: `${urlPrefix}/assets/demo/Kristen_Hall_60.jpg`
};

const userMe = {
    id: -3,
    name_first: "Me",
    name_last: "",
    picture: null
}

const relatedUsers = [userMike, userKristen, userMe];

export const projectResult = {
    id: -1,
    public_key: 'demo',
    name: "Notetracks Demo Project",
    duration: 146.52,
    media_type: "audio",
    userCanComment: true,
    userCanWrite: true,
    user_id: -1,
    user: userMike,
    related_users: relatedUsers,
};

export const mediasResult = [
    {
        id: -1,
        project_id: -1,
        is_main: false,
        name: "Cherry-Nowhere_to-hide",
        sequence: 1,
        f_main_dl: `${urlPrefix}/assets/demo/herry-owhere-to-hide.mp3`,
        duration: 146.52,
        status: "processed",
        color: "freedo",
        original_filesize: 3.3541259765625,
        project: projectResult
    }
];

export const commentsResult = [
    {
        id: -1,
        media_id: -1,
        project_id: -1,
        user_id: -2,
        parent_id: null,
        reactions: { counts: null, mine: [] },
        comment: "I love the intro synth and the snaps.  Maybe some ad libs would be great just before the vocals come in.  Perhaps trying to extend by 4 more bars as well!",
        timecode: 0.213,
        timecode_end: null,
        userCanUpdate: true,
        userCanDelete: true,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userKristen
    },
    {
        id: -2,
        media_id: -1,
        project_id: -1,
        parent_id: null,
        user_id: -2,
        reactions: { counts: null, mine: [] },
        comment: "Can we try quarter note delays?",
        timecode: 16.018,
        timecode_end: null,
        userCanUpdate: true,
        userCanDelete: true,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userKristen
    },
    {
        id: -3,
        project_id: -1,
        media_id: -1,
        parent_id: null,
        user_id: -2,
        reactions: { counts: null, mine: [] },
        comment: "The tick is slightly off time",
        timecode: 37.348,
        timecode_end: null,
        userCanUpdate: true,
        userCanDelete: true,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userKristen
    },
    {
        id: -4,
        project_id: -1,
        media_id: -1,
        parent_id: null,
        user_id: -2,
        reactions: { counts: null, mine: [] },
        comment: "Nice build up!!!",
        timecode: 50.8,
        timecode_end: null,
        userCanUpdate: true,
        userCanDelete: true,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userKristen
    },
    {
        id: -5,
        project_id: -1,
        media_id: -1,
        parent_id: null,
        user_id: -2,
        reactions: { counts: null, mine: [] },
        comment: "Let's see if there's anything we can do different on the instrumental for the second verse.",
        timecode: 76.478,
        timecode_end: null,
        userCanUpdate: true,
        userCanDelete: true,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userKristen
    },
    {
        id: -6,
        project_id: -1,
        media_id: -1,
        parent_id: null,
        user_id: -2,
        reactions: { counts: null, mine: [] },
        comment: "Love the Outro!!",
        timecode: 139.887,
        timecode_end: null,
        userCanUpdate: true,
        userCanDelete: true,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userKristen
    },
    {
        id: -11,
        project_id: -1,
        media_id: -1,
        parent_id: -1,
        user_id: -1,
        reactions: { counts: null, mine: [] },
        comment: "Thanks! Great idea for the adlibs.  Will try that out along with extending but if gets too long we'll go with the shorter version.",
        timecode: null,
        timecode_end: null,
        userCanUpdate: true,
        userCanDelete: true,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userMike
    },
    {
        id: -12,
        project_id: -1,
        media_id: -1,
        parent_id: -2,
        user_id: -1,
        reactions: { counts: null, mine: [] },
        comment: "Sure thing!",
        timecode: null,
        timecode_end: null,
        userCanUpdate: true,
        userCanDelete: true,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userMike
    },
    {
        id: -13,
        project_id: -1,
        reactions: { counts: null, mine: [] },
        comment: "I noticed it too.  It does create some tension though.  Let's tighten it up and see if it makes a difference",
        timecode: null,
        media_id: -1,
        user_id: -1,
        userCanUpdate: true,
        userCanDelete: true,
        parent_id: -3,
        timecode_end: null,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userMike
    },
    {
        id: -14,
        project_id: -1,
        reactions: { counts: null, mine: [] },
        comment: "I love it too!",
        timecode: null,
        media_id: -1,
        user_id: -1,
        userCanUpdate: true,
        userCanDelete: true,
        parent_id: -4,
        timecode_end: null,
        created_at: "2021-08-02T21:18:55-04:00",
        updated_at: "2021-08-02T21:18:56-04:00",
        user: userMike
    }
];

export const drawingsResult = [
    {
        id: -1,
        media_id: -1,
        project_id: -1,
        color: "balanc",
        data: "Intro",
        row_index: 1,
        timecode: 1.08,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -2,
        media_id: -1,
        project_id: -1,
        color: "balanc",
        data: "Intro",
        row_index: 1,
        timecode: 4.442,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -3,
        media_id: -1,
        project_id: -1,
        color: "balanc",
        data: "Intro",
        row_index: 1,
        timecode: 7.679,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -4,
        media_id: -1,
        project_id: -1,
        color: "balanc",
        data: "Intro",
        row_index: 1,
        timecode: 10.763,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -5,
        media_id: -1,
        project_id: -1,
        color: "passio",
        data: "Verse 1",
        row_index: 1,
        timecode: 17.305,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -6,
        media_id: -1,
        project_id: -1,
        color: "passio",
        data: "Verse 1",
        row_index: 1,
        timecode: 20.266,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -7,
        media_id: -1,
        project_id: -1,
        color: "passio",
        data: "Verse 1",
        row_index: 1,
        timecode: 22.981,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -8,
        media_id: -1,
        project_id: -1,
        color: "passio",
        data: "Verse 1",
        row_index: 1,
        timecode: 26.065,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -9,
        media_id: -1,
        project_id: -1,
        color: "passio",
        data: "Verse 1",
        row_index: 1,
        timecode: 28.78,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -10,
        media_id: -1,
        project_id: -1,
        color: "passio",
        data: "Verse 1",
        row_index: 1,
        timecode: 31.679,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -11,
        media_id: -1,
        project_id: -1,
        color: "passio",
        data: "Verse 1",
        row_index: 1,
        timecode: 34.548,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -12,
        media_id: -1,
        project_id: -1,
        color: "passio",
        data: "Verse 1",
        row_index: 1,
        timecode: 14.344,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -13,
        media_id: -1,
        project_id: -1,
        color: "spirit",
        data: "Pre - Chorus",
        row_index: 1,
        timecode: 37.592,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        id: -14,
        media_id: -1,
        project_id: -1,
        color: "spirit",
        data: "Pre - Chorus",
        row_index: 1,
        timecode: 41.365,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: "Pre - Chorus",
        id: -15,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 45.252,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: "Pre - Chorus",
        id: -16,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 48.891,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook",
        id: -17,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 52.601,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook",
        id: -18,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 55.803,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook",
        id: -19,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 59.37,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook",
        id: -20,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 62.328,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook",
        id: -21,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 65.896,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook",
        id: -22,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 69.525,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook",
        id: -23,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 70.895,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook",
        id: -24,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 73.55,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: "Verse 2",
        id: -25,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 77.347,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: "Verse 2",
        id: -26,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 80.56,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: "Verse 2",
        id: -27,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 83.516,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: "Verse 2",
        id: -28,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 86.164,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: "Verse 2",
        id: -29,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 88.863,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: "Verse 2",
        id: -30,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 91.768,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: "Verse 2",
        id: -31,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 94.313,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: "Verse 2",
        id: -32,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 96.883,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: "Pre Chorus 2",
        id: -33,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 100.312,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: "Pre Chorus",
        id: -34,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 104.291,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: "Pre Chorus",
        id: -35,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 107.53,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: "Pre Chorus",
        id: -36,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 111.54,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook 2",
        id: -37,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 115.489,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook 2",
        id: -38,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 118.481,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook 2",
        id: -39,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 121.627,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook 2",
        id: -40,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 125.39,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook 2",
        id: -41,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 128.753,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook 2",
        id: -42,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 132.115,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "balanc",
        data: "Hook 2",
        id: -43,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 135.785,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: "Outro",
        id: -44,
        media_id: -1,
        project_id: -1,
        row_index: 1,
        timecode: 140.166,
        type: "text",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: null,
        id: -45,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 14.652,
        type: "mic",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: null,
        id: -46,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 17.829,
        type: "mic",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: null,
        id: -47,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 20.79,
        type: "mic",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: null,
        id: -48,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 23.474,
        type: "mic",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "passio",
        data: null,
        id: -49,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 25.937,
        type: "mic",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: null,
        id: -50,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 38.28,
        type: "bongo",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: null,
        id: -51,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 40.717,
        type: "bongo",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: null,
        id: -52,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 43.154,
        type: "bongo",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: null,
        id: -53,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 45.802,
        type: "bongo",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    },
    {
        color: "spirit",
        data: null,
        id: -54,
        media_id: -1,
        project_id: -1,
        row_index: 2,
        timecode: 47.904,
        type: "bongo",
        userCanDelete: true,
        userCanUpdate: true,
        user_id: -1
    }
];

export const projectUserState = {
    id: -1,
    state: {
        solo: 0,
        speed: 1,
        timecode: 0,
        tracks: [
            { id: 1, muted: false, collapsed: false },
            { id: 2, muted: true, collapsed: false }
        ],
        volume: 0.5,
        volume_muted: false,
        zoom: 5
    }
}


//#region Demo Templates
export const mediaTemplate = {
    id: undefined,
    project_id: undefined,
    is_main: false,
    name: undefined,
    sequence: undefined,
    f_main_dl: undefined,
    duration: undefined,
    status: "processed",
    color: undefined,
    original_filesize: undefined,
    project: projectResult
};

export const commentTemplate = {
    id: undefined,
    media_id: undefined,
    project_id: undefined,
    user_id: -3,
    parent_id: null,
    reactions: { counts: null, mine: [] },
    comment: undefined,
    timecode: undefined,
    timecode_end: null,
    userCanUpdate: true,
    userCanDelete: true,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    user: userMe
};

export const drawingTemplate = {
    id: undefined,
    media_id: undefined,
    project_id: undefined,
    color: undefined,
    data: undefined,
    row_index: undefined,
    timecode: undefined,
    type: undefined,
    userCanDelete: true,
    userCanUpdate: true,
    user_id: userMe
};
//#endregion