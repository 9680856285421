import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Observable, EMPTY } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';

import { ConfigService } from '@app/config.service';

import { AuthFormBaseComponent } from '../../components';
import { AuthComponent } from '../../auth.component';
import { AuthService } from '../../services';
import { AuthUser } from '../../types';

@Component({
  selector: 'nt-invitation',
  templateUrl: '../register/register.component.html',
  styleUrls: ['../register/register.component.scss', '../register/register-mobile.component.scss']
})
export class InvitationComponent extends AuthFormBaseComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  public password_confirm: string;
  public inviteToken: string;
  public termsAccepted: boolean;

  // tslint:disable-next-line: max-line-length
  constructor(
    protected authComponent: AuthComponent, protected auth: AuthService, protected socialAuth: SocialAuthService, public config: ConfigService,
    protected title: Title, private route: ActivatedRoute, private router: Router, private toast: ToastrService
  ){
    super(title, authComponent, auth, socialAuth);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.title.setTitle('Notetracks - Sign-up');
    this.authComponent.showPricingLink = true;
    this.authComponent.leftText = ['','','']; // ['Get started', 'For free', 'Try Notetracks Pro for free'];
    // tslint:disable-next-line: deprecation
    this.route.params.subscribe((params: Params) => {
      // tslint:disable-next-line: no-string-literal
      this.auth.postAuthenticationUrl = this.router.createUrlTree(['/project', params['projectId']], {});
      // tslint:disable-next-line: no-string-literal
      this.inviteToken = params['token'];
    });
    this.authComponent.cd.detectChanges();
  }

  submit(): void {
    if (this.form.valid && this.termsAccepted) {
      this.isProcessing = true;
      this.submitted = true;
      this.auth.register(this.credentials, this.inviteToken).pipe(
        finalize(() => {
          this.isProcessing = false;
        }),
        catchError((response: HttpErrorResponse, caught: Observable<AuthUser>) => {
          console.warn(response);
          this.error = response.error.notice.message || response.message;
          return EMPTY;
        })
      // tslint:disable-next-line: deprecation
      ).subscribe((user: AuthUser) => {
          this.toast.success('Account created.');
          this.router.navigate(['/auth/login'], {});
        });
    }
  }

  loginWithGoogle() {
    this.isProcessing = true;
    this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

}
