import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timecode'
})
export class TimecodePipe implements PipeTransform {

  transform(value: number, format: string = "hh?:mm:ss.msmsms"): string {

    const h = (format.match(/h+\?*/g) || [''])[0];
    const m = (format.match(/m+\?*/g) || [''])[0];
    const s = (format.match(/s+\?*/g) || [''])[0];
    const ms = (format.match(/(ms)+\?*/g) || [''])[0];

    let result = format.replace('?',''); // Remove optional indicator
    if(h) {
        const hours = Math.floor(value / 3600);
        if(!hours && h.includes('?')) {
            result = result.replace(/h+[^a-z]*/g, '')
        } else {
            const padLength = h.replace('?','').length;
            result = result.replace(/h+/g, hours.toString().padStart(padLength, '0'));
        }
    }

    if(ms) {
        let [ _, milsec ] = String(value || 0).split('.');
        milsec = milsec || '';
        if(!milsec && ms.includes('?')) {
            result = result.replace(/[^a-z]*ms+/g, '')
        } else {
            const padLength = ms.replace('?','').length / 2;
            result = result.replace(/(ms)+/g, milsec.substring(0, padLength).toString().padStart(padLength, '0'));
        }
    }

    if(m) {
        const minutes = Math.floor(((value || 0) % 3600) / 60);
        if(!minutes && m.includes('?')) {
            result = result.replace(/m+[^a-z]*/g, '')
        } else {
            const padLength = m.replace('?','').length
            result = result.replace(/m+/g, minutes.toString().padStart(padLength, '0'));
        }
    }

    if(s) {
        const seconds = Math.floor((value || 0) % 60);
        if(!seconds && s.includes('?')) {
            result = result.replace(/s+[^a-z]*/g, '')
        } else {
            const padLength = s.replace('?','').length;
            result = result.replace(/s+/g, seconds.toString().padStart(padLength, '0'));
        }
    }

    return result;
  }

}
