import { AfterViewInit, Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { NtDraggableMoveEvent } from '@app/shared';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProjectManagerService } from '../../project.module';

@Component({
    selector: 'nt-scroll',
    templateUrl: './scroll.component.html',
    styleUrls: ['./scroll.component.scss']
})
export class ScrollComponent implements OnInit, AfterViewInit {

    @HostBinding('hidden') hidden: boolean;
    public hostWidth: number = 0;
    public barWidth: number;
    public barLeft: number;
    private _destroy: Subject<void> = new Subject();

    constructor(
        private readonly pms: ProjectManagerService,
        private hostElement: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        this.pms.ui.zoom$.pipe(takeUntil(this._destroy)).subscribe((zoom: number) => {
            this.hidden = zoom === 1;
        });
    }

    ngAfterViewInit(): void {
        if (this.hostWidth == 0) {
            this.hostWidth = this.hostElement.nativeElement.clientWidth;
        }

        const zoom$ = this.pms.ui.zoom$
        const scrollOffsetFactor$ = this.pms.ui.scrollOffsetFactor$;
        combineLatest([zoom$, scrollOffsetFactor$]).pipe(takeUntil(this._destroy)).subscribe((results) => {
            const [zoom, offsetFactor] = results;
            setTimeout(() => {
                this.barWidth = this.hostWidth / zoom;
                this.barLeft = this.hostWidth * -offsetFactor;
            });
        });
    }

    onMouseMoveBar(moveEvent: NtDraggableMoveEvent): void {
        if (this.barLeft + this.barWidth + moveEvent.movementX < this.hostWidth) {
            const newLeft = Math.max(0, this.barLeft + moveEvent.movementX);
            const newOffset = newLeft / this.hostWidth * -1;
            this.pms.ui.setScrollOffsetFactor(newOffset);
        }
    }
}