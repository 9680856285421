import { Injectable } from '@angular/core';
import { SocketEventMessage, SocketService, Store } from '@app/core';
import { Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { UserStateDataService } from '../services';
import { IUserStateData, UserState } from '../types';

@Injectable({
  providedIn: 'root'
})
export class UserStateStore extends Store<UserState, IUserStateData, UserStateDataService> {

  protected static readonly LIST_KEY_PARTS = ['id'];
  
  constructor(protected readonly service: UserStateDataService, protected readonly socket: SocketService) {
      super(socket);
  }

  public saveState(id: number, name: string, value: any): Observable<UserState> {
    return this.get(id).pipe(take(1),switchMap((state: UserState) => {
        state.state[name] = value;
        return this.save(state);
    }))
  }
  
  protected observe(): Observable<SocketEventMessage> {
      return this.socket.observe(UserState);
  }

  ngOnDestroy(): void {
    Object.keys(this._subscriptions).map(key => this._subscriptions[key])
            .filter((subscription: Subscription) => subscription instanceof Subscription)
            .forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}
