import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { defaultIfEmpty, switchMap, take, tap } from "rxjs/operators";
import { TimecodePipe } from "../pipes";
import { ProjectManagerService } from "../project.module";
import { Comment, Media, Project } from "../types";
import { StoreService } from "./store.service";

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(protected readonly store: StoreService, private readonly projectManager: ProjectManagerService) { }

  exportComments(project: Project): void {
    let timecodePipe: TimecodePipe = new TimecodePipe();
    let dataToExport = '';

    let medias$: Observable<Array<Media>> = this.projectManager.media.list$;
    
    medias$.subscribe((medias: Array<Media>) => {

        for (let m = 0; m < medias.length; m++) {
            let collaborators = [];
            let currentMedia = medias[m];
    
            dataToExport += currentMedia.name + ' by ' + project.user.nameFirst + ' ' + project.user.nameLast + '\n';
            dataToExport += '{ncollaborators} collaborators\n';

            let comments$: Observable<Array<Comment>> = this.store.comment.list({ projectId: currentMedia.projectId, mediaId: currentMedia.id, parentId: null }, 'timecode').pipe(
                switchMap((aoc: Array<Observable<Comment>>) => combineLatest(aoc).pipe(defaultIfEmpty([] as Array<Comment>))),
            );

            comments$.subscribe((comments: Array<Comment>) => {
                
                for (let c = 0; c < comments.length; c++) {
                    let currentComment = comments[c];

                    dataToExport += '\n' + timecodePipe.transform(currentComment.timecode, 'mm:ss');
        
                    if (currentComment.timecodeEnd) {
                        dataToExport += ' - ' + timecodePipe.transform(currentComment.timecodeEnd, 'mm:ss') + '\n';
                    } else {
                        dataToExport += '\n';
                    }
        
                    let fullname = currentComment.anonName ? currentComment.anonName : currentComment.user ? currentComment.user.nameFirst + ' ' + currentComment.user.nameLast : '(unknown)';
                    dataToExport += fullname + ': ';
                    dataToExport += '"' + currentComment.comment + '"\n';
        
                    if (collaborators.indexOf(fullname) === -1) {
                        collaborators.push(fullname);
                    }

                    let replies$: Observable<Array<Comment>> = this.store.comment.list({ projectId: currentComment.projectId, mediaId: currentComment.mediaId, parentId: currentComment.id }, 'createdAt').pipe(
                        take(1),
                        switchMap((aoc: Array<Observable<Comment>>) => combineLatest(aoc).pipe(take(1), defaultIfEmpty([] as Array<Comment>))),
                    );

                    replies$.subscribe((replies: Array<Comment>) => {
                        for(let r = 0; r < replies.length; r++) {
                            let currentReply = replies[r];
        
                            fullname = currentReply.anonName ? currentReply.anonName : currentReply.user ? currentReply.user.nameFirst + ' ' + currentReply.user.nameLast : '(unknown)';
                            dataToExport += '\t' + fullname + ': ';
                            dataToExport += '\"' + currentReply.comment + '"\n';
        
                            if (collaborators.indexOf(fullname) === -1) {
                                collaborators.push(fullname);
                            }
                        }
                    });
                }
            });
    
            dataToExport = dataToExport.replace('{ncollaborators}', collaborators.length.toString());
            dataToExport += '\nAll users completed commenting\n\n\n\n\n';
        }
    });
    
    this.downloadExportedFile(dataToExport, 'comments.txt', 'text/plain');
  }

  exportCommentsAsAuditionMarkers(mediaName: string, comments: Array<Comment>): void {
    var _this = this;
    let dataToExport = 'Name\tStart\tDuration\tTime Format\tType\tDescription\n';
    comments.forEach((comment) => {
        dataToExport += `${comment.anonName ? comment.anonName : comment.user ? comment.user.nameFirst + ' ' + comment.user.nameLast : '(unknown)' }\t${_this.toHHMMSS(comment.timecode)}\t${comment.timecodeEnd ? _this.toHHMMSS(((comment.timecodeEnd * 1000) - (comment.timecode) * 1000) / 1000) : "0:00.000"}\tdecimal\tCue\t${comment.comment.replace(/\r?\n|\r/g, " ")}`;
        
        this.store.comment.list({ projectId: comment.projectId, mediaId: comment.mediaId, parentId: comment.id }, 'createdAt').pipe(
          take(1),
          switchMap((aoc: Array<Observable<Comment>>) => combineLatest(aoc).pipe(take(1), defaultIfEmpty([] as Array<Comment>))),
        ).subscribe((replies: Array<Comment>) => {
          if (replies && replies.length > 0) {
            replies.forEach((reply) => {
                dataToExport += ` ${reply.anonName ? reply.anonName : reply.user.nameFirst + ' ' + reply.user.nameLast}: ${reply.comment.replace(/\r?\n|\r/g, " ")}`;
            });
          }
        });
        
        dataToExport += '\n';
    });

    let fileName = mediaName + ' (audition markers).csv';

    this.downloadExportedFile(dataToExport, fileName, 'text/plain');
  }

   exportCommentsAsAudacityLabels(mediaName: string, comments: Array<Comment>): void {
    let dataToExport = '';
    comments.forEach((comment) => {
        dataToExport += `${comment.timecode}\t${comment.timecodeEnd ? comment.timecodeEnd : comment.timecode}\t${comment.anonName ? comment.anonName : comment.user ? comment.user.nameFirst + ' ' + comment.user.nameLast : '(unknown)'}: "${comment.comment.replace(/\r?\n|\r/g, " ")}"`;
        
        this.store.comment.list({ projectId: comment.projectId, mediaId: comment.mediaId, parentId: comment.id }, 'createdAt').pipe(
          take(1),
          switchMap((aoc: Array<Observable<Comment>>) => combineLatest(aoc).pipe(take(1), defaultIfEmpty([] as Array<Comment>))),
        ).subscribe((replies: Array<Comment>) => {
          if (replies && replies.length > 0) {
            replies.forEach((reply) => {
                dataToExport += ` ${reply.anonName ? reply.anonName : reply.user.nameFirst + ' ' + reply.user.nameLast}: "${reply.comment.replace(/\r?\n|\r/g, " ")}"`;
            });
          }
        });
        
        dataToExport += '\n';
    });

    let fileName = mediaName + ' (audacity labels).txt';
    this.downloadExportedFile(dataToExport, fileName, 'text/plain');
  }

  downloadExportedFile(dataToExport: string, fileName: string, mimeType: string): any {
      if (navigator.msSaveBlob) {
          var blob = new Blob([dataToExport], { type: mimeType });
          return navigator.msSaveBlob(blob, fileName);
      }
      else {
          var data = "data:text/plain;charset=utf-8," + encodeURIComponent(dataToExport);
          var downloader = document.createElement('a');
          
          downloader.setAttribute('href', data);
          downloader.setAttribute('download', fileName);
          
          if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
              document.body.appendChild(downloader);
          }
          
          downloader.click();
          
          if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
              document.body.removeChild(downloader);
          }
      }
  }

  toHHMMSS (totalSeconds: number): string {
    let milli = '000' + Math.trunc((totalSeconds * 1000) - (Math.trunc(totalSeconds) * 1000));
    milli = milli.substring(milli.length - 3, milli.length);
    let sec_num = totalSeconds;
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));

    let strHours: string = hours.toString();
    let strMinutes: string = minutes.toString();
    let strSeconds : string = seconds.toString();

    if (hours   < 10) { strHours   = "0" + hours; }
    if (minutes < 10) { strMinutes = "0" + minutes; }
    if (seconds < 10) { strSeconds = "0" + seconds; }

    if (strHours == "00") {
        if (strMinutes == "00") {
            return '0:' + strSeconds + "." + milli;
        }
        return strMinutes + ':' + strSeconds + "." + milli;
    }

    return strHours + ':' + strMinutes + ':' + strSeconds + "." + milli;
  }

}
