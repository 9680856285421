import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';

import { Observable } from 'rxjs';

import { Subscriber } from '@app/core';

@Component({
    selector: 'nt-collaborator-bar',
    templateUrl: './collaborator-bar.component.html',
    styleUrls: ['./collaborator-bar.component.scss']
})
export class CollaboratorBarComponent implements OnInit {

    @Input('collaborators') collaborators$: Observable<Array<Subscriber>>;

    @ViewChildren('img[collaborator]') collaborators: QueryList<HTMLImageElement>;

    constructor() { }

    ngOnInit(): void {
        
    }

}
