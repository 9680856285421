import { ApiObject } from '@app/core';
import { IProjectData } from './iproject-data';
import { IUserData, User } from '../user';


export class Project extends ApiObject<IProjectData> {

    protected static readonly POST_FIELDS_MAP = {
        id: 'id', // Not editable but needed to build the Api URL
        name: 'name', description: 'description', company: 'company', notepad: 'notepad',
        duplicatedFrom: 'duplicated_from', duplicateFrom: 'duplicate_from',
        mediaType: 'media_type', state: 'state', publicPermission: 'public_permission'
    };

    //#region Database
    //#region Read-only
    public readonly id: number;
    public readonly userId: number;
    public readonly publicKey: string;
    public readonly abr: number;
    public readonly storage: number;
    // public readonly duration: number;
    public readonly status: string;
    public readonly duplicatedFrom: string;
    //#endregion

    //#region Editable
    public name: string;
    public description: string;
    public company: string;
    public picture: URL;
    public notepad: string;
    public publicPermission: 'r' | 'w' | 'rw' | '';
    public mediaType: 'audio' | 'video';
    //#endregion

    //#region Metafields
    public readonly user: User;
    public readonly userCanWrite: boolean;
    public readonly userCanComment: boolean;
    public readonly shareId: number;
    public readonly relatedUsers: User[];
    //#endregion

    //#region Foreign Object
    // public readonly medias: MediaList;
    //#endregion

    //#region Timestamps
    public readonly createdAt: Date;
    public readonly updatedAt: Date;
    public readonly deletedAt: Date;
    //#endregion
    //#endregion

    //#region Construction Transformers
    protected transformPictureForInit(data: string, set: Partial<IProjectData>): URL {
        return this.transformURLForInit(data, set);
    }
    protected transformUserForInit(data: IUserData, set: Partial<IProjectData>): User {
        return new User(data || { picture: `${location.protocol}//${location.host}/assets/images/default_audio_project_icon.png` });
    }
    protected transformRelatedUsersForInit(data: IUserData[], set: Partial<IProjectData>): User[] {
        return (data || []).map((user: IUserData) => new User(user || { picture: `${location.protocol}//${location.host}/assets/images/default_audio_project_icon.png` }));
    }
    //#endregion

    public clone<Project extends ApiObject<IProjectData>>(): Project {
        return this.shallowClone(this.constructor as { new (data: IProjectData): Project } );
    }

    //#region Static Methods
    public static parseProjectKey(projectIdOrKey: string) {
        // Backward compatibility for links with projectId
        let publicKey: string;
        let projectId: number = parseInt(projectIdOrKey, 10);
        if (isNaN(projectId) || projectId.toString() !== projectIdOrKey) {
            publicKey = projectIdOrKey;
            projectId = parseInt(publicKey.substring(0, publicKey.indexOf('_')), 16);
        }

        if (isNaN(projectId) && publicKey === 'demo') { projectId = -1; }

        return { publicKey, projectId };
    }
    //#endregion
}
