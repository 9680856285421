import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService, AuthUser, IAuthUser } from '@app/features/auth';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { StoreService } from '../../../project/services';
import { User } from '../../../project/types';

@Component({
  selector: 'nt-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public user$: Observable<Readonly<AuthUser>>;
  public firstName: string = '';
  public lastName: string = '';
  public receiveNotifications: boolean= false;
  public error_message: string = '';
  public editing: boolean = false;

  @ViewChild('editButton') editButton: ElementRef<HTMLButtonElement>;
  @ViewChild('txtFirstName') txtFirstName: ElementRef<HTMLInputElement>;

  constructor(
    private auth: AuthService,
    private store: StoreService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.user$ = this.auth.user$;
    // this.firstName = this.auth.currentUser.nameFirst;
    // this.lastName = this.auth.currentUser.nameLast;
    this.receiveNotifications = this.auth.currentUser.optinNotification;

    this.auth.user$.subscribe((user: AuthUser) => {
      this.firstName = user.nameFirst;
      this.lastName = user.nameLast;
    });
  }

  save(): void {

    if (this.firstName == '') {
      this.toast.error('First name is required.');
      return;
    }

    if (this.lastName == '') {
      this.toast.error('Last name is required.');
      return;
    }

    this.auth.save(this.firstName, this.lastName, this.receiveNotifications).subscribe((savedUser: AuthUser) => {
      this.editing = false;
      this.toast.success('Profile has been updated');
    });
  }

  cancel(): void {
    this.firstName = this.auth.currentUser.nameFirst;
    this.lastName = this.auth.currentUser.nameLast;
    this.receiveNotifications = this.auth.currentUser.optinNotification;
    this.editing = false;
  }

  edit(event: MouseEvent): void {
    this.editing = true;
    if (event.target == this.editButton?.nativeElement) {
      this.txtFirstName.nativeElement.focus();
    }
  }
}
