import { ApiObject } from '@app/core/api';
import { IPlanData } from './iplan-data';

export class Plan extends ApiObject<IPlanData> {
    //#region IPlan Data Accessors
    //#region Read-only
    public readonly id: number;
    public readonly priceCharged: number;
    public readonly taxes: any[];
    public readonly interval: string;
    public readonly name: string;
    //#endregion

    //#region Editable
    public code: string;
    public limitProject: number;
    public limitStorage: number;
    public limitTraffic: number;
    public ttl: number;
    public price: number;
    public visible: boolean;
    public sequence: number;
    //#endregion

    //#region Timestamps
    public readonly createdAt: Date;
    public readonly updatedAt: Date;
    //#endregion
    //#endregion

    public clone<Plan extends ApiObject<IPlanData>>(): Plan {
        return this.shallowClone(this.constructor as { new (data: IPlanData): Plan } );
    }
}
