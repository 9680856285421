import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { SocialAuthService } from '@abacritt/angularx-social-login';

import { AuthService } from '../../services';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit, OnDestroy {

  private _subscriptions: Record<string, Subscription> = {};

  constructor(private auth: AuthService, private socialAuth: SocialAuthService) { }

  ngOnInit(): void {
    this._subscriptions.socialAuth = this.socialAuth.initState.subscribe((value) => {
        this.socialAuth.signOut()
            .finally(() => this.auth.revoke())
            .catch((reason) => console.info('Google SignOut:', reason))
            .then();
    });
  }

  ngOnDestroy(): void {
    Object.keys(this._subscriptions).map(key => this._subscriptions[key])
        .filter((subscription: Subscription) => subscription instanceof Subscription)
        .forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}
