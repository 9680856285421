import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';

import { SocialAuthService } from '@abacritt/angularx-social-login';

import { FormComponent } from '@app/shared';
import { AuthService } from '../../services';
import { AuthComponent } from '../../auth.component';
import { IAuthCredentials } from '../../types';

@Component({ template: ''})
export abstract class AuthFormBaseComponent extends FormComponent implements OnInit {

  public credentials: IAuthCredentials = { email: null, password: null };

  private _subscriptions: Record<string, Subscription> = {};

  public message: string = '';
  public showErrorTip: boolean = false;
  public tiptop: number = 0;
  public tipleft: number = 0;
  public tcUnchecked: boolean = false;
  public termsAccepted: boolean;
  public partner: string;
  public partnerName: string;
  public code: string;

  constructor(protected title: Title, protected authComponent: AuthComponent, protected auth: AuthService, protected socialAuth: SocialAuthService) {
    super();
  }

  ngOnInit(): void {
    this._subscriptions.socialAuth = this.socialAuth.authState.subscribe((user) => {
        if(user) {
            this.auth.authenticated$.pipe(take(1)).subscribe((authenticated: boolean) => {
                if(authenticated) {
                    this.auth.postAuthenticationRedirect();
                }

                this.authenticate('google', user.idToken)
            });
        }
    });
  }

  

  ngOnDestroy(): void {
    Object.keys(this._subscriptions).map(key => this._subscriptions[key])
        .filter((subscription: Subscription) => subscription instanceof Subscription)
        .forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public clearError(): void {
    super.clearError();
  }

  protected authenticate(email, password) {
    this.auth.authenticate(email, password).pipe(
        finalize(() => {
          this.isProcessing = false;
        }),
        catchError((response: HttpErrorResponse, caught: Observable<boolean>) => {
          console.warn(response);
          this.error = response.error.notice?.message || response.statusText;
          return EMPTY;
        })
      // tslint:disable-next-line: deprecation
      ).subscribe((authenticated: boolean) => {
        console.assert(authenticated);
        if (!authenticated) {
          console.error('Unexpected "isAuthenticated = false" from "success" callback.');
          this.error = this.auth.currentSession.rawResponse.notice.message;
        }
      });
  }

  
  checkForm(): boolean {
    if (!this.form.valid) {
      let htmlElement: HTMLElement;
      this.message = 'Please fill out this field';

      if (!this.form.controls['name_first'].valid) {
        htmlElement = document.getElementsByName('name_first')[0];
      } else if (!this.form.controls['name_last'].valid) {
        htmlElement = document.getElementsByName('name_last')[0];
      } else if (!this.form.controls['email'].valid) {
        htmlElement = document.getElementsByName('email')[0];
        if (this.form.controls['email'].value != '') {
          this.message = 'Email is not valid';
        }
      } else if (!this.form.controls['password'].valid) {
        htmlElement = document.getElementsByName('password')[0];
      } else if (!this.form.controls['password_confirm'].valid) {
        htmlElement = document.getElementsByName('password_confirm')[0];
        if (this.credentials.password != '' && this.form.controls['password_confirm'].value != '') {
          this.message = 'Passwords do not match';
        }
      } else if (this.partner != '' && !this.form.controls['invitation_code'].valid) {
        htmlElement = document.getElementsByName('invitation_code')[0];
      }

      this.tiptop = htmlElement.offsetTop + 46;
      this.tipleft = htmlElement.offsetLeft + 2;

      htmlElement.focus();
      this.showErrorTip = true;
        
      setTimeout(() => {
        this.showErrorTip = false;
      }, 2000);
    } else if (!this.termsAccepted) {
      this.tcUnchecked = true;
    } else {
      this.tcUnchecked = false;
    }

    return true;
  }

}
