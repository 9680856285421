import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Observable, EMPTY } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { AuthService } from '../../services';
import { AuthUser } from '../../types';

@Component({
  template: ''
})
export class ValidationComponent implements OnInit {

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router, private toast: ToastrService) { }

  ngOnInit(): void {
    // tslint:disable-next-line: deprecation
    this.route.params.subscribe((params: Params) => {
      // tslint:disable-next-line: no-string-literal
      const token = params['token'];
      this.auth.confirmEmail(token).pipe(
        finalize(() => {
          this.router.navigate(['/auth/login'], {});
        }),
        catchError((response: HttpErrorResponse, caught: Observable<AuthUser>) => {
          console.warn(response);
          this.toast.error(response.error.notice.message || response.message);
          return EMPTY;
        })
      // tslint:disable-next-line: deprecation
      ).subscribe((user: AuthUser) => {
        this.toast.success('Email confirmed.');
      });
    });
  }

}
