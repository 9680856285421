import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { ApiService, IApiOptions, IApiResponse } from '@app/core';
import { Comment, CommentList, ICommentData, ICommentDataList, Project } from '../../types';
import { HttpContext, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { IDS } from '.';


@Injectable({
  providedIn: 'root'
})
export class CommentDataService {

  constructor(private api: ApiService, private storage: LocalStorageService) { }

  //#region CRUD Operation
  public list(params?: Record<string, any>): Observable<CommentList> {
    const { projectId, mediaId, publicKey } = params;
    const mediaPath = mediaId ? `media/${mediaId}/` : '';
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }
    return this.api.get<ICommentDataList>(`project/${projectId}/${mediaPath}comment?limit=0`, options).pipe(
      map((response: IApiResponse<ICommentDataList>) => {
        const l = new CommentList(response.result, Comment);
        // tslint:disable-next-line: no-string-literal
        // project['_medias'] = ml;
        return l;
      })
    );
  }

  public get(params?: Record<string, any>): Observable<Comment> {
    const { id, projectId, mediaId, publicKey } = params;
    const mediaPath = mediaId ? `media/${mediaId}/` : '';
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.get<ICommentData>(`project/${projectId}/${mediaPath}comment/${id}`, options).pipe(
      map((response: IApiResponse<ICommentData>) => {
        return new Comment(response.result);
      })
    );
  }

  public save(commentData: Partial<ICommentData> & Record<string, any>, params: Record<string, any>): Observable<Comment> {
    const { publicKey } = params;
    const { id, project_id, media_id } = commentData;  delete commentData.id, delete commentData.project_id, delete commentData.media_id;
    const context = new HttpContext().set(IDS, { id, project_id, media_id });
    const mediaPath = media_id ? `media/${media_id}/` : '';
    const pathId = id ? `/${id}` : '';
    const options: IApiOptions = { observe: 'response' as 'response', context };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.post<ICommentData, Partial<ICommentData>>(`project/${project_id}/${mediaPath}comment${pathId}`, commentData, options).pipe(
      map((response: IApiResponse<ICommentData>) => {
        let newComment = new Comment(response.result);
        if (newComment.anonName) {
          this.storage.store('anonymous-name', newComment.anonName);
        }
        return newComment;
      })
    );
  }

  public delete(params?: Record<string, any>): Observable<boolean> {
    const { id, projectId, mediaId, publicKey } = params;
    const mediaPath = mediaId ? `media/${mediaId}/` : '';
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.delete<boolean>(`project/${projectId}/${mediaPath}comment/${id}`, options).pipe(
      map((response: IApiResponse<boolean>) => {
        return response.result;
      })
    );
  }
  //#endregion

}
