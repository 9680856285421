import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { StoreService } from '../services';
import { Project } from '../types';

@Injectable({
    providedIn: 'root'
})
export class ProjectResolver implements Resolve<Project> {

    constructor(private store: StoreService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
        let publicKey: string;
        let id: number;

        // Backward compatibility for links with projectId
        if (!isNaN(route.params.projectId)) {
            id = parseInt(route.params.projectId, 10);
        } else {
            publicKey = route.params.projectId;
            id = parseInt(publicKey.substring(0, publicKey.indexOf('_')), 16);
        }

        if (isNaN(id) && publicKey === 'demo') { id = -1; }

        this.store.project.fetch({ id, publicKey });
        return this.store.project.get(id).pipe(first());
    }
}
