import { Clip, Effect, IMetadata, Metadata } from "@app/features/project/types";
import { v4 as uuidv4 } from 'uuid';

export class NtAudioTrackNode {
    private static SCRATCH_BUFFER: AudioBuffer;
    // private _connectionArgs: Array<AudioNode | AudioParam | number>;
    private _gain: GainNode;
    private _mute: GainNode;
    private _sources: Array<AudioBufferSourceNode> = [];

    private _isMuted: boolean = false;
    private _isSoloed: boolean = false;
    public get isSoloed(): boolean { return this._isSoloed; }

    public speed: number = 1;

    public get duration(): number {
        return this._metadata.clips.map((clip: Clip) => clip.position + clip.duration).reduce((sum, curr) => Math.max(sum, curr), 0);
    }
    public get metadata(): Metadata { return this._metadata; };

    constructor(private readonly _context: BaseAudioContext, public buffer: AudioBuffer, private readonly _metadata: Metadata) {
        if (!NtAudioTrackNode.SCRATCH_BUFFER) {
            NtAudioTrackNode.SCRATCH_BUFFER = this._context.createBuffer(1, 1, 22050);
        }
        this._gain = this._context.createGain();
        this._gain.gain.value = 1;
        this._mute = this._context.createGain();
        this._mute.gain.value = 0;

        if(!this?._metadata?.clips || !this?._metadata?.clips?.length) {
            const clip = new Clip({ uuid: uuidv4(), position: 0, start: 0, duration: buffer.duration });
            if(!this?._metadata) this._metadata = new Metadata({} as IMetadata);
            this._metadata.clips = [clip];
        }
    }

    cloneWithContext(_context: BaseAudioContext): NtAudioTrackNode {
        const clone = new NtAudioTrackNode(_context, this.buffer, this._metadata);
        clone._gain.gain.value = this._gain.gain.value;
        clone._isMuted = this._isMuted;
        clone._isSoloed = this._isSoloed;
        clone.speed = this.speed;
        return clone;
    }

    public updateClips(clips: Clip[]) {
        this._metadata.clips = clips;
    }

    public updateEffects(effects: Effect[]) {
        this._metadata.effects = effects;
    }

    public playAt(offset: number) {
        this._sources = [];
        this._metadata.clips.forEach((clip: Clip) => {
            const endInTimeline = clip.position + clip.duration;
            if(offset <= clip.position || offset <= endInTimeline) {
                const source = this._context.createBufferSource();
                this._isMuted ? source.connect(this._mute) : source.connect(this._gain);
                source.buffer = this.buffer;
                source.playbackRate.value = this.speed;
                const startOffset = Math.max(0, clip.position - offset);
                const audioOffset = Math.max(0, offset - clip.position);
                const duration = clip.duration - audioOffset;
                source.start(this._context.currentTime + startOffset, audioOffset + clip.start, duration);
                this._sources.push(source);
            }
        });
    }

    public stop() {
        this._sources.forEach((source: AudioBufferSourceNode) => {
            source.stop();
            source.onended = null;
            source.disconnect(0);
            try { source.buffer = NtAudioTrackNode.SCRATCH_BUFFER} catch(e) {}
            source = null;
        });
        this._sources = [];
    }

    public volume(volume?: number): number {
        if (volume !== undefined) {
            this._gain.gain.setValueAtTime(volume, this._context.currentTime);
        }

        return this._gain.gain.value;
    }

    public mute(): boolean;
    /**
     * Mute/unmute the track by removing/reinserting it from the signal path
     * 
     * @param value Whether to mute (true) or unmute (false) the GainNode
     * @returns Whether the GainNode is muted or not
     */
    public mute(mute: boolean): boolean;
    public mute(mute?: boolean): boolean {
        if(mute !== undefined && mute !== this._isMuted){
            this._sources.forEach((source: AudioBufferSourceNode) => {
                if(mute) {
                    source.disconnect();
                    source.connect(this._mute);
                } else {
                    source.disconnect();
                    source.connect(this._gain);
                }
            });

            this._isMuted = mute;
        }

        return this._isMuted;
    }
    /**
     * Toggles the mute/unmute state of the track
     * 
     * @returns Whether the GainNode is muted or not
     */
    public toggleMute(): boolean {
        return this.mute(!this._isMuted);
    }

    public solo(): boolean;
    /**
     * Set the solo flag of the Track.
     * Does not actually affect the signal path, only the flag.
     * 
     * @param value The value to set the solo flag to
     */
    public solo(value: boolean): boolean;
    /**
     * Set the solo flag of the Track and remove/reinsert the audio from the signal path.
     * 
     * If `hasSoloed` is true then we are in solo mode and want to disconnect/reconnect nodes based on solo/mute and connection.
     * if `hasSoloed` is false then we are NOT in solo mode and want to reconnect nodes based on mute and connection
     * 
     * @param value The value to set the solo flag to
     * @param hasSoloed Whether there's soloed nodes or not
     */
    public solo(value: boolean, hasSoloed: boolean): boolean;
    public solo(value?: boolean, hasSoloed?: boolean): boolean {
        if(value !== undefined) {
            this._isSoloed = value;
            if(hasSoloed !== undefined) { // We do nothing when we don't know whether some tracks are soloed or not
                const shouldBeConnected = (hasSoloed && this._isSoloed) || (!hasSoloed && !this._isMuted);
                const shouldBeDisconnected = (hasSoloed && !this._isSoloed) || (!hasSoloed && this._isMuted);
                const _isConnected = this._gain.numberOfInputs > 0;
                this._sources.forEach((source: AudioBufferSourceNode) => {
                    if(shouldBeConnected /*&& !_isConnected*/) {
                        source.disconnect();
                        source.connect(this._gain);
                    } else if (shouldBeDisconnected /*&& _isConnected*/) {
                        source.disconnect();
                        source.connect(this._mute);
                    }
                });
            }
        }

        return this._isSoloed;
    }
    /**
     * Toggles the solo flag of the GainNode
     * 
     * @returns Whether the GainNode solo flag is on or not
     */
     toggleSolo(): boolean {
        return this.solo(!this._isSoloed);
    }

    //#region Node Connect/Disconnect
    connect(destinationNode: AudioNode, output?: number, input?: number): AudioNode;
    connect(destinationParam: AudioParam, output?: number): void;
    connect(destinationNode: AudioNode | AudioParam, output?: number, input?: number): AudioNode | void {
        // this._connectionArgs = [destinationNode, output, input];
        if(destinationNode instanceof AudioNode) {
            this._mute.connect(destinationNode, output, input);
            return this._gain.connect(destinationNode as AudioNode, output, input);
        } else {
            this._mute.connect(destinationNode, output);
            return this._gain.connect(destinationNode as AudioParam, output);
        }
    }
    disconnect() {
        return this._gain.disconnect();
    }
    //#endregion

    destroy(): void {
        this.stop();
        this.buffer = NtAudioTrackNode.SCRATCH_BUFFER;
        delete this.buffer;
    }
}