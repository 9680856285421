import { Injectable, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';

import { SocketEventMessage, SocketService, Store } from '@app/core';
import { DrawingDataService } from '../services';
import { Drawing, IDrawingData } from '../types';


@Injectable({
  providedIn: 'root'
})
export class DrawingStore extends Store<Drawing, IDrawingData, DrawingDataService> implements OnDestroy {

  protected static readonly LIST_KEY_PARTS = ['projectId'];
  
  constructor(protected service: DrawingDataService, protected readonly socket: SocketService) {
    super(socket);
  }

  protected observe(): Observable<SocketEventMessage> {
    return this.socket.observe(Drawing);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
  
}
