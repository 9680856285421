<div wrapper>
    <nt-comment #commentRef [id]="'thread-' + comment.uuid" [editable]="true" [comment]="comment" (reply)="collapseReply = !collapseReply">
        <ng-container reply>
            <span [hidden]="!collapseReply">{{
            (replies$ | async).length
                ? (replies$ | async).length + ( (replies$ | async).length > 1 ? ' Replies' : ' Reply' )
                : 'Reply'
            }}</span>
            <span [hidden]="collapseReply">Hide Replies</span>
        </ng-container>
    </nt-comment>

    <ng-container *ngFor="let reply of (replies$ | async); trackBy:trackById">
        <ng-container *ngIf="!collapseReply">
            <nt-comment [id]="'thread-' + reply.uuid" [editable]="true" [comment]="reply"></nt-comment>
        </ng-container>
    </ng-container>
</div>
<div replyForm *ngIf="!collapseReply">
    <div *ngIf="!auth.currentUser?.id">
        <input #authorName type="text" placeholder="Your name" [ngModel]="anonymousName" [ntAutofocus]="focusReply && !auth.currentUser?.id && !anonymousName" />
        <input #authorEmail type="email" placeholder="Email address (optional)" />
    </div>
    <textarea #textarea placeholder="Reply" [disabled]="isSavingReply" [ntAutofocus]="focusReply && (!!auth.currentUser?.id || !!anonymousName)"
        [ngClass]="{ empty: !textarea.value }"
        (keypress)="onKeypress($event)"
        (focus)="textarea.value && onTextAreaChange($event)"
        (input)="textarea.value && onTextAreaChange($event)"
        (ngModel)="textarea.value && onTextAreaChange($event)"></textarea>
    <textarea #textareaControl style="opacity: 0; position: absolute; overflow: hidden; user-select: none; pointer-events: none;" [style.width]="textarea.clientWidth + 'px !important'">{{ textarea.value }}</textarea>

    <nt-audionote *ngIf="!hideAudionote" #audionote></nt-audionote>
    <nt-audionote *ngIf="hideAudionote" #audionote></nt-audionote>

    <div class="actions-buttons">
        <button class="cancel" (click)="onCancel()" [disabled]="isSavingReply">Cancel</button>
        <button #postRef class="basic1" (click)="onPost(textarea.value, authorName?.nativeElement?.value, authorEmail?.nativeElement?.value)" [disabled]="isSavingReply">Post</button> <!--(click)="post()" [disabled]="!isValidForm()"-->
    </div>
</div>