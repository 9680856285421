<!-- #region Track -->
<div class="trackDetails" (click)="pms.ui.selectedTrackUuid = media.uuid">
    <ng-container > <!--  *ngIf="isAudioReady" -->
        <div top>
            <span *ngIf="!(isCollapsed$ | async); else collapse" collapse (click)="toggleIsCollapsed()" [inlineSVG]="'/assets/images/icons/expanded.svg'"></span>
            <ng-template #collapse>
                <span collapse (click)="toggleIsCollapsed()" [inlineSVG]="'/assets/images/icons/collapsed.svg'"></span>
            </ng-template>
            <span *ngIf="pms.ui.journalMode; else collapseMobile" collapseMobile (click)="toggleCommentJournal()" [inlineSVG]="'/assets/images/icons/expanded.svg'"></span>
            <ng-template #collapseMobile>
                <span collapseMobile (click)="toggleCommentJournal()" [inlineSVG]="'/assets/images/icons/collapsed.svg'"></span>
            </ng-template>
            <span collapseMobile (click)="toggleCommentJournal()" [inlineSVG]="'/assets/images/icons/collapsed.svg'"></span>
            <span handle cdkDragHandle cdkDrag cdkDragLockAxis="y" cdkDragRootElement="nt-track-viewer" [inlineSVG]="'/assets/images/icons/hamburger.svg'"></span>
            <span name [contentEditable]="!!(project$ | async)?.userCanWrite" (keypress)="onNameKeypress($event)" (blur)="setName($event)">{{ media.name }}</span>
            <span menu #trackTrigger="matMenuTrigger" [matMenuTriggerFor]="trackMenu" [inlineSVG]="'/assets/images/icons/ellipses.svg'" (click)="onTrackMenuClicked($event)"></span>

            <mat-menu #trackMenu="matMenu" yPosition="below" class="track-menu">
                <span class="track-menu-option" (click)="exportToHeadliner(media.fMain.href)">
                    <span [inlineSVG]="'/assets/images/icons/play.svg'"></span>
                    Export to Headliner
                </span>
                <span class="track-menu-option" (click)="updateTrack()">
                    <span [inlineSVG]="'/assets/images/icons/upload.svg'"></span>
                    Update
                </span>
                <a class="track-menu-option" (click)="exportTrackAudio()">
                    <span [inlineSVG]="'/assets/images/icons/download.svg'"></span>
                    Download
                </a>
                <a class="track-menu-option" [href]="media.fOriginal?.href | safeUrl">
                    <span [inlineSVG]="'/assets/images/icons/download.svg'"></span>
                    Download original
                </a>
                <span class="track-menu-option" [ngClass]="{ disabled : !(comments$ | async)?.length }" (click)="exportAudacityLabels()">
                    <span [inlineSVG]="'/assets/images/icons/phylacteries.svg'"></span>
                    Export Audacity Labels
                </span>
                <span class="track-menu-option" [ngClass]="{ disabled : !(comments$ | async)?.length }" (click)="exportAuditionMarkers()">
                    <span [inlineSVG]="'/assets/images/icons/phylacteries.svg'"></span>
                    Export Audition Markers
                </span>
                <span class="track-menu-option" (click)="deleteTrack()">
                    <span [inlineSVG]="'/assets/images/icons/trash.svg'"></span>
                    Delete
                </span>
            </mat-menu>

        </div>
        <div bottom>
            <span color (click)="onTrackColorClicked($event)" [ngClass]="media.color"><span>{{media.sequence}}</span></span>
            <span colorTrigger #trackColorTrigger="matMenuTrigger" [matMenuTriggerFor]="trackColorMenu"></span>
            <mat-menu #trackColorMenu="matMenu" yPosition="above" [overlapTrigger]="false" class="track-color-menu">
                <ng-container *ngFor="let color of ['freedo','passio','balanc','spirit','creati']">
                    <span *ngIf="(media.color || 'freedo') != color" color [ngClass]="color" (click)="setColor(color)"></span>
                </ng-container>
            </mat-menu>
            <!-- <span download [inlineSVG]="'/assets/images/icons/download.svg'"></span>
            <span share [inlineSVG]="'/assets/images/icons/reply.svg'"></span> -->
            <span mute [attr.disabled]="!isAudioReady" [ngClass]="{ muted, soloed }" (click)="toggleMuteTrack()">M</span>
            <span solo [attr.disabled]="!isAudioReady" [ngClass]="{ soloed }" (click)="toggleSoloTrack()">S</span>
            <span volume [attr.disabled]="!isAudioReady" (click)="onVolumeClicked($event)" (dblclick)="onVolumeClicked($event)">
                <span *ngIf="volume >= 1" [inlineSVG]="'/assets/images/icons/speakermax.svg'"></span>
                <span *ngIf="volume < 1 && volume > 0" [inlineSVG]="'/assets/images/icons/speakermin.svg'"></span>
                <span *ngIf="volume === 0" [inlineSVG]="'/assets/images/icons/speakeroff.svg'"></span>
            </span>
            <span [attr.disabled]="!isAudioReady" #volumeTrigger="matMenuTrigger" [matMenuTriggerFor]="volumeMenu"></span>
            <mat-menu #volumeMenu="matMenu" yPosition="above" [overlapTrigger]="false" class="volume-menu">
                <mat-slider min="0" max="1.5" step="0.01" vertical="false" [(ngModel)]="volume" (click)="$event.stopPropagation()" (input)="volume = $event.value" (change)="onVolumeChange($event)"></mat-slider>
            </mat-menu>
            <span switch [ngClass]="{ off: !media.isActive }" (click)="setIsActive(!media.isActive)"></span>
            <div *ngIf="uploadProgress !== undefined" class="mediaUploadProgress">
                <div class="fill"
                    [ngClass]="{ full: uploadProgress === 100, done: uploadProgressDescription === 'completed' }"
                    [style.width]="uploadProgress + '%'">
                    <span>{{ uploadProgressDescription }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div #waveform class="waveform" [ngClass]="media.color"  (click)="pms.ui.selectedTrackUuid = media.uuid" (click)="onWaveformClicked($event)" [style.background-position]="(pms.ui.scrollOffset$ | async) + 'px 0px'">
    <mat-spinner *ngIf="!isReady" diameter="50" strokeWidth="4"></mat-spinner>
    <div #clips [id]="'clips_' + media.uuid" class="clips" style="height: 100%;" [style.width]="(pms.ui.width$ | async) + 'px'" [style.transform]="'translateX(' + (pms.ui.scrollOffset$ | async) + 'px)'"
        (dblclick)="waveformDoubleClick($event)" (click)="click$$.next($event)">
        <ng-container *ngIf="isAudioReady">
            <ng-container *ngIf="media?.metadata?.clips; else noClips">
                <nt-clip #ntClip *ngFor="let clip of media.metadata.clips; trackBy: trackByClipUuid"
                    [id]="clip.uuid"
                    [ntDraggable]="clip.uuid === (pms.ui.selectedClipUuid$ | async)"
                    [ntDraggableDropSelector]="'#clips_' + media.uuid"
                    ntDraggableTriggerDropOn="source"
                    [track]="trackNode" [clip]="clip" [color]="media.color" [style.left.px]="clip.position / (pms.ui.pixelDuration$ | async)"
                    (select)="pms.ui.selectedTrackUuid = media.uuid"
                    (move)="onClipMove($event, clip)"
                    (drop)="onClipChanged(clip)"
                    (change)="onClipChanged($event)"
                    (split)="onClipSplit($event)" (delete)="onClipDelete($event)"></nt-clip>
            </ng-container>
            <ng-template #noClips>
                <nt-clip [track]="trackNode" [clip]="null" [color]="media.color"
                    (split)="onClipSplit($event)" (delete)="onClipDelete($event)"></nt-clip>
            </ng-template>
        </ng-container>
    </div>
    <!-- <canvas #canvas class="canvas" height="100%" [width]="16384 * 3" [style.width]="(pms.ui.width$ | async) + 'px'" [style.transform]="'translateX(' + (pms.ui.scrollOffset$ | async) + 'px)'"
       ></canvas> -->
    <div #rangeSelector class="range-selector"></div>
</div>
<!-- #endregion -->

<!-- #region Track Comments-->

<div #sidePaneComment class="trackComments sidePane" [ngClass]="{ collapsed: commentCollapsed$ | async}">
    <span annotations (click)="toggleCollapseComment()" [inlineSVG]="'/assets/images/icons/collapsed.svg'" [prepend]="true">
        Comments
    </span>
</div>

<div #commentsPane class="trackComments commentsPane" [ngClass]="{ collapsed: commentCollapsed$ | async}"
    [style.background-position]="(pms.ui.scrollOffset$ | async) + 'px 0px'"
    [style.background-size]=" (10 / (pms.ui.pixelDuration$ | async) / 1.2) + 'px 1px'"
>
    <ng-container *ngIf="comments$ | async as comments; else noComments">
        <div comments [style.transform]="'translateX(' + (pms.ui.scrollOffset$ | async) + 'px)'" [style.height]=" (commentsHeight + 18 + 9) + 'px'">
            <ng-container *ngFor="let comment of comments; trackBy: trackByCommentId, let i = index" [style.width]="(pms.ui.width$ | async) + 'px'">
                <nt-marker ntDraggable [ngClass]="{ userCanUpdate: comment.userCanUpdate, userCanDelete: comment.userCanDelete }"
                    [offsetX]="comment.timecode / (pms.ui.pixelDuration$ | async)" [offsetXEnd]="comment.timecodeEnd / (pms.ui.pixelDuration$ | async)"
                    (move)="comment.timecode = limitTimecode(comment.timecode + ($event.movementX * pms.ui.pixelDuration), undefined, comment.timecodeEnd)"
                    (drop)="onMarkerChanged(comment)" ntDraggableTriggerDropOn="source"></nt-marker>
                <nt-marker ntDraggable end *ngIf="comment.timecodeEnd"
                    [ngClass]="{ userCanUpdate: comment.userCanUpdate, userCanDelete: comment.userCanDelete }"
                    [offsetX]="comment.timecodeEnd / (pms.ui.pixelDuration$ | async)"
                    (move)="comment.timecodeEnd = limitTimecode(comment.timecodeEnd + ($event.movementX* pms.ui.pixelDuration), comment.timecode)"
                    (drop)="onMarkerChanged(comment)" ntDraggableTriggerDropOn="source"></nt-marker>
                <nt-comment [id]="'track-' + comment.uuid" [ngClass]="{ userCanUpdate: comment.userCanUpdate, userCanDelete: comment.userCanDelete }"
                    #comment [style.z-index]="'-' + i"
                    [comment]="comment" [showLines]="2" [editable]="true"
                    [offsetX]="comment.timecode / (pms.ui.pixelDuration$ | async)"
                    [offsetY]="commentsOffsetY && commentsOffsetY[comment.id] ? commentsOffsetY[comment.id] : 0"
                    (more)="onOpenComment(comment)"
                    (reply)="onOpenComment(comment, true)"
                >
                    <ng-container reply>{{
                        (commentRepliesCount$ | async)[comment.uuid]
                            ? (commentRepliesCount$ | async)[comment.uuid] + ( (commentRepliesCount$ | async)[comment.uuid] > 1 ? ' Replies' : ' Reply' )
                            : 'Reply'
                    }}</ng-container>
                </nt-comment>
                <!-- </ng-container> -->
            </ng-container>
            <div #newCommentContainer></div>
        </div>
    </ng-container>
    <ng-template #noComments>
        <div noComments>
            Add comments, drawing and stamps using your toolbox in the top bar
        </div>
    </ng-template>
</div>
<!-- #endregion -->


<!-- #region Track Drawings-->
<div #sidePaneDrawing class="trackDrawings sidePane" [ngClass]="{ collapsed: drawingCollapsed$ | async}">
    <span drawings (click)="toggleCollapseDrawing()" [inlineSVG]="'/assets/images/icons/collapsed.svg'" [prepend]="true">Drawings</span>
</div>

<div #drawingsPane class="trackDrawings drawingsPane" [ngClass]="{ collapsed: drawingCollapsed$ | async}"
    (ntDrop)="onDrawingDropFromTools($event)" (click)="onClickDrawingPane($event)"
    [style.background-position]="(pms.ui.scrollOffset$ | async) + 'px 0px'"
    [style.background-size]=" (10 / (pms.ui.pixelDuration$ | async) / 1.2) + 'px 1px'"
>

    <ng-container *ngIf="drawings$ | async as drawings; else noDrawings">
        <div drawings
            (dblclick)="onDoubleClickDrawingsPane($event)"
            [style.transform]="'translateX(' + (pms.ui.scrollOffset$ | async) + 'px)'"
            [style.width.px]="(pms.ui.width$ | async)"
            [style.height.px]="(drawingsHeight + 18 + 9)">

            <div drawingRow class="drawingRow" *ngFor="let drawingRow of getRows()" id="{{ drawingRow }}">
                <ng-container *ngFor="let drawing of getDrawingsByRow(drawingRow); trackBy: trackByDrawingId, let i = index" [style.width]="(pms.ui.width$ | async) + 'px'">
                    <nt-drawing
                        [ntDraggable]="drawing.userCanUpdate" ntDraggableDropSelector=".drawingRow,.drawingsPane"
                        ntDraggableTriggerDropOn="source"
                        (start)="pms.ui.drawingColor = drawing.color"
                        (move)="onMoveDrawing($event, drawing)"
                        (click)="drawing.userCanDelete && toolService.idSelectedDrawing = drawing.id"
                        [style.z-index]="toolService.idSelectedDrawing == drawing.id ? '999' : 'unset'"
                        [ngClass]="{ selected : toolService.idSelectedDrawing == drawing.id, userCanUpdate: drawing.userCanUpdate, userCanDelete: drawing.userCanDelete }"
                        class="{{ drawing.type }}"
                        [drawing]="drawing"
                        [offsetX]="drawing.timecode / (pms.ui.pixelDuration$ | async)"></nt-drawing>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #noDrawings>
        <div noDrawings>
            Add drawings using your toolbox in the top bar
        </div>
    </ng-template>
</div>

<!-- #endregion -->
