import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, IApiOptions, IApiResponse } from '@app/core';
import { IUserStateData, IUserStateDataList, UserState, UserStateList } from '../../types';
import { HttpContext, HttpHeaders } from '@angular/common/http';
import { IDS } from '.';

@Injectable({
  providedIn: 'root'
})
export class UserStateDataService {

    constructor(private api: ApiService) { }

  //#region CRUD Operation
  public list(params?: Record<string, any>): Observable<UserStateList> {
    throw { message: 'Unsupported operation' };
  }

  public get(params?: Record<string, any>): Observable<UserState> {
    const { id, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
        options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }
    return this.api.get<IUserStateData>(`project/${id}/userstate`, options).pipe(
      map((response: IApiResponse<IUserStateData>) => {
        return new UserState(Object.assign({ id }, response.result));
      })
    );
  }

  public save(userStateData: Partial<IUserStateData>): Observable<UserState> {
    const { id } = userStateData; delete userStateData.id;
    const context = new HttpContext().set(IDS, { id });

    const options: IApiOptions = { observe: 'response' as 'response', context };
    // if (publicKey) {
    //   options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    // }

    return this.api.post<IUserStateData, Partial<IUserStateData>>(`project/${id}/userstate`, userStateData, options).pipe(
      map((response: IApiResponse<IUserStateData>) => {
        return new UserState(Object.assign({ id }, response.result));
      })
    );
  }

  public delete(params?: Record<string, any>): Observable<boolean> {
    const { id, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
        options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }
    return this.api.delete<boolean>(`project/${id}/userstate`).pipe(
      map((response: IApiResponse<boolean>) => {
        return response.result;
      })
    );
  }
  //#endregion
  
}
