import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CKEditor5, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, filter, map, Observable, Subject, take, takeUntil } from 'rxjs';

import * as Editor from '../../../../../../vendor/ckeditor5/build/ckeditor';
import { StoreService } from '../../project.module';
import { Project } from '../../types';

@Component({
    selector: 'nt-notepad',
    templateUrl: './notepad.component.html',
    styleUrls: ['./notepad.component.scss', './notepad-responsive.component.scss']
})
export class NotepadComponent implements OnInit, OnDestroy {

    public readonly Editor: any = Editor;
    @Input('project$') project$: Observable<Project>;
    @Input('editable') @HostBinding('class.editable') editable: boolean;
    @HostBinding('class.editing') public editMode: boolean;
    @HostBinding('style.--nt-ck-toolbar-height') ckToolbarHeight: string;

    @ViewChild('ckeditor') ckeditor: CKEditorComponent;

    public config: CKEditor5.Config = { toolbar: { shouldNotGroupWhenFull: true }};

    private _data$$: BehaviorSubject<string> = new BehaviorSubject(undefined);
    public data$: Observable<string> = this._data$$.pipe(filter(v => v !== undefined));

    private _fixedTimer: number;
    private _typingTimer: number;

    private _destroy: Subject<void> = new Subject();

    constructor(private readonly host: ElementRef<HTMLElement>, private readonly sanitizer: DomSanitizer, private readonly store: StoreService) { }

    ngOnInit(): void {
        this.project$.pipe(takeUntil(this._destroy)).subscribe((project: Project) => {
            this._data$$.next(project.notepad);
        });
    }

    onCkReady(event) {
        console.log('onCkReady', event);
        // We rely on the toolbar height to calculate the content height for proper scrolling
        const toolbar = this.host.nativeElement.querySelector('ckeditor .ck.ck-editor .ck.ck-editor__top .ck.ck-sticky-panel');
        this.ckToolbarHeight = `${toolbar.clientHeight}px`;
    }

    ngOnDestroy(): void {
        if(this.editMode && (this._fixedTimer || this._typingTimer)) {
            if(this._fixedTimer) clearTimeout(this._fixedTimer);
            if(this._typingTimer) clearTimeout(this._typingTimer);
            this.save();
        }
        this._destroy.next();
        this._destroy.complete();
    }

    setEditMode(mode: boolean) {
        console.log('setEditMode', mode);
        this.editMode = mode;
        if(this.editMode) {
            setTimeout(() => this.ckeditor.editorInstance.editing.view.focus());
        }
    }

    onSave() {
        console.log('onSave');
        this.save();
        this.setEditMode(false);
    }

    onNotepadChange(event) {
        console.log('onNotepadChange', event);
        this._data$$.next(event.editor.getData());
        // Only save after 1 sec not typing
        // if(this._typingTimer) clearTimeout(this._typingTimer);
        // this._typingTimer = window.setTimeout(() => {
        //     delete this._typingTimer;
        //     if(this._fixedTimer) clearTimeout(this._fixedTimer);
        //     delete this._fixedTimer;
        // }, 1000);

        // Regardless, save every 5 sec if changes happened
        if(this._fixedTimer) return;
        this._fixedTimer = window.setTimeout(() => {
            delete this._fixedTimer;
            if(this._typingTimer) clearTimeout(this._typingTimer);
            delete this._typingTimer;
        }, 5000);
    }

    onNotepadFocus(event) {
        console.log('onNotepadFocus', event);
        if(this.editable && !this.editMode) {
            this.setEditMode(true);
        }
    }

    private save() {
        console.log('save');
        this.project$.pipe(take(1)).subscribe((project: Project) => {
            project.notepad = this._data$$.value;
            this.store.project.save(project).subscribe();
        });
    }

}
