import { Injectable, OnDestroy } from '@angular/core';
import { HttpProgressEvent } from '@angular/common/http';

import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { FileService } from '@app/core';
import { NtAudioBufferSourceNode, NtGainNode } from '../types';
import { Jungle } from '../types/jungle';
import { IPlayer, Loop, State } from '../types/iplayer';
import { NtAudioTrackNode } from '../types/nt-audio-track-node';
import { Metadata } from '@app/features/project/types';

const isDemo = false; // @todo Inject

@Injectable({
    providedIn: 'root'
})
export class AudioService implements OnDestroy {

    private static Player2 = class Player2 implements IPlayer {
        private _masterGain: GainNode;
        private _pitch: Jungle;
        private _outputGain;
        private _volume: number = 1;
        private _speed$$: BehaviorSubject<number> = new BehaviorSubject(1);
        public speed$: Observable<number> = this._speed$$.asObservable();

        //#region Playing State
        public get isPlaying(): boolean { return this._playing$$.value; }
    
        private _playing$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
        public playing$: Observable<boolean> = this._playing$$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));
    
        private _state$$: BehaviorSubject<State> = new BehaviorSubject(undefined);
        public get state$(): Observable<State> {
            return this._state$$.asObservable().pipe(filter(s => s !== undefined));
        }
        //#endregion

        //#region Looping
        private _isLooping: boolean = false;
        public get isLooping(): boolean {
            return this._isLooping;
        }
        private _loop: Loop;
        public get loop(): Loop {
            return this._loop;
        }
        public set loop(value: Loop | number[]) {
            if (Array.isArray(value)) {
                const [start, end] = (value).sort();
                value = { start, end };
            }
    
            if (value.start > value.end) return;
    
            this._loop = value;
            this.looping(this._isLooping);
        }
        //#endregion

        //#region Timing
        /**
         * The timestamp when AudioContext was last played
         */
        private _startTimestamp: number;
        private _timeInterval: number;
        /**
         * Current playing position
         */
        private readonly _duration$$: BehaviorSubject<number> = new BehaviorSubject(undefined);
        public readonly duration$: Observable<number> = this._duration$$.asObservable().pipe(filter(s => s !== undefined));
        public get duration(): number {
            return this._duration$$.value;
        }
        /**
         * Current playing position
         */
         private _currentTime: number = 0;
        public get currentTime(): number {
            if (this.isPlaying) {
                let ct = ((Date.now() - this._startTimestamp) / 1000 * this._speed$$.value) + this._currentTime;
                if (this._isLooping && this.loop.start !== this.loop.end && ct >= this.loop.end) {
                    const loopDuration = (this.loop.end - this.loop.start) * 1000;
                    this._startTimestamp = this._startTimestamp + loopDuration;
                    ct = ((Date.now() - this._startTimestamp) / 1000 * this._speed$$.value) + this._currentTime;
                    this.pause(false);
                    this.play(false);
                }

                if (ct > this.duration) { this.pause(); }
                return ct;
            }
    
            return this._currentTime;
        }
        public set currentTime(time: number) {
            const isPlaying = this.isPlaying;
            if (isPlaying) {
                this.pause(false);
            }

            time = Math.min(this.duration || 0, Math.max(0, time)); // Changes not appearing
    
            this._currentTime = time;
            this._currentTime$$.next(this._currentTime);
            this._startTimestamp = Date.now();
    
            if (isPlaying) {
                this.play(false);
            }
        }
        private readonly _currentTime$$: BehaviorSubject<number> = new BehaviorSubject(this._currentTime);
        public readonly currentTime$: Observable<number> = this._currentTime$$.asObservable();
        //#endregion

        private _tracks: Record<string, NtAudioTrackNode>;

        
        public trackBuffers: Record<string, AudioBuffer>;

        /**   
         * Record of downloading Observable AudioBufferSourceNode
         */
        private _downloadConvertQueue: Record<string, Observable<AudioBuffer | HttpProgressEvent>>;

        constructor(private audio: AudioService) {}

        /**
         * Initialize the player with fresh starting values
         */
        public initialize(session: string) {
            if (session !== this.audio._session) {
                this.audio._session = session;
                this.audio._context = new AudioContext();
                this._masterGain = this.audio._context.createGain();
                this._pitch = new Jungle(this.audio._context);
                this._outputGain = this.audio._context.createGain();
                this._pitch.setPitchOffset(0);
                this.speed(1);
                this._tracks = {};
                this._downloadConvertQueue = {};
                this.currentTime = 0;
    
                this.trackBuffers = new Proxy<Record<string, AudioBuffer>>(this._tracks as any, {
                    get: (target: any, name): AudioBuffer => {
                        return target[name.toString()]?.buffer || undefined;
                    }
                });
            }
    
            return this.audio._session === session;
        }

        public destroy(session: string) {
            if (session === this.audio._session) {
                Object.keys(this._tracks).forEach((k: string) => {
                    const track = this._tracks[k].destroy();
                })
            }
        }

        exportTrack(hash: string, name: string) {
            const trackNode: NtAudioTrackNode = this._tracks[hash];
            const sampleLength = Math.floor(trackNode.duration * this.audio._context.sampleRate);

            const offline = new OfflineAudioContext(this.audio._context.destination.channelCount, sampleLength, this.audio._context.sampleRate);

            const offlineTrackNode = new NtAudioTrackNode(offline, trackNode.buffer, trackNode.metadata);
            offlineTrackNode.connect(offline.destination);
            offlineTrackNode.playAt(0);

            offline.oncomplete = (event: OfflineAudioCompletionEvent) => {
                const file = this.audio.bufferToWavFile(event.renderedBuffer, name);
                const url = URL.createObjectURL(file);

                // TODO REFACTOR IN A REUSABLE METHOD IN FILESERVICE
                const anchor = document.createElement('a');
                anchor.setAttribute('href', url);
                anchor.setAttribute('download', file.name + '.wav');
                anchor.click();
                anchor.remove();
                setTimeout(() => URL.revokeObjectURL(url), 100);
                // END TODO
                
            }
            offline.startRendering();
        }

        // This is not ideal as any change to the chain requires this method to adapt,
        // it would be good to refactor the Player to be able to swap context at some point
        exportMix(name: string) {
            const numberOfChannels = this.audio._context.destination.channelCount;
            const sampleLength = this.duration * this.audio._context.sampleRate;
            const offline = new OfflineAudioContext(numberOfChannels, sampleLength, this.audio._context.sampleRate);

            const masterGain = offline.createGain();
            masterGain.gain.value = this._masterGain.gain.value;
            const speed = this._speed$$.value;
            const pitch = new Jungle(offline);
            if(speed !== 1) {
                const pitchCorrection = (1 - this._speed$$.value) / this._speed$$.value / 0.5;
                pitch.setPitchOffset(pitchCorrection);
            } else {
                pitch.setPitchOffset(0);
            }
            const volume = this._volume;
            const outputGain = offline.createGain();
            outputGain.gain.value = this._outputGain.gain.value;

            masterGain.gain.setValueAtTime(volume, 0);
            pitch.output.connect(outputGain);
            masterGain.connect(outputGain);
            outputGain.connect(offline.destination);

            if(speed !== 1) {
                try { masterGain.disconnect(outputGain) } catch(e) {}
                masterGain.connect(pitch.input);
            } else {
                try { masterGain.disconnect(pitch.input) } catch(e) {}
                masterGain.connect(outputGain);
            }

            const offlineTracks: Array<NtAudioTrackNode> = Object.keys(this._tracks).map((hash) => {
                const oTrack = this._tracks[hash].cloneWithContext(offline);
                oTrack.connect(masterGain);
                return oTrack;
            });

            Object.keys(offlineTracks).forEach(((hash) => {
                const oTrack = offlineTracks[hash];
                oTrack.speed = speed;
                oTrack.playAt(0);
            }));

            offline.oncomplete = (event: OfflineAudioCompletionEvent) => {
                const file = this.audio.bufferToWavFile(event.renderedBuffer, name);
                const url = URL.createObjectURL(file);

                // TODO REFACTOR IN A REUSABLE METHOD IN FILESERVICE
                const anchor = document.createElement('a');
                anchor.setAttribute('href', url);
                anchor.setAttribute('download', file.name + '.wav');
                anchor.click();
                anchor.remove();
                setTimeout(() => URL.revokeObjectURL(url), 100);
                // END TODO
                
            }
            offline.startRendering();
        }

        play(triggerState: boolean = true) {
            this._startTimestamp = Date.now(); // Track/Clip ???
    
            this._masterGain.gain.setValueAtTime(this._volume, this.audio._context.currentTime);
            // this._masterGain.connect(this._outputGain);
            this._pitch.output.connect(this._outputGain);
            this._masterGain.connect(this._outputGain);
            this._outputGain.connect(this.audio._context.destination);

            if(this._speed$$.value !== 1) {
                try { this._masterGain.disconnect(this._outputGain); } catch(e) {}
                this._masterGain.connect(this._pitch.input);
            } else {
                try { this._masterGain.disconnect(this._pitch.input); } catch(e) {}
                this._masterGain.connect(this._outputGain);
            }
    
            Object.keys(this._tracks).forEach(hash => {
                const track: NtAudioTrackNode = this._tracks[hash];
                track.speed = this._speed$$.value;
                track.playAt(this._currentTime)
                return;
            });
    
            this._playing$$.next(true);
    
            this._timeInterval = requestAnimationFrame(this.onFrameAnimation);
    
            // this._timeInterval = setInterval(() => this._currentTime$.next(this.currentTime), 250, []); // 0.00000340382
            if(triggerState) this._state$$.next('playing');
        }
        pause(triggerState: boolean = true) {
            this._playing$$.next(false);
            Object.keys(this._tracks).forEach(hash => {
                try {
                    this._tracks[hash].stop();
                } catch (e) {
                    console.warn(e.message);
                }
            });
    
            // if(this._timeInterval) { clearInterval(this._timeInterval); }
            if (this._timeInterval) { cancelAnimationFrame(this._timeInterval); }
            this.currentTime = ((Date.now() - this._startTimestamp) / 1000) * this._speed$$.value + this._currentTime;
            if(triggerState) this._state$$.next('paused');
        }
        stop(triggerState: boolean = true) {
             // if (this._recorder && this._recorder.state !== 'inactive') {
            //     this._recorder.stop();
            // }
            this._playing$$.next(false);
            Object.keys(this._tracks).forEach(hash => {
                try {
                    this._tracks[hash].stop();
                } catch (e) {
                    console.warn(e.message);
                }
            });
    
            // if(this._timeInterval) { clearInterval(this._timeInterval); }
            if (this._timeInterval) { cancelAnimationFrame(this._timeInterval); }
            this.currentTime = this.isLooping && this.currentTime > this.loop.start ? this.loop.start : 0;
            if(triggerState) this._state$$.next('stopped');
        }
        seek(increment: number) {
            const isPlaying = this.isPlaying;
            if (isPlaying) {
                this.pause();
            }
    

            this._currentTime += increment;
            this._currentTime$$.next(this._currentTime);
            this._startTimestamp = Date.now();
    
            if (isPlaying) {
                this.play();
            }
        }
        looping(loop: boolean, gotoLoopStart?: boolean) {
            this._isLooping = loop;
    
            if (this._isLooping && (gotoLoopStart || (this.isPlaying && this.currentTime > this.loop.end))) {
                this.currentTime = this.loop.start;
            }
        }
        toggleLooping(gotoLoopStart?: boolean) {
            this.looping(!this._isLooping, gotoLoopStart);
        }
        volume(volume?: number): number {
            if (volume !== undefined) {
                this._volume = volume;
                if(this.isPlaying) {
                    this._masterGain.gain.setValueAtTime(this._volume, this._currentTime);
                }
            }
    
            return this._volume;
        }
        speed(speed?: number): number {
            if (speed !== undefined && speed !== this._speed$$.value) {
                if(this.isPlaying) {
                    this.pause(false);
                    this._speed$$.next(speed);
                    Object.keys(this._tracks).forEach(hash => {
                        const track: NtAudioTrackNode = this._tracks[hash];
                        track.speed = this._speed$$.value;
                    });
                    if(this._speed$$.value !== 1) {
                        const pitchCorrection = (1 - this._speed$$.value) / this._speed$$.value / 0.5;
                        this._pitch.setPitchOffset(pitchCorrection);
                    } else {
                        this._pitch.setPitchOffset(0);
                    }
                    this.play(false);
                } else {
                    this._speed$$.next(speed);
                }
            }
    
            return this._speed$$.value;
        }
        trackVolume(hash: string, volume?: number) {
            if (volume !== undefined) {
    
                this._tracks[hash].volume(volume);
            }
    
            return this._tracks[hash].volume();
        }
        muteTrack(hash: string, mute?: boolean): boolean {
            if (mute !== undefined) {
                this._tracks[hash].mute(mute);
            }
    
            return this._tracks[hash].mute();
        }
        toggleMuteTrack(hash: string) {
            this._tracks[hash].toggleMute();
        }
        soloTrack(hash: string, solo?: boolean): boolean {
            if (solo !== undefined) {
                this._tracks[hash].solo(solo);
    
                // Go throught all nodes to refresh their solo state
                const hasSoloedTrack = Object.keys(this._tracks).map(h => this._tracks[h].isSoloed).reduce((sum, curr) => sum || curr, false);
                Object.keys(this._tracks).forEach(h => {
                    this._tracks[h].solo(this._tracks[h].isSoloed, hasSoloedTrack);
                });
            }
    
            return this._tracks[hash].solo();
        }
        toggleSoloTrack(hash: string) {
            if (this._tracks && this._tracks[hash]) {
                this.soloTrack(hash, this._tracks[hash].toggleSolo());
            }
        }
        getTrackBuffer(hash: string): Observable<AudioBuffer> {
            if(hash in this._downloadConvertQueue) {
                return this._downloadConvertQueue[hash].pipe(
                    filter((dl: AudioBuffer | HttpProgressEvent) => dl instanceof AudioBuffer),
                    map(dl => dl as AudioBuffer)
                )
            } else if(hash in this._tracks) {
                return of(this._tracks[hash].buffer);
            } else {
                return of(undefined);
            }
        }
        getTrackDuration(hash: string): number {
            return this._tracks[hash].duration;
        }

        /**
         * Add audio to the player
         * 
         * @param hash Unique string identifier
         * @param audio The audio to add
         * @param metadata The track metadata (effects/clips)
         * @returns An Observable boolean that returns true when the audio is loaded and ready
         */
        public addTrack(hash: string, audio: URL, metadata: Metadata): Observable<NtAudioTrackNode>;
        public addTrack(hash: string, audio: URL, metadata: Metadata, reportProgress: boolean): Observable<NtAudioTrackNode | HttpProgressEvent>;
        public addTrack(hash: string, audio: URL, metadata: Metadata, reportProgress: boolean = false): Observable<NtAudioTrackNode | HttpProgressEvent> {
            if (!(hash in this._downloadConvertQueue)) { // Not already downloading
                const o = reportProgress ? { reportProgress } : undefined;
                this._downloadConvertQueue[hash] = this.audio.fileService.downloadFile(audio, o).pipe(
                    switchMap((dl: Blob | HttpProgressEvent) => {
                        if(dl instanceof Blob) {
                            return of(dl).pipe(
                                switchMap(this.audio.blobToBuffer)
                            )
                        } else {
                            return of(dl);
                        }
                    }),
                    shareReplay(1)
                );
            }
    
            return this._downloadConvertQueue[hash].pipe(
                switchMap((dl: AudioBuffer | HttpProgressEvent) => {
                    if(dl instanceof AudioBuffer) {
                        return of(dl).pipe(
                            tap((ab: AudioBuffer) => {
                                delete this._downloadConvertQueue[hash];
                                this._tracks[hash] = new NtAudioTrackNode(this.audio._context, ab, metadata);
                                this._tracks[hash].connect(this._masterGain);
                                const duration = Object.keys(this._tracks).map(hash => {
                                    return this._tracks[hash].duration;
                                }).reduce((sum, curr) => Math.max(sum, curr), 0);
                                this._duration$$.next(duration);

                                // Start playing the added buffer at current offset
                                if (this.isPlaying) {
                                    const currentTime = ((Date.now() - this._startTimestamp) / 1000) * this._speed$$.value + this._currentTime;
                                    this._tracks[hash].playAt(currentTime);
                                }
                            }),
                            map((ab: AudioBuffer) => {
                                return this._tracks[hash];
                            })
                        );
                    } else {
                        return of(dl);
                    }
                }),
                shareReplay(1)
            );
        }

        removeTrack(hash: string) {
            try {
                this._tracks[hash].stop();
            } catch (e) { }
            try {
                this._tracks[hash].disconnect();
            } catch (e) { }
            try {
                this._tracks[hash].destroy();
            } catch (e) { }
    
            delete this._tracks[hash];
    
            const duration = Object.keys(this._tracks).map(hash => {
                return this._tracks[hash].duration;
            }).reduce((sum, curr) => Math.max(sum, curr), 0);
            this._duration$$.next(duration);
        }

        //#region Event Handlers
        private onFrameAnimation = () => {
            this._currentTime$$.next(this.currentTime);
            if (this.isPlaying) {
                this._timeInterval = requestAnimationFrame(this.onFrameAnimation);
                if(this.isLooping) {
                    Object.keys(this._tracks).forEach(hash => {
                        const track = this._tracks[hash];
                        if(this._currentTime$$.value > track.duration) {
                            track.stop();
                        }
                    });
                }
            }
        }
        //#endregion
    }
    public player = new AudioService.Player2(this);

    private static Recorder = class Recorder {
        private _recorder: MediaRecorder;
        public get isRecording(): boolean { return this._recording$$.value; }
        private _recording$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
        public recording$: Observable<boolean> = this._recording$$.asObservable().pipe(distinctUntilChanged(), shareReplay(1));
        private _recordedBuffer$$: BehaviorSubject<Blob>;
        public recordedBuffer$: Observable<Blob>;

        constructor(private audio: AudioService) {}
    
        /*
         * Record an audio track
        */
        public start() {
            this._recordedBuffer$$ = new BehaviorSubject(undefined);
            this.recordedBuffer$ = this._recordedBuffer$$.asObservable().pipe(filter((value: Blob) => value !== undefined), shareReplay(1));
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream: MediaStream) => {
                this._recorder = new MediaRecorder(stream);
    
                const chunks: Blob[] = [];
    
                this._recorder.addEventListener('start', () => {
                    this._recording$$.next(true);
                });
                this._recorder.addEventListener('dataavailable', (event: BlobEvent) => {
                    chunks.push(event.data);
                    const blob = new Blob(chunks, { type: this._recorder.mimeType });
                    this._recordedBuffer$$.next(blob);
                });
                this._recorder.addEventListener('stop', () => {
                    this._recorder.removeAllListeners();
                    delete this._recorder;
                    this._recording$$.next(false);
                    this._recordedBuffer$$.complete();
                });
    
                this._recorder.start(500);
                this.audio.player.play();
            });
        }
    
        public stop() {
            if (this._recorder && this._recorder.state !== 'inactive') {
                this._recorder.stop();
            }
        }
    
        public reset() {
            delete this._recorder;
            delete this._recordedBuffer$$;
            delete this.recordedBuffer$;
        }
    };
    public recorder = new AudioService.Recorder(this);

    protected _session: string;

    /**
     * The active AudioContext
     */
    protected _context: AudioContext;

    constructor(public readonly fileService: FileService) { }

    ngOnDestroy(): void {
        this.destroySession();
    }

    destroySession(): void {
        this.player.destroy(this._session);
    }

    public decodeAudioData = (arrayBuffer: ArrayBuffer): Observable<AudioBuffer> => {
        return from(this._context.decodeAudioData(arrayBuffer));
    }

    public blobToAudioBufferSourceNode = (blob: Blob): Observable<AudioBufferSourceNode> => {
        return this.blobToBuffer(blob).pipe(map((buffer: AudioBuffer) => {
            const source = this._context.createBufferSource();
            source.buffer = buffer;
            return source;
        }));
    }

    public blobToBuffer = (blob: Blob): Observable<AudioBuffer> => {
        return from(blob.ntArrayBuffer()).pipe(switchMap(this.decodeAudioData));
    }

    public bufferToWavFile = (abuffer: AudioBuffer, name: string): File => {
        const numOfChan = abuffer.numberOfChannels,
            length = abuffer.length * numOfChan * 2 + 44,
            buffer = new ArrayBuffer(length),
            view = new DataView(buffer),
            channels = [];
        let i: number, sample: number, pos = 0, offset = 0;

        const setUint16 = (data) => {
            view.setUint16(pos, data, true);
            pos += 2;
        }

        const setUint32 = (data) => {
            view.setUint32(pos, data, true);
            pos += 4;
        }

        // write WAVE header
        setUint32(0x46464952);                         // "RIFF"
        setUint32(length - 8);                         // file length - 8
        setUint32(0x45564157);                         // "WAVE"

        setUint32(0x20746d66);                         // "fmt " chunk
        setUint32(16);                                 // length = 16
        setUint16(1);                                  // PCM (uncompressed)
        setUint16(numOfChan);
        setUint32(abuffer.sampleRate);
        setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint16(numOfChan * 2);                      // block-align
        setUint16(16);                                 // 16-bit (hardcoded in this demo)

        setUint32(0x61746164);                         // "data" - chunk
        setUint32(length - pos - 4);                   // chunk length

        // write interleaved data
        for (i = 0; i < abuffer.numberOfChannels; i++)
            channels.push(abuffer.getChannelData(i));

        while (pos < length) {
            for (i = 0; i < numOfChan; i++) {             // interleave channels
                sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
                sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
                view.setInt16(pos, sample, true);          // update data chunk
                pos += 2;
            }
            offset++                                     // next source sample
        }

        // create Blob
        return new File([buffer], name, { type: "audio/wav" });
    }
}
