<button mat-dialog-close [ngStyle]="{'z-index': 1050, display: 'block'}">
    <span [inlineSVG]="'assets/images/icons/x.svg'"></span>
</button>
<h3 mat-dialog-title>
    <span>Insert drawing</span>
</h3>
<div mat-dialog-content>
    <div *ngIf="error" class="alert-danger">
        <!-- <button (click)="error = null" class="close"></button> -->
        <p class="alert-danger">{{ error }}</p>
    </div>
    <nt-drawing-board #drawingBoard mode="write" [data]="data" [color]="color"></nt-drawing-board>
    <input type="button" value="Save" (click)="submit()">
</div>