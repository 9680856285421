<button mat-dialog-close [ngStyle]="{'z-index': 1050, display: 'block'}">
    <span [inlineSVG]="'assets/images/icons/x.svg'"></span>
</button>
<h3 mat-dialog-title>
    <span>Add new track</span>
</h3>

<div mat-dialog-content>
    <div *ngIf="uploading; else uploadui">
        <mat-progress-bar color="primary" [value]="progress"></mat-progress-bar>
        <span>Uploading files. Please wait. ({{ progress }}%)</span>
    </div>
    <ng-template #uploadui>
        <div class="drop-zone" *ngIf="!uploading" ntFileDragAndDrop [mediaFilter]="mediaFilter" [allowMultiple]="allowMultiple" (onFilesDropped)="onDropFiles($event)" (onDropError)="onDropError($event)">
            <div class="dragdrop">Drag & Drop</div>
            <div class="separator"></div>
            <input #fileUpload type="file" class="file-input" [accept]="pms.ui.mediaFilter$ | async" (change)="onSelectFiles($event)" [multiple]="allowMultiple">
            <button (click)="fileUpload.click()">Select Files</button>
            <div class="separator"></div>
            <button id="dropbox" (click)="onDropboxChoose()"></button>
            <button id="google-drive" (click)="onGoogleDriveChoose()"></button>
        </div>
    </ng-template>
</div>
<!-- <script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="{{ config.get('dropbox').app_key }}"></script> -->