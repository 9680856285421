import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';

import { EMPTY, Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { AuthService, AuthUser, IAuthCredentials } from '@app/features/auth/auth.module';
import { EmailValidationService } from '@app/features/auth';
import { Project } from '@app/features/project/types';

@Component({
  selector: 'nt-login-register-flow-dialog',
  templateUrl: './login-register-flow-dialog.component.html',
  styleUrls: ['./login-register-flow-dialog.component.scss']
})
export class LoginRegisterFlowDialogComponent implements OnInit, AfterViewInit {

  public title: string = 'Enter your email to add your media';
  public subtitle: string = 'This will let the team know who you are';
  public message: string = '';
  public digits = {
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
    sixth: ''
  };
  
  /*
  steps:
  1. Email is requested and verified
     >> If email does not exist a verification code is sent to the email
     2. Enter verification code
     3. Sign in form (if entered verification code is correct)
     
     >> If email already exist
     4. Enter password
  5. Show additional info, if necessary
  */
  public step: number = 1;
  public action: string = 'Next';
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public password: string = '';
  public project: Project;
  public resource: string = 'media';

  @ViewChild('txtDigit1') txtDigit1: ElementRef<HTMLInputElement>;
  @ViewChild('txtDigit2') txtDigit2: ElementRef<HTMLInputElement>;
  @ViewChild('txtDigit3') txtDigit3: ElementRef<HTMLInputElement>;
  @ViewChild('txtDigit4') txtDigit4: ElementRef<HTMLInputElement>;
  @ViewChild('txtDigit5') txtDigit5: ElementRef<HTMLInputElement>;
  @ViewChild('txtDigit6') txtDigit6: ElementRef<HTMLInputElement>;
  @ViewChild('txtEmail') txtEmail: ElementRef<HTMLInputElement>;
  @ViewChild('txtFirstName') txtFirstName: ElementRef<HTMLInputElement>;
  @ViewChild('txtPassword') txtPassword: ElementRef<HTMLInputElement>;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private readonly dialogRef: MatDialogRef<LoginRegisterFlowDialogComponent>,
    protected emailservice: EmailValidationService,
    protected auth: AuthService,
    private toast: ToastrService,
    protected storage: LocalStorageService
  ) { 
    if (data && data.project instanceof Project) {
      this.project = data.project;
    }

    if (data && data.resource) {
      this.resource = data.resource;
    }
  }

  ngOnInit(): void {
    this.title = this.resource == 'media' ? 'Enter your email to add your media' : 'Enter your email to add your drawing';
  }

  ngAfterViewInit(): void {
    this.setFocus(this.txtEmail.nativeElement);
  }

  doAction(): void {
    if (this.step == 1) {
      this.message = 'Validating email... please wait.'
      this.emailservice.emailExist(this.email, this.project.publicKey).pipe(first()).subscribe(
        (result: any) => {
          this.moveToStep(4);
        }, 
        (error) => {
          this.emailservice.requestVerificationCode(this.email, this.project.publicKey).pipe(first()).subscribe(
            (result: any) => {
              this.moveToStep(2);
            },
            (error) => {
              this.message = 'Something went wrong. Please try again.';
            }
          );
        }
      );
    } else if (this.step == 2) {
      if (this.getCode().length == 6) {
        this.message = 'Validating code... please wait';
        this.emailservice.validateVerificationCode(this.email, this.getCode(), this.project.publicKey).pipe(first()).subscribe(
          (result: any) => {
            this.storage.store('public_share_email_validation_key', result.email_validation_key);
            this.moveToStep(3);
          },
          (error) => {
            this.message = 'Verification code incorrect. Please try again.';
          }
        );
      } else {
        this.message = '';
        this.moveFocusInDigits();
      }
    } else if (this.step == 3) {
      if (this.SignUpFormIsValid()) {
        let credentials: IAuthCredentials = { email: this.email, password: this.password, name_first: this.firstName, name_last: this.lastName };
        
        let email_validation_key = this.storage.retrieve('public_share_email_validation_key');
        if (email_validation_key) {
          credentials['email_validation_key'] = email_validation_key;
        }

        this.auth.postAuthenticationUrl = false;
        this.auth.register(credentials).pipe(
          catchError((response: HttpErrorResponse) => {
            this.toast.error(response.error.notice.message || response.message, 'Error');
            return EMPTY;
          })
        ).subscribe((user: AuthUser) => {
          this.auth.authenticate(this.email, this.password).pipe(
            catchError((response: HttpErrorResponse) => {
              this.toast.error(response.error.notice?.message || response.statusText, 'Error');
              return EMPTY;
            })
          ).subscribe((authenticated: boolean) => {
            if (authenticated) {
              this.toast.success('Account created successfully!', 'Success');
              this.dialogRef.close(user);
            }
          });
        });
      }
    } else if (this.step == 4) {
      if (this.password != '') {
        this.auth.postAuthenticationUrl = false;
        this.auth.authenticate(this.email, this.password, true).pipe(
          catchError((response: HttpErrorResponse, caught: Observable<boolean>) => {
            // this.toast.error(response.error.notice?.message || response.statusText, 'Error');
            this.message = response.error.notice?.message || response.statusText;
            return EMPTY;
          })
        ).subscribe((authenticated: boolean) => {
          if (authenticated) {
            this.dialogRef.close(this.auth.currentUser);
          } else {
            console.warn('Not authenticated');
          }
        });
      }
    }
  }

  moveToStep(nextStep: number): void {
    this.step = nextStep;

    if (nextStep == 2) {
      this.title = 'Verify your email';
      this.subtitle = 'We just sent a 6-digit code to <b>' + this.email + '</b>. Please enter the code below.';
      this.message = '';
      this.action = 'Verify';
      this.setFocus(this.txtDigit1.nativeElement);
    } else if (nextStep == 3) {
      this.title = this.resource == 'media' ? 'Sign up to add your media' : 'Sign up to add your drawing';
      this.subtitle = this.resource == 'media' ? 'Your media will be added immediately' : 'Your drawing will be added inmediately';
      this.message = '';
      this.action = 'Sign up';
      this.setFocus(this.txtFirstName.nativeElement);
    } else if(nextStep == 4) {
      this.title = this.resource == 'media' ? 'Sign up to add your media' : 'Sign up to add your drawing';
      this.subtitle = this.resource == 'media' ? 'Your media will be added immediately' : 'Your drawing will be added inmediately';
      this.message = '';
      this.action = 'Sign in';
      this.setFocus(this.txtPassword.nativeElement);
    }
  }

  moveFocusInDigits(): void {
    if (this.txtDigit1.nativeElement == document.activeElement && this.digits.first != '') {
      this.txtDigit2.nativeElement.focus();
    } else if (this.txtDigit2.nativeElement == document.activeElement && this.digits.second != '') {
      this.txtDigit3.nativeElement.focus();
    } else if (this.txtDigit3.nativeElement == document.activeElement && this.digits.third != '') {
      this.txtDigit4.nativeElement.focus();
    } else if (this.txtDigit4.nativeElement == document.activeElement && this.digits.fourth != '') {
      this.txtDigit5.nativeElement.focus();
    } else if (this.txtDigit5.nativeElement == document.activeElement && this.digits.fifth != '') {
      this.txtDigit6.nativeElement.focus();
    }
  }

  setFocus(input: HTMLInputElement): void {
    setTimeout(() => {
      input.focus();
    }, 250);
  }

  SignUpFormIsValid(): boolean {
    return this.firstName != '' && this.lastName != '' && this.password != '';
  }

  getCode(): string {
    return this.digits.first +
      this.digits.second +
      this.digits.third +
      this.digits.fourth +
      this.digits.fifth +
      this.digits.sixth
  };

  pasteCode(event: ClipboardEvent): void {
    let pastedText = event.clipboardData.getData('text');
    pastedText = pastedText.replace('-', '');

    if (pastedText.length <= 6) {
        let digits = pastedText.split('');

        this.digits.first  = digits.length >= 1 ? digits[0] : this.digits.first;
        this.digits.second = digits.length >= 2 ? digits[1] : this.digits.second;
        this.digits.third  = digits.length >= 3 ? digits[2] : this.digits.third;
        this.digits.fourth = digits.length >= 4 ? digits[3] : this.digits.fourth;
        this.digits.fifth  = digits.length >= 5 ? digits[4] : this.digits.fifth;
        this.digits.sixth  = digits.length >= 6 ? digits[5] : this.digits.sixth;
    }
  }

}
