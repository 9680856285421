export class NtGainNode extends GainNode {

    private _connectionArgs: Array<AudioNode | AudioParam | number>;
    private _isConnected: boolean = false;
    private _isMuted: boolean = false;
    private _isSoloed: boolean = false;
    public get isSoloed(): boolean { return this._isSoloed; }

    constructor(node: GainNode, private masterMute: GainNode) {
        super(node.context);
        Object.assign(this, node);
    }

    /**
     * @see GainNode.connect()
     */
    override connect(destinationNode: AudioNode, output?: number, input?: number): AudioNode;
    override connect(destinationParam: AudioParam, output?: number): void;
    connect(destinationNode: AudioNode | AudioParam, output?: number, input?: number): AudioNode | void {
        this._connectionArgs = [destinationNode, output, input];
        this._isConnected = true;
        if(destinationNode instanceof AudioNode)
            return super.connect(destinationNode as AudioNode, output, input);
        else
            return super.connect(destinationNode as AudioParam, output);
    }

    /**
     * @see GainNode.disconnect()
     */
    override disconnect() {
        this._isConnected = false;
        super.disconnect();
    }

    /**
     * Mute/unmute the GainNode by removing/reinserting it from the signal path
     * 
     * @param value Whether to mute (true) or unmute (false) the GainNode
     * @returns Whether the GainNode is muted or not
     */
    mute(value?: boolean): boolean {
        if(value !== undefined && value !== this._isMuted){
            if(value) {
                this.disconnect();
                super.connect(this.masterMute)
            } else {
                this.reconnect();
            }

            this._isMuted = value;
        }

        return this._isMuted;
    }

    /**
     * Toggles the mute/unmute state of the GainNode
     * 
     * @returns Whether the GainNode is muted or not
     */
    toggleMute(): boolean {
        return this.mute(!this._isMuted);
    }

    solo(): boolean;
    /**
     * Set the solo flag of the GainNode.
     * Does not actually affect the signal path, only the flag.
     * 
     * @param value The value to set the solo flag to
     */
    solo(value: boolean): boolean;
    /**
     * Set the solo flag of the GainNode and remove/reinsert the Gain in the signal path.
     * 
     * If `hasSoloed` is true then we are in solo mode and want to disconnect/reconnect nodes based on solo/mute and connection.
     * if `hasSoloed` is false then we are NOT in solo mode and want to reconnect nodes based on mute and connection
     * 
     * @param value The value to set the solo flag to
     * @param hasSoloed Whether there's soloed nodes or not
     */
    solo(value: boolean, hasSoloed: boolean): boolean;
    solo(value?: boolean, hasSoloed?: boolean): boolean {
        if(value !== undefined) {
            this._isSoloed = value;
            if(hasSoloed !== undefined) { // We do nothing when we don't know whether some tracks are soloed or not
                const shouldBeConnected = (hasSoloed && this._isSoloed) || (!hasSoloed && !this._isMuted);
                const shouldBeDisconnected = (hasSoloed && !this._isSoloed) || (!hasSoloed && this._isMuted);
                if(shouldBeConnected && !this._isConnected) {
                    this.reconnect();
                } else if (shouldBeDisconnected && this._isConnected) {
                    this.disconnect();
                    super.connect(this.masterMute)
                }
            }
        }

        return this._isSoloed;
    }

    /**
     * Toggles the solo flag of the GainNode
     * 
     * @returns Whether the GainNode solo flag is on or not
     */
    toggleSolo(): boolean {
        return this.solo(!this._isSoloed);
    }

    /**
     * Reconnect the GainNode using the last connection settings
     */
    private reconnect() {
        this.connect(this._connectionArgs[0] as AudioNode, this._connectionArgs[1] as number, this._connectionArgs[2] as number);
    }
}
