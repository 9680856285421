<!-- start: LOGIN BOX -->
<div class="box-login">
    <div class="error-wrapper">
        <ngb-alert *ngIf="error" (close)="clearError()" [dismissible]="true" [type]="'danger'">
            {{ error }}
        </ngb-alert>
    </div>
    <form fxLayout="column" class="form-login" (ngSubmit)="submit()" #theForm="ngForm">
        <ng-container *ngIf="config.get('google').auth_enabled">
            <div class="form-group">
                
                <span fxFlex="75" fxLayout="column" fxLayoutAlign="center center" class="input sign-in-google">
                    <button class="google" type="button" [disabled]="isProcessing" (click)="loginWithGoogle()">
                        <div class="glogo"></div>
                        <span>Sign in with Google</span>
                    </button>
                </span>
                
            </div>

            <div class="form-group dvLabel empty-row">&nbsp;</div>
            <div class="form-group dvLabel">
                <span fxFlex="75">OR</span>
            </div>
        </ng-container>
        <div class="form-group dvLabel empty-row">&nbsp;</div>

        <div class="form-group dvLabel">
            <label fxFlex="75" i18n>Email</label>
        </div>
        <div class="form-group">
            <input fxFlex="75" type="email" class="form-control" [disabled]="isProcessing"
                    name="email" #email="ngModel" [(ngModel)]="credentials.email"
                    [ngClass]="{error: this.submitted && email.invalid, noerror: this.submitted && email.valid }"
                    i18n-placeholder placeholder="email@address.com" email required>
        </div>
        <div class="form-group">
            <span fxFlex="75" class="error-message">
                <span *ngIf="this.submitted && email.errors">
                    <span [hidden]="!email.errors.required" i18n>
                        Email is required.
                    </span>
                    <span [hidden]="!email.errors.email" i18n>
                        Email must be valid.
                    </span>
                </span>
            </span>
        </div>

        <div class="form-group dvLabel">
            <label fxFlex="75" i18n>Password</label>
        </div>
        <div class="form-group">
            <input fxFlex="75" type="password" class="form-control password" [disabled]="isProcessing"
                    name="password" #password="ngModel" [(ngModel)]="credentials.password"
                    [ngClass]="{error: this.submitted && password.invalid}"
                    i18n-placeholder placeholder="Your password" required>
        </div>
        <div class="form-group">
            <span fxFlex="75" class="error-message">
                <span *ngIf="this.submitted && password.errors">
                    <span [hidden]="!password.errors.required" i18n>
                        Password is required.
                    </span>
                </span>
            </span>
        </div>

        <div class="form-group dvLink">
            <a fxFlex="75" [routerLink]="['/auth/forgot-password']" i18n>Forgot password?</a>
        </div>

        <div class="form-group form-actions">
                <span fxFlex="75" class="input" fxLayout="column" fxLayoutAlign="center center">
                    <button class="big" type="submit" [disabled]="isProcessing"
                            (click)="validate() && theForm.ngSubmit.emit()" i18n>
                        Log In
                    </button>
                </span>
        </div>
    </form>
</div>
<!-- end: LOGIN BOX -->
