import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormComponent } from '@app/shared/shared.module';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable } from 'rxjs';
import { StoreService } from '../../services';

import { IProjectData, Project } from '../../types';

@Component({
  selector: 'nt-project-form-dialog',
  templateUrl: './project-form-dialog.component.html',
  styleUrls: ['./project-form-dialog.component.scss']
})
export class ProjectFormDialogComponent extends FormComponent implements OnInit {

  project: Project;
  get mode(): 'new' | 'copy' | 'edit' {
    return this.project.id ? 'edit' : (this.project.duplicatedFrom ? 'copy' : 'new');
  }
  get isEditing(): boolean { return !!this.project.id; }

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) private data, private readonly dialogRef: MatDialogRef<ProjectFormDialogComponent>, private store: StoreService, private readonly toast: ToastrService) {
    super();
  }

  ngOnInit(): void { 
    if(this.data && this.data.project instanceof Project) {
        this.project = this.data.project;
    } else if(this.data && this.data.project) {
        this.project = new Project(Object.assign({ media_type: 'audio', name: '' } as IProjectData, this.data.project));
    } else {
        this.project = new Project({ media_type: 'audio', name: '' } as IProjectData);
    }
  }

  submit(): void {
      if(this.validate()) {
        this.store.project.save(this.project).pipe(
            catchError((error: any, caught: Observable<Project>) => {
                const msg = error?.error?.notice?.message;
                if (msg) {
                    this.toast.error(msg);
                }
                this.dialogRef.close();
                throw error;
            })
        ).subscribe((p: Project) => {
            this.dialogRef.close(p)
        });
      }
  }

}
