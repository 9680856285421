import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

import { InlineSVGModule } from 'ng-inline-svg-w';
import { TimeagoModule } from 'ngx-timeago';
import { HotkeyModule } from 'angular2-hotkeys';
import { LinkyModule } from 'ngx-linky';

import { SharedModule } from '@app/shared';
import { ProjectComponent } from './project.component';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectListComponent, ProjectViewComponent } from './pages';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { NgxStripeModule } from 'ngx-stripe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import {
    SequencerComponent, RulerComponent, ControlsComponent, ProjectShareDialogComponent, ProjectFormDialogComponent,
    CommentComponent, CommentThreadDialogComponent, EditCommentComponent, CommentJournalComponent, CommentThreadComponent,
    TrackRecorderComponent, TrackAddDialogComponent, AudionoteComponent, MarkerComponent,
    DrawingToolbarComponent, DrawingComponent, DrawingDialogComponent, DrawingBoardComponent, CollaboratorBarComponent,
} from './components';
import { EmojiPipe, TimecodePipe } from './pipes';

import { FileDragAndDropDirective } from './directives';
import { SubscriptionComponent } from '../account/pages/subscription/subscription.component';
import { PlanComponent } from '../account/components/plan/plan.component';
import { PartnerComponent } from '../account/pages/partner/partner.component';
import { ProfileComponent } from '../account/pages/profile/profile.component';
import { PaymentComponent } from '../account/pages/payment/payment.component';
import { BillingComponent } from '../account/pages/billing/billing.component';
import { EditBillingDialogComponent } from '../account/components/edit-billing-dialog/edit-billing-dialog.component';
import { ScrollComponent } from './components/scroll/scroll.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NotepadComponent } from './components/notepad/notepad.component';
import { TrackComponent } from './components/track/track.component';
import { ClipComponent } from './components/track/clip/clip.component';


export * from './types';
export * from './services';
export * from './resolvers';
export * from './components';

@NgModule({
  declarations: [
    ProjectComponent,
    ProjectListComponent,
    ProjectViewComponent,
    ProjectFormDialogComponent,
    SequencerComponent,
    RulerComponent,
    TimecodePipe,
    CommentComponent,
    ControlsComponent,
    EmojiPipe,
    ProjectShareDialogComponent,
    EditCommentComponent,
    CommentThreadDialogComponent,
    MarkerComponent,
    AudionoteComponent,
    TrackRecorderComponent,
    TrackAddDialogComponent,
    FileDragAndDropDirective,
    DrawingToolbarComponent,
    DrawingComponent,
    DrawingDialogComponent,
    DrawingBoardComponent,
    NavigationBarComponent,
    SubscriptionComponent,
    PlanComponent,
    PartnerComponent,
    PaymentComponent,
    BillingComponent,
    EditBillingDialogComponent,
    ProfileComponent,
    CommentJournalComponent,
    CommentThreadComponent,
    ScrollComponent,
    CollaboratorBarComponent,
    NotepadComponent,
    TrackComponent,
    ClipComponent,
  ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, MatSidenavModule, MatDialogModule, MatSlideToggleModule, MatMenuModule, MatSelectModule, MatSliderModule,
    MatProgressBarModule, MatProgressSpinnerModule, CKEditorModule,
    FlexLayoutModule, DragDropModule,
    LinkyModule,
    HotkeyModule,
    InlineSVGModule,
    TimeagoModule.forChild(),
    SharedModule,
    ProjectRoutingModule,
    MatChipsModule, MatIconModule, ClipboardModule, MatRadioModule, MatCheckboxModule,
    NgxStripeModule.forRoot('pk_test_s872U2C33qEVo0PTjSV8tmMU'),
    MatFormFieldModule, MatInputModule
  ]
})
export class ProjectModule { }
