import { ApiObject } from '@app/core/api';
import { ICardData } from './icard-data';

export class Card extends ApiObject<ICardData> {
    //#region IPlan Data Accessors
    //#region Read-only
    public readonly brand: string;
    public readonly country: string;
    public readonly cvcCheck: string;
    public readonly expMonth: number;
    public readonly expYear: number;
    public readonly last4: string;
    //#endregion
    //#endregion

    public clone<Card extends ApiObject<ICardData>>(): Card {
        return this.shallowClone(this.constructor as { new (data: ICardData): Card } );
    }
}