import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToolService {

    public idSelectedDrawing: number = 0;
    public newDrawingId: number = 0;

    constructor() { }
}
