import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { defaultIfEmpty, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ProjectManagerService, StoreService, ToolService } from '../../../project.module';
import { Comment, ICommentData, Media, Project } from '../../../types';
import { AuthService } from '@app/features/auth';
import { AudioService } from '@app/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'nt-comment-journal',
    templateUrl: './comment-journal.component.html',
    styleUrls: ['./comment-journal.component.scss', './comment-journal-responsive.component.scss']
})
export class CommentJournalComponent implements OnInit, AfterViewInit {

    @Output('close') close: EventEmitter<void> = new EventEmitter();

    @Input('project') project: Project;
    @Input('medias$') medias$: Observable<Array<Media>>;
    @ViewChild('newCommentContainer', { read: ViewContainerRef }) commentsContainerRef: ViewContainerRef;
    @ViewChild('scrollWrapper') scrollWrapper: ElementRef<HTMLDivElement>;
    public comments$: Observable<Array<Comment>>;
    public replies$: Record<number, Observable<Array<Comment>>> = {};

    public currentMedia$: Observable<Media>;

    private _sort$$: BehaviorSubject<{ by: string, desc?: boolean}> = new BehaviorSubject({ by: 'timecode' });
    public sort$: Observable<{ by: string, desc?: boolean}> = this._sort$$.asObservable();

    private _filter$$: BehaviorSubject<{ userId?: number, isResolved?: boolean }> = new BehaviorSubject({});
    public filter$: Observable<{ userId?: number, isResolved?: boolean }> = this._filter$$.asObservable();

    private _search$$: BehaviorSubject<string> = new BehaviorSubject('');
    public search$: Observable<string> = this._search$$.asObservable();

    public newComment: Comment;

    private _destroy: Subject<void> = new Subject();

    constructor(
        protected readonly store: StoreService,
        private readonly pms: ProjectManagerService,
        private readonly audio: AudioService,
        protected readonly auth: AuthService,
        private readonly dds: DeviceDetectorService
    ) { }

    ngOnInit(): void {
        this.currentMedia$ = combineLatest([this.pms.ui.selectedTrackUuid$, this.medias$]).pipe(
            map((values) => {
                const [uuid, medias] = values;

                return medias.filter(m => m.uuid === uuid)?.[0];
            })
        ).pipe(filter(v => v !== undefined));

        this.comments$ = combineLatest([this.currentMedia$, this.sort$]).pipe(
            switchMap((values) => {
                const [media, sort] = values;
                return this.store.comment.list({ projectId: this.project.id, mediaId: media.id, parentId: null }, sort.by, sort.desc).pipe(
                    switchMap((aoc: Array<Observable<Comment>>) => combineLatest(aoc).pipe(
                        defaultIfEmpty([] as Array<Comment>)
                    )),
                );
            })
        );

        this.medias$.pipe(
            takeUntil(this._destroy),
            map((medias: Array<Media>) => medias.filter((m: Media) => m.status === 'processed')),
        ).subscribe((medias: Array<Media>) => {
            if(!this.pms.ui.selectedTrackUuid || medias.filter((m: Media) => m.uuid === this.pms.ui.selectedTrackUuid).length === 0) {
                if(medias.length > 0) this.pms.ui.selectedTrackUuid = medias[0].uuid;
            }
        });

    }

    ngAfterViewInit(): void {
        this.pms.eventBus.on('CreateComment').pipe(
            takeUntil(this._destroy),
            withLatestFrom((this.currentMedia$)),
            filter(([mediaId, currentMedia]) =>  this.pms.ui.journalMode && currentMedia.id === mediaId)
        ).subscribe(([mediaId, currentMedia]) => {
            const timeoutTime = !this.dds.isDesktop() ? 100 : 0;
            //#region HACK[iOS]: Fake Input to focus outside setTimeout and have iOS open the keyboard, so we can later focus the right input
            let fakeInput;
            if(!this.dds.isDesktop()) {
                fakeInput = document.createElement('input');
                fakeInput.setAttribute('type', 'text');
                fakeInput.style.position = 'absolute';
                fakeInput.style.opacity = '0';
                fakeInput.style.height = '0';
                fakeInput.style.fontSize = '16px';
                document.body.prepend(fakeInput);
                fakeInput.focus();
            }
            //#endregion

            // We need to make sure the scroll is at the top so the iOS keyboard generated container scrolls correctly
            setTimeout(() => {
                if(!this.dds.isDesktop()) this.scrollWrapper.nativeElement.scrollTo(0,0);
                this.newComment = new Comment({
                    project_id: currentMedia.projectId,
                    media_id: currentMedia.id,
                    comment: '',
                    timecode: this.audio.player.currentTime
                } as ICommentData);
                //#region HACK[iOS]: Continued
                if(!this.dds.isDesktop() && fakeInput) {
                    setTimeout(() => fakeInput.remove());
                }
                //#endregion
            }, timeoutTime);

            // this.loadEditCommentComponent(newComment, this.audio.player.currentTime / this.pms.ui.pixelDuration, 0);
        });

        this.pms.eventBus.on('CreateMarker').pipe(
            takeUntil(this._destroy),
            withLatestFrom((this.currentMedia$)),
            filter(([mediaId, currentMedia]) =>  this.pms.ui.journalMode && currentMedia.id === mediaId)
        ).subscribe(([mediaId, currentMedia]) => {
            const newComment: Comment = new Comment({
                project_id: currentMedia.projectId,
                media_id: currentMedia.id,
                comment: '',
                timecode: this.audio.player.currentTime
            } as ICommentData);
            this.store.comment.save(newComment).subscribe();
            // this.generateCommentLevels(this.pms.ui.pixelDuration);
        });
    }

    trackByCommentId(index: number, comment: Comment) {
        return comment.id;
    }

    selectMedia(media: Media) {
        this.pms.ui.selectedTrackUuid = media.uuid;
    }

    setSort(sort: { by: string, desc?: boolean }) {
        this._sort$$.next(sort);
    }

    toggleFilterOnlyOwn() {
        const filter = this._filter$$.value;
        if (filter.userId) {
            delete filter.userId;
        } else {
            filter.userId = this.auth.currentUser.id;
        }
        this._filter$$.next(filter);
    }

    toggleFilterShowResolved() {
        const filter = this._filter$$.value;
        if (filter.isResolved === true) {
            delete filter.isResolved;
        } else {
            filter.isResolved = true;
        }
        this._filter$$.next(filter);
    }

    toggleFilterShowUnresolved() {
        const filter = this._filter$$.value;
        if (filter.isResolved === false) {
            delete filter.isResolved;
        } else {
            filter.isResolved = false;
        }
        this._filter$$.next(filter);
    }

    setSearch(search: string) {
        this._search$$.next(search);
    }

    onNewComment(comment: Comment) {
        delete this.newComment;
        this.comments$.pipe(
            filter((comments: Array<Comment>) => {
                return comments.map((c: Comment) => c.uuid === comment.uuid ).reduce((s,c) => s || c, false)
            }),
            take(1)
        ).subscribe((comments: Array<Comment>) => {
            setTimeout(() => {
                const parentElement = document.querySelector('nt-project-view.journal');
                const commentElement = document.getElementById('thread-' + comment.uuid);

                const parentRect = parentElement.getBoundingClientRect();
                const elementRect = commentElement.getBoundingClientRect();

                if(elementRect.top < parentRect.top || elementRect.bottom > parentRect.bottom) {
                    setTimeout(() => commentElement.querySelector('[timecode]').scrollIntoView({ behavior: 'smooth' }), 150);
                }
            },50);
        });
    }
}
