import { Injectable, OnDestroy } from '@angular/core';
import { SocketEventMessage, SocketService, Store } from '@app/core';
import { Observable } from 'rxjs';
import { AudionoteDataService } from '../services';
import { Audionote, IAudionoteData } from '../types';

@Injectable({
    providedIn: 'root'
})
export class AudionoteStore extends Store<Audionote, IAudionoteData, AudionoteDataService> implements OnDestroy {

    protected static override LIST_KEY_PARTS = ['projectId'];

    constructor(protected readonly service: AudionoteDataService, protected readonly socket: SocketService) {
        super(socket);
    }
  
    protected observe(): Observable<SocketEventMessage> {
        return this.socket.observe(Audionote);
    }

    ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    upload(audionote: Audionote, file: File, params: Record<string, any>) {
        return this.service.save({ ...audionote.toPostableData(), file: file }, params);
    }

    protected override mergeItem(current: Audionote, item: Audionote): Audionote {
        if(current && item.status !== 'processed') {
            return Object.assign(item, current, { status: 'processed' }); // If not processed, we want to keep the local values
        }

        return super.mergeItem(current, item);
    }
}
