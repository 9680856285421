import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject,  OnInit } from '@angular/core';
import { HttpProgressEvent } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AudioService, FileService } from '@app/core';
import { ConfigService } from '@app/config.service';
import { IProjectData, Media, Project } from '../../../types';
import { ProjectManagerService, StoreService } from '../../../project.module';
import { AuthService, AuthUser } from '@app/features/auth/auth.module';
import { LoginRegisterFlowDialogComponent, MediaFilter } from '@app/features/auth';

declare const Dropbox: any;

@Component({
  selector: 'nt-add-track-dialog',
  templateUrl: './track-add-dialog.component.html',
  styleUrls: ['./track-add-dialog.component.scss']
})
export class TrackAddDialogComponent implements OnInit, AfterViewInit {

  public project: Project;
  public mediaFilter: MediaFilter;
  public allowMultiple: boolean = false;
  private files: File[];

  //#region Download Progress
  private _progress: number = 0;
  public get progress(): number { return this._progress; }
  private filesToFetchCount: number = 0;
  private filesToFetchProgresses: Array<number>;
  //#endregion

  public uploading: boolean = false;

  private _googleDriveReady$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _googleAccessToken$$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  private _destroy: Subject<void> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private readonly audio: AudioService,
    private readonly store: StoreService,
    private readonly config: ConfigService,
    private readonly fileService: FileService,
    private readonly dialogRef: MatDialogRef<TrackAddDialogComponent>,
    private readonly elementRef: ElementRef,
    private readonly changeDetector: ChangeDetectorRef,
    protected readonly auth: AuthService,
    private readonly dialog: MatDialog,
    public readonly pms: ProjectManagerService
  ) { 
    if (data && data.project instanceof Project) {
        this.project = data.project;
    } else if (data && data.project) {
        this.project = new Project(Object.assign({}, data.project));
    } else {
        throw { message: "Invalid project data" };
    }

    if(data && data.mediaFilter) {
        this.mediaFilter = data.mediaFilter;
    } else {
        throw { message: "Invalid mediaFilter data" };
    }

    if(data && data.allowMultiple) {
        this.allowMultiple = data.allowMultiple;
    }

    this.files = data.files;

  }

  //#region Lifecycle
  ngOnInit(): void {
    gapi.load('auth', () => {
        console.info('GAPI Loaded!');
        gapi.load('picker', () => {
            console.info('GAPI Picker Loaded!');
            gapi.load('client', () => {
                console.info('GAPI Client Loaded!');
                gapi.client.load('drive', 'v3', () => {
                    console.info('GAPI Drive v3 Loaded!');
                    this._googleDriveReady$$.next(true);
                });
            });
        });
    });
  }

  ngAfterViewInit(): void {
      const s = document.createElement('script');
      s.type = "text/javascript";
      s.src = "https://www.dropbox.com/static/api/2/dropins.js";
      s.id = "dropboxjs";
      s.setAttribute("data-app-key", this.config.get('dropbox').app_key);

      s.onload = () => {
          console.info('Dropbox dropins loaded!');
      };

      this.elementRef.nativeElement.appendChild(s);

      if(this.files) {
        this.onDropFiles(this.files);
    }
  }
  //#endregion

  //#region Actions
  onDropError(error: Error) {
    console.error(error);
  }

  onSelectFiles(event: Event): void {
    let files: FileList = (event.target as HTMLInputElement).files;
    this.onProcessOrSelectFiles(Array.from(files), 'select');
  }

  onDropFiles(files: File[]) {
    this.onProcessOrSelectFiles(files, 'dragdrop');
  }

  onDropboxChoose(): void {
    this.onProcessOrSelectFiles(null, 'dropbox');
  }

  onGoogleDriveChoose(): void {
    this.onProcessOrSelectFiles(null, 'gdrive');
  }

  onProcessOrSelectFiles(files: File[], origin: string): void {
    if (this.auth.currentUser) {
      switch (origin) {
        case 'select'  : this.uploadLocalFile(files); break;
        case 'dragdrop': this.uploadLocalFile(files); break;
        case 'dropbox' : this.openDropBoxFileSelector(); break;
        case 'gdrive'  : this.openGoogleDriveFileSelector(); break;
      }
    } else {
      this.dialog.open(LoginRegisterFlowDialogComponent, {
        autoFocus: false,
        panelClass: 'login-register-flow-dialog',
        width: '616px',
        data: { project: this.project, resource: 'media' }
      }).afterClosed().pipe().subscribe((user: AuthUser) => {
        if (user) {
          switch (origin) {
            case 'select'  : this.uploadLocalFile(files); break;
            case 'dragdrop': this.uploadLocalFile(files); break;
            case 'dropbox' : this.openDropBoxFileSelector(); break;
            case 'gdrive'  : this.openGoogleDriveFileSelector(); break;
          }
        }
      });
    }
  }

  openDropBoxFileSelector(): void {
    Dropbox.choose({
      success: (files) => {
        this.uploading = true;
        this.filesToFetchCount = files.length;
        this.filesToFetchProgresses = new Array(this.filesToFetchCount).fill(0);

        const downloadFiles$: Array<Observable<File>> = [];
        
        files.forEach((file, index) => {
          const downloadFile$ = this.fileService.downloadFile(file.link, { reportProgress: true }).pipe(shareReplay(1)).pipe(
            tap((event: Blob | HttpProgressEvent) => {
                if(!(event instanceof Blob)) {
                    this.onDownloadProgressEvent(event, index)
                }
            }),
            filter((response: Blob | HttpProgressEvent) => response instanceof Blob),
            map((blob: Blob) => new File([blob], file.name, { type: blob.type }))
          );
          downloadFiles$.push(downloadFile$);
        });

        combineLatest(downloadFiles$).subscribe((files: Array<File>) => this.onDownloadResponse(files));
      },
      linkType: 'direct',
      multiselect: this.allowMultiple,
      extensions: this.mediaFilter.split(',')
    });
  }

  openGoogleDriveFileSelector(): void {
    this._googleDriveReady$$.asObservable().pipe(takeUntil(this._destroy)).subscribe((ready: boolean) => {
        if(!ready) { return console.error('This should not happen.'); }
        this._googleAccessToken$$.asObservable().subscribe((accessToken) => {
            if(accessToken === undefined) {
                gapi.auth.authorize({
                    'client_id': this.config.get('google').client_id,
                    'scope': 'https://www.googleapis.com/auth/drive.readonly',
                    'immediate': false
                }, (authResult) => {
                    if (authResult && !authResult.error) {
                        this._googleAccessToken$$.next(authResult.access_token);
                    }
                });
                return;
            }

            this.createGooglePicker(accessToken, (docs) => {
                this.uploading = true;
                this.filesToFetchCount = docs && docs.docs ? docs.docs.length : 0;
                this.filesToFetchProgresses = new Array(this.filesToFetchCount).fill(0);
                this.changeDetector.detectChanges();

                if (docs.action && docs.action == 'cancel') {
                  this.uploading = false;
                  return;
                }
        
                if (docs[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
                    const downloadFiles$: Array<Observable<File>> = [];
                    docs.docs.forEach((singleDoc: google.picker.DocumentObject, index: number) => {
                        let doc = singleDoc;
                        // let request = gapi.client.drive.files.get({
                        // 'fileId': doc.id
                        // });
        
                        // request.execute((file: gapi.client.Response<gapi.client.drive.File>) => (file: gapi.client.Response<gapi.client.drive.File>) => {
                            const fileUrl = new URL('https://www.googleapis.com/drive/v3/files/' + doc.id + '?alt=media');
                            const headers = { Authorization: 'Bearer ' + accessToken };
                            const downloadFile$ = this.fileService.downloadFile(fileUrl, { reportProgress: true, headers }).pipe(shareReplay(1)).pipe(
                                tap((event: Blob | HttpProgressEvent) => {
                                    if(!(event instanceof Blob)) {
                                        this.onDownloadProgressEvent(event, index)
                                    }
                                }),
                                filter((response: Blob | HttpProgressEvent) => response instanceof Blob),
                                map((blob: Blob) => new File([blob], doc.name, { type: doc.mimeType }))
                            );
                            downloadFiles$.push(downloadFile$);
                        // });
                    });
                    combineLatest(downloadFiles$).subscribe((files: Array<File>) => this.onDownloadResponse(files));
                }
            });
        });
    });
  }
  //#endregion

  //#region Helpers
  private updateFetchProgress(progress: number, index: number): void {
    this.filesToFetchProgresses[index] = progress;
    this._progress = Math.round(this.filesToFetchProgresses.reduce((partialSum, a) => partialSum + a, 0) / (this.filesToFetchCount * 100) * 100);
  }

  private uploadLocalFile(files: File[]): void {
    if (!files.length) {
      return console.warn('No files selected');
    }

    this.uploading = true;
    this.filesToFetchCount = files.length;
    this.filesToFetchProgresses = new Array(this.filesToFetchCount).fill(0) ;
    this.changeDetector.detectChanges();

    this.onDownloadResponse(files);
  }

  private createGooglePicker(accessToken: string, callback) {
    const view = new google.picker.DocsView(google.picker.ViewId.DOCS);
    if (this.mediaFilter == MediaFilter.Audio) {
      view.setMimeTypes("audio/mp3,audio/x-mp3,audio/mpeg,audio/x-mpeg,audio/mpeg3,audio/x-mpeg3,audio/wav,audio/x-wav");
    } else {
      view.setMimeTypes("video/mpeg,video/mpeg4,video/mp4,audio/mp3,audio/x-mp3,audio/mpeg,audio/x-mpeg,audio/mpeg3,audio/x-mpeg3,audio/wav,audio/x-wav");
    }
    
    const picker = new google.picker.PickerBuilder()
        .addView(view)
        .setOAuthToken(accessToken)
        .enableFeature(this.allowMultiple ? google.picker.Feature.MULTISELECT_ENABLED : undefined)
        .setCallback(callback)
        .build();
        
        picker.setVisible(true);
  }
  //#endregion

  //#region Event Handlers
  onDownloadProgressEvent = (event: HttpProgressEvent, index: number) => {
    this.updateFetchProgress(event.loaded / event.total * 95, index);  // Fetch represent 95% of progress, audio data parsing is the 5% remaining
    this.changeDetector.detectChanges();
  }
  onDownloadResponse = (files: Array<File>) => {
    const filesAndbuffers = [];
    files.forEach((file: File, index: number) => {
        this.updateFetchProgress(95, index);
        const buffer = this.audio.blobToBuffer(file).pipe(
            map((buffer: AudioBuffer) => ({ file, buffer})),
            tap(() => {
                this.updateFetchProgress(100, index);
                this.changeDetector.detectChanges();
            })
        );
        filesAndbuffers.push(buffer);
    });

    combineLatest(filesAndbuffers).subscribe((values: Array<{ file: File, buffer: AudioBuffer }>) => {
        this.dialogRef.close(values);
    });
  }
  //#endregion

}
