import { IApiResponse } from '@app/core/core.module';

import { IAuthUser } from '../auth.module';

export interface IAuthResponse {
    authToken?: string;
    expiry?: string;
    ttl?: number;
    user?: IAuthUser;
    rawResponse: IApiResponse<IAuthResponse>;
}

// class Card {
//  brand: string,
//  last4: string,
//  exp_month: number,
//  exp_year: number,
//  country: string,
//  cvc_check: string
// }
