export const plansWording = {
    "2": {
      "title":"Starter",
      "description": "For professionals working on a few projects.",
      "period": "monthly"
    },
    "3": {
      "title":"Plus",
      "description": "For professionals with growing project needs.",
      "period": "monthly"
    },
    "4": {
      "title":"Premium",
      "description": "For professionals working on many projects with multiple clients.",
      "period": "monthly"
    },
    "25": {
      "title":"Starter",
      "description": "For professionals working on a few projects.",
      "period": "annual"
    },
    "26": {
      "title":"Plus",
      "description": "For professionals with growing project needs.",
      "period": "annual"
    },
    "27": {
      "title":"Premium",
      "description": "For professionals working on many projects with multiple clients.",
      "period": "annual"
    },
  };