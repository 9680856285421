<div project>
    <div logo></div>
    <div name [contentEditable]="project.userCanWrite"  (keypress)="onNameKeypress($event)" (blur)="setName($event)">{{ project.name }}</div>
    <!-- <div settings [inlineSVG]="'/assets/images/icons/cog.svg'"></div> -->

</div>
<div transport>
    <div timecode><span>{{ (currentTime$ | async) | timecode }}</span></div>
    <div playhead [style.width.vw]="(currentTime$ | async) / audio.player.duration * 100"></div>
    <button start (click)="skipTo(0)" class="dark" [disabled]="!isReady" [inlineSVG]="'/assets/images/icons/skipbackward.svg'"></button>
    <button rewind (click)="skipBy(-1)" class="dark" [disabled]="!isReady"[inlineSVG]="'/assets/images/icons/rewind.svg'"></button>
    <button fastforward (click)="skipBy(1)" class="dark" [disabled]="!isReady" [inlineSVG]="'/assets/images/icons/fastforward.svg'"></button>
    <button end (click)="skipTo(audio.player.duration)" class="dark" [disabled]="!isReady" [inlineSVG]="'/assets/images/icons/skipforward.svg'"></button>
    <button stop [ngClass]="{ responsiveHide: !audio.player.isPlaying }" (click)="stop()" class="dark" [disabled]="!isReady" [inlineSVG]="'/assets/images/icons/stop.svg'"></button>
    <button play (click)="play()" class="dark" [disabled]="!isReady" [hidden]="audio.player.isPlaying" [inlineSVG]="'/assets/images/icons/play.svg'"></button>
    <button pause (click)="pause()" class="dark" [disabled]="!isReady" [hidden]="!audio.player.isPlaying" [inlineSVG]="'/assets/images/icons/pause.svg'"></button>
    <button record (click)="startRecording()" class="dark" [disabled]="!isReady" [hidden]="audio.recorder.recording$ | async" [inlineSVG]="'/assets/images/icons/record.svg'"></button>
    <button stopRecording (click)="stopRecording()" [disabled]="!isReady" [hidden]="!(audio.recorder.recording$ | async)" [inlineSVG]="'/assets/images/icons/record.svg'"></button>

    <button loop (click)="toggleLoop()" [ngClass]="{ dark: !audio.player.isLooping }" [disabled]="!isReady" [inlineSVG]="'/assets/images/icons/refresh.svg'"></button>

    <button speed class="dark" [disabled]="!isReady" #speedMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="speedMenu">
        <mat-icon svgIcon="speed"></mat-icon> {{ audio.player.speed() }}x
    </button>
    <mat-menu #speedMenu="matMenu" yPosition="above" class="speed-menu">
        <span class="speed-menu-option" (click)="audio.player.speed(0.25)">
            0.25x
        </span>
        <span class="speed-menu-option" (click)="audio.player.speed(0.5)">
            0.5x
        </span>
        <span class="speed-menu-option" (click)="audio.player.speed(0.75)">
            0.75x
        </span>
        <span class="speed-menu-option" (click)="audio.player.speed(1)">
            1x
        </span>
        <span class="speed-menu-option" (click)="audio.player.speed(1.25)">
            1.25x
        </span>
        <span class="speed-menu-option" (click)="audio.player.speed(1.5)">
            1.5x
        </span>
        <span class="speed-menu-option" (click)="audio.player.speed(1.75)">
            1.75x
        </span>
        <span class="speed-menu-option" (click)="audio.player.speed(2)">
            2x
        </span>
    </mat-menu>
</div>

<div other>
    <span speaker *ngIf="audio.player.volume() >= 0.75" [inlineSVG]="'/assets/images/icons/speakermax.svg'"></span>
    <span speaker *ngIf="audio.player.volume() < 0.75 && audio.player.volume() > 0" [inlineSVG]="'/assets/images/icons/speakermin.svg'"></span>
    <span speaker *ngIf="audio.player.volume() === 0" [inlineSVG]="'/assets/images/icons/speakeroff.svg'"></span>
    <mat-slider volume min="0" max="1" step="0.01"  [ngModel]="audio.player.volume()" (input)="audio.player.volume($event.value)" (change)="setVolume($event.value)"></mat-slider>

    <button download class="dark" [inlineSVG]="'/assets/images/icons/ellipses.svg'" [prepend]="true"
        #projectMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="projectMenu"></button>
    <mat-menu #projectMenu="matMenu" yPosition="above" class="project-menu">
        <span class="project-menu-option" (click)="downloadMix()">
            <span [inlineSVG]="'/assets/images/icons/download.svg'"></span>
            Download mix
        </span>
        <span *ngIf="project.userCanWrite" class="project-menu-option" (click)="downloadComments()">
            <span [inlineSVG]="'/assets/images/icons/download.svg'"></span>
            Download comments
        </span>
    </mat-menu>
</div>