<a class="logo" [routerLink]="logoLink" (click)="logoClick.emit($event)" [ngClass]="{ icon: logoMode === 'icon' }" ></a>
<ng-content select="[postLogo]"></ng-content>

<ng-content></ng-content>

<div class="profile" fxLayout="row" fxLayoutAlign="end center">
    <ng-content select="[profileExtra]"></ng-content>
    <ng-content select="[preProfile]"></ng-content>
    <div class="identity" [ngClass]="{ icon: profileMode === 'icon' }" #userMenuTrigger [matMenuTriggerFor]="userMenu" (onMenuClose)="userMenuTrigger.classList.remove('opened')" (onMenuOpen)="userMenuTrigger.classList.add('opened')">
        <ng-container *ngIf="user?.id; else anonymous">
            <img  [src]="user.picture"/>
            <span *ngIf="profileMode === 'full'" name>{{ user.nameFirst}} {{ user.nameLast }}</span>
        </ng-container>
        <ng-template #anonymous>
            <mat-icon svgIcon="anonymous"></mat-icon>
            <span *ngIf="profileMode === 'full'" name></span>
        </ng-template>
    </div>
    <mat-menu #userMenu="matMenu" yPosition="below" [overlapTrigger]="false" class="user-menu">
        <span class="user-menu-option" *ngIf="user" [routerLink]="['/account/profile']">
            <span [inlineSVG]="'/assets/images/icons/person.svg'"></span>
            Profile
        </span>
        <span class="user-menu-option" *ngIf="user && !user.isPartner" [routerLink]="['/account/subscription']">
            <span [inlineSVG]="'/assets/images/icons/marker.svg'"></span>
            Subscription
        </span>
        <span class="user-menu-option" *ngIf="user && user.card && !user.isPartner" [routerLink]="['/account/billing']">
            <span [inlineSVG]="'/assets/images/icons/info.svg'"></span>
            Billing
        </span>
        <span class="user-menu-option" *ngIf="user && user.isPartner" [routerLink]="['/account/partner']">
            <span [inlineSVG]="'/assets/images/icons/marker.svg'"></span>
            {{ user.isAnnualSubscription ? "Account info" : "Stack code" }}
        </span>
        <span class="user-menu-option" (click)="GoToHelp()">
            <span [inlineSVG]="'/assets/images/icons/info.svg'"></span>
            Help
        </span>
        <span class="user-menu-option" *ngIf="user" [routerLink]="['/auth/logout']">
            <span [inlineSVG]="'/assets/images/icons/enter.svg'"></span>
            Logout
        </span>

        <span class="user-menu-option" *ngIf="!user" [routerLink]="['/auth/login']">
            <span></span>
            Login
        </span>
        <span class="user-menu-option" *ngIf="!user" [routerLink]="['/auth/register']">
            <span ></span>
            Register
        </span>
    </mat-menu>
</div>
