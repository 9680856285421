import { Injectable, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';

import { IApiOptions, SocketEventMessage, SocketService, Store } from '@app/core';
import { Comment, ICommentData } from '../types';
import { CommentDataService, REACTIONS, ReactionDataService } from '../services';
import { HttpContext } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CommentStore extends Store<Comment, ICommentData, CommentDataService> implements OnDestroy {

    protected static readonly LIST_KEY_PARTS = ['projectId'];

    constructor(protected service: CommentDataService, protected readonly socket: SocketService, protected reaction: ReactionDataService) {
        super(socket);
    }

    ngOnDestroy(): void {
        this.clearSubscriptions()
    }
  
    protected observe(): Observable<SocketEventMessage> {
      return this.socket.observe(Comment);
  }

    addReaction(comment: Comment, code: number) {
        const context = new HttpContext().set(REACTIONS, comment.reactions);
        this.reaction.create(code, comment.toPostableData(), { context } as IApiOptions).subscribe((commentWithReactions: Comment) => {
            // The API does not return ALL the same comment data when calling reaction, so we only get the new reactions
            const identity = this._idUuidMap[commentWithReactions.id];
            const existingComment = this._items[identity].value;
            existingComment.reactions = commentWithReactions.reactions;
            this._items[identity].next(existingComment);
        });
    }

    removeReaction(comment: Comment, code: number) {
        const context = new HttpContext().set(REACTIONS, comment.reactions);
        this.reaction.delete(code, comment.toPostableData(), { context } as IApiOptions).subscribe((commentWithReactions: Comment) => {
            // The API does not return ALL the same comment data when calling reaction, so we only get the new reactions
            const identity = this._idUuidMap[commentWithReactions.id];
            const existingComment = this._items[identity].value;
            existingComment.reactions = commentWithReactions.reactions;
            this._items[identity].next(existingComment);
        });
    }
}
