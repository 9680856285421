import { Emoji } from "../../emojis";

// export class Reaction {
//     public code: number;
//     public htmlcode: string;
// }

export class ReactionUsage {
    public key: number;
    public frequency: number;
}