<div mat-dialog-title>Verify your email</div>
<div mat-dialog-content>
    <div class="ve-subtitle">
        We sent you a verification link to the email you provided
        when signing up for Notetracks. Please check your inbox and
        click the link to verify your email address. You may need
        to check your spam folder.
    </div>
    <div class="ve-controls">
        <label>email address</label>
        <div>{{ email }}</div>
        <button class="bigone" ng-click="ResendVerificationEmail()">Don't see the email? Resend it</button>
    </div>
</div>
<div mat-dialog-actions>
    <label>Still having trouble? <a href="https://www.notetracks.com/" target="_blank">Contact Us</a></label>
</div>
