<button mat-dialog-close [ngStyle]="{'z-index': 1050, display: 'block'}">
  <span [inlineSVG]="'assets/images/icons/x.svg'"></span>
</button>
<h3 mat-dialog-title>
  <ng-container [ngSwitch]="mode">
    <span *ngSwitchCase="'edit'" i18n>Edit</span>
    <span *ngSwitchCase="'copy'" i18n>Copy</span>
    <span *ngSwitchCase="'new'" i18n>New</span>
  </ng-container>&nbsp;<span class="modal-title" i18n>Project</span>
</h3>
<div mat-dialog-content>
  <div *ngIf="error" class="alert alert-danger">
    <button (click)="clearError()" class="close">
      &times;
    </button>
    <p>{{ error }}</p>
  </div>
  <form ngForm class="project-add" (ngSubmit)="submit()" #theForm="ngForm" novalidate>
    <div fxLayout="row" fxLayoutAlign="start center">
      <label for="projectType">Project Type</label>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

      <mat-radio-group [(ngModel)]="project.mediaType" name="projectTypeOptions">
        <mat-radio-button value="audio" [checked]="true" [disabled]="isEditing">Audio</mat-radio-button>
        <span class="inline-separator1"></span>
        <mat-radio-button value="video" [disabled]="isEditing">Video</mat-radio-button>
      </mat-radio-group>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <label for="title">Title</label>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <input fxFlex="100" id="title" type="text" name="title" ntAutofocus
             #name="ngModel" [(ngModel)]="project.name" required pattern=".*\S+.*"
             [ngClass]="{ error: submitted && name.invalid }"
             title="This field cannot contain only space(s)" placeholder="Start typing here..." />
    </div>
    <div class="footer" fxLayout="row" fxLayoutAlign="start center">
      <button type="submit" class="center-block"
              [disabled]="isProcessing || project.name == ''" i18n>Done</button>
    </div>
  </form>
</div>
