<div class="bar-container" [style.width]="canvasWidth + 'px'">
    <canvas #navBar></canvas>
    <div #zoomView ntDraggable (move)="onMouseMoveViewer($event)"
        [style.left]="(leftControlPosition) + 'px'"
        [style.width]="(rightControlPosition - leftControlPosition) + 'px'"
        class="zoom-view"></div>
    <div #controlLeft ntDraggable (move)="onMouseMoveLeft($event)"
        [style.left]="leftControlPosition - 3 + 'px'"
        class="drag-control cleft"></div> 
    <div #controlRight ntDraggable (move)="onMouseMoveRight($event)"        
        [style.left]="rightControlPosition - 5 + 'px'"
        class="drag-control cright"></div>
</div>

<div class="view-option wave" [ngClass]="{ selected: (mode$ | async) === MODE.WAVE}" (click)="setMode(MODE.WAVE)"></div>
<div class="view-option multitrack" [ngClass]="{ selected: (mode$ | async) === MODE.TRACKS }" (click)="setMode(MODE.TRACKS)"></div>
<div class="view-option zoom100" [ngClass]="{ active: !zoomToggleMemory }" (click)="toggleZoom()"></div>
