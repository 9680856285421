<button mat-dialog-close [ngStyle]="{'z-index': 1050, display: 'block'}">
    <span [inlineSVG]="'assets/images/icons/x.svg'"></span>
</button>
<h3 mat-dialog-title>
    Share with others
    <div class="subtitle">
        <span class="projectInfo">{{ (project$ | async)?.name }} </span><span class="dot"></span>
        <span *ngIf="shares$ | async as shares; else noShares">{{ shares.length + 1 }} members</span>
        <ng-template #noShares>1 member</ng-template>
    </div>
</h3>
<div mat-dialog-content>
    <form ngForm (ngSubmit)="submit()" #theForm="ngForm" novalidate>
        
        <!-- #region Email Field-->

        <div class="row">
            <div class="col-10">
                <div class="dark1">
                    <mat-form-field floatLabel="never" class="col-8">
                        <mat-chip-list #chipList aria-label="collaborators emails">
                            <mat-chip *ngFor="let recipient of recipients" (removed)="removeRecipient(recipient)">
                                {{ recipient.email }}
                                <button matChipRemove><mat-icon>cancel</mat-icon></button>
                            </mat-chip>
                            <input #emailInput
                                placeholder="Invite people by entering names or email addresses" 
                                [matChipInputFor]="chipList" 
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="addRecipient($event)">
                        </mat-chip-list>
                    </mat-form-field>
                    <mat-select class="float-right col-4" [(value)]="invitePermission">
                        <mat-option *ngFor="let permission of PERMISSIONS" [value]="permission">{{ permission.description }}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="col-2 px-2">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <button class="basic1" type="submit" [disabled]="hasErrors() || recipients.length == 0">Send Invite</button>
                </div>
            </div>
        </div>
        <!-- #endregion -->

        <!-- #region Invite & Message-->
        <div fxLayout="row" fxLayoutAlign="start center" *ngIf="recipients.length > 0">
            <textarea name="invitationMessage" [(ngModel)]="invitationMessage" placeholder="Type a message here to send along with your invite"></textarea>
        </div>
        
        <!-- #endregion -->

        <!-- #region Existing Shares-->
        <div class="share-owner">
            <div class="row share-item" *ngIf="recipients.length == 0">
                <div class="col-10 user-name">
                    <img *ngIf="(project$ | async)?.user.picture; else noAvatar" [src]="(project$ | async)?.user.picture">
                    <ng-template #noAvatar>
                        <img src="/assets/images/star-track.png">
                    </ng-template>
                    <ng-container *ngIf="(project$ | async)?.user.nameFirst || (project$ | async)?.user.nameLast; else noName">
                        <span class="fullname">
                            {{ (project$ | async)?.user.nameFirst }} {{ (project$ | async)?.user.nameLast }} (you) <br>
                            <span class="email">{{ (project$ | async)?.user.email }}</span>
                        </span>
                    </ng-container>
                    <ng-template #noName>
                        <span class="fullname">
                            <span class="email">{{ (project$ | async)?.user.email }}</span>
                        </span>
                    </ng-template>
                </div>
                <div class="col-2 gray1 align-right vtop">
                    Owner
                </div>
            </div>
        </div>
        
        <ng-container *ngIf="shares$ | async as shares">
            <div *ngIf="shares.length > 0" fxLayout="row" class="share-container" fxLayoutAlign="start center" [hidden]="recipients.length > 0">
                <div class="row share-item" *ngFor="let share of shares" [hidden]="recipients.length > 0">
                    <div class="col-9 user-name">
                        <img *ngIf="share.user.picture; else noAvatar" [src]="share.user.picture">
                        <ng-template #noAvatar>
                            <img src="/assets/images/star-track.png">
                        </ng-template>
                        <ng-container *ngIf="share.user.nameFirst || share.user.nameLast; else noName">
                            <span class="fullname">
                                {{ share.user.nameFirst }} {{ share.user.nameLast }}<br>
                                <span class="email">{{ share.user.email }}</span>
                            </span>
                        </ng-container>
                        <ng-template #noName>
                            <span class="fullname">
                                <span class="email">{{ share.user.email }}</span>
                            </span>
                        </ng-template>
                    </div>
                    <div class="col-3 fitRight">
                        <mat-select class="position-right noback medium fitRight" [value]="share.permission" (selectionChange)="onSharePermissionChanged($event, share)">
                            <mat-option *ngFor="let permission of PERMISSIONS_WITH_REMOVE" 
                                [ngClass]="{ permissionAlert: permission.code == 'rm' }"
                                [value]="permission.code">{{ permission.description }}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- #endregion -->
    </form>

    
        <!-- #region -->
        <div class="row applications">
            <ul>
                <li>
                    <a class="gmailShare" target="_blank" href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&su={{ encodedProjectName }}&body={{ encodedProjectURL }}">
                        <img src="assets/images/icons/gmail_logo.png" alt=""><span>Gmail</span>
                    </a>
                </li>
                <li>
                    <a class="messengerShare" (click)="messengerShare()">
                        <img src="assets/images/icons/messenger_logo.png" alt=""><span>Messenger</span>
                    </a>
                </li>
                <li>
                    <a class="whatsappShare" target="_blank" href="https://api.whatsapp.com/send?text={{ encodedProjectName }}%0a{{ encodedProjectURL }}">
                        <img src="assets/images/icons/whatsapp_logo.png" alt=""><span>Whatsapp</span>
                    </a>
                </li>
                <li><img src="assets/images/icons/slack_logo.png" alt=""><span>Slack</span></li>
                <li>
                    <div #shareTeams class="teams-share-button">
                    </div><span>Teams</span>
                </li>
                <li style="display:none;"><img src="assets/images/icons/discord_logo.png" alt=""><span>Discord</span></li>
            </ul>
        </div>
        <!-- #endregion -->

        <!-- #region Project Link-->
        <div class="row link">
            <div class="linkDiv">
                <span #publicLink class="publicLink">{{ shareURL }}</span>
            </div>
            <div class="button">
                <span class="chain" [inlineSVG]="'assets/images/icons/link.svg'"></span>
                <button class="buttonLink" type="button" (click)="copyLink()">
                    <ng-container *ngIf="(shareMode$ | async)?.code === 'im';else copyLinkPublic">
                        Activate Public Link
                    </ng-container>
                    <ng-template #copyLinkPublic>
                        Copy link
                    </ng-template>
                </button>
            </div>
        </div>
        <!-- #endregion -->

        <!-- #region Share Mode / Public Permissions / Start playback -->
        <div class="row settings">
            <div class="col-8">
                <span [inlineSVG]="'assets/images/icons/cog.svg'"></span>
                <span>&nbsp;&nbsp;Privacy Settings: </span>
                &nbsp;<span class="fakeLink" [matMenuTriggerFor]="shareModeMenu">{{ (shareMode$ | async).description }}</span>&nbsp;
                <mat-menu #shareModeMenu="matMenu">
                    <div class="menu-option" (click)="setShareMode({ code: 'im', description: 'Invited members' })">Only invites</div>
                    <div class="menu-option" (click)="setShareMode({ code: 'an', description: 'Anyone' })">Anyone</div>
                </mat-menu>
                <ng-container *ngIf="(shareMode$ | async)?.code === 'an'">
                    <span>with this link</span>
                    &nbsp;<span class="fakeLink lowercase" [matMenuTriggerFor]="permissionMenu">{{ (publicPermission$ | async)?.description }}</span>&nbsp;
                    <mat-menu #permissionMenu="matMenu">
                        <div class="menu-option" (click)="setPermission({ code: 'r', description: 'Can view' })">Can view</div>
                        <div class="menu-option" (click)="setPermission({ code: 'rc', description: 'Can comment' })">Can comment</div>
                        <div class="menu-option" (click)="setPermission({ code: 'rw', description: 'Can edit' })">Can edit</div>
                    </mat-menu>
                </ng-container>
            </div>
            <div class="startPlayback col-4">
                <mat-checkbox #chkParameter class="check mt-3" (change)="setParameter()">
                    Start playback at 
                </mat-checkbox>
                <span class="timecode" [hidden]="editingTimecode" (click)="onEdit()">{{ timeCode | timecode: 'h?:mm:ss' }}</span>
                <span><input #txtTimecode class="mini1" type="text" [hidden]="!editingTimecode" [(ngModel)]="timeCodeAux" (blur)="setTimecode()" (keyup.enter)="setTimecode()" /></span>
            </div>
        </div>
        <!-- #endregion -->
</div>