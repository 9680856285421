import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, AuthUser, Plan, plansWording } from '@app/features/auth';
import { PlanService } from '@app/features/auth/services/plan.service';
import { SubscriptionService } from '@app/features/auth/services/subscription.service';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { countries } from '../../../project/types';

@Component({
  selector: 'nt-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss','./payment-mobile.component.scss']
})
export class PaymentComponent implements OnInit, AfterViewInit {

  public user$: Observable<Readonly<AuthUser>>;

  public cardNumber: string;
  public cardHolderName: string;
  public promoCode: string;
  public cardExpirationMonth: number;
  public cardExpirationYear: number;
  public cardExpirationCvc: number;
  public address: string;
  public city: string;
  public selectedCountry: string = null;
  public zip: string;
  public selectedRegion: string = null;
  public user:AuthUser;
  private _destroy: Subject<void> = new Subject();
  public planId: number;
  public allCountries: any;
  public countryRegions: any;
  public plan$: Observable<Plan>;
  public plan: Plan;
  public allPlansWording: any;
  public amountOff: number;
  public promocodeValid: boolean = null;
  public termsAccepted: boolean;
  public selRegion: FormControl = new FormControl('', null);
  public paymentForm: FormGroup = new FormGroup({
    txtCardHolderName: new FormControl('', null),
    txtPromoCode: new FormControl(''),
    txtAddress: new FormControl('', null),
    txtCity: new FormControl('', null),
    // selCountry: new FormControl(''),
    txtZip: new FormControl('', null),
    selRegion: this.selRegion
  });
  
  //#region Stripe
  public cardOptions: StripeCardElementOptions;
  public elementsOptions: StripeElementsOptions;
  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  // public stripeCard: FormGroup;
  //#endregion end

  constructor(
    private readonly auth: AuthService,
    private readonly stripe: StripeService,
    private readonly route: ActivatedRoute,
    private readonly subscription: SubscriptionService,
    private toast: ToastrService,
    protected planService: PlanService,
    private readonly router: Router
  ) { 
    this.cardOptions = {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: '#666EE8',
          color: '#000',
          fontWeight: '700',
          fontFamily: 'Inter, "Theinhardt", Roboto, "Open Sans", sans-serif',
          fontSize: '15px',
          '::placeholder': {
            color: '#000'
          }
        }
      }
    } as StripeCardElementOptions;

    this.elementsOptions = {
      locale: 'en'
    } as StripeElementsOptions;
  }

  ngOnInit(): void {
    this.user$ = this.auth.user$;

    this.allCountries = countries;
    this.allPlansWording = plansWording;
    this.termsAccepted = false;

    this.route.params.pipe(takeUntil(this._destroy)).subscribe((params) => {
      this.planId = params.planId;
      this.plan$ = this.planService.getPlan(this.planId);
      this.plan$.subscribe((thePlan:Plan) => {
        this.plan = thePlan;
      });
    });

    this.auth.user$.subscribe((usr: AuthUser) => {
      this.user = usr;
    });
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    this.selectedCountry = 'CA';
    this.getRegions(null, this.selectedCountry);
  }

  getPlanAbreviation(planId: number): string {
    switch (planId) {
      case 2: case 3: case 4: return 'mo';
      case 25: case 26: case 27: return 'yr'
    }

    return 'mo';
  }

  checkPromoCode(): void {
    this.subscription.validatePromocode(this.promoCode).subscribe((data: any) => {
      let coupon = data.result;
      if (coupon.valid) {
        this.amountOff = -this.plan.priceCharged * coupon.percent_off_precise / 100.0;
        this.toast.success('Coupon applied successfully.');
        this.promocodeValid = true;
      }
    }, (error) => {
      this.amountOff = null;
      this.promocodeValid = false;
      this.toast.error(error.error.notice.message);
    });
  }

  onPromocodeChange(): void {
    this.promocodeValid = null;
    this.promoCode = null;
  }

  public cardFormIsComplete(): boolean {
    return document.querySelector('ngx-stripe-card div').classList.contains('StripeElement--complete');
  }

  public formIsValid(): boolean {
    return this.paymentForm.valid && this.cardFormIsComplete();
  }

  upgrade(): void {
    if (!this.formIsValid()) {
      return;
    }
    
    if (!this.user.card) {
      // subscribe plan
      this.stripe.createToken(this.card.element, { name: this.cardHolderName }).subscribe((result) => {
        if (result.token) {
          let apiForm = this.getFormData(result.token.id);
          if (this.promoCode) {
            apiForm.stripe_promocode = this.promoCode;
          }

          this.subscription.subscribePlan({ planId: this.planId }, apiForm, this.promoCode).subscribe((plan: Plan) => {
            this.toast.success('Subscription plan upgraded successfully.');
            this.router.navigate(['project','list']);
            // intercom 'UPGRADE PLAN'

            // validate demo project pending to save
            // if not, go to projects list
          },
          (error) => {
            this.toast.error(error.error.message);
          });
        } else if (result.error) {
          console.error('error', result.error.message);
        }
      },
      (error) => {
        this.toast.error(error.error.message);
      });
    } else {
      // upgrade plan
      let apiForm: any = { plan_id: this.planId };
      if (this.promoCode) {
        apiForm.stripe_promocode = this.promoCode;
      }
      this.subscription.upgradePlanFirstTime(apiForm).subscribe((plan: Plan) => {
        this.toast.success('Subscription plan upgraded successfully.');
        this.router.navigate(['project','list']);
        // intercom 'UPGRADE PLAN'

        // validate demo project pending to save
        // if not, go to projects list
      },
      (error) => {
        console.error(error);
        this.toast.error(error.error.notice.message);
      });
    }
  }

  private getFormData(stripeToken: string): any {
    return { 
      billing_address1: this.address,
      billing_address2: null,
      billing_city: this.city,
      billing_country: this.selectedCountry,
      billing_postalcode: this.zip,
      billing_region: this.selectedRegion,
      plan_id: this.planId,
      stripe_token: stripeToken,
    };
  }

  public getRegions(event: MatSelectChange, countryCode: string): any {
    this.selectedRegion = null;
    // this.paymentForm.value.selRegion = null;
    this.paymentForm.controls['selRegion'].setErrors({ 'incorrect': true });

    let country = this.allCountries.find((c: any) => { return c.countryShortCode == countryCode });

    if (country) {
      this.countryRegions = country.regions;
    } else {
      this.countryRegions = [];
    }
  }
}
