<div video *ngIf="project.mediaType === 'video' && medias.length > 0 && (pms.ui.video$ | async)?.fMain?.href" [hidden]="!(pms.ui.videoDisplayFactor$ | async)">
    <video #videoPlayer oncontextmenu="return false;" crossorigin="anonymous" controlsList="odownload, nofullscreen, noremoteplayback" [muted]="true" webkit-playsinline playsinline>
        <source [src]="(pms.ui.video$ | async)?.fMain?.href | safeUrl">
    </video>
</div>

<div loader *ngIf="!isReady"></div>
<ng-container *ngIf="isAudioPlayerInitialized">
    <nt-ruler #ruler [projectId]="project.id" [hasAllTracksCollapsed]="hasAllTracksCollapsed | async" (allTracksCollapsed)="toggleAllTracksCollapsed($event)" (ready)="isRulerReady = $event"></nt-ruler>

    <div *ngIf="isRulerReady" id="tracks" cdkDropList (cdkDropListDropped)="onDrop($event)">
        <ng-container *ngIf="(audio.recorder.recording$ | async) || (audio.recorder.recordedBuffer$ | async)">
            <nt-track-recorder #recorderTrack class="spirit" [project]="project" (record)="onRecordTrack($event)"></nt-track-recorder>
        </ng-container>
        <ng-container *ngFor="let media of medias.sortBy('sequence'); trackBy: trackByMediaId">

            <nt-track *ngIf="!media.isMain && media.status === 'processed'" #track
                [ngClass]="media.color"
                [media]="media"
                (ready)="onTrackReady($event)"
                (readyProgress)="updateProgress($event, media)"
                (mediaBeingLoaded)="mediaBeingLoaded($event)"></nt-track>

        </ng-container>
        <!-- #region Add Track-->
        <div trackAdd [ngClass]="{ emphasis: !medias.length && triggerTrackReadyWhenEmpty(!medias.length) }" (click)="onShowAddTrackDialog()">
            <span add [inlineSVG]="'/assets/images/icons/plusthin.svg'"></span>
            <div>Add or Record New Track</div>
        </div>
        <div></div>
        <!-- #endregion -->
    </div>
    <nt-scroll></nt-scroll>
</ng-container>
