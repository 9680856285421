import { HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, IApiOptions, IApiResponse } from '@app/core/api';
import { Project, IProjectData, ProjectList, IProjectDataList } from '../../types';
import { IDS } from '.';

@Injectable({
  providedIn: 'root'
})
export class ProjectDataService {

  //#region Fields
  //#endregion

  constructor(private api: ApiService) { }

  //#region CRUD Operation
  public list(params?: Record<string, any>): Observable<ProjectList> {
    const { limit, skip, orderBy, order } = params;
    const queryParams = { limit: limit || 0, skip, orderBy, order };
    const queryString = Object.keys(queryParams).filter(k => queryParams[k] !== undefined).map(k => `${k}=${queryParams[k]}`).join('&')
    return this.api.get<IProjectDataList>(`project?${queryString}`).pipe(
      map((response: IApiResponse<IProjectDataList>) => {
        const pl = new ProjectList(response.result, Project);
        return pl;
      })
    );
  }

  public get(params?: Record<string, any>): Observable<Project> {
    const { id, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }

    return this.api.get<IProjectData>(`project/${id}`, options).pipe(
      map((response: IApiResponse<IProjectData>) => {
        const p = new Project(response.result);
        return p;
      })
    );
  }

  public save(projectData: Partial<IProjectData>): Observable<Project> {
    const { id, public_key } = projectData; delete projectData.id;
    const context = new HttpContext().set(IDS, { id });
    const options: IApiOptions = { observe: 'response' as 'response', context };
    if (public_key) {
        options.headers = new HttpHeaders({ 'X-Public-Key': public_key });
    }
    const pathId = id ? `/${id}` : '';
    return this.api.post<IProjectData, Partial<IProjectData>>(`project${pathId}`, projectData, options).pipe(
      map((response: IApiResponse<IProjectData>) => {
        const p = new Project(response.result);
        return p;
      })
    );
  }

  public delete(params?: Record<string, any>): Observable<boolean> {
    const { id, publicKey } = params;
    const options: IApiOptions = { observe: 'response' as 'response' };
    if (publicKey) {
      options.headers = new HttpHeaders({ 'X-Public-Key': publicKey });
    }
    
    return this.api.delete<boolean>(`project/${id}`, options).pipe(
      map((response: IApiResponse<boolean>) => {
        return response.result;
      })
    );
  }
  //#endregion

}
