import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AudioService } from '@app/core';
import { ProjectManagerService } from '@app/features/project/project.module';
import { Project } from '@app/features/project/types';

import { filter, switchMap, take } from 'rxjs/operators';

import { BaseTrackComponent } from '../base/base-track.component';

@Component({
  selector: 'nt-track-recorder',
  templateUrl: './track-recorder.component.html',
  styleUrls: ['../base/base-track.component.scss', './track-recorder.component.scss']
})
export class TrackRecorderComponent extends BaseTrackComponent implements AfterViewInit {

  @Input() project: Project;
  @Input() firstSequence: number;
  @Input() lastSequence: number;
  @Output() record: EventEmitter<AudioBuffer> = new EventEmitter();

  constructor(public override readonly audio: AudioService, public override readonly pms: ProjectManagerService){
    super();
  }

  ngAfterViewInit(): void {
    this.audio.recorder.recordedBuffer$.pipe(switchMap(this.audio.blobToBuffer)).subscribe((buffer: AudioBuffer) => {
        if(this.canvas) {
            this.isAudioReady = true;
            this.drawWaveform(buffer, buffer.duration, 'spirit');
        }
      });
      this.audio.recorder.recording$.pipe(
        filter(r => !r),
        switchMap((r) => this.audio.recorder.recordedBuffer$.pipe(switchMap(this.audio.blobToBuffer))),
        take(1),
      ).subscribe((buffer: AudioBuffer) => {
        this.record.emit(buffer);
    });
  }

}
