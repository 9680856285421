import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectViewComponent } from '../pages';

@Injectable({
  providedIn: 'root'
})
export class StopAudioBeforeLeavingGuard implements CanDeactivate<ProjectViewComponent> {
  
  canDeactivate(component: ProjectViewComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    component.controls.stop();
    return true;
  }
  
}
