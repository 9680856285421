import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, OnChanges, AfterViewInit, SimpleChanges, SimpleChange, HostBinding, HostListener, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { Subject, take, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { AudioService } from '@app/core';
import { NtAudioTrackNode } from '@app/core/audio/types/nt-audio-track-node';
import { ProjectManagerService } from '@app/features/project/project.module';
import { Clip, Color } from '@app/features/project/types';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Clipboard } from '@angular/cdk/clipboard';


const colorRgb = {
    passio: 'rgba(242,102,102,1)',
    freedo: 'rgba(255,190,93,1)',
    balanc: 'rgba(141,216,100,1)',
    spirit: 'rgba(122,190,250,1)',
    creati: 'rgba(187,152,230,1)',
}

@Component({
    selector: 'nt-clip',
    templateUrl: './clip.component.html',
    styleUrls: ['./clip.component.scss']
})
export class ClipComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    private readonly HOTKEY_COMBO_COPY = 'mod+c';
    private readonly HOTKEY_COMBO_CUT = 'mod+x';

    public readonly MAX_FULL_RESOLUTION = 16384 * 2 -1; // 16384 * 3 is too big for firefox

    @Input('track') track: NtAudioTrackNode;
    @Input('clip') clip: Clip;

    @HostBinding('class')
    @Input('color') color: Color;

    @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

    @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

    @Output('select') onSelect: EventEmitter<Clip> = new EventEmitter();
    @Output('split') onSplit: EventEmitter<Array<Clip>> = new EventEmitter();
    @Output('delete') onDelete: EventEmitter<Clip> = new EventEmitter();
    @Output('change') onChange: EventEmitter<Clip> = new EventEmitter();

    contextMenuPosition = { x: '0px', y: '0px' };

    @HostBinding('class.selected')
    isSelected: boolean;

    private _destroy: Subject<void> = new Subject();

    constructor(
        public readonly pms: ProjectManagerService, private readonly audio: AudioService,
        private hotkeys: HotkeysService, public readonly host: ElementRef) { }

    //#region Lifecycle
    ngOnInit(): void {
        this.pms.ui.selectedClipUuid$.pipe(takeUntil(this._destroy)).subscribe((uuid: string) => {
            this.isSelected = uuid === this.clip.uuid;

            // Each remove their hotkeys in case the uuid is null
            this.clearOwnCopyCutHotkeys();

            if(this.isSelected) { // Add if selected
                const hkc = new Hotkey([this.HOTKEY_COMBO_COPY], this.onCopy, undefined, 'Copy clip');
                const hkx = new Hotkey([this.HOTKEY_COMBO_CUT], this.onCut, undefined, 'Cut clip');
                this.hotkeys.add(hkc);
                this.hotkeys.add(hkx);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.clip instanceof SimpleChange) {
            if (!this.clip) {
                this.clip = new Clip({ uuid: uuidv4(), position: 0, start: 0, duration: this.track.duration });
                // this.drawWaveform(this.clip, this.track.buffer, this.track.buffer.duration, this.color, true);
            }
        }
        if (changes.color instanceof SimpleChange) {
            if (this.canvas) {
                this.drawWaveform(this.track.buffer, this.color, true);
            }
        }

        this.pms.ui.selectedClipUuid$.pipe(take(1)).subscribe((uuid: string) => this.isSelected = uuid === this.clip.uuid);
    }

    ngAfterViewInit(): void {
        this.drawWaveform(this.track.buffer, this.color, true);
        this.pms.ui.selectedClipUuid$.pipe(take(1)).subscribe((uuid: string) => this.isSelected = uuid === this.clip.uuid);
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
        this.clearOwnCopyCutHotkeys();
        delete this.track;
    }
    //#endregion

    clearOwnCopyCutHotkeys() {
        // Copy
        const copySymbol = Hotkey.symbolize(this.HOTKEY_COMBO_COPY);
        const currHkc = this.hotkeys.hotkeys.filter(v => v.combo.indexOf(copySymbol) > -1 && v.callback === this.onCopy);
        if (currHkc.length > 0) this.hotkeys.remove(currHkc);

        // Cut
        const cutKeys = 'mod+x'
        const cutSymbol = Hotkey.symbolize(this.HOTKEY_COMBO_CUT);
        const currHkx = this.hotkeys.hotkeys.filter(v => v.combo.indexOf(cutSymbol) > -1 && v.callback === this.onCut)
        if (currHkx.length > 0) this.hotkeys.remove(currHkx);
    }

    private drawWaveform(buffer: AudioBuffer, color: string, strereo: boolean = true) {
        const dataLeft: Float32Array = buffer.getChannelData(0);
        const dataRight: Float32Array = buffer.numberOfChannels >= 2 ? buffer.getChannelData(1) : null;
        const data: Array<Float32Array> = dataRight && strereo ? [ dataLeft, dataRight ] : [ dataLeft ];

        this.drawWaveformData(data, color);
    }

    private drawClipWaveform(clip: Clip, buffer: AudioBuffer, duration: number, color: string, strereo: boolean = true) {
        const dataLeft: Float32Array = buffer.getChannelData(0);
        const dataRight: Float32Array = buffer.numberOfChannels >= 2 ? buffer.getChannelData(1) : null;
        const clipSampleOffset = Math.round(dataLeft.length * (clip.start / buffer.duration));
        const clipSampleLength =  Math.round(dataLeft.length * (clip.duration / buffer.duration));
        const data: Array<Float32Array> = dataRight && strereo ? [
            dataLeft.subarray(clipSampleOffset, clipSampleOffset + clipSampleLength),
            dataRight.subarray(clipSampleOffset, clipSampleOffset + clipSampleLength)
        ] : [dataLeft.subarray(clipSampleOffset, clipSampleOffset + clipSampleLength)];

        this.drawWaveformData(data, color);
    }

    private drawWaveformData(data, color) {
        const context = this.canvas.nativeElement.getContext('2d');
        const canvasWidth = this.MAX_FULL_RESOLUTION;

        // we 'resample' with cumul, count, variance
        // Offset 0 : PositiveCumul  1: PositiveCount  2: PositiveVariance
        //        3 : NegativeCumul  4: NegativeCount  5: NegativeVariance
        // that makes 6 data per bucket
        const bucketSize = 6;

        const padLength = 0;
        const sampleCount = data[0].length + padLength;

        const waveSkipLength = 1;

        try {
            context.save();
            context.scale(1, 1);
            context.moveTo(0, 0);
            context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
            // if(skipDraw) return true;
            data.forEach((d, dIdx, alld) => {
                const resampled = new Float64Array(canvasWidth * bucketSize);
                let min = Infinity, max = -Infinity;
                // first pass for mean
                for (let i = 0; i < sampleCount; i += waveSkipLength) {
                    // in which bucket do we fall ?
                    let buckIndex = 0 | (canvasWidth * i / sampleCount);
                    buckIndex *= 6;
                    // positive or negative ?
                    const thisValue = d[i] || 0;
                    if (thisValue > 0) {
                        resampled[buckIndex] += thisValue;
                        resampled[buckIndex + 1] += 1;
                    } else if (thisValue < 0) {
                        resampled[buckIndex + 3] += thisValue;
                        resampled[buckIndex + 4] += 1;
                    }
                    if (thisValue < min) min = thisValue;
                    if (thisValue > max) max = thisValue;
                }

                // compute mean now
                for (let i = 0, j = 0; i < canvasWidth; i++, j += 6) {
                    if (resampled[j + 1] != 0) {
                        resampled[j] /= resampled[j + 1];;
                    }
                    if (resampled[j + 4] != 0) {
                        resampled[j + 3] /= resampled[j + 4];
                    }
                }

                // second pass for mean variation  ( variance is too low)
                for (let i = 0; i < sampleCount; i += waveSkipLength) {
                    // in which bucket do we fall ?
                    let buckIndex = 0 | (canvasWidth * i / sampleCount);
                    buckIndex *= 6;
                    // positive or negative ?
                    let thisValue = d[i] || 0;
                    if (thisValue > 0) {
                        resampled[buckIndex + 2] += Math.abs(resampled[buckIndex] - thisValue);
                    } else if (thisValue < 0) {
                        resampled[buckIndex + 5] += Math.abs(resampled[buckIndex + 3] - thisValue);
                    }
                }

                // compute mean variation/variance now
                for (let i = 0, j = 0; i < canvasWidth; i++, j += 6) {
                    if (resampled[j + 1]) resampled[j + 2] /= resampled[j + 1];
                    if (resampled[j + 4]) resampled[j + 5] /= resampled[j + 4];
                }

                context.scale(1, 1);
                context.translate(0, 0);
                // context.beginPath();
                // context.globalCompositeOperation = 'copy';
                context.globalAlpha = 1;


                // Draw channel separator if on secong channel
                if (dIdx > 0) {
                    context.translate(0, 0);
                    context.strokeStyle = colorRgb[color || 'freedo'].replace(',1)', ',0.25)'); //'rgba(0,0,0, 0.5)';
                    context.lineWidth = 0.25;
                    context.beginPath();
                    context.moveTo(0, 50);
                    context.lineTo(canvasWidth, 50);
                    context.stroke();
                    context.lineWidth = 1;
                }

                // context.fillStyle = '#888' ;
                // context.fillRect(0,0,canvasWidth,canvasHeight );
                context.translate(0.5, (alld.length === 1 ? 50 : (dIdx === 0 ? 25 : 75)));
                context.scale(1, 100);

                const strokeColor = colorRgb[color || 'freedo'];

                const reductionFactor = (alld.length === 1 ? 0.8 : 0.4);
                for (var i = 0; i < canvasWidth; i++) {
                    const j = i * 6;

                    // Offset 0 : PositiveCumul  1: PositiveCount  2: PositiveVariance
                    //        3 : NegativeCumul  4: NegativeCount  5: NegativeVariance

                    // draw from positiveAvg + variance to negativeAvg + variance
                    context.strokeStyle = strokeColor; // '#F00';
                    context.beginPath();
                    context.moveTo(i, Math.min(0.5, (resampled[j] /*+ resampled[j + 2]*/)) * reductionFactor);
                    context.lineTo(i, Math.max(-0.5, (resampled[j + 3] /*- resampled[j + 5]*/)) * reductionFactor);
                    context.stroke();
                    // draw from positiveAvg - variance to negativeAvg - variance
                    // context.strokeStyle = "rgba(0, 0, 0, 0.05)";
                    // context.beginPath();
                    // context.moveTo(i, Math.max(-0.5, (resampled[j] - resampled[j + 2])) * reductionFactor);
                    // context.lineTo(i, Math.min(0.5, (resampled[j + 3] + resampled[j + 5])) * reductionFactor);
                    // context.stroke();
                    // // draw from positiveAvg - variance to positiveAvg + variance
                    // context.strokeStyle = strokeColor; // '#FFF';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j] - resampled[j+2] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j] + resampled[j+2] ) * reductionFactor );
                    // context.stroke();
                    // draw from negativeAvg + variance to negativeAvg - variance
                    // // context.strokeStyle = '#FFF';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j+3] + resampled[j+5] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j+3] - resampled[j+5] ) * reductionFactor );
                    // context.stroke();


                    //#region Old Logic
                    // // draw from positiveAvg - variance to negativeAvg - variance
                    // context.strokeStyle = strokeColor; // '#F00';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j] - resampled[j+2] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j +3] + resampled[j+5] ) * reductionFactor );
                    // context.stroke();
                    // // draw from positiveAvg - variance to positiveAvg + variance
                    // context.strokeStyle = strokeColor; // '#FFF';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j] - resampled[j+2] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j] + resampled[j+2] ) * reductionFactor );
                    // context.stroke();
                    // // draw from negativeAvg + variance to negativeAvg - variance
                    // // context.strokeStyle = '#FFF';
                    // context.beginPath();
                    // context.moveTo( i  , (resampled[j+3] + resampled[j+5] ) * reductionFactor );
                    // context.lineTo( i  , (resampled[j+3] - resampled[j+5] ) * reductionFactor );
                    // context.stroke();
                    //#endregion
                }
                context.restore();
                context.save();
            });
        } catch (e) {
            console.warn('Error while drawing waveform', e);
        }

        return true;
    }

    //#region Event Handlers
    @HostListener('click', ['$event'])
    onClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.pms.ui.selectedClipUid = this.clip.uuid;
        this.onSelect.emit(this.clip);
    }
    @HostListener('dblclick', ['$event'])
    onDoubleClick(event) {
        event.preventDefault();
        event.stopPropagation();
    }
    @HostListener('contextmenu', ['$event'])
    onContextMenu(event) {
        event.preventDefault();
        event.stopPropagation();
        this.pms.ui.selectedClipUid = this.clip.uuid;
        this.pms.ui.isPlayheadAttached = false;

        const offsetInClip = event.offsetX; // Where the mouse was clicked relative to the canvas
        const clipOffsetInTimeline = this.clip.position / this.pms.ui.pixelDuration; // Where the clip is positioned on the timeline
        const canvasInternalOffset = (this.clip.start / this.pms.ui.pixelDuration * -1); // The offset of the canvas in the clip

        const offsetInTimeline = clipOffsetInTimeline + offsetInClip + canvasInternalOffset;


        this.contextMenuPosition.x = (offsetInClip + canvasInternalOffset) + 'px';
        this.contextMenuPosition.y = (event.offsetY) + 'px';
        this.contextMenu.menuData = { 'at': offsetInTimeline * this.pms.ui.pixelDuration };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
        //#region HACKISH: A BIT OF A HACK TO MAKE RIGHT CLICKING THE BACKDROP CLOSE THE MENU
        const backdrop = document.getElementsByClassName(this.contextMenu.menu.backdropClass).item(0);
        this.contextMenu.menuClosed.asObservable().pipe(take(1)).subscribe(() => backdrop.removeEventListener("contextmenu", backdropRightClickHandler));
        const backdropRightClickHandler = (e) => {
            this.contextMenu.closeMenu();
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        if(backdrop) backdrop.addEventListener("contextmenu", backdropRightClickHandler);
        //#endregion
        this.audio.player.currentTime = offsetInTimeline * this.pms.ui.pixelDuration;
    }
    // @HostListener('window:copy', ['$event'])
    onCut = (event: KeyboardEvent, combo: string): boolean => {
        console.log('CUTING', this.clip);
        this.pms.ui.clipboard = { parent: this.pms.ui.selectedTrackUuid, clip: this.clip };
        return true;
    }
    onCopy = (event: KeyboardEvent, combo: string): boolean => {
        console.log('COPYING', this.clip);
        const clip = new Clip({ ...this.clip, uuid: uuidv4() });
        this.pms.ui.clipboard = { parent: this.pms.ui.selectedTrackUuid, clip: clip };
        return true;
    }

    onContextMenuSplitAt(at) {
        const left = new Clip({...this.clip, duration: at - this.clip.position});
        const right = new Clip({...this.clip, position: at, start: left.start + left.duration, duration: this.clip.duration - left.duration});
        right.uuid = uuidv4();

        this.onSplit.emit([this.clip, left, right]);
    }
    onContextMenuDelete() {
        this.onDelete.emit(this.clip);
    }

    onHandleMove(event, side: 'left' | 'right') {
        this.pms.ui.isPlayheadAttached = false;
        switch (side) {
            case 'left':
                const position = this.clip.position + (event.movementX * this.pms.ui.pixelDuration);
                const start = this.clip.start + (event.movementX * this.pms.ui.pixelDuration);
                const dur = this.clip.duration - (event.movementX * this.pms.ui.pixelDuration);
                if((event.movementX <= 0 && (position < 0 || start < 0))
                    || (event.movementX >= 0 && dur < 0.05)) return;
                this.clip.position = position;
                this.clip.start = start;
                this.clip.duration = dur;
                break;
            case 'right':
                const duration = this.clip.duration + (event.movementX * this.pms.ui.pixelDuration);
                if((event.movementX >= 0 && duration > this.track.buffer.duration - this.clip.start)
                    || (event.movementX <= 0 && duration < 0.05)) return;
                this.clip.duration = duration;
                break;
            default:
                throw new Error('Unknown handle side "' + side + '", this should not happen');
        }
    }
    onHandleMoved(event, side: 'left' | 'right') {
        this.onChange.emit(this.clip);
        this.drawWaveform(this.track.buffer, this.color, true);
    }
    //#endregion

}
