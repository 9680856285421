<!-- #region Timecode -->
<div  *ngIf="!comment?.parentId" timecode>
    {{ comment.timecode | timecode: 'h?:mm:ss' }}{{ comment.timecodeEnd ? ' - ' + (comment.timecodeEnd | timecode : 'h?:mm:ss') : '' }}
</div>
<!-- #endregion -->

<!-- #region Info -->
<div info>
    <span>
        <ng-container *ngIf="auth.currentUser; else anonymous">
            <img [src]="auth.currentUser.picture"/>
            {{ auth.currentUser.nameFirst + ' ' + auth.currentUser.nameLast }}</ng-container>
        <ng-template #anonymous>
            <img src="/assets/images/icons/anonymous.svg"/>
            <input #authorName type="text" [(ngModel)]="anonymousName" placeholder="Your name" [ntAutofocus]="!anonymousName" value="{{ anonymousName }}">
        </ng-template>
        · now
    </span>
</div>
<!-- #endregion -->

<!-- #region Comment-->
<div comment>
    <textarea #commentText placeholder="Add Comment..." (keypress)="onKeypress($event)"  [ntAutofocus]="!!auth.currentUser || !!anonymousName" [(ngModel)]="commentTextValue"></textarea>
</div>
<!-- #endregion -->

<!-- #region Audionote-->
<ng-container *ngIf="(audionotes$ | async)?.length > 0; else noAudionote">
    <ng-container *ngFor="let audionote$ of audionotes$ | async">
        <ng-container *ngIf="audionote$ | async as audionote">
            <nt-audionote #audionote [audionote]="audionote" [editMode]="true"></nt-audionote>
        </ng-container>
    </ng-container>
</ng-container>
<ng-template #noAudionote>
    <nt-audionote #audionote [editMode]="true"></nt-audionote>
</ng-template>
<!-- #endregion -->

<!-- #region Reply-->
<div reply>
    <div class="actions-buttons">
        <button class="cancel" (click)="cancel()" [disabled]="isPosting">Cancel</button>
        <button class="basic1" (click)="post()" [disabled]="!isValidForm() || isPosting">Post</button>
    </div>
</div>
<!-- #endregion -->
