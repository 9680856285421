
import { ApiObject } from '@app/core';
import { Color } from '../color';
import { IProjectData, Project } from '../project';
import { DrawingType, IDrawingData } from './idrawing-data';

export class Drawing extends ApiObject<IDrawingData> {
    
    public clone<Drawing extends ApiObject<IDrawingData>>(): Drawing {
        return this.shallowClone(this.constructor as { new (data: IDrawingData): Drawing } );
    }

    protected static readonly POST_FIELDS_MAP = {
        id: 'id', projectId: 'project_id', mediaId: 'media_id', // Not editable but needed to build the Api URL
        type: 'type', timecode: 'timecode', rowIndex: 'row_index', data: 'data', color: 'color'
    };

    //#region Database
    //#region Read-only
    public readonly id: number;
    public readonly projectId: number;
    public readonly mediaId: number;
    public readonly userId: number;
    //#endregion

    //#region Editable
    public type: DrawingType;
    public timecode: number;
    public rowIndex: number;
    public data: string;
    public color: Color;
    //#endregion

    //#region Metafields
    public readonly userCanUpdate: boolean;
    public readonly userCanDelete: boolean;
    public readonly project: Project;
    //#endregion

    //#region Timestamps
    public readonly createdAt: Date;
    public readonly updatedAt: Date;
    public readonly deletedAt: Date;
    //#endregion
    //#endregion

    //#region Construction Setters
    protected transformProjectForInit(data: IProjectData): Project {
        return new Project(data);
    }
    //#endregion
}
