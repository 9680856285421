import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nt-verify-email-dialog',
  templateUrl: './verify-email-dialog.component.html',
  styleUrls: ['./verify-email-dialog.component.scss']
})
export class VerifyEmailDialogComponent implements OnInit {

  public email: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.email) {
      this.email = this.data.email;
    }
  }

  public ResendVerificationEmail() {

  }
}
