import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HotkeyModule } from 'angular2-hotkeys';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { TimeagoDefaultFormatter, TimeagoFormatter, TimeagoModule } from 'ngx-timeago';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from './config.service';
import { CoreModule } from './core/core.module';
import { AuthModule, AuthInterceptor } from './features/auth/auth.module';
import { ProjectModule } from './features/project/project.module';
import { EMOJIS, emojis } from './features/project/emojis';
import { DemoInterceptor } from './features/project/interceptors';
import { AccountModule } from './features/account/account.module';


@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule, BrowserModule, BrowserAnimationsModule, FormsModule, FlexLayoutModule, ToastrModule.forRoot(), NgbModule,
    HttpClientModule,
    HotkeyModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    InlineSVGModule.forRoot(),
    TimeagoModule.forRoot({
        formatter: { provide: TimeagoFormatter, useClass: class extends TimeagoDefaultFormatter {
            format(then: number): string {
                const now = Date.now();
                if(Math.round(Math.abs(now - then)) < 60000) { return 'Just now'; }
                return super.format(then);
            }
        }}
    }),
    SocialLoginModule,
    AppRoutingModule,
    CoreModule,
    AuthModule,
    ProjectModule,
    AccountModule
  ],
  providers: [
    { provide: 'Window', useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DemoInterceptor, multi: true },
    ConfigService,
    { provide: EMOJIS , useValue: emojis },
    { provide: APP_INITIALIZER, useFactory: (config: ConfigService) => () => config.load(), deps: [ConfigService], multi: true },
    { provide: 'SocialAuthServiceConfig', useFactory: (config: ConfigService) => {
        return {
            autoLogin: false,
            providers: [
              {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider(
                  config.get('google').client_id
                )
              }
            ]
          } as SocialAuthServiceConfig
    }, deps: [ConfigService] }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
