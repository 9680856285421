import { Component, OnInit } from '@angular/core';
import { AuthService, AuthUser, Plan } from '@app/features/auth';
import { PlanService } from '@app/features/auth/services/plan.service';
import { SubscriptionService } from '@app/features/auth/services/subscription.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'nt-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  public partnerCode: string = '';

  private plans$: Observable<Array<Plan>>;
  public plans: Array<Plan>;
  
  public user$: Observable<Readonly<AuthUser>>;
  public title: string = '';

  constructor(
    protected planService: PlanService,
    protected auth: AuthService,
    private subscription: SubscriptionService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.plans$ = this.planService.getPlans();

    this.plans$.subscribe((allPlans: Array<Plan>) => {
      this.plans = allPlans;
    });

    this.user$ = this.auth.user$; // necessary to get the user.plan.name attribute

    this.user$.subscribe((user: AuthUser) => {
        this.title = user.isAnnualSubscription ? 'Account Information' : 'Upgrade your account';
    });
  }

  stackCodes(): void {
    this.subscription.stackCodes(this.partnerCode).subscribe((plan: Plan) => {
      if (plan) {
        this.toast.success('Code stacked successfully.');
      }
    },
    (error) => {
      console.error(error);
      this.toast.error(error.error.notice.message);
    });
  }

}
