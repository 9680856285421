<div class="tools">
    <span collapse *ngIf="!hasAllTracksCollapsed; else collapse" (click)="toggleAllTracksCollapsed()" [inlineSVG]="'/assets/images/icons/expanded.svg'"></span>
    <ng-template #collapse>
        <span collapse (click)="toggleAllTracksCollapsed()" [inlineSVG]="'/assets/images/icons/collapsed.svg'"></span>
    </ng-template>
    <span zoomOut (click)="pms.ui.incrementZoom(-0.1)" [inlineSVG]="'/assets/images/icons/magnifyminus.svg'"></span>
    <mat-slider zoom min="1" max="{{pms.ui.MAX_ZOOM}}" step="0.1"  [ngModel]="pms.ui.zoom$ | async" (input)="this.pms.ui.setZoom($event.value)" (change)="setZoom($event.value)"></mat-slider>
    <span zoomIn (click)="pms.ui.incrementZoom(0.1)" [inlineSVG]="'/assets/images/icons/magnifyplus.svg'"></span>
    <span follow (click)="pms.ui.isPlayheadAttached = !pms.ui.isPlayheadAttached" [ngClass]="{ following: pms.ui.isPlayheadAttached }" [inlineSVG]="'/assets/images/icons/followhead.svg'">F</span>
</div>

<div #ruler class="ruler" (click)="onRulerClicked($event)"
        [inlineSVG]="rulerUrl$ | async"
        [onSVGLoaded]="onSvgLoaded" (onSVGInserted)="onSvgInserted($event)" [prepend]="true">
    <div loopRange class="loop-range" *ngIf="audio.player.loop !== undefined" [ngClass]="{ active: audio.player.isLooping }">
        <div class="loop-range-bar" [ngStyle]="{ width: (loopEndOffset - loopStartOffset) + 'px', transform: 'translateX( ' + loopStartOffset + 'px )' }">
        <div class="loop-range-handle loop-range-handle-left"
            cdkDrag cdkDragBoundary=".loop-range" (cdkDragStarted)="onLoopControlDragStarted($event)" (cdkDragEnded)="onLoopControlDragEnded($event)"></div>
        <div #loopRangeHandleLeft="cdkDrag" class="loop-range-handle loop-range-handle-right"
            cdkDrag cdkDragBoundary=".loop-range" (cdkDragStarted)="onLoopControlDragStarted($event)" (cdkDragEnded)="onLoopControlDragEnded($event)"></div>
        </div>
    </div>
</div>

<!-- <ng-container *ngIf="audio.currentTime$ | async as currentTime"> -->
    <div playhead [style.opacity]="(((audio.player.currentTime$ | async) / (pms.ui.pixelDuration$ | async)) + (pms.ui.scrollOffset$ | async) ) < 0 ? 0 : 1" [ngStyle]="{ transform: 'translateX( ' + playheadOffset + 'px )'}">
        <svg width="21" height="12" viewBox="0 0 21 12">
            <path d="M0,0 H21 L11,12 L10,12 Z"/>
         </svg>
    </div>
<!-- </ng-container> -->
<div loopMarkers *ngIf="audio.player.loop !== undefined" [ngClass]="{ active: audio.player.isLooping }">
    <div loopStart [ngStyle]="{ transform: 'translateX( ' + loopStartOffset + 'px )' }"></div>
    <div loopEnd [ngStyle]="{ transform: 'translateX( ' + loopEndOffset + 'px )' }"></div>
</div>
