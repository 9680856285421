<nt-app-toolbar [user]="user$ | async"></nt-app-toolbar>

<div class="main-title">Choose a plan that works for you</div>

<div class="planmode-options">
    <span class="plan-mode monthly" [ngClass]="{active: !planPeriod}">MONTHLY</span>
    <span class="slide-container">
        <mat-slide-toggle class="slide" [(ngModel)]="planPeriod"></mat-slide-toggle>
    </span>
    <span class="plan-mode annual" [ngClass]="{active: planPeriod}">ANNUAL (SAVE 15%)</span>
</div>

<nt-plan [plans]="plans" [period]="!planPeriod ? 'month' : 'year'"></nt-plan>

<div class="subtitlebar">
    Enterprise or educational institution? <a class="contactUs" href="mailto:sales@notetracks.com"><b>Contact us</b></a>for more options
</div>
