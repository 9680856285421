import { HttpContextToken } from '@angular/common/http';

export const IDS = new HttpContextToken<Record<string, number>>(() => null);
export const REACTIONS = new HttpContextToken<{ counts: Record<number, number>, mine: Array<number> }>(() => null);

export * from './audionote-data.service';
export * from './comment-data.service';
export * from './drawing-data.service';
export * from './media-data.service';
export * from './project-data.service';
export * from './reaction-data.service';
export * from './share-data.service';
export * from './user-state-data.service';