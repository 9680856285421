import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { EMPTY, Observable } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';

import { ConfigService } from '@app/config.service';

import { AuthFormBaseComponent } from '../../components';
import { AuthComponent } from '../../auth.component';
import { AuthService } from '../../services';
import { AuthUser } from '../../types';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { VerifyEmailDialogComponent } from '../../components/verify-email-dialog/verify-email-dialog.component';

@Component({
  selector: 'nt-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss','./register-mobile.component.scss']
})
export class RegisterComponent extends AuthFormBaseComponent implements OnInit, AfterViewInit {

  // tslint:disable-next-line: variable-name
  public password_confirm: string;
  public inviteToken: string;
  @HostBinding('class.partnerForm') partnerFormCSSClass: boolean = false;

  constructor(
    protected authComponent: AuthComponent, protected auth: AuthService, protected socialAuth: SocialAuthService, public config: ConfigService,
    protected title: Title, private route: ActivatedRoute, private router: Router, private toast: ToastrService,
    private readonly dialog: MatDialog
  ) {
    super(title, authComponent, auth, socialAuth);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.title.setTitle('Notetracks - Sign-up');
    this.authComponent.showPricingLink = true;
    // this.authComponent.leftText = ['Get started', 'For free', 'Try Notetracks Pro for free', 'Collaboration.', 'Share projects and invite collaborators easily from anywhere in the world to chime in and get on the same wavelength.'];
    this.authComponent.leftText = ['', '', '', 'Collaboration.', 'Share projects and invite collaborators easily from anywhere in the world to chime in and get on the same wavelength.'];
    // tslint:disable-next-line: deprecation
    this.route.params.subscribe((params: Params) => {
      // tslint:disable-next-line: no-string-literal
      this.credentials.invitation_code = params['token'];
    });
    this.authComponent.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.partner = '';
    this.code = '';

    this.route.params.subscribe((params: Params) => {
      // tslint:disable-next-line: no-string-literal
      this.credentials.invitation_code = params['token'];

      if (params['partner'] && params['code']) {
        this.partner = params['partner'];
        this.code = params['code'];
        this.partnerFormCSSClass = true;
      } else if (!params['partner'] && params['code']) {
        this.partner = params['code'];
        this.code = null;
        this.partnerFormCSSClass = true;
      }

      if (this.partner != '' && this.code != '') {
        if (Object.keys(environment.partners).indexOf(this.partner) >= 0) {
          let partnerExist = environment.partners[this.partner];
          if (partnerExist) {
            this.partnerName = partnerExist.displayName;
          }
        }
      }
    });
  }

  submit(): void {
    if (this.form.valid && this.termsAccepted) {
      this.isProcessing = true;
      this.submitted = true;

      if (this.partner != '') {
        this.credentials.partner_code = this.code;
      }

      this.auth.register(this.credentials).pipe(
        finalize(() => {
          this.isProcessing = false;
        }),
        catchError((response: HttpErrorResponse, caught: Observable<AuthUser>) => {
          console.warn(response);
          this.error = response.error.notice.message || response.message;
          return EMPTY;
        })
      // tslint:disable-next-line: deprecation
      ).subscribe((user: AuthUser) => {
        this.toast.success('Account created! Please validate your email before logging in.');
        
        this.dialog.open(VerifyEmailDialogComponent, {
          panelClass: 'verify-email-modal',
          width: '600px',
          disableClose: true,
          data: { 
              email: user.email
          }
        }).afterClosed().subscribe();
        // this.router.navigate(['/auth/login'], {});
      });
    }
  }

  loginWithGoogle() {
    this.isProcessing = true;
    this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
}
