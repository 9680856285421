import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormComponent } from '@app/shared';
import { ProjectManagerService, ToolService } from '../../../services';
import { Color } from '../../../types';
import { DrawingBoardComponent } from '../drawing-board/drawing-board.component';

@Component({
  selector: 'nt-drawing-dialog',
  templateUrl: './drawing-dialog.component.html',
  styleUrls: ['./drawing-dialog.component.scss']
})
export class DrawingDialogComponent extends FormComponent implements OnInit {
  @ViewChild(DrawingBoardComponent) drawingBoard: DrawingBoardComponent;
  public error: string = null;
//   public data: string = null;
  public color: Color = 'freedo';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private readonly dialogRef: MatDialogRef<DrawingDialogComponent>,
    private pms: ProjectManagerService,
  ) {
    super();
  }

  ngOnInit(): void {
    //this.drawingStorage = new DrawingStorageService(undefined);
    this.color = this.pms.ui.drawingColor;
  }

  submit(): void {
    this.drawingBoard.drawingStorage.serialize((data, isEmpty) => {
      if (!isEmpty) {
        this.dialogRef.close(data);
      } else {
        
        this.drawingBoard.drawingStorage.onchange = () => {
            this.error = undefined;
            this.drawingBoard.drawingStorage.onchange = undefined;
        }
        this.error = 'The drawing is required.';
      }
    });
    
  }
}
