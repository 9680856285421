import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, IApiResponse } from '@app/core';
import { IShareData, IShareDataList, Share, ShareList } from '../../types';

@Injectable({
    providedIn: 'root'
})
export class ShareDataService {

    constructor(private api: ApiService) { }

    //#region CRUD Operation
    public list(params?: Record<string, any>): Observable<ShareList> {
        const { id } = params;
        return this.api.get<IShareDataList>(`project/${id}/share`).pipe(
            map((response: IApiResponse<IShareDataList>) => {
                const pl = new ShareList(response.result, Share);
                return pl;
            })
        );
    }

    public save(shareData: Partial<IShareData> & Record<string, any>): Observable<Share> {
        const { project_id, user_id } = shareData;
        const pathId = user_id ? `/${user_id}` : '';
        return this.api.post<Share, Partial<IShareData> & Record<string, any>>(`project/${project_id}/share${pathId}`, shareData).pipe(
            map((response: IApiResponse<null>) => {
                return new Share(response.result);
            })
        );
    }

    public delete(shareData: Partial<IShareData> & Record<string, any>): Observable<boolean> {
        const { project_id, user_id } = shareData;
        const pathId = user_id ? `/${user_id}` : '';
        return this.api.delete<boolean>(`project/${project_id}/share${pathId}`).pipe(
            map((response: IApiResponse<null>) => {
                return response.result;
            })
        );
    }
    //#endregion
}
