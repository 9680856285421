import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { EMPTY, empty, Observable, throwError } from 'rxjs';

import { LocalStorage, LocalStorageService } from 'ngx-webstorage';

import { IAuthResponse } from '../types';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../services';
import { ApiService, THROW_ERROR } from '@app/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  @LocalStorage('auth')
  private session: IAuthResponse;
  @LocalStorage('X-Anonymous-Token')
  private xAnonymousToken: string;

  constructor(private readonly auth: AuthService, private readonly api: ApiService, s: LocalStorageService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Intercept call if it's our own API
    if(request.url.startsWith(this.api.API_BASE_URL)) {
        return next.handle(this.injectAuthorizationHeader(request)).pipe(
            tap((response: HttpEvent<any>) => {
                if(response instanceof HttpResponse) {
                    this.xAnonymousToken = response.headers.get('X-Anonymous-Token');
                }
                return response;
            }),
            catchError((err: HttpErrorResponse) => {
                if(err.status === HttpStatusCode.Unauthorized) {
                    this.auth.revoke(!request.context.get(THROW_ERROR));
                } else if(err.status === HttpStatusCode.Forbidden && !this.auth.isAuthenticated) {
                    // Whenever access if forbidden but no user are logged in, we assume "Unauthenticated access is Forbidden"
                    const morphed = new HttpErrorResponse({...err, status: HttpStatusCode.Unauthorized });
                    return throwError(() => morphed);
                }
                return throwError(() => err);
            })
        );
    }

    return next.handle(request);
  }

  private injectAuthorizationHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const headers: Record<string, string> = {};
    if (this.session && this.session.authToken && !request.headers.get('Authorization')) {
        headers.Authorization =  `Basic ${(`authToken:${this.session.authToken}`).base64encode()}`;
    } else if(this.xAnonymousToken) {
        headers['X-Anonymous-Token'] = this.xAnonymousToken;
    }

    if(Object.keys(headers).length) {
        const authRequest = request.clone({
            setHeaders: headers
        });
        return authRequest;
    }

    return request;
  }
}
