import { ApiObject } from "@app/core";
import { IFolderData } from "./ifolder-data";

export class Folder extends ApiObject<IFolderData> {

    protected static readonly POST_FIELDS_MAP = {
        id: 'id', // Not editable but needed to build the Api URL
        name: 'name', parentFolderId: 'parent_folder_id'
    };

    //#region Database
    //#region Read-only
    id: number;
    user_id: number;
    hierarchy: string;
    //#endregion

    //#region Editable
    name: string;
    parent_folder_id: number;
    //#endregion

    //#region Timestamps
    created_at: string;
    updated_at: string;
    //#endregion
    //#endregion

    public clone<T extends ApiObject<IFolderData>>(): T {
        return this.shallowClone(this.constructor as { new (data: IFolderData): Folder } );
    }

}
