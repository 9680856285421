import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, AuthUser } from '@app/features/auth';
import { SubscriptionService } from '@app/features/auth/services/subscription.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { EditBillingDialogComponent } from '../../components/edit-billing-dialog/edit-billing-dialog.component';

@Component({
  selector: 'nt-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  public user$: Observable<Readonly<AuthUser>>;
  private user: AuthUser;

  constructor(
    protected readonly auth: AuthService,
    private readonly subscription: SubscriptionService,
    private readonly toast: ToastrService,
    private readonly dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.user$ = this.auth.user$;
    this.user$.subscribe((usr: AuthUser) => {
      this.user = usr;
    });
  }

  planCanceledPending(): Date {
    let periodEnd: number = this.user.planCancelAtPeriodEnd ? Date.parse(this.user.periodEnd) : null;
    let canceledAt: number = this.user.planCanceledAt && (this.user.planCanceledAt as any).date ? Date.parse((this.user.planCanceledAt as any).date) : Date.parse(this.user.planCanceledAt) || null;
    
    // We don't care about null since Date.now() is always going to be greater in the end
    return  canceledAt > periodEnd
        ? (canceledAt > Date.now() ? new Date(canceledAt) : (new Date(this.user.planCanceledAt) || null))
        : (periodEnd > Date.now() ? new Date(this.user.periodEnd) : null);
  }

  planCanceledEffecive(): Date {
    let periodEnd: number = this.user.planCancelAtPeriodEnd ? Date.parse(this.user.periodEnd) : null;
    let canceledAt: number = this.user.planCanceledAt && (this.user.planCanceledAt as any).date ? Date.parse((this.user.planCanceledAt as any).date) : Date.parse(this.user.planCanceledAt) || null;

    // We do care about null since null is always going to be smaller than Date.now()
    return  canceledAt > periodEnd
        ? (canceledAt && Date.now() > canceledAt ? new Date(canceledAt) : (new Date(this.user.planCanceledAt) || null))
        : (periodEnd && Date.now() > periodEnd ? new Date(this.user.periodEnd) : null);
  }

  cancel(): void {
    this.subscription.cancel().subscribe((result: boolean) => {
      this.toast.success('Billing updated successfully');
    },
    (error) => {
      this.toast.error(error.error.message);
    });
  }

  editBilling(): void {
    this.dialog.open(EditBillingDialogComponent, {
      autoFocus: false,
      panelClass: 'edit-billing-dialog',
      width: '640px',
      data: { }
    }).afterClosed().subscribe(() => {
      // do something
    });
  }
}
