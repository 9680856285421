import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';

import { combineLatest, Observable, Observer, throwError } from 'rxjs';
import { defaultIfEmpty, filter, map, switchMap, take } from 'rxjs/operators';
import { Comment } from '@app/features/project/types';
import { StoreService } from '@app/features/project/services';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private readonly http: HttpClient/*, protected readonly store: StoreService*/) { }

  /**
   * Download a file
   * 
   * @param file The file URL
   * @returns An Observable Blob
   */
   public downloadFile(file: URL): Observable<Blob>;
   public downloadFile(file: URL, options): Observable<Blob | HttpProgressEvent>;
   public downloadFile(file: URL, options = {}): Observable<Blob | HttpProgressEvent> {
     return this.http.get(file.toString(), {...options, ...{ responseType: 'arraybuffer', observe: 'events' }}).pipe(
       filter((event: HttpEvent<ArrayBuffer>) => event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.Response),
       map((event: HttpResponse<ArrayBuffer> | HttpProgressEvent) => {
           if(event.type === HttpEventType.Response) {
             return new Blob([event.body], { type: event.headers.get('content-type')});
           } else {
             return event;
           }
       })
     );
   }

//   private convertToWav(fileName: string, track: Audio, blob: Blob, timeOffset: number) {
//     var instance = this;
//     // track.name = fileName;
    
//     return new Observable((observer: Observer<any>) => {
//       if(track && blob) {
//         // Process Audio
//         var offlineAudioCtx = new OfflineAudioContext({
//           numberOfChannels: 2,
//           length: 44100 * track.source.buffer.duration,
//           sampleRate: 44100
//         });

//         // Audio Buffer Source
//         var soundSource = offlineAudioCtx.createBufferSource();
//         soundSource.buffer = track.source.buffer;

//         // Create Compressor Node
//         var compressor = offlineAudioCtx.createDynamicsCompressor();

//         compressor.threshold.setValueAtTime(-20, offlineAudioCtx.currentTime);
//         compressor.knee.setValueAtTime(30, offlineAudioCtx.currentTime);
//         compressor.ratio.setValueAtTime(5, offlineAudioCtx.currentTime);
//         compressor.attack.setValueAtTime(.05, offlineAudioCtx.currentTime);
//         compressor.release.setValueAtTime(.25, offlineAudioCtx.currentTime);

//         // Gain Node
//         var gainNode = offlineAudioCtx.createGain();
//         gainNode.gain.setValueAtTime(1, offlineAudioCtx.currentTime);
        
//         // Connect nodes to destination
//         soundSource.connect(compressor);
//         compressor.connect(gainNode);
//         gainNode.connect(offlineAudioCtx.destination);

//         // Reading the blob
//         var reader = new FileReader();

//         reader.onload = (event: ProgressEvent<FileReader>) => {
//             offlineAudioCtx.startRendering().then((renderedBuffer) => {
//                 var source = offlineAudioCtx.createBufferSource();
//                 source.buffer = renderedBuffer;

//                 let _blob = instance.bufferToWave(renderedBuffer, offlineAudioCtx.length);
//                 let _file = new File([_blob], track.name, { type: "audio/wav" });

//                 track.size = _file.size;
//                 // track.file = _file;
//                 track.source = source;
//                 track.duration = source.buffer.duration;
//                 track.sampleRate = source.buffer.sampleRate;
                
//                 observer.next(_file);
//                 observer.complete();
//               }).catch((err) => {
//                 throw throwError(err);
//               });

//         };

//         reader.readAsArrayBuffer(blob);
//         soundSource.start(); // diference of time between track sound and recording. needs to be calculated.
//       }
      
//     });

//   }

//   // Convert an AudioBuffer to a Blob using WAVE representation
//   private bufferToWave(abuffer, len) {
//     var numOfChan = abuffer.numberOfChannels,
//         length = len * numOfChan * 2 + 44,
//         buffer = new ArrayBuffer(length),
//         view = new DataView(buffer),
//         channels = [], i, sample,
//         offset = 0,
//         pos = 0;

//     // write WAVE header
//     setUint32(0x46464952);                         // "RIFF"
//     setUint32(length - 8);                         // file length - 8
//     setUint32(0x45564157);                         // "WAVE"

//     setUint32(0x20746d66);                         // "fmt " chunk
//     setUint32(16);                                 // length = 16
//     setUint16(1);                                  // PCM (uncompressed)
//     setUint16(numOfChan);
//     setUint32(abuffer.sampleRate);
//     setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
//     setUint16(numOfChan * 2);                      // block-align
//     setUint16(16);                                 // 16-bit (hardcoded in this demo)

//     setUint32(0x61746164);                         // "data" - chunk
//     setUint32(length - pos - 4);                   // chunk length

//     // write interleaved data
//     for(i = 0; i < abuffer.numberOfChannels; i++)
//         channels.push(abuffer.getChannelData(i));

//     while(pos < length) {
//         for(i = 0; i < numOfChan; i++) {             // interleave channels
//             sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
//             sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
//             view.setInt16(pos, sample, true);          // write 16-bit sample
//             pos += 2;
//         }
//         offset++                                     // next source sample
//     }

//     // create Blob
//     return new Blob([buffer], {type: "audio/wav"});

//     function setUint16(data) {
//         view.setUint16(pos, data, true);
//         pos += 2;
//     }

//     function setUint32(data) {
//         view.setUint32(pos, data, true);
//         pos += 4;
//     }
//   }
}
