import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { AudioService } from '@app/core';
import { NtDraggableMoveEvent } from '@app/shared';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ProjectManagerService } from '../../services';
import { Color, ColorRGBA, Media, Project } from '../../types';

enum MODE {
    WAVE = "WAVE", TRACKS = "TRACKS"
}

@Component({
    selector: 'nt-navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements AfterViewInit, OnDestroy {

    public readonly MODE = MODE;
    private readonly ColorRGBA = ColorRGBA;

    public rightControlPosition: number = 0;
    public leftControlPosition: number = 0;
    // private _canvasWidth$$: BehaviorSubject<number> = new BehaviorSubject(undefined);
    public canvasWidth: number;
    public canvasHeight: number;
    public zoomToggleMemory: number;
    private _mode$$: BehaviorSubject<MODE> = new BehaviorSubject(MODE.WAVE);
    public mode$: Observable<MODE> = this._mode$$.asObservable();

    private _destroy: Subject<void> = new Subject();

    @ViewChild('navBar') navBar: ElementRef<HTMLCanvasElement>;

    @Input('project') project: Project;
    @Input('isReady') isReady: boolean;

    constructor(
        private readonly pms: ProjectManagerService,
        private readonly audio: AudioService
    ) { }

    ngAfterViewInit(): void {
        let styles = window.getComputedStyle(this.navBar.nativeElement, null);
        setTimeout(() => {
            this.canvasWidth = parseInt(styles.getPropertyValue('width'));
            this.canvasHeight = parseInt(styles.getPropertyValue('height'));

            this.navBar.nativeElement.width = this.canvasWidth;
            this.navBar.nativeElement.height = this.canvasHeight;

            const zoom$ = this.pms.ui.zoom$
            const scrollOffsetFactor$ = this.pms.ui.scrollOffsetFactor$;
            combineLatest([zoom$, scrollOffsetFactor$]).pipe(takeUntil(this._destroy)).subscribe((results) => {
                const [zoom, offsetFactor] = results;

                const zoomWidth = this.canvasWidth / zoom;
                const zoomStart = this.canvasWidth * -offsetFactor;
                if (zoomStart + zoomWidth > this.canvasWidth) {
                    this.rightControlPosition = this.canvasWidth;
                    this.leftControlPosition = this.canvasWidth - zoomWidth;
                } else {
                    this.leftControlPosition = zoomStart;
                    this.rightControlPosition = zoomStart + zoomWidth;
                }

            });

            combineLatest([this.mode$, this.pms.media.list$]).pipe(takeUntil(this._destroy)).subscribe((results) => {
                const [mode, medias] = results;
                if(mode === MODE.WAVE) {
                    const longestMedia = medias.reduce((sum: Media, current: Media) => {
                        const currDuration = this.audio.player.getTrackDuration(current.uuid);
                        const sumDuration = this.audio.player.getTrackDuration(sum.uuid);
                        return currDuration > (sumDuration || -Infinity) ? current : sum
                    }, {} as Media);

                    if (longestMedia) {
                        this.audio.player.getTrackBuffer(longestMedia.uuid).pipe(
                            filter((audioBuffer: AudioBuffer) => audioBuffer instanceof AudioBuffer),
                            take(1)
                        ).subscribe((audioBuffer: AudioBuffer) => {
                            this.drawWave(audioBuffer, false, longestMedia.color);
                        });
                    }
                } else {
                    this.drawMultitrack(medias);
                }
            });
        });
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    //#region Event Listeners
    // @HostListener('window:resize', ['$event'])
    // onResize(event) { }

    onMouseMoveViewer(moveEvent: NtDraggableMoveEvent): void {
        this.pms.ui.zoom$.pipe(take(1)).subscribe((zoom) => {
            const zoomWidth = (this.canvasWidth / zoom);
            const newLeft = Math.max(0, this.leftControlPosition + moveEvent.movementX);
            const newRight = newLeft + zoomWidth;
    
            if (newLeft >= 0 && newRight <= this.canvasWidth) {
                const newOffset = newLeft / this.canvasWidth * -1;
                this.pms.ui.setScrollOffsetFactor(newOffset);
            }
        });
    }

    onMouseMoveLeft(moveEvent: NtDraggableMoveEvent): void {
        const minZoomWidth = (this.canvasWidth / this.pms.ui.MAX_ZOOM);
        const maxLeft = this.rightControlPosition - minZoomWidth;
        const newLeft = Math.min(maxLeft, Math.max(0, this.leftControlPosition + moveEvent.movementX));

        if (newLeft === this.leftControlPosition) { return; }

        if (newLeft + minZoomWidth <= this.rightControlPosition) {
            const newZoom = Math.min(this.pms.ui.MAX_ZOOM, this.canvasWidth / (this.rightControlPosition - newLeft));
            this.pms.ui.setZoom(newZoom);
            const newOffset = newLeft / this.canvasWidth * -1;
            this.pms.ui.setScrollOffsetFactor(newOffset);
            this.leftControlPosition = newLeft; // Set immediately so the handle moves even if we're on the edge, limidation of NtDraggable
        }
    }

    onMouseMoveRight(moveEvent: NtDraggableMoveEvent): void {
        const minZoomWidth = (this.canvasWidth / this.pms.ui.MAX_ZOOM);
        const minRight = this.leftControlPosition + minZoomWidth;
        const newRight = Math.min(this.canvasWidth, Math.max(minRight, this.rightControlPosition + moveEvent.movementX));

        if (newRight === this.rightControlPosition) { return; }

        if (newRight - minZoomWidth >= this.leftControlPosition) {
            const newZoom = Math.min(this.pms.ui.MAX_ZOOM, this.canvasWidth / (newRight - this.leftControlPosition));
            this.pms.ui.setZoom(newZoom);
            this.rightControlPosition = newRight; // Set immediately so the handle moves even if we're on the edge, limidation of NtDraggable
        }
    }

    toggleZoom(): void {
        this.pms.ui.zoom$.pipe(take(1)).subscribe((zoom) => {
            if(!this.zoomToggleMemory) {
                this.zoomToggleMemory = zoom;
                this.pms.ui.setZoom(1);
            } else {
                this.pms.ui.setZoom(this.zoomToggleMemory);
                this.zoomToggleMemory = undefined;
            }
        });
    }

    setMode(mode: MODE): void {
        this._mode$$.next(mode);
    }

    drawWave(buffer: AudioBuffer, stereo: boolean, color: Color): void {
        const context = this.navBar.nativeElement.getContext('2d');
        const data = buffer.getChannelData(0)

        // we 'resample' with cumul, count, variance
        // Offset 0 : PositiveCumul  1: PositiveCount  2: PositiveVariance
        //        3 : NegativeCumul  4: NegativeCount  5: NegativeVariance
        // that makes 6 data per bucket
        const bucketSize = 6;
        const sampleCount = data.length;
        const waveSkipLength = 1200;
        const resampled = new Float64Array(this.canvasWidth * bucketSize);
        let min = Infinity, max = -Infinity;

        // first pass for mean
        for (let i = 0; i < sampleCount; i += waveSkipLength) {
            // in which bucket do we fall ?
            let buckIndex = 0 | (this.canvasWidth * i / sampleCount);
            buckIndex *= 6;
            // positive or negative ?
            const thisValue = data[i] || 0;
            if (thisValue > 0) {
                resampled[buckIndex] += thisValue;
                resampled[buckIndex + 1] += 1;
            } else if (thisValue < 0) {
                resampled[buckIndex + 3] += thisValue;
                resampled[buckIndex + 4] += 1;
            }
            if (thisValue < min) min = thisValue;
            if (thisValue > max) max = thisValue;
        }

        // compute mean now
        for (let i = 0, j = 0; i < this.canvasWidth; i++, j += 6) {
            if (resampled[j + 1] != 0) {
                resampled[j] /= resampled[j + 1];
            }
            if (resampled[j + 4] != 0) {
                resampled[j + 3] /= resampled[j + 4];
            }
        }

        // second pass for mean variation  ( variance is too low)
        for (let i = 0; i < sampleCount; i += waveSkipLength) {
            // in which bucket do we fall ?
            let buckIndex = 0 | (this.canvasWidth * i / sampleCount);
            buckIndex *= 6;
            // positive or negative ?
            let thisValue = data[i] || 0;
            if (thisValue > 0) {
                resampled[buckIndex + 2] += Math.abs(resampled[buckIndex] - thisValue);
            } else if (thisValue < 0) {
                resampled[buckIndex + 5] += Math.abs(resampled[buckIndex + 3] - thisValue);
            }
        }

        // compute mean variation/variance now
        for (let i = 0, j = 0; i < this.canvasWidth; i++, j += 6) {
            if (resampled[j + 1]) resampled[j + 2] /= resampled[j + 1];
            if (resampled[j + 4]) resampled[j + 5] /= resampled[j + 4];
        }

        context.save();
        context.scale(1, 1);
        context.translate(0, 0);
        context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        context.beginPath();
        // context.globalCompositeOperation = 'copy';
        context.globalAlpha = 1;

        context.translate(0.5, this.canvasHeight / 2);
        context.scale(1, 100);

        const strokeColor = 'rgba(153,153,153,1)';
        const reductionFactor = 0.2;

        for (var i = 0; i < this.canvasWidth; i++) {
            const j = i * 6;

            // Offset 0 : PositiveCumul  1: PositiveCount  2: PositiveVariance
            //        3 : NegativeCumul  4: NegativeCount  5: NegativeVariance

            // draw from positiveAvg + variance to negativeAvg + variance 
            context.strokeStyle = strokeColor; // '#F00';
            context.beginPath();
            context.moveTo(i, (resampled[j] + resampled[j + 2]) * reductionFactor);
            context.lineTo(i, (resampled[j + 3] - resampled[j + 5]) * reductionFactor);
            context.stroke();
            // draw from positiveAvg - variance to negativeAvg - variance 
            context.strokeStyle = "rgba(0, 0, 0, 0.05)";
            context.beginPath();
            context.moveTo(i, (resampled[j] - resampled[j + 2]) * reductionFactor);
            context.lineTo(i, (resampled[j + 3] + resampled[j + 5]) * reductionFactor);
            context.stroke();
        }
        context.restore();
        // context.save();
    }

    drawMultitrack(medias: Array<Media>): void {
        var canvasCtx = this.navBar.nativeElement.getContext('2d');

        canvasCtx.save();
        canvasCtx.scale(1, 1);
        canvasCtx.translate(0, 0);
        canvasCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        canvasCtx.beginPath();
        canvasCtx.globalAlpha = 1;
        canvasCtx.lineWidth = 3;

        let validMedias = medias;

        let count = validMedias.length + 1;
        let spaceBetweenLines = this.canvasHeight / count;

        validMedias.forEach((media, index) => {
          let color = this.ColorRGBA[media.color || 'freedo'];
          const duration = this.audio.player.getTrackDuration(media.uuid);
          let lineWidth = duration / this.audio.player.duration * this.canvasWidth;

          canvasCtx.strokeStyle = color;
          canvasCtx.beginPath();
          canvasCtx.moveTo(0, (index + 1) * spaceBetweenLines);
          canvasCtx.lineTo(lineWidth, (index + 1) * spaceBetweenLines);
          canvasCtx.stroke();

        });

        canvasCtx.restore();
        canvasCtx.save();
    }
}
