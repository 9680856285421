
<nt-app-toolbar [user]="user$ | async"></nt-app-toolbar>

<div class="main-title">{{ title }}</div>

<div class="stackCode" *ngIf="!(user$ | async).isAnnualSubscription">
    <label>Stack more codes</label>
    <input type="text" class="code" [(ngModel)]="partnerCode" [placeholder]="(user$ | async).partnerInfo.displayName + 'code'">
    <button [disabled]="partnerCode == ''" (click)="stackCodes()">Stack</button>
</div>

<nt-plan [plans]="plans" [period]="'month'" class="unique"></nt-plan>
