import { ApiObject } from "@app/core";
import { IShareData } from ".";
import { IUserData, User } from "..";

export class Share extends ApiObject<IShareData> {
    protected static readonly POST_FIELDS_MAP = {
        id: 'id', // Not editable but needed to build the Api URL
        projectId: 'project_id', userId: 'user_id', permission: 'permission'
    };
    
    //#region Read-only
    public readonly id: number;
    //#endregion

    //#region Editable
    public projectId: number;
    public userId: number;
    public permission: 'r' | 'rc' | 'rw' | 'rm';
    //#endregion

    public user: User;

    //#region Timestamps
    createdAt: Date;
    updatedAt: Date;
    //#endregion
    //#endregion

    protected transformUserForInit(data: IUserData): User {
        return new User(data || {});
    }

    public clone<Share extends ApiObject<IShareData>>(): Share {
        return this.shallowClone(this.constructor as { new (data: IShareData): Share } );
    }
}
