import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { first, take, tap } from 'rxjs/operators';
import { StoreService } from '../services';
import { Audionote } from '../types';

@Injectable({
  providedIn: 'root'
})
export class AudionotesResolver implements Resolve<Array<Observable<Audionote>>> {
    constructor(private store: StoreService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Observable<Audionote>>> {
        let publicKey: string;
        let projectId: number;

        // Backward compatibility for links with projectId
        if (!isNaN(route.params.projectId)) {
            projectId = parseInt(route.params.projectId, 10);
        } else {
            publicKey = route.params.projectId;
            projectId = parseInt(publicKey.substring(0, publicKey.indexOf('_')), 16);
        }

        if (isNaN(projectId) && publicKey === 'demo') { projectId = -1; }

        this.store.audionote.fetch({ projectId, publicKey });
        return this.store.audionote.list({ projectId }).pipe(take(1));
    }
}
