import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { StoreService } from '../services';
import { Project } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ProjectsResolver implements Resolve<Array<Observable<Project>>> {

  constructor(private store: StoreService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Observable<Project>>> {
    this.store.project.fetch();
    return this.store.project.list().pipe(first());
  }
}
