import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '@app/config.service';

import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthComponent } from '../../auth.component';

import { AuthFormBaseComponent } from '../../components';
import { AuthService } from '../../services';

@Component({
    selector: 'nt-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', './login-mobile.component.scss']
})
export class LoginComponent extends AuthFormBaseComponent implements OnInit {

    constructor(
        protected readonly title: Title, protected readonly parent: AuthComponent, protected readonly auth: AuthService, protected readonly socialAuth: SocialAuthService,
        public readonly config: ConfigService
    ) {
        super(title, parent, auth, socialAuth);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.title.setTitle('Notetracks - Sign-in');
        this.authComponent.showPricingLink = false;
        this.authComponent.leftText = ['Welcome', 'Back'];
        this.authComponent.cd.detectChanges();
    }

    submit(): void {
        if (this.form.valid) {
            this.isProcessing = true;
            this.submitted = true;
            this.authenticate(this.credentials.email, this.credentials.password);
        }
    }

    loginWithGoogle() {
        this.isProcessing = true;
        this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

}
