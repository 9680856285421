import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpContext, HttpContextToken, HttpProgressEvent, HttpEventType, HttpEvent, HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, catchError, shareReplay, filter } from 'rxjs/operators';

import { ConfigService } from '@app/config.service';

import { IApiOptions, IApiResponse } from '..';

export const THROW_ERROR = new HttpContextToken<boolean>(() => false);
export const DEFAULT_API_OPTIONS = { observe: 'response' as 'response', responseType: 'json' as 'json', headers: new HttpHeaders({Accept: 'application/json'}) };

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public readonly API_BASE_URL: string;
  private readonly API_BASE_OPTIONS: IApiOptions;

  constructor(@Inject('Window') private window: Window, private config: ConfigService, private http: HttpClient, private router: Router) {
    const domain = this.config.get('api').host || this.window.location.host;
    const host = `${this.window.location.protocol.ctrim(':')}://${domain}`;
    const basePath = this.config.get('api').basePath.ctrim('/') + '/' + this.config.get('api').version.ctrim('/');
    this.API_BASE_URL = host.ctrim('/') + '/' + basePath.ctrim('/');
    this.API_BASE_OPTIONS = { observe: 'response' as 'response', responseType: 'json' as 'json', headers: new HttpHeaders({Accept: 'application/json'}) }
  }

  //#region Helpers
  private getCall(call: string): string {
    return `${this.API_BASE_URL}/${call.ctrim('/')}`;
  }

  private getOptions(options?: IApiOptions, throwError: boolean = false): IApiOptions {
    const mergedOptions = { ...this.API_BASE_OPTIONS, ...options };
    if(!mergedOptions.context) {
        mergedOptions.context = new HttpContext()
    }
    mergedOptions.context.set(THROW_ERROR, throwError) ;

    let headers = new HttpHeaders();
    if (this.API_BASE_OPTIONS.headers) {
      for (const header of this.API_BASE_OPTIONS.headers.keys()) {
        headers = headers.set(header, this.API_BASE_OPTIONS.headers.get(header));
      }
    }
    if (options && options.headers) {
      for (const header of options.headers.keys()) {
        headers = headers.set(header, options.headers.get(header));
      }
    }

    mergedOptions.headers = headers;

    return mergedOptions;
  }
  //#endregion

  //#region Verbs
  public get<T>(callAction: string, options?: IApiOptions, throwError: boolean = false): Observable<IApiResponse<T>> {
    const exceptionHandler = throwError ? this.exceptionPassthru : this.exceptionHandler;
    return this.http.get<IApiResponse<T>>(this.getCall(callAction), this.getOptions(options, throwError)).pipe(
      map(this.responseMapHandler),
      shareReplay(),
      catchError(exceptionHandler)
    );
  }

  public post<T, D>(callAction: string, data: D, options?: IApiOptions, throwError: boolean = false): Observable<IApiResponse<T>> {
    const exceptionHandler = throwError ? this.exceptionPassthru : this.exceptionHandler;
    return this.http.post<IApiResponse<T>>(this.getCall(callAction), data, this.getOptions(options, throwError)).pipe(
      map(this.responseMapHandler),
      shareReplay(),
      catchError(exceptionHandler)
    );
  }

    public upload<T, D>(callAction: string, data: D, options?: IApiOptions, throwError: boolean = false): Observable<IApiResponse<T> | HttpProgressEvent> {
        const exceptionHandler = throwError ? this.exceptionPassthru : this.exceptionHandler;
        return this.http.post<IApiResponse<T>>(this.getCall(callAction), data, { reportProgress: true, ...this.getOptions(options, throwError), observe: 'events' }).pipe(
            filter((event: HttpEvent<IApiResponse<T>>) => event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response),
            map((event: HttpResponse<IApiResponse<T>> | HttpProgressEvent) => {
                if(event.type === HttpEventType.Response) {
                    return this.responseMapHandler(event);
                } else {
                    return event;
                }
            }),
            shareReplay(),
            catchError(exceptionHandler)
        );
    }

  public put<T, D>(callAction: string, data: D, options?: IApiOptions, throwError: boolean = false): Observable<IApiResponse<T>> {
    const exceptionHandler = throwError ? this.exceptionPassthru : this.exceptionHandler;
    return this.http.put<IApiResponse<T>>(this.getCall(callAction), data, this.getOptions(options, throwError)).pipe(
      map(this.responseMapHandler),
      shareReplay(),
      catchError(exceptionHandler)
    );
  }

  public delete<T>(callAction: string, options?: IApiOptions, throwError: boolean = false): Observable<IApiResponse<T>> {
    const exceptionHandler = throwError ? this.exceptionPassthru : this.exceptionHandler;
    return this.http.delete<IApiResponse<T>>(this.getCall(callAction), this.getOptions(options, throwError)).pipe(
      map(this.responseMapHandler),
      shareReplay(),
      catchError(exceptionHandler)
    );
  }
  //#endregion

  //region Handlers
  private responseMapHandler = <T>(response: HttpResponse<IApiResponse<T>>): IApiResponse<T> => {
    return response.body || { code: response.status };
  }

  private exceptionPassthru = <T>(error: HttpErrorResponse, caught: Observable<IApiResponse<T>>): Observable<IApiResponse<T>> => {
    return throwError(() => error);
  }

  private exceptionHandler = <T>(error: HttpErrorResponse, caught: Observable<IApiResponse<T>>): Observable<IApiResponse<T>> => {
    if (error.status === HttpStatusCode.Unauthorized && !this.router.url.startsWith('/auth/')) { // @todo Make this configurable
      this.router.navigate(['/auth/login']);
    } else if (error.status === HttpStatusCode.Forbidden && this.router.url !== '/account/subscription'){
      this.router.navigate(['/account/subscription']);
    } else if (error.status === HttpStatusCode.PaymentRequired  && this.router.url !== '/account/subscription') {
        this.router.navigate(['/account/subscription']);
    }

    return throwError(() => error);
  }
  //endregion
}
