<ng-container *ngIf="['bongo', 'mic', 'trumpe', 'piano', 'saxo', 'guitar', 'tambou', 'disc'].includes(drawing.type)">
    <span drawing class="{{ drawing.type }} {{ drawing.color }}"></span>
</ng-container>
<ng-container *ngIf="drawing.type == 'text'">
    <p drawing #paragraph class="{{ drawing.type }} {{ drawing.color }}" 
        placeholder="Type..."
        (dblclick)="onEditText($event)" 
        (keypress)="onEditing($event)" [innerText]="drawing.data"></p>
</ng-container>
<ng-container *ngIf="drawing.type == 'simvec'">
    <nt-drawing-board class="simvec" mode="view" (dblclick)="onEditSimvec($event)" [data]="drawing.data" [color]="drawing.color"></nt-drawing-board>
</ng-container>
<span [hidden]="toolService.idSelectedDrawing != drawing.id" class="delete" (click)="onDelete()" [inlineSVG]="'/assets/images/icons/trash.svg'"></span>