import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.fullstoryTag) {
    const script = document.createElement('script');
    script.innerHTML = environment.fullstoryTag;
    document.head.appendChild(script);
}

if (environment.production || environment.staging) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then((ref: NgModuleRef<AppModule>) => {
    if(window['ngRef']) {
        window['ngRef'].destroy();
    }
    window['ngRef'] = ref;
}).catch(err => console.error(err));
