<!-- #region TOOLBAR-->
<nt-app-toolbar [logoLink]="undefined" [user]="auth.user$ | async">
    <input class="search" type="text" [ngModel]="search$ | async" (ngModel)="setSearch($event)" (ngModelChange)="setSearch($event)" placeholder="Search files from anywhere">
</nt-app-toolbar>
<!-- #endregion -->

<ul id="menu">
    <li (click)="setFilter('')" [ngClass]="{ active: (filter$ | async) === ''}"><span [inlineSVG]="'/assets/images/icons/folder.svg'"></span> All Files</li>
    <li (click)="setFilter('recents')" [ngClass]="{ active: (filter$ | async) === 'recents'}"><span [inlineSVG]="'/assets/images/icons/recent.svg'"></span>Recents</li>
    <!-- <li (click)="filter = 'starred'" [ngClass]="{ active: filter === 'starred'}"><span [inlineSVG]="'/assets/images/icons/star.svg'"></span>Starred</li> -->
    <li (click)="setFilter('shared')" [ngClass]="{ active: (filter$ | async) === 'shared'}"><span [inlineSVG]="'/assets/images/icons/reply.svg'"></span>Shared</li>
    <!-- <li (click)="filter = 'settings'" [ngClass]="{ active: filter === 'settings'}"><span [inlineSVG]="'/assets/images/icons/cog.svg'"></span>Settings</li> -->
</ul>

<div id="list">
    <h4 *ngIf="!(filter$ | async)">All Files</h4>
    <h4 *ngIf="(filter$ | async) === 'recents'">Recents</h4>
    <!-- <h4 *ngIf="(filter$ | async) === 'starred'">Starred</h4> -->
    <h4 *ngIf="(filter$ | async) === 'shared'">Shared With Me</h4>
    <span class="button-bar">
        <!-- <button class="dark" [inlineSVG]="'/assets/images/icons/folder.svg'" [prepend]="true">
            New Folder
        </button> -->
        <!-- <button class="dark sortable">
            A-Z <span [inlineSVG]="'/assets/images/icons/expanded.svg'"></span>
        </button> -->
        <button (click)="createProject()" [inlineSVG]="'/assets/images/icons/plus.svg'" [prepend]="true">
            <span>New Project</span>
        </button>
    </span>
    <span class="list" role="table">
        <div role="rowheader">
            <div role="columnheader"  class="name" (click)="setSort('name')">
                Name
                <mat-icon [hidden]="(sort$ | async).by !== 'name'" [ngClass]="{ desc: (sort$ | async).desc}" svgIcon="expanded"></mat-icon>
            </div>
            <div role="columnheader"  class="type" (click)="setSort('mediaType')">
                Type
                <mat-icon [hidden]="(sort$ | async).by !== 'mediaType'" [ngClass]="{ desc: (sort$ | async).desc}" svgIcon="expanded"></mat-icon>
            </div>
            <div role="columnheader"  class="modified" (click)="setSort('updatedAt')">
                Last Modified
                <mat-icon [hidden]="(sort$ | async).by !== 'updatedAt'" [ngClass]="{ desc: !(sort$ | async).desc}" svgIcon="expanded"></mat-icon>
            </div>
            <div role="columnheader" class="owner" (click)="setSort('userId')">
                Owner
                <mat-icon [hidden]="(sort$ | async).by !== 'userId'" [ngClass]="{ desc: (sort$ | async).desc}" svgIcon="expanded"></mat-icon>
            </div>
            <div role="columnheader" class="created" (click)="setSort('createdAt')">
                Created
                <mat-icon [hidden]="(sort$ | async).by !== 'createdAt'" [ngClass]="{ desc: !(sort$ | async).desc}" svgIcon="expanded"></mat-icon>
            </div>
            <div role="columnheader" class="actions"></div>
        </div>
        <!-- #region Demo-->
        <div *ngIf="demo" #row role="row" class="collapsed demo" (click)="openProject(demo)">
            <div role="cell"  class="name">
                <!-- <span [inlineSVG]="'/assets/images/icons/collapsed.svg'" (click)="expandProject($event, demo, row)"></span> -->
                <ng-container [ngSwitch]="!!demo?.picture">
                    <img *ngSwitchCase="true" [src]="demo.picture">
                    <img *ngSwitchCase="false" src="/assets/images/star-track.png">
                </ng-container> 
                {{ demo.name }}
            </div>
            <div role="cell"  class="type">
                <mat-icon [title]="demo?.mediaType.ucfirst()">{{ demo?.mediaType === 'video' ? 'theaters' : (demo?.mediaType === 'audio' ? 'graphic_eq' : 'question_mark') }}</mat-icon>
            </div>
            <div role="cell" class="modified">{{ demo.updatedAt ? (demo.updatedAt | timeago) : '' }}</div>
            <div role="cell" class="owner">
                <img class="owner" [src]="demo?.user?.picture">
                <span class="fullName">{{ demo?.user?.nameFirst }} {{ demo?.user?.nameLast }}</span>
                <span class="initials">{{ demo?.user?.nameFirst?.[0] }}{{ demo?.user?.nameLast?.[0] }}</span>
            </div>
            <div role="cell" class="created">{{ demo.createdAt ? (demo.createdAt | timeago) : '' }}</div>
            <div role="cell" class="actions">
                <!-- <span [ngClass]="{ hollow: true }" [inlineSVG]="'/assets/images/icons/star.svg'" (click)="starProject($event, demo)"></span> -->
                <!-- <span [inlineSVG]="'/assets/images/icons/reply.svg'" (click)="shareProject($event, demo)" [hidden]="!demo.userCanWrite ? true : null"></span> -->
                <span [inlineSVG]="'/assets/images/icons/trash.svg'" (click)="deleteProject($event, demo)" [hidden]="!demo.userCanWrite ? true : null"></span>
                <!-- <span [inlineSVG]="'/assets/images/icons/ellipses.svg'" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" (onMenuClose)="closeProjectMenu(demo)" (onMenuOpen)="openProjectMenu(demo)"></span>
                <mat-menu #menu="matMenu" yPosition="below" [overlapTrigger]="false" class="project-list-menu">
                    <button class="project-list-menu-item" mat-menu-item (click)="duplicateProject($event, demo)">
                        <span >Duplicate</span>
                    </button>
                    <button class="project-list-menu-item" mat-menu-item [hidden]="!demo.userCanWrite" (click)="editProject($event, demo)">
                        <span>Rename</span>
                    </button>
                </mat-menu> -->
            </div>
        </div>
        <!-- #endregion -->
        <!-- #region Projects-->
        <ng-container *ngIf="projects$ | async as projects">
            <ng-container *ngFor="let project of projects">
                <!-- <ng-container *ngIf="project$ | async as project"> -->
                    <div #row role="row" class="collapsed"  [ngClass]="{ duplicating: project.status === 'duplicating' }" (click)="openProject(project)">
                        <div role="cell" class="name">
                            <!-- <span [inlineSVG]="'/assets/images/icons/collapsed.svg'" (click)="expandProject($event, project, row)"></span> -->
                            <ng-container [ngSwitch]="!!project?.picture">
                                <img *ngSwitchCase="true" [src]="project.picture">
                                <img *ngSwitchCase="false" src="/assets/images/star-track.png">
                            </ng-container>{{ project.name }}
                            <div class="modified">Last Modified: {{ project.updatedAt ? (project.updatedAt | timeago) : '' }}</div>
                        </div>
                        <div role="cell" class="type">
                            <mat-icon [title]="project?.mediaType.ucfirst()">{{ project?.mediaType === 'video' ? 'theaters' : (project?.mediaType === 'audio' ? 'graphic_eq' : 'question_mark') }}</mat-icon>
                        </div>
                        <div role="cell" class="modified">{{ project.updatedAt ? (project.updatedAt | timeago) : '' }}</div>
                        <div role="cell" class="owner">
                            <img class="owner" [src]="project?.user?.picture">
                            <span class="fullName">{{ project?.user?.nameFirst }} {{ project?.user?.nameLast }}</span>
                            <span class="initials">{{ project?.user?.nameFirst?.[0] }}{{ project?.user?.nameLast?.[0] }}</span>
                        </div>
                        <div role="cell" class="created">{{ project.createdAt ? (project.createdAt | timeago) : '' }}</div>
                        <div role="cell" class="actions">
                            <!-- <span class="star" [ngClass]="{ hollow: true }" [inlineSVG]="'/assets/images/icons/star.svg'" (click)="starProject($event, project)"></span> -->
                            <span class="share" [inlineSVG]="'/assets/images/icons/reply.svg'" (click)="shareProject($event, project)" [hidden]="!project.userCanWrite ? true : null"></span>
                            <span class="delete" [inlineSVG]="'/assets/images/icons/trash.svg'" (click)="deleteProject($event, project)" [hidden]="!project.userCanWrite ? true : null"></span>
                            <span [inlineSVG]="'/assets/images/icons/ellipses.svg'" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" (onMenuClose)="closeProjectMenu(project)" (onMenuOpen)="openProjectMenu(project)"></span>
                            <mat-menu #menu="matMenu" yPosition="below" [overlapTrigger]="false" class="project-list-menu">
                                <button class="project-list-menu-item" mat-menu-item (click)="duplicateProject($event, project)">
                                    <span >Duplicate</span>
                                </button>
                                <button class="project-list-menu-item" mat-menu-item [hidden]="!project.userCanWrite" (click)="editProject($event, project)">
                                    <span>Rename</span>
                                </button>
                                <button class="project-list-menu-item share" mat-menu-item (click)="shareProject($event, project)" [hidden]="!project.userCanWrite ? true : null">
                                    <span >Share</span>
                                </button>
                                <button class="project-list-menu-item delete" mat-menu-item (click)="deleteProject($event, project)" [hidden]="!project.userCanWrite ? true : null">
                                    <span >Delete</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                <!-- </ng-container> -->
            </ng-container>
        </ng-container>
        <!-- #endregion -->
    </span>
</div>

<!--
    <td>{{ project.status == "duplicating" ? project.status + '...' :  project.createdAt | date: 'MMM d, y — h:mm a' | titlecase }}</td>
    <td>{{ project.status == "duplicating" ? '' : project.updatedAt | date: 'MMM d, y — h:mm a' | titlecase }}</td>
    <td class="menu" (mouseenter)="doOpenMenu(project.id)" (mouseleave)="doCloseMenu(project.id)">
        ...
        <ul *ngIf="openedMenu === project.id">
            <li [attr.disabled]="!project.userCanWrite ? true : null" (click)="doDelete(project)" class="delete">Delete</li>
        </ul>
    </td>
-->

