import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth.component';
import {
  LoginComponent, RegisterComponent, ValidationComponent, ForgotPasswordComponent,
  ResetPasswordComponent, InvitationComponent, LogoutComponent
} from './pages';
import { IsAuthenticatedGuard } from './guards';


const routes: Routes = [
  //#region Redirect legacy urls
  { path: 'p/login', redirectTo: 'auth/login' },
  { path: 'p/register', redirectTo: 'auth/register' },
  { path: 'p/register/:token', redirectTo: 'auth/register/:token' },
  { path: 'p/validation/:token', redirectTo: 'auth/validation/:token' },
  { path: 'p/forgot', redirectTo: 'auth/forgot-password' },
  { path: 'p/reset/:token', redirectTo: 'auth/reset-password/:token' },
  { path: 'p/invitation/:projectId/:token', redirectTo: 'auth/invitation/:projectId/:token' },
  { path: 'p/partner/:partner', redirectTo: 'auth/partner/:partner' },
  { path: 'p/partner/:partner/:code', redirectTo: 'auth/partner/:partner/:code' },
  //#endregion
  //#region Redirect legacy email urls
  { path: 'app/email/invitation/:projectId/:token', redirectTo: 'auth/invitation/:projectId/:token' },
  { path: 'app/email/validation/:token', redirectTo: 'auth/validation/:token' },
  { path: 'app/email/reset/:token', redirectTo: 'auth/reset-password/:token' },
  { path: 'app/logout', redirectTo: 'auth/logout' },
  //#endregion
  //#region Routes
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'register/:token', component: RegisterComponent },
      { path: 'partner/:code', component: RegisterComponent },
      { path: 'partner/:partner/:code', component: RegisterComponent },
      { path: 'validation/:token', component: ValidationComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
      { path: 'invitation/:projectId/:token', component: InvitationComponent },
      { path: 'logout', component: LogoutComponent, canActivate: [IsAuthenticatedGuard] },
    ]
  }
  //#endregion
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
