import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {

  transform(values: any[], property: string | string[] = '', reverse: boolean = false): any {
    if (!values){
      return values;
    }

    if (values instanceof Array){
      const sorted = this.sortArray(values, property);
      return reverse ? sorted.reverse() : sorted;
    }

    return null;
  }

  private sortArray(values: any[], property: string | string[]): any[] {
    return values.sort((a: any, b: any) => {
      if (!property){
        return a > b ? 1 : (a < b ? -1 : 0);
      }

      if (property instanceof Array){
        for (const v of property) {
          if (this.getDescendantProp(a, v) > this.getDescendantProp(b, v)){
            return 1;
          }
          else if (this.getDescendantProp(a, v) < this.getDescendantProp(b, v)){
            return -1;
          }
        }
        return 0;
      }

      return a[property as string] > b[property as string] ? 1 : (a[property as string] < b[property as string] ? -1 : 0);
    });
  }

  private getDescendantProp(obj: any, desc: string): any {
    const arr = desc.split('.');
    // tslint:disable-next-line: curly no-conditional-assignment
    while (arr.length && (obj = obj[arr.shift() as string]));

    return obj;
  }
}
