import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AuthService } from '../services';

type BoolOrUrl = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): BoolOrUrl {
    return this.auth.authenticated$.pipe(
        take(1),
        switchMap((authenticated: boolean) => {
            if(authenticated) {
                return of(authenticated);
            }
            
            this.auth.postAuthenticationUrl = this.router.parseUrl(state.url);
            return of(this.router.createUrlTree(['/auth/login'], {}));
        }),
    );
  }
}
