import { Injectable } from "@angular/core";
import { ApiService, IApiResponse } from "@app/core";
import { LocalStorageService } from "ngx-webstorage";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { AuthResponse, AuthService, AuthUser } from "..";
import { IPlanData, Plan } from "../auth.module";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    constructor(private readonly api: ApiService, private readonly storage: LocalStorageService, private auth: AuthService) {}

    stackCodes(partner_code: string): Observable<Plan> {
        // const options: IApiOptions = { observe: 'response' as 'response' };

        // const mockPlan = new Plan({
        //     code: "appsumo_40",
        //     created_at: "2022-05-19T12:00:50-04:00",
        //     id: 48,
        //     interval: "month",
        //     limit_project: 1150,
        //     limit_storage: 199680,
        //     limit_traffic: 102400,
        //     price: 0,
        //     sequence: 100,
        //     ttl: null,
        //     updated_at: "2022-05-19T12:00:50-04:00",
        //     visible: false,
        // })

        return this.api.post<IPlanData, { partner_code: string }>('user/plan/partner/code', { partner_code }).pipe(
            map((response: IApiResponse<IPlanData>) => new Plan(Object.assign({ name: `plans.name.${response.result.code}`}, response.result))),
            tap((plan: Plan) => {
                const session: AuthResponse = this.storage.retrieve('auth');
                const mergedPlan = Object.assign({}, session.user.plan, new Plan(plan));
                const mergedUser = Object.assign({}, session.user, { plan: mergedPlan });
                const mergedSession = Object.assign({}, session, { user: mergedUser });
                this.storage.store('auth', mergedSession);
            }),
            catchError((error: any) => {
                return throwError(error) as Observable<any>
            })
        );
    }

    validatePromocode(stripe_promocode: string): any {
        return this.api.post<any, { stripe_promocode: string }>('plans/coupon', { stripe_promocode });
    }

    updateBilling(apiForm: any): Observable<Plan> {
        return this.api.post<IPlanData, any>('user/plan', apiForm).pipe(
            map((response: IApiResponse<IPlanData>) => new Plan(response.result)),
            tap((plan: Plan) => {
                this.auth.fetchUser().subscribe((user:AuthUser) => {
                    const session: AuthResponse = this.storage.retrieve('auth');
                    const mergedUser = Object.assign({}, session.user, user);
                    const mergedSession = Object.assign({}, session, { user: mergedUser });
                    this.storage.store('auth', mergedSession);
                });

                // const session: AuthResponse = this.storage.retrieve('auth');
                // const mergedPlan = Object.assign({}, session.user.plan, new Plan(plan));
                // const mergedUser = Object.assign({}, session.user, { plan: mergedPlan });
                // const mergedSession = Object.assign({}, session, { user: mergedUser });
                // this.storage.store('auth', mergedSession);
            }),
            catchError((error: any) => {
                return throwError(error) as Observable<any>
            })
        );
    }

    subscribePlan(form, apiForm, promocode): Observable<Plan> {
        return this.api.post<IPlanData, any>('user/plan', apiForm).pipe(
            map((response: IApiResponse<IPlanData>) => new Plan(response.result)),
            tap((plan: Plan) => {
                this.auth.fetchUser().subscribe((user:AuthUser) => {
                    const session: AuthResponse = this.storage.retrieve('auth');
                    const mergedPlan = Object.assign({}, session.user.plan, new Plan(plan));
                    const mergedUser = Object.assign({}, Object.assign({}, session.user, user), { plan: mergedPlan });;
                    const mergedSession = Object.assign({}, session, { user: mergedUser });
                    this.storage.store('auth', mergedSession);
                });
                
            }),
            catchError((error: any) => {
                return throwError(error) as Observable<any>
            })
        );
    }

    upgradePlanFirstTime(form: any): Observable<Plan> {
        return this.api.post<IPlanData, any>('user/plan', form).pipe(
            map((response: IApiResponse<IPlanData>) => new Plan(response.result)),
            tap((plan: Plan) => {
                
                this.auth.fetchUser().subscribe((user:AuthUser) => {
                    const session: AuthResponse = this.storage.retrieve('auth');
                    const mergedPlan = Object.assign({}, session.user.plan, new Plan(plan));
                    const mergedUser = Object.assign({}, Object.assign({}, session.user, user), { plan: mergedPlan });;
                    const mergedSession = Object.assign({}, session, { user: mergedUser });
                    this.storage.store('auth', mergedSession);
                });
                
            }),
            catchError((error: any) => {
                return throwError(error) as Observable<any>
            })
        );
    }

    upgradePlan(plan_id: number): Observable<Plan> {
        return this.api.post<IPlanData, { plan_id: number }>('user/plan', { plan_id }).pipe(
            map((response: IApiResponse<IPlanData>) => new Plan(response.result)),
            tap((plan: Plan) => {
                const session: AuthResponse = this.storage.retrieve('auth');
                const mergedPlan = Object.assign({}, session.user.plan, new Plan(plan));
                const mergedUser = Object.assign({}, session.user, { plan: mergedPlan });
                const mergedSession = Object.assign({}, session, { user: mergedUser });
                this.storage.store('auth', mergedSession);
            }),
            catchError((error: any) => {
                return throwError(error) as Observable<any>
            })
        );
    }

    downgradePlan(plan_id: number): Observable<Plan> {
        return this.api.post<IPlanData, { plan_id: number }>('user/plan', { plan_id }).pipe(
            map((response: IApiResponse<IPlanData>) => new Plan(response.result)),
            tap((plan: Plan) => {
                const session: AuthResponse = this.storage.retrieve('auth');
                const mergedPlan = Object.assign({}, session.user.plan, new Plan(plan));
                const mergedUser = Object.assign({}, session.user, { plan: mergedPlan });
                const mergedSession = Object.assign({}, session, { user: mergedUser });
                this.storage.store('auth', mergedSession);
            }),
            catchError((error: any) => {
                return throwError(error) as Observable<any>
            })
        );
    }

    cancel(): Observable<boolean> {
        return this.api.delete<boolean>('user/plan').pipe(
            map((response: IApiResponse<boolean>) => {
                this.auth.fetchUser().subscribe((user:AuthUser) => {
                    const session: AuthResponse = this.storage.retrieve('auth');
                    const mergedUser = Object.assign({}, session.user, user);
                    const mergedSession = Object.assign({}, session, { user: mergedUser });
                    this.storage.store('auth', mergedSession);
                });
                return response.result;
          }));
    }
}