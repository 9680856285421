import { ApiObject } from "@app/core";
import { IUsageData, IUsageItemData, IUsagePeriodData } from "./iusage-data";

export class Usage extends ApiObject<IUsageData> {
    public period: UsagePeriod;
    public current: UsageItem;
    public limit: UsageItem;
    public left: UsageItem;

    public get hasProjectLeft(): boolean {
        return this.limit.project === null || this.left.project > 0;
    }

    public clone<Usage extends ApiObject<IUsageData>>(): Usage {
        return this.shallowClone(this.constructor as { new (data: IUsageData): Usage } );
    }
}

export class UsageItem extends ApiObject<IUsageItemData> {
    public project: number;
    public storage: number;
    public traffic: number;

    public clone<UsageItem extends ApiObject<IUsageItemData>>(): UsageItem {
        return this.shallowClone(this.constructor as { new (data: IUsageItemData): UsageItem } );
    }
}

export class UsagePeriod extends ApiObject<IUsagePeriodData> {
    public dateFrom: string;
    public dateTo: string;

    public clone<UsagePeriod extends ApiObject<IUsagePeriodData>>(): UsagePeriod {
        return this.shallowClone(this.constructor as { new (data: IUsagePeriodData): UsagePeriod } );
    }
}