<button mat-dialog-close [ngStyle]="{'z-index': 1050, display: 'block'}">
    <span [inlineSVG]="'assets/images/icons/x.svg'"></span>
</button>
<h3 mat-dialog-title>
    <span>{{ title }}</span>
</h3>
<div mat-dialog-content>
    <span class="subtitle" [innerHTML]="subtitle"></span>
    <div class="signInForm" [hidden]="step != 1 && step != 3 && step != 4">
        <div class="section email-section">
            <label>Email</label>
            <input #txtEmail type="text" id="txtEmail" class="email" [(ngModel)]="email" placeholder="Email address" [disabled]="step == 3 || step == 4">
        </div>
        <div class="section first-name-section" [hidden]="step != 3">
            <label>First name</label>
            <input #txtFirstName type="text" id="txtFirstName" class="firstName" [(ngModel)]="firstName" placeholder="First name">
        </div>
        <div class="section last-name-section" [hidden]="step != 3">
            <label>Last name</label>
            <input type="text" id="txtLastName" class="lastName" [(ngModel)]="lastName" placeholder="Last name">
        </div>
        <div class="section password-section" [hidden]="step != 3 && step != 4">
            <label>Password</label>
            <input #txtPassword type="password" id="pwdPassword" class="password" [(ngModel)]="password" placeholder="Password">
        </div>
    </div>
    <div class="section email-verification" [hidden]="step != 2">
        <input #txtDigit1 type="text" placeholder="0" maxlength="1" class="digit digit1" (input)="doAction()" (paste)="pasteCode($event)" [(ngModel)]="digits.first" />
        <input #txtDigit2 type="text" placeholder="0" maxlength="1" class="digit digit2" (input)="doAction()" [(ngModel)]="digits.second" />
        <input #txtDigit3 type="text" placeholder="0" maxlength="1" class="digit digit3" (input)="doAction()" [(ngModel)]="digits.third" />
        <!--<span class="digit-separator">-</span>-->
        <input #txtDigit4 type="text" placeholder="0" maxlength="1" class="digit digit4" (input)="doAction()" [(ngModel)]="digits.fourth" />
        <input #txtDigit5 type="text" placeholder="0" maxlength="1" class="digit digit5" (input)="doAction()" [(ngModel)]="digits.fifth" />
        <input #txtDigit6 type="text" placeholder="0" maxlength="1" class="digit digit6" (input)="doAction()" [(ngModel)]="digits.sixth" />
    </div>
    <div class="section additional-info">
        <span>{{ message }}</span>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button class="action" (click)="doAction()">{{ action }}</button>
</div>
