import { ApiObject } from '@app/core';
import { Color } from '../color';
import { IProjectData, Project } from '../project';
import { IMediaData } from './imedia-data';
import { IMetadata } from './imetadata';
import { Metadata } from './metadata';


export class Media extends ApiObject<IMediaData> {
    protected static readonly POST_FIELDS_MAP = {
        id: 'id', projectId: 'project_id', // Not editable but needed to build the Api URL
        name: 'name', sequence: 'sequence', color: 'color', volume: 'volume', isActive: 'is_active',
        flagGreen: 'flag_green', flagOrange: 'flag_orange', flagRed: 'flag_red', file: 'file', metadata: 'metadata'
    };

    //#region Database
    //#region Read-only
    public readonly id: number;
    public readonly projectId: number;
    public readonly fMain: URL;
    public readonly fMainDl: URL;
    public readonly fOriginal: URL;
    public readonly fOriginalDl: URL;
    public readonly fSvg: URL;
    public readonly status: string;
    public readonly isMain: boolean;
    public readonly abr: number;
    public readonly originalFilesize: number;
    // public readonly duration: number;
    //#endregion

    //#region Editable
    public name: string;
    public sequence: number;
    public color: Color;
    public volume: number;
    public isActive: boolean;
    public mediaType: 'audio' | 'video';
    public flagGreen: boolean;
    public flagOrange: boolean;
    public flagRed: boolean;
    public metadata: Metadata;
    //#endregion

    //#region Metafields
    public readonly project: Project;
    //#endregion

    //#region Timestamps
    public readonly processedAt: Date;
    public readonly createdAt: Date;
    public readonly updatedAt: Date;
    public readonly deletedAt: Date;
    //#endregion
    //#endregion

    //#region Construction Setters
    protected transformFMainForInit(data: string, set: Partial<IMediaData>): URL {
        return this.transformURLForInit(data, set);
    }
    protected transformFMainDlForInit(data: string, set: Partial<IMediaData>): URL {
        return this.transformURLForInit(data, set);
    }
    protected transformFOriginalForInit(data: string, set: Partial<IMediaData>): URL {
        return this.transformURLForInit(data, set);
    }
    protected transformFOriginalDlForInit(data: string, set: Partial<IMediaData>): URL {
        return this.transformURLForInit(data, set);
    }
    protected transformFSvgForInit(data: string, set: Partial<IMediaData>): URL {
        return this.transformURLForInit(data, set);
    }
    protected transformMetadataForInit(data: IMetadata, set: Partial<IMediaData>): Metadata {
        return new Metadata(data);
    }
    protected transformProjectForInit(data: IProjectData, set: Partial<IMediaData>): Project {
        return new Project(data);
    }
    protected transformProcessedAtForInit(data: string, set: Partial<IMediaData>): Date {
        return this.transformDateForInit(data, set);
    }
    //#endregion

    //#region Postable Transformers

    // protected transformMetadataForPost(name: string): string {
    //     try {
    //         return JSON.stringify(this[name]);
    //     } catch(e) {
    //         return null;
    //     }
    // }
    //#endregion

    public clone<Media extends ApiObject<IMediaData>>(): Media {
        return this.shallowClone(this.constructor as { new (data: IMediaData): Media } );
    }
}
