<!-- start: APP -->
<div id="app-toolbar">
    <div class="actionbar" fxLayout="row" fxLayoutAlign="start center">
        <a fxFlex class="logo pull-left" [routerLink]="['/']"></a>
        <div fxFlex fxLayout="row" fxLayoutAlign="center center"></div>
        <div fxFlex class="pull-right navigation" fxLayout="row" fxLayoutAlign="end center">
            <span class="btn-register" *ngIf="router.url.endsWith('/auth/login')">
                <label for="registerButton">Don't have an account?</label>
                <button class="w120" id="registerButton" [routerLink]="['/auth/register']" i18n>Sign up</button>
            </span>
            <span class="btn-login" style="display: none;">
                <label for="loginButton">Already have an account?</label>
                <button class="w120" id="loginButton" [routerLink]="['/auth/login']" i18n>Log In</button>
            </span>
        </div>
    </div>
</div>

<!--<toaster-container toaster-options="{-->
  <!--'close-button': true,-->
  <!--'debug': true,-->
  <!--'position-class': 'toast-top-full-width',-->
  <!--'onclick': null,-->
  <!--'show-duration': '300',-->
  <!--'hide-duration': '800',-->
  <!--'time-out': '5000',-->
  <!--'extended-time-out': '1000',-->
  <!--'show-easing': 'swing',-->
  <!--'hide-easing': 'linear',-->
  <!--'show-method': 'fadeIn',-->
  <!--'hide-method': 'fadeOut'-->
<!--}"></toaster-container>-->
<div id="app-content">
    <div fxLayout="row">
        <div fxFlex="50">
            <div fxLayout="column">
                <ng-container *ngIf="leftText; else noLines">
                    <h2 *ngIf="leftText[0]" class="line1">{{ leftText[0] }}</h2>
                    <h2 *ngIf="leftText[1]" class="line2">{{ leftText[1] }}</h2>
                    <p *ngIf="leftText[2]" class="line3">{{ leftText[2] }}</p>
                </ng-container>
                <ng-template #noLines>
                    <span class="line1 line2 line3"></span><!-- Otherwise styles won't showup with optimization on-->
                </ng-template>
                <!-- <a *ngIf="showPricingLink" [routerLink]="['subscription/plans']"><span i18n>View pricing</span>&nbsp;&nbsp;&nbsp;<i class="material-icons">trending_flat</i></a> -->
            </div>
        </div>
        <div fxFlex="50">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="message">
        <span *ngIf="leftText && leftText[3]" class="line4">{{ leftText[3] }}</span>
        <span *ngIf="leftText && leftText[4]" class="line5">&nbsp;{{ leftText[4] }}</span>
    </div>
</div>
