import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output, Renderer2, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'nt-marker',
    templateUrl: './marker.component.html',
    styleUrls: ['./marker.component.scss']
})
export class MarkerComponent implements OnChanges {

    /**
     * The offsetX to place the marker at
     */
    @Input('offsetX') offsetX: number;
    /**
     * The offsetX of the matching end marker
     */
    @Input('offsetXEnd') offsetXEnd: number;

    @HostBinding('style.transform') transform: string;
    @ViewChild('area') area: ElementRef<HTMLDivElement>;

    constructor(private readonly element: ElementRef<HTMLElement>, private readonly renderer: Renderer2) { }

    //#region Lifecycle
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.offsetX instanceof SimpleChange) {
            this.transform = `translateX(${this.offsetX}px)`;
        }
    }
    //#endregion

}
