import { ApiObject } from '@app/core/core.module';
import { IApiListResult } from '../';

export abstract class ApiList<T extends ApiObject<I>, I> implements IApiListResult<T> {
    public readonly count: number;
    public readonly skip: number;
    public readonly limit: number;
    public readonly items: T[];

    constructor(list: IApiListResult<I>, TCreator: new (item) => T) {
        this.count = list.count;
        this.skip = list.skip;
        this.limit = list.limit;
        this.items = list.items.map((item: I) => new TCreator(item));
    }
}
