import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Emoji, EMOJIS } from '../emojis';

@Pipe({
  name: 'emoji'
})
export class EmojiPipe implements PipeTransform {
  constructor(@Inject(EMOJIS) private readonly emojis) {}

  transform(value: number | string | Emoji, count?: number): string {
    if(typeof value === 'string') {
        value = parseInt(value)
    }
    const emoji = typeof value === 'object' ? value : this.emojis.find((emoji: Emoji) => emoji.index == value);
    return `${emoji.htmlcode} ${count ? count : ''}`;
  }

}
