<nt-app-toolbar [user]="user$ | async"></nt-app-toolbar>

<div class="main container">
    <div class="text-center h1 font-weight-bold">Thank you for deciding to upgrade!</div>
    <form [formGroup]="paymentForm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 leftPanel">
                    <div class="subtitle">Payment Method</div>
                    <div class="row">&nbsp;</div>
                    <div class="container">
                        <div class="row">
                            <div class="col card-holder-name">
                                <mat-form-field appearance="fill">
                                    <mat-label>Card holder name</mat-label>
                                    <input matInput type="text" id="txtCardHolderName" formControlName="txtCardHolderName" [(ngModel)]="cardHolderName">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col promo-code">
                                <mat-form-field appearance="fill" [ngClass]="{ valid: promocodeValid === true, invalid: promocodeValid === false }">
                                    <mat-label>Promo code</mat-label>
                                    <input (focus)="onPromocodeChange()" matInput type="text" id="txtPromoCode" formControlName="txtPromoCode" [(ngModel)]="promoCode">
                                </mat-form-field>
                            </div>
                            <div class="col-2 apply-promocode"><button [disabled]="!promoCode" (click)="checkPromoCode()">Apply</button></div>
                            <div class="col"></div>
                        </div>
                        <div class="row stripe-section">
                            <div class="col">
                                <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col address">
                                <mat-form-field appearance="fill">
                                    <mat-label>Number & Street</mat-label>
                                    <input matInput type="text" id="txtAddress" formControlName="txtAddress" [(ngModel)]="address">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row city-country">
                            <div class="col city">
                                <mat-form-field appearance="fill">
                                    <mat-label>City</mat-label>
                                    <input matInput type="text" id="txtCity" formControlName="txtCity" [(ngModel)]="city">
                                </mat-form-field>
                            </div>
                            <div class="col-mini"></div>
                            <div class="col country">
                                <mat-form-field appearance="fill">
                                    <mat-label>Country</mat-label>
                                    <mat-select [(value)]="selectedCountry" class="prefilled" (selectionChange)="getRegions($event, selectedCountry)" >
                                        <mat-option *ngFor="let country of allCountries" [value]="country.countryShortCode">{{ country.countryName }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row zip-state">
                            <div class="col zip">
                                <mat-form-field appearance="fill">
                                    <mat-label>Zip/Postal code</mat-label>
                                    <input matInput type="text" id="txtZip" formControlName="txtZip" [(ngModel)]="zip">
                                </mat-form-field>
                            </div>
                            <div class="col-mini"></div>
                            <div class="col state">
                                <mat-form-field appearance="fill">
                                    <mat-label>State/Province/Region</mat-label>
                                    <mat-select [(value)]="selectedRegion" [formControl]="selRegion">
                                        <mat-option *ngFor="let region of countryRegions" [value]="region.shortCode">{{ region.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 rightPanel">
                    <ng-container *ngIf="plan$ | async as plan">
                        <div class="col">
                            <div class="heading-wrapper row">
                                <div class="col-1"></div>
                                <div class="logo col-2"></div>
                                <div class="heading col">
                                    <div class="plan-label">Your Plan</div>
                                    <div class="plan-info">{{ allPlansWording[plan.id].title }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div *ngIf="!amountOff">
                                <div class="amount align-middle">
                                    <span>
                                        {{ (plan.priceCharged || plan.price) | currency }} 
                                        <span class="per-month">&nbsp;* /{{ getPlanAbreviation(plan.id) }}</span>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="amountOff">
                                <div class="amount align-middle">
                                    <span class="label">Original price</span>
                                    <div>
                                        <span class="subtotal">{{ (plan.priceCharged || plan.price) | currency }}</span>
                                        <span class="per-month">&nbsp;* /{{ getPlanAbreviation(plan.id) }}</span>
                                    </div>

                                    <span class="label">Promotion discount applied</span>
                                    <div>
                                        <span class="discount">{{ amountOff | currency }}</span>
                                        <span class="per-month">&nbsp;* /{{ getPlanAbreviation(plan.id) }}</span>
                                    </div>

                                    <span class="label">Total billed</span>
                                    <div>
                                        <span class="final">{{ ((plan.priceCharged || plan.price) + amountOff) | currency }}</span>
                                        <span class="per-month">&nbsp;* /{{ getPlanAbreviation(plan.id) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">&nbsp;</div>
                        <div class="col">
                            <div class="buttons"><button class="col" [disabled]="!termsAccepted || !formIsValid()" (click)="upgrade()">Upgrade</button></div>
                        </div>
                        <div class="col">
                            <div class="terms">
                                <div class="checkbox-container">
                                    <mat-checkbox (change)="termsAccepted = !termsAccepted" [color]="'#FFBE5D'"></mat-checkbox>
                                </div>
                                <div class="message-container">I accept the <a href="http://notetracks.com/pro/terms">Terms of Service</a> & <a href="http://notetracks.com/pro/privacy">Privacy Agreements</a>.</div>
                            </div>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
        </div>
    </form>
    <div class="row footer">
        <div class="col-2"><div class="powered-by-stripe"></div></div>
        <div class="col-2"><div class="secure-checkout"></div></div>
        <div class="col"></div>
        <div class="col-2"><div class="need-help">
            <a href="http://support.notetracks.com" class="help">Need Help?</a>
        </div></div>
    </div>
    
</div>