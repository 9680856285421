import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Observer, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import * as io from 'socket.io-client';

import { ConfigService } from '@app/config.service';
import { AuthService } from '@app/features/auth/services';
import { SocketAction, SocketActionResponse, SocketEvent, SocketEventMessage, Subscriber } from '..';

const isNotUndefined = (v) => v !== undefined;
interface ReadyState { ready: boolean; connected: boolean; login: boolean; }

@Injectable({
    providedIn: 'root'
})
export class SocketService implements OnDestroy {
    /**
     * Login Response
     * 
     * Emits on Login Message, does not replay
     */
    private readonly MAX_LOGIN_RETRY = 3;
    private loginRetry = 0;

    /**
     * Connected State
     * 
     * Emits last of either true, on socket connect, or false, on socket disconnect
     */
    private _socket: SocketIOClient.Socket = io(this.config.get('socketio').url, { autoConnect: false });
    private _connected$$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

    /**
     * Ready State
     * 
     * Emits last of either true, when socket is ready to send message other than login; or false, when not ready
     */
    private _ready$: Observable<ReadyState> = this._connected$$.asObservable().pipe(
        filter(isNotUndefined),
        distinctUntilChanged(),
        shareReplay(1),
    ).pipe( // @todo Remove double pipe left from refactoring and test to ensure same result
        switchMap((connected: boolean) => connected ? this.login().pipe(map((login) => ({ ready: true, connected, login }) )) : of({ ready: false, connected, login: false })),
        distinctUntilChanged(),
        shareReplay(1)
    );

    /**
     * Record of action responses
     */
    private _responses$$: Record<SocketAction, Subject<SocketActionResponse>> = {
        [SocketAction.LOGIN]:  new Subject(),
        [SocketAction.PUBLICKEY]:  new Subject(),
        [SocketAction.SUBSCRIBE]: new Subject(),
        [SocketAction.UNSUBSCRIBE]: new Subject(),
    };

    private _publicKey$$: BehaviorSubject<string> = new BehaviorSubject(undefined);
    public publicKey$: Observable<string> = this._publicKey$$.asObservable()//.pipe(filter(isNotUndefined));

    /**
     * Record of "event" subjects filtered by ObjectType
     * 
     * Emits when an event is received for the ObjectType
     */
    private _events$$: Record<string, Subject<Omit<SocketEventMessage, "subcribets">>> =  {};

    private _subscribers$$: BehaviorSubject<Omit<SocketEventMessage, "object_type" | "object_id">> = new BehaviorSubject(undefined);

    private _destroy$$: Subject<void> = new Subject();
    private _unsubscribe$$: Subject<void> = new Subject();

    constructor(private readonly config: ConfigService, private readonly auth: AuthService) {
        //#region Register Handlers
        this._socket.off('connect');
        this._socket.on('connect', () => {
            console.info(`Connected to socket: [${this.config.get('socketio').url}]`);
            this._connected$$.next(true);
        });
        this._socket.off('disconnect');
        this._socket.on('disconnect', (reason: string) => {
            console.info(`Disconnected from socket: [${this.config.get('socketio').url}] because [${reason}]`);
            this._connected$$.next(false);
        });
        this._socket.off('connect_error');
        this._socket.on('connect_error', (err: Object) => {
            console.error(`Error connecting to socket: [${this.config.get('socketio').url}]`, err);
            this._connected$$.next(false);
        });
        this._socket.off('error');
        this._socket.on('error', (err: Object) => {
            console.error(`An error occured in socket: [${this.config.get('socketio').url}]`, err);
            // @todo emit something to outside world
        });
        this._socket.off('message');
        this._socket.on('message', this.onMessage);
        //#endregion

        this._socket.connect();

    }

    ngOnDestroy(): void {
        this._destroy$$.next();
        this._destroy$$.complete();
    }

    //#region Actions
    private login(): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            this._responses$$.login.asObservable().pipe(take(1)).subscribe((response: SocketActionResponse) => {
                observer.next(!response.error);
                observer.complete();
            });
            const action = SocketAction.LOGIN, username = 'authToken', password = this.auth.currentSession?.authToken;
            this._socket.send({ action, username, password });
        });
    }

    private publicKey(publicKey): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            if(publicKey) {
                this._responses$$.publickey.asObservable().pipe(take(1)).subscribe((response: SocketActionResponse) => {
                    if(!response.error) {
                        this._publicKey$$.next(publicKey);
                    } else {
                        this._publicKey$$.next(undefined);
                    }
                    observer.next(!response.error);
                    observer.complete();
                });
                const action = SocketAction.PUBLICKEY;
                this._socket.send({ action, publicKey });
            } else {
                observer.next(true); // True, no error
                observer.complete();
            }
        });
    }

    sub(projectId: number, publicKey?: string, isDemo: boolean = false): Observable<boolean> {
        console.info(`Subscribibg to socket: [${this.config.get('socketio').url}] for project [${projectId}] with key [${publicKey}]`);
        if(isDemo) { return of(true); }

        return new Observable((observer: Observer<boolean>) => {
            this._ready$.pipe(
                takeUntil(this._unsubscribe$$),
                filter((state: ReadyState) => state.ready), 
                switchMap(() => this.publicKey(publicKey).pipe(take(1))),
            ).subscribe((key: boolean) => {
                this._responses$$.subscribe.asObservable().pipe(take(1)).subscribe((response: SocketActionResponse) => {
                    observer.next(!response.error);
                    observer.complete();
                });
                const action = SocketAction.SUBSCRIBE, project_id = projectId;
                this._socket.send({ action, project_id });
            });
        });
    }

    unsub(projectId?: number) {
        console.info(`Unsubscribibg from socket: [${this.config.get('socketio').url}] for project [${projectId}]`);
        return new Observable((observer: Observer<boolean>) => {
            this._responses$$.unsubscribe.pipe(take(1)).subscribe((response: SocketActionResponse) => {
                observer.next(!response.error);
                observer.complete();
                this._unsubscribe$$.next();
            });
            this._socket.send({
                'action': 'unsubscribe',
                'project_id': projectId
            });
        });
    }
    //#endregion

    subscribers(projectId: number): Observable<Array<Subscriber>> {
        return this._subscribers$$.asObservable().pipe(
            filter(isNotUndefined),
            filter((r: Omit<SocketEventMessage, "object_type" | "object_id">) => r.project_id === projectId),
            map((r: Omit<SocketEventMessage, "object_type" | "object_id">) => r.subscribers),
            distinctUntilChanged(),
            shareReplay(1)
        );
    }

    observe<T>(objectClass: new (data) => T): Observable<SocketEventMessage> {
        if(!(objectClass.name in this._events$$)) {
            this._events$$[objectClass.name] = new Subject();
        }

        return this._events$$[objectClass.name].asObservable();
    }

    //#region EventListeners
    private onMessage = (message) => {
        if(message.event) {
            return this.onEventMessage(message);
        } else {
            switch(message.action) {
                case 'login':
                    return this.onLoginMessage(message);
                case 'publickey':
                    return this.onPublicKeyMessage(message);
                case 'subscribe':
                    return this.onSubscribeMessage(message);
                case 'unsubscribe':
                    return this.onUnsubscribeMessage(message);
                default:
                    console.info(`Message from socket: [${this.config.get('socketio').url}]`, message);
                    break;
            }
        }
        // @todo emit something to outside world
    }
    private onEventMessage = (message: SocketEventMessage) => {
        console.info(`Event message from socket: [${this.config.get('socketio').url}]`, message);
        if(message.event === SocketEvent.SUBSCRIBERS) {
            this._subscribers$$.next(message);
        } else {
            const { object_type } = message;
            if(!(object_type in this._events$$)) {
                this._events$$[object_type] = new Subject();
            }

            this._events$$[object_type].next(message);
        }
    }
    private onLoginMessage = (message) => {
        console.info(`Login message from socket: [${this.config.get('socketio').url}]`, message);
        if(message.error) {
            if(this.loginRetry < this.MAX_LOGIN_RETRY) {
                this.loginRetry++;
                setTimeout(() => this.login(), 5000);
            } else {
                this._responses$$[SocketAction.LOGIN].next(message);
            }
        } else {
            this._responses$$[SocketAction.LOGIN].next(message);
        }
    }
    private onPublicKeyMessage = (message) => {
        console.info(`PublicKey message from socket: [${this.config.get('socketio').url}]`, message);
        this._responses$$[SocketAction.PUBLICKEY].next(message);
    }

    private onSubscribeMessage = (message) => {
        console.info(`Subscribe message from socket: [${this.config.get('socketio').url}]`, message);
        this._responses$$[SocketAction.SUBSCRIBE].next(message);
        if(message.error && message.code === 401) {
            if(message.project_id) {
                // this.ready$.pipe(filter(Boolean),take(1)).subscribe(() => this.subscribe(message.project_id))
                // this.login();
            }
        }
    }
    private onUnsubscribeMessage = (message) => {
        console.info(`Unsubscribe message from socket: [${this.config.get('socketio').url}]`, message);
        this._responses$$[SocketAction.UNSUBSCRIBE].next(message);
        if(message.error && message.code === 401) {
            if(message.project_id) {
                // this.ready$.pipe(filter(Boolean),take(1)).subscribe(() => this.subscribe(message.project_id))
                // this.login();
            }
        }
    }
    //#endregion
}
