import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthUser } from '@app/features/auth';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'nt-app-toolbar',
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['./app-toolbar.component.scss']
})
export class AppToolbarComponent implements OnInit {

  private readonly ICONS = [
    'anonymous','back','bell','bongo','check','cog','collapsed','commentmaker','credit_card','dark','disc','divider','dots','download','dropbox',
    'ellipses','emoji','emojihallow','enter','exit','expanded','fastforward','filter','folder','followhead','google-drive','guitar','hamburger',
    'hashtag','headphones','hidden','home','horizontal_lines','image','info','light','link','magnify','magnifyminus','magnifyplus','marker',
    'mic','microphone','multitrack','notepad','pause','pencil','pencilup','person','phylacteries','phylactery','piano','play','plus',
    'plusthin','pointer','radio-check','recent','record','refresh','reply','resolved','rewind','saxo','skipbackward','skipforward','speakermax',
    'speakermin','speakeroff','speed','star','stop','tambou','text','thumbspu','trash','trumpet','upload','vertical_lines','visible','wave','waveform','x'
  ];

  @Input('logo') logoMode: 'full' | 'icon' = 'full';
  @Input('profile') profileMode: 'full' | 'icon' = 'full';
  @Input('logoLink') logoLink: any[] | string | null | undefined = ['/project'];
  @Input('user') user: Readonly<AuthUser>;

  @Output('logoClick') logoClick: EventEmitter<PointerEvent | MouseEvent> = new EventEmitter();

  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {
    // const domain = `${location.protocol}//${location.host}${location.port ? ':' + location.port : ''}`;
    this.ICONS.forEach((name: string) => {
        const url: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${location.origin}/assets/images/icons/${name}.svg`);
        this.matIconRegistry.addSvgIcon(name, url);
    });
  }

  ngOnInit(): void {
  }

  GoToHelp() {
    window.location.href = environment.helpUrl;
  }

}
