<span *ngIf="status === Statuses.Initial; else notInitial" class="start" [inlineSVG]="'/assets/images/icons/microphone.svg'" (click)="armRecording()"></span>
<ng-template #notInitial>
    <div class="controls">
        <!-- <span *ngIf="editMode && status !== Statuses.Recording && status !== Statuses.Armed" class="remove" [inlineSVG]="'/assets/images/icons/trash.svg'" (click)="remove()"></span> -->
        <span *ngIf="status === Statuses.Armed" (click)="startRecording()" [inlineSVG]="'/assets/images/icons/recordborder.svg'"></span>
        <span *ngIf="status === Statuses.Recording" class="recording" (click)="stopRecording()" [inlineSVG]="'/assets/images/icons/stop.svg'"></span>
        <span *ngIf="status === Statuses.Recorded || status === Statuses.Paused" (click)="play()" [inlineSVG]="'/assets/images/icons/play.svg'"></span>
        <span *ngIf="status === Statuses.Playing" (click)="pause()" [inlineSVG]="'/assets/images/icons/pause.svg'"></span>
        <audio #player class="audionote"></audio>
    </div>
    <div class="states">
        <ng-container [ngSwitch]="status">
            <div *ngSwitchCase="Statuses.Recording" class="state" >{{ Statuses[status] }}</div>
            <div *ngSwitchCase="Statuses.Armed">Press button to start recording</div>
            <div *ngSwitchDefault #waveform class="waveform">
                <canvas #canvas height="100%" width="100%"></canvas>
                <div #playhead class="playhead" [style.transform]="'translateX(' + playheadOffset + 'px)'" [hidden]="!showPlayhead"></div>
            </div>
        </ng-container>
        <!-- <div class="state" *ngIf="status === Statuses.Recording; else notRecording">{{ Statuses[status] }}</div>
        <ng-template #notRecording>
            <div #waveform class="waveform">
                <canvas #canvas height="100%" width="100%"></canvas>
                <div #playhead class="playhead" [style.transform]="'translateX(' + playheadOffset + 'px)'" [hidden]="!showPlayhead"></div>
            </div>
        </ng-template> -->
    </div>
    <div class="timecode">
        <span *ngIf="status !== Statuses.Recording && status != Statuses.Armed; else recordingTime">{{ player.currentTime | timecode:'mm:ss' }}</span>
        <ng-template #recordingTime>
            <span *ngIf="status != Statuses.Armed">{{ recorderTime | async | timecode:'mm:ss' }}</span>
        </ng-template>
    </div>
    <div class="dispose">
        <span *ngIf="editMode && status !== Statuses.Recording" (click)="remove()" [inlineSVG]="'/assets/images/icons/xthin.svg'"></span>
    </div>
</ng-template>