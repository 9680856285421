import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';

@Component({ template: '' })
export abstract class FormComponent implements OnInit {
  @ViewChild('theForm')
  public form: NgForm;
  public isProcessing: boolean;
  public submitted: boolean;
  public error: any;

  abstract ngOnInit(): void;
  abstract submit(): void;

  public validate(): boolean {
    if (!this.form.valid) {
      this.submitted = true;
    }

    return this.form.valid || false;
  }

  public clearError(): void {
    this.error = null;
  }
}
