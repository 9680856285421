import { ApiObject, IApiResponse } from '@app/core/api';
import { IAuthUser } from '.';
import { AuthUser } from './auth-user';
import { IAuthResponse } from './iauth-response';

export class AuthResponse extends ApiObject<IAuthResponse> {
    public readonly authToken: string;
    public readonly expiry: string;
    public readonly ttl: number;
    public readonly user: AuthUser;
    public readonly rawResponse: IApiResponse<IAuthResponse>;

    protected transformUserForInit(data: IAuthUser) {
        return new AuthUser(data);
    }

    public clone<AuthResponse extends ApiObject<IAuthResponse>>(): AuthResponse {
        return this.shallowClone(this.constructor as { new (data: IAuthResponse): AuthResponse } );
    }
}
